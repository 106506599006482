import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {AccessControlIndicator, withPolicyAccessCheck} from '@access-control';
import {FeatureAccessStatus} from '@models/generated/graphql';

import {useJurisdictionFeature} from '../../app/config/hooks';
import {FeatureName} from '../../app/config/types';
import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {userProfileActions} from '../actions';
import {
    financeActionsReadPermissions,
    financeActionsUpdatePermissions,
    p2pActionReadResources,
    p2pActionUpdateResources,
} from '../permissions';

import {LockActionProps} from './UserProfileActions';
import {UserProfileLock} from './UserProfileLock';

const localized = defineMessages({
    label: {
        id: 'P2PTransferLock_label',
        defaultMessage: 'P2P transfer',
    },
});

type P2PTransferLockProps = LockActionProps<FeatureAccessStatus>;

function P2PTransferLockInner({uid, status, disabled}: P2PTransferLockProps) {
    const {formatMessage} = useIntl();

    return (
        <UserProfileLock
            action={userProfileActions.changeP2PTransferStatus}
            label={formatMessage(localized.label)}
            uid={uid}
            isLocked={status === FeatureAccessStatus.Locked}
            disabled={disabled}
            dataTestId="p2p-transfer-lock"
        />
    );
}

const P2PTransferLockObsolete = withMultiplePermission(
    withMultiplePermission(P2PTransferLockInner, {
        allowedPermissions: financeActionsUpdatePermissions,
        indicator: LackOfPermissionIndicator.Disabled,
    }),
    {allowedPermissions: financeActionsReadPermissions, indicator: LackOfPermissionIndicator.Hidden}
);

const P2PTransferLockUpdated = withPolicyAccessCheck(
    withPolicyAccessCheck(P2PTransferLockInner, p2pActionUpdateResources, AccessControlIndicator.Disabled),
    p2pActionReadResources,
    AccessControlIndicator.Hidden
);

export function P2PTransferLock(props: P2PTransferLockProps) {
    const isAvailable = useJurisdictionFeature({featureName: FeatureName.P2POptionsHideForPHRoles});
    const Component: React.ComponentType<P2PTransferLockProps> = isAvailable ? P2PTransferLockUpdated : P2PTransferLockObsolete;
    return <Component {...props} />;
}
