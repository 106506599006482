import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {PermissionAccessibleItem, PermissionEnum} from '../module-shared/types';

export const withdrawalActionsPermissions: PermissionAccessibleItem[] = [
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.Payment,
    },
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.WithdrawalProcessingRisk,
    },
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
    },
];

export const withdrawalRiskUpdatePermissions: PermissionAccessibleItem[] = [
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.WithdrawalProcessingRisk,
    },
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
    },
];

export const withdrawalPaymentUpdatePermissions: PermissionAccessibleItem[] = [
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.Payment,
    },
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
    },
];

export const redeemRevenueSharePermissions: PermissionAccessibleItem[] = [
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.EntityTransactions,
        submoduleName: SubmoduleName.RevenueShare,
    },
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.EntityTransactions,
    },
];

export const manualTransactionAddPolicies: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.ActionCenter, permissions: [PermissionEnum.Update]},
    {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.FinanceActions, permissions: [PermissionEnum.Update]},
];
