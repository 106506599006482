import React from 'react';
import {defineMessages} from 'react-intl';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource, withPolicyAccessCheck} from '@access-control';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar from '@components/toolbar/Toolbar';
import {Policy} from '@auth';
import {ViewType} from '@redux/view';

import {ModuleName} from 'src/common/routeEnums';
import {LackOfPermissionIndicator, withPermissionAndJurisdictionConfig} from 'src/features/app/permission/PermissionHoc';
import {DataGridSportsbookTransactionServer} from '../features/block-sportsbook-transaction/components/DataGridSportsbookTransaction';
import {SportsbookTransactionQueryFilters} from '../features/block-sportsbook-transaction/components/SportsbookTransactionQueryFilterAdapter';
import {useSportsbookTransaction} from '../features/block-sportsbook-transaction/hooks';
import {SportsbookTransactionViewModelKeys} from '../features/block-sportsbook-transaction/types';

const localized = defineMessages({
    title: {
        id: 'SportsbookTransactionHistory_title',
        defaultMessage: 'Sportsbook Transaction History',
    },
});

function SportsbookTransactionHistoryInner() {
    const view: ViewType = 'SportsbookTransactionHistory';
    const keys: SportsbookTransactionViewModelKeys[] = [
        'compUserId',
        'ticketId',
        'sabaTicketId',
        'ticketStatus',
        'matchNames',
        'tournamentNames',
        'matchIds',
        'betOptions',
        'betOdds',
        'betAmount',
        'settleAmount',
        'betTime',
    ];

    const {items, totalCount, searchFilter, filterString, handlePageChange, handlePageSizeChange, handleSortChange, handleFilterChange} =
        useSportsbookTransaction({
            viewType: view,
            syncWithUrl: true,
            defaultSorting: [{field: 'betTime', sort: 'desc'}],
            defaultPaging: {
                page: 1,
                pageSize: 10,
            },
            validateFilter: () => true,
        });
    return (
        <LayoutQueue
            header={<PageHeader header={localized.title} />}
            toolbar={
                <Toolbar
                    primary={
                        <SportsbookTransactionQueryFilters
                            model={filterString}
                            onChange={handleFilterChange}
                            availableFilters={['compUserId', 'ticketId', 'tournamentName', 'matchId', 'ticketStatus', 'betTime']}
                        />
                    }
                />
            }
            body={
                <DataGridSportsbookTransactionServer
                    columns={keys}
                    rows={items}
                    rowCount={totalCount}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={handleSortChange}
                    page={searchFilter?.paging?.page}
                    pageSize={searchFilter?.paging?.pageSize}
                    sortModel={searchFilter?.sorting}
                />
            }
        />
    );
}

export const sportsbookTransactionResourse: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'SportsbookTransactionHistory',
    rules: new PoliciesAccessRule([new Policy(ModuleName.PageSportsbookOperation, null, 'read')]),
});

export const SportsbookTransactionHistory = withPermissionAndJurisdictionConfig(
    withPolicyAccessCheck(SportsbookTransactionHistoryInner, sportsbookTransactionResourse),
    {moduleName: ModuleName.PageSportsbookOperation},
    LackOfPermissionIndicator.Disabled
);
