import {GridValueGetterParams} from '@mui/x-data-grid';

import {renderChip} from '@components/chip/ChipRenderer';
import {
    AmountCurrencyCellProps,
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    labelCellLocalized,
    labelCellValueGetter,
    MuiDataGridClient,
    MuiDataGridServer,
    renderAmountCurrencyCellWithTooltip,
    renderCellHeader,
    renderCellWithTooltip,
    renderDateCell,
    renderDefaultChipCell,
    renderGridNumber,
    renderLabelsCell,
    renderTextLink,
    TextLinkCell,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import {TransactionStatus, TransactionType} from '@models/generated/graphql';
import {TransactionDuration as TransactionDurationType, TransactionViewModel, TransactionViewModelKeys} from '@models/transaction';

import {RouteUrl} from 'src/common/routeEnums';

import {localizedHeaders} from './DataGridTransaction.localize';
import {renderTransactionDuration} from './TransactionDuration';
import {renderTransactionMethod, TransactionMethodProps} from './TransactionMethod';
import {renderP2PTransferDirection} from './TransactionType';

export class DataGridTransactionConfigFactory extends DataGridEntityConfigFactory<TransactionViewModelKeys, TransactionViewModel> {
    getColumnConfig(): Partial<Record<TransactionViewModelKeys, DataGridEntityColDef<TransactionViewModelKeys>>> {
        const columnsMapping: Partial<Record<TransactionViewModelKeys, DataGridEntityColDef<TransactionViewModelKeys>>> = {
            uid: {
                field: 'uid',
                valueGetter: p => this.getUid(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridUid),
                renderCell: renderDefaultChipCell,
                flex: 0.15,
                sortable: false,
            },
            transaction_id: {
                field: 'transaction_id',
                valueGetter: p => this.getTransactionId(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridTransactionId),
                renderCell: renderDefaultChipCell,
                flex: 0.15,
                sortable: false,
            },
            withdrawal_id: {
                field: 'withdrawal_id',
                valueGetter: p => this.getWithdrawalLink(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridWithdrawalId),
                renderCell: renderTextLink,
                flex: 0.3,
                sortable: false,
            },
            transaction_started_ts: {
                field: 'transaction_started_ts',
                valueGetter: p => this.getTransactionStartedTs(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionTimestamp),
                renderCell: renderDateCell,
                flex: 0.175,
                sortable: false,
            },
            transaction_updated_ts: {
                field: 'transaction_updated_ts',
                valueGetter: p => this.getTransactionUpdatedTs(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionTimestamp),
                renderCell: renderDateCell,
                flex: 0.175,
                sortable: false,
            },
            type: {
                field: 'type',
                valueGetter: p => this.getType(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridDirection),
                renderCell: params => renderChip<TransactionType>(params.value as TransactionType, nameof<TransactionType>()),
                flex: 0.2,
                sortable: false,
            },
            transaction_type: {
                field: 'transaction_type',
                valueGetter: p => this.getType(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridTransactionType),
                renderCell: params => renderChip<TransactionType>(params.value as TransactionType, nameof<TransactionType>()),
                flex: 0.15,
                sortable: false,
            },
            payment_method_name: {
                field: 'payment_method_name',
                valueGetter: p => this.getPaymentMethodName(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridPaymentMethod),
                renderCell: renderTransactionMethod,
                flex: 0.15,
                sortable: false,
            },
            payment_method_description: {
                field: 'payment_method_description',
                valueGetter: p => this.getPaymentMethodDescription(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridPaymentMethodDescription),
                renderCell: renderCellWithTooltip,
                flex: 0.2,
                sortable: false,
            },
            amount: {
                field: 'amount',
                valueGetter: p => this.getAmount(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridAmount),
                renderCell: renderAmountCurrencyCellWithTooltip,
                flex: 0.15,
                sortable: false,
            },
            amount_without_currency: {
                field: 'amount_without_currency',
                valueGetter: p => this.getAmountWithoutCurrency(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridAmount),
                renderCell: renderGridNumber,
                flex: 0.11,
                sortable: false,
            },
            currency: {
                field: 'currency',
                valueGetter: p => this.getCurrency(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridCurrency),
                flex: 0.12,
                sortable: false,
            },
            transaction_status: {
                field: 'transaction_status',
                valueGetter: p => this.getTransactionStatus(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridTransactionStatus),
                renderCell: params => renderChip<TransactionStatus>(params.value as TransactionStatus, nameof<TransactionStatus>()),
                flex: 0.15,
                sortable: false,
            },
            current_balance: {
                field: 'current_balance',
                valueGetter: p => this.getCurrentBalance(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridCurrentBalance),
                renderCell: renderAmountCurrencyCellWithTooltip,
                flex: 0.12,
                sortable: false,
            },
            email: {
                field: 'email',
                valueGetter: p => this.getEmail(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridEmail),
                renderCell: renderCellWithTooltip,
                flex: 0.2,
                sortable: false,
            },
            phone: {
                field: 'phone',
                valueGetter: p => this.getPhone(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridPhone),
                renderCell: renderCellWithTooltip,
                flex: 0.15,
                sortable: false,
            },
            username: {
                field: 'username',
                valueGetter: p => this.getUsername(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridUsername),
                renderCell: renderCellWithTooltip,
                flex: 0.1,
                sortable: false,
            },
            register_marketing_code: {
                field: 'register_marketing_code',
                valueGetter: p => this.getMarketingCode(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridMarketingCode),
                renderCell: renderCellWithTooltip,
                flex: 0.1,
                sortable: false,
            },
            referrer_player_id: {
                field: 'referrer_player_id',
                valueGetter: p => this.getReferrerPlayerId(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridReferrerPlayerId),
                renderCell: renderCellWithTooltip,
                flex: 0.1,
                sortable: false,
            },
            player_country: {
                field: 'player_country',
                valueGetter: p => this.getPlayerCountry(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridCountry),
                renderCell: renderCellWithTooltip,
                flex: 0.1,
                sortable: false,
            },
            counterpart_player_id: {
                field: 'counterpart_player_id',
                valueGetter: p => this.getCounterpartPlayerId(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridCounterpartPlayerId),
                renderCell: renderCellWithTooltip,
                flex: 0.15,
                sortable: false,
            },
            duration: {
                field: 'duration',
                valueGetter: p => this.getDuration(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridDuration),
                renderCell: renderTransactionDuration,
                flex: 0.11,
                sortable: false,
            },
            user_labels: {
                field: 'user_labels',
                valueGetter: p => this.getLabels(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: labelCellLocalized.labels,
                renderCell: renderLabelsCell,
                flex: 0.2,
                sortable: false,
            },
            p2p_direction: {
                field: 'p2p_direction',
                valueGetter: p => this.getType(p),
                renderHeader: () => renderCellHeader(localizedHeaders.transactionsGridP2PDirection),
                renderCell: renderP2PTransferDirection,
                flex: 0.15,
                sortable: false,
            },
        };

        return columnsMapping;
    }

    private getUid(p: GridValueGetterParams) {
        return this.getRow(p)?.uid;
    }

    private getEmail(p: GridValueGetterParams) {
        return this.getRow(p)?.email;
    }

    private getTransactionId(p: GridValueGetterParams) {
        return this.getRow(p)?.transaction_id;
    }

    private getWithdrawalLink(p: GridValueGetterParams) {
        const link: TextLinkCell = {id: this.getRow(p)?.id, path: RouteUrl.WithdrawalDetails};
        return link;
    }

    private getType(p: GridValueGetterParams) {
        return this.getRow(p)?.transaction_type;
    }

    private getAmount(p: GridValueGetterParams) {
        const value: AmountCurrencyCellProps = {amount: this.getAmountWithoutCurrency(p), currency: this.getCurrency(p)};
        return value;
    }

    private getAmountWithoutCurrency(p: GridValueGetterParams) {
        return this.getRow(p)?.amount;
    }

    private getCurrency(p: GridValueGetterParams) {
        return this.getRow(p)?.currency;
    }

    private getTransactionStartedTs(p: GridValueGetterParams) {
        return this.getRow(p)?.transaction_started_ts;
    }

    private getTransactionUpdatedTs(p: GridValueGetterParams) {
        return this.getRow(p)?.transaction_updated_ts;
    }

    private getPaymentMethodName(p: GridValueGetterParams): TransactionMethodProps {
        return {payment_method_name: this.getRow(p)?.payment_method_name, transaction_type: this.getRow(p)?.transaction_type};
    }

    private getPaymentMethodDescription(p: GridValueGetterParams) {
        return this.getRow(p)?.payment_method_description;
    }

    private getTransactionStatus(p: GridValueGetterParams) {
        return this.getRow(p)?.transaction_status;
    }

    private getCurrentBalance(p: GridValueGetterParams) {
        const value: AmountCurrencyCellProps = {amount: this.getRow(p)?.current_balance, currency: this.getCurrency(p)};
        return value;
    }

    private getDuration(p: GridValueGetterParams): TransactionDurationType {
        return {history: this.getRow(p)?.status_log, started_at: this.getRow(p)?.transaction_started_ts};
    }

    private getPhone(p: GridValueGetterParams) {
        return this.getRow(p)?.phone;
    }

    private getUsername(p: GridValueGetterParams) {
        return this.getRow(p)?.username;
    }

    private getMarketingCode(p: GridValueGetterParams) {
        return this.getRow(p)?.register_marketing_code;
    }

    private getReferrerPlayerId(p: GridValueGetterParams) {
        return this.getRow(p)?.referrer_player_id;
    }

    private getPlayerCountry(p: GridValueGetterParams) {
        return this.getRow(p)?.player_country;
    }

    private getCounterpartPlayerId(p: GridValueGetterParams) {
        return this.getRow(p)?.counterpart_player_id;
    }

    private getLabels(p: GridValueGetterParams) {
        return labelCellValueGetter(this.getRow(p)?.user_labels);
    }
}

export const DataGridTransactionsClient = withDataGridEntityClient<TransactionViewModelKeys, TransactionViewModel>(MuiDataGridClient, () =>
    new DataGridTransactionConfigFactory().getColumnConfig()
);

export const DataGridTransactionsServer = withDataGridEntityServer<TransactionViewModelKeys, TransactionViewModel>(MuiDataGridServer, () =>
    new DataGridTransactionConfigFactory().getColumnConfig()
);
