import {defineMessages} from 'react-intl';

import {KycInitReasonCode} from '@models/generated/graphql';

export const localizedKYCInitReasonCodes = defineMessages<KycInitReasonCode>({
    [KycInitReasonCode.Payment]: {
        id: 'KYCInitReasonCodes_Payment',
        defaultMessage: 'KYC Payment',
    },
    [KycInitReasonCode.Security]: {
        id: 'KYCInitReasonCodes_Security',
        defaultMessage: 'KYC Security',
    },
    [KycInitReasonCode.PePs]: {
        id: 'KYCInitReasonCodes_PEPs',
        defaultMessage: 'KYC PEPs',
    },
    [KycInitReasonCode.CcDep]: {
        id: 'KYCInitReasonCodes_CCDep',
        defaultMessage: 'KYC CC Dep',
    },
    [KycInitReasonCode.Proac]: {
        id: 'KYCInitReasonCodes_Proac',
        defaultMessage: 'KYC Proac',
    },
    [KycInitReasonCode.Others]: {
        id: 'KYCInitReasonCodes_Others',
        defaultMessage: 'KYC Others',
    },
});
