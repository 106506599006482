import {combineReducers} from 'redux';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {createGridModuleReducers} from '../../features/module-shared/reducers';

import {domain} from './types';

const bonusEngineDetailsReducer = combineReducers({
    grid: createGridModuleReducers(domain, ModuleName.MarketingFunction, SubmoduleName.BonusEngine),
});

export default bonusEngineDetailsReducer;
