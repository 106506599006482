import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {GridCellParams, GridValueGetterParams} from '@mui/x-data-grid';

import {IModuleGridItem} from '@components/data-grid';
import {DataGridEntityColDef, DataGridEntityConfigFactory, renderCellHeader, renderValueWithTooltip} from '@components/data-grid/mui';
import {LocalizedText} from '@components/i18n';

import {GlobalServerErrorMessage} from '../../app/error-handling';

import {localizedHeaders} from './DataGridBulkResults.localize';

export const dataGridBulkResultsColumnKeys = ['rowIndex', 'error', 'errorRaw'] as const;

export type DataGridBulkResultsColumns = (typeof dataGridBulkResultsColumnKeys)[number];

export type BulkResultsRecord = {rowIndex: number; error: MessageDescriptor; rawErrorMessage: string};

export class DataGridBulkResultsConfigFactory extends DataGridEntityConfigFactory<
    DataGridBulkResultsColumns,
    BulkResultsRecord & IModuleGridItem
> {
    getColumnConfig(): Record<DataGridBulkResultsColumns, DataGridEntityColDef<DataGridBulkResultsColumns>> {
        const columnsMapping: Record<DataGridBulkResultsColumns, DataGridEntityColDef<DataGridBulkResultsColumns>> = {
            rowIndex: {
                field: 'rowIndex',
                valueGetter: p => this.getRowIndex(p),
                renderHeader: () => renderCellHeader(localizedHeaders.bulkResultsGridRowIndex),
                flex: 0.15,
                sortable: false,
            },
            error: {
                field: 'error',
                renderCell: p => renderValueWithTooltip(<LocalizedText label={this.getError(p)} />),
                renderHeader: () => renderCellHeader(localizedHeaders.bulkResultsGridError),
                flex: 0.45,
                sortable: false,
            },
            errorRaw: {
                field: 'errorRaw',
                renderCell: p =>
                    renderValueWithTooltip(<GlobalServerErrorMessage error={this.getErrorRaw(p)} hasError={this.hasError(p)} />),
                renderHeader: () => renderCellHeader(localizedHeaders.bulkResultsGridError),
                flex: 0.45,
                sortable: false,
            },
        };

        return columnsMapping;
    }

    private getRowIndex(p: GridValueGetterParams) {
        return this.getRow(p)?.rowIndex;
    }

    private getError(p: GridCellParams) {
        return this.getRow(p)?.error;
    }

    private getErrorRaw(p: GridCellParams) {
        return this.getRow(p)?.rawErrorMessage;
    }

    private hasError(p: GridCellParams) {
        return this.getRow(p)?.rawErrorMessage !== undefined;
    }
}
