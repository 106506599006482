import {inject, injectable} from 'inversify';
import {merge, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {BulkItemInput, BulkItemStatus, BulkMutationInput, FeatureAccessLockInput, UserProfile} from '@models/generated/graphql';
import {ChangeLockStatusModel} from '@services/rest-api/playerManagementApiService';
import {GqlMutationRequest, ServerResponseStatus, ServiceResponsePayload} from '@services/types';
import {IUserProfileService} from '@services/userProfileService';

import {ServiceContainer} from '../../../app/inversify/serviceContainer';
import {
    AccountLockActionItem,
    AddSecurityCaseActionItem,
    BulkActionKey,
    ChangeBulkActionStatusItem,
    DepositLockActionItem,
    failedOperationId,
    IBulkStrategy,
    LobbyLockActionItem,
    PerformStrategyActionItemsResponse,
    PerformStrategyOperationResponse,
    PerformStrategyRequest,
    RemoveSecurityCaseActionItem,
    SendMessageActionItem,
    WithdrawalLockActionItem,
} from '../../block-bulk-actions';
import {EditSecurityCasesSubmitModel} from '../../player-actions/cases-actions/types';

@injectable()
export class PerformP2PLockStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse> {
    private _userProfile: IUserProfileService;

    constructor(@inject(ServiceTypes.UserProfileService) userProfile: IUserProfileService) {
        this._userProfile = userProfile;
    }

    process({items}: PerformStrategyRequest): Observable<PerformStrategyOperationResponse> {
        const timeout = 10000;
        const retries = 3;
        const payload: BulkMutationInput = {
            items: items.map(i => ({id: i.itemId, data: {p2p_lock: i.value as FeatureAccessLockInput}})),
            retries,
            timeout,
        };

        return this._userProfile.lockP2PBatch(payload).pipe(
            map(response => ({actionKey: BulkActionKey.P2PTransferLock, id: response.id})),
            catchError(() => of({actionKey: BulkActionKey.P2PTransferLock, id: failedOperationId}))
        );
    }
}

@injectable()
export class PerformAccountLockStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyActionItemsResponse> {
    private readonly userProfileService: IUserProfileService;

    constructor(@inject(ServiceTypes.UserProfileService) userProfileService: IUserProfileService) {
        this.userProfileService = userProfileService;
    }

    process({items}: PerformStrategyRequest): Observable<PerformStrategyActionItemsResponse> {
        const usersObservables = items.map(item => {
            const user: AccountLockActionItem = item.value as AccountLockActionItem;
            return this.userProfileService.changeAccountLockStatus({uid: user.uid, value: user.isLocked}).pipe(
                map<ServiceResponsePayload<ChangeLockStatusModel, null>, ChangeBulkActionStatusItem>(response => ({
                    actionKey: BulkActionKey.AccountLock,
                    itemId: item.itemId,
                    status: response?.status === ServerResponseStatus.Success ? BulkItemStatus.Successful : BulkItemStatus.Failed,
                }))
            );
        });

        return merge(...usersObservables).pipe(map(result => ({items: [result], actionKey: result.actionKey})));
    }
}

@injectable()
export class PerformLobbyLockStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyActionItemsResponse> {
    private readonly userProfileService: IUserProfileService;

    constructor(@inject(ServiceTypes.UserProfileService) userProfileService: IUserProfileService) {
        this.userProfileService = userProfileService;
    }

    process({items}: PerformStrategyRequest): Observable<PerformStrategyActionItemsResponse> {
        const usersObservables = items.map(item => {
            const user: LobbyLockActionItem = item.value as LobbyLockActionItem;
            return this.userProfileService.changeLobbyLockStatus({uid: user.uid, value: user.isLocked}).pipe(
                map<ServiceResponsePayload<ChangeLockStatusModel, null>, ChangeBulkActionStatusItem>(response => ({
                    actionKey: BulkActionKey.LobbyLock,
                    itemId: item.itemId,
                    status: response?.status === ServerResponseStatus.Success ? BulkItemStatus.Successful : BulkItemStatus.Failed,
                }))
            );
        });

        return merge(...usersObservables).pipe(map(result => ({items: [result], actionKey: result.actionKey})));
    }
}

@injectable()
export class PerformCasinoLockStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse> {
    private _userProfile: IUserProfileService;

    constructor(@inject(ServiceTypes.UserProfileService) userProfile: IUserProfileService) {
        this._userProfile = userProfile;
    }

    process({items}: PerformStrategyRequest<FeatureAccessLockInput>): Observable<PerformStrategyOperationResponse> {
        const bulkItemInput: BulkItemInput[] = items.map(i => ({id: i.itemId, data: {casino_lock: i.value}}));

        return this._userProfile.lockCasinoBatch(bulkItemInput).pipe(
            map(response => ({
                actionKey: BulkActionKey.CasinoLock,
                id: response.status === ServerResponseStatus.Success ? response.responsePayload.id : failedOperationId,
            }))
        );
    }
}

@injectable()
export class PerformSportsbookLockStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse> {
    private _userProfile: IUserProfileService;

    constructor(@inject(ServiceTypes.UserProfileService) userProfile: IUserProfileService) {
        this._userProfile = userProfile;
    }

    process({items}: PerformStrategyRequest<FeatureAccessLockInput>): Observable<PerformStrategyOperationResponse> {
        const bulkItemInput: BulkItemInput[] = items.map(i => ({id: i.itemId, data: {sportsbook_lock: i.value}}));

        return this._userProfile.lockSportsbookBatch(bulkItemInput).pipe(
            map(response => ({
                actionKey: BulkActionKey.SportsbookLock,
                id: response.status === ServerResponseStatus.Success ? response.responsePayload.id : failedOperationId,
            }))
        );
    }
}

@injectable()
export class PerformDepositLockStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyActionItemsResponse> {
    private readonly userProfileService: IUserProfileService;

    constructor(@inject(ServiceTypes.UserProfileService) userProfileService: IUserProfileService) {
        this.userProfileService = userProfileService;
    }

    process({items}: PerformStrategyRequest): Observable<PerformStrategyActionItemsResponse> {
        const usersObservables = items.map(item => {
            const user: DepositLockActionItem = item.value as DepositLockActionItem;
            return this.userProfileService.changeDepositLockStatus({uid: user.uid, value: user.isLocked}).pipe(
                map<ServiceResponsePayload<GqlMutationRequest, UserProfile>, ChangeBulkActionStatusItem>(response => ({
                    actionKey: BulkActionKey.DepositLock,
                    itemId: item.itemId,
                    status: response?.status === ServerResponseStatus.Success ? BulkItemStatus.Successful : BulkItemStatus.Failed,
                }))
            );
        });

        return merge(...usersObservables).pipe(map(result => ({items: [result], actionKey: result.actionKey})));
    }
}

@injectable()
export class PerformWithdrawalLockStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyActionItemsResponse> {
    private readonly userProfileService: IUserProfileService;

    constructor(@inject(ServiceTypes.UserProfileService) userProfileService: IUserProfileService) {
        this.userProfileService = userProfileService;
    }

    process({items}: PerformStrategyRequest): Observable<PerformStrategyActionItemsResponse> {
        const usersObservables = items.map(item => {
            const user: WithdrawalLockActionItem = item.value as WithdrawalLockActionItem;
            return this.userProfileService.changeWithdrawalLockStatus({uid: user.uid, value: user.isLocked}).pipe(
                map<ServiceResponsePayload<GqlMutationRequest, UserProfile>, ChangeBulkActionStatusItem>(response => ({
                    actionKey: BulkActionKey.WithdrawalLock,
                    itemId: item.itemId,
                    status: response?.status === ServerResponseStatus.Success ? BulkItemStatus.Successful : BulkItemStatus.Failed,
                }))
            );
        });

        return merge(...usersObservables).pipe(map(result => ({items: [result], actionKey: result.actionKey})));
    }
}

@injectable()
export class PerformSecurityCasesStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyActionItemsResponse> {
    private readonly _container: ServiceContainer;

    constructor(@inject(ServiceTypes.ServiceContainer) container: ServiceContainer) {
        this._container = container;
    }

    process({items}: PerformStrategyRequest): Observable<PerformStrategyActionItemsResponse> {
        const usersObservables = items.map(item => {
            const value = item.value as AddSecurityCaseActionItem & RemoveSecurityCaseActionItem;
            return this._container.securityCasesService
                .updateSecurityCases(value.uid, {add: value.add, remove: value.remove} as EditSecurityCasesSubmitModel)
                .pipe(
                    map(
                        () =>
                            ({
                                actionKey: item.actionKey,
                                itemId: item.itemId,
                                status: BulkItemStatus.Successful,
                            } as ChangeBulkActionStatusItem)
                    ),
                    catchError(() =>
                        of({
                            actionKey: item.actionKey,
                            itemId: item.itemId,
                            status: BulkItemStatus.Failed,
                        } as ChangeBulkActionStatusItem)
                    )
                );
        });

        return merge(...usersObservables).pipe(map(result => ({items: [result], actionKey: result.actionKey})));
    }
}

@injectable()
export class PerformSendMessageStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyActionItemsResponse> {
    private readonly _container: ServiceContainer;

    constructor(@inject(ServiceTypes.ServiceContainer) container: ServiceContainer) {
        this._container = container;
    }

    process({items}: PerformStrategyRequest): Observable<PerformStrategyActionItemsResponse> {
        const usersObservables = items.map(item => {
            const value: SendMessageActionItem = item.value as SendMessageActionItem;
            return this._container.playerMessageService.sendMessage(value.uid, value.message).pipe(
                map(
                    () =>
                        ({
                            actionKey: BulkActionKey.Message,
                            itemId: item.itemId,
                            status: BulkItemStatus.Successful,
                        } as ChangeBulkActionStatusItem)
                ),
                catchError(() =>
                    of({
                        actionKey: BulkActionKey.Message,
                        itemId: item.itemId,
                        status: BulkItemStatus.Failed,
                    } as ChangeBulkActionStatusItem)
                )
            );
        });

        return merge(...usersObservables).pipe(map(result => ({items: [result], actionKey: result.actionKey})));
    }
}
