import React from 'react';
import {defineMessages} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {OutlinedButton} from '@components/button/Buttons';
import {DisplayCountries, DisplayCountriesProps} from '@components/data-visualize';
import {CustomIcon} from '@components/icons/types';
import {UserLogin} from '@models/generated/graphql';
import {agentReadPolicies} from '@models/permissions/permissions';
import {EntityType, UserLoginQueryFields} from '@redux/entity';
import {useView} from '@redux/view';
import {appendQuery, getFilterString, getSortString} from '@utils';

import {RouteUrl} from 'src/common/routeEnums';
import {withMultiplePermission} from '../../app/permission/PermissionHoc';

import {WorkspaceList} from './WorkspaceList';

const localized = defineMessages({
    loginHistoryButton: {
        id: 'LoggedInCountry_loginHistoryLabel',
        defaultMessage: 'Login History',
    },
});

type LoggedInCountryProps = DisplayCountriesProps & {
    uid?: string;
};

export const useClasses = makeStyles()(() => ({
    loggedInCountry: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    loggedInCountryIcon: {
        fontSize: 'inherit',
    },
}));

const LoggedInCountryInner = ({value, uid}: LoggedInCountryProps) => {
    const {classes} = useClasses();
    const history = useHistory();
    const {forceUpdateFilter} = useView('LoginHistory');

    const redirect = (...routeUrls: RouteUrl[]) => {
        let search = getFilterString('', false, {key: nameof<UserLogin>(m => m.uid), value: uid});
        const sortField: UserLoginQueryFields = 'logged_at_ts.seconds';
        search = getSortString(search, [{field: sortField, sort: 'desc'}]);
        const updatedRoute = appendQuery(routeUrls.join(''), search);

        history.push(updatedRoute);
        forceUpdateFilter(EntityType.UserLogin, search);
    };

    return (
        <WorkspaceList>
            <Box className={classes.loggedInCountry}>
                <DisplayCountries value={value} />
                {uid && (
                    <OutlinedButton
                        smallPlus={true}
                        label={localized.loginHistoryButton}
                        startIcon={<Box component="span" className={CustomIcon.Details} />}
                        onClick={() => redirect(RouteUrl.PlayerManagement, RouteUrl.LoginHistory)}
                    />
                )}
            </Box>
        </WorkspaceList>
    );
};

const restrictedPermissions = agentReadPolicies;

export const LoggedInCountry = withMultiplePermission(LoggedInCountryInner, {restrictedPermissions});
