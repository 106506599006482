import {renderChip, renderDefaultChip} from '@components/chip/ChipRenderer';
import {ChipType, ChipVariant} from '@components/chip/types';
import {
    ColumnConfigurationColDef,
    FeatureColDef,
    gridCellDefaultFormatter,
    PermissionColDef,
    renderCellHeader,
    renderCellWithTooltip,
    renderGridCurrencyWithTooltip,
} from '@components/data-grid/mui';
import {DateRange, TransactionType, UserTransactionFilter} from '@models/generated/graphql';
import {formatTimestamp} from '@utils';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {FilterKey, Sorting} from 'src/common/types';
import {JurisdictionCurrency} from '../../../features/app/config/types';
import {PermissionEnum} from '../../../features/module-shared/types';
import {EditableColDef} from '../../../features/module-temp-storage/components/editable-components/types';
import {ModuleFilterDateRangeObsolete} from '../../../features/shared/filter/components/date-range-picker/FilterDateRangePickerObsolete';
import FilterTextField from '../../../features/shared/filter/components/FilterText';
import {Filter, FilterPlacement} from '../../../features/shared/filter/types';
import {getDateRangeFilterKey} from '../../../features/shared/filter/utils';
import {renderPaymentOptionChip} from '../../../features/transactions/components/TransactionPaymentOption';
import {TransactionStatusAction} from '../../../features/transactions/components/TransactionStatusAction';
import {localizedTransactionMessages} from '../../../features/transactions/localize';
import {TransactionColumnsConfiguration, TransactionGridItem} from '../../../features/transactions/types';

import {localizedTransactionHistory} from './TransactionHistory.localize';

const getGridColumns = (currency: JurisdictionCurrency) => {
    const columns: EditableColDef[] = [
        {
            field: nameof<TransactionGridItem>(u => u.id),
            hide: true,
        },
        {
            field: nameof<TransactionGridItem>(u => u.uid),
            renderHeader: () => renderCellHeader(localizedTransactionHistory.id),
            configurationColumns: [nameof<TransactionColumnsConfiguration>(u => u.withUid)],
        } as ColumnConfigurationColDef,
        {
            field: nameof<TransactionGridItem>(u => u.username),
            renderHeader: () => renderCellHeader(localizedTransactionHistory.userName),
            width: 200,
            configurationColumns: [nameof<TransactionColumnsConfiguration>(u => u.withUsername)],
        } as ColumnConfigurationColDef,
        {
            field: nameof<TransactionGridItem>(u => u.payment_vendor),
            renderHeader: () => renderCellHeader(localizedTransactionHistory.paymentVendor),
            renderCell: params => renderDefaultChip(params.value as string, ChipVariant.Disabled, ChipType.Mark),
            width: 200,
            configurationColumns: [nameof<TransactionColumnsConfiguration>(u => u.withPaymentVendor)],
        } as ColumnConfigurationColDef & FeatureColDef,
        {
            field: nameof<TransactionGridItem>(u => u.payment_option),
            renderHeader: () => renderCellHeader(localizedTransactionHistory.paymentOption),
            renderCell: renderPaymentOptionChip,
            width: 200,
            configurationColumns: [nameof<TransactionColumnsConfiguration>(u => u.withPaymentOption)],
        } as ColumnConfigurationColDef & FeatureColDef,
        {
            field: nameof<TransactionGridItem>(u => u.payment_method_name),
            renderHeader: () => renderCellHeader(localizedTransactionHistory.paymentMethod),
            renderCell: params => renderDefaultChip(params.value as string, ChipVariant.Green, ChipType.Mark),
            width: 200,
            configurationColumns: [nameof<TransactionColumnsConfiguration>(u => u.withPaymentMethodName)],
        } as ColumnConfigurationColDef & FeatureColDef,
        {
            field: nameof<TransactionGridItem>(u => u.payment_method_description),
            renderHeader: () => renderCellHeader(localizedTransactionHistory.paymentMethodDescription),
            valueFormatter: gridCellDefaultFormatter<TransactionGridItem>(t => t.payment_method_description),
            width: 250,
            configurationColumns: [nameof<TransactionColumnsConfiguration>(u => u.withPaymentMethodDescription)],
        } as ColumnConfigurationColDef & FeatureColDef,
        {
            field: nameof<TransactionGridItem>(u => u.transaction_id),
            renderHeader: () => renderCellHeader(localizedTransactionMessages.transactionId),
            width: 250,
            configurationColumns: [nameof<TransactionColumnsConfiguration>(u => u.withTransactionId)],
        } as ColumnConfigurationColDef,
        {
            field: nameof<TransactionGridItem>(u => u.type),
            renderHeader: () => renderCellHeader(localizedTransactionMessages.transactionType),
            renderCell: params => renderChip<TransactionType>(params.value as TransactionType, nameof<TransactionType>()),
            width: 200,
            configurationColumns: [nameof<TransactionColumnsConfiguration>(u => u.withType)],
        } as ColumnConfigurationColDef,
        {
            field: 'transactionStatus_ReadOnly',
            renderHeader: () => renderCellHeader(localizedTransactionHistory.transactionStatus),
            valueGetter: params => (params.row as TransactionGridItem)?.status_log ?? [],
            renderCell: TransactionStatusAction,
            width: 300,
            sortable: false,
            isEditable: false,
            configurationColumns: [
                nameof<TransactionColumnsConfiguration>(u => u.withType),
                nameof<TransactionColumnsConfiguration>(u => u.withStatusLog),
            ],
        } as FeatureColDef & ColumnConfigurationColDef,
        {
            field: nameof.full<TransactionGridItem>(u => u.transaction_started_ts.seconds),
            renderHeader: () => renderCellHeader(localizedTransactionMessages.transactionStartedDate),
            valueFormatter: gridCellDefaultFormatter<TransactionGridItem>(t => formatTimestamp(t.transaction_started_ts, 'date-time')),
            width: 210,
            sortField: nameof.full<TransactionGridItem>(u => u.transaction_started_ts.seconds),
            configurationColumns: [nameof<TransactionColumnsConfiguration>(u => u.withTransactionStartedTs)],
        } as ColumnConfigurationColDef & FeatureColDef,
        {
            field: nameof<TransactionGridItem>(u => u.amount),
            renderHeader: () => renderCellHeader(localizedTransactionMessages.transactionAmount),
            renderCell: params => renderGridCurrencyWithTooltip(params, currency),
            configurationColumns: [nameof<TransactionColumnsConfiguration>(u => u.withAmount)],
            sortField: nameof.full<TransactionGridItem>(u => u.amount),
        } as ColumnConfigurationColDef,
        {
            field: nameof<TransactionGridItem>(u => u.previous_balance),
            renderHeader: () => renderCellHeader(localizedTransactionMessages.previousBalance),
            renderCell: params => renderGridCurrencyWithTooltip(params, currency),
            width: 160,
            configurationColumns: [nameof<TransactionColumnsConfiguration>(u => u.withPreviousBalance)],
        } as ColumnConfigurationColDef,
        {
            field: nameof<TransactionGridItem>(u => u.current_balance),
            renderHeader: () => renderCellHeader(localizedTransactionMessages.currentBalance),
            renderCell: params => renderGridCurrencyWithTooltip(params, currency),
            configurationColumns: [nameof<TransactionColumnsConfiguration>(u => u.withCurrentBalance)],
        } as ColumnConfigurationColDef,
    ];

    const defaultCol: PermissionColDef = {
        field: '',
        width: 150,
        permissions: [PermissionEnum.Read],
        moduleName: ModuleName.PlayerManagement,
        submoduleName: SubmoduleName.TransactionHistory,
        renderCell: renderCellWithTooltip,
    };

    return columns.map(c => ({...defaultCol, ...c}));
};

const getFilters = () =>
    [
        {
            key: FilterKey.FullTextSearch,
            name: localizedTransactionHistory.userFilter,
            label: localizedTransactionHistory.userFilterLabel,
            component: FilterTextField,
            placement: FilterPlacement.Primary,
        },
        {
            key: [
                getDateRangeFilterKey(
                    nameof<UserTransactionFilter>(u => u.transaction_started_ts),
                    nameof<DateRange>(d => d.from)
                ),
                getDateRangeFilterKey(
                    nameof<UserTransactionFilter>(u => u.transaction_started_ts),
                    nameof<DateRange>(d => d.to)
                ),
            ],
            component: ModuleFilterDateRangeObsolete,
            placement: FilterPlacement.Secondary,
            options: nameof<UserTransactionFilter>(u => u.transaction_started_ts),
        },
    ] as Filter[];

const defaultSorting: Sorting<string>[] = [{field: nameof.full<TransactionGridItem>(u => u.transaction_started_ts.seconds), sort: 'desc'}];

export default {
    filters: getFilters(),
    getGridColumns: getGridColumns,
    localized: localizedTransactionHistory,
    defaultSorting,
};
