import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';

import {MarketingCode, MarketingCodeFilterInput, QueryGetMarketingCodesArgs} from '@models/generated/graphql';
import {Filter, MarketingCodeFilterKeys, MarketingCodeQueryFields} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';

import {DynamicConfig} from 'src/configuration';
import {JurisdictionConfigService} from 'src/features/app/config/services';
import {ServiceTypes} from '../inversify';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';

@injectable()
export class MarketingCodeService extends EntityBaseGqlService<
    QueryGetMarketingCodesArgs,
    MarketingCodeQueryFields,
    MarketingCodeFilterKeys
> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.JurisdictionConfigService) jurisdictionConfigService: JurisdictionConfigService,
        @inject(ServiceTypes.Config) config: DynamicConfig
    ) {
        super(client, mapper, new MarketingCodeRequestBuilder(jurisdictionConfigService, config));
    }
}

export class MarketingCodeRequestBuilder extends GqlRequestBuilder<
    QueryGetMarketingCodesArgs,
    MarketingCodeQueryFields,
    MarketingCodeFilterKeys
> {
    protected buildFilter(filter: Filter<MarketingCodeFilterKeys>): {filter: MarketingCodeFilterInput} {
        return {
            filter: {
                text: this.getGQLTextFilter([
                    this.toGQLTextFilter(
                        nameof.toArray<MarketingCode>(m => [m.marketing_code]),
                        filter.marketingCode as string
                    ),
                ]),
                type: this.toGQLMultiselectFilter(filter, 'type'),
            },
        };
    }

    private getCreatedAtQueryItems = (): MarketingCodeQueryFields[] => {
        return ['created_at.seconds'];
    };

    public buildQuery(fields: MarketingCodeQueryFields[]): DocumentNode {
        return gql`
            query getMarketingCodes($filter: MarketingCodeFilterInput, $sort: Sorting, $start: Int, $end: Int) {
                getMarketingCodes(filter: $filter, sort: $sort, end: $end, start: $start) {
                    items {
                        marketing_code @include(if: ${this.hasField(fields, 'marketing_code')})
                        type @include(if: ${this.hasField(fields, 'type')})
                        added_by_uid @include(if: ${this.hasField(fields, 'added_by_uid')})
                        created_at @include(if: ${this.hasAnyField(fields, this.getCreatedAtQueryItems())}) {
                            seconds ${this.hasQueryItem(fields, 'created_at.seconds')}
                        }
                    }
                    total_count
                }
            }
        `;
    }
}
