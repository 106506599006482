import React from 'react';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {IconButton} from '@components/button/Buttons';
import {CustomIcon} from '@components/icons/types';
import {agentReadPolicies} from '@models/permissions/permissions';

import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {GridModalActionProps, GridModalProps, withGridModal} from '../../data-grids/components/GridModalHoc';
import {ActionModalTitle} from '../../player-actions/common/ActionModalTitle';

import ActionHistoryGrid, {ActionHistoryGridProps} from './ActionHistoryGrid';

type ActionShowHistoryActionProps = {
    uid: string;
    options: ActionHistoryGridProps;
};

const useClasses = makeStyles()(() => ({
    styledActionHistoryGrid: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const localized = defineMessages({
    actionHistoryModalTitle: {
        id: 'AH_actionHistoryModalTitle',
        defaultMessage: 'Action History',
    },
});

const ShowHistoryActionButton = ({onClick}: GridModalActionProps): JSX.Element => {
    return <IconButton round iconClassName={CustomIcon.FileBox} onClick={onClick}></IconButton>;
};

const ActionHistoryModal = ({id, options}: GridModalProps<any, any, ActionHistoryGridProps>): JSX.Element => {
    const {classes} = useClasses();
    const {type, parent, actions, filterType} = options;

    return (
        <Box className={classes.styledActionHistoryGrid}>
            <ActionHistoryGrid id={id} type={type} parent={parent} actions={actions} filterType={filterType} />
        </Box>
    );
};

const ActionHistory = withGridModal(ShowHistoryActionButton, ActionHistoryModal);

const ActionHistoryModalActionInner = ({uid, options}: ActionShowHistoryActionProps) => {
    return (
        <ActionHistory
            title={<ActionModalTitle label={localized.actionHistoryModalTitle} />}
            id={uid}
            options={options}
            modalStyleParams={{maxWidth: 'lg'}}
        />
    );
};

export const ActionHistoryModalAction = withMultiplePermission(ActionHistoryModalActionInner, {
    restrictedPermissions: agentReadPolicies,
});
