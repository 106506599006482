import React, {useContext, useEffect, useState} from 'react';
import {Controller, useFormState, useWatch} from 'react-hook-form';
import {defineMessages, useIntl} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {EditMode} from '@components/button/EditButton';
import LocalizedText from '@components/i18n/LocalizedText';
import {RuleType, useValidationFormatter} from '@components/input';
import {ModalContext} from '@components/modal';
import {IAsyncAction} from '@redux';

import {useUserProfileDetails} from 'src/features/block-user-profile-details/hooks';
import {useReduxForm, useReduxFormRequestMode} from 'src/features/shared/form/hooks';
import {EditablePlayerIdAutocomplete} from 'src/features/view-autocomplete/components/PlayerIdAutocomplete';
import {UpdatePlayerIdRequestPayload} from '../actions';

import {AgentRevenueShareModal} from './AgentRevenueShareModal';

export type UserProfilePlayerIdFieldModel = {
    playerId: string;
    revenueShare: string;
};

const useClasses = makeStyles()(theme => ({
    editableAgentPlayerIdContainer: {
        width: '100%',
    },
    editableAgentPlayerIdNextButton: {
        height: theme.spacing(4),
    },
}));

const localized = defineMessages({
    agentPlayerIdFieldName: {
        id: 'EditableAgentPlayerId_agentPlayerIdFieldName',
        defaultMessage: 'Agent Player ID',
    },
    agentRevenueModalTitle: {
        id: 'EditableAgentPlayerId_agentRevenueModalTitle',
        defaultMessage: 'Revenue Sharing',
    },
    agentRevenueModalSubTitle: {
        id: 'EditableAgentPlayerId_agentRevenueModalSubTitle',
        defaultMessage: 'Please set revenue sharing for user with Player ID {playerId}',
    },
    agentRevenueModalSaveButtonLabel: {
        id: 'EditableAgentPlayerId_agentRevenueModalSaveButtonLabel',
        defaultMessage: 'Save',
    },
});

// TODO: [BO-2903] Remove updatePlayerIdAction prop and use single action for updating player id
type AgentPlayerIdProps = {
    playerId: string;
    boUserId: string;
    updatePlayerIdAction: IAsyncAction;
};

export function EditableAgentPlayerId({playerId, boUserId, updatePlayerIdAction}: AgentPlayerIdProps) {
    const {classes} = useClasses();
    const {formatMessage} = useIntl();
    const validationMessageFormatter = useValidationFormatter();
    const [currentId, setCurrentId] = useState(playerId);

    const [editMode, setEditMode] = useState<EditMode>('Read');
    const {item: user, requestStatus} = useUserProfileDetails({
        id: editMode === 'EditMultiStep' ? currentId : null,
        viewType: 'EditableAgentPlayerId',
        fields: ['uid', 'agent_info.default_agent_revenue_share_history'],
    });
    const playerRevenueShare = user?.agent_info?.default_agent_revenue_share_history;

    const currentFieldValue: UserProfilePlayerIdFieldModel = {playerId: currentId ?? '', revenueShare: playerRevenueShare ?? ''};
    const {control, state, setValue, submit, reset, trigger, handleSubmit} = useReduxForm<
        UserProfilePlayerIdFieldModel,
        UpdatePlayerIdRequestPayload
    >({
        initialModel: currentFieldValue,
        asyncAction: updatePlayerIdAction,
        map: m => {
            return {
                agentId: boUserId,
                revenueShare: m?.revenueShare,
                newPlayerId: m?.playerId,
                oldPlayerId: playerId,
            };
        },
        shouldHaveActionId: true,
    });
    const {errors} = useFormState({control});
    const watched = useWatch({control});

    const {openModal, closeModal} = useContext(ModalContext);
    const {requestMode, resetRequestMode} = useReduxFormRequestMode<UpdatePlayerIdRequestPayload>(
        updatePlayerIdAction,
        state.actionId,
        true
    );

    useEffect(() => {
        //TODO: Remove after changing 2 mutation calls to 1 for updating agent player id
        const isRequestFailed = state.errorMessage && requestMode === 'EditMultiStep';
        if (isRequestFailed) {
            handleRequestModeChange('Read');
            state.resetAsyncActionState();
        } else {
            handleRequestModeChange(requestMode);
        }
    }, [requestMode]);

    useEffect(() => {
        handlePlayersDataChange();
        setCurrentId(playerId);
    }, [playerId, boUserId]);

    useEffect(() => {
        setCurrentId(watched.playerId);
    }, [watched.playerId]);

    useEffect(() => {
        if (state.isError) {
            setCurrentId(playerId);
        }
    }, [state.isError]);

    function handleNextButtonClick() {
        trigger();
        if (watched.playerId) {
            openModal({
                body: <AgentRevenueShareModal playerRevenueShare={playerRevenueShare} onSave={handleFormSubmit} />,
                title: <LocalizedText label={localized.agentRevenueModalTitle} />,
                subtitle: <LocalizedText label={localized.agentRevenueModalSubTitle} labelParams={{playerId: watched.playerId}} />,
                onClose: handleModalClose,
            });
        }
    }

    function handleModalClose() {
        setEditMode('Read');
        resetRequestMode();
        resetForm();
        closeModal();
        setCurrentId(playerId);
    }

    function handleFormSubmit(value: string) {
        resetRequestMode();
        setValue('revenueShare', value);
        handleSubmit(submit)();
        closeModal();
    }

    function handleEditButtonModeChange(mode: EditMode) {
        if (editMode !== mode) {
            setEditMode(mode);

            if (mode === 'Read') {
                resetForm();
                closeModal();
                setCurrentId(playerId);
            }
        }
    }

    function handleRequestModeChange(mode: EditMode) {
        //sync internal status with request status
        if (editMode !== mode) {
            setEditMode(mode);

            if (mode === 'Read') {
                closeModal();
            }
        }
    }

    function handlePlayersDataChange() {
        resetForm();
    }

    function resetForm() {
        reset(currentFieldValue);
    }

    return (
        <Box className={classes.editableAgentPlayerIdContainer}>
            <form>
                <Controller
                    render={({field, fieldState}) => (
                        <EditablePlayerIdAutocomplete
                            mode={editMode}
                            value={field.value}
                            onValueChange={v => {
                                field.onChange(v);
                                trigger();
                                resetRequestMode();
                            }}
                            onSave={() => null}
                            onNextStep={handleNextButtonClick}
                            onEditModeChange={handleEditButtonModeChange}
                            placeholder={localized.agentPlayerIdFieldName}
                            errorMessage={
                                state.errorMessage
                                    ? formatMessage(state.errorMessage)
                                    : fieldState?.error?.message ?? errors?.playerId?.message
                            }
                            isMultiStep
                            loading={currentId && requestStatus === 'inProgress'}
                        />
                    )}
                    control={control}
                    name="playerId"
                    defaultValue={currentFieldValue?.playerId}
                    rules={{
                        required: validationMessageFormatter(RuleType.Required, localized.agentPlayerIdFieldName),
                    }}
                ></Controller>
                <Controller
                    control={control}
                    render={({field}) => <input hidden {...field}></input>}
                    name="revenueShare"
                    defaultValue={currentFieldValue?.revenueShare}
                ></Controller>
            </form>
        </Box>
    );
}
