import React from 'react';

import {PermissionEnum} from '@models/permissions/types';
import {usePolicies} from '@auth';

import {useJurisdictionFeature} from '../../features/app/config/hooks';
import {ModuleName, SubmoduleName} from '../routeEnums';
import {Route} from '../types';

type RotesContextProps = {
    routes: Route[];
};

export const RoutesContext = React.createContext<RotesContextProps>({routes: null});

type RoutesProviderProps = {
    children: JSX.Element | React.ReactNode;
    routes: readonly Route[];
};

export function RoutesProvider({children, routes}: RoutesProviderProps) {
    const permissions = [PermissionEnum.Create, PermissionEnum.Read, PermissionEnum.Update, PermissionEnum.Delete];
    const policies = usePolicies();

    const availableRoutes: Route[] = routes?.reduce((result, route) => {
        const availableSubRoutes = route?.subRoutes?.reduce((subRoutes, subRoute) => {
            const isSubRouteAvailable =
                subRoute?.resource?.rules?.validate(policies) &&
                useJurisdictionFeature({
                    moduleName: route?.name as ModuleName,
                    submoduleName: subRoute?.name as SubmoduleName,
                    featureName: subRoute?.featureName,
                    permissions,
                });
            if (isSubRouteAvailable) {
                subRoutes?.push(subRoute);
            }
            return subRoutes;
        }, [] as Route[]);

        const isRouteAvailable =
            route?.resource?.rules?.validate(policies) &&
            useJurisdictionFeature({
                moduleName: route?.name as ModuleName,
                submoduleName: null,
                featureName: route?.featureName,
                permissions,
            });
        if (isRouteAvailable || availableSubRoutes?.length) {
            result.push({...route, subRoutes: availableSubRoutes});
        }
        return result;
    }, [] as Route[]);

    return <RoutesContext.Provider value={{routes: availableRoutes}}>{children}</RoutesContext.Provider>;
}
