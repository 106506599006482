import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {BoUserPage} from '@models/bo-user';
import {map} from '@otel';
import {ApiRoutesService} from '@services/rest-api/apiRoutesService';
import {
    GetUsersRequestPayload,
    GetUsersTextFilter,
    GetUsersTextFilterKey,
    IUserManagementApiService,
} from '@services/rest-api/IUserManagementApiService';
import {ServiceResponsePayload} from '@services/types';

import {RestService} from './restService';

@injectable()
export class InternalUserApiService implements IUserManagementApiService {
    private readonly _restService: RestService;
    private readonly _apiRouteService: ApiRoutesService;
    private readonly _internalRequestBuilder: InternalUsersRequestBuilder;

    constructor(
        @inject(ServiceTypes.RestService) restService: RestService,
        @inject(ServiceTypes.ApiRoutesService) apiRouteService: ApiRoutesService
    ) {
        this._restService = restService;
        this._apiRouteService = apiRouteService;
        this._internalRequestBuilder = new InternalUsersRequestBuilder();
    }

    getUserPage(requestPayload: GetUsersRequestPayload): Observable<ServiceResponsePayload<GetUsersRequestPayload, BoUserPage>> {
        this._internalRequestBuilder.reset();
        this._internalRequestBuilder.buildFilter(requestPayload.textFilter);
        this._internalRequestBuilder.buildIdsFilter(requestPayload.ids);
        this._internalRequestBuilder.buildPagination(requestPayload.page, requestPayload.size);
        const query = this._internalRequestBuilder.getQuery();
        const usersEndpoint: string = this._apiRouteService?.getInternalUsersEndpoint();

        return this._restService.get({endpoint: usersEndpoint, query}).pipe(
            map(response => ({
                ...response,
                requestPayload,
                responsePayload: {items: response?.responsePayload?.response?.items, total: response?.responsePayload?.response?.count},
            }))
        );
    }
}

class InternalUsersRequestBuilder {
    private urlQuery: URLSearchParams;

    public getQuery(): string {
        return this.urlQuery.toString();
    }

    public reset() {
        this.urlQuery = new URLSearchParams();
    }

    buildPagination(page: number, size: number): void {
        if (page > 0) {
            this.urlQuery.set('page', (page - 1).toString());
        }
        if (size > 0) {
            this.urlQuery.set('size', size.toString());
        }
    }

    buildFilter(filters: GetUsersTextFilter[]): void {
        const filterKeyMap: Record<GetUsersTextFilterKey, InternalUsersFilterParams> = {
            em_fn_ln: null,
            email: 'email',
            firstName: 'firstName',
            lastName: 'lastName',
        };

        filters?.forEach(filter => {
            if (filter.value) {
                this.urlQuery.set(filterKeyMap[filter.key], filter.value);
            }
        });
    }

    buildIdsFilter(ids: string[]): void {
        if (ids?.length > 0) {
            const idKey: InternalUsersFilterParams = 'id';
            this.urlQuery.set(idKey, ids[0]);
        }
    }
}

type InternalUsersFilterParams = 'email' | 'firstName' | 'lastName' | 'id';
