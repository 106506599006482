import {useTheme} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme, PaletteColorOptionsKeys, SystemColorsEnum} from './theme';

export function useCustomTheme(): CustomTheme {
    const theme = useTheme<CustomTheme>();
    return theme;
}

const useSystemColorStyles = makeStyles()((theme: CustomTheme) => {
    const systemColors: Record<PaletteColorOptionsKeys, {color: string}> = {
        beige: {
            color: theme.custom.palette.status.beige.main,
        },
        blue: {
            color: theme.custom.palette.status.blue.main,
        },
        green: {
            color: theme.custom.palette.status.green.main,
        },
        grey: {
            color: theme.custom.palette.status.grey.main,
        },
        lime: {
            color: theme.custom.palette.status.lime.main,
        },
        orange: {
            color: theme.custom.palette.status.orange.main,
        },
        pink: {
            color: theme.custom.palette.status.pink.main,
        },
        purple: {
            color: theme.custom.palette.status.purple.main,
        },
        red: {
            color: theme.custom.palette.status.red.main,
        },
        transparent: {
            color: theme.custom.palette.status.transparent.main,
        },
        turquoise: {
            color: theme.custom.palette.status.turquoise.main,
        },
        yellow: {
            color: theme.custom.palette.status.yellow.main,
        },
        default: {
            color: theme.custom.palette.status.default.main,
        },
        disabled: {
            color: theme.custom.palette.status.disabled.main,
        },
        bronze: {
            color: theme.custom.palette.status.bronze.main,
        },
        lightGreen: {
            color: theme.custom.palette.status.lightGreen.main,
        },
        lightBlue: {
            color: theme.custom.palette.status.lightBlue.main,
        },
        lightGrey: {
            color: theme.custom.palette.status.lightGrey.main,
        },
        darkGrey: {
            color: theme.custom.palette.status.darkGrey.main,
        },
        greyInverted: {
            color: theme.custom.palette.status.greyInverted.main,
        },
        bronzeInverted: {
            color: theme.custom.palette.status.bronzeInverted.main,
        },
        dropdownDefault: {
            color: theme.custom.palette.status.dropdownDefault.main,
        },
        primaryButton: {
            color: theme.custom.palette.status.primaryButton.main,
        },
        coffee: {
            color: theme.custom.palette.status.coffee.main,
        },
        mint: {
            color: theme.custom.palette.status.mint.main,
        },
        yellowNeon: {
            color: theme.custom.palette.status.yellowNeon.main,
        },
        burgundy: {
            color: theme.custom.palette.status.burgundy.main,
        },
        skyBlue: {
            color: theme.custom.palette.status.skyBlue.main,
        },
        warmYellow: {
            color: theme.custom.palette.status.warmYellow.main,
        },
        violet: {
            color: theme.custom.palette.status.violet.main,
        },
        darkTeal: {
            color: theme.custom.palette.status.darkTeal.main,
        },
        white: {
            color: theme.custom.palette.status.white.main,
        },
        aliceBlue: {
            color: theme.custom.palette.status.aliceBlue.main,
        },
        navyBlue: {
            color: theme.custom.palette.status.navyBlue.main,
        },
        darkPink: {
            color: theme.custom.palette.status.darkPink.main,
        },
        darkViolet: {
            color: theme.custom.palette.status.darkViolet.main,
        },
    };

    return systemColors;
});

export function useSystemColors(): Record<SystemColorsEnum, string> {
    const {classes} = useSystemColorStyles();
    const colorClasses: Record<SystemColorsEnum, string> = {
        [SystemColorsEnum.LightBlue]: classes.lightBlue,
        [SystemColorsEnum.Bronze]: classes.bronze,
        [SystemColorsEnum.LightGreen]: classes.lightGreen,
        [SystemColorsEnum.Orange]: classes.orange,
        [SystemColorsEnum.LightGrey]: classes.lightGrey,
        [SystemColorsEnum.DarkGrey]: classes.darkGrey,
        [SystemColorsEnum.Violet]: classes.violet,
        [SystemColorsEnum.Coffee]: classes.coffee,
        [SystemColorsEnum.Beige]: classes.beige,
        [SystemColorsEnum.Mint]: classes.mint,
        [SystemColorsEnum.YellowNeon]: classes.yellowNeon,
        [SystemColorsEnum.Burgundy]: classes.burgundy,
        [SystemColorsEnum.SkyBlue]: classes.skyBlue,
        [SystemColorsEnum.Pink]: classes.pink,
        [SystemColorsEnum.WarmYellow]: classes.warmYellow,
        [SystemColorsEnum.White]: classes.white,
        [SystemColorsEnum.Emerald]: classes.green,
        [SystemColorsEnum.DarkTeal]: classes.darkTeal,
        [SystemColorsEnum.Red]: classes.red,
        [SystemColorsEnum.AliceBlue]: classes.aliceBlue,
        [SystemColorsEnum.NavyBlue]: classes.navyBlue,
        [SystemColorsEnum.Green]: classes.green,
        [SystemColorsEnum.DarkPink]: classes.darkPink,
        [SystemColorsEnum.DarkViolet]: classes.darkViolet,
    };

    return colorClasses;
}
