import React, {useEffect, useRef} from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box, MenuList} from '@mui/material';
import Popover, {PopoverOrigin} from '@mui/material/Popover';

import LocalizedText from '@components/i18n/LocalizedText';
import {sharedLocalization} from '@localization';

import Button from '../button/Buttons';

import {useDropdownClasses, useDropdownStyle} from './DropdownHoc.style';

//TODO: [BO-2669] Move dropdown components to input folder (?)
export enum DropdownType {
    Separated = 'Separated',
    Attached = 'Attached',
}

export type DropdownStyles = {
    dropdownContainer?: string;
    dropdownRoot?: string;
    dropdownPaper?: string;
    dropdownButton?: string;
    dropdownButtonOpen?: string;
    dropdownActions?: string;
};

export type DropdownProps = {
    children: React.ReactNode;
    listTitle?: string | MessageDescriptor;
    buttonContent?: JSX.Element | string;
    container?: Element | (() => Element);
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    closeOnClickInside?: boolean;
    closeOnClickOutside?: boolean;
    isApplyDisabled?: boolean;
    applyAction?: boolean | MessageDescriptor;
    applyActionContent?: React.ReactNode;
    cancelAction?: boolean | MessageDescriptor;
    actionsFullWidth?: boolean;
    onApplyClick?: () => void;
    onCancelClick?: () => void;
    onOutsideClick?: () => void;
    onDropdownOpen?: () => void;
    onDropdownClose?: () => void;
    styles?: DropdownStyles;
    hasMenuList?: boolean;
    dropdownType?: DropdownType;
    className?: string;
    fitDropdownToContent?: boolean;
    dropdownListNoMinWidth?: boolean;
    reverseActionsOrder?: boolean;
};

export const withDropdown =
    <TProps extends object>(WrappedComponent: React.ComponentType<TProps>) =>
    (props: DropdownProps & TProps) => {
        const {classes, cx} = useDropdownClasses();
        const {
            buttonContent,
            container,
            anchorOrigin,
            transformOrigin,
            listTitle,
            children,
            closeOnClickInside,
            closeOnClickOutside,
            applyAction,
            applyActionContent,
            actionsFullWidth = true,
            isApplyDisabled,
            cancelAction,
            onApplyClick,
            onCancelClick,
            onOutsideClick,
            onDropdownOpen,
            onDropdownClose,
            styles,
            hasMenuList = true,
            dropdownType = DropdownType.Separated,
            className,
            fitDropdownToContent = false,
            dropdownListNoMinWidth = false,
            reverseActionsOrder = false,
            ...otherProps
        } = props;

        const containerRef = useRef<HTMLDivElement>(null);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const defaultDropdownStyles = useDropdownStyle(dropdownType);
        const computedDropdownStyles = {...defaultDropdownStyles, ...styles};

        const handleDropdownActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            stopEvent(event);
        };

        const stopEvent = (e: React.BaseSyntheticEvent) => {
            (e.nativeEvent as Event).stopImmediatePropagation();
            e.stopPropagation();
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleClickInside = () => {
            if (closeOnClickInside !== false) {
                handleClose();
            }
        };

        useEffect(() => {
            if (onDropdownOpen && onDropdownClose) {
                if (open) {
                    onDropdownOpen();
                } else {
                    onDropdownClose();
                }
            }
        }, [open]);

        const handleApplyClick = () => {
            handleClose();

            if (onApplyClick) {
                onApplyClick();
            }
        };

        const handleCancelClick = () => {
            handleClose();

            if (onCancelClick) {
                onCancelClick();
            }
        };

        const handleClickOutside = (event: React.MouseEvent<HTMLButtonElement>) => {
            if (closeOnClickOutside !== false) {
                handleClose();
            }

            if (onOutsideClick) {
                onOutsideClick();
            }
            stopEvent(event);
        };

        const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
            stopEvent(event);
        };

        const cancleActionNode = cancelAction ? (
            <Button
                data-testid="cancelAction"
                onClick={handleCancelClick}
                className={cx(classes.dropdownAction, {
                    [classes.dropdownActionUnsetWidth]: !actionsFullWidth,
                })}
            >
                <LocalizedText label={cancelAction === true ? sharedLocalization.cancel : cancelAction}></LocalizedText>
            </Button>
        ) : (
            <></>
        );

        const applyActionNode = applyAction ? (
            <Button
                data-testid="applyAction"
                color="primary"
                onClick={handleApplyClick}
                className={cx(classes.dropdownAction, {
                    [classes.dropdownActionUnsetWidth]: !actionsFullWidth,
                })}
                disabled={isApplyDisabled}
                label={applyAction === true ? sharedLocalization.apply : applyAction}
            >
                {applyActionContent}
            </Button>
        ) : (
            <></>
        );

        return (
            <>
                <div ref={containerRef}>
                    <WrappedComponent
                        {...(otherProps as TProps)}
                        onClick={handleDropdownActionClick}
                        className={cx(
                            computedDropdownStyles?.dropdownButton,
                            {
                                [computedDropdownStyles?.dropdownButtonOpen]: open,
                            },
                            className
                        )}
                    >
                        {buttonContent}
                    </WrappedComponent>
                </div>
                <Popover
                    data-testid="dropdownPopover"
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={
                        anchorOrigin ?? {
                            vertical: 'bottom',
                            horizontal: 'right',
                        }
                    }
                    transformOrigin={
                        transformOrigin ?? {
                            vertical: 'top',
                            horizontal: 'right',
                        }
                    }
                    onClose={handleClickOutside}
                    disableRestoreFocus
                    classes={{root: computedDropdownStyles?.dropdownRoot, paper: computedDropdownStyles?.dropdownPaper}}
                    container={container === undefined ? containerRef.current : container}
                    onClick={handleClick}
                >
                    {hasMenuList ? (
                        <MenuList
                            className={cx(
                                dropdownListNoMinWidth ? classes.dropdownListNoMinWidth : '',
                                fitDropdownToContent ? classes.dropdownListNoPadding : '',
                                classes.dropdownList,
                                computedDropdownStyles?.dropdownContainer
                            )}
                        >
                            {listTitle ? (
                                <Box className={classes.dropdownListTitle}>
                                    <LocalizedText label={listTitle} />
                                </Box>
                            ) : null}
                            <div onClick={handleClickInside}>{children}</div>
                            {applyAction || cancelAction ? (
                                <Box className={cx(classes.dropdownActions, computedDropdownStyles?.dropdownActions)}>
                                    {reverseActionsOrder ? [applyActionNode, cancleActionNode] : [cancleActionNode, applyActionNode]}
                                </Box>
                            ) : null}
                        </MenuList>
                    ) : (
                        <div className={classes.dropdownContent} onClick={handleClickInside}>
                            {children}
                        </div>
                    )}
                </Popover>
            </>
        );
    };
