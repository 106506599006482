import React from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';

import {useAgentLevelReportDownload} from '../hooks';
import {AgentLevelReportViewModelKeys} from '../types';

const localized = defineMessages({
    buttonLabel: {
        id: 'AgentReportingDownloadCsvButton_label',
        defaultMessage: 'Download CSV',
    },
});

const useStyles = makeStyles()(theme => ({
    agentReportingDownloadCsvButton: {
        borderRadius: theme.shape.borderRadius * 2,
    },
}));

type AgentLevelReportDownloadButtonProps = {
    filterString: string;
    keys: AgentLevelReportViewModelKeys[];
    filename: string;
};

export function AgentLevelReportDownloadButton(props: AgentLevelReportDownloadButtonProps) {
    const {classes} = useStyles();
    const {handleClick, isProgress} = useAgentLevelReportDownload(props);

    return (
        <Button
            label={localized.buttonLabel}
            onClick={handleClick}
            loading={isProgress}
            className={classes.agentReportingDownloadCsvButton}
        />
    );
}
