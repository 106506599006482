import React from 'react';
import {GridSortModel} from '@mui/x-data-grid';

import {Sorting} from '../../../types';

import MuiDataGrid, {GridPageChangeParams, GridSortModelParams, MuiDataGridProps} from './MuiDataGrid';
import {GridColDef} from './types';

export type MuiDataGridServerProps = Omit<
    MuiDataGridProps,
    | 'onPageChange'
    | 'onPageSizeChange'
    | 'onSortModelChange'
    | 'sortModel'
    | 'sortingMode'
    | 'paginationMode'
    | 'page'
    | 'pageSize'
    | 'rowCount'
> & {
    onPageChange: (page: number) => void;
    onPageSizeChange: (pageSize: number) => void;
    onSortModelChange: (sortModel: Sorting<string>[]) => void;
    sortModel: Sorting<string>[];
    page: number;
    pageSize: number;
    rowCount: number;
};

const withDataGridServer = (WrappedDataGrid: (props: MuiDataGridProps) => JSX.Element) => (props: MuiDataGridServerProps) => {
    const {onPageChange, onPageSizeChange, onSortModelChange, sortModel, columns} = props;

    const handlePageChange = (p: GridPageChangeParams) => {
        if (onPageChange) {
            onPageChange(p.page);
        }
    };

    const handlePageSizeChange = (p: GridPageChangeParams) => {
        if (onPageSizeChange) {
            onPageSizeChange(p.pageSize);
        }
    };

    const handleSortModelChange = (p: GridSortModelParams) => {
        if (onSortModelChange) {
            onSortModelChange(transformClientToServerSorting(p.sortModel, columns));
        }
    };

    const transformServerToClientSorting = (sortModel: Sorting<string>[], columns: GridColDef[]): GridSortModel => {
        return sortModel.map(s => ({
            field: columns.find(c => s.field === c?.sortField)?.field ?? s.field,
            sort: s.sort as 'asc' | 'desc' | undefined | null,
        }));
    };

    const transformClientToServerSorting = (sortModel: GridSortModel, columns: GridColDef[]): Sorting<string>[] => {
        return sortModel.map(s => ({
            field: columns.find(c => s.field === c.field)?.sortField ?? s.field,
            sort: s.sort,
        }));
    };

    return (
        <WrappedDataGrid
            {...props}
            sortModel={transformServerToClientSorting(sortModel, columns)}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onSortModelChange={handleSortModelChange}
            sortingMode="server"
            paginationMode="server"
        />
    );
};

export const MuiDataGridServer = withDataGridServer(MuiDataGrid);
