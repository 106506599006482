import {createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {SecurityCase} from '@models/generated/graphql';

import {SecurityCaseViewModel} from './types';

export class SecurityCasesMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            SecurityCase,
            SecurityCaseViewModel,
            forMember(
                vm => vm.case_id,
                mapFrom(m => m.case_id)
            ),
            forMember(
                vm => vm.type,
                mapFrom(m => m.type)
            ),
            forMember(
                vm => vm.closed_at,
                mapFrom(m => m.closed_at)
            ),
            forMember(
                vm => vm.opened_at,
                mapFrom(m => m.opened_at)
            )
        );
    }
}
