import {normalize, NormalizedSchema, schema} from 'normalizr';
import {from, GroupedObservable, merge, Observable, of} from 'rxjs';
import {bufferTime, filter, groupBy, toArray} from 'rxjs/operators';
import {Action, isActionOf, PayloadAction} from 'typesafe-actions';

import {BaseEpicsBuilder} from '@redux';

import {getErrorByFailureAction} from '../../../features/app/intl/shared-resources/serverResponse';
import {showErrorAction} from '../../../features/message-snack-bar/actions';

import {
    EntityFetchRequestPayload,
    EntityFetchResponsePayload,
    EntityFetchServiceResponsePayload,
    EntityType,
    fetchBufferTimeSpan,
} from './types/base';
import {
    entityActions,
    EntityCleanActionPayload,
    EntityFetchActionPayload,
    EntityFetchRequestActionPayload,
    EntitySaveActionPayload,
} from './actions';
import {EntityKeysMapper, schemaMapper} from './schemas';
import Entity = schema.Entity;
import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';

import {ServiceTypes} from '@inversify';
import {map, mergeMap} from '@otel';
import {RootEpic} from '@redux';
import {viewActions} from '@redux/view';
import {IEntityReadService} from '@services/entity';
import {CustomErrorCodes} from '@services/types';

@injectable()
export class EntityEpicsBuilder extends BaseEpicsBuilder {
    private readonly _serviceFactory: (entityType: EntityType) => IEntityReadService;

    constructor(@inject(ServiceTypes.EntityReadServiceFactory) serviceFactory: (entityType: EntityType) => IEntityReadService) {
        super();
        this._serviceFactory = serviceFactory;
    }

    protected buildEpicList(): RootEpic[] {
        return [
            this.buildFetchEpic(),
            ...this.buildFetchRequestEpics(),
            this.buildFetchRequestSuccessEpic(),
            this.buildFetchRequestFailureEpic(),
            this.buildCollectGarbageEpic(),
        ];
    }

    public buildFetchEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(entityActions.fetch)),
                bufferTime(fetchBufferTimeSpan),
                mergeMap((actions: PayloadAction<string, EntityFetchActionPayload>[]) =>
                    from(actions).pipe(
                        map((action: PayloadAction<string, EntityFetchActionPayload>) => action.payload),
                        groupBy((p: EntityFetchActionPayload) => p.type),
                        mergeMap((entityGroup$: GroupedObservable<EntityType, EntityFetchActionPayload>) =>
                            entityGroup$.pipe(
                                groupBy((p: EntityFetchActionPayload) => p.filter),
                                mergeMap((filterGroup$: GroupedObservable<string, EntityFetchActionPayload>) =>
                                    filterGroup$.pipe(toArray())
                                )
                            )
                        ),
                        mergeMap((payloadGroup: EntityFetchActionPayload[]) => {
                            let result: Observable<unknown> = of();
                            if (payloadGroup.length) {
                                const joinedFields: string[] = payloadGroup.map((p: EntityFetchActionPayload) => p.fields).flat();
                                const uniqueFields: string[] = [...new Set(joinedFields)];
                                if (uniqueFields.length > 0) {
                                    result = of(
                                        entityActions.fetchRequest.request({
                                            type: payloadGroup[0].type,
                                            filter: payloadGroup[0].filter,
                                            fields: uniqueFields,
                                        })
                                    );
                                }
                            }
                            return result;
                        })
                    )
                )
            );
    }

    public buildFetchRequestEpics(): Epic[] {
        const sendRequestEpic = this.buildRequestEpic<EntityFetchRequestPayload, EntityFetchRequestPayload, EntityFetchResponsePayload>(
            entityActions.fetchRequest,
            payload => this._serviceFactory(payload.type).get(payload),
            false
        );

        const updateViewStatusEpic: Epic = action$ =>
            action$.pipe(
                filter(isActionOf(entityActions.fetchRequest.request)),
                map((action: PayloadAction<string, EntityFetchRequestActionPayload>) =>
                    entityActions.fetchStatus({
                        requestStatus: 'inProgress',
                        filter: action.payload.filter,
                        entityType: action.payload.type,
                    })
                )
            );

        return [sendRequestEpic, updateViewStatusEpic];
    }

    public buildFetchRequestSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(entityActions.fetchRequest.success)),
                mergeMap((action: PayloadAction<string, EntityFetchServiceResponsePayload>) => {
                    const {items, total} = action.payload.responsePayload;
                    let result: Observable<unknown>;
                    if (items?.length) {
                        const entityType: EntityType = action.payload.requestPayload.type;
                        const schema: Entity = schemaMapper.getSchema(entityType);
                        const normalizedItems: NormalizedSchema<Record<EntityType, Record<string, unknown>>, string[]> = normalize<
                            Entity,
                            Record<EntityType, Record<string, unknown>>,
                            string[]
                        >(items, [schema]);
                        const entities: Partial<Record<EntityType, Record<string, unknown>>> = normalizedItems.entities;
                        const saveEntityActions: PayloadAction<string, EntitySaveActionPayload>[] = Object.entries(entities).map(
                            ([type, items]: [EntityType, Record<string, unknown>]) => entityActions.save({type, items})
                        );
                        result = merge(
                            of(...saveEntityActions),
                            of(
                                viewActions.updateKeys({
                                    entity: action.payload.requestPayload.type,
                                    keys: normalizedItems.result,
                                    filter: action.payload.requestPayload.filter,
                                    total,
                                })
                            ),
                            of(
                                entityActions.fetchStatus({
                                    requestStatus: 'idle',
                                    entityType: action.payload.requestPayload.type,
                                    filter: action.payload.requestPayload.filter,
                                })
                            )
                        );
                    } else {
                        result = merge(
                            of(
                                viewActions.updateKeys({
                                    entity: action.payload.requestPayload.type,
                                    keys: [],
                                    filter: action.payload.requestPayload.filter,
                                    total,
                                })
                            ),
                            of(
                                entityActions.fetchStatus({
                                    requestStatus: 'idle',
                                    entityType: action.payload.requestPayload.type,
                                    filter: action.payload.requestPayload.filter,
                                })
                            )
                        );
                    }

                    return result;
                })
            );
    }

    public buildFetchRequestFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(entityActions.fetchRequest.failure)),
                mergeMap((action: PayloadAction<string, EntityFetchServiceResponsePayload>) =>
                    //TODO: dispatch action to ViewEpics to show View display name
                    of(
                        showErrorAction({
                            message: getErrorByFailureAction(action.type, CustomErrorCodes.FetchRequestFailure),
                            values: {type: action.payload.requestPayload.type},
                        }),
                        entityActions.fetchStatus({
                            requestStatus: 'idle',
                            filter: action.payload.requestPayload.filter,
                            entityType: action.payload.requestPayload.type,
                        })
                    )
                )
            );
    }

    public buildCollectGarbageEpic(): RootEpic {
        const collectGarbageDelay = 120000;
        return (action$, state$) =>
            action$.pipe(
                //cleaning entity state triggered in 2 mins after first save data to state or after previous garbage collection
                filter(isActionOf([entityActions.save, entityActions.collectGarbage])),
                bufferTime(collectGarbageDelay),
                filter(actions => actions.length > 0),
                mergeMap(() => {
                    const resultActions: (PayloadAction<string, any> | Action)[] = [];
                    const state = state$.value;
                    const mapper = new EntityKeysMapper(schemaMapper);
                    const referencesToClean = Object.entries(state.entities.references)
                        .filter(([_, count]) => count === 0)
                        .map(i => i[0]);

                    if (referencesToClean.length > 0) {
                        const keysToRemove = Object.entries(
                            referencesToClean
                                .map(key => mapper.mapToObjectKey(key))
                                .reduce((store: Partial<Record<EntityType, string[]>>, {type, id}) => {
                                    if (!store[type]) {
                                        store[type] = [];
                                    }

                                    if (!store[type].includes(id)) {
                                        store[type].push(id);
                                    }
                                    return store;
                                }, {})
                        ).map(([type, ids]) => ({type: type as EntityType, ids} as EntityCleanActionPayload));

                        resultActions.push(entityActions.cleanReferenceCounter({references: referencesToClean}));
                        resultActions.push(...keysToRemove.map(payload => entityActions.clean(payload)));
                    }

                    resultActions.push(entityActions.collectGarbage());

                    return of(...resultActions);
                })
            );
    }
}
