import {useAutoMapper} from '@auto-mapper';
import {Transaction, TransactionType} from '@models/generated/graphql';
import {TransactionViewModel, TransactionViewModelKeys} from '@models/transaction';
import {EntityType, TransactionFilterKeys, TransactionQueryFields} from '@redux/entity';
import {RealtimeMessageTrigger} from '@redux/realtime';
import {getNonEmptyValueValidator, UseDetailsViewEntityProps, UseDetailsViewEntityResult, useViewInit, ViewType} from '@redux/view';
import {updateKeyValueFilterArray} from '@utils';

import {Filter} from '../../common/types';
import {useTransactions} from '../block-transaction-list';

export function useTransactionDetails({
    id,
    fields,
    viewType,
    displayName,
    cleanDelay = 0,
    realtimeMode,
    defaultFilters,
    validateFilter,
}: UseDetailsViewEntityProps<TransactionFilterKeys, TransactionViewModelKeys>): UseDetailsViewEntityResult<
    TransactionViewModel,
    TransactionViewModelKeys
> {
    const mapper = useAutoMapper();
    const queryFields: TransactionQueryFields[] = mapper?.map<TransactionViewModelKeys[], TransactionQueryFields[]>(
        fields,
        nameof<TransactionViewModelKeys>(),
        nameof<TransactionQueryFields>()
    );

    const defaultFilter: Filter<unknown, TransactionFilterKeys>[] = updateKeyValueFilterArray(
        [{key: 'transactionId', value: id ?? null}],
        ...(defaultFilters ?? [])
    );
    const {
        items,
        searchFilter,
        viewEntity: {filter: filterString},
        handleFilterChange,
    } = useViewInit<Transaction, TransactionFilterKeys, TransactionQueryFields>({
        viewType,
        entity: {
            entity: EntityType.Transaction,
            fields: queryFields,
        },
        displayName,
        realtime: realtimeMode ? {entity: EntityType.Transaction, mode: realtimeMode, triggers: [RealtimeMessageTrigger.Update]} : null,
        defaultFilters: defaultFilter,
        defaultPaging: {page: 1, pageSize: 1},
        validateFilter: validateFilter ?? getNonEmptyValueValidator<TransactionFilterKeys>('transactionId'),
        cleanDelay,
    });

    const transactionItem: Transaction = items?.length ? items[0] : null;
    const item: TransactionViewModel = mapper?.map(transactionItem, Transaction, TransactionViewModel);

    return {item, filterString, searchFilter, handleFilterChange};
}

type UseLastTransactionProps = {
    uid: string;
    viewType: ViewType;
    type: TransactionType;
};

export function useLastTransaction({uid, viewType, type}: UseLastTransactionProps): TransactionViewModel {
    const {items} = useTransactions({
        viewType,
        fields: ['amount', 'currency', 'transaction_started_ts', 'transaction_id'],
        defaultFilters: [
            {key: 'uid', value: uid},
            {key: 'transactionTypes', value: type},
        ],
        defaultPaging: {
            page: 1,
            pageSize: 1,
        },
        defaultSorting: 'started',
        validateFilter: () => true,
    });
    return items?.length ? items[0] : null;
}
