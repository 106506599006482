import {constructUsing, createMap, extend, forMember, mapFrom, Mapper, mapWith, mapWithArguments} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {CountryInfoViewModel} from '@models/country';
import {
    AccountVerification,
    AccountVerificationStatusLog,
    CountryInfo,
    JumioLocation,
    KycDocument,
    Timestamp as GqlTimestamp,
    UserProfile,
} from '@models/generated/graphql';
import {AccountVerificationMock} from '@models/mock/accountVerification';
import {Timestamp} from '@models/shared/types';
import {AccountVerificationQueryFields, AccountVerificationSortingFields} from '@redux/entity';

import {
    AccountVerificationDurationViewModel,
    AccountVerificationStatusLogViewModel,
    AccountVerificationViewModel,
    AccountVerificationViewModelKeys,
    JumioLocationViewModel,
    KycDocumentViewModel,
} from './types';

export type AccountVerificationMappingExtraArgs = {
    user: Pick<UserProfile, 'uid' | 'address'>;
};

export class AccountVerificationMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            CountryInfo,
            CountryInfoViewModel,
            forMember(
                vm => vm.name,
                mapFrom(m => m.name)
            ),
            forMember(
                vm => vm.isoAlpha2Code,
                mapFrom(m => m.iso_alpha2_code)
            )
        );
        createMap(
            mapper,
            JumioLocation,
            JumioLocationViewModel,
            forMember(
                vm => vm.city,
                mapFrom(m => m.city)
            ),
            forMember(
                vm => vm.country,
                mapFrom(m => m.country)
            ),
            forMember(
                vm => vm.formatted,
                mapFrom(m => m.formatted)
            ),
            forMember(
                vm => vm.line1,
                mapFrom(m => m.line1)
            ),
            forMember(
                vm => vm.line2,
                mapFrom(m => m.line2)
            ),
            forMember(
                vm => vm.postal_code,
                mapFrom(m => m.postal_code)
            ),
            forMember(
                vm => vm.subdivision,
                mapFrom(m => m.subdivision)
            )
        );
        createMap(
            mapper,
            KycDocument,
            KycDocumentViewModel,
            forMember(
                vm => vm.client_ip,
                mapFrom(m => m.client_ip)
            ),
            forMember(
                vm => vm.country_info,
                mapWith(CountryInfoViewModel, CountryInfo, m => m.country_info)
            ),
            forMember(
                vm => vm.device_id,
                mapFrom(m => m.device_id)
            ),
            forMember(
                vm => vm.device_verified,
                mapFrom(m => m.device_verified)
            ),
            forMember(
                vm => vm.did,
                mapFrom(m => m.did)
            ),
            forMember(
                vm => vm.dob,
                mapFrom(m => m.dob)
            ),
            forMember(
                vm => vm.doc_id_sub_type,
                mapFrom(m => m.doc_id_sub_type)
            ),
            forMember(
                vm => vm.doc_ref_id,
                mapFrom(m => m.doc_ref_id)
            ),
            forMember(
                vm => vm.doc_type,
                mapFrom(m => m.doc_type)
            ),
            forMember(
                vm => vm.first_name,
                mapFrom(m => m.first_name)
            ),
            forMember(
                vm => vm.gender,
                mapFrom(m => m.gender)
            ),
            forMember(
                vm => vm.geolocation_verified,
                mapFrom(m => m.geolocation_verified)
            ),
            forMember(
                vm => vm.ip_verified,
                mapFrom(m => m.ip_verified)
            ),
            forMember(
                vm => vm.last_name,
                mapFrom(m => m.last_name)
            ),
            forMember(
                vm => vm.location,
                mapWith(JumioLocationViewModel, JumioLocation, m => m.location)
            ),
            forMember(
                vm => vm.number,
                mapFrom(m => m.number)
            ),
            forMember(
                vm => vm.status,
                mapFrom(m => m.status)
            ),
            forMember(
                vm => vm.uploaded_ts,
                mapWith(Timestamp, GqlTimestamp, m => m.uploaded_ts)
            )
        );
        createMap(
            mapper,
            AccountVerificationStatusLog,
            AccountVerificationStatusLogViewModel,
            forMember(
                vm => vm.account_verification_status,
                mapFrom(m => m.account_verification_status)
            ),
            forMember(
                vm => vm.kyc_status,
                mapFrom(m => m.kyc_status)
            ),
            forMember(
                vm => vm.logged_at,
                mapFrom(m => m.logged_at)
            )
        );
        createMap(
            mapper,
            AccountVerification,
            AccountVerificationDurationViewModel,
            forMember(
                vm => vm.history,
                mapWith(AccountVerificationStatusLogViewModel, AccountVerificationStatusLog, m => m.status_log)
            ),
            forMember(
                vm => vm.initiated_at,
                mapFrom(m => m.initiated_at)
            )
        );
        createMap(
            mapper,
            AccountVerification,
            AccountVerificationViewModel,
            forMember(
                vm => vm.account_verification_status,
                mapFrom(m => m.account_verification_status)
            ),
            forMember(
                vm => vm.documents,
                mapWith(KycDocumentViewModel, KycDocument, m => m.documents)
            ),
            forMember(
                vm => vm.documents_with_link,
                mapWith(KycDocumentViewModel, KycDocument, m => m.documents)
            ),
            forMember(
                vm => vm.email,
                mapFrom(m => m.email)
            ),
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.id_ndrp,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.serverId,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.initiated_at,
                mapWith(Timestamp, GqlTimestamp, m => m.initiated_at)
            ),
            forMember(
                vm => vm.iso_alpha2_country_code,
                mapFrom(m => m.iso_alpha2_country_code)
            ),
            forMember(
                vm => vm.kyc_case_id,
                mapFrom(m => m.kyc_case_id)
            ),
            forMember(
                vm => vm.status_log,
                mapWith(AccountVerificationStatusLogViewModel, AccountVerificationStatusLog, m => m.status_log)
            ),
            forMember(
                vm => vm.type,
                mapFrom(m => m.type)
            ),
            forMember(
                vm => vm.uid,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.username,
                mapFrom(m => m.username)
            ),
            forMember(
                vm => vm.player_country,
                mapWithArguments((_, {user}: AccountVerificationMappingExtraArgs) => {
                    return user?.address?.country_info?.iso_alpha2_code ?? null;
                })
            ),
            forMember(
                vm => vm.duration,
                mapWith(AccountVerificationDurationViewModel, AccountVerification, m => m)
            ),
            forMember(
                vm => vm.init_reason_codes,
                mapFrom(m => m.init_reason_codes)
            ),
            forMember(
                vm => vm.labels,
                mapFrom(m => m.user_labels)
            )
        );
        createMap(
            mapper,
            AccountVerificationMock,
            AccountVerificationViewModel,
            extend(AccountVerification, AccountVerificationViewModel),
            forMember(
                vm => vm.documents_object.id_card,
                mapWith(KycDocumentViewModel, KycDocument, m => m.documents_object?.id_card)
            )
        );
        //TODO: change mapping from array-array to object-object
        createMap<AccountVerificationViewModelKeys[], AccountVerificationQueryFields[]>(
            mapper,
            nameof<AccountVerificationViewModelKeys>(),
            nameof<AccountVerificationQueryFields>(),
            constructUsing((m, _) => {
                const mapper: Record<AccountVerificationViewModelKeys, AccountVerificationQueryFields[]> = {
                    account_verification_status: ['account_verification_status'],
                    documents: [
                        'documents.doc_ref_id',
                        'documents.doc_type',
                        'documents.status',
                        'documents.uploaded_ts.seconds',
                        'documents.country_info.name',
                        'documents.country_info.iso_alpha2_code',
                        'documents.device_id',
                    ],
                    documents_with_link: [
                        'documents.doc_ref_id',
                        'documents.doc_type',
                        'documents.status',
                        'documents.uploaded_ts.seconds',
                        'documents.country_info.name',
                        'documents.country_info.iso_alpha2_code',
                        'documents.device_id',
                    ],
                    email: ['email'],
                    id: ['id'],
                    id_ndrp: ['id'],
                    'initiated_at.seconds': ['initiated_at.seconds'],
                    iso_alpha2_country_code: ['iso_alpha2_country_code'],
                    kyc_case_id: ['kyc_case_id'],
                    status_log: ['status_log.kyc_status', 'status_log.account_verification_status', 'status_log.logged_at.seconds'],
                    type: ['type'],
                    uid: ['uid'],
                    username: ['username'],
                    player_country: [],
                    duration: [
                        'status_log.kyc_status',
                        'status_log.account_verification_status',
                        'status_log.logged_at.seconds',
                        'initiated_at.seconds',
                    ],
                    serverId: ['id'],
                    init_reason_codes: ['init_reason_codes'],
                    labels: [
                        'user_labels.group.color',
                        'user_labels.group.id',
                        'user_labels.group.name',
                        'user_labels.group.source_type',
                        'user_labels.id',
                        'user_labels.name',
                    ],
                    documents_object: [],
                    'documents_object.id_card.uploaded_ts': ['documents_object.id_card'],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );

        createMap<AccountVerificationViewModelKeys, AccountVerificationSortingFields>(
            mapper,
            nameof<AccountVerificationViewModelKeys>(),
            nameof<AccountVerificationSortingFields>(),
            constructUsing((m, _) => {
                const mapper: Record<AccountVerificationViewModelKeys, AccountVerificationSortingFields> = {
                    id: 'id',
                    type: 'type',
                    duration: null,
                    email: 'email',
                    player_country: 'iso_alpha2_country_code',
                    status_log: null,
                    uid: 'uid',
                    username: 'username',
                    iso_alpha2_country_code: 'iso_alpha2_country_code',
                    labels: null,
                    account_verification_status: 'account_verification_status',
                    documents: null,
                    init_reason_codes: null,
                    kyc_case_id: 'kyc_case_id',
                    serverId: null,
                    documents_object: null,
                    'initiated_at.seconds': 'initiated_at.seconds',
                    'documents_object.id_card.uploaded_ts': 'documents_object.id_card.uploaded_ts.seconds',
                    documents_with_link: null,
                    id_ndrp: 'id',
                };

                return mapper[m];
            })
        );
        createMap<AccountVerificationSortingFields, AccountVerificationViewModelKeys>(
            mapper,
            nameof<AccountVerificationSortingFields>(),
            nameof<AccountVerificationViewModelKeys>(),
            constructUsing((m, _) => {
                const mapper: Record<AccountVerificationSortingFields, AccountVerificationViewModelKeys> = {
                    id: 'id',
                    type: 'type',
                    email: 'email',
                    uid: 'uid',
                    username: 'username',
                    iso_alpha2_country_code: 'iso_alpha2_country_code',
                    account_verification_status: 'account_verification_status',
                    kyc_case_id: 'kyc_case_id',
                    'initiated_at.seconds': 'initiated_at.seconds',
                    'initiated_at.nanos': null,
                    'documents_object.id_card.uploaded_ts.seconds': 'documents_object.id_card.uploaded_ts',
                    'documents_object.id_card.uploaded_ts.nanos': null,
                };

                return mapper[m];
            })
        );
    }
}
