import React from 'react';
import {GridToolbarColumnsButton} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    gridColumnButtonContainer: {
        marginLeft: theme.spacing(5),
        marginBottom: theme.spacing(2),
        '& .MuiButtonBase-root': {
            backgroundColor: '#ffffff',
            fontSize: 14,
            padding: theme.spacing(2),
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.secondary.light}`,
            boxShadow: `0px 1px 1px ${theme.custom.palette.content.boxShadow}`,
            '& .MuiButton-startIcon': {
                color: theme.palette.secondary.main,
            },
            '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.background.paper,
                '& .MuiButton-startIcon': {
                    color: theme.palette.primary.main,
                },
            },
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(2),
        },
    },
}));

export function GridColumnsButton() {
    const {classes} = useStyles();
    return (
        <div className={classes.gridColumnButtonContainer}>
            <GridToolbarColumnsButton />
        </div>
    );
}
