import React from 'react';

import Toolbar from '@components/toolbar/Toolbar';
import {aggregatedFinanceSummaryDataRangePrefix, AggregatedFinanceSummaryFilterKeys} from '@redux/entity';

import {ModuleFilterDateRangeObsolete} from '../../shared/filter/components/date-range-picker/FilterDateRangePickerObsolete';
import {FilterPlacement, FilterProps} from '../../shared/filter/types';

type AggregatedFinancialSummaryFiltersProps = Omit<FilterProps, 'filter'>;

export function AggregatedFinancialSummaryFilters(props: AggregatedFinancialSummaryFiltersProps) {
    const filterKeys: AggregatedFinanceSummaryFilterKeys[] = ['aggregationRange.from', 'aggregationRange.to'];
    return (
        <Toolbar
            primary={
                <ModuleFilterDateRangeObsolete
                    {...props}
                    filter={{
                        key: filterKeys,
                        placement: FilterPlacement.Primary,
                        options: aggregatedFinanceSummaryDataRangePrefix,
                    }}
                />
            }
        />
    );
}
