import React from 'react';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource, withPolicyAccessCheck} from '@access-control';
import {ErrorModal} from '@components/error';
import {LayoutQueueTabs} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import {RoutingTabs, Tab} from '@components/tab/RoutingTabs';
import {AccountVerificationStatus} from '@models/generated/graphql';
import {withTraceErrorBoundary} from '@otel/TraceErrorBoundary';
import {Policy} from '@auth';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';

import {ModuleName, RouteUrl} from 'src/common/routeEnums';
import {FeatureName} from 'src/features/app/config/types';
import {withPermissionAndJurisdictionConfig} from 'src/features/app/permission/PermissionHoc';
import {KYCQueue} from '../../../features/block-account-verification';
import {localized} from '../localized';
import {kycWorkspaceHeaderActionContainerId} from '../types';

function PendingCases() {
    return (
        <KYCQueue
            statuses={[AccountVerificationStatus.InitNdrpKyc]}
            fields={['id_ndrp', 'initiated_at.seconds', 'duration', 'uid', 'email', 'type', 'account_verification_status']}
            filters={['text', 'date', 'duration', 'country']}
            viewType={'NDRPPendingCases'}
            kycType="NDRP"
            realtimeMode={RealtimeUpdatesMode.Confirm}
            triggers={[RealtimeMessageTrigger.Add, RealtimeMessageTrigger.Update]}
            syncWithUrl
        />
    );
}

function HistoryCases() {
    return (
        <KYCQueue
            statuses={[AccountVerificationStatus.Approved, AccountVerificationStatus.DeniedNdrpKyc]}
            fields={['id_ndrp', 'initiated_at.seconds', 'duration', 'uid', 'email', 'type', 'account_verification_status']}
            filters={['text', 'date', 'closedNDRPStatuses', 'country']}
            viewType={'NDRPHistoryCases'}
            kycType="NDRP"
            realtimeMode={RealtimeUpdatesMode.Confirm}
            triggers={[RealtimeMessageTrigger.Update]}
            syncWithUrl
        />
    );
}

function NDRPProcessing() {
    const tabs: Tab[] = [
        {
            component: PendingCases,
            title: localized.pendingCases,
            path: `${RouteUrl.NDRPProcessing}${RouteUrl.KycPendingCases}`,
            id: 'pendingCases',
        },
        {
            component: HistoryCases,
            title: localized.historyCases,
            path: `${RouteUrl.NDRPProcessing}${RouteUrl.KycHistoryCases}`,
            id: 'historyCases',
        },
    ];
    return (
        <LayoutQueueTabs
            header={
                <PageHeader
                    header={localized.ndrpHeader}
                    actions={<div id={kycWorkspaceHeaderActionContainerId}></div>}
                    removeBottomSpacing
                />
            }
            body={<RoutingTabs tabs={tabs} />}
        />
    );
}

export const ndrpProcessingResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'NDRPProcessing',
    rules: new PoliciesAccessRule([new Policy(ModuleName.PageNdrp, null, 'read')]),
});

export default withTraceErrorBoundary(
    withPermissionAndJurisdictionConfig(withPolicyAccessCheck(NDRPProcessing, ndrpProcessingResource), {featureName: FeatureName.NDRPPage}),
    ErrorModal
);
