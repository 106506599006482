import {ModuleName} from '../common/routeEnums';
import {
    FeatureName,
    Jurisdiction,
    JurisdictionConfiguration,
    JurisdictionCurrency,
    JurisdictionTimezone,
} from '../features/app/config/types';

export const configAsia: JurisdictionConfiguration = {
    jurisdiction: Jurisdiction.WPTO,
    locale: 'en',
    timezone: JurisdictionTimezone.WPTO,
    currency: JurisdictionCurrency.USD,
    limitedFeatures: [
        {
            moduleName: ModuleName.AccessManagement,
        },
        {
            moduleName: ModuleName.PlayerManagement,
        },
        {
            moduleName: ModuleName.LegalContentManagement,
        },
        {
            moduleName: ModuleName.KycProcessing,
        },
        {
            moduleName: ModuleName.WithdrawalProcessing,
        },
        {
            moduleName: ModuleName.SecurityWorkspace,
        },
        {
            moduleName: ModuleName.CustomerSupport,
        },
        {
            moduleName: ModuleName.MarketingFunction,
        },
        {
            moduleName: ModuleName.PageAgentReport,
        },
        {
            moduleName: ModuleName.TeamWorkspace,
        },
        {
            moduleName: ModuleName.GameAdmin,
        },
        {
            moduleName: ModuleName.PageRules,
        },

        {
            featureName: FeatureName.AgentPathStatisticsVn,
        },
    ],
};
