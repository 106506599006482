import {createMap, forMember, mapFrom, Mapper, mapWithArguments, Resolver} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {FeatureAccessStatus, Query, UserAccountStatus} from '@models/generated/graphql';
import {
    AgentPlayerReportingExtended,
    AgentPlayerReportingKrExtended,
    AgentPlayerReportingVnExtended,
    EntityFetchRequestPayload,
    EntityFetchResponsePayload,
    EntityFetchServiceResponsePayload,
    entityReservedId,
    EntityType,
    OccupationApiModel,
    UserProfileExtended,
    UserProfileQueryFields,
} from '@redux/entity';
import {getPageItemWithSummary} from '@services/entity/utils';

import {GqlQueryResponse, RestResponse} from '../types';

export type ItemPage = {
    items?: unknown[];
    total_count?: number;
};

export type ResponsePayloadMapExtraArgs = {
    requestPayload: EntityFetchRequestPayload;
};

const gqlResponsePayloadResolver: Resolver<GqlQueryResponse, ResponsePayloadMapExtraArgs, EntityFetchResponsePayload> = {
    resolve(source: GqlQueryResponse, {requestPayload}: ResponsePayloadMapExtraArgs): EntityFetchResponsePayload {
        const payload: Query = source?.responsePayload;
        const agentReportingSummaryItem: AgentPlayerReportingExtended = {
            uid: 'agentReportingSummaryId',
            is_summary: true,
            ...payload?.getAgentPlayerReportings?.summary,
            __typename: 'AgentPlayerReporting',
        };
        const agentReportingSummaryKrItem: AgentPlayerReportingKrExtended = {
            uid: 'agentReportingSummaryKrId',
            is_summary: true,
            referrer_agent_id: null,
            ...payload?.getAgentPlayerReportingsKr?.summary,
            __typename: 'AgentPlayerReportingKr',
        };
        const agentReportingSummaryVnItem: AgentPlayerReportingVnExtended = {
            uid: 'agentReportingSummaryVnId',
            is_summary: true,
            referrer_agent_id: null,
            ...payload?.getAgentPlayerReportingsVn?.summary,
            __typename: 'AgentPlayerReportingVn',
        };
        const payloadMapper: Record<EntityType, ItemPage> = {
            BoModule: undefined,
            BoRole: undefined,
            BoUser: undefined,
            Country: undefined,
            LegalDocAcceptanceVersion: undefined,
            Occupation: undefined,
            State: undefined,
            AccountVerification: payload?.getAccountVerifications,
            AgentPathStatistics: payload?.getAgentPathStatistics,
            BotGroup: payload?.getBotGroups,
            BulkOperation: {items: [payload?.getBulkOperationResult], total_count: payload?.getBulkOperationResult ? 1 : 0},
            EventLogRecord: payload?.getEventLogs,
            FriendGroup: payload?.getFriendGroups,
            GeoCheckSummary: {
                items: [payload?.getGeoCheckSummary],
                total_count: payload?.getGeoCheckSummary ? 1 : 0,
            },
            GlobalSearchRecord: {items: payload?.search, total_count: payload?.search?.length},
            KycCheckResults: payload?.getUsersKYC,
            LabelGroup: payload?.getLabelGroups,
            MarketingCode: payload?.getMarketingCodes,
            Note: payload?.getNotes,
            PlayerBonus: payload?.getPlayerBonuses,
            PlayerReferral: payload?.getPlayerReferrals,
            PlayerReferrerSummary: {items: [payload?.getPlayerReferrerSummary], total_count: payload?.getPlayerReferrerSummary ? 1 : 0},
            PlayersRelation: payload?.getRelatedPlayers,
            ReferralSettings: {
                items: [{entityReservedId, ...payload?.getReferralSettings}],
                total_count: payload?.getReferralSettings ? 1 : 0,
            },
            RgUserLimits: payload?.getRgUsersLimits,
            SecurityCase: {items: payload?.getSecurityCases, total_count: payload?.getSecurityCases?.length},
            Transaction: payload?.getUsersTransactions,
            UserAction: payload?.getUserActionHistory,
            UserLogin: payload?.getUsersLoginHistory,
            UserProfile: {
                items: payload?.getUserProfiles?.items?.map(i => {
                    const user: UserProfileExtended = {
                        ...i,
                        referral: {...(i.referral ?? {}), personal_referral_link: payload?.getPlayerReferralLink},
                    };

                    const requestFields: UserProfileQueryFields[] = requestPayload?.fields as UserProfileQueryFields[];
                    if (requestFields?.includes('account_status')) {
                        user.account_status = i.account_status ?? UserAccountStatus.Active;
                    }
                    if (requestFields.includes('deposit_status')) {
                        user.deposit_status = i.deposit_status ?? FeatureAccessStatus.Unlocked;
                    }
                    if (requestFields.includes('p2p_transfer_status')) {
                        user.p2p_transfer_status = i.p2p_transfer_status ?? FeatureAccessStatus.Unlocked;
                    }
                    if (requestFields.includes('withdrawal_status')) {
                        user.withdrawal_status = i.withdrawal_status ?? FeatureAccessStatus.Unlocked;
                    }
                    if (requestFields.includes('lobby_access_status')) {
                        user.lobby_access_status = i.lobby_access_status ?? FeatureAccessStatus.Unlocked;
                    }

                    return user;
                }),
                total_count: payload?.getUserProfiles?.total_count,
            },
            UserStatistics: payload?.getUserPokerStatistics,
            AggregatedFinanceSummary: {
                items: [payload?.getAggregatedFinanceSummary],
                total_count: 1,
            },
            AgentPlayerReporting:
                getPageItemWithSummary(payload?.getAgentPlayerReportings, agentReportingSummaryItem) ??
                getPageItemWithSummary(payload?.getAgentPlayerReportingsKr, agentReportingSummaryKrItem) ??
                getPageItemWithSummary(payload?.getAgentPlayerReportingsVn, agentReportingSummaryVnItem),
            AgentProfile: {items: payload?.getAgentProfiles, total_count: payload?.getAgentProfiles?.length},
            Backpack: payload?.getBackpackItems,
            GameTemplate: undefined,
            GameRoom: undefined,
            GameBlind: undefined,
            BonusCode: payload?.getBonusCodes,
            Rule: undefined,
            CasinoGame: undefined,
            CasinoSupplier: undefined,
            AgentLevelReport: payload?.getAgentRevenueShareWeeklyReport,
            SportsbookTransaction: undefined,
            EmbeddedReport: {items: [payload?.getEmbeddedDashboard], total_count: payload?.getEmbeddedDashboard ? 1 : 0},
        };
        const itemsPage: ItemPage = payloadMapper[requestPayload.type];

        return itemsPage?.items?.length ? {items: itemsPage.items, total: itemsPage.total_count} : {items: [], total: 0};
    },
};

export class GqlServiceMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            GqlQueryResponse,
            EntityFetchServiceResponsePayload,
            forMember(
                vm => vm.status,
                mapFrom(m => m.status)
            ),
            forMember(
                vm => vm.message,
                mapFrom(m => m.message)
            ),
            forMember(
                vm => vm.errors,
                mapFrom(m => m.errors)
            ),
            forMember(
                vm => vm.requestPayload,
                mapWithArguments((_, {requestPayload}: ResponsePayloadMapExtraArgs) => requestPayload)
            ),
            forMember(vm => vm.responsePayload, mapWithArguments(gqlResponsePayloadResolver))
        );
    }
}

const restResponsePayloadResolver: Resolver<RestResponse, ResponsePayloadMapExtraArgs, EntityFetchResponsePayload> = {
    resolve(source: RestResponse, {requestPayload}: ResponsePayloadMapExtraArgs): EntityFetchResponsePayload {
        const response = source?.responsePayload?.response;
        const payloadMapper: Partial<Record<EntityType, unknown[]>> = {
            BoModule: response?.items,
            BoRole: response?.items,
            BoUser: response?.items,
            Country: response,
            Occupation: response?.dataKeys?.map((i: OccupationApiModel) => ({code: i.key})),
            State: response?.subdivisions,
            GameRoom: response,
            Rule: response,
        };
        const items: unknown[] = payloadMapper[requestPayload.type] ?? [];

        return {items, total: items.length};
    },
};

export class RestServiceMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            RestResponse,
            EntityFetchServiceResponsePayload,
            forMember(
                vm => vm.status,
                mapFrom(m => m.status)
            ),
            forMember(
                vm => vm.errors,
                mapFrom(m => m.errors)
            ),
            forMember(
                vm => vm.message,
                mapFrom(m => m.message)
            ),
            forMember(
                vm => vm.requestPayload,
                mapWithArguments((_, {requestPayload}: ResponsePayloadMapExtraArgs) => requestPayload)
            ),
            forMember(vm => vm.responsePayload, mapWithArguments(restResponsePayloadResolver))
        );
    }
}
