import {constructUsing, createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {AccountVerificationViewModelKeys} from '@models/account-verification';
import {Platform, UserProfile} from '@models/generated/graphql';
import {UserProfileDownloadModel, UserProfileViewModelKeys} from '@models/user-profile/types';
import {formatTimestamp} from '@utils';

import {UserProfileAction} from './components/UserProfileActions';

export class UserProfileActionsMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap<UserProfile, UserProfileDownloadModel>(
            mapper,
            UserProfile,
            UserProfileDownloadModel,
            forMember(
                vm => vm.uid,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.full_name,
                mapFrom(m => (m.first_name || m.last_name ? `${m.first_name} ${m.last_name}` : undefined))
            ),
            forMember(
                vm => vm.nickname,
                mapFrom(m => m.nickname)
            ),
            forMember(
                vm => vm.platform,
                mapFrom(m => (m.platform === Platform.Wpto ? 'WPTG' : m.platform))
            ),
            forMember(
                vm => vm.platform_cs,
                mapFrom(m => (m.platform === Platform.Wpto ? 'WPTG' : m.platform))
            ),
            forMember(
                vm => vm.account_status,
                mapFrom(m => m.account_status)
            ),
            forMember(
                vm => vm.withdrawal_status,
                mapFrom(m => m.withdrawal_status)
            ),
            forMember(
                vm => vm.deposit_status,
                mapFrom(m => m.deposit_status)
            ),
            forMember(
                vm => vm.security_cases,
                mapFrom(m => m.security_cases?.map(c => c.case_id)?.join())
            ),
            forMember(
                vm => vm['latest_kyc.payment.account_verification_status'],
                mapFrom(m => m.latest_kyc?.payment?.account_verification_status)
            ),
            forMember(
                vm => vm['latest_kyc.payment.account_verification_status_cs'],
                mapFrom(m => m.latest_kyc?.payment?.account_verification_status)
            ),
            forMember(
                vm => vm['latest_kyc.security.account_verification_status'],
                mapFrom(m => m.latest_kyc?.security?.account_verification_status)
            ),
            forMember(
                vm => vm.phone,
                mapFrom(m => m.contact?.mobile?.full_number)
            ),
            forMember(
                vm => vm.referrer_player_id,
                mapFrom(m => m.referral?.inviting_user_id)
            ),
            forMember(
                vm => vm.referrer_username,
                mapFrom(m => m.referral?.inviting_user_username)
            ),
            forMember(
                vm => vm.referred_date,
                mapFrom(m => formatTimestamp(m.referral?.referred_at, 'date') ?? undefined)
            ),
            forMember(
                vm => vm.email,
                mapFrom(m => m.contact?.email)
            ),
            forMember(
                vm => vm.username,
                mapFrom(m => m.username)
            ),
            forMember(
                vm => vm['finance.balance'],
                mapFrom(m => m.finance?.balance?.toString())
            ),
            forMember(
                vm => vm.p2p_transfer_status,
                mapFrom(m => m.p2p_transfer_status)
            ),
            forMember(
                vm => vm.uid_link_security,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.uid_link_cs,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.uid_link_agent,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.register_marketing_code,
                mapFrom(m => m.marketing_info?.register_marketing_code)
            ),
            forMember(
                vm => vm.referrer,
                mapFrom(m => m?.affiliate?.btag ?? m?.referral?.inviting_user_id)
            ),
            forMember(
                vm => vm.labels,
                mapFrom(m => m.labels?.map(l => l.name)?.join())
            ),
            forMember(
                vm => vm.national_id,
                mapFrom(m => m.region_player_info?.national_id)
            ),
            forMember(
                vm => vm.networks_PKW,
                mapFrom(m => m?.networks?.find(item => item.platform === Platform.Pkw)?.uid)
            ),
            forMember(
                vm => vm.networks_BL,
                mapFrom(m => m?.networks?.find(item => item.platform === Platform.Bl)?.uid)
            ),
            forMember(
                vm => vm['latest_login.logged_at_ts.seconds'],
                mapFrom(m => formatTimestamp(m.latest_login?.logged_at_ts, 'date-time-with-seconds') ?? undefined)
            ),
            forMember(
                vm => vm.withdrawal_approved_amount,
                mapFrom(m => m.finance?.withdrawal?.total?.successful_amount?.toString())
            ),
            forMember(
                vm => vm.withdrawal_approved_count,
                mapFrom(m => m.finance?.withdrawal?.total?.successful_count?.toString())
            ),
            forMember(
                vm => vm.withdrawal_pending_amount,
                mapFrom(m => m.finance?.withdrawal?.total?.pending_amount?.toString())
            ),
            forMember(
                vm => vm.country,
                mapFrom(m => m?.address?.country_info?.iso_alpha2_code)
            ),
            forMember(
                vm => vm.login_countries,
                mapFrom(m => m?.countries?.join())
            ),
            forMember(
                vm => vm.first_name,
                mapFrom(m => m?.first_name)
            ),
            forMember(
                vm => vm.last_name,
                mapFrom(m => m?.last_name)
            ),
            forMember(
                vm => vm['date_of_joining.seconds'],
                mapFrom(m => formatTimestamp(m?.date_of_joining, 'date-time-with-seconds') ?? undefined)
            ),
            forMember(
                vm => vm.brand,
                mapFrom(m => m?.brand)
            ),
            forMember(
                vm => vm.license_type,
                mapFrom(m => m?.license_type)
            )
        );
        createMap<UserProfileAction[], UserProfileViewModelKeys[]>(
            mapper,
            nameof<UserProfileAction>(),
            nameof<UserProfileViewModelKeys>(),
            constructUsing(m => {
                const mapper: Record<UserProfileAction, UserProfileViewModelKeys[]> = {
                    'account-status': ['uid', 'account_status'],
                    'deposit-status': ['uid', 'deposit_status'],
                    'lobby-status': ['uid', 'lobby_access_status'],
                    'withdrawal-status': ['uid', 'withdrawal_status'],
                    'casino-status': ['uid', 'casino_status'],
                    'sportsbook-status': ['uid', 'sportsbook_status'],
                    'p2p-transfer-status': ['uid', 'p2p_transfer_status'],
                    'init-payment-kyc': [],
                    'send-message': ['uid'],
                    'assign-security-case-id': ['uid', 'security_cases'],
                    'manual-transaction': ['uid'],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
        createMap<UserProfileAction[], AccountVerificationViewModelKeys[]>(
            mapper,
            nameof<UserProfileAction>(),
            nameof<AccountVerificationViewModelKeys>(),
            constructUsing(m => {
                const mapper: Record<UserProfileAction, AccountVerificationViewModelKeys[]> = {
                    'account-status': [],
                    'deposit-status': [],
                    'lobby-status': [],
                    'withdrawal-status': [],
                    'casino-status': [],
                    'sportsbook-status': [],
                    'p2p-transfer-status': [],
                    'init-payment-kyc': ['uid', 'account_verification_status', 'init_reason_codes'],
                    'send-message': [],
                    'assign-security-case-id': [],
                    'manual-transaction': [],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
    }
}
