import {MuiDataGridClient, MuiDataGridServer, withDataGridEntityClient, withDataGridEntityServer} from '@components/data-grid/mui';

import {DataGridUserProfileConfigFactory} from 'src/features/block-user-profile-list';
import {DataGridTransactionWithUserProfileColumns, TransactionWithUserProfileGridItem} from '../types';

import {DataGridTransactionConfigFactory} from './DataGridTransaction';

export const DataGridTransactionWithUserProfileClient = withDataGridEntityClient<
    DataGridTransactionWithUserProfileColumns,
    TransactionWithUserProfileGridItem
>(MuiDataGridClient, () => ({
    ...new DataGridUserProfileConfigFactory().getColumnConfig(),
    ...new DataGridTransactionConfigFactory().getColumnConfig(),
}));

export const DataGridTransactionWithUserProfileServer = withDataGridEntityServer<
    DataGridTransactionWithUserProfileColumns,
    TransactionWithUserProfileGridItem
>(MuiDataGridServer, () => ({
    ...new DataGridUserProfileConfigFactory().getColumnConfig(),
    ...new DataGridTransactionConfigFactory().getColumnConfig(),
}));
