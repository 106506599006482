import React, {useContext} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {defineMessages, IntlContext} from 'react-intl';
import {Box, InputAdornment, List, ListItem, ListItemText} from '@mui/material';
import {format, parse} from 'date-fns';
import {makeStyles} from 'tss-react/mui';

import {useAutoMapper} from '@auto-mapper';
import Button from '@components/button/Buttons';
import {FormTextInputDefault, numberRegex, RuleType, useValidationFormatter} from '@components/input';
import {ModalFooter} from '@components/modal';
import {AgentRevenueShareViewModel} from '@models/user-profile/types';
import {sortByDate} from '@utils/date';

type AgentRevenueShareModalProps = {
    playerRevenueShare: string;
    onSave: (value: string) => void;
};

const localized = defineMessages({
    agentRevenueSharePokerShare: {
        id: 'AgentRevenueShareModal_agentRevenueSharePokerShare',
        defaultMessage: 'Poker Share',
    },
    agentRevenueShareGrossDeposit: {
        id: 'AgentRevenueShareModal_agentRevenueShareGrossDeposit',
        defaultMessage: 'Gross Deposit',
    },
    agentRevenueSharePlayerWinloss: {
        id: 'AgentRevenueShareModal_agentRevenueSharePlayerWinloss',
        defaultMessage: 'Player Win / Loss',
    },
    agentRevenueShareNetDepositShare: {
        id: 'AgentRevenueShareModal_agentRevenueShareNetDepositShare',
        defaultMessage: 'Net Deposit Share',
    },
    agentRevenueShareCasinoShare: {
        id: 'AgentRevenueShareModal_agentRevenueShareCasinoShare',
        defaultMessage: 'Casino Share',
    },
    agentRevenueShareSaveButtonLabel: {
        id: 'AgentRevenueShareModal_agentRevenueShareSaveButtonLabel',
        defaultMessage: 'Save',
    },
});

const useClasses = makeStyles()(theme => ({
    agentRevenueShareModalList: {
        paddingTop: theme.spacing(3),
        maxHeight: 720,
        overflow: 'auto',
    },
    agentRevenueShareModalListItem: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
    },
    agentRevenueShareModalListItemText: {
        minWidth: theme.spacing(25),
        marginBottom: theme.spacing(2),
        textTransform: 'capitalize',
        '& .MuiTypography-body1': {
            fontWeight: 600,
        },
    },
    agentRevenueShareModalListSubItemText: {
        paddingLeft: theme.spacing(2),
        '& .MuiTypography-body1': {
            fontWeight: 400,
        },
    },
}));

export function AgentRevenueShareModal({playerRevenueShare, onSave}: AgentRevenueShareModalProps) {
    const {classes, cx} = useClasses();
    const {formatMessage} = useContext(IntlContext);
    const validationMessageFormatter = useValidationFormatter();
    const mapper = useAutoMapper();

    const dateMask = 'yyyy-MM-dd';
    const revenueShareHistory: AgentRevenueShareViewModel[] = getRevenuShareValue();
    const latestRevenueShare: AgentRevenueShareViewModel = revenueShareHistory?.[revenueShareHistory.length - 1];
    const bounds = [7000, 11000, 23000, 46000, 116000];
    const defaultRevenueShare: AgentRevenueShareViewModel = {
        active_since: '',
        casino_share: null,
        net_deposit_share: null,
        player_winloss: null,
        gross_deposit: null,
        poker_share: {
            weekly_bands: [
                {lower_bound: null, upper_bound: bounds[0], revenue_share: null},
                {lower_bound: bounds[0], upper_bound: bounds[1], revenue_share: null},
                {lower_bound: bounds[1], upper_bound: bounds[2], revenue_share: null},
                {lower_bound: bounds[2], upper_bound: bounds[3], revenue_share: null},
                {lower_bound: bounds[3], upper_bound: bounds[4], revenue_share: null},
                {lower_bound: bounds[4], upper_bound: null, revenue_share: null},
            ],
        },
    };

    const {control, handleSubmit} = useForm<AgentRevenueShareViewModel>({defaultValues: latestRevenueShare ?? defaultRevenueShare});

    function getRevenuShareValue(): AgentRevenueShareViewModel[] {
        function sortHistory(first: AgentRevenueShareViewModel, second: AgentRevenueShareViewModel): number {
            const firstDate = parse(first.active_since, dateMask, new Date());
            const secondDate = parse(second.active_since, dateMask, new Date());
            return sortByDate(firstDate, secondDate);
        }

        const revenueShareHistory: AgentRevenueShareViewModel[] = mapper.map(
            playerRevenueShare,
            String,
            nameof<AgentRevenueShareViewModel>()
        );
        return revenueShareHistory?.slice()?.sort(sortHistory) ?? [];
    }

    function handleFormSubmit(data: AgentRevenueShareViewModel) {
        const updatedValue: AgentRevenueShareViewModel = {...data, active_since: format(new Date(), dateMask)};
        const updatedHistory: AgentRevenueShareViewModel[] = [...revenueShareHistory, updatedValue];
        const revenueShareJson = mapper.map(updatedHistory, nameof<AgentRevenueShareViewModel>(), String).toString();
        onSave(revenueShareJson);
    }

    const percentageAdornment = <InputAdornment position="end">%</InputAdornment>;

    return (
        <Box>
            <form>
                <List classes={{padding: classes.agentRevenueShareModalList}}>
                    <ListItem className={classes.agentRevenueShareModalListItem}>
                        <ListItemText
                            className={classes.agentRevenueShareModalListItemText}
                            primary={formatMessage(localized.agentRevenueSharePokerShare)}
                        />
                    </ListItem>
                    {Object.values(defaultRevenueShare?.poker_share?.weekly_bands).map((weeklyBand, index) => {
                        const label = `${weeklyBand.lower_bound} / ${weeklyBand.upper_bound}`;
                        return (
                            <ListItem key={label} className={classes.agentRevenueShareModalListItem}>
                                <ListItemText
                                    className={cx(
                                        classes.agentRevenueShareModalListSubItemText,
                                        classes.agentRevenueShareModalListItemText
                                    )}
                                    primary={label}
                                />
                                <Controller
                                    render={({field, fieldState}) => (
                                        <FormTextInputDefault
                                            value={field.value}
                                            onChange={field.onChange}
                                            fieldState={fieldState}
                                            textAlign="right"
                                            type="number"
                                            endAdornment={percentageAdornment}
                                        ></FormTextInputDefault>
                                    )}
                                    control={control}
                                    name={`poker_share.weekly_bands.${index}.revenue_share`}
                                    rules={{
                                        required: validationMessageFormatter(RuleType.Required, label),
                                        pattern: {
                                            value: numberRegex,
                                            message: validationMessageFormatter(RuleType.Number, label),
                                        },
                                    }}
                                />
                            </ListItem>
                        );
                    })}
                    <ListItem className={classes.agentRevenueShareModalListItem}>
                        <ListItemText
                            className={classes.agentRevenueShareModalListItemText}
                            primary={formatMessage(localized.agentRevenueShareCasinoShare)}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <FormTextInputDefault
                                    value={field.value}
                                    onChange={field.onChange}
                                    fieldState={fieldState}
                                    textAlign="right"
                                    type="number"
                                    endAdornment={percentageAdornment}
                                ></FormTextInputDefault>
                            )}
                            control={control}
                            name="casino_share"
                            rules={{
                                required: validationMessageFormatter(RuleType.Required, localized.agentRevenueShareCasinoShare),
                                pattern: {
                                    value: numberRegex,
                                    message: validationMessageFormatter(RuleType.Number, localized.agentRevenueShareCasinoShare),
                                },
                            }}
                        />
                    </ListItem>
                    <ListItem className={classes.agentRevenueShareModalListItem}>
                        <ListItemText
                            className={classes.agentRevenueShareModalListItemText}
                            primary={formatMessage(localized.agentRevenueShareNetDepositShare)}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <FormTextInputDefault
                                    value={field.value}
                                    onChange={field.onChange}
                                    fieldState={fieldState}
                                    textAlign="right"
                                    type="number"
                                    endAdornment={percentageAdornment}
                                ></FormTextInputDefault>
                            )}
                            control={control}
                            name="net_deposit_share"
                            rules={{
                                required: validationMessageFormatter(RuleType.Required, localized.agentRevenueShareNetDepositShare),
                                pattern: {
                                    value: numberRegex,
                                    message: validationMessageFormatter(RuleType.Number, localized.agentRevenueShareNetDepositShare),
                                },
                            }}
                        />
                    </ListItem>
                    <ListItem className={classes.agentRevenueShareModalListItem}>
                        <ListItemText
                            className={classes.agentRevenueShareModalListItemText}
                            primary={formatMessage(localized.agentRevenueSharePlayerWinloss)}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <FormTextInputDefault
                                    value={field.value}
                                    onChange={field.onChange}
                                    fieldState={fieldState}
                                    textAlign="right"
                                    type="number"
                                    endAdornment={percentageAdornment}
                                ></FormTextInputDefault>
                            )}
                            control={control}
                            name="player_winloss"
                            rules={{
                                required: validationMessageFormatter(RuleType.Required, localized.agentRevenueSharePlayerWinloss),
                                pattern: {
                                    value: numberRegex,
                                    message: validationMessageFormatter(RuleType.Number, localized.agentRevenueSharePlayerWinloss),
                                },
                            }}
                        />
                    </ListItem>
                    <ListItem className={classes.agentRevenueShareModalListItem}>
                        <ListItemText
                            className={classes.agentRevenueShareModalListItemText}
                            primary={formatMessage(localized.agentRevenueShareGrossDeposit)}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <FormTextInputDefault
                                    value={field.value}
                                    onChange={field.onChange}
                                    fieldState={fieldState}
                                    textAlign="right"
                                    type="number"
                                    endAdornment={percentageAdornment}
                                ></FormTextInputDefault>
                            )}
                            control={control}
                            name="gross_deposit"
                            rules={{
                                required: validationMessageFormatter(RuleType.Required, localized.agentRevenueShareGrossDeposit),
                                pattern: {
                                    value: numberRegex,
                                    message: validationMessageFormatter(RuleType.Number, localized.agentRevenueShareGrossDeposit),
                                },
                            }}
                        />
                    </ListItem>
                </List>
            </form>
            <ModalFooter>
                <Button
                    color="primary"
                    variant="contained"
                    label={localized.agentRevenueShareSaveButtonLabel}
                    onClick={handleSubmit(handleFormSubmit)}
                />
            </ModalFooter>
        </Box>
    );
}
