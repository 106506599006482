import React from 'react';

import {agentReadPolicies} from '@models/permissions/permissions';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {SecurityCaseViewModel} from '../../block-security-case';
import {PermissionEnum} from '../../module-shared/types';
import {AssignSecurityCaseId} from '../../player-actions/cases-actions/components/SecurityCasesAction';
import {PlayerProfileGridItem} from '../../player-actions/types';

type AssignSecurityCaseIdButtonProps = {
    uid: string;
    securityCases: SecurityCaseViewModel[];
    disabled?: boolean;
};

export const AssignSecurityCaseIdButton = withMultiplePermission(
    ({uid, securityCases, disabled}: AssignSecurityCaseIdButtonProps) => {
        return uid ? (
            <AssignSecurityCaseId
                disabled={disabled}
                defaultValue={securityCases}
                field={nameof<PlayerProfileGridItem>(u => u.security_cases)}
                id={uid}
                model={{uid, security_cases: securityCases}}
            />
        ) : null;
    },
    {
        allowedPermissions: [
            {moduleName: ModuleName.ActionCenter, permissions: [PermissionEnum.Read, PermissionEnum.Create, PermissionEnum.Delete]},
            {
                moduleName: ModuleName.ActionCenter,
                submoduleName: SubmoduleName.Cases,
                permissions: [PermissionEnum.Read, PermissionEnum.Create, PermissionEnum.Delete],
            },
        ],
        restrictedPermissions: agentReadPolicies,
        indicator: LackOfPermissionIndicator.Disabled,
    }
);
