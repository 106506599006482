import {defineMessages} from 'react-intl';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    MuiDataGridClient,
    MuiDataGridServer,
    renderCellHeader,
    renderCellWithTooltip,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import {AgentPathStatisticsViewModel, AgentPathStatisticsViewModelKeys} from '@models/agent-path-statistics';

const localizedHeaders = defineMessages({
    agentUid: {
        id: 'DataGridAgentPathStatistics_agentUid',
        defaultMessage: 'Agent UID',
    },
    pathToSubagent: {
        id: 'DataGridAgentPathStatistics_pathToSubagent',
        defaultMessage: 'Path to subagent',
    },
    registeredPlayerNumber: {
        id: 'DataGridAgentPathStatistics_registeredPlayerNumber',
        defaultMessage: 'Registered player number',
    },
    activePlayerNumber: {
        id: 'DataGridAgentPathStatistics_activePlayerNumber',
        defaultMessage: 'Active player number',
    },
});

export class DataGridAgentPathStatisticsConfigFactory extends DataGridEntityConfigFactory<
    AgentPathStatisticsViewModelKeys,
    AgentPathStatisticsViewModel
> {
    getColumnConfig(): Partial<Record<AgentPathStatisticsViewModelKeys, DataGridEntityColDef<AgentPathStatisticsViewModelKeys>>> {
        return {
            id: {
                field: 'id',
                hide: true,
            },
            agent_uid: {
                field: 'agent_uid',
                valueGetter: p => this.getAgentUid(p),
                renderHeader: () => renderCellHeader(localizedHeaders.agentUid),
                renderCell: renderCellWithTooltip,
                flex: 1,
                sortable: false,
            },
            path_to_subagent: {
                field: 'path_to_subagent',
                valueGetter: p => this.getPathToSubagent(p),
                renderHeader: () => renderCellHeader(localizedHeaders.pathToSubagent),
                renderCell: renderCellWithTooltip,
                flex: 2,
                sortable: false,
            },
            registered_player_number: {
                field: 'registered_player_number',
                valueGetter: p => this.getRegisteredPlayerNumber(p),
                renderHeader: () => renderCellHeader(localizedHeaders.registeredPlayerNumber),
                renderCell: renderCellWithTooltip,
                flex: 1,
                sortable: false,
            },
            active_player_number: {
                field: 'active_player_number',
                valueGetter: p => this.getActivePlayerNumber(p),
                renderHeader: () => renderCellHeader(localizedHeaders.activePlayerNumber),
                renderCell: renderCellWithTooltip,
                flex: 1,
                sortable: false,
            },
        };
    }

    private getAgentUid(p: GridValueGetterParams) {
        return this.getRow(p)?.agent_uid;
    }

    private getPathToSubagent(p: GridValueGetterParams) {
        return this.getRow(p)?.path_to_subagent;
    }

    private getRegisteredPlayerNumber(p: GridValueGetterParams) {
        return this.getRow(p)?.registered_player_number;
    }

    private getActivePlayerNumber(p: GridValueGetterParams) {
        return this.getRow(p)?.active_player_number;
    }
}

export const DataGridAgentPathStatisticsClient = withDataGridEntityClient<AgentPathStatisticsViewModelKeys, AgentPathStatisticsViewModel>(
    MuiDataGridClient,
    () => new DataGridAgentPathStatisticsConfigFactory().getColumnConfig()
);

export const DataGridAgentPathStatisticsServer = withDataGridEntityServer<AgentPathStatisticsViewModelKeys, AgentPathStatisticsViewModel>(
    MuiDataGridServer,
    () => new DataGridAgentPathStatisticsConfigFactory().getColumnConfig()
);
