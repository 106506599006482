import {MessageDescriptor} from 'react-intl';
import {GridCellParams, GridColDef as MuiGridColDef, GridSortDirection, GridValueGetterParams} from '@mui/x-data-grid';

import {ChipsTypes} from '@config/chip';

import {JurisdictionFeature} from '../../../../features/app/config/types';
import {PermissionAccessibleItem} from '../../../../features/module-shared/types';
import {IModuleGridItem} from '../types';

export type HideColDef = {
    hide?: boolean;
};

export type ExtendedMuiGridColDef = MuiGridColDef & HideColDef;

export type GridColDef = Omit<ExtendedMuiGridColDef, 'renderHeader'> &
    JurisdictionFeature & {
        sortField?: string;
        sortDirection?: GridSortDirection;
        localizedHeaderName?: MessageDescriptor;
        renderHeader?: (label: string | MessageDescriptor) => JSX.Element;
    };

/**
 * @deprecated
 * <p>Should be removed. Use {@link GridColDef}</p>
 */
export type SortableColDef = ExtendedMuiGridColDef & {
    sortField?: string;
    sortDirection?: GridSortDirection;
};

/**
 * @deprecated
 * <p>Should be removed. Use {@link GridColDef}</p>
 */
export type PermissionColDef = SortableColDef & PermissionAccessibleItem & {};

/**
 * @deprecated
 * <p>Should be removed. Use {@link GridColDef}</p>
 */
export type FeatureColDef = ExtendedMuiGridColDef & JurisdictionFeature;
export type ColumnConfigurationColDef = ExtendedMuiGridColDef & {
    configurationColumns?: string[];
};
export type DataGridEntityColDef<TColumn extends string> = Omit<GridColDef, 'field'> & {
    field: TColumn | 'id';
};
export type DataGridEntityOptions = {
    [field: string]: unknown;
};

export abstract class DataGridEntityConfigFactory<TColumns extends string, TEntity extends IModuleGridItem> {
    abstract getColumnConfig(options?: DataGridEntityOptions): Partial<Record<TColumns, DataGridEntityColDef<TColumns>>>;

    protected getRow(p: GridValueGetterParams | GridCellParams) {
        return p.row as TEntity;
    }

    protected getChipValue(value: ChipsTypes, type: string) {
        return {
            value,
            type,
        };
    }

    protected getRowsCount(p: GridValueGetterParams): number {
        return p.api.getRowsCount();
    }
}

export const gridMaxSize = 100;
