import {ApolloQueryResult, DocumentNode, FetchResult, NormalizedCacheObject, OperationVariables, ServerError} from '@apollo/client';
import {ApolloError} from '@apollo/client/errors';
import {ServerParseError} from '@apollo/client/link/http';
import {ExecutionResult, GraphQLError} from 'graphql';
import {from, Observable, of, Subject, Subscriber, timer} from 'rxjs';
import {catchError, map, mergeMap, takeUntil} from 'rxjs/operators';

import {Mutation, Query, Subscription} from '@models/generated/graphql';

import {ErrorHandlingService} from '../../../features/app/error-handling/services';
import {ErrorCategory} from '../../../features/app/error-handling/types';
import {errorMessages, getErrorByFailureAction} from '../../../features/app/intl/shared-resources/serverResponse';
import {
    GqlMutationResponse,
    GqlResponse,
    IServiceErrorResponsePayload,
    ServerResponseError,
    ServerResponseErrorCode,
    ServerResponseStatus,
} from '../types';

import {ApolloClientProxy} from './ApolloClientProxy';

export type UnknownSubscriptionError = {
    name: string;
    message: string;
};

export type MutationError = {
    errorType: string;
    errorInfo?: string;
    message?: string;
    name: string;
};

export type UnknownSubscriptionErrors = UnknownSubscriptionError & {
    errors: UnknownSubscriptionError[];
};

export type GqlSubscriptionResponsePayload = GqlResponse<Subscription>;

type FailedApolloQueryResult = ApolloQueryResult<Query>;

export class GraphQLService {
    private client: ApolloClientProxy<NormalizedCacheObject>;
    private readonly errorHandlingService: ErrorHandlingService;
    private readonly _originalSubscriptionObservable: Record<string, Observable<FetchResult<Subscription>>> = {};
    private readonly _unsubscribeNotifier: Record<string, Subject<unknown>> = {};

    public constructor(client: ApolloClientProxy<NormalizedCacheObject>) {
        this.client = client;
        this.errorHandlingService = new ErrorHandlingService();
    }

    //TODO: Mutation should return response in the same format as gql query
    /**
     * @deprecated
     * <p>Mutation should return response in the same format as gql query</p>
     * <p>Use mutateNew instead</p>
     */
    public mutateObsolete<TMutation>(mutation: DocumentNode, variables?: unknown): Observable<TMutation> {
        return this.handleErrors(this.client.mutate<TMutation>({mutation: mutation, variables}));
    }

    public mutate<TMutation extends Mutation, TArgs>(
        mutation: DocumentNode,
        variables?: TArgs
    ): Observable<GqlMutationResponse<TMutation, TArgs>> {
        return from(this.client.mutate<TMutation>({mutation: mutation, variables})).pipe(
            map(res => {
                let result: GqlMutationResponse<TMutation, TArgs>;
                if (res.errors) {
                    const error = this.getError(res);
                    this.logError(error, ErrorCategory.GraphQL);
                    result = {
                        requestPayload: {mutation, variables},
                        responsePayload: res.data,
                        ...this.parseMutationError(res),
                    };
                } else {
                    result = {
                        status: ServerResponseStatus.Success,
                        requestPayload: {mutation, variables},
                        responsePayload: res.data,
                    };
                }
                return result;
            }),
            catchError((e: ApolloError) => {
                this.logError(this.getError(e), ErrorCategory.GraphQL);
                return of({
                    requestPayload: {mutation, variables},
                    responsePayload: null,
                    ...this.parseApolloError(e),
                });
            })
        );
    }

    private handleErrors<TResult>(promise: Promise<ExecutionResult<TResult>>) {
        return new Observable((subscriber: Subscriber<TResult>) => {
            promise
                .then(res => {
                    if (res.errors) {
                        this.logError(this.getError(res), ErrorCategory.GraphQL);
                    }
                    return res.errors?.length ? subscriber.error(res) : subscriber.next(res.data);
                })
                .catch(error => {
                    const err = this.getError(error);
                    this.logError(err, ErrorCategory.GraphQL);
                    return subscriber.error(err);
                })
                .finally(() => subscriber.complete());
        });
    }

    public query<TVariables = OperationVariables>(query: DocumentNode, filter?: TVariables): Observable<GqlResponse<Query>> {
        return from(this.client.query<Query>({query, variables: filter})).pipe(
            map(res => {
                let result: GqlResponse<Query>;
                if (res.errors?.length) {
                    const error = this.getError(res);
                    this.logError(error, ErrorCategory.GraphQL);
                    result = {
                        requestPayload: {query, variables: filter},
                        responsePayload: res.data,
                        ...this.parseQueryError(res),
                    };
                } else {
                    result = {
                        status: ServerResponseStatus.Success,
                        requestPayload: {query, variables: filter},
                        responsePayload: res.data,
                    };
                }
                return result;
            }),
            catchError((e: ApolloError) => {
                this.logError(this.getError(e), ErrorCategory.GraphQL);
                return of({
                    requestPayload: {query, variables: filter},
                    responsePayload: null as Query,
                    ...this.parseQueryError(e),
                });
            })
        );
    }

    public subscribeTemp(query: DocumentNode, key: string): Observable<GqlSubscriptionResponsePayload> {
        this._unsubscribeNotifier[key] = new Subject();
        const subscribeWithRetry = new Observable<GqlSubscriptionResponsePayload>(observer => {
            const parseSuccess = (res: FetchResult): GqlSubscriptionResponsePayload => {
                return {
                    requestPayload: {query, variables: null},
                    responsePayload: res?.data,
                    errors: res?.errors?.map<ServerResponseError>(this.parseGqlError),
                    status: ServerResponseStatus.Success,
                };
            };

            const parseError = (e: ApolloError | UnknownSubscriptionErrors): GqlSubscriptionResponsePayload => {
                return {
                    requestPayload: {query, variables: null},
                    responsePayload: {},
                    ...((e as UnknownSubscriptionErrors).errors
                        ? this.parseUnknownErrors(e as UnknownSubscriptionErrors)
                        : this.parseApolloError(e as ApolloError)),
                };
            };

            const subscribe = () => {
                const observable = this.subscribeGql(query, key);
                const subscribeTimeout = 500;
                //timeout added to handle the case with 2 immediate subscriptions when view initialized
                //when unsubscribe is called for the previous ones (see return)

                return timer(subscribeTimeout)
                    .pipe(mergeMap(() => observable))
                    .subscribe(
                        res => {
                            observer.next(parseSuccess(res));
                        },
                        err => {
                            this.logError(this.getError(err), ErrorCategory.WebSocket);
                            observer.next(parseError(err));
                            this.clearCache(key);
                            // NOTE: resubscribe removed to fix traffic issue
                            //subscription = subscribe();
                        }
                    );
            };

            const subscription = subscribe();

            return () => {
                subscription.unsubscribe();
            };
        });

        return subscribeWithRetry.pipe(
            map(e => e),
            takeUntil(this._unsubscribeNotifier[key])
        );
    }

    public unsubscribe(keys: string[]): Observable<GqlSubscriptionResponsePayload> {
        const unsubscribeNotifiers = keys.map(key => this._unsubscribeNotifier[key]);
        keys.forEach(k => this.clearCache(k));
        unsubscribeNotifiers.forEach(notifier => {
            if (notifier) {
                notifier.next(null);
                notifier.unsubscribe();
            }
        });

        return of({
            requestPayload: null,
            responsePayload: null,
            status: ServerResponseStatus.Success,
        });
    }

    private parseQueryError(error: ApolloError | FailedApolloQueryResult): IServiceErrorResponsePayload {
        return error instanceof ApolloError ? this.parseApolloError(error) : this.parseGraphQLErrors(error as FailedApolloQueryResult);
    }

    private parseMutationError(errorResult: FetchResult): IServiceErrorResponsePayload {
        return {
            status: ServerResponseStatus.Failed,
            errors: errorResult?.errors?.map<ServerResponseError>(this.parseGqlError),
        };
    }

    private parseApolloError(e: ApolloError): IServiceErrorResponsePayload {
        const result: IServiceErrorResponsePayload = {
            status: ServerResponseStatus.Failed,
            message: e.message,
            errors: [],
        };

        if (e.networkError) {
            result.errors.push(this.parseNetworkError(e.networkError));
        }

        if (e.graphQLErrors?.length) {
            result.errors.push(...e.graphQLErrors?.map<ServerResponseError>(this.parseGqlError));
        }

        return result;
    }

    private parseNetworkError(networkError: Error | ServerParseError | ServerError): ServerResponseError {
        let result: ServerResponseError;

        if ((networkError as ServerError).statusCode) {
            const message = getErrorByFailureAction(null, (networkError as ServerError).statusCode).defaultMessage as string;
            result = {
                code: (networkError as ServerError).statusCode.toString(),
                message: message === errorMessages.defaultError.defaultMessage ? networkError.message : message,
                values: [],
            };
        } else {
            result = {
                code: networkError.name,
                message: networkError.message,
                values: [],
            };
        }

        return result;
    }

    private parseGraphQLErrors(e: FailedApolloQueryResult): IServiceErrorResponsePayload {
        const result: IServiceErrorResponsePayload = {
            status: ServerResponseStatus.Failed,
            message: e?.errors?.length ? e.errors[0].message : '',
            errors: [],
        };

        if (e.errors?.length) {
            result.errors.push(...e.errors.map(this.parseGqlError));
        }

        return result;
    }

    private parseGqlError(error: GraphQLError): ServerResponseError {
        let jsonError;
        try {
            jsonError = JSON.parse(error.message);
        } catch (e) {
            jsonError = {
                error: {
                    message: error?.message,
                    //TODO: [BO-2975] Remove error?.name after switch to ariadne
                    type: error?.name ?? error?.extensions?.errorType ?? ServerResponseErrorCode.BadRequest,
                    data: error?.extensions?.data,
                },
            };
        }
        return {
            message: jsonError?.error?.message,
            code: jsonError?.error?.type,
            values: [],
            data: jsonError?.error?.data,
        };
    }

    private logError(err: Error, category: ErrorCategory) {
        this.errorHandlingService.logError(err, category, this.constructor);
    }

    private getError(err: UnknownSubscriptionErrors | FetchResult | ApolloError): Error {
        const errors = (err as any).errors;
        const error = errors?.length > 0 ? errors[0] : err;
        return error as Error;
    }

    private clearCache(key: string) {
        //on unsubscribe we just clean the cache to allow subscribe again
        //real unsubscribe happens when observable stopped
        const subscription = this._originalSubscriptionObservable[key];
        if (subscription) {
            delete this._originalSubscriptionObservable[key];
            delete this._unsubscribeNotifier[key];
        }
    }

    private subscribeGql(query: DocumentNode, key: string) {
        if (!this._originalSubscriptionObservable[key]) {
            this._originalSubscriptionObservable[key] = this.client.subscribeRx({query, fetchPolicy: 'no-cache'});
        }

        return this._originalSubscriptionObservable[key];
    }

    private parseUnknownErrors(e: UnknownSubscriptionErrors): IServiceErrorResponsePayload {
        const result: IServiceErrorResponsePayload = {
            status: ServerResponseStatus.Failed,
            message: e?.errors?.length ? e.errors[0].message : '',
            errors: [],
        };

        if (e.errors?.length) {
            result.errors.push(...e.errors.map(this.parseUnknownError));
        }

        return result;
    }

    private parseUnknownError(error: UnknownSubscriptionError): ServerResponseError {
        return {
            code: error.message,
            message: error.message,
            values: [],
        };
    }
}
