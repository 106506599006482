import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';

import {ServiceTypes} from '@inversify';
import {
    AgentPlayerReporting,
    AgentPlayerReportVersion,
    QueryGetAgentPlayerReportingsArgs,
    QueryGetAgentPlayerReportingsVnArgs,
} from '@models/generated/graphql';
import {
    AgentPlayerReportingQueryFields,
    AgentPlayerReportingServerFilterKeys,
    AgentPlayerReportingServerTextFilterKeys,
    AgentReportRegion,
    EntityFetchRequestPayload,
    Filter,
} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';
import {GqlRequest} from '@services/types';

import {DynamicConfig} from 'src/configuration';
import {JurisdictionConfigService} from 'src/features/app/config/services';
import {FeatureName} from 'src/features/app/config/types';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';

@injectable()
export class AgentPlayerReportingService extends EntityBaseGqlService<
    QueryGetAgentPlayerReportingsArgs,
    AgentPlayerReportingQueryFields,
    AgentPlayerReportingServerFilterKeys
> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.JurisdictionConfigService) jurisdictionConfigService: JurisdictionConfigService,
        @inject(ServiceTypes.Config) config: DynamicConfig
    ) {
        super(client, mapper, new AgentPlayerReportingRequestBuilder(jurisdictionConfigService, config));
    }
}

export class AgentPlayerReportingRequestBuilder extends GqlRequestBuilder<
    QueryGetAgentPlayerReportingsArgs,
    AgentPlayerReportingQueryFields,
    AgentPlayerReportingServerFilterKeys
> {
    public buildGqlRequest(
        requestPayload: EntityFetchRequestPayload<AgentPlayerReportingQueryFields>
    ): GqlRequest<QueryGetAgentPlayerReportingsArgs> {
        const filter = this.mapToFilterObject(requestPayload.filter);

        return !this.isFilterInvalid(filter)
            ? {
                  query: this.getQueryByRegion(requestPayload.fields, filter.region as AgentReportRegion),
                  variables: this.getGqlPageArgs(filter),
              }
            : null;
    }

    private getQueryByRegion(fields: AgentPlayerReportingQueryFields[], country: AgentReportRegion): DocumentNode {
        const queryCountryMapper: Record<AgentReportRegion, DocumentNode> = {
            vn: this.buildQueryVn(fields),
            kr: this.buildQueryKr(fields),
        };
        return queryCountryMapper[country] ?? this.buildQuery(fields);
    }

    public buildQuery = (fields: AgentPlayerReportingQueryFields[]): DocumentNode => gql`
        query GetAgentPlayerReportings($filter: AgentPlayerReportingFilter, $sort: Sorting, $start: Int, $end: Int) {
            getAgentPlayerReportings(filter: $filter, sort: $sort, end: $end, start: $start) {
                items {
                    uid @include(if: ${this.hasField(fields, 'uid')})
                    username @include(if: ${this.hasField(fields, 'username')})
                    contact @include(if: ${this.hasAnyField(fields, this.getContactQueryItems())}) {
                        email @include(if: ${this.hasField(fields, 'contact.email')})
                        mobile @include(if: ${this.hasAnyField(fields, this.getContactMobileQueryItems())}) {
                            full_number @include(if: ${this.hasField(fields, 'contact.mobile.full_number')})
                        }
                    }
                    iso_alpha2_country_code @include(if: ${this.hasField(fields, 'iso_alpha2_country_code')})
                    referrer_agent_id @include(if: ${this.hasField(fields, 'referrer_agent_id')})
                    referrer_agent_username @include(if: ${this.hasField(fields, 'referrer_agent_username')})
                    ggr_summary @include(if: ${this.hasAnyField(fields, this.getGgrSummaryQueryItems())}) {
                        cash_ggr @include(if: ${this.hasField(fields, 'ggr_summary.cash_ggr')})
                        mtt_ggr @include(if: ${this.hasField(fields, 'ggr_summary.mtt_ggr')})
                        casino_ggr @include(if: ${this.hasField(fields, 'ggr_summary.casino_ggr')})
                        poker_share @include(if: ${this.hasField(fields, 'ggr_summary.poker_share')})
                        casino_share @include(if: ${this.hasField(fields, 'ggr_summary.casino_share')})
                        total_share @include(if: ${this.hasField(fields, 'ggr_summary.total_share')})
                        global_spin_ggr @include(if: ${this.hasField(fields, 'ggr_summary.global_spin_ggr')})
                        external_casino_ggr @include(if: ${this.hasField(fields, 'ggr_summary.external_casino_ggr')}) 
                        internal_casino_ggr @include(if: ${this.hasField(fields, 'ggr_summary.internal_casino_ggr')}) 
                        external_casino_bet_amount @include(if: ${this.hasField(fields, 'ggr_summary.external_casino_bet_amount')}) 
                        internal_casino_bet_amount @include(if: ${this.hasField(fields, 'ggr_summary.internal_casino_bet_amount')}) 
                        plo_ggr_high_stake @include(if: ${this.hasField(fields, 'ggr_summary.plo_ggr_high_stake')})
                        plo_ggr_low_stake @include(if: ${this.hasField(fields, 'ggr_summary.plo_ggr_low_stake')})
                        plo_ggr_medium_stake @include(if: ${this.hasField(fields, 'ggr_summary.plo_ggr_medium_stake')})
                        plo_ggr_micro_stake @include(if: ${this.hasField(fields, 'ggr_summary.plo_ggr_micro_stake')})
                        plo_ggr_total @include(if: ${this.hasField(fields, 'ggr_summary.plo_ggr_total')})
                        plo_ggr_unknown_stake @include(if: ${this.hasField(fields, 'ggr_summary.plo_ggr_unknown_stake')})
                        poker_rake @include(if: ${this.hasField(fields, 'ggr_summary.poker_rake')}) 
                        poker_addons @include(if: ${this.hasField(fields, 'ggr_summary.poker_addons')}) 
                        poker_mtt_fees @include(if: ${this.hasField(fields, 'ggr_summary.poker_mtt_fees')}) 
                        poker_ggr @include(if: ${this.hasField(fields, 'ggr_summary.poker_ggr')}) 
                        total @include(if: ${this.hasField(fields, 'ggr_summary.total')})
                        mtt_total_buyin @include(if: ${this.hasField(fields, 'ggr_summary.mtt_total_buyin')})
                    }
                    ngr_summary @include(if: ${this.hasAnyField(fields, this.getNgrSummaryQueryItems())}) {
                        cash_ngr @include(if: ${this.hasField(fields, 'ngr_summary.cash_ngr')})
                        casino_ngr @include(if: ${this.hasField(fields, 'ngr_summary.casino_ngr')})
                        mtt_ngr @include(if: ${this.hasField(fields, 'ngr_summary.mtt_ngr')})
                        poker_ngr @include(if: ${this.hasField(fields, 'ngr_summary.poker_ngr')})
                        total @include(if: ${this.hasField(fields, 'ngr_summary.total')})
                    }
                    bonus_summary @include(if: ${this.hasAnyField(fields, this.getBonusSummaryQueryItems())}) {
                        mtt_ticket_bonus @include(if: ${this.hasField(fields, 'bonus_summary.mtt_ticket_bonus')})
                        poker_bonus @include(if: ${this.hasField(fields, 'bonus_summary.poker_bonus')})
                        casino_bonus @include(if: ${this.hasField(fields, 'bonus_summary.casino_bonus')})
                        realized_bonus_total @include(if: ${this.hasField(fields, 'bonus_summary.realized_bonus_total')})
                        unrealized_bonus_total @include(if: ${this.hasField(fields, 'bonus_summary.unrealized_bonus_total')})
                        cost_global_spins_tickets @include(if: ${this.hasField(fields, 'bonus_summary.cost_global_spins_tickets')})
                    }
                    deduction_summary @include(if: ${this.hasAnyField(fields, this.getDeductionSummaryQueryItems())}) {
                        casino_provider_fee @include(if: ${this.hasField(fields, 'deduction_summary.casino_provider_fee')})
                        ngr_cost @include(if: ${this.hasField(fields, 'deduction_summary.ngr_cost')})
                        psp_fee @include(if: ${this.hasField(fields, 'deduction_summary.psp_fee')})
                        total @include(if: ${this.hasField(fields, 'deduction_summary.total')})
                    }
                    player_games_summary @include(if: ${this.hasAnyField(fields, this.getPlayerGamesSummaryQueryItems())}) {
                        cash_game_hand_played @include(if: ${this.hasField(fields, 'player_games_summary.cash_game_hand_played')})
                        mtt_played @include(if: ${this.hasField(fields, 'player_games_summary.mtt_played')})
                        player_net_deposit @include(if: ${this.hasField(fields, 'player_games_summary.player_net_deposit')})
                        player_profit_loss @include(if: ${this.hasField(fields, 'player_games_summary.player_profit_loss')})
                    }
                    other_summary @include(if: ${this.hasAnyField(fields, this.getOtherSummaryQueryItems())}) {
                        cost_tournament_overlay_excl_freeroll @include(if: ${this.hasField(
                            fields,
                            'other_summary.cost_tournament_overlay_excl_freeroll'
                        )})
                    }
                    highest_blind_level_is_pro_nlhe @include(if: ${this.hasField(fields, 'highest_blind_level_is_pro_nlhe')})
                    pro_summary @include(if: ${this.hasAnyField(fields, this.getProSummaryQueryItems())}) {
                        is_pro_shortdeck @include(if: ${this.hasField(fields, 'pro_summary.is_pro_shortdeck')})
                        cost_spins_tournament_tickets_pro @include(if: ${this.hasField(
                            fields,
                            'pro_summary.cost_spins_tournament_tickets_pro'
                        )})
                        cost_ftd_bonus_pro @include(if: ${this.hasField(fields, 'pro_summary.cost_ftd_bonus_pro')})
                        cost_psp_fee_pro @include(if: ${this.hasField(fields, 'pro_summary.cost_psp_fee_pro')})
                        poker_ggr_usd_pro @include(if: ${this.hasField(fields, 'pro_summary.poker_ggr_usd_pro')})
                        nlhe_ggr_usd_pro @include(if: ${this.hasField(fields, 'pro_summary.nlhe_ggr_usd_pro')})
                        shortdeck_ggr_usd_pro @include(if: ${this.hasField(fields, 'pro_summary.shortdeck_ggr_usd_pro')})
                        nlhe_micro_ggr_usd_pro @include(if: ${this.hasField(fields, 'pro_summary.nlhe_micro_ggr_usd_pro')})
                        nlhe_low_ggr_usd_pro @include(if: ${this.hasField(fields, 'pro_summary.nlhe_low_ggr_usd_pro')})
                        nlhe_medium_ggr_usd_pro @include(if: ${this.hasField(fields, 'pro_summary.nlhe_medium_ggr_usd_pro')})
                        nlhe_high_ggr_usd_pro @include(if: ${this.hasField(fields, 'pro_summary.nlhe_high_ggr_usd_pro')})
                    }
                    
                    register_marketing_code @include(if: ${this.hasField(fields, 'register_marketing_code')})
                }
                total_count
                summary @include(if: ${this.hasField(fields, 'summary')}) {
                    ggr_summary @include(if: ${this.hasAnyField(fields, this.getGgrSummaryQueryItems())}) {
                        cash_ggr @include(if: ${this.hasField(fields, 'ggr_summary.cash_ggr')})
                        mtt_ggr @include(if: ${this.hasField(fields, 'ggr_summary.mtt_ggr')})
                        casino_ggr @include(if: ${this.hasField(fields, 'ggr_summary.casino_ggr')})
                        poker_share @include(if: ${this.hasField(fields, 'ggr_summary.poker_share')})
                        casino_share @include(if: ${this.hasField(fields, 'ggr_summary.casino_share')})
                        total_share @include(if: ${this.hasField(fields, 'ggr_summary.total_share')})
                        global_spin_ggr @include(if: ${this.hasField(fields, 'ggr_summary.global_spin_ggr')})
                        external_casino_ggr @include(if: ${this.hasField(fields, 'ggr_summary.external_casino_ggr')}) 
                        internal_casino_ggr @include(if: ${this.hasField(fields, 'ggr_summary.internal_casino_ggr')}) 
                        external_casino_bet_amount @include(if: ${this.hasField(fields, 'ggr_summary.external_casino_bet_amount')})
                        internal_casino_bet_amount @include(if: ${this.hasField(fields, 'ggr_summary.internal_casino_bet_amount')})
                        plo_ggr_high_stake @include(if: ${this.hasField(fields, 'ggr_summary.plo_ggr_high_stake')})
                        plo_ggr_low_stake @include(if: ${this.hasField(fields, 'ggr_summary.plo_ggr_low_stake')})
                        plo_ggr_medium_stake @include(if: ${this.hasField(fields, 'ggr_summary.plo_ggr_medium_stake')})
                        plo_ggr_micro_stake @include(if: ${this.hasField(fields, 'ggr_summary.plo_ggr_micro_stake')})
                        plo_ggr_total @include(if: ${this.hasField(fields, 'ggr_summary.plo_ggr_total')})
                        plo_ggr_unknown_stake @include(if: ${this.hasField(fields, 'ggr_summary.plo_ggr_unknown_stake')})
                        poker_rake @include(if: ${this.hasField(fields, 'ggr_summary.poker_rake')}) 
                        poker_addons @include(if: ${this.hasField(fields, 'ggr_summary.poker_addons')}) 
                        poker_mtt_fees @include(if: ${this.hasField(fields, 'ggr_summary.poker_mtt_fees')}) 
                        poker_ggr @include(if: ${this.hasField(fields, 'ggr_summary.poker_ggr')}) 
                        total @include(if: ${this.hasField(fields, 'ggr_summary.total')})
                        mtt_total_buyin @include(if: ${this.hasField(fields, 'ggr_summary.mtt_total_buyin')})
                    }
                    ngr_summary @include(if: ${this.hasAnyField(fields, this.getNgrSummaryQueryItems())}) {
                        cash_ngr @include(if: ${this.hasField(fields, 'ngr_summary.cash_ngr')})
                        casino_ngr @include(if: ${this.hasField(fields, 'ngr_summary.casino_ngr')})
                        mtt_ngr @include(if: ${this.hasField(fields, 'ngr_summary.mtt_ngr')})
                        poker_ngr @include(if: ${this.hasField(fields, 'ngr_summary.poker_ngr')})
                        total @include(if: ${this.hasField(fields, 'ngr_summary.total')})
                    }
                    bonus_summary @include(if: ${this.hasAnyField(fields, this.getBonusSummaryQueryItems())}) {
                        mtt_ticket_bonus @include(if: ${this.hasField(fields, 'bonus_summary.mtt_ticket_bonus')})
                        poker_bonus @include(if: ${this.hasField(fields, 'bonus_summary.poker_bonus')})
                        casino_bonus @include(if: ${this.hasField(fields, 'bonus_summary.casino_bonus')})
                        realized_bonus_total @include(if: ${this.hasField(fields, 'bonus_summary.realized_bonus_total')})
                        unrealized_bonus_total @include(if: ${this.hasField(fields, 'bonus_summary.unrealized_bonus_total')})
                        cost_global_spins_tickets @include(if: ${this.hasField(fields, 'bonus_summary.cost_global_spins_tickets')})
                    }
                    deduction_summary @include(if: ${this.hasAnyField(fields, this.getDeductionSummaryQueryItems())}) {
                        casino_provider_fee @include(if: ${this.hasField(fields, 'deduction_summary.casino_provider_fee')})
                        ngr_cost @include(if: ${this.hasField(fields, 'deduction_summary.ngr_cost')})
                        psp_fee @include(if: ${this.hasField(fields, 'deduction_summary.psp_fee')})
                        total @include(if: ${this.hasField(fields, 'deduction_summary.total')})
                    }
                    player_games_summary @include(if: ${this.hasAnyField(fields, this.getPlayerGamesSummaryQueryItems())}) {
                        cash_game_hand_played @include(if: ${this.hasField(fields, 'player_games_summary.cash_game_hand_played')})
                        mtt_played @include(if: ${this.hasField(fields, 'player_games_summary.mtt_played')})
                        player_net_deposit @include(if: ${this.hasField(fields, 'player_games_summary.player_net_deposit')})
                        player_profit_loss @include(if: ${this.hasField(fields, 'player_games_summary.player_profit_loss')})
                    }
                    other_summary @include(if: ${this.hasAnyField(fields, this.getOtherSummaryQueryItems())}) {
                        cost_tournament_overlay_excl_freeroll @include(if: ${this.hasField(
                            fields,
                            'other_summary.cost_tournament_overlay_excl_freeroll'
                        )})
                    }
                    pro_summary @include(if: ${this.hasAnyField(fields, this.getProSummaryQueryItems())}) {
                        is_pro_shortdeck @include(if: ${this.hasField(fields, 'pro_summary.is_pro_shortdeck')})
                        cost_spins_tournament_tickets_pro @include(if: ${this.hasField(
                            fields,
                            'pro_summary.cost_spins_tournament_tickets_pro'
                        )})
                        cost_ftd_bonus_pro @include(if: ${this.hasField(fields, 'pro_summary.cost_ftd_bonus_pro')})
                        cost_psp_fee_pro @include(if: ${this.hasField(fields, 'pro_summary.cost_psp_fee_pro')})
                        poker_ggr_usd_pro @include(if: ${this.hasField(fields, 'pro_summary.poker_ggr_usd_pro')})
                        nlhe_ggr_usd_pro @include(if: ${this.hasField(fields, 'pro_summary.nlhe_ggr_usd_pro')})
                        shortdeck_ggr_usd_pro @include(if: ${this.hasField(fields, 'pro_summary.shortdeck_ggr_usd_pro')})
                        nlhe_micro_ggr_usd_pro @include(if: ${this.hasField(fields, 'pro_summary.nlhe_micro_ggr_usd_pro')})
                        nlhe_low_ggr_usd_pro @include(if: ${this.hasField(fields, 'pro_summary.nlhe_low_ggr_usd_pro')})
                        nlhe_medium_ggr_usd_pro @include(if: ${this.hasField(fields, 'pro_summary.nlhe_medium_ggr_usd_pro')})
                        nlhe_high_ggr_usd_pro @include(if: ${this.hasField(fields, 'pro_summary.nlhe_high_ggr_usd_pro')})
                    }
                }
            }
        }
    `;

    private buildQueryVn(fields: AgentPlayerReportingQueryFields[]): DocumentNode {
        return gql`
            query GetAgentPlayerReportingsVn($filter: AgentPlayerReportingFilterVn, $sort: Sorting, $start: Int, $end: Int) {
                getAgentPlayerReportingsVn(filter: $filter, sort: $sort, end: $end, start: $start) {
                    items {
                        uid
                        contact @include(if: ${this.hasAnyField(fields, this.getContactQueryItems())}) {
                            email @include(if: ${this.hasField(fields, 'contact.email')})
                            mobile @include(if: ${this.hasAnyField(fields, this.getContactMobileQueryItems())}) {
                                full_number @include(if: ${this.hasField(fields, 'contact.mobile.full_number')})
                            }
                        }
                        referrer_agent_id @include(if: ${this.hasField(fields, 'referrer_agent_id')})
                        referrer_agent_username @include(if: ${this.hasField(fields, 'referrer_agent_username')})
                        revenue_share_type @include(if: ${this.hasField(fields, 'revenue_share_type')})
                        referee_type @include(if: ${this.hasField(fields, 'referee_type')})
                        rakes @include(if: ${this.hasField(fields, 'rakes')})
                        insurance_tool @include(if: ${this.hasField(fields, 'insurance_tool')})
                        casino_ggr @include(if: ${this.hasField(fields, 'casino_ggr')})
                        registered_player_number @include(if: ${this.hasField(fields, 'registered_player_number')})
                        active_player_number @include(if: ${this.hasField(fields, 'active_player_number')})
                        revenue_share @include(if: ${this.hasField(fields, 'revenue_share')})
                    }
                    total_count
                    summary @include(if: ${this.hasField(fields, 'summary')}) ${
            this.isFeatureDisabled(FeatureName.AgentReportingVnTotalGridRow) ? '@client' : ''
        } {
                        rakes @include(if: ${this.hasField(fields, 'rakes')})
                        insurance_tool @include(if: ${this.hasField(fields, 'insurance_tool')})
                        casino_ggr @include(if: ${this.hasField(fields, 'casino_ggr')})
                        revenue_share @include(if: ${this.hasField(fields, 'revenue_share')})
                    }
                }
            }
        `;
    }

    private buildQueryKr(fields: AgentPlayerReportingQueryFields[]): DocumentNode {
        return gql`
            query GetAgentPlayerReportingsKr($filter: AgentPlayerReportingFilter, $sort: Sorting, $start: Int, $end: Int) {
                getAgentPlayerReportingsKr(filter: $filter, sort: $sort, end: $end, start: $start) {
                    items {
                        uid
                        referrer_agent_id @include(if: ${this.hasField(fields, 'referrer_agent_id')})
                        referrer_agent_username @include(if: ${this.hasField(fields, 'referrer_agent_username')})
                        revenue_share_type @include(if: ${this.hasField(fields, 'revenue_share_type')})
                        referee_type @include(if: ${this.hasField(fields, 'referee_type')})
                        total_share @include(if: ${this.hasField(fields, 'total_share')})
                        poker_share @include(if: ${this.hasField(fields, 'poker_share')})
                        casino_share @include(if: ${this.hasField(fields, 'casino_share')})
                    }
                    total_count
                    summary @include(if: ${this.hasField(fields, 'summary')}) {
                        total_share @include(if: ${this.hasField(fields, 'total_share')})
                        poker_share @include(if: ${this.hasField(fields, 'poker_share')})
                        casino_share @include(if: ${this.hasField(fields, 'casino_share')})
                    }
                }
            }
        `;
    }

    protected buildFilter(
        filter: Filter<AgentPlayerReportingServerFilterKeys>
    ): QueryGetAgentPlayerReportingsArgs | QueryGetAgentPlayerReportingsVnArgs {
        const referrerId: string =
            this.toGQLStringFilter(filter, 'referrerPlayerId') ?? this.toGQLStringFilter(filter, 'defaultReferrerPlayerId');
        const dateFilter = this.toGQLDateRange(filter['transactionStartedTs.from'], filter['transactionStartedTs.to']);
        if (filter.region === 'vn' && dateFilter?.to?.seconds) {
            dateFilter.to.seconds = dateFilter?.to?.seconds + 1;
        }

        return {
            filter: {
                text: this.getGQLTextFilter(
                    Object.keys(this.filterFieldsMapper).map((key: AgentPlayerReportingServerTextFilterKeys) =>
                        this.toGQLTextFilter(this.filterFieldsMapper[key], filter[key] as string, this.transformTextMapper[key])
                    )
                ),
                transaction_started_ts: dateFilter,
                referrer: referrerId
                    ? {
                          referrer_player_id: referrerId,
                          is_downstream: this.toGQLBooleanFilter(filter, 'isDownstream'),
                          is_agent: true,
                      }
                    : undefined,
                report_version: this.toGQLStringFilter(filter, 'reportVersion') as AgentPlayerReportVersion,
            },
        };
    }

    private getContactQueryItems(): AgentPlayerReportingQueryFields[] {
        return ['contact.email', ...this.getContactMobileQueryItems()];
    }

    private getContactMobileQueryItems(): AgentPlayerReportingQueryFields[] {
        return ['contact.mobile.full_number'];
    }

    private getGgrSummaryQueryItems(): AgentPlayerReportingQueryFields[] {
        return [
            'ggr_summary.cash_ggr',
            'ggr_summary.mtt_ggr',
            'ggr_summary.casino_ggr',
            'ggr_summary.poker_share',
            'ggr_summary.casino_share',
            'ggr_summary.total_share',
            'ggr_summary.global_spin_ggr',
            'ggr_summary.external_casino_ggr',
            'ggr_summary.internal_casino_ggr',
            'ggr_summary.external_casino_bet_amount',
            'ggr_summary.internal_casino_bet_amount',
            'ggr_summary.poker_rake',
            'ggr_summary.poker_addons',
            'ggr_summary.poker_mtt_fees',
            'ggr_summary.poker_ggr',
            'ggr_summary.total',
            'ggr_summary.plo_ggr_high_stake',
            'ggr_summary.plo_ggr_low_stake',
            'ggr_summary.plo_ggr_medium_stake',
            'ggr_summary.plo_ggr_micro_stake',
            'ggr_summary.plo_ggr_total',
            'ggr_summary.plo_ggr_unknown_stake',
            'ggr_summary.mtt_total_buyin',
        ];
    }

    private getNgrSummaryQueryItems(): AgentPlayerReportingQueryFields[] {
        return ['ngr_summary.total', 'ngr_summary.cash_ngr', 'ngr_summary.casino_ngr', 'ngr_summary.mtt_ngr', 'ngr_summary.poker_ngr'];
    }

    private getBonusSummaryQueryItems(): AgentPlayerReportingQueryFields[] {
        return [
            'bonus_summary.mtt_ticket_bonus',
            'bonus_summary.poker_bonus',
            'bonus_summary.casino_bonus',
            'bonus_summary.realized_bonus_total',
            'bonus_summary.unrealized_bonus_total',
            'bonus_summary.cost_global_spins_tickets',
        ];
    }

    private getDeductionSummaryQueryItems(): AgentPlayerReportingQueryFields[] {
        return [
            'deduction_summary.casino_provider_fee',
            'deduction_summary.ngr_cost',
            'deduction_summary.psp_fee',
            'deduction_summary.total',
        ];
    }

    private getPlayerGamesSummaryQueryItems(): AgentPlayerReportingQueryFields[] {
        return [
            'player_games_summary.cash_game_hand_played',
            'player_games_summary.mtt_played',
            'player_games_summary.player_net_deposit',
            'player_games_summary.player_profit_loss',
        ];
    }

    private getOtherSummaryQueryItems(): AgentPlayerReportingQueryFields[] {
        return ['other_summary.cost_tournament_overlay_excl_freeroll'];
    }

    private getProSummaryQueryItems(): AgentPlayerReportingQueryFields[] {
        return [
            'pro_summary.is_pro_shortdeck',
            'pro_summary.cost_spins_tournament_tickets_pro',
            'pro_summary.cost_ftd_bonus_pro',
            'pro_summary.cost_psp_fee_pro',
            'pro_summary.poker_ggr_usd_pro',
            'pro_summary.nlhe_ggr_usd_pro',
            'pro_summary.shortdeck_ggr_usd_pro',
            'pro_summary.nlhe_micro_ggr_usd_pro',
            'pro_summary.nlhe_low_ggr_usd_pro',
            'pro_summary.nlhe_medium_ggr_usd_pro',
            'pro_summary.nlhe_high_ggr_usd_pro',
        ];
    }

    private readonly filterFieldsMapper: Record<AgentPlayerReportingServerTextFilterKeys, string[]> = {
        uid_email: nameof.toArray<AgentPlayerReporting>(m => [m.uid, nameof.full(m.contact.email, 1)]),
        uid_em_un_rmc: nameof.toArray<AgentPlayerReporting>(m => [
            m.uid,
            nameof.full(m.contact.email, 1),
            m.username,
            nameof<AgentPlayerReporting>(m => m.register_marketing_code),
        ]),
        uid: nameof.toArray<AgentPlayerReporting>(m => [m.uid]),
        'contact.email': nameof.toArray<AgentPlayerReporting>(m => [nameof.full(m.contact.email, 1)]),
        username: nameof.toArray<AgentPlayerReporting>(m => [m.username]),
        register_marketing_code: [nameof<AgentPlayerReporting>(m => m.register_marketing_code)],
    };

    private transformTextMapper: Partial<Record<AgentPlayerReportingServerTextFilterKeys, (value: string) => string>> = {
        'contact.email': this.ignoreCase,
    };
}
