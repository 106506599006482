import React from 'react';
import {Box, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

const useClasses = makeStyles()(theme => ({
    keyValueContainer: {
        width: '100%',
        padding: theme.spacing(0, 4),
    },
    keyValueHeader: {
        marginBottom: theme.spacing(2),
    },
    keyValueContent: {
        width: '100%',
        display: 'grid',
        columnGap: theme.spacing(5),
        gridTemplateColumns: 'repeat(auto-fit, minmax(0, 1fr))',
    },
}));

type KeyValueTableProps = {
    title?: string;
    children: React.ReactNode;
};

export function KeyValueTable({title, children}: KeyValueTableProps) {
    const {classes} = useClasses();
    return (
        <Box className={classes.keyValueContainer}>
            {title && (
                <Typography className={classes.keyValueHeader} variant="h6">
                    {title}
                </Typography>
            )}
            <Box className={classes.keyValueContent}>{children}</Box>
        </Box>
    );
}
