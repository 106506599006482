import React from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {useService} from '@inversify';
import {FeatureAccessStatus} from '@models/generated/graphql';

import {ModuleName, SubmoduleName} from '../../../common/routeEnums';
import {withPermissionAndJurisdictionConfig} from '../../app/permission/PermissionHoc';
import {
    bulkActionsActions,
    BulkChooseActionStepLockItem,
    BulkLockItemValue,
    itemsSelector,
    p2pTransferLockResultValueSelector,
} from '../../block-bulk-actions';
import {UserProfileGridItem} from '../../data-grids/types';
import {PermissionEnum} from '../../module-shared/types';
import {ApplyP2PLockRequest, ApplyP2PLockStrategy} from '../services/applyStrategy';

const localized = defineMessages({
    p2pTransferStatus: {
        id: 'BulkActionP2PLock_p2pTransferStatus',
        defaultMessage: 'P2P transfer',
    },
});

function ActionP2PLock() {
    const dispatch = useDispatch();
    const strategy = useService<ApplyP2PLockStrategy>('ApplyP2PLockStrategy');
    const players = useSelector((state: RootState) => itemsSelector<UserProfileGridItem>(state));
    const initialValues = players.map(i => i.p2p_transfer_status === FeatureAccessStatus.Locked);
    const resultValue: FeatureAccessStatus = useSelector(p2pTransferLockResultValueSelector);

    function handleSubmit(toggleValue: BulkLockItemValue): void {
        const valueMapper: Record<BulkLockItemValue, FeatureAccessStatus> = {
            [BulkLockItemValue.Checked]: FeatureAccessStatus.Locked,
            [BulkLockItemValue.Unchecked]: FeatureAccessStatus.Unlocked,
            [BulkLockItemValue.NoChanges]: null,
        };
        const request: ApplyP2PLockRequest = {players, value: valueMapper[toggleValue]};
        dispatch(bulkActionsActions.apply({strategy, request}));
    }

    return (
        <BulkChooseActionStepLockItem
            label={localized.p2pTransferStatus}
            initialValues={initialValues}
            resultValue={resultValue ? resultValue === FeatureAccessStatus.Locked : null}
            onSubmit={handleSubmit}
        />
    );
}

export const BulkUserProfileP2PLock = withPermissionAndJurisdictionConfig(ActionP2PLock, {
    moduleName: ModuleName.ActionCenter,
    submoduleName: SubmoduleName.FinanceActions,
    permissions: [PermissionEnum.Update],
});
