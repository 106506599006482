import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {GridColDef} from '@components/data-grid/mui';
import {Policy} from '@auth';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {withModule} from '../../../features/module-shared/components/ModuleHoc';
import ModulePage from '../../../features/module-shared/components/ModulePage';
import {PermissionEnum} from '../../../features/module-shared/types';
import actions from '../actions';
import {domain} from '../types';

import data from './AccessManagement.config';
import UserEditForm from './UserEditForm';

const AccessManagement = () => {
    const dispatch = useDispatch();
    const roles = useSelector((state: RootState) => state.users.data.roles?.items);
    const stateData = useSelector((state: RootState) => state.users);

    useEffect(() => {
        dispatch(actions.getRoles.request());
    }, []);

    return (
        <Fragment>
            <ModulePage
                domain={domain}
                data={stateData}
                header={data.localized.title}
                filters={data.getFilters(roles)}
                moduleActions={data.actions}
                columns={data.columns as GridColDef[]}
                defaultSorting={[]}
                form={
                    <UserEditForm
                        domain={domain}
                        headers={data.columns as GridColDef[]}
                        headerItemName={data.localized.item}
                        stateSelector={(state: RootState) => state.users.data.editForm}
                        submitAction={actions.saveUser.request}
                        closeAction={actions.closeUserForm}
                    ></UserEditForm>
                }
            ></ModulePage>
        </Fragment>
    );
};

export const accessManagementResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'AccessManagement',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.AccessManagement, null, 'read'),
        new Policy(ModuleName.AccessManagement, SubmoduleName.Users, 'read'),
    ]),
});

export default withModule(AccessManagement, [
    {moduleName: ModuleName.AccessManagement, submoduleName: SubmoduleName.Users, permissions: [PermissionEnum.Read]},
]);
