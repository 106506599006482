import React, {Fragment, useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {MessageDescriptor} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {Box, Checkbox, Collapse, FormControlLabel, FormGroup, Grid, TextField, Typography} from '@mui/material';
import produce from 'immer';
import {makeStyles} from 'tss-react/mui';

import {IconButton} from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {FormTextInputDefault, RuleType, useValidationFormatter} from '@components/input';
import {BoRole, Permission, Policy} from '@redux/entity';
import {CustomTheme} from '@style';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {useJurisdictionConfig} from '../../../features/app/config/hooks';
import {localizedModuleName} from '../../../features/app/intl/shared-resources/moduleName';
import {localizedPermission} from '../../../features/app/intl/shared-resources/permission';
import {localizedSubmoduleName} from '../../../features/app/intl/shared-resources/submoduleName';
import withModuleEditForm, {ModuleFormInnerProps} from '../../../features/module-shared/components/ModuleEditForm';
import {PermissionEnum} from '../../../features/module-shared/types';
import {Module} from '../../../features/modules/types';
import {loadModulesWithDetailsAction} from '../actions';
import {localizedRoleManagement} from '../localize';
import {
    businessRulesModulesSelector,
    entityModulesSelector,
    oldModulesSelector,
    pageModulesSelector,
    submodulesSelector,
} from '../selectors';

type PolicyEditRowProps = {
    module: Module;
    policies: Policy[];
    onChange: (policies: Policy[]) => void;
    isEditDisabled?: boolean;
};

type PermissionHeader = {
    action: PermissionEnum;
    name: MessageDescriptor;
};

const makeClasses = makeStyles()((theme: CustomTheme) => ({
    module: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    moduleCollapse: {
        width: '100%',
    },
    moduleCell: {
        textAlign: 'center',
    },
    submodule: {
        paddingLeft: theme.spacing(4),
    },
    permissionCell: {
        margin: 'auto',
    },
    formControl: {
        margin: theme.spacing(2, 0),
    },
    form: {
        margin: theme.spacing(2, 0),
        overflow: 'auto',
    },
    policyRows: {
        display: 'grid',
        rowGap: theme.spacing(2),
    },
}));

const permissionHeaders = Object.keys(PermissionEnum)
    .filter(p => p !== PermissionEnum.All.toString())
    .reduce((result, key) => (!isNaN(Number(key)) ? [...result, Number(key)] : result), [] as number[])
    .map(
        (key: number) =>
            ({
                action: key as PermissionEnum,
                name: localizedPermission[key as PermissionEnum],
            } as PermissionHeader)
    );

const PolicyEditRow = ({module, policies, onChange, isEditDisabled}: PolicyEditRowProps) => {
    const {classes} = makeClasses();
    const allPermissions = permissionHeaders.map(header => ({action: header.action} as Permission));
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    const hasSubmodules = () => {
        return module.submodules !== undefined && module.submodules !== null && module.submodules.length !== 0;
    };

    const isModuleItemChecked = (policy: Policy) => {
        return policy?.permissions !== undefined && policy?.permissions.length === permissionHeaders.length;
    };

    const isPermissionChecked = (policy: Policy, action: PermissionEnum) => {
        return (
            policy?.permissions !== undefined &&
            policy?.permissions.length !== 0 &&
            policy?.permissions.some(permission => permission.action === action)
        );
    };

    const getModulePolicy = () => {
        return policies.find(policy => policy.module?.id === module.id);
    };

    const getSubmodulePolicy = (submoduleId: number) => {
        return policies.find(policy => policy.submodule?.id === submoduleId);
    };

    const isModuleChecked = () => {
        return isModuleItemChecked(getModulePolicy());
    };

    const isModulePermissionChecked = (action: PermissionEnum) => {
        return isPermissionChecked(getModulePolicy(), action);
    };

    const isSubmoduleChecked = (submoduleId: number) => {
        return isModuleItemChecked(getSubmodulePolicy(submoduleId));
    };

    const isSubmodulePermissionChecked = (submoduleId: number, action: PermissionEnum) => {
        return isPermissionChecked(getSubmodulePolicy(submoduleId), action);
    };

    const handleModuleChange = (checked: boolean) => {
        handleChange(policies.map(policy => ({...policy, permissions: checkAllPermissions(checked)})));
    };

    const handleSubmoduleChange = (submoduleId: number, checked: boolean) => {
        handleChange(
            policies.map(policy => (policy.submodule?.id !== submoduleId ? policy : {...policy, permissions: checkAllPermissions(checked)}))
        );
    };

    const handleModulePermissionChange = (action: PermissionEnum, checked: boolean) => {
        handleChange(policies.map(policy => checkPermission(policy, action, checked)));
    };

    const handleSubmodulePermissionChange = (submoduleId: number, action: PermissionEnum, checked: boolean) => {
        handleChange(policies.map(policy => (policy.submodule?.id !== submoduleId ? policy : checkPermission(policy, action, checked))));
    };

    const handleChange = (policies: Policy[]) => {
        const updatePolicies = policies.map(policy => {
            let policyPermissions = policy.permissions;
            if (policy.module) {
                const submodulePolicies = policies.filter(p => p.submodule !== undefined && p.submodule !== null);

                if (submodulePolicies !== undefined && submodulePolicies.length !== 0) {
                    permissionHeaders.forEach(permission => {
                        const isAllSubmodulesHavePermission = submodulePolicies.every(
                            s => s.permissions.length !== 0 && s.permissions.findIndex(p => p.action === permission.action) !== -1
                        );
                        const policyPermission = policy.permissions.find(p => p.action === permission.action);
                        const shouldAddModulePermission = isAllSubmodulesHavePermission && policyPermission === undefined;
                        const shouldRemoveModulePermission = !isAllSubmodulesHavePermission && policyPermission !== undefined;

                        if (shouldAddModulePermission) {
                            policyPermissions = [...policyPermissions, {action: permission.action} as Permission];
                        } else if (shouldRemoveModulePermission) {
                            policyPermissions = policyPermissions.reduce((res, per) => {
                                return per.action === permission.action ? res : [...res, per];
                            }, [] as Permission[]);
                        }
                    });
                }
            }
            return {...policy, permissions: policyPermissions};
        });

        onChange(updatePolicies);
    };

    const checkAllPermissions = (checked: boolean) => {
        return checked ? allPermissions : [];
    };

    const checkPermission = (policy: Policy, action: PermissionEnum, checked: boolean) => {
        const permission = {
            action: action,
        } as Permission;

        const updatedPermissions = produce(policy.permissions, permissions => {
            const index = permissions.findIndex(o => o.action === permission.action);
            const isFound = index !== -1;
            if (checked && !isFound) {
                permissions.push(permission);
            } else if (!checked && isFound) {
                permissions.splice(index, 1);
            }
        });

        return {...policy, permissions: updatedPermissions};
    };

    return (
        <React.Fragment>
            <Grid container item xs={12}>
                <Grid item xs={4}>
                    <Box className={classes.module}>
                        <FormGroup row>
                            <FormControlLabel
                                label={<LocalizedText label={localizedModuleName[module.name as ModuleName]} />}
                                control={
                                    <Checkbox
                                        checked={isModuleChecked()}
                                        disabled={isEditDisabled}
                                        onChange={(_, checked) => handleModuleChange(checked)}
                                        color="secondary"
                                    />
                                }
                            />
                        </FormGroup>
                        {!hasSubmodules() ? null : (
                            <IconButton onClick={() => handleToggle()}>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>
                        )}
                    </Box>
                </Grid>
                {permissionHeaders.map(header => (
                    <Grid className={classes.moduleCell} item xs={2} key={header.action}>
                        <Checkbox
                            checked={isModulePermissionChecked(header.action)}
                            disabled={isEditDisabled}
                            onChange={(_, checked) => handleModulePermissionChange(header.action, checked)}
                            color="secondary"
                        />
                    </Grid>
                ))}
            </Grid>

            {!hasSubmodules() ? null : (
                <Collapse className={classes.moduleCollapse} in={open}>
                    <Grid container item xs={12}>
                        {module.submodules.map(submodule => (
                            <Fragment key={submodule.id}>
                                <Grid item xs={4}>
                                    <FormGroup row className={classes.submodule}>
                                        <FormControlLabel
                                            label={
                                                <LocalizedText
                                                    label={localizedSubmoduleName[submodule.name as SubmoduleName] ?? submodule.displayName}
                                                />
                                            }
                                            control={
                                                <Checkbox
                                                    checked={isSubmoduleChecked(submodule.id)}
                                                    disabled={isEditDisabled}
                                                    onChange={(_, checked) => handleSubmoduleChange(submodule.id, checked)}
                                                    color="secondary"
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                                {permissionHeaders.map(header => (
                                    <Grid className={classes.moduleCell} item xs={2} key={header.action}>
                                        <Checkbox
                                            checked={isSubmodulePermissionChecked(submodule.id, header.action)}
                                            disabled={isEditDisabled}
                                            onChange={(_, checked) => handleSubmodulePermissionChange(submodule.id, header.action, checked)}
                                            color="secondary"
                                        />
                                    </Grid>
                                ))}
                            </Fragment>
                        ))}
                    </Grid>
                </Collapse>
            )}
        </React.Fragment>
    );
};

const RoleForm = ({value, control, setValue, isEditDisabled}: ModuleFormInnerProps<BoRole>) => {
    const {classes} = makeClasses();
    const dispatch = useDispatch();
    const validationMessageFormatter = useValidationFormatter();

    const oldModules = useSelector(oldModulesSelector);
    const pageModules = useSelector(pageModulesSelector);
    const entityModules = useSelector(entityModulesSelector);
    const businessRulesModules = useSelector(businessRulesModulesSelector);
    const modules = [...oldModules, ...pageModules, ...entityModules, ...businessRulesModules];
    const submodules = useSelector(submodulesSelector);
    const {limitedFeatures: hiddenFeatures} = useJurisdictionConfig();

    useEffect(() => {
        dispatch(loadModulesWithDetailsAction.request(hiddenFeatures));
    }, []);

    const getDefaultPolicy = (item: Module, moduleId: number, isSubmodule: boolean) => {
        let defaultPolicy = {};
        let permissions = [] as Permission[];

        if (isSubmodule) {
            defaultPolicy = {...defaultPolicy, submodule: {...item, parent: {id: moduleId}}};
            if (value.policies !== undefined) {
                permissions = (value.policies?.find(p => p.module?.id === moduleId)?.permissions as Permission[]) ?? [];
            }
        } else {
            defaultPolicy = {...defaultPolicy, module: item};
        }

        return {...defaultPolicy, permissions: permissions} as Policy;
    };

    const getDefaultValue = () => {
        const defaultValue = value as BoRole;
        return {
            ...defaultValue,
            [nameof<BoRole>(u => u.policies)]: [...modules, ...submodules].map(item => {
                const isSubmodule = item.submodules === undefined;
                const moduleId = isSubmodule ? modules?.find(m => m.submodules?.map(s => s.id).includes(item.id))?.id : item.id;

                const policy =
                    defaultValue.policies?.find(
                        p => (!isSubmodule && p.module?.id === item.id) || (isSubmodule && p.submodule?.id === item.id)
                    ) ?? getDefaultPolicy(item, moduleId, isSubmodule);

                return policy;
            }),
        };
    };

    const defaultValue = getDefaultValue() as BoRole;

    useEffect(() => {
        setFormData(defaultValue);
    }, [defaultValue.id]);

    useEffect(() => {
        if (modules.length !== 0) {
            setFormData(defaultValue);
        }
    }, [modules?.map(m => m?.name)?.join()]);

    const [formData, setFormData] = useState(defaultValue);
    useEffect(() => {
        setValue(
            nameof<BoRole>(u => u.policies),
            formData.policies
        );
    }, [formData]);

    const isPermissionChecked = (action: PermissionEnum) => {
        return (
            formData.policies.length !== 0 &&
            formData.policies.every(
                policy =>
                    policy.permissions !== undefined && policy.permissions.findIndex(permission => permission.action === action) !== -1
            )
        );
    };

    const getModulePolicies = (module: Module) => {
        return formData.policies.filter(
            policy => policy.module?.id === module.id || module.submodules?.map(s => s.id)?.includes(policy.submodule?.id)
        );
    };

    const handlePermissionChange = (action: PermissionEnum, checked: boolean) => {
        const permission = {
            action: action,
        } as Permission;

        setFormData(
            produce(formData, draft => {
                draft.policies.forEach(policy => {
                    const index = policy.permissions.findIndex(o => o.action === permission.action);
                    const isFound = index !== -1;

                    if (checked && !isFound) {
                        policy.permissions.push(permission);
                    } else if (!checked && isFound) {
                        policy.permissions.splice(index, 1);
                    }
                });
            })
        );
    };

    const handleModulePoliciesChange = (updatedPolicies: Policy[]) => {
        setFormData(
            produce(formData, draft => {
                draft.policies = draft.policies.map(policy => {
                    const isSubmodulePolicy = policy.submodule?.id !== undefined;
                    const updatedPolicy = isSubmodulePolicy
                        ? updatedPolicies.find(p => p.submodule?.id === policy.submodule?.id)
                        : updatedPolicies.find(p => p.module?.id === policy.module?.id);

                    return updatedPolicy ?? policy;
                });
            })
        );
    };

    return (
        <Box className={classes.form}>
            <Box display="none">
                <Controller render={_ => <TextField hidden />} name="id" control={control} defaultValue={value?.id ?? ''} />
            </Box>
            <Controller
                render={({field, fieldState}) => (
                    <FormTextInputDefault
                        value={field.value}
                        onChange={field.onChange}
                        label={localizedRoleManagement.name}
                        placeholder={localizedRoleManagement.name}
                        fieldState={fieldState}
                        disabled={isEditDisabled}
                        hideLabel={true}
                    />
                )}
                defaultValue=""
                name="name"
                control={control}
                rules={{required: validationMessageFormatter(RuleType.Required, localizedRoleManagement.name)}}
            />
            <Box className={classes.formControl}>
                <Controller
                    render={_ => (
                        <Grid container>
                            <Grid container item xs={12}>
                                <Grid item xs={4}></Grid>
                                {permissionHeaders.map(header => (
                                    <Grid item xs={2} key={header.action}>
                                        <FormGroup row>
                                            <FormControlLabel
                                                className={classes.permissionCell}
                                                labelPlacement="bottom"
                                                control={
                                                    <Checkbox
                                                        checked={isPermissionChecked(header.action)}
                                                        disabled={isEditDisabled}
                                                        onChange={(_, checked) => handlePermissionChange(header.action, checked)}
                                                        color="secondary"
                                                    />
                                                }
                                                label={<LocalizedText label={header.name} />}
                                            />
                                        </FormGroup>
                                    </Grid>
                                ))}
                            </Grid>
                            <Box className={classes.policyRows}>
                                <Box>
                                    <Typography variant="h6" color="secondary">
                                        <LocalizedText label={localizedRoleManagement.pagePermissions} />
                                    </Typography>
                                    {oldModules.map(module => (
                                        <PolicyEditRow
                                            key={module.id}
                                            module={module}
                                            isEditDisabled={isEditDisabled}
                                            policies={getModulePolicies(module)}
                                            onChange={policies => handleModulePoliciesChange(policies)}
                                        />
                                    ))}
                                    {pageModules.map(module => (
                                        <PolicyEditRow
                                            key={module.id}
                                            module={module}
                                            isEditDisabled={isEditDisabled}
                                            policies={getModulePolicies(module)}
                                            onChange={policies => handleModulePoliciesChange(policies)}
                                        />
                                    ))}
                                </Box>
                                <Box>
                                    <Typography variant="h6" color="secondary">
                                        <LocalizedText label={localizedRoleManagement.entityPermissions} />
                                    </Typography>
                                    {entityModules.map(module => (
                                        <PolicyEditRow
                                            key={module.id}
                                            module={module}
                                            isEditDisabled={isEditDisabled}
                                            policies={getModulePolicies(module)}
                                            onChange={policies => handleModulePoliciesChange(policies)}
                                        />
                                    ))}
                                </Box>
                                <Box>
                                    <Typography variant="h6" color="secondary">
                                        <LocalizedText label={localizedRoleManagement.businessRulePermissions} />
                                    </Typography>
                                    {businessRulesModules.map(module => (
                                        <PolicyEditRow
                                            key={module.id}
                                            module={module}
                                            isEditDisabled={isEditDisabled}
                                            policies={getModulePolicies(module)}
                                            onChange={policies => handleModulePoliciesChange(policies)}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                    )}
                    name="policies"
                    control={control}
                    defaultValue={[]}
                />
            </Box>
        </Box>
    );
};

const RoleEditForm = withModuleEditForm<BoRole>(RoleForm, BoRole);

export default RoleEditForm;
