import {MuiDataGridClient, MuiDataGridServer, withDataGridEntityClient, withDataGridEntityServer} from '@components/data-grid/mui';

import {DataGridUserProfileConfigFactory} from '../../block-user-profile-list';
import {AccountVerificationWithUserProfileGridItem, DataGridAccountVerificationWithUserProfileColumns} from '../types';

import {DataGridAccountVerificationConfigFactory} from './DataGridAccountVerification';

export const DataGridAccountVerificationWithUserProfileClient = withDataGridEntityClient<
    DataGridAccountVerificationWithUserProfileColumns,
    AccountVerificationWithUserProfileGridItem
>(MuiDataGridClient, () => ({
    ...new DataGridUserProfileConfigFactory().getColumnConfig(),
    ...new DataGridAccountVerificationConfigFactory().getColumnConfig(),
}));

export const DataGridAccountVerificationWithUserProfileServerKYC = withDataGridEntityServer<
    DataGridAccountVerificationWithUserProfileColumns,
    AccountVerificationWithUserProfileGridItem
>(MuiDataGridServer, () => ({
    ...new DataGridUserProfileConfigFactory().getColumnConfig(),
    ...new DataGridAccountVerificationConfigFactory('KYC').getColumnConfig(),
}));

export const DataGridAccountVerificationWithUserProfileServerNDRP = withDataGridEntityServer<
    DataGridAccountVerificationWithUserProfileColumns,
    AccountVerificationWithUserProfileGridItem
>(MuiDataGridServer, () => ({
    ...new DataGridUserProfileConfigFactory().getColumnConfig(),
    ...new DataGridAccountVerificationConfigFactory('NDRP').getColumnConfig(),
}));
