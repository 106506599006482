import {defineMessages} from 'react-intl';

import {GridColDef, renderCellHeader, renderCellWithTooltip} from '@components/data-grid/mui';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {Sorting} from 'src/common/types';
import {PermissionEnum} from '../../../features/module-shared/types';
import FilterTextField from '../../../features/shared/filter/components/FilterText';
import {Filter, FilterPlacement} from '../../../features/shared/filter/types';
import {LegalDocType, VersionAcceptanceDocument} from '../types';
import {VersionAcceptanceGridItem} from '../types';

import data from './data';
import {renderVersionAcceptanceCell} from './VersionAcceptanceAction';
import renderVersionAcceptanceDocumentAcceptanceDate from './VersionAcceptanceDocumentAcceptanceDate';
import VersionAcceptanceGridHeader from './VersionAcceptanceGridHeader';

const localized = defineMessages({
    title: {
        id: 'LD_title',
        defaultMessage: 'User Acceptance Version',
    },
    userId: {
        id: 'LD_userId',
        defaultMessage: 'User Id',
    },
    email: {
        id: 'LD_email',
        defaultMessage: 'Email',
    },
    privacyPolicy: {
        id: 'LD_privacyPolicy',
        defaultMessage: 'Privacy Policy version',
    },
    terms: {
        id: 'LD_terms',
        defaultMessage: 'Terms And Conditions version',
    },
    date: {
        id: 'LD_date',
        defaultMessage: 'Accepted Date',
    },
    version: {
        id: 'LD_version',
        defaultMessage: 'Version',
    },
    userSearch: {
        id: 'LD_userSearch',
        defaultMessage: 'User',
    },
    userSearchLabel: {
        id: 'LD_userSearchLabel',
        defaultMessage: 'Search by user email',
    },
});

const getGridColumns = () => {
    const columns: GridColDef[] = [
        {
            field: nameof<VersionAcceptanceGridItem>(u => u.id),
            hide: true,
        },
        {
            field: nameof<VersionAcceptanceGridItem>(u => u.userId),
            renderHeader: () => renderCellHeader(localized.userId),
            sortField: nameof<VersionAcceptanceGridItem>(u => u.id),
        },
        {
            field: nameof<VersionAcceptanceGridItem>(u => u.email),
            renderHeader: () => renderCellHeader(localized.email),
            width: 300,
        },
        {
            field: `${nameof<VersionAcceptanceGridItem>(u => u.documents)}.${nameof<VersionAcceptanceDocument>(u => u.version)}.${
                LegalDocType.PrivacyPolicy
            }`,
            renderCell: params => renderVersionAcceptanceCell(params, LegalDocType.PrivacyPolicy),
            renderHeader: () => VersionAcceptanceGridHeader(data.localized.privacyPolicy, localized.version),

            width: 250,
            sortable: false,
        },
        {
            field: `${nameof<VersionAcceptanceGridItem>(u => u.documents)}.${nameof<VersionAcceptanceDocument>(u => u.acceptedAt)}.${
                LegalDocType.PrivacyPolicy
            }`,
            renderCell: params => renderVersionAcceptanceDocumentAcceptanceDate(params, LegalDocType.PrivacyPolicy),
            renderHeader: () => VersionAcceptanceGridHeader(data.localized.privacyPolicy, localized.date),

            width: 250,
            sortable: false,
        },
        {
            field: `${nameof<VersionAcceptanceGridItem>(u => u.documents)}.${nameof<VersionAcceptanceDocument>(u => u.version)}.${
                LegalDocType.TermsAndConditions
            }`,
            renderCell: params => renderVersionAcceptanceCell(params, LegalDocType.TermsAndConditions),
            renderHeader: () => VersionAcceptanceGridHeader(data.localized.termsAndConditions, localized.version),

            width: 250,
            sortable: false,
        },
        {
            field: `${nameof<VersionAcceptanceGridItem>(u => u.documents)}.${nameof<VersionAcceptanceDocument>(u => u.acceptedAt)}.${
                LegalDocType.TermsAndConditions
            }`,
            renderCell: params => renderVersionAcceptanceDocumentAcceptanceDate(params, LegalDocType.TermsAndConditions),
            renderHeader: () => VersionAcceptanceGridHeader(data.localized.termsAndConditions, localized.date),

            width: 250,
            sortable: false,
        },
    ];

    const defaultCol: GridColDef = {
        field: '',
        width: 200,
        permissions: [PermissionEnum.Read],
        moduleName: ModuleName.LegalContentManagement,
        submoduleName: SubmoduleName.UserAcceptanceVersion,
        renderCell: renderCellWithTooltip,
    };

    return columns.map(c => ({...defaultCol, ...c}));
};

const getFilters = () =>
    [
        {
            key: nameof<VersionAcceptanceGridItem>(u => u.email),
            name: localized.userSearch,
            label: localized.userSearchLabel,
            component: FilterTextField,
            placement: FilterPlacement.Primary,
        },
    ] as Filter[];

const defaultSorting: Sorting<string>[] = [{field: nameof<VersionAcceptanceGridItem>(u => u.id), sort: 'desc'}];

export default {
    localized,
    columns: getGridColumns(),
    filters: getFilters(),
    defaultSorting,
};
