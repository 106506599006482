import React from 'react';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource, withPolicyAccessCheck} from '@access-control';
import {ErrorModal} from '@components/error';
import {withTraceErrorBoundary} from '@otel';
import {Policy} from '@auth';

import {ModuleName} from '../common/routeEnums';
import {KycDetails} from '../features/block-account-verification';

export const kycProcessingDetailsPageResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'KycProcessingDetailsPage',
    rules: new PoliciesAccessRule([new Policy(ModuleName.KycProcessing), new Policy(ModuleName.KycProcessing, null, 'read')]),
});

function KycProcessingDetailsPage() {
    return <KycDetails type="kyc" />;
}

export default withTraceErrorBoundary(withPolicyAccessCheck(KycProcessingDetailsPage, kycProcessingDetailsPageResource), ErrorModal);
