import {defineMessages} from 'react-intl';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    MuiDataGridClient,
    MuiDataGridServer,
    renderCellHeader,
    renderCellWithTooltip,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import {PlayerViewModel} from '@models/game-room/types';
import {gameTableUpdatePermission} from '@models/permissions/permissions';

import {RemovePlayerActionCellProps, renderRemovePlayerActionCell} from '../../block-game-room-actions/components/RemovePlayerButtonCell';

const localizedHeaders = defineMessages({
    playerId: {
        id: 'DataGridPlayer_playerId',
        defaultMessage: 'ID',
    },
    name: {
        id: 'DataGridPlayer_name',
        defaultMessage: 'Player',
    },
});

type GamePlayerKeys = keyof PlayerViewModel | 'remove';

class DataGridPlayerConfigFactory extends DataGridEntityConfigFactory<GamePlayerKeys, PlayerViewModel> {
    getColumnConfig(): Partial<Record<GamePlayerKeys, DataGridEntityColDef<GamePlayerKeys>>> {
        const columnsMapping: Partial<Record<GamePlayerKeys, DataGridEntityColDef<GamePlayerKeys>>> = {
            playerId: {
                field: 'playerId',
                valueGetter: p => this.getPlayerId(p),
                renderHeader: () => renderCellHeader(localizedHeaders.playerId),
                renderCell: renderCellWithTooltip,
                flex: 1,
                sortable: false,
            },
            name: {
                field: 'name',
                valueGetter: p => this.getName(p),
                renderHeader: () => renderCellHeader(localizedHeaders.name),
                renderCell: renderCellWithTooltip,
                flex: 1,
                sortable: false,
            },
            remove: {
                field: 'remove',
                valueGetter: p => this.getDataForRemove(p),
                headerName: ' ',
                renderCell: renderRemovePlayerActionCell,
                flex: 1,
                sortable: false,
                ...gameTableUpdatePermission,
            },
        };
        return columnsMapping;
    }

    private getPlayerId(p: GridValueGetterParams) {
        return this.getRow(p)?.playerId;
    }

    private getName(p: GridValueGetterParams) {
        return this.getRow(p)?.name;
    }

    private getDataForRemove(p: GridValueGetterParams): RemovePlayerActionCellProps {
        return {roomId: this.getRow(p).roomId, userId: this.getPlayerId(p), gameType: this.getRow(p).gameType};
    }
}

export const DataGridPlayerClient = withDataGridEntityClient<GamePlayerKeys, PlayerViewModel>(MuiDataGridClient, () =>
    new DataGridPlayerConfigFactory().getColumnConfig()
);

export const DataGridPlayerServer = withDataGridEntityServer<GamePlayerKeys, PlayerViewModel>(MuiDataGridServer, () =>
    new DataGridPlayerConfigFactory().getColumnConfig()
);
