import React from 'react';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import StyledAccordion from '@components/StyledAccordion';
import {agentReadPolicies} from '@models/permissions/permissions';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {LegalDocumentItem} from '../../legal-docs/types';
import {PermissionAccessibleItem, PermissionEnum} from '../../module-shared/types';

import {UserTermsAndConditions} from './UserTermsAndConditions';

const localizedUserDetailsTermsAndConditionsInfo = defineMessages({
    termsAndConditions: {
        id: 'UD_termsAndConditions',
        defaultMessage: 'Terms and Conditions',
    },
});

const useUserDetailsTermsAndConditionsInfoClasses = makeStyles()(() => ({
    userDetailsTermsAndConditionsInfoContainer: {
        width: '100%',
    },
    userDetailsTermsAndConditionsInfo: {
        paddingTop: 0,
        boxShadow: 'none',
    },
}));

type UserDetailsTermsAndConditionsInfoProps = {
    userId: string;
};

function UserDetailsTermsAndConditionsInfoInner({userId}: UserDetailsTermsAndConditionsInfoProps) {
    const {classes} = useUserDetailsTermsAndConditionsInfoClasses();

    return (
        <StyledAccordion
            header={localizedUserDetailsTermsAndConditionsInfo.termsAndConditions}
            componentId="termsAndConditions"
            isCollapsed
            unmountOnCollapse
            children={
                <Box className={classes.userDetailsTermsAndConditionsInfoContainer}>
                    <UserTermsAndConditions
                        itemId={userId}
                        className={classes.userDetailsTermsAndConditionsInfo}
                        fields={[nameof<LegalDocumentItem>(l => l.content), nameof<LegalDocumentItem>(l => l.acceptedAt)]}
                        hideTitle
                    />
                </Box>
            }
        />
    );
}

const allowedPermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.LegalContentManagement, permissions: [PermissionEnum.Read]},
    {moduleName: ModuleName.LegalContentManagement, submoduleName: SubmoduleName.TermsAndConditions, permissions: [PermissionEnum.Read]},
];

const restrictedPermissions = agentReadPolicies;

export const UserDetailsTermsAndConditionsInfo = withMultiplePermission(UserDetailsTermsAndConditionsInfoInner, {
    allowedPermissions,
    restrictedPermissions,
});
