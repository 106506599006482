import React, {Fragment} from 'react';
import {defineMessages} from 'react-intl';

import {LayoutQueue} from '@components/layout';
import Toolbar from '@components/toolbar/Toolbar';
import {withdrawalProcessingPermissions} from '@models/permissions/permissions';
import {TransactionViewModelKeys} from '@models/transaction';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {UserProfileServerFilterKeys} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {extendedViewCleanDelay, getNonEmptyValueValidator, ViewType} from '@redux/view';

import {
    DataGridTransactionWithUserProfileServer,
    pendingCasesDefaultFilter,
    TransactionFilters,
    useTransactions,
} from 'src/features/block-transaction-list';
import {withModule} from 'src/features/module-shared/components/ModuleHoc';

const localized = defineMessages({
    title: {
        id: 'WithdrawalPendingCases_title',
        defaultMessage: 'Withdrawal Pending Cases',
    },
});

function WithdrawalPendingCases() {
    const viewType: ViewType = 'WithdrawalPendingCases';
    const mode = RealtimeUpdatesMode.Confirm;
    const triggers = [RealtimeMessageTrigger.Add, RealtimeMessageTrigger.Update];
    const keys: TransactionViewModelKeys[] = [
        'withdrawal_id',
        'duration',
        'uid',
        'email',
        'transaction_status',
        'amount',
        'user_labels',
        'player_country',
    ].filter(c => c) as TransactionViewModelKeys[];
    const userColumns: UserProfileViewModelKeys[] = [
        'withdrawal_approved_amount',
        'withdrawal_approved_count',
        'withdrawal_pending_amount',
        'latest_kyc.payment.account_verification_status',
        'security_cases',
        'account_status',
    ];
    const userKeys: UserProfileViewModelKeys[] = [...userColumns, 'uid'];
    const columns = [...keys, ...userColumns];

    const {items, totalCount, searchFilter, filterString, handlePageChange, handlePageSizeChange, handleSortChange, handleFilterChange} =
        useTransactions(
            {
                viewType,
                displayName: localized.title,
                fields: keys,
                realtimeMode: mode,
                triggers,
                defaultFilters: pendingCasesDefaultFilter,
                validateFilter: () => true,
                syncWithUrl: true,
                cleanDelay: extendedViewCleanDelay,
            },
            {
                viewType,
                displayName: localized.title,
                fields: userKeys,
                cleanDelay: extendedViewCleanDelay,
                validateFilter: getNonEmptyValueValidator<UserProfileServerFilterKeys>('uid'),
            }
        );

    return (
        <Fragment>
            <LayoutQueue
                dataTestId="withdrawalPendingCases"
                toolbar={
                    <Toolbar
                        primary={
                            <TransactionFilters
                                filter={filterString}
                                defaultFilter={pendingCasesDefaultFilter}
                                onFilterChange={handleFilterChange}
                                filters={['uidUsernameTransactionIdEmailText']}
                            />
                        }
                        secondary={
                            <TransactionFilters
                                filter={filterString}
                                defaultFilter={pendingCasesDefaultFilter}
                                onFilterChange={handleFilterChange}
                                filters={['date']}
                            />
                        }
                        advanced={
                            <TransactionFilters
                                filter={filterString}
                                defaultFilter={pendingCasesDefaultFilter}
                                onFilterChange={handleFilterChange}
                                filters={['amount', 'pendingStatuses', 'duration', 'country', 'labels']}
                            />
                        }
                    />
                }
                body={
                    <DataGridTransactionWithUserProfileServer
                        columns={columns}
                        rows={items}
                        rowCount={totalCount}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSortModelChange={handleSortChange}
                        page={searchFilter?.paging?.page}
                        pageSize={searchFilter?.paging?.pageSize}
                        sortModel={searchFilter?.sorting}
                    />
                }
            />
        </Fragment>
    );
}

export default withModule(WithdrawalPendingCases, withdrawalProcessingPermissions);
