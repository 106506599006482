import React, {useContext, useState} from 'react';
import {IntlContext} from 'react-intl';

import {useJurisdictionConfig} from '../../../../features/app/config/hooks';
import {IModuleGridItem} from '../types';

import {MuiDataGridClientProps} from './MuiDataGridClient';
import {MuiDataGridServerProps} from './MuiDataGridServer';
import {DataGridEntityOptions, GridColDef} from './types';

export type EntityDataGridProps<
    TGridProps extends MuiDataGridClientProps | MuiDataGridServerProps,
    TColumns extends string,
    TEntity extends IModuleGridItem
> = Omit<TGridProps, 'columns' | 'visibleColumns' | 'rows'> & {
    columns: TColumns[];
    visibleColumns?: TColumns[];
    rows: TEntity[];
    selectedIds?: string[];
    onSelect?: (ids: string[]) => void;
    onEdit?: (index: number, field: string, value: unknown) => void;
    pinnedColumns?: TColumns[];
};

//TODO: [BO-2915] Refactor id column logic for data grids
function useDataGridEntity<TColumns extends string, TEntity extends IModuleGridItem>(
    columns: TColumns[],
    rows: TEntity[],
    selectedIds: string[],
    getColumnsConfig: (options: DataGridEntityOptions) => Partial<Record<TColumns, GridColDef>>
) {
    const {currency} = useJurisdictionConfig();
    const {formatMessage} = useContext(IntlContext);
    const columnsConfig = getColumnsConfig({currency, formatMessage});
    const colDefs = columns?.map((column: TColumns) => columnsConfig[column]);
    const selectedItems = rows?.filter((row: TEntity) => selectedIds?.includes(row?.id));

    return {colDefs, selectedItems};
}

export function useDataGridSelectedIds() {
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    function handleSelect(selectedIds: string[]) {
        setSelectedIds(selectedIds);
    }

    return {selectedIds, handleSelect};
}

export const withDataGridEntityServer =
    <TColumns extends string, TEntity extends IModuleGridItem>(
        WrappedDataGrid: (props: MuiDataGridServerProps) => JSX.Element,
        getColumnsConfig: (options: DataGridEntityOptions) => Partial<Record<TColumns, GridColDef>>
    ) =>
    (props: EntityDataGridProps<MuiDataGridServerProps, TColumns, TEntity>) => {
        const {columns, rows, selectedIds, ...otherProps} = props;
        const {colDefs, selectedItems} = useDataGridEntity(columns, rows, selectedIds, getColumnsConfig);

        return <WrappedDataGrid {...otherProps} columns={colDefs} rows={rows} selectedItems={selectedItems}></WrappedDataGrid>;
    };

export const withDataGridEntityClient =
    <TColumns extends string, TEntity extends IModuleGridItem>(
        WrappedDataGrid: (props: MuiDataGridClientProps) => JSX.Element,
        getColumnsConfig: (options: DataGridEntityOptions) => Partial<Record<TColumns, GridColDef>>
    ) =>
    (props: EntityDataGridProps<MuiDataGridClientProps, TColumns, TEntity>) => {
        const {columns, rows, selectedIds, ...otherProps} = props;
        const {colDefs, selectedItems} = useDataGridEntity(columns, rows, selectedIds, getColumnsConfig);

        return <WrappedDataGrid {...otherProps} columns={colDefs} rows={rows} selectedItems={selectedItems}></WrappedDataGrid>;
    };
