import React from 'react';
import {GridCellParams, GridColDef} from '@mui/x-data-grid';

import {AutoAdjustedWidthTooltip} from '@components/tooltip/AutoAdjustedWidthTooltip';

export const renderCellWithTooltip = (params: GridCellParams<any, React.ReactNode>, showEmptyCharacter = true) => {
    const colDef = params.colDef as GridColDef;
    const value = colDef.valueFormatter ? params.formattedValue : params.value;

    return renderValueWithTooltip(value, showEmptyCharacter);
};

export const renderValueWithTooltip = (value: React.ReactNode, showEmptyCharacter = true) => {
    return <AutoAdjustedWidthTooltip value={value} showEmptyCharacter={showEmptyCharacter}></AutoAdjustedWidthTooltip>;
};
