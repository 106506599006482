import {beBonus} from './beBonus';
import {beBonusHistory} from './beBonusHistory';
import {globalSearch} from './globalSearch';
import {playerBonus} from './playerBonus';

//TODO: [BO-2938] Cleanup all files in common/services/query folder after Bonus module is moved to view feature
export const gqlQueryArgs = {
    globalSearch,
    beBonus,
    beBonusHistory,
    playerBonus,
};
