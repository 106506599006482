import {defineMessages} from 'react-intl';
import {useDispatch} from 'react-redux';

import {useAutoMapper} from '@auto-mapper';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {EntityType, UserProfileExtended, UserProfileQueryFields, UserProfileServerFilterKeys} from '@redux/entity';
import {RealtimeMessageTrigger} from '@redux/realtime';
import {getNonEmptyValueValidator, UseDetailsViewEntityProps, UseDetailsViewEntityResult, useViewInit} from '@redux/view';
import {updateKeyValueFilterArray} from '@utils';

import {Filter} from '../../common/types';
import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

export function useUserProfileDetails({
    id,
    fields,
    viewType,
    displayName,
    cleanDelay = 0,
    realtimeMode,
    defaultFilters,
    validateFilter,
}: UseDetailsViewEntityProps<UserProfileServerFilterKeys, UserProfileViewModelKeys>): UseDetailsViewEntityResult<
    UserProfileExtended,
    UserProfileViewModelKeys
> {
    const mapper = useAutoMapper();
    const queryFields: UserProfileQueryFields[] = mapper.map(fields, nameof<UserProfileViewModelKeys>(), nameof<UserProfileQueryFields>());

    const defaultFilter: Filter<unknown, UserProfileServerFilterKeys>[] = updateKeyValueFilterArray(
        [{key: 'uid', value: id ?? null}],
        ...(defaultFilters ?? [])
    );
    const {
        items,
        searchFilter,
        viewEntity: {filter: filterString, status},
        handleFilterChange,
    } = useViewInit<UserProfileExtended, UserProfileServerFilterKeys, UserProfileQueryFields>({
        viewType,
        entity: {
            entity: EntityType.UserProfile,
            fields: queryFields,
        },
        realtime: realtimeMode ? {entity: EntityType.UserProfile, mode: realtimeMode, triggers: [RealtimeMessageTrigger.Update]} : null,
        displayName,
        defaultFilters: defaultFilter,
        defaultPaging: {page: 1, pageSize: 1},
        validateFilter: validateFilter ?? getNonEmptyValueValidator<UserProfileServerFilterKeys>('uid'),
        cleanDelay,
    });

    return {item: items?.length ? items[0] : null, requestStatus: status, handleFilterChange, searchFilter, filterString};
}

const referralLinkLocalized = defineMessages({
    copyWasSuccess: {
        id: 'ReferralLink_copySuccess',
        defaultMessage: 'Copied',
    },
    copyWasFailed: {
        id: 'ReferralLink_copyWasFailed',
        defaultMessage: 'Failed to copy',
    },
});

type UseReferralLinkResult = {
    link: string;
    handleCopyClick: () => void;
};

export function useReferralLink(uid: string): UseReferralLinkResult {
    const dispatch = useDispatch();
    const {item} = useUserProfileDetails({
        id: uid,
        fields: ['referral.personal_referral_link'],
        viewType: 'ReferralLink',
    });

    const link = item?.referral?.personal_referral_link;
    function handleCopyClick() {
        navigator.clipboard
            .writeText(link)
            .then(() => {
                dispatch(showMessageAction({message: referralLinkLocalized.copyWasSuccess}));
            })
            .catch(() => {
                dispatch(showErrorAction({message: referralLinkLocalized.copyWasFailed}));
            });
    }
    return {link, handleCopyClick};
}
