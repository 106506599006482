import {defineMessages, MessageDescriptor} from 'react-intl';

import {UserProfileViewModelKeys} from '@models/user-profile';

const platformMessageDescriptor: MessageDescriptor = {
    id: 'DataGridUserProfile_platform',
    defaultMessage: 'Platform',
};

const uidMessageDescriptor: MessageDescriptor = {
    id: 'DataGridUserProfile_uid',
    defaultMessage: 'UID',
};

export const gridUserProfileLocalizedHeaders = defineMessages<UserProfileViewModelKeys>({
    uid: uidMessageDescriptor,
    uid_link_cs: uidMessageDescriptor,
    uid_link_agent: uidMessageDescriptor,
    uid_link_security: uidMessageDescriptor,
    full_name: {
        id: 'DataGridUserProfile_fullName',
        defaultMessage: 'Name',
    },
    nickname: {
        id: 'DataGridUserProfile_nickname',
        defaultMessage: 'Nickname',
    },
    networks_PKW: {
        id: 'DataGridUserProfile_cashGamesId',
        defaultMessage: 'Cash Game ID',
    },
    networks_BL: {
        id: 'DataGridUserProfile_tournamentsId',
        defaultMessage: 'Tournaments ID',
    },
    account_status: {
        id: 'DataGridUserProfile_accountStatus',
        defaultMessage: 'Account Status',
    },
    deposit_status: {
        id: 'DataGridUserProfile_depositStatus',
        defaultMessage: 'Deposit Lock',
    },
    withdrawal_status: {
        id: 'DataGridUserProfile_withdrawalStatus',
        defaultMessage: 'Withdrawal Lock',
    },
    p2p_transfer_status: {
        id: 'DataGridUserProfile_p2pTransferStatus',
        defaultMessage: 'P2P Transfer Lock',
    },
    security_cases: {
        id: 'DataGridUserProfile_securityCase',
        defaultMessage: 'Security Case ID',
    },
    email: {
        id: 'DataGridUserProfile_email',
        defaultMessage: 'Email',
    },
    register_marketing_code: {
        id: 'DataGridUserProfile_marketingCode',
        defaultMessage: 'Marketing Code',
    },
    referrer: {
        id: 'DataGridUserProfile_referrer',
        defaultMessage: 'Referrer',
    },
    platform: platformMessageDescriptor,
    platform_cs: platformMessageDescriptor,
    national_id: {
        id: 'DataGridUserProfile_nationalId',
        defaultMessage: 'National ID',
    },
    'latest_kyc.payment.account_verification_status': {
        id: 'DataGridUserProfile_kycStatus',
        defaultMessage: 'KYC Status',
    },
    phone: {
        id: 'DataGridUserProfile_Phone',
        defaultMessage: 'Phone number',
    },
    referrer_player_id: {
        id: 'DataGridUserProfile_ReferrerPlayerId',
        defaultMessage: 'Referral ID',
    },
    referrer_username: {
        id: 'DataGridUserProfile_referrerUsername',
        defaultMessage: 'Referral Username',
    },
    referred_date: {
        id: 'DataGridUserProfile_referredDate',
        defaultMessage: 'Referred Date',
    },
    username: {
        id: 'DataGridUserProfile_username',
        defaultMessage: 'Username',
    },
    'finance.balance': {
        id: 'DataGridUserProfile_balance',
        defaultMessage: 'Balance',
    },
    'latest_kyc.security.account_verification_status': {
        id: 'DataGridUserProfile_securityKYCStatus',
        defaultMessage: 'Security KYC Status',
    },
    'latest_kyc.payment.account_verification_status_cs': {
        id: 'DataGridUserProfile_paymentKYCStatus',
        defaultMessage: 'Payment KYC Status',
    },
    withdrawal_approved_amount: {
        id: 'DataGridUserProfile_approvedWithdrawal',
        defaultMessage: 'Total Approved Withdrawal',
    },
    withdrawal_approved_count: {
        id: 'DataGridUserProfile_approvedWithdrawalCount',
        defaultMessage: 'Total Approved Withdrawal Count',
    },
    withdrawal_pending_amount: {
        id: 'DataGridUserProfile_pendingWithdrawal',
        defaultMessage: 'Total Pending Withdrawal',
    },
    first_name: {
        id: 'DataGridUserProfile_firstName',
        defaultMessage: 'First Name',
    },
    labels: {
        id: 'DataGridUserProfile_labels',
        defaultMessage: 'Label',
    },
    'latest_login.logged_at_ts.seconds': {
        id: 'DataGridUserProfile_latestLogin',
        defaultMessage: 'Last login date/time',
    },
    last_name: {
        id: 'DataGridUserProfile_lastName',
        defaultMessage: 'Last Name',
    },
    'phone.area': null,
    'phone.number': null,
    'birthday.seconds': null,
    gender: null,
    'date_of_joining.seconds': {
        id: 'DataGridUserProfile_dateOfJoining',
        defaultMessage: 'Registration Date',
    },
    is_2fa_enabled: null,
    is_challenge_questions_enabled: null,
    country: {
        id: 'DataGridUserProfile_country',
        defaultMessage: 'Country',
    },
    lobby_access_status: null,
    'security_cases.type': null,
    'security_cases.case_id': null,
    'security_cases.closed_at': null,
    'security_cases.opened_at': null,
    'latest_kyc.security.id': null,
    deposit_total: null,
    balance: null,
    ggr: null,
    lifetime_bonus: null,
    bonus_ratio: null,
    'agent_info.bo_agent_id': null,
    'agent_info.default_agent_revenue_share_history': null,
    casino_status: null,
    sportsbook_status: null,
    'referral.personal_referral_link': null,
    'latest_kyc.payment.id': null,
    'networks.uid': null,
    'networks.platform': null,
    aml_labels: null,
    login_countries: {
        id: 'DataGridUserProfile_loginCountries',
        defaultMessage: 'Login Countries',
    },
    vpn: null,
    brand: {
        id: 'DataGridUserProfile_brand',
        defaultMessage: 'Brand',
    },
    license_type: {
        id: 'DataGridUserProfile_licenseType',
        defaultMessage: 'License Type',
    },
});
