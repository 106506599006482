import React, {Fragment} from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';
import {goBack} from '@vs-centaurea/connected-react-router';
import {makeStyles} from 'tss-react/mui';

import {OutlinedButton} from '@components/button/Buttons';
import {CustomIcon} from '@components/icons/types';

const useClasses = makeStyles()(() => ({
    goBackButtonIcon: {
        fontSize: '8px !important',
        transform: 'rotate(90deg)',
    },
}));

const localized = defineMessages({
    backButtonLabel: {
        id: 'backButtonLabel',
        defaultMessage: 'Back',
    },
});

type BackButtonProps = {
    onClick?: () => void;
    dispatchOnClick?: boolean;
};

const BackButton = ({dispatchOnClick = true, onClick}: BackButtonProps) => {
    const dispatch = useDispatch();
    const {classes} = useClasses();

    const handleOnClick = () => {
        if (dispatchOnClick) dispatch(goBack());
        else {
            onClick();
        }
    };

    return (
        <Fragment>
            <OutlinedButton
                size="small"
                label={localized.backButtonLabel}
                startIcon={<Box component="span" className={`${CustomIcon.ArrowDown} ${classes.goBackButtonIcon}`} />}
                onClick={handleOnClick}
            />
        </Fragment>
    );
};

export default BackButton;
