import {defineMessages, MessageDescriptor} from 'react-intl';
import {injectable} from 'inversify';
import {Observable} from 'rxjs';

import {CsvDocumentBuilder} from '@file/services/CsvDocumentBuilder';
import {CsvDocumentBuilderAdapter} from '@file/services/CsvDocumentBuilderAdapter';
import {IFileCreator} from '@file/services/IFileFactory';
import {DataWorksheet, DataWorksheetColDefs} from '@file/types';
import {TransactionDownloadModel, TransactionViewModelKeys} from '@models/transaction';

const localized = defineMessages<TransactionViewModelKeys, MessageDescriptor>({
    id: {
        id: 'TransactionDownloadModel_uid',
        defaultMessage: 'ID',
    },
    uid: {
        id: 'TransactionDownloadModel_uid',
        defaultMessage: 'Player Id',
    },
    created_by_uid: {
        id: 'TransactionDownloadModel_created_by_uid',
        defaultMessage: 'Created by',
    },
    status_log: {
        id: 'TransactionDownloadModel_status_log',
        defaultMessage: 'Status Log',
    },
    email: {
        id: 'TransactionDownloadModel_email',
        defaultMessage: 'Email Address',
    },
    phone: {
        id: 'TransactionDownloadModel_phone',
        defaultMessage: 'Phone number',
    },
    username: {
        id: 'TransactionDownloadModel_username',
        defaultMessage: 'Username',
    },
    referrer_player_id: {
        id: 'TransactionDownloadModel_referrer_player_id',
        defaultMessage: 'Referral ID',
    },
    player_country: {
        id: 'TransactionDownloadModel_player_country',
        defaultMessage: "Player's Country",
    },
    transaction_started_ts: {
        id: 'TransactionDownloadModel_transaction_started_ts',
        defaultMessage: 'Timestamp',
    },
    transaction_updated_ts: {
        id: 'TransactionDownloadModel_transaction_updated_ts',
        defaultMessage: 'Timestamp',
    },
    transaction_type: {
        id: 'TransactionDownloadModel_transaction_type',
        defaultMessage: 'Type',
    },
    transaction_status: {
        id: 'TransactionDownloadModel_transaction_status',
        defaultMessage: 'Status',
    },
    amount: {
        id: 'TransactionDownloadModel_amount',
        defaultMessage: 'Amount',
    },
    counterpart_player_id: {
        id: 'TransactionDownloadModel_counterpart_player_id',
        defaultMessage: 'Counterpart player ID',
    },
    transaction_id: {
        id: 'TransactionDownloadModel_transaction_id',
        defaultMessage: 'Transaction ID',
    },
    withdrawal_id: {
        id: 'TransactionDownloadModel_withdrawal_id',
        defaultMessage: 'Withdrawal ID',
    },
    type: {
        id: 'TransactionDownloadModel_type',
        defaultMessage: 'Type',
    },
    amount_without_currency: {
        id: 'TransactionDownloadModel_amount_without_currency',
        defaultMessage: 'Amount',
    },
    currency: {
        id: 'TransactionDownloadModel_currency',
        defaultMessage: 'Currency',
    },
    payment_method_name: {
        id: 'TransactionDownloadModel_payment_method_name',
        defaultMessage: 'Payment Method',
    },
    payment_method_description: {
        id: 'TransactionDownloadModel_payment_method_description',
        defaultMessage: 'Payment Method Description',
    },
    current_balance: {
        id: 'TransactionDownloadModel_current_balance',
        defaultMessage: 'Current Balance',
    },
    duration: {
        id: 'TransactionDownloadModel_duration',
        defaultMessage: 'Duration',
    },
    register_marketing_code: {
        id: 'TransactionDownloadModel_register_marketing_code',
        defaultMessage: 'Register Marketing Code',
    },
    user_labels: {
        id: 'TransactionDownloadModel_user_labels',
        defaultMessage: 'Label',
    },
    p2p_direction: {
        id: 'TransactionDownloadModel_p2p_direction',
        defaultMessage: 'Direction',
    },
    'transaction_updated_ts.seconds': {
        id: 'TransactionDownloadModel_transaction_updated_ts',
        defaultMessage: 'Timestamp',
    },
    'transaction_updated_ts.nanos': {
        id: 'TransactionDownloadModel_transaction_updated_ts',
        defaultMessage: 'Timestamp',
    },
    'transaction_started_ts.seconds': {
        id: 'TransactionDownloadModel_transaction_started_ts',
        defaultMessage: 'Timestamp',
    },
    'transaction_started_ts.nanos': {
        id: 'TransactionDownloadModel_transaction_started_ts',
        defaultMessage: 'Timestamp',
    },
});

type WorkSheetColumnType = DataWorksheetColDefs<TransactionViewModelKeys>;

/**
 *  @deprecated
 * This approach is outdated. Use {@link CsvDocumentBuilderAdapter}
 * */
@injectable()
export class TransactionCsvFileFactory implements IFileCreator<TransactionDownloadModel> {
    private readonly builder = new CsvDocumentBuilder();

    public createFile(items: TransactionDownloadModel[], keys: TransactionViewModelKeys[]): Observable<ArrayBuffer> {
        const worksheet = this.getWorksheet(items, keys);
        return this.builder.createDocument<TransactionViewModelKeys>(worksheet);
    }

    private getWorksheet(data: TransactionDownloadModel[], keys: TransactionViewModelKeys[]): DataWorksheet<TransactionViewModelKeys> {
        const columns = keys.reduce<WorkSheetColumnType>((previousValue, key, index) => {
            previousValue[key] = {key, header: localized[key].defaultMessage as string, colIndex: index + 1};
            return previousValue;
        }, {} as WorkSheetColumnType);

        return {
            title: 'Transactions',
            columns,
            data,
        };
    }
}
