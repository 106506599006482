import React from 'react';
import {Typography} from '@mui/material';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme, SystemColorsEnum} from '@style';

import {CircleBox} from './circle-box/CircleBox';

//TODO: [BO-2686] Move files to src/common/components/user
const useStyles = makeStyles()((theme: CustomTheme) => ({
    avatarCircleMaximized: {
        width: theme.spacing(6),
        minWidth: theme.spacing(6),
        height: theme.spacing(6),
        textAlign: 'center',
        borderRadius: '50%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    avatarCircleMinimized: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        textAlign: 'center',
        borderRadius: '50%',
        marginLeft: theme.spacing(0.75),
    },
    userInformationContent: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        overflow: 'hidden',
    },
    username: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    email: {
        marginTop: theme.spacing(1),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    hide: {
        display: 'none',
    },
}));

type UserInformationProps = {
    onlyCircle?: boolean;
    name?: string;
    preferred_username?: string;
    color: SystemColorsEnum;
};

export const UserInformation = ({onlyCircle, name, preferred_username, color}: UserInformationProps) => {
    const {classes: styles} = useStyles();
    const avatarCircleLetter = preferred_username?.charAt(0).toUpperCase() ?? name?.charAt(0).toUpperCase();

    return (
        <React.Fragment>
            <Box className={styles.userInformationContent}>
                <Box className={`${onlyCircle ? styles.avatarCircleMinimized : styles.avatarCircleMaximized}`}>
                    <CircleBox letter={avatarCircleLetter} color={color} />
                </Box>
                <Box className={onlyCircle ? styles.hide : ''}>
                    <Box className={styles.username}>
                        <Typography variant="h6">{name ?? '-'}</Typography>
                    </Box>
                    <Box className={styles.email}>
                        <Typography variant="subtitle1">{preferred_username ?? '-'}</Typography>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
};
