import React, {useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {AppBar, Box, Drawer, Toolbar, Typography, useTheme} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {appMenuLocalized} from '@components/appMenuLocalized';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomTheme} from '@style';

import ModuleList from '../../features/modules/components/ModuleList';

import {IconButton} from './button/Buttons';
import {Account} from './sidebar/Account';

//TODO: [BO-2679] Move files to src/common/components/sidebar (rename to SidebarMenu)
const makeClasses = makeStyles()((theme: CustomTheme) => ({
    menuDrawer: {
        [theme.breakpoints.up('md')]: {
            width: '80%',
        },
    },
    menuDrawerPaper: {
        paddingTop: theme.mixins.toolbar.minHeight,
        background: theme.palette.background.paper,
    },
    menu: {
        width: 'auto',
        left: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: theme.zIndex.tooltip,
        [theme.breakpoints.up('md')]: {
            left: theme.custom.sideBarWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    accountSection: {
        marginLeft: theme.spacing(1),
        marginTop: 'auto',
        marginRight: theme.spacing(1),
    },
    overviewSectionTitle: {
        marginLeft: theme.spacing(2.5),
    },
    overviewSection: {
        overflow: 'auto',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
}));

function Menu() {
    const theme = useTheme();
    const {classes} = makeClasses();

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box>
            <AppBar sx={{display: {md: 'none', xs: 'block'}}} position="fixed" className={classes.menu} elevation={0}>
                <Toolbar>
                    <IconButton color="default" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
                        {mobileOpen ? <CloseIcon /> : <MenuIcon />}
                    </IconButton>
                    <Typography variant="h6" color="textPrimary">
                        <LocalizedText label={appMenuLocalized.teamName} />
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.menuDrawer}>
                <Drawer
                    sx={{display: {md: 'none', xs: 'block'}}}
                    classes={{paper: classes.menuDrawerPaper}}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{keepMounted: true}}
                >
                    <Box className={classes.overviewSection}>
                        <Typography className={classes.overviewSectionTitle} variant="caption">
                            <LocalizedText label={appMenuLocalized.overview} />
                        </Typography>
                        <ModuleList showSubmodules={true} />
                    </Box>
                    <Box className={classes.accountSection}>
                        <Account />
                    </Box>
                </Drawer>
            </nav>
        </Box>
    );
}

export default Menu;
