import React from 'react';
import {Box} from '@mui/material';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {ChipWithTooltip} from '@components/chip/Chips';
import {ChipType, ChipVariant} from '@components/chip/types';
import {withEmptyCheck} from '@components/label';

const useClasses = makeStyles()(theme => ({
    textListWithTooltipCellVisibleElement: {
        flexShrink: 2,
        flexGrow: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: theme.spacing(0.5),
    },
    textListWithTooltipCellDropdownContent: {
        display: 'grid',
        gridGap: theme.spacing(1),
        justifyItems: 'baseline',
        flexShrink: 0,
        flexGrow: 0,
    },
}));

type TextListCellWithTooltipProps = {
    visible: string;
    hidden: string[];
    width?: number;
};

export function TextListWithTooltipCell({visible, hidden}: TextListCellWithTooltipProps) {
    const {classes} = useClasses();
    return (
        <>
            <Box className={classes.textListWithTooltipCellVisibleElement}>{visible}</Box>
            {hidden?.length ? (
                <ChipWithTooltip
                    chipType={ChipType.Status}
                    chipVariant={ChipVariant.Grey}
                    label={hidden?.length}
                    fillWidth={false}
                    title={
                        <Box className={classes.textListWithTooltipCellDropdownContent}>
                            {hidden.map(item => (
                                <div>{item}</div>
                            ))}
                        </Box>
                    }
                    hideTooltip={false}
                />
            ) : null}
        </>
    );
}

const TextListWithTooltipCellAdapter = withEmptyCheck(({value}: {value: string[]}) => {
    return <TextListWithTooltipCell visible={value?.[0]} hidden={value} />;
});

export const renderTextListWithTooltipCell = (params: GridCellParams<any, string[]>) => {
    const value = params.value;
    return <TextListWithTooltipCellAdapter value={value} />;
};
