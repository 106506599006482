import React from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import {AgentPlayerReportingViewModelKeys} from '@models/agent-player-reporting';

import {useAgentPlayerReportDownloadButton} from '../hooks';
import {AgentReportType} from '../types';

const localized = defineMessages({
    buttonLabel: {
        id: 'AgentReportingDownloadCsvButton_label',
        defaultMessage: 'Download CSV',
    },
});

const useStyles = makeStyles()(theme => ({
    agentReportingDownloadCsvButton: {
        borderRadius: theme.shape.borderRadius * 2,
    },
}));

type AgentPlayerReportDownloadCsvButtonProps = {
    filterString: string;
    filename: string;
    keys: AgentPlayerReportingViewModelKeys[];
    agentReportType: AgentReportType;
};

export function AgentPlayerReportDownloadCsvButton(props: AgentPlayerReportDownloadCsvButtonProps) {
    const {classes} = useStyles();
    const {handleClick, isProgress} = useAgentPlayerReportDownloadButton(props);
    return (
        <Button
            loading={isProgress}
            onClick={handleClick}
            label={localized.buttonLabel}
            className={classes.agentReportingDownloadCsvButton}
        />
    );
}
