import React, {useState} from 'react';
import {Box} from '@mui/material';

import {ChipAccordion} from '@components/chip/ChipAccordion';
import {ChipCounter} from '@components/chip/ChipCounter';
import {MemoizedChip} from '@components/chip/ChipRenderer';
import {Chip} from '@components/chip/Chips';
import {ChipType, ChipVariant} from '@components/chip/types';
import {TransactionStatus, UserAccountStatus} from '@models/generated/graphql';

import {Wrapper, WrapperChild} from './WrapperDev';

export const Chips = () => {
    const deleteHandler = () => alert('Hey delete me???');
    return (
        <Box>
            <Wrapper label="Chips">
                <WrapperChild>
                    <Chip fillWidth={false} chipType={ChipType.Status} chipVariant={ChipVariant.Green} label="Verified" />
                </WrapperChild>
                <WrapperChild>
                    <Chip
                        fillWidth={false}
                        chipType={ChipType.Status}
                        chipVariant={ChipVariant.Orange}
                        label="In Progress"
                        onDelete={deleteHandler}
                    />
                </WrapperChild>
                <WrapperChild>
                    <Chip
                        fillWidth={false}
                        chipType={ChipType.Status}
                        chipVariant={ChipVariant.Default}
                        label="Not Verified"
                        onDelete={deleteHandler}
                        size="medium"
                    />
                </WrapperChild>
                <br />
                <br />
                <br />
                <WrapperChild>
                    <Chip fillWidth={false} chipType={ChipType.Tag} chipVariant={ChipVariant.Default} label="20210210_2" />
                </WrapperChild>
                <WrapperChild>
                    <Chip
                        fillWidth={false}
                        chipType={ChipType.Tag}
                        chipVariant={ChipVariant.Default}
                        label="20210210_2"
                        onDelete={deleteHandler}
                    />
                </WrapperChild>
                <WrapperChild>
                    <Chip
                        fillWidth={false}
                        chipType={ChipType.Tag}
                        chipVariant={ChipVariant.Default}
                        label="20210210_2"
                        disabled
                        onDelete={deleteHandler}
                    />
                </WrapperChild>
                <br />
                <br />
                <br />
                <WrapperChild>
                    <Chip fillWidth={false} chipType={ChipType.Mark} chipVariant={ChipVariant.LightGreen} label="Mark" />
                </WrapperChild>
                <WrapperChild>
                    <Chip fillWidth={false} chipType={ChipType.Mark} chipVariant={ChipVariant.Turquoise} label="Mark" />
                </WrapperChild>
                <WrapperChild>
                    <Chip fillWidth={false} chipType={ChipType.Mark} chipVariant={ChipVariant.Blue} label="Mark" />
                </WrapperChild>
                <WrapperChild>
                    <Chip fillWidth={false} chipType={ChipType.Mark} chipVariant={ChipVariant.Yellow} label="Mark" />
                </WrapperChild>
                <WrapperChild>
                    <Chip fillWidth={false} chipType={ChipType.Mark} chipVariant={ChipVariant.Purple} label="Mark" />
                </WrapperChild>
                <WrapperChild>
                    <Chip fillWidth={false} chipType={ChipType.Mark} chipVariant={ChipVariant.Bronze} label="Mark" />
                </WrapperChild>
                <br />
                <br />
                <br />
                <WrapperChild>
                    <Chip fillWidth={false} chipType={ChipType.Prefix} chipVariant={ChipVariant.Default} label="FIN" />
                </WrapperChild>
                <WrapperChild>
                    <Chip fillWidth={false} chipType={ChipType.Prefix} chipVariant={ChipVariant.Default} label="AML" value="3" />
                </WrapperChild>
                <WrapperChild>
                    <Chip fillWidth={false} chipType={ChipType.Prefix} chipVariant={ChipVariant.Default} label="BOT" value="1099" />
                </WrapperChild>
            </Wrapper>
            <Wrapper label="Memoized Chips (Rendering According Type)">
                <WrapperChild>
                    <MemoizedChip value={TransactionStatus.Pending} chipName={nameof<TransactionStatus>()} />
                </WrapperChild>
                <WrapperChild>
                    <MemoizedChip value={TransactionStatus.OnHoldRisk} chipName={nameof<TransactionStatus>()} />
                </WrapperChild>
                <WrapperChild>
                    <MemoizedChip value={TransactionStatus.Rejected} chipName={nameof<TransactionStatus>()} />
                </WrapperChild>
                <WrapperChild>
                    <MemoizedChip value={UserAccountStatus.Active} chipName={nameof<UserAccountStatus>()} />
                </WrapperChild>
            </Wrapper>
            <Wrapper label="Colored chips">
                {[
                    ChipVariant.LightBlue,
                    ChipVariant.Orange,
                    ChipVariant.DarkGrey,
                    ChipVariant.Bronze,
                    ChipVariant.WarmYellow,
                    ChipVariant.Coffee,
                    ChipVariant.Pink,
                    ChipVariant.LightGrey,
                    ChipVariant.SkyBlue,
                    ChipVariant.LightGreen,
                    ChipVariant.Beige,
                    ChipVariant.Mint,
                    ChipVariant.YellowNeon,
                    ChipVariant.Violet,
                    ChipVariant.Burgundy,
                    ChipVariant.Red,
                ].map(variant => (
                    <WrapperChild key={`${variant}`}>
                        <Chip
                            fillWidth={false}
                            chipType={ChipType.Status}
                            chipVariant={variant}
                            label={variant}
                            onDelete={deleteHandler}
                            size="medium"
                        />
                    </WrapperChild>
                ))}
            </Wrapper>
            <Wrapper label="ChipAccordion">
                <ChipAccordion
                    visibleChips={[ChipVariant.LightBlue, ChipVariant.Orange, ChipVariant.DarkGrey, ChipVariant.Bronze].map(variant => (
                        <Chip
                            key={`${variant}`}
                            fillWidth={false}
                            chipType={ChipType.Status}
                            chipVariant={variant}
                            label={variant}
                            onDelete={deleteHandler}
                            size="medium"
                        />
                    ))}
                    collapsedChips={[ChipVariant.Pink, ChipVariant.Violet, ChipVariant.Burgundy, ChipVariant.WarmYellow].map(variant => (
                        <Chip
                            key={`${variant}`}
                            fillWidth={false}
                            chipType={ChipType.Status}
                            chipVariant={variant}
                            label={variant}
                            onDelete={deleteHandler}
                            size="medium"
                        />
                    ))}
                ></ChipAccordion>
            </Wrapper>
            <Wrapper label="ChipCounter with isCrossedOut">
                <WrapperChild>
                    <ChipCounterDev />
                </WrapperChild>
            </Wrapper>
        </Box>
    );
};

function ChipCounterDev() {
    const [isDeleted, setDeleted] = useState(false);

    const clickHandler = () => {
        if (isDeleted) setDeleted(false);
    };

    const clickDeleteHandler = () => {
        if (!isDeleted) setDeleted(true);
    };

    return (
        <ChipCounter
            fillWidth={false}
            chipType={ChipType.Status}
            chipVariant={ChipVariant.Green}
            label="Some Label"
            count={`${8}`}
            isCrossedOut={isDeleted}
            onDelete={clickDeleteHandler}
            onClick={clickHandler}
        />
    );
}
