import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {Box, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons';
import {useAuthUser, useLogout} from '@auth';
import {CustomTheme, SystemColorsEnum} from '@style';

import LocaleSwitch from '../LocaleSwitch';
import {UserInformation} from '../UserInformation';

//TODO: [BO-2670] Rename Account to SidebarFooter
//TODO: [BO-2671] Remove dependency to features in Sidebar (move actions to specific features)
const useStyles = makeStyles()((theme: CustomTheme) => ({
    listItemIcon: {
        minWidth: theme.spacing(4),
        fontSize: '18px',
    },
    listContent: {
        paddingBottom: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
    avatarCircleMaximized: {
        width: theme.spacing(6),
        minWidth: theme.spacing(6),
        height: theme.spacing(6),
        backgroundColor: theme.palette.success.main,
        textAlign: 'center',
        borderRadius: '50%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    avatarCircleMinimized: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        backgroundColor: theme.palette.success.main,
        textAlign: 'center',
        borderRadius: '50%',
        marginLeft: theme.spacing(0.75),
    },
    listItem: {
        height: theme.spacing(6),
        '&:hover': {
            background: 'transparent',
        },
    },
    listItemMinimized: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        marginLeft: theme.spacing(0.5),
        '&:hover': {
            background: 'transparent',
        },
    },
    avatarCircleInitials: {
        position: 'relative',
        top: theme.spacing(2),
        fontSize: '20px',
        lineHeight: 0,
        color: theme.palette.success.contrastText,
    },
    userInformationContent: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        overflow: 'hidden',
    },
    username: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    email: {
        marginTop: theme.spacing(1),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    localeSwith: {
        width: 0,
        marginRight: theme.spacing(3),
    },
    accountTitle: {
        marginLeft: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
}));

const localize = defineMessages({
    notification: {
        id: 'Account_notification',
        defaultMessage: 'Notification',
    },
    account: {
        id: 'Account_account',
        defaultMessage: 'ACCOUNT',
    },
    language: {
        id: 'Account_language',
        defaultMessage: 'Language',
    },
    logout: {
        id: 'Account_logout',
        defaultMessage: 'Log out',
    },
});
type AccountListItemIconTextProps = {
    icon: string;
    label: MessageDescriptor;
    onlyIcons: boolean;
};

const AccountListItemIconText = ({icon, label, onlyIcons}: AccountListItemIconTextProps) => {
    const {classes: styles} = useStyles();

    return (
        <>
            <ListItemIcon className={styles.listItemIcon}>
                <Box component="span" className={icon}></Box>
            </ListItemIcon>
            <ListItemText
                className={onlyIcons ? styles.hide : ''}
                disableTypography
                primary={
                    <Typography variant="body2">
                        <LocalizedText label={label} />
                    </Typography>
                }
            />
        </>
    );
};

type AccountProps = {
    onlyIcons?: boolean;
};

export const Account = ({onlyIcons}: AccountProps) => {
    const {classes: styles} = useStyles();
    //TODO: avoid auth hooks in base components - pass data through props
    const user = useAuthUser();
    const {logout} = useLogout();

    return (
        <React.Fragment>
            <Typography className={styles.accountTitle} variant="caption">
                {!onlyIcons && <LocalizedText label={localize.account} />}
            </Typography>
            <List className={styles.listContent}>
                <ListItem className={!onlyIcons ? styles.listItem : styles.listItemMinimized}>
                    <AccountListItemIconText icon={CustomIcon.Earth} label={localize.language} onlyIcons={onlyIcons} />
                    {!onlyIcons && (
                        <ListItemSecondaryAction className={styles.localeSwith}>
                            <LocaleSwitch />
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
                <ListItem
                    button
                    disableRipple
                    disableTouchRipple
                    className={!onlyIcons ? styles.listItem : styles.listItemMinimized}
                    onClick={logout}
                >
                    <AccountListItemIconText icon={CustomIcon.Logout} label={localize.logout} onlyIcons={onlyIcons} />
                </ListItem>
            </List>
            <UserInformation
                onlyCircle={onlyIcons}
                name={user?.name}
                preferred_username={user?.preferred_username}
                color={SystemColorsEnum.Emerald}
            />
        </React.Fragment>
    );
};
