import React from 'react';
import {defineMessages} from 'react-intl';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {renderChip} from '@components/chip/ChipRenderer';
import {
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    DataGridEntityOptions,
    MuiDataGridClient,
    MuiDataGridServer,
    renderCellHeader,
    renderCellWithTooltip,
    renderCurrencyCell,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import {BonusCreditType, BonusStatus} from '@models/generated/graphql';
import {formatTimestamp} from '@utils';

import {BonusCodeDetailsViewModel, BonusCodeDetailsViewModelKeys} from '../types';

import {renderDeactivateBonusModalButtonCell} from './BonusDeactivateButton';
import {BonusEngineDetailsActionProps, renderBonusEngineDetailsAction} from './BonusEngineDetailsAction';

const localized = defineMessages({
    bonusId: {
        id: 'BE_bonusId',
        defaultMessage: 'Bonus ID',
    },
    bonusName: {
        id: 'BE_bonusName',
        defaultMessage: 'Bonus Name',
    },
    bonusType: {
        id: 'BE_bonusType',
        defaultMessage: 'Bonus Type',
    },
    totalPaidOutValue: {
        id: 'BE_totalPaidOutValue',
        defaultMessage: 'Total Paid',
    },
    creationDate: {
        id: 'BE_creationDate',
        defaultMessage: 'Creation Date',
    },
    bonusStatus: {
        id: 'BE_bonusStatus',
        defaultMessage: 'Status',
    },
    description: {
        id: 'BE_description',
        defaultMessage: 'Description',
    },
});

export type DataGridBonusCodeKeys = BonusCodeDetailsViewModelKeys | 'deactivate_action' | 'details_action';

export class DataGridBonusCodeConfigFactory extends DataGridEntityConfigFactory<DataGridBonusCodeKeys, BonusCodeDetailsViewModel> {
    getColumnConfig(options?: DataGridEntityOptions): Partial<Record<DataGridBonusCodeKeys, DataGridEntityColDef<DataGridBonusCodeKeys>>> {
        const config: Partial<Record<DataGridBonusCodeKeys, DataGridEntityColDef<DataGridBonusCodeKeys>>> = {
            bonus_id: {
                field: 'bonus_id',
                valueGetter: p => this.getBonusId(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localized.bonusId,
                renderCell: renderCellWithTooltip,
                flex: 0.2,
                sortable: false,
            },
            bonus_name: {
                field: 'bonus_name',
                valueGetter: p => this.getBonusName(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localized.bonusName,
                renderCell: renderCellWithTooltip,
                flex: 0.2,
                sortable: false,
            },
            credit_type: {
                field: 'credit_type',
                valueGetter: p => this.getCreditType(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localized.bonusType,
                renderCell: params => renderChip<BonusCreditType>(params.value as BonusCreditType, nameof<BonusCreditType>()),
                flex: 0.15,
                sortable: false,
            },
            total_paid_out_amount: {
                field: 'total_paid_out_amount',
                valueGetter: p => this.getTotalPaidAmount(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localized.totalPaidOutValue,
                renderCell: params => renderCurrencyCell(params, options.currency as string),
                flex: 0.15,
                sortable: false,
            },
            'create_time.seconds': {
                field: 'create_time.seconds',
                valueGetter: p => this.getCreateTime(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localized.creationDate,
                renderCell: renderCellWithTooltip,
                flex: 0.2,
                sortable: false,
            },
            bonus_status: {
                field: 'bonus_status',
                valueGetter: p => this.getBonusStatus(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localized.bonusStatus,
                renderCell: params => renderChip<BonusStatus>(params.value as BonusStatus, nameof<BonusStatus>()),
                flex: 0.15,
                sortable: false,
            },
            description: {
                field: 'description',
                valueGetter: p => this.getDescription(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localized.description,
                renderCell: renderCellWithTooltip,
                flex: 0.3,
                sortable: false,
            },
            deactivate_action: {
                field: 'deactivate_action',
                valueGetter: p => this.getDeactivateButtonProps(p),
                renderHeader: () => <></>,
                renderCell: renderDeactivateBonusModalButtonCell,
                width: 160,
                sortable: false,
            },
            details_action: {
                field: 'details_action',
                valueGetter: p => this.detailsActionProps(p),
                renderHeader: () => <></>,
                renderCell: renderBonusEngineDetailsAction,
                width: 75,
                sortable: false,
            },
        };
        return config;
    }

    private getBonusId(p: GridValueGetterParams) {
        return this.getRow(p)?.bonus_id;
    }

    private getBonusName(p: GridValueGetterParams) {
        return this.getRow(p)?.bonus_name;
    }

    private getCreditType(p: GridValueGetterParams) {
        return this.getRow(p)?.credit_type;
    }

    private getTotalPaidAmount(p: GridValueGetterParams) {
        return this.getRow(p)?.total_paid_out_amount;
    }

    private getCreateTime(p: GridValueGetterParams) {
        return formatTimestamp(this.getRow(p)?.create_time, 'date-time');
    }

    private getBonusStatus(p: GridValueGetterParams) {
        return this.getRow(p)?.bonus_status;
    }

    private getDescription(p: GridValueGetterParams) {
        return this.getRow(p)?.description;
    }

    private getDeactivateButtonProps(p: GridValueGetterParams): Pick<BonusCodeDetailsViewModel, 'bonus_id' | 'bonus_name'> {
        const data = this.getRow(p);
        return {bonus_id: data?.bonus_id, bonus_name: data?.bonus_name};
    }

    private detailsActionProps(p: GridValueGetterParams): BonusEngineDetailsActionProps {
        return {bonusId: this.getRow(p)?.bonus_id};
    }
}

export const DataGridBonusCodeClient = withDataGridEntityClient<DataGridBonusCodeKeys, BonusCodeDetailsViewModel>(
    MuiDataGridClient,
    options => new DataGridBonusCodeConfigFactory().getColumnConfig(options)
);

export const DataGridBonusCodeServer = withDataGridEntityServer<DataGridBonusCodeKeys, BonusCodeDetailsViewModel>(
    MuiDataGridServer,
    options => new DataGridBonusCodeConfigFactory().getColumnConfig(options)
);
