import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {Policy} from '@auth';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {withModule} from '../../../features/module-shared/components/ModuleHoc';
import ModulePage from '../../../features/module-shared/components/ModulePage';
import {PermissionEnum} from '../../../features/module-shared/types';
import {domain} from '../types';

import data from './versionAcceptanceData';

const VersionAcceptance = () => {
    const stateData = useSelector((state: RootState) => state.userAcceptance);
    return (
        <Fragment>
            <ModulePage
                domain={domain.userAcceptanceVersion}
                data={stateData}
                header={data.localized.title}
                filters={data.filters}
                columns={data.columns}
                defaultSorting={data.defaultSorting}
            ></ModulePage>
        </Fragment>
    );
};

export const versionAcceptanceResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'VersionAcceptance',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.LegalContentManagement, null, 'read'),
        new Policy(ModuleName.LegalContentManagement, SubmoduleName.UserAcceptanceVersion, 'read'),
    ]),
});

export default withModule(VersionAcceptance, [
    {moduleName: ModuleName.LegalContentManagement, submoduleName: SubmoduleName.UserAcceptanceVersion, permissions: [PermissionEnum.Read]},
]);
