import React from 'react';

import {CircleSizes} from '@components/circle-box/CircleBox';
import {CircleBoxWithText} from '@components/circle-box/CircleBoxWithText';
import {EmptyLabel} from '@components/label';
import StyledTooltip from '@components/Tooltip';
import {SystemColorsEnum} from '@style';

export type CircleBoxWithColorMappingProps<T extends string> = {
    value: T;
    label: string;
    colorMapping: Record<T, SystemColorsEnum>;
};

export function CircleBoxWithColorMapping<T extends string>({value, label, colorMapping}: CircleBoxWithColorMappingProps<T>) {
    return (
        <>
            {value ? (
                <StyledTooltip
                    title={label}
                    element={
                        <div>
                            <CircleBoxWithText
                                letter={value?.charAt(0)}
                                color={value ? colorMapping[value] : SystemColorsEnum.DarkGrey}
                                size={CircleSizes.Small}
                                text={label}
                            />
                        </div>
                    }
                />
            ) : (
                <EmptyLabel defaultSymbol="dash" />
            )}
        </>
    );
}
