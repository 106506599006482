import {useContext} from 'react';
import {defineMessages, IntlContext, MessageDescriptor} from 'react-intl';

const localized = defineMessages({
    fieldDefault: {
        id: 'fieldDefault',
        defaultMessage: 'Field',
    },

    fieldRequired: {
        id: 'fieldRequired',
        defaultMessage: '{field} is required',
    },

    fieldShouldBeNumber: {
        id: 'fieldShouldBeNumber',
        defaultMessage: '{field} should be number',
    },

    fieldShouldBeGreaterOrEqual: {
        id: 'fieldShouldBeGreaterOrEqual',
        defaultMessage: '{field} should be at least {value}',
    },

    fieldShouldBeGreater: {
        id: 'fieldShouldBeGreater',
        defaultMessage: '{field} should be greater than {value}',
    },

    fieldShouldBeLessOrEqual: {
        id: 'fieldShouldBeLessOrEqual',
        defaultMessage: '{field} should be at most {value}',
    },

    fieldShouldBePositiveInteger: {
        id: 'fieldShouldBePositiveInteger',
        defaultMessage: '{field} should be positive integer',
    },

    fieldShoulHaveMaxLength: {
        id: 'fieldShoulHaveMaxLength',
        defaultMessage: '{field} should be no longer than {value} characters',
    },
});

export const numberRegex = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
export const positiveIntegerRegex = /^\d+$/;

export enum RuleType {
    Required,
    Number,
    PositiveInteger,
    Min,
    Max,
    MaxLength,
    GreaterThan,
}

const ruleMessageMapping = {
    [RuleType.Required]: localized.fieldRequired,
    [RuleType.Number]: localized.fieldShouldBeNumber,
    [RuleType.Min]: localized.fieldShouldBeGreaterOrEqual,
    [RuleType.Max]: localized.fieldShouldBeLessOrEqual,
    [RuleType.PositiveInteger]: localized.fieldShouldBePositiveInteger,
    [RuleType.MaxLength]: localized.fieldShoulHaveMaxLength,
    [RuleType.GreaterThan]: localized.fieldShouldBeGreater,
};

export const useValidationFormatter = () => {
    const {formatMessage} = useContext(IntlContext);

    return (ruleType: RuleType, field: string | MessageDescriptor, value?: string | number) => {
        const ruleMessage = ruleMessageMapping[ruleType];
        const fieldNameLocalized = field
            ? typeof field === 'string'
                ? field
                : formatMessage(field)
            : formatMessage(localized.fieldDefault);
        return formatMessage(ruleMessage, {field: fieldNameLocalized, value});
    };
};

export const useCustomValidationFormatter = () => {
    const {formatMessage} = useContext(IntlContext);

    return (message: MessageDescriptor, values?: Record<string, string>) => {
        return formatMessage(message, values);
    };
};
