import React, {useContext} from 'react';
import {Box} from '@mui/material';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {ModalContent, ModalContext, ModalStyleParams} from '@components/modal';
import {CustomTheme} from '@style';

//TODO: [BO-2680] Move files to src/common/components/modal
export const useClasses = makeStyles()((theme: CustomTheme) => ({
    cellContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        margin: theme.spacing(0, -2),
        padding: theme.spacing(0, 2),
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    cellControl: {
        display: 'flex',
        flexGrow: 1,
    },
    cellControlCentered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
    },
}));

export type GridModalActionProps<TOptions = any> = {
    onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
    params?: GridCellParams;
    options?: TOptions;
    centered?: boolean;
};

export type GridModalProps<TRow = any, TCol = any, TOptions = any> = {
    id?: string;
    title: JSX.Element;
    modalStyleParams?: ModalStyleParams;
    row?: TRow;
    rowCount?: number;
    columns?: TCol;
    params?: GridCellParams;
    options?: TOptions;
    footer?: JSX.Element;
    centered?: boolean;
};

export const withGridModal =
    <TRow, TCol, TOptions>(
        WrappedComponent: React.ComponentType<GridModalActionProps<TOptions>>,
        WrappedComponentModal: React.ComponentType<GridModalProps<TRow, TCol, TOptions>>
    ) =>
    ({title, modalStyleParams, id, row, rowCount, columns, footer, centered, ...otherParams}: GridModalProps<TRow, TCol, TOptions>) => {
        const {classes} = useClasses();

        const {openModal} = useContext(ModalContext);

        const open = (content: ModalContent) => {
            openModal(content);
        };

        const modalContent = (
            <WrappedComponentModal id={id} row={row} rowCount={rowCount} columns={columns} title={title} {...otherParams} />
        );

        return (
            <Box className={classes.cellContainer}>
                <Box className={centered ? classes.cellControlCentered : classes.cellControl}>
                    {
                        <WrappedComponent
                            onClick={() =>
                                open({
                                    body: modalContent,
                                    title,
                                    styleParams: modalStyleParams,
                                    footer,
                                })
                            }
                            {...otherParams}
                        />
                    }
                </Box>
            </Box>
        );
    };
