import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {UserManager} from 'oidc-client-ts';
import {from, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {
    AddUserLabelInput,
    BulkItemInput,
    BulkMutationInput,
    BulkMutationResponse,
    DeleteUserLabelInput,
    FeatureAccessStatus,
    Mutation,
    MutationAddUserLabelsArgs,
    MutationAddUserLabelsOperationArgs,
    MutationDeleteUserLabelArgs,
    MutationDeleteUserLabelsOperationArgs,
    MutationLockCasinoArgs,
    MutationLockCasinoBulkOperationArgs,
    MutationLockDepositArgs,
    MutationLockP2pFeatureArgs,
    MutationLockSportsbookArgs,
    MutationLockSportsbookBulkOperationArgs,
    MutationLockWithdrawalArgs,
    MutationUpdateAffiliateBtagArgs,
    MutationUpdateUserArgs,
    Platform,
    QueryGetPlayerReferralLinkArgs,
    QueryGetUserProfilesArgs,
    SecurityCase,
    TextFilter,
    UserAddressInfoInput,
    UserLabel,
    UserNetworkProfile,
    UserProfile,
    UserProfileFilter,
    UserProfileInput,
} from '@models/generated/graphql';
import {Filter, UserProfileQueryFields, UserProfileServerFilterKeys, UserProfileServerTextFilterKeys} from '@redux/entity';
import {EntityBaseGqlService, IEntityReadService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';
import {PlayerManagementApiService} from '@services/rest-api';
import {ChangeLockStatusModel, EditEmailRequestModel, EditPhoneRequestModel} from '@services/rest-api/playerManagementApiService';

import {DynamicConfig} from 'src/configuration';
import {JurisdictionConfigService} from 'src/features/app/config/services';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';
import {GqlMutationRequest, ServiceResponsePayload} from './types';

type UpdatePersonalInfoModel = Pick<UserProfileInput, 'uid' | 'first_name' | 'last_name' | 'nickname' | 'birthday' | 'gender'>;
type UpdateAddressInfoModel = Pick<UserProfileInput, 'uid'> & {
    address: Pick<UserAddressInfoInput, 'city' | 'country_info' | 'state' | 'post_code' | 'street'> & {
        stateName?: string;
    };
};
export type UpdateAffiliateBtagModel = Pick<UserProfileInput, 'uid' | 'affiliate'> & {id: string};
type UpdateAgentInfoModel = Pick<UserProfileInput, 'uid' | 'agent_info'>;

export interface IUserProfileService extends IEntityReadService {
    lockP2PBatch(payload: BulkMutationInput): Observable<BulkMutationResponse>;

    lockCasinoBatch(
        items: BulkItemInput[]
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationLockCasinoBulkOperationArgs>, BulkMutationResponse>>;

    assignUserLabels(payload: BulkMutationInput): Observable<BulkMutationResponse>;

    unAssignUserLabels(payload: BulkMutationInput): Observable<BulkMutationResponse>;

    addUserLabels(input: AddUserLabelInput): Observable<ServiceResponsePayload<GqlMutationRequest, UserProfile>>;

    deleteUserLabel(input: DeleteUserLabelInput): Observable<ServiceResponsePayload<GqlMutationRequest, UserProfile>>;

    editEmail(uid: string, email: string): Observable<ServiceResponsePayload<EditEmailRequestModel, null>>;

    editPhone(uid: string, area: string, mobile: string): Observable<ServiceResponsePayload<EditPhoneRequestModel, null>>;

    updatePersonalInfo(
        user: UpdatePersonalInfoModel
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>>;

    updateAddressInfo(
        user: UpdateAddressInfoModel
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>>;

    updateAffiliateBtag(user: UpdateAffiliateBtagModel): Observable<ServiceResponsePayload<UpdateAffiliateBtagModel, UserProfile>>;

    changeAccountLockStatus(model: ChangeLockStatusModel): Observable<ServiceResponsePayload<ChangeLockStatusModel, null>>;

    changeCasinoLockStatus(model: ChangeLockStatusModel): Observable<ServiceResponsePayload<GqlMutationRequest, UserProfile>>;

    changeSportsbookLockStatus(
        model: ChangeLockStatusModel
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationLockSportsbookArgs>, UserProfile>>;

    lockSportsbookBatch(
        items: BulkItemInput[]
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationLockSportsbookBulkOperationArgs>, BulkMutationResponse>>;

    changeDepositLockStatus(model: ChangeLockStatusModel): Observable<ServiceResponsePayload<GqlMutationRequest, UserProfile>>;

    changeWithdrawalLockStatus(model: ChangeLockStatusModel): Observable<ServiceResponsePayload<GqlMutationRequest, UserProfile>>;

    changeLobbyLockStatus(model: ChangeLockStatusModel): Observable<ServiceResponsePayload<ChangeLockStatusModel, null>>;

    changeP2PTransferStatus(model: ChangeLockStatusModel): Observable<ServiceResponsePayload<GqlMutationRequest, UserProfile>>;

    updateAgentInfo(
        user: UpdateAgentInfoModel
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>>;

    //TODO: Remove when agent revenue share update is enabled
    updateAgentInfoObsolete(
        user: UpdateAgentInfoModel
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>>;
}

@injectable()
export class UserProfileService
    extends EntityBaseGqlService<QueryGetUserProfilesArgs, UserProfileQueryFields, UserProfileServerFilterKeys>
    implements IEntityReadService, IUserProfileService
{
    private readonly _playerManagementApiService: PlayerManagementApiService;
    private readonly _userManager: UserManager;

    constructor(
        @inject(ServiceTypes.ApolloClientIGPMocked) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.PlayerManagementApiService) playerManagementApiService: PlayerManagementApiService,
        @inject(ServiceTypes.JurisdictionConfigService) jurisdictionConfigService: JurisdictionConfigService,
        @inject(ServiceTypes.UserManager) userManager: UserManager,
        @inject(ServiceTypes.Config) config: DynamicConfig
    ) {
        super(client, mapper, new UserProfileRequestBuilder(jurisdictionConfigService, config));
        this._playerManagementApiService = playerManagementApiService;
        this._userManager = userManager;
    }

    public lockP2PBatch(payload: BulkMutationInput): Observable<BulkMutationResponse> {
        return this._service
            .mutateObsolete(this.getLockP2PMutation(), {bulkData: payload})
            .pipe(map((res: Mutation) => res.lockP2pBulkOperation));
    }

    public lockCasinoBatch(
        items: BulkItemInput[]
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationLockCasinoBulkOperationArgs>, BulkMutationResponse>> {
        const payload: BulkMutationInput = {
            items,
            retries: 3,
            timeout: 10000,
        };

        return this._service
            .mutate<Mutation, MutationLockCasinoBulkOperationArgs>(this.getBulkCasinoLockMutation(), {bulkData: payload})
            .pipe(map(res => ({...res, responsePayload: res?.responsePayload?.lockCasinoBulkOperation})));
    }

    public assignUserLabels(payload: BulkMutationInput): Observable<BulkMutationResponse> {
        return this._service
            .mutate<Mutation, MutationAddUserLabelsOperationArgs>(this.getAssignUserLabelsMutation(), {bulkData: payload})
            .pipe(map(res => res.responsePayload?.addUserLabelsOperation));
    }

    public unAssignUserLabels(payload: BulkMutationInput): Observable<BulkMutationResponse> {
        return this._service
            .mutate<Mutation, MutationDeleteUserLabelsOperationArgs>(this.getUnAssignUserLabelsMutation(), {bulkData: payload})
            .pipe(map(res => res.responsePayload?.deleteUserLabelsOperation));
    }

    public addUserLabels(input: AddUserLabelInput): Observable<ServiceResponsePayload<GqlMutationRequest, UserProfile>> {
        return this._service
            .mutate<Mutation, MutationAddUserLabelsArgs>(this.getAddUserLabelsMutation(), {input})
            .pipe(map(res => ({...res, responsePayload: res.responsePayload?.addUserLabels})));
    }

    public deleteUserLabel(input: DeleteUserLabelInput): Observable<ServiceResponsePayload<GqlMutationRequest, UserProfile>> {
        return this._service
            .mutate<Mutation, MutationDeleteUserLabelArgs>(this.getDeleteUserLabelMutation(), {
                input: {uid: input.uid, label_id: input.label_id},
            })
            .pipe(map(res => ({...res, responsePayload: res.responsePayload?.deleteUserLabel})));
    }

    public editEmail(uid: string, email: string): Observable<ServiceResponsePayload<EditEmailRequestModel, null>> {
        return this._playerManagementApiService.editEmail(uid, email);
    }

    public editPhone(uid: string, area: string, mobile: string): Observable<ServiceResponsePayload<EditPhoneRequestModel, null>> {
        return this._playerManagementApiService.editPhone(uid, area, mobile);
    }

    public updatePersonalInfo(
        user: UpdatePersonalInfoModel
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>> {
        return from(this._userManager.getUser()).pipe(
            mergeMap(boUser =>
                this._service
                    .mutate<Mutation, MutationUpdateUserArgs>(this.getUpdatePersonalInfoMutation(), {
                        uid: user.uid,
                        user: {...user, action_by_uid: boUser?.profile.sub, pii_manual: true},
                    })
                    .pipe(map(r => ({...r, responsePayload: r.responsePayload.updateUser})))
            )
        );
    }

    public updateAddressInfo(
        user: UpdateAddressInfoModel
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>> {
        const {stateName, ...userAddress} = user.address ?? {};
        const userPayload: UserProfileInput = {...user, address: userAddress} as UserProfileInput;
        return from(this._userManager.getUser()).pipe(
            mergeMap(boUser =>
                this._service
                    .mutate<Mutation, MutationUpdateUserArgs>(this.getUpdateAddressInfoMutation(), {
                        uid: userPayload.uid,
                        user: {...userPayload, action_by_uid: boUser?.profile.sub, pii_manual: true},
                    })
                    .pipe(map(r => ({...r, responsePayload: r.responsePayload.updateUser})))
            )
        );
    }

    public updateAffiliateBtag(user: UpdateAffiliateBtagModel): Observable<ServiceResponsePayload<UpdateAffiliateBtagModel, UserProfile>> {
        return this._service
            .mutate<Mutation, MutationUpdateAffiliateBtagArgs>(this.getUpdateAffiliateBtagMutation(), {
                uid: user.uid,
                affiliate: {btag: user.affiliate.btag},
            })
            .pipe(map(r => ({...r, requestPayload: user, responsePayload: r.responsePayload.updateUser})));
    }

    public updateAgentInfo(
        user: UpdateAgentInfoModel
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>> {
        return user?.uid
            ? this._service
                  .mutate<Mutation, MutationUpdateUserArgs>(this.getUpdateAgentInfoMutation(), {
                      uid: user.uid,
                      user,
                  })
                  .pipe(map(r => ({...r, responsePayload: r.responsePayload.updateUser})))
            : of();
    }

    //TODO: Remove when agent revenue share update is enabled
    public updateAgentInfoObsolete(
        user: UpdateAgentInfoModel
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>> {
        return user?.uid
            ? this._service
                  .mutate<Mutation, MutationUpdateUserArgs>(this.getUpdateAgentInfoMutationObsolete(), {
                      uid: user.uid,
                      user,
                  })
                  .pipe(map(r => ({...r, responsePayload: r.responsePayload.updateUser})))
            : of();
    }

    public changeAccountLockStatus(model: ChangeLockStatusModel): Observable<ServiceResponsePayload<ChangeLockStatusModel, null>> {
        return this._playerManagementApiService.changeAccountLockStatus(model);
    }

    public changeDepositLockStatus({
        uid,
        value,
    }: ChangeLockStatusModel): Observable<ServiceResponsePayload<GqlMutationRequest, UserProfile>> {
        return this._service
            .mutate<Mutation, MutationLockDepositArgs>(this.getDepositLockMutation(), {
                uid,
                deposit_lock: {uid, feature_status: value ? FeatureAccessStatus.Locked : FeatureAccessStatus.Unlocked},
            })
            .pipe(map(r => ({...r, responsePayload: r.responsePayload.lockDeposit})));
    }

    public changeWithdrawalLockStatus({
        uid,
        value,
    }: ChangeLockStatusModel): Observable<ServiceResponsePayload<GqlMutationRequest, UserProfile>> {
        return this._service
            .mutate<Mutation, MutationLockWithdrawalArgs>(this.getWithdrawalLockMutation(), {
                uid,
                withdrawal_lock: {uid, feature_status: value ? FeatureAccessStatus.Locked : FeatureAccessStatus.Unlocked},
            })
            .pipe(map(r => ({...r, responsePayload: r.responsePayload.lockWithdrawal})));
    }

    public changeCasinoLockStatus({
        uid,
        value,
    }: ChangeLockStatusModel): Observable<ServiceResponsePayload<GqlMutationRequest, UserProfile>> {
        return this._service
            .mutate<Mutation, MutationLockCasinoArgs>(this.getCasinoLockMutation(), {
                uid,
                casino_lock: {uid, feature_status: value ? FeatureAccessStatus.Locked : FeatureAccessStatus.Unlocked},
            })
            .pipe(map(r => ({...r, responsePayload: r.responsePayload.lockCasino})));
    }

    public changeSportsbookLockStatus({
        uid,
        value,
    }: ChangeLockStatusModel): Observable<ServiceResponsePayload<GqlMutationRequest<MutationLockSportsbookArgs>, UserProfile>> {
        return this._service
            .mutate<Mutation, MutationLockSportsbookArgs>(this.getSportsbookLockMutation(), {
                uid,
                sportsbook_lock: {uid, feature_status: value ? FeatureAccessStatus.Locked : FeatureAccessStatus.Unlocked},
            })
            .pipe(map(r => ({...r, responsePayload: r.responsePayload.lockSportsbook})));
    }

    public lockSportsbookBatch(
        items: BulkItemInput[]
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationLockSportsbookBulkOperationArgs>, BulkMutationResponse>> {
        const payload: BulkMutationInput = {
            items,
            retries: 3,
            timeout: 10000,
        };

        return this._service
            .mutate<Mutation, MutationLockSportsbookBulkOperationArgs>(this.getBulkSportsbookLockMutation(), {bulkData: payload})
            .pipe(map(res => ({...res, responsePayload: res?.responsePayload?.lockSportsbookBulkOperation})));
    }

    public changeLobbyLockStatus(model: ChangeLockStatusModel): Observable<ServiceResponsePayload<ChangeLockStatusModel, null>> {
        return this._playerManagementApiService.changeLobbyLockStatus(model);
    }

    public changeP2PTransferStatus({
        uid,
        value,
    }: ChangeLockStatusModel): Observable<ServiceResponsePayload<GqlMutationRequest, UserProfile>> {
        return this._service
            .mutate<Mutation, MutationLockP2pFeatureArgs>(this.getP2PLockMutation(), {
                uid,
                p2p_lock: {uid, feature_status: value ? FeatureAccessStatus.Locked : FeatureAccessStatus.Unlocked},
            })
            .pipe(map(r => ({...r, responsePayload: r.responsePayload.lockP2pFeature})));
    }

    private getLockP2PMutation() {
        return gql`
            mutation LockP2pBulkOperation($bulkData: BulkMutationInput!) {
                lockP2pBulkOperation(bulkData: $bulkData) {
                    id
                }
            }
        `;
    }

    private getBulkCasinoLockMutation() {
        return gql`
            mutation LockCasinoBulkOperation($bulkData: BulkMutationInput!) {
                lockCasinoBulkOperation(bulkData: $bulkData) {
                    id
                }
            }
        `;
    }

    private getBulkSportsbookLockMutation() {
        return gql`
            mutation LockSportsbookBulkOperation($bulkData: BulkMutationInput!) {
                lockSportsbookBulkOperation(bulkData: $bulkData) {
                    id
                }
            }
        `;
    }

    private getP2PLockMutation() {
        return gql`
            mutation LockP2pFeature($uid: String!, $p2p_lock: FeatureAccessLockInput!) {
                lockP2pFeature(uid: $uid, p2p_lock: $p2p_lock) {
                    uid
                }
            }
        `;
    }

    private getDepositLockMutation() {
        return gql`
            mutation LockDeposit($uid: String!, $deposit_lock: FeatureAccessLockInput!) {
                lockDeposit(uid: $uid, deposit_lock: $deposit_lock) {
                    uid
                }
            }
        `;
    }

    private getWithdrawalLockMutation() {
        return gql`
            mutation LockWithdrawal($uid: String!, $withdrawal_lock: FeatureAccessLockInput!) {
                lockWithdrawal(uid: $uid, withdrawal_lock: $withdrawal_lock) {
                    uid
                }
            }
        `;
    }

    private getCasinoLockMutation() {
        return gql`
            mutation LockCasino($uid: String!, $casino_lock: FeatureAccessLockInput!) {
                lockCasino(uid: $uid, casino_lock: $casino_lock) {
                    uid
                }
            }
        `;
    }

    private getSportsbookLockMutation() {
        return gql`
            mutation LockSportsbook($uid: String!, $sportsbook_lock: FeatureAccessLockInput!) {
                lockSportsbook(uid: $uid, sportsbook_lock: $sportsbook_lock) {
                    uid
                }
            }
        `;
    }

    private getAssignUserLabelsMutation() {
        return gql`
            mutation AddUserLabelsOperation($bulkData: BulkMutationInput!) {
                addUserLabelsOperation(bulkData: $bulkData) {
                    id
                }
            }
        `;
    }

    private getUnAssignUserLabelsMutation() {
        return gql`
            mutation DeleteUserLabelsOperation($bulkData: BulkMutationInput!) {
                deleteUserLabelsOperation(bulkData: $bulkData) {
                    id
                }
            }
        `;
    }

    private getAddUserLabelsMutation() {
        return gql`
            mutation AddUserLabels($input: AddUserLabelInput!) {
                addUserLabels(input: $input) {
                    uid
                    labels {
                        id
                        name
                        group {
                            id
                            color
                            name
                        }
                    }
                }
            }
        `;
    }

    private getDeleteUserLabelMutation() {
        return gql`
            mutation DeleteUserLabel($input: DeleteUserLabelInput!) {
                deleteUserLabel(input: $input) {
                    uid
                    labels {
                        id
                        name
                        group {
                            id
                            color
                            name
                        }
                    }
                }
            }
        `;
    }

    private getUpdatePersonalInfoMutation() {
        return gql`
            mutation UpdateUser($uid: String!, $user: UserProfileInput!) {
                updateUser(uid: $uid, user: $user) {
                    uid
                    first_name
                    last_name
                    nickname
                    birthday {
                        seconds
                    }
                    gender
                }
            }
        `;
    }

    private getUpdateAddressInfoMutation() {
        return gql`
            mutation UpdateUser($uid: String!, $user: UserProfileInput!) {
                updateUser(uid: $uid, user: $user) {
                    uid
                    address {
                        city
                        street
                        country_info {
                            iso_alpha2_code
                            name
                        }
                        post_code
                        state
                    }
                }
            }
        `;
    }

    private getUpdateAffiliateBtagMutation() {
        return gql`
            mutation UpdateAffiliateBtag($uid: String!, $affiliate: AffiliateInput!) {
                updateAffiliateBtag(uid: $uid, affiliate: $affiliate) {
                    uid
                    affiliate {
                        btag
                    }
                }
            }
        `;
    }

    private getUpdateAgentInfoMutation() {
        return gql`
            mutation UpdateUser($uid: String!, $user: UserProfileInput!) {
                updateUser(uid: $uid, user: $user) {
                    uid
                    agent_info {
                        bo_agent_id
                        agent_granted_ts {
                            seconds
                        }
                        default_agent_revenue_share_history
                    }
                }
            }
        `;
    }

    //TODO: Remove when agent revenue share update is enabled
    private getUpdateAgentInfoMutationObsolete() {
        return gql`
            mutation UpdateUser($uid: String!, $user: UserProfileInput!) {
                updateUser(uid: $uid, user: $user) {
                    uid
                    agent_info {
                        bo_agent_id
                        agent_granted_ts {
                            seconds
                        }
                    }
                }
            }
        `;
    }
}

export class UserProfileRequestBuilder extends GqlRequestBuilder<
    QueryGetUserProfilesArgs,
    UserProfileQueryFields,
    UserProfileServerFilterKeys
> {
    protected buildFilter(
        filter: Filter<UserProfileServerFilterKeys>
    ): Pick<QueryGetUserProfilesArgs, 'filter'> & Pick<QueryGetPlayerReferralLinkArgs, 'uid'> {
        const referrerId: string =
            this.toGQLStringFilter(filter, 'referrerPlayerId') ?? this.toGQLStringFilter(filter, 'defaultReferrerPlayerId');
        return {
            filter: {
                text: this.getGQLTextFilter([
                    ...Object.keys(this.userProfileTextFilterFieldsMapper).map((key: UserProfileServerTextFilterKeys) =>
                        this.toGQLTextFilter(
                            this.userProfileTextFilterFieldsMapper[key],
                            filter[key] as string,
                            this.transformTextMapper[key]
                        )
                    ),
                    filter.networks_PKW
                        ? {
                              text: Platform.Pkw,
                              search_in: [`${nameof.full<UserProfile>(u => u.networks)}.${nameof<UserNetworkProfile>(p => p.platform)}`],
                          }
                        : null,
                    filter.networks_BL
                        ? {
                              text: Platform.Bl,
                              search_in: [`${nameof.full<UserProfile>(u => u.networks)}.${nameof<UserNetworkProfile>(p => p.platform)}`],
                          }
                        : null,
                ] as TextFilter[]),
                birthday: this.toGQLDateRange(filter.birthdayFrom, filter.birthdayTo),
                date_of_joining: this.toGQLDateRange(filter.joinedFrom, filter.joinedTo),
                account_status: this.toGQLMultiselectFilter(filter, 'accountStatus'),
                deposit_status: this.toGQLMultiselectFilter(filter, 'depositStatus'),
                withdrawal_status: this.toGQLMultiselectFilter(filter, 'withdrawalStatus'),
                kyc_status: this.toGQLMultiselectFilter(filter, 'kycStatus'),
                security_cases: this.toGQLMultiselectFilter(filter, 'securityCases'),
                players_groups: this.toGQLMultiselectFilter(filter, 'playersGroups'),
                referrer: referrerId
                    ? {
                          referrer_player_id: referrerId,
                          is_downstream: this.toGQLBooleanFilter(filter, 'isDownstream'),
                          is_agent: true,
                      }
                    : undefined,
            } as UserProfileFilter,
            uid: (filter.uid as string) ?? '',
        };
    }

    private getNetworksQueryItems(): UserProfileQueryFields[] {
        return ['networks.uid', 'networks.platform'];
    }

    private getBirthdayQueryItems(): UserProfileQueryFields[] {
        return ['birthday.seconds'];
    }

    private getDateOfJoiningQueryItems(): UserProfileQueryFields[] {
        return ['date_of_joining.seconds'];
    }

    private getContactQueryItems(): UserProfileQueryFields[] {
        return ['contact.email', ...this.getContactMobileQueryItems()];
    }

    private getContactMobileQueryItems(): UserProfileQueryFields[] {
        return ['contact.mobile.area', 'contact.mobile.mobile', 'contact.mobile.full_number'];
    }

    private getAddressQueryItems(): UserProfileQueryFields[] {
        return [
            'address.address',
            'address.city',
            'address.state',
            'address.country',
            'address.post_code',
            ...this.getAddressCountryQueryItems(),
        ];
    }

    private getAddressCountryQueryItems(): UserProfileQueryFields[] {
        return ['address.country_info.iso_alpha2_code', 'address.country_info.name'];
    }

    private getSecuritySettingsQueryItems(): UserProfileQueryFields[] {
        return ['security_settings.is_2fa_enabled', 'security_settings.is_challenge_questions_enabled'];
    }

    private getBlacklistQueryItems(): UserProfileQueryFields[] {
        return ['black_list.items.type', 'black_list.items.key'];
    }

    private getDevicesQueryItems(): UserProfileQueryFields[] {
        return ['devices.uuid', 'devices.model', 'devices.name', 'devices.operating_system'];
    }

    private getSecurityCasesQueryItems(): UserProfileQueryFields[] {
        return ['security_cases.type', 'security_cases.case_id'];
    }

    private getLatestLoginQueryItems(): UserProfileQueryFields[] {
        return [
            'latest_login.login_type',
            'latest_login.login_status',
            'latest_login.ip',
            'latest_login.isp',
            'latest_login.session_id',
            'latest_login.recaptcha_score',
            'latest_login.recaptcha_reasons',
            'latest_login.ip_quality_score',
            ...this.getLatestLoginLoggedAtQueryItems(),
            ...this.getLatestLoginLocationQueryItems(),
            ...this.getLatestDeviceQueryItems(),
        ];
    }

    private getLatestLoginLoggedAtQueryItems(): UserProfileQueryFields[] {
        return ['latest_login.logged_at_ts.seconds'];
    }

    private getLatestLoginLocationQueryItems(): UserProfileQueryFields[] {
        return [
            'latest_login.location.country',
            'latest_login.location.state',
            'latest_login.location.city',
            'latest_login.location.latitude',
            'latest_login.location.longitude',
        ];
    }

    private getLatestDeviceQueryItems(): UserProfileQueryFields[] {
        return [
            'latest_login.device.mac_address',
            'latest_login.device.model',
            'latest_login.device.uuid',
            'latest_login.device.name',
            'latest_login.device.operating_system',
        ];
    }

    private getFinanceQueryItems(): UserProfileQueryFields[] {
        return [
            ...this.getFinanceDepositQueryItems(),
            ...this.getFinanceWithdrawalQueryItems(),
            'finance.generated_rake',
            'finance.balance',
            'finance.ggr',
            'finance.lifetime_bonus',
            'finance.bonus_ratio',
        ];
    }

    private getFinanceDepositQueryItems(): UserProfileQueryFields[] {
        return [...this.getFinanceDepositTotalQueryItems(), ...this.getFinanceDepositPerMethodQueryItems()];
    }

    private getFinanceDepositTotalQueryItems(): UserProfileQueryFields[] {
        return ['finance.deposit.total.successful_amount'];
    }

    private getFinanceDepositPerMethodQueryItems(): UserProfileQueryFields[] {
        return [
            'finance.deposit.per_method.category',
            'finance.deposit.per_method.group_name',
            'finance.deposit.per_method.successful_amount',
            'finance.deposit.per_method.failed_amount',
            'finance.deposit.per_method.rejected_amount',
            'finance.deposit.per_method.successful_count',
            'finance.deposit.per_method.failed_count',
            'finance.deposit.per_method.rejected_count',
            'finance.deposit.per_method.currency',
        ];
    }

    private getFinanceWithdrawalQueryItems(): UserProfileQueryFields[] {
        return [...this.getFinanceWithdrawalTotalQueryItems(), ...this.getFinanceWithdrawalPerMethodQueryItems()];
    }

    private getFinanceWithdrawalTotalQueryItems(): UserProfileQueryFields[] {
        return [
            'finance.withdrawal.total.successful_amount',
            'finance.withdrawal.total.successful_count',
            'finance.withdrawal.total.pending_amount',
        ];
    }

    private getFinanceWithdrawalPerMethodQueryItems(): UserProfileQueryFields[] {
        return [
            'finance.withdrawal.per_method.category',
            'finance.withdrawal.per_method.group_name',
            'finance.withdrawal.per_method.successful_amount',
            'finance.withdrawal.per_method.failed_amount',
            'finance.withdrawal.per_method.rejected_amount',
            'finance.withdrawal.per_method.successful_count',
            'finance.withdrawal.per_method.failed_count',
            'finance.withdrawal.per_method.rejected_count',
            'finance.withdrawal.per_method.currency',
        ];
    }

    private getLatestKycPaymentDocumentsQueryItems(): UserProfileQueryFields[] {
        return [
            'latest_kyc.payment.documents.first_name',
            'latest_kyc.payment.documents.last_name',
            'latest_kyc.payment.documents.gender',
            'latest_kyc.payment.documents.dob',
            'latest_kyc.payment.documents.doc_type',
            'latest_kyc.payment.documents.doc_id_sub_type',
            'latest_kyc.payment.documents.number',
            'latest_kyc.payment.documents.device_verified',
            'latest_kyc.payment.documents.ip_verified',
            'latest_kyc.payment.documents.geolocation_verified',
        ];
    }

    private getLatestKycPaymentQueryItems(): UserProfileQueryFields[] {
        return [
            ...this.getLatestKycPaymentDocumentsQueryItems(),
            'latest_kyc.payment.id',
            'latest_kyc.payment.account_verification_status',
        ];
    }

    private getLatestKycSecurityDocumentsQueryItems(): UserProfileQueryFields[] {
        return [
            'latest_kyc.security.documents.first_name',
            'latest_kyc.security.documents.last_name',
            'latest_kyc.security.documents.gender',
            'latest_kyc.security.documents.dob',
            'latest_kyc.security.documents.doc_type',
            'latest_kyc.security.documents.doc_id_sub_type',
            'latest_kyc.security.documents.number',
            'latest_kyc.security.documents.device_verified',
            'latest_kyc.security.documents.ip_verified',
            'latest_kyc.security.documents.geolocation_verified',
        ];
    }

    private getLatestKycSecurityQueryItems(): UserProfileQueryFields[] {
        return [
            ...this.getLatestKycSecurityDocumentsQueryItems(),
            'latest_kyc.security.id',
            'latest_kyc.security.kyc_case_id',
            'latest_kyc.security.account_verification_status',
        ];
    }

    private getLatestKycQueryItems(): UserProfileQueryFields[] {
        return [...this.getLatestKycPaymentQueryItems(), ...this.getLatestKycSecurityQueryItems()];
    }

    private getAffiliateQueryItems(): UserProfileQueryFields[] {
        return ['affiliate.btag'];
    }

    private getReferralQueryItems(): UserProfileQueryFields[] {
        return [
            'referral.inviting_user_id',
            'referral.inviting_user_username',
            'referral.personal_referral_code',
            'referral.referral_code',
            'referral.referred_at',
        ];
    }

    private getAgentInfoQueryItems(): UserProfileQueryFields[] {
        return ['agent_info.agent_granted_ts.seconds', 'agent_info.bo_agent_id', 'agent_info.default_agent_revenue_share_history'];
    }

    private getRegionRelatedInfoQueryItems(): UserProfileQueryFields[] {
        return [
            'region_player_info.occupation',
            'region_player_info.tax_id',
            'region_player_info.national_id',
            'region_player_info.nationality',
            'region_player_info.country_of_birth',
            'region_player_info.district',
        ];
    }

    private getMarketingInfoQueryItems(): UserProfileQueryFields[] {
        return ['marketing_info.register_marketing_code'];
    }

    private getLabelsGroupQueryItems(): UserProfileQueryFields[] {
        return ['labels.group.id', 'labels.group.color', 'labels.group.name'];
    }

    private getLabelsQueryItems(): UserProfileQueryFields[] {
        return ['labels.id', 'labels.name', ...this.getLabelsGroupQueryItems()];
    }

    public buildQuery(fields: UserProfileQueryFields[]): DocumentNode {
        return gql`
            query GetUserItems($filter: UserProfileFilter, $uid: String!, $sort: Sorting, $start: Int, $end: Int) {
                getUserProfiles(filter: $filter, sort: $sort, start: $start, end: $end) {
                    items {
                        uid
                        networks @include(if: ${this.hasAnyField(fields, this.getNetworksQueryItems())}) {
                            uid @include(if: ${this.hasField(fields, 'networks.uid')})
                            platform @include(if: ${this.hasField(fields, 'networks.platform')})
                        }
                        username @include(if: ${this.hasField(fields, 'username')})
                        nickname @include(if: ${this.hasField(fields, 'nickname')})
                        first_name @include(if: ${this.hasField(fields, 'first_name')})
                        last_name @include(if: ${this.hasField(fields, 'last_name')})
                        contact  @include(if: ${this.hasAnyField(fields, this.getContactQueryItems())}) {
                            email @include(if: ${this.hasField(fields, 'contact.email')})
                            mobile  @include(if: ${this.hasAnyField(fields, this.getContactMobileQueryItems())}) {
                                area @include(if: ${this.hasField(fields, 'contact.mobile.area')})
                                mobile @include(if: ${this.hasField(fields, 'contact.mobile.mobile')})
                                full_number @include(if: ${this.hasField(fields, 'contact.mobile.full_number')})
                            }
                        }
                        birthday @include(if: ${this.hasAnyField(fields, this.getBirthdayQueryItems())}) {
                            seconds @include(if: ${this.hasField(fields, 'birthday.seconds')})
                        }
                        gender @include(if: ${this.hasField(fields, 'gender')})
                        address @include(if: ${this.hasAnyField(fields, this.getAddressQueryItems())}) {
                            address @include(if: ${this.hasField(fields, 'address.address')})
                            city @include(if: ${this.hasField(fields, 'address.city')})
                            state @include(if: ${this.hasField(fields, 'address.state')})
                            country @include(if: ${this.hasField(fields, 'address.country')})
                            country_info @include(if: ${this.hasAnyField(fields, this.getAddressCountryQueryItems())}) {
                                iso_alpha2_code @include(if: ${this.hasField(fields, 'address.country_info.iso_alpha2_code')})
                                name @include(if: ${this.hasField(fields, 'address.country_info.name')})
                            }
                            post_code @include(if: ${this.hasField(fields, 'address.post_code')})
                        }
                        date_of_joining  @include(if: ${this.hasAnyField(fields, this.getDateOfJoiningQueryItems())}) {
                            seconds @include(if: ${this.hasField(fields, 'date_of_joining.seconds')})
                        }
                        account_status @include(if: ${this.hasField(fields, 'account_status')})
                        casino_status @include(if: ${this.hasField(fields, 'casino_status')})
                        sportsbook_status @include(if: ${this.hasField(fields, 'sportsbook_status')})
                        security_settings @include(if: ${this.hasAnyField(fields, this.getSecuritySettingsQueryItems())}) {
                            is_2fa_enabled @include(if: ${this.hasField(fields, 'security_settings.is_2fa_enabled')})
                            is_challenge_questions_enabled @include(if: ${this.hasField(
                                fields,
                                'security_settings.is_challenge_questions_enabled'
                            )})
                        }
                        black_list @include(if: ${this.hasAnyField(fields, this.getBlacklistQueryItems())}) {
                            items @include(if: ${this.hasAnyField(fields, this.getBlacklistQueryItems())}) {
                                type @include(if: ${this.hasField(fields, 'black_list.items.type')})
                                key @include(if: ${this.hasField(fields, 'black_list.items.key')})
                            }
                        }
                        ip_addresses @include(if: ${this.hasField(fields, 'ip_addresses')})
                        devices  @include(if: ${this.hasAnyField(fields, this.getDevicesQueryItems())}) {
                            uuid @include(if: ${this.hasField(fields, 'devices.uuid')})
                            model @include(if: ${this.hasField(fields, 'devices.model')})
                            name @include(if: ${this.hasField(fields, 'devices.name')})
                            operating_system @include(if: ${this.hasField(fields, 'devices.operating_system')})
                        }
                        security_cases @include(if: ${this.hasAnyField(fields, this.getSecurityCasesQueryItems())}) {
                            type @include(if: ${this.hasField(fields, 'security_cases.type')})
                            case_id @include(if: ${this.hasField(fields, 'security_cases.case_id')})
                            opened_at @include(if: ${this.hasField(fields, 'security_cases.opened_at')}) {
                                seconds
                            }
                            closed_at @include(if: ${this.hasField(fields, 'security_cases.closed_at')}) {
                                seconds
                            }
                        }
                        latest_login @include(if: ${this.hasAnyField(fields, this.getLatestLoginQueryItems())}) {
                            login_type @include(if: ${this.hasField(fields, 'latest_login.login_type')})
                            login_status @include(if: ${this.hasField(fields, 'latest_login.login_status')})
                            logged_at_ts  @include(if: ${this.hasAnyField(fields, this.getLatestLoginLoggedAtQueryItems())}) {
                                seconds @include(if: ${this.hasField(fields, 'latest_login.logged_at_ts.seconds')})
                            }
                            location @include(if: ${this.hasAnyField(fields, this.getLatestLoginLocationQueryItems())}) {
                                country @include(if: ${this.hasField(fields, 'latest_login.location.country')})
                                state @include(if: ${this.hasField(fields, 'latest_login.location.state')})
                                city @include(if: ${this.hasField(fields, 'latest_login.location.city')})
                                latitude @include(if: ${this.hasField(fields, 'latest_login.location.latitude')})
                                longitude @include(if: ${this.hasField(fields, 'latest_login.location.longitude')})
                            }
                            ip @include(if: ${this.hasField(fields, 'latest_login.ip')})
                            isp @include(if: ${this.hasField(fields, 'latest_login.isp')})
                            device @include(if: ${this.hasAnyField(fields, this.getLatestDeviceQueryItems())}) {
                                mac_address @include(if: ${this.hasField(fields, 'latest_login.device.mac_address')})
                                model @include(if: ${this.hasField(fields, 'latest_login.device.model')})
                                uuid @include(if: ${this.hasField(fields, 'latest_login.device.uuid')})
                                name @include(if: ${this.hasField(fields, 'latest_login.device.name')})
                                operating_system @include(if: ${this.hasField(fields, 'latest_login.device.operating_system')})
                            }
                            session_id
                            recaptcha_score @include(if: ${this.hasField(fields, 'latest_login.recaptcha_score')})
                            recaptcha_reasons @include(if: ${this.hasField(fields, 'latest_login.recaptcha_reasons')})
                            ip_quality_score @include(if: ${this.hasField(fields, 'latest_login.ip_quality_score')})
                        }                
                        finance @include(if: ${this.hasAnyField(fields, this.getFinanceQueryItems())}) {
                            deposit @include(if: ${this.hasAnyField(fields, this.getFinanceDepositQueryItems())}) {
                                total @include(if: ${this.hasAnyField(fields, this.getFinanceDepositTotalQueryItems())}) {
                                    successful_amount @include(if: ${this.hasField(fields, 'finance.deposit.total.successful_amount')})
                                }
                                per_method  @include(if: ${this.hasAnyField(fields, this.getFinanceDepositPerMethodQueryItems())}) {
                                    category @include(if: ${this.hasField(fields, 'finance.deposit.per_method.category')})
                                    group_name @include(if: ${this.hasField(fields, 'finance.deposit.per_method.group_name')})
                                    successful_amount @include(if: ${this.hasField(fields, 'finance.deposit.per_method.successful_amount')})
                                    failed_amount @include(if: ${this.hasField(fields, 'finance.deposit.per_method.failed_amount')})
                                    rejected_amount @include(if: ${this.hasField(fields, 'finance.deposit.per_method.rejected_amount')})
                                    successful_count @include(if: ${this.hasField(fields, 'finance.deposit.per_method.successful_count')})
                                    failed_count @include(if: ${this.hasField(fields, 'finance.deposit.per_method.failed_count')})
                                    rejected_count @include(if: ${this.hasField(fields, 'finance.deposit.per_method.rejected_count')})
                                    currency @include(if: ${this.hasField(fields, 'finance.deposit.per_method.currency')})
                                }
                            }
                            withdrawal @include(if: ${this.hasAnyField(fields, this.getFinanceWithdrawalQueryItems())}) {
                                total @include(if: ${this.hasAnyField(fields, this.getFinanceWithdrawalTotalQueryItems())}) {
                                    successful_amount @include(if: ${this.hasField(fields, 'finance.withdrawal.total.successful_amount')})
                                    successful_count @include(if: ${this.hasField(fields, 'finance.withdrawal.total.successful_count')})
                                    pending_amount @include(if: ${this.hasField(fields, 'finance.withdrawal.total.pending_amount')})
                                }
                                per_method @include(if: ${this.hasAnyField(fields, this.getFinanceWithdrawalPerMethodQueryItems())}) {
                                    category @include(if: ${this.hasField(fields, 'finance.withdrawal.per_method.category')})
                                    group_name @include(if: ${this.hasField(fields, 'finance.withdrawal.per_method.group_name')})
                                    successful_amount @include(if: ${this.hasField(
                                        fields,
                                        'finance.withdrawal.per_method.successful_amount'
                                    )})
                                    failed_amount @include(if: ${this.hasField(fields, 'finance.withdrawal.per_method.failed_amount')})
                                    rejected_amount @include(if: ${this.hasField(fields, 'finance.withdrawal.per_method.rejected_amount')})
                                    successful_count @include(if: ${this.hasField(
                                        fields,
                                        'finance.withdrawal.per_method.successful_count'
                                    )})
                                    failed_count @include(if: ${this.hasField(fields, 'finance.withdrawal.per_method.failed_count')})
                                    rejected_count @include(if: ${this.hasField(fields, 'finance.withdrawal.per_method.rejected_count')})
                                    currency @include(if: ${this.hasField(fields, 'finance.withdrawal.per_method.currency')})
                                }
                            }
                            generated_rake @include(if: ${this.hasField(fields, 'finance.generated_rake')})
                            balance @include(if: ${this.hasField(fields, 'finance.balance')})
                            ggr @include(if: ${this.hasField(fields, 'finance.ggr')})
                            lifetime_bonus @include(if: ${this.hasField(fields, 'finance.lifetime_bonus')})
                            bonus_ratio @include(if: ${this.hasField(fields, 'finance.bonus_ratio')})
                        }
                        security @include(if: ${this.hasField(fields, 'security')}) @client
                        latest_kyc @include(if: ${this.hasAnyField(fields, this.getLatestKycQueryItems())}) {
                            security @include(if: ${this.hasAnyField(fields, this.getLatestKycSecurityQueryItems())}) {
                                id @include(if: ${this.hasField(fields, 'latest_kyc.security.id')})
                                kyc_case_id @include(if: ${this.hasField(fields, 'latest_kyc.security.kyc_case_id')})
                                account_verification_status @include(if: ${this.hasField(
                                    fields,
                                    'latest_kyc.security.account_verification_status'
                                )})
                                documents @include(if: ${this.hasAnyField(fields, this.getLatestKycSecurityDocumentsQueryItems())}) {
                                    first_name @include(if: ${this.hasField(fields, 'latest_kyc.security.documents.first_name')})
                                    last_name @include(if: ${this.hasField(fields, 'latest_kyc.security.documents.last_name')})
                                    gender @include(if: ${this.hasField(fields, 'latest_kyc.security.documents.gender')})
                                    dob @include(if: ${this.hasField(fields, 'latest_kyc.security.documents.dob')})
                                    doc_type @include(if: ${this.hasField(fields, 'latest_kyc.security.documents.doc_type')})
                                    doc_id_sub_type @include(if: ${this.hasField(fields, 'latest_kyc.security.documents.doc_id_sub_type')})
                                    number @include(if: ${this.hasField(fields, 'latest_kyc.security.documents.number')})
                                    device_verified @include(if: ${this.hasField(fields, 'latest_kyc.security.documents.device_verified')})
                                    ip_verified @include(if: ${this.hasField(fields, 'latest_kyc.security.documents.ip_verified')})
                                    geolocation_verified @include(if: ${this.hasField(
                                        fields,
                                        'latest_kyc.security.documents.geolocation_verified'
                                    )})
                                }
                            }
                            payment @include(if: ${this.hasAnyField(fields, this.getLatestKycPaymentQueryItems())}) {
                                id @include(if: ${this.hasField(fields, 'latest_kyc.payment.id')})
                                account_verification_status @include(if: ${this.hasField(
                                    fields,
                                    'latest_kyc.payment.account_verification_status'
                                )})
                                documents @include(if: ${this.hasAnyField(fields, this.getLatestKycPaymentDocumentsQueryItems())}) {
                                    first_name @include(if: ${this.hasField(fields, 'latest_kyc.payment.documents.first_name')})
                                    last_name @include(if: ${this.hasField(fields, 'latest_kyc.payment.documents.last_name')})
                                    gender @include(if: ${this.hasField(fields, 'latest_kyc.payment.documents.gender')})
                                    dob @include(if: ${this.hasField(fields, 'latest_kyc.payment.documents.dob')})
                                    doc_type @include(if: ${this.hasField(fields, 'latest_kyc.payment.documents.doc_type')})
                                    doc_id_sub_type @include(if: ${this.hasField(fields, 'latest_kyc.payment.documents.doc_id_sub_type')})
                                    number @include(if: ${this.hasField(fields, 'latest_kyc.payment.documents.number')})
                                    device_verified @include(if: ${this.hasField(fields, 'latest_kyc.payment.documents.device_verified')})
                                    ip_verified @include(if: ${this.hasField(fields, 'latest_kyc.payment.documents.ip_verified')})
                                    geolocation_verified @include(if: ${this.hasField(
                                        fields,
                                        'latest_kyc.payment.documents.geolocation_verified'
                                    )})
                                }
                            }
                        }
                        aml_labels @include(if: ${this.hasField(fields, 'aml_labels')})
                        punishment_status @include(if: ${this.hasField(fields, 'punishment_status')})
                        withdrawal_status @include(if: ${this.hasField(fields, 'withdrawal_status')})
                        deposit_status @include(if: ${this.hasField(fields, 'deposit_status')})
                        lobby_access_status @include(if: ${this.hasField(fields, 'lobby_access_status')})
                        p2p_transfer_status @include(if: ${this.hasField(fields, 'p2p_transfer_status')})
                        business_pool @include(if: ${this.hasField(fields, 'business_pool')})
                        platform @include(if: ${this.hasField(fields, 'platform')})                        
                        brand @include(if: ${this.hasField(fields, 'brand')}) 
                        license_type @include(if: ${this.hasField(fields, 'license_type')})
                        pool_id @include(if: ${this.hasField(fields, 'pool_id')})
                        countries @include(if: ${this.hasField(fields, 'countries')})
                        vpn @include(if: ${this.hasField(fields, 'vpn')})
                        marketing_info @include(if: ${this.hasAnyField(fields, this.getMarketingInfoQueryItems())}) {
                            register_marketing_code @include(if: ${this.hasField(fields, 'marketing_info.register_marketing_code')})
                        }
                        labels @include(if: ${this.hasAnyField(fields, this.getLabelsQueryItems())}) {
                            id @include(if: ${this.hasField(fields, 'labels.id')})
                            name @include(if: ${this.hasField(fields, 'labels.name')})
                            group @include(if: ${this.hasAnyField(fields, this.getLabelsGroupQueryItems())}) {
                                id @include(if: ${this.hasField(fields, 'labels.group.id')})
                                color @include(if: ${this.hasField(fields, 'labels.group.color')})
                                name @include(if: ${this.hasField(fields, 'labels.group.name')})
                            }
                        }
                        affiliate @include(if: ${this.hasAnyField(fields, this.getAffiliateQueryItems())}) {
                            btag @include(if: ${this.hasField(fields, 'affiliate.btag')})
                        }
                        referral @include(if: ${this.hasAnyField(fields, this.getReferralQueryItems())}) {
                            inviting_user_id @include(if: ${this.hasField(fields, 'referral.inviting_user_id')})
                            inviting_user_username @include(if: ${this.hasField(fields, 'referral.inviting_user_username')})
                            personal_referral_code @include(if: ${this.hasField(fields, 'referral.personal_referral_code')})
                            referral_code @include(if: ${this.hasField(fields, 'referral.referral_code')})
                            referred_at @include(if: ${this.hasField(fields, 'referral.referred_at')}) {seconds} 
                        }
                        agent_info @include(if: ${this.hasAnyField(fields, this.getAgentInfoQueryItems())}) {
                            agent_granted_ts @include(if: ${this.hasField(fields, 'agent_info.agent_granted_ts.seconds')}) {
                                seconds @include(if: ${this.hasField(fields, 'agent_info.agent_granted_ts.seconds')})
                            }
                            bo_agent_id @include(if: ${this.hasField(fields, 'agent_info.bo_agent_id')})
                            default_agent_revenue_share_history @include(if: ${this.hasField(
                                fields,
                                'agent_info.default_agent_revenue_share_history'
                            )})
                        }
                        region_player_info @include(if: ${this.hasAnyField(fields, this.getRegionRelatedInfoQueryItems())}) {
                            occupation @include(if: ${this.hasField(fields, 'region_player_info.occupation')})
                            tax_id @include(if: ${this.hasField(fields, 'region_player_info.tax_id')})
                            national_id @include(if: ${this.hasField(fields, 'region_player_info.national_id')})
                            nationality @include(if: ${this.hasField(fields, 'region_player_info.nationality')})
                            country_of_birth @include(if: ${this.hasField(fields, 'region_player_info.country_of_birth')})
                            district @include(if: ${this.hasField(fields, 'region_player_info.district')})
                        }
                    }
                    total_count
                }

                getPlayerReferralLink(uid: $uid) @include(if: ${this.hasField(fields, 'referral.personal_referral_link')})
            }
        `;
    }

    private userProfileTextFilterFieldsMapper: Record<UserProfileServerTextFilterKeys, string[]> = {
        uid_un_fn_ln_em_ph_rmc: [
            nameof<UserProfile>(w => w.uid),
            nameof<UserProfile>(w => w.username),
            nameof<UserProfile>(w => w.first_name),
            nameof<UserProfile>(w => w.last_name),
            nameof.full<UserProfile>(w => w.contact.email),
            nameof.full<UserProfile>(w => w.contact.mobile.full_number),
            `${nameof.full<UserProfile>(u => u.networks)}.${nameof<UserNetworkProfile>(p => p.uid)}`,
            nameof.full<UserProfile>(w => w.marketing_info.register_marketing_code),
        ],
        uid_un_fn_ln_labels_em_ni_rmc_phone_r: [
            nameof<UserProfile>(w => w.uid),
            nameof<UserProfile>(w => w.username),
            nameof<UserProfile>(w => w.first_name),
            nameof<UserProfile>(w => w.last_name),
            `${nameof.full<UserProfile>(w => w.labels)}.${nameof.full<UserLabel>(l => l.name)}`,
            nameof.full<UserProfile>(w => w.contact.email),
            nameof.full<UserProfile>(w => w.region_player_info.national_id),
            nameof.full<UserProfile>(w => w.marketing_info.register_marketing_code),
            nameof.full<UserProfile>(w => w.contact.mobile.full_number),
            nameof.full<UserProfile>(w => w.affiliate.btag),
            nameof.full<UserProfile>(w => w.referral.inviting_user_id),
        ],
        uid_un_nn_em_sc_nuid: [
            nameof<UserProfile>(w => w.uid),
            nameof<UserProfile>(w => w.username),
            nameof<UserProfile>(w => w.nickname),
            nameof.full<UserProfile>(w => w.contact.email),
            `${nameof.full<UserProfile>(u => u.security_cases)}.${nameof<SecurityCase>(u => u.case_id)}`,
        ],
        uid_un_nn_em_l_sc_nuid_ref: [
            nameof<UserProfile>(w => w.uid),
            nameof<UserProfile>(w => w.username),
            nameof<UserProfile>(w => w.nickname),
            nameof.full<UserProfile>(w => w.contact.email),
            `${nameof<UserProfile>(w => w.labels)}.${nameof<UserLabel>(l => l.name)}`,
            `${nameof.full<UserProfile>(u => u.security_cases)}.${nameof<SecurityCase>(u => u.case_id)}`,
            `${nameof.full<UserProfile>(u => u.networks)}.${nameof<UserNetworkProfile>(p => p.uid)}`,
            nameof.full<UserProfile>(w => w.affiliate.btag),
            nameof.full<UserProfile>(w => w.referral.inviting_user_id),
        ],
        uid_un_rmc_em: [
            nameof<UserProfile>(m => m.uid),
            nameof.full<UserProfile>(m => m.contact.email),
            nameof<UserProfile>(m => m.username),
            nameof.full<UserProfile>(m => m.marketing_info.register_marketing_code),
        ],
        uid: [nameof<UserProfile>(m => m.uid)],
        firstName: [nameof<UserProfile>(m => m.first_name)],
        lastName: [nameof<UserProfile>(m => m.last_name)],
        username: [nameof<UserProfile>(m => m.username)],
        nickname: [nameof<UserProfile>(m => m.nickname)],
        email: [nameof.full<UserProfile>(m => m.contact.email)],
        phone: [nameof.full<UserProfile>(m => m.contact.mobile.full_number)],
        security_cases: [`${nameof.full<UserProfile>(u => u.security_cases)}.${nameof<SecurityCase>(u => u.case_id)}`],
        marketingCode: [nameof.full<UserProfile>(m => m.marketing_info.register_marketing_code)],
        labels: [`${nameof.full<UserProfile>(m => m.labels)}.${nameof.full<UserLabel>(l => l.name)}`],
        networks_PKW: [`${nameof.full<UserProfile>(u => u.networks)}.${nameof<UserNetworkProfile>(p => p.uid)}`],
        networks_BL: [`${nameof.full<UserProfile>(u => u.networks)}.${nameof<UserNetworkProfile>(p => p.uid)}`],
        referrerId: [nameof.full<UserProfile>(w => w.affiliate.btag), nameof.full<UserProfile>(w => w.referral.inviting_user_id)],
        nationalId: [nameof.full<UserProfile>(u => u.region_player_info.national_id)],
        boUserId_uid: [nameof.full<UserProfile>(u => u.agent_info.bo_agent_id), nameof<UserProfile>(u => u.uid)],
        boUserId: [nameof.full<UserProfile>(u => u.agent_info.bo_agent_id)],
        registrationCountry: [nameof.full<UserProfile>(u => u.address.country_info.iso_alpha2_code)],
        loginCountry: [nameof.full<UserProfile>(u => u.countries)],
    };

    private transformTextMapper: Partial<Record<UserProfileServerTextFilterKeys, (value: string) => string>> = {
        email: this.ignoreCase,
        labels: this.phraseSearch,
        registrationCountry: value => this.phraseListSearch(value.replaceAll(',', ' '), ' '),
        loginCountry: value => this.phraseListSearch(value.replaceAll(',', ' '), ' '),
    };
}
