import {GridValueGetterParams} from '@mui/x-data-grid';

import {
    ColumnConfigurationColDef,
    gridCellDefaultFormatter,
    PermissionColDef,
    renderCellHeader,
    renderCellWithTooltip,
    renderUserChipCell,
} from '@components/data-grid/mui';
import {BonusType} from '@models/generated/graphql';
import {formatTimestamp} from '@utils';

import {ModuleName} from 'src/common/routeEnums';
import {Sorting} from 'src/common/types';
import {JurisdictionFeature} from '../../../features/app/config/types';
import {localizedBonusType} from '../../../features/app/intl/shared-resources/bonusType';
import {MarketingCodeColumnsConfiguration, MarketingCodeGridItem} from '../../../features/marketing-code/types';
import {PermissionEnum, SelectOption} from '../../../features/module-shared/types';
import {EditableColDef} from '../../../features/module-temp-storage/components/editable-components/types';
import {ModuleAction} from '../../../features/shared/actions/types';
import FilterMultiSelect from '../../../features/shared/filter/components/FilterMultiSelect';
import FilterTextField from '../../../features/shared/filter/components/FilterText';
import {Filter, FilterPlacement, PageModuleSelectFilter} from '../../../features/shared/filter/types';

import {localizedMarketingBonusCode} from './MarketingBonusCode.localize';
import {renderMarketingBonusCodeCreateAction} from './MarketingBonusCodeCreateAction';
import {renderMarketingBonusCodeDeleteAction} from './MarketingBonusCodeDeleteAction';

export const getGridColumns = () => {
    const columns: (EditableColDef & ColumnConfigurationColDef)[] = [
        {
            field: nameof<MarketingCodeGridItem>(u => u.id),
            hide: true,
        },
        {
            field: nameof<MarketingCodeGridItem>(u => u.marketing_code),
            renderHeader: () => renderCellHeader(localizedMarketingBonusCode.marketingCode),
            valueFormatter: gridCellDefaultFormatter<MarketingCodeGridItem>(i => i.marketing_code),
            configurationColumns: [nameof<MarketingCodeColumnsConfiguration>(u => u.withMarketingCode)],
            sortable: false,
            flex: 0.25,
        },
        {
            field: nameof<MarketingCodeGridItem>(u => u.type),
            renderHeader: () => renderCellHeader(localizedMarketingBonusCode.type),
            valueFormatter: gridCellDefaultFormatter<MarketingCodeGridItem>(i => i.type),
            configurationColumns: [nameof<MarketingCodeColumnsConfiguration>(u => u.withType)],
            flex: 0.25,
            sortable: false,
        } as ColumnConfigurationColDef,
        {
            field: nameof<MarketingCodeGridItem>(u => u.btag),
            renderHeader: () => renderCellHeader(localizedMarketingBonusCode.btag),
            valueFormatter: gridCellDefaultFormatter<MarketingCodeGridItem>(i => i.btag || i.referrer_id),
            valueGetter: (p: GridValueGetterParams) => {
                const model = p.row as MarketingCodeGridItem;
                const res = model.type === BonusType.Team ? model.referrer_id : model.btag;
                return res;
            },
            configurationColumns: [nameof<MarketingCodeColumnsConfiguration>(u => u.withBtag)],
            sortable: false,
            flex: 0.25,
        } as ColumnConfigurationColDef & JurisdictionFeature,
        {
            field: nameof<MarketingCodeGridItem>(u => u.addedByUsername),
            renderHeader: () => renderCellHeader(localizedMarketingBonusCode.user),
            renderCell: renderUserChipCell,
            configurationColumns: [nameof<MarketingCodeColumnsConfiguration>(u => u.withAddedByUid)],
            flex: 0.25,
        } as ColumnConfigurationColDef,
        {
            field: nameof<MarketingCodeGridItem>(u => u.created_at),
            type: 'dateTime',
            renderHeader: () => renderCellHeader(localizedMarketingBonusCode.timestamp),
            valueFormatter: gridCellDefaultFormatter<MarketingCodeGridItem>(i => formatTimestamp(i.created_at, 'date-time')),
            configurationColumns: [nameof<MarketingCodeColumnsConfiguration>(u => u.withCreatedAt)],
            sortField: nameof.full<MarketingCodeGridItem>(u => u.created_at.seconds),
            flex: 0.25,
        } as ColumnConfigurationColDef,
    ];

    const defaultCol: PermissionColDef = {
        field: '',
        width: 200,
        renderCell: renderCellWithTooltip,
        sortable: false,
    } as PermissionColDef;

    return columns.map(c => ({...defaultCol, ...c}));
};

export const getActions = (): ModuleAction[] => [
    {
        component: renderMarketingBonusCodeDeleteAction,
        permissions: [PermissionEnum.Delete],
        moduleName: ModuleName.MarketingFunction,
        placement: FilterPlacement.Secondary,
    },
    {
        component: renderMarketingBonusCodeCreateAction,
        permissions: [PermissionEnum.Create],
        moduleName: ModuleName.MarketingFunction,
        placement: FilterPlacement.Secondary,
    },
];

export const getFilters = (): Filter[] =>
    [
        {
            key: nameof<MarketingCodeGridItem>(u => u.marketing_code),
            name: localizedMarketingBonusCode.marketingCodeFilter,
            label: localizedMarketingBonusCode.marketingCodeFilterPlaceholder,
            component: FilterTextField,
            placement: FilterPlacement.Primary,
            limitMaxWidth: true,
        },
        {
            key: nameof<MarketingCodeGridItem>(u => u.type),
            name: localizedMarketingBonusCode.typeFilter,
            label: localizedMarketingBonusCode.typeFilter,
            component: FilterMultiSelect,
            options: Object.values(BonusType).map(s => ({label: localizedBonusType[s], value: s} as SelectOption)),
            placement: FilterPlacement.Primary,
        } as PageModuleSelectFilter,
    ] as Filter[];

const defaultSorting: Sorting<string>[] = [{field: nameof.full<MarketingCodeGridItem>(u => u.created_at.seconds), sort: 'desc'}];

export default {
    filters: getFilters(),
    columns: getGridColumns(),
    actions: getActions(),
    defaultSorting,
};
