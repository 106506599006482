import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';

import {ServiceTypes} from '@inversify';
import {BackpackItem, BackpackItemsFilter, QueryGetBackpackItemsArgs} from '@models/generated/graphql';
import {BackpackFilterKeys, BackpackQueryFields, BackpackTextFilterKeys, Filter} from '@redux/entity';
import {EntityBaseGqlService, IEntityReadService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';

import {DynamicConfig} from 'src/configuration';
import {JurisdictionConfigService} from 'src/features/app/config/services';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';

@injectable()
export class BackpackService
    extends EntityBaseGqlService<QueryGetBackpackItemsArgs, BackpackQueryFields, BackpackFilterKeys>
    implements IEntityReadService
{
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.JurisdictionConfigService) jurisdictionConfigService: JurisdictionConfigService,
        @inject(ServiceTypes.Config) config: DynamicConfig
    ) {
        super(client, mapper, new BackpackRequestBuilder(jurisdictionConfigService, config));
    }
}

export class BackpackRequestBuilder extends GqlRequestBuilder<QueryGetBackpackItemsArgs, BackpackQueryFields, BackpackFilterKeys> {
    public buildQuery = (fields: BackpackQueryFields[]): DocumentNode => gql`
        query GetBackpackItems($filter: BackpackItemsFilter, $sort: Sorting, $start: Int, $end: Int) {
            getBackpackItems(filter: $filter, sort: $sort, end: $end, start: $start) {
                items {
                    bonus_id
                    uid @include(if: ${this.hasField(fields, 'uid')})
                    marketing_code @include(if: ${this.hasField(fields, 'marketing_code')})
                    bonus_name @include(if: ${this.hasField(fields, 'bonus_name')})
                    bonus_status @include(if: ${this.hasField(fields, 'bonus_status')})
                    created_at @include(if: ${this.hasField(fields, 'created_at.seconds')}) {
                        seconds
                    }               
                    serial_number @include(if: ${this.hasField(fields, 'serial_number')})
                    used_at @include(if: ${this.hasField(fields, 'used_at.seconds')}) {
                        seconds
                    }
                    tool_label @include(if: ${this.hasField(fields, 'tool_label')})
                    value @include(if: ${this.hasField(fields, 'value')})
                }
                total_count
            }
        }
    `;

    protected buildFilter(filter: Filter<BackpackFilterKeys>): {filter: BackpackItemsFilter} {
        return {
            filter: {
                text: this.getGQLTextFilter(
                    Object.keys(this.filterFieldsMapper).map((key: BackpackTextFilterKeys) =>
                        this.toGQLTextFilter(this.filterFieldsMapper[key], filter[key] as string)
                    )
                ),
            },
        };
    }

    private readonly filterFieldsMapper: Record<BackpackTextFilterKeys, string[]> = {
        uid: nameof.toArray<BackpackItem>(m => [m.uid]),
    };
}
