import {defineMessages} from 'react-intl';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {iconButtonSmallSize} from '@components/button/Buttons';
import {renderChip} from '@components/chip/ChipRenderer';
import {
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    MuiDataGridClient,
    MuiDataGridServer,
    renderCellHeader,
    renderCellWithTooltip,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import {PlayerBonusStatus} from '@models/generated/graphql';
import {PlayerBonusViewModel, PlayerBonusViewModelKeys} from '@models/player-bonus';

import {ExpirePlayerBonusProps, renderExpirePlayerBonusCell} from './ExpirePlayerBonus';
import {renderPlayerBonusAmount} from './PlayerBonusAmount';

const localizedHeaders = defineMessages({
    playerBonusGridBonusStatus: {
        id: 'PlayerBonusGridHeader_bonusStatus',
        defaultMessage: 'Bonus Status',
    },
    playerBonusGridBonusCode: {
        id: 'PlayerBonusGridHeader_bonusCode',
        defaultMessage: 'Bonus Code',
    },
    playerBonusGridBonusName: {
        id: 'PlayerBonusGridHeader_bonusName',
        defaultMessage: 'Bonus Name',
    },
    playerBonusGridDescription: {
        id: 'PlayerBonusGridHeader_description',
        defaultMessage: 'Description',
    },
    playerBonusGridAmountAvailable: {
        id: 'PlayerBonusGridHeader_amountAvailable',
        defaultMessage: 'Amount Available',
    },
    playerBonusGridAmountRedeemed: {
        id: 'PlayerBonusGridHeader_amountRedeemed',
        defaultMessage: 'Amount Redeemed',
    },
    playerBonusGridRemainingAmount: {
        id: 'PlayerBonusGridHeader_remainingAmount',
        defaultMessage: 'Remaining Amount',
    },
    playerBonusGridExpiryDate: {
        id: 'PlayerBonusGridHeader_expiryDate',
        defaultMessage: 'Expiry date',
    },
    playerBonusGridEmptyList: {
        id: 'PlayerBonusGridHeader_emptyList',
        defaultMessage: 'Bonus codes list is empty',
    },
    playerBonusGridTitle: {
        id: 'PlayerBonusGridHeader_title',
        defaultMessage: 'Cash Bonuses',
    },
});

export type PlayerBonusColumn =
    | Extract<
          PlayerBonusViewModelKeys,
          | 'created_at.seconds'
          | 'bonus_status'
          | 'marketing_code'
          | 'bonus_name'
          | 'desc'
          | 'realized_amount'
          | 'total_amount'
          | 'remaining_amount'
          | 'expire_at.seconds'
      >
    | 'expireButton';

export class DataGridPlayerBonusConfigFactory extends DataGridEntityConfigFactory<PlayerBonusColumn, PlayerBonusViewModel> {
    getColumnConfig(): Record<PlayerBonusColumn, DataGridEntityColDef<PlayerBonusColumn>> {
        const columnsMapping: Record<PlayerBonusColumn, DataGridEntityColDef<PlayerBonusColumn>> = {
            'created_at.seconds': {
                field: 'created_at.seconds',
                valueGetter: p => this.getCreatedAt(p),
                sortable: true,
                hide: true,
            },
            bonus_status: {
                field: 'bonus_status',
                valueGetter: p => this.getBonusStatus(p),
                renderHeader: () => renderCellHeader(localizedHeaders.playerBonusGridBonusStatus),
                renderCell: params => renderChip<PlayerBonusStatus>(params.value as PlayerBonusStatus, nameof<PlayerBonusStatus>()),
                sortable: false,
                flex: 1,
            },
            marketing_code: {
                field: 'marketing_code',
                valueGetter: p => this.getMarketingCode(p),
                renderHeader: () => renderCellHeader(localizedHeaders.playerBonusGridBonusCode),
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 1,
            },
            bonus_name: {
                field: 'bonus_name',
                valueGetter: p => this.getBonusName(p),
                renderHeader: () => renderCellHeader(localizedHeaders.playerBonusGridBonusName),
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 1,
            },
            desc: {
                field: 'desc',
                valueGetter: p => this.getDesc(p),
                renderHeader: () => renderCellHeader(localizedHeaders.playerBonusGridDescription),
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 1,
            },
            realized_amount: {
                field: 'realized_amount',
                valueGetter: p => this.getRealizedAmount(p),
                renderHeader: () => renderCellHeader(localizedHeaders.playerBonusGridAmountRedeemed),
                renderCell: renderPlayerBonusAmount,
                sortable: false,
                flex: 1,
            },
            total_amount: {
                field: 'total_amount',
                valueGetter: p => this.getTotalAmount(p),
                renderHeader: () => renderCellHeader(localizedHeaders.playerBonusGridAmountAvailable),
                renderCell: renderPlayerBonusAmount,
                sortable: false,
                flex: 1,
            },
            remaining_amount: {
                field: 'remaining_amount',
                valueGetter: p => this.getRemainingAmount(p),
                renderHeader: () => renderCellHeader(localizedHeaders.playerBonusGridRemainingAmount),
                renderCell: renderPlayerBonusAmount,
                sortable: false,
                flex: 1,
            },
            'expire_at.seconds': {
                field: 'expire_at.seconds',
                valueGetter: p => this.getExpireAt(p),
                renderHeader: () => renderCellHeader(localizedHeaders.playerBonusGridExpiryDate),
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 1,
            },
            expireButton: {
                field: 'expireButton',
                valueGetter: p => this.getForceExpireValue(p),
                renderHeader: () => null,
                renderCell: renderExpirePlayerBonusCell,
                sortable: false,
                width: iconButtonSmallSize + 32,
            },
        };

        return columnsMapping;
    }

    private getCreatedAt(p: GridValueGetterParams) {
        return this.getRow(p)?.created_at;
    }

    private getBonusStatus(p: GridValueGetterParams) {
        return this.getRow(p)?.bonus_status;
    }

    private getMarketingCode(p: GridValueGetterParams) {
        return this.getRow(p)?.marketing_code;
    }

    private getBonusName(p: GridValueGetterParams) {
        return this.getRow(p)?.bonus_name;
    }

    private getDesc(p: GridValueGetterParams) {
        return this.getRow(p)?.desc;
    }

    private getRealizedAmount(p: GridValueGetterParams) {
        return this.getRow(p)?.realized_amount;
    }

    private getTotalAmount(p: GridValueGetterParams) {
        return this.getRow(p)?.total_amount;
    }

    private getRemainingAmount(p: GridValueGetterParams) {
        return this.getRow(p)?.remaining_amount;
    }

    private getExpireAt(p: GridValueGetterParams) {
        return this.getRow(p)?.expire_at;
    }

    private getForceExpireValue(p: GridValueGetterParams): ExpirePlayerBonusProps {
        const row = this.getRow(p);
        return {
            bonusId: row?.bonus_id,
            bonusStatus: row?.bonus_status,
            releaseMechanism: row?.release_mechanism,
            creditType: row?.credit_type,
        };
    }
}

export const DataGridPlayerBonusClient = withDataGridEntityClient<PlayerBonusColumn, PlayerBonusViewModel>(MuiDataGridClient, () =>
    new DataGridPlayerBonusConfigFactory().getColumnConfig()
);

export const DataGridPlayerBonusServer = withDataGridEntityServer<PlayerBonusColumn, PlayerBonusViewModel>(MuiDataGridServer, () =>
    new DataGridPlayerBonusConfigFactory().getColumnConfig()
);
