import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import {labelCellLocalized} from '@components/data-grid/mui';
import {UserProfileServerFilterKeys} from '@redux/entity';
import {sharedLocalization} from '@localization';

import {JurisdictionSelectOption} from 'src/features/module-shared/types';
import FilterTextWithDropdown from 'src/features/shared/filter/components/FilterTextWithDropdown';
import {FilterPlacement, MultipleKeysFilter} from 'src/features/shared/filter/types';
import {FilterTextInput} from '../../shared/filter/components/FilterTextInput';

import {UserProfileFilterProps, UserProfileFilterType} from './UserProfileFiltersObsolete';

const localized = defineMessages({
    textFullFilter: {
        id: 'UserProfileFilterText_textFullFilter',
        defaultMessage: 'All',
    },
    userIdFilter: {
        id: 'UserProfileFilterText_userIdFilter',
        defaultMessage: 'Player ID',
    },
    usernameFilter: {
        id: 'UserProfileFilterText_usernameFilter',
        defaultMessage: 'Username',
    },
    referrerFilter: {
        id: 'UserProfileFilterText_referrerFilter',
        defaultMessage: 'Referrer',
    },
    labelFilterPlaceholder: {
        id: 'UserProfileFilterText_labelFilterPlaceholder',
        defaultMessage: '123',
    },
    nationalIdFilter: {
        id: 'UserProfileFilterText_nationalIdFilter',
        defaultMessage: 'National ID',
    },
    textFullFilterPlaceholder: {
        id: 'UserProfileFilter_textFullFilterPlaceholder',
        defaultMessage: 'Search',
    },
    textFullFilterPlaceholderCS: {
        id: 'UserProfileFilterText_textFullFilterPlaceholderCS',
        defaultMessage: 'Search in Customer Support Workspace',
    },
    textFullFilterPlaceholderSecurity: {
        id: 'UserProfileFilterText_textFullFilterPlaceholderSecurity',
        defaultMessage: 'Search in Security Workspace',
    },
    userIdFilterPlaceholder: {
        id: 'UserProfileFilterText_userIdFilterPlaceholder',
        defaultMessage: '123',
    },
    usernameFilterPlaceholder: {
        id: 'UserProfileFilterText_usernameFilterPlaceholder',
        defaultMessage: 'For example, Antonio',
    },
    firstNameFilterPlaceholder: {
        id: 'UserProfileFilterText_firstNameFilterPlaceholder',
        defaultMessage: 'First Name',
    },
    lastNameFilterPlaceholder: {
        id: 'UserProfileFilterText_lastNameFilterPlaceholder',
        defaultMessage: 'Last Name',
    },
    emailFilterPlaceholder: {
        id: 'UserProfileFilterText_emailFilterPlaceholder',
        defaultMessage: 'For example, antonio@xyz.com',
    },
    nationalIdFilterPlaceholder: {
        id: 'UserProfileFilterText_nationalIdFilterPlaceholder',
        defaultMessage: '123',
    },
    registerMarketingCodeFilter: {
        id: 'UserProfileFilterText_registerMarketingCodeFilter',
        defaultMessage: 'Registration bonus',
    },
    registerMarketingCodeFilterPlaceholder: {
        id: 'UserProfileFilterText_registerMarketingCodeFilterPlaceholder',
        defaultMessage: 'REG_00001',
    },
    phoneNumberFilter: {
        id: 'UserProfileFilterText_phoneNumberFilter',
        defaultMessage: 'Phone number',
    },
    phoneNumberFilterPlaceholder: {
        id: 'UserProfileFilterText_phoneNumberFilterFilterPlaceholder',
        defaultMessage: '+1234567890',
    },
    securityCaseIdFilter: {
        id: 'UserProfileFilterText_securityCaseIdFilter',
        defaultMessage: 'Security Case ID',
    },
    cashGamesIdFilter: {
        id: 'UserProfileFilterText_cashGamesIdFilter',
        defaultMessage: 'Cash Game ID',
    },
    tournamentsIdFilter: {
        id: 'UserProfileFilterText_tournamentsIdFilter',
        defaultMessage: 'Tournaments ID',
    },
    nicknameFilter: {
        id: 'UserProfileFilterText_nicknameFilter',
        defaultMessage: 'Nickname',
    },
    nicknameFilterPlaceholder: {
        id: 'UserProfileFilterText_nicknameFilterPlaceholder',
        defaultMessage: 'For example, Antonio',
    },
    securityCaseIdFilterPlaceholder: {
        id: 'UserProfileFilterText_securityCaseIdFilterPlaceholder',
        defaultMessage: 'For example, BOT_09072021_1005',
    },
});

function withUserProfileFilterText(WrappedComponent: typeof FilterTextWithDropdown, filterKeys: UserProfileServerFilterKeys[]) {
    return function UserProfileFilterText({filter, onFilterChange, domain}: UserProfileFilterProps) {
        const textSearchOptions: JurisdictionSelectOption[] = [
            {label: localized.textFullFilter, value: 'uid_un_rmc_em'},
            {label: localized.textFullFilter, value: 'uid_un_fn_ln_labels_em_ni_rmc_phone_r'},
            {label: localized.textFullFilter, value: 'uid_un_nn_em_l_sc_nuid_ref'},
            {label: localized.userIdFilter, value: 'uid'},
            {label: localized.usernameFilter, value: 'username'},
            {label: sharedLocalization.firstName, value: 'firstName'},
            {label: sharedLocalization.lastName, value: 'lastName'},
            {label: localized.nicknameFilter, value: 'nickname'},
            {
                label: labelCellLocalized.labels,
                value: 'labels',
            },
            {
                label: sharedLocalization.emailFilter,
                value: 'email',
            },
            {
                label: localized.nationalIdFilter,
                value: 'nationalId',
            },
            {
                label: localized.registerMarketingCodeFilter,
                value: 'marketingCode',
            },
            {
                label: localized.phoneNumberFilter,
                value: 'phone',
            },
            {label: localized.referrerFilter, value: 'referrerId'},
            {
                label: localized.securityCaseIdFilter,
                value: 'security_cases',
            },
            {
                label: localized.cashGamesIdFilter,
                value: 'networks_PKW',
            },
            {
                label: localized.tournamentsIdFilter,
                value: 'networks_BL',
            },
        ].filter(o => filterKeys.includes(o.value as UserProfileServerFilterKeys));

        const getFilterLabel = (key: string): MessageDescriptor => {
            return {
                ['uid_un_rmc_em']: localized.textFullFilterPlaceholder,
                ['uid_un_fn_ln_labels_em_ni_rmc_phone_r']: localized.textFullFilterPlaceholderCS,
                ['uid_un_nn_em_l_sc_nuid_ref']: localized.textFullFilterPlaceholderSecurity,
                ['uid']: localized.userIdFilterPlaceholder,
                ['username']: localized.usernameFilterPlaceholder,
                ['firstName']: localized.firstNameFilterPlaceholder,
                ['lastName']: localized.lastNameFilterPlaceholder,
                ['labels']: localized.labelFilterPlaceholder,
                ['email']: localized.emailFilterPlaceholder,
                ['nationalId']: localized.nationalIdFilterPlaceholder,
                ['marketingCode']: localized.registerMarketingCodeFilterPlaceholder,
                ['phone']: localized.phoneNumberFilterPlaceholder,
                ['referrerId']: localized.userIdFilterPlaceholder,
                ['nickname']: localized.nicknameFilterPlaceholder,
                ['security_cases']: localized.securityCaseIdFilterPlaceholder,
                ['networks_PKW']: localized.userIdFilterPlaceholder,
                ['networks_BL']: localized.userIdFilterPlaceholder,
            }[key];
        };

        return (
            <WrappedComponent
                onFilterChange={onFilterChange}
                domain={domain}
                filter={
                    {
                        keys: filterKeys,
                        component: FilterTextWithDropdown,
                        options: textSearchOptions,
                        placement: FilterPlacement.Primary,
                        getLabel: getFilterLabel,
                    } as MultipleKeysFilter
                }
                filterString={filter}
            />
        );
    };
}

export const UidEmailUsernameMarketingCodeFilterText = withUserProfileFilterText(FilterTextWithDropdown, [
    'uid_un_rmc_em',
    'uid',
    'email',
    'username',
    'marketingCode',
]);

export function UserProfileFilterText({filter, onFilterChange, filterKey, ...props}: UserProfileFilterProps) {
    const labelMapper: Partial<Record<UserProfileFilterType, MessageDescriptor>> = {
        uid: localized.userIdFilter,
        username: localized.usernameFilter,
        firstName: sharedLocalization.firstName,
        lastName: sharedLocalization.lastName,
        labels: labelCellLocalized.labels,
        email: sharedLocalization.emailFilter,
        nationalId: localized.nationalIdFilter,
        marketingCode: localized.registerMarketingCodeFilter,
        phone: localized.phoneNumberFilter,
        referrerId: localized.referrerFilter,
        nickname: localized.nicknameFilter,
        security_cases: localized.securityCaseIdFilter,
        networks_PKW: localized.cashGamesIdFilter,
        networks_BL: localized.tournamentsIdFilter,
    };

    return (
        <FilterTextInput
            filter={{
                key: filterKey,
                label: labelMapper[filterKey],
            }}
            filterString={filter}
            onFilterChange={onFilterChange}
            {...props}
        />
    );
}
