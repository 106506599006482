// TODO: [BO-2942] Remove columns dependency after adding UserProfileViewModel
import {FeatureAccessStatus, UserAccountStatus} from '@models/generated/graphql';

import {SecurityCaseViewModel} from 'src/features/block-security-case/types';

export type UserProfileViewModelKeys =
    | 'uid'
    | 'full_name'
    | 'nickname'
    | 'uid_link_security'
    | 'uid_link_cs'
    | 'uid_link_agent'
    | 'account_status'
    | 'withdrawal_status'
    | 'deposit_status'
    | 'security_cases'
    | 'platform'
    | 'platform_cs'
    | 'latest_kyc.payment.account_verification_status'
    | 'latest_kyc.payment.account_verification_status_cs'
    | 'phone'
    | 'referrer_player_id'
    | 'referrer_username'
    | 'email'
    | 'username'
    | 'first_name'
    | 'last_name'
    | 'phone.area'
    | 'phone.number'
    | 'birthday.seconds'
    | 'gender'
    | 'date_of_joining.seconds'
    | 'is_2fa_enabled'
    | 'is_challenge_questions_enabled'
    | 'p2p_transfer_status'
    | 'finance.balance'
    | 'referred_date'
    | 'country'
    | 'lobby_access_status'
    | 'security_cases.type'
    | 'security_cases.case_id'
    | 'security_cases.opened_at'
    | 'security_cases.closed_at'
    | 'latest_kyc.security.id'
    | 'latest_kyc.security.account_verification_status'
    | 'latest_kyc.payment.id'
    | 'deposit_total'
    | 'withdrawal_approved_amount'
    | 'withdrawal_approved_count'
    | 'withdrawal_pending_amount'
    | 'balance'
    | 'ggr'
    | 'lifetime_bonus'
    | 'bonus_ratio'
    | 'agent_info.bo_agent_id'
    | 'agent_info.default_agent_revenue_share_history'
    | 'referrer'
    | 'register_marketing_code'
    | 'labels'
    | 'latest_login.logged_at_ts.seconds'
    | 'national_id'
    | 'networks_BL'
    | 'networks_PKW'
    | 'networks.uid'
    | 'networks.platform'
    | 'aml_labels'
    | 'casino_status'
    | 'sportsbook_status'
    | 'referral.personal_referral_link'
    | 'login_countries'
    | 'vpn'
    | 'brand'
    | 'license_type';

export class AgentPokerShareWeeklyBandViewModel {
    lower_bound: number;
    upper_bound: number;
    revenue_share: number;
}

export class AgentPokerShareViewModel {
    weekly_bands: AgentPokerShareWeeklyBandViewModel[];
}

export class AgentRevenueShareViewModel {
    active_since: string;
    casino_share: number;
    net_deposit_share: number;
    player_winloss: number;
    gross_deposit: number;
    poker_share: AgentPokerShareViewModel;
}

export class AgentInfoViewModel {
    bo_agent_id: string;
    revenue_share_history: AgentRevenueShareViewModel[];
}

enum Brand {
    Unknown = 'UNKNOWN',
    Wptg = 'WPTG',
    Wptgo = 'WPTGO',
}

export class UserProfileViewModel {
    uid: string;
    username?: string;
    security_cases?: SecurityCaseViewModel[];
    agent_info?: AgentInfoViewModel;
    account_status?: UserAccountStatus;
    deposit_status?: FeatureAccessStatus;
    casino_status?: FeatureAccessStatus;
    withdrawal_status?: FeatureAccessStatus;
    lobby_access_status?: FeatureAccessStatus;
    p2p_transfer_status?: FeatureAccessStatus;
    sportsbook_status?: FeatureAccessStatus;
    brand?: Brand;
    license_type?: string;
}

export class UserProfileDownloadModel implements Record<UserProfileViewModelKeys, string> {
    uid: string;
    full_name: string;
    nickname: string;
    uid_link_security: string;
    uid_link_cs: string;
    uid_link_agent: string;
    account_status: string;
    withdrawal_status: string;
    deposit_status: string;
    security_cases: string;
    platform: string;
    platform_cs: string;
    sportsbook_status: string;
    'latest_kyc.payment.account_verification_status': string;
    'latest_kyc.payment.account_verification_status_cs': string;
    phone: string;
    referrer_player_id: string;
    referrer_username: string;
    email: string;
    username: string;
    first_name: string;
    last_name: string;
    'phone.area': string;
    'phone.number': string;
    'birthday.seconds': string;
    'latest_login.logged_at_ts.seconds': string;
    gender: string;
    'date_of_joining.seconds': string;
    is_2fa_enabled: string;
    is_challenge_questions_enabled: string;
    p2p_transfer_status: string;
    'finance.balance': string;
    referred_date: string;
    country: string;
    lobby_access_status: string;
    'security_cases.type': string;
    'security_cases.case_id': string;
    'security_cases.opened_at': string;
    'security_cases.closed_at': string;
    'latest_kyc.security.id': string;
    'latest_kyc.security.account_verification_status': string;
    deposit_total: string;
    withdrawal_approved_amount: string;
    withdrawal_approved_count: string;
    withdrawal_pending_amount: string;
    balance: string;
    ggr: string;
    lifetime_bonus: string;
    bonus_ratio: string;
    'agent_info.bo_agent_id': string;
    'agent_info.default_agent_revenue_share_history': string;
    referrer: string;
    register_marketing_code: string;
    labels: string;
    national_id: string;
    networks_BL: string;
    networks_PKW: string;
    casino_status: string;
    'referral.personal_referral_link': string;
    ndrp_status: string;
    aml_labels: string;
    login_countries: string;
    vpn: string;
    'networks.uid': string;
    'networks.platform': string;
    'latest_kyc.payment.id': string;
    brand: string;
    license_type: string;
}
