import React, {useEffect} from 'react';
import {Controller} from 'react-hook-form';
import {defineMessages, useIntl} from 'react-intl';
import {Box, TextField} from '@mui/material';

import {ToggleSwitch} from '@components/toggle';
import {IAsyncAction} from '@redux';

import {useReduxForm} from '../../shared/form/hooks';

const localized = defineMessages({
    locked: {
        id: 'UserProfileLock_locked',
        defaultMessage: 'Locked',
    },
    unlocked: {
        id: 'UserProfileLock_unlocked',
        defaultMessage: 'Unlocked',
    },
});

class UserProfileLockModel {
    uid: string;
    value: boolean;
}

type UserProfileLockProps = {
    uid: string;
    isLocked: boolean;
    label: string;
    disabled?: boolean;
    action: IAsyncAction;
    dataTestId?: string;
};

export function UserProfileLock({isLocked, uid, label, action, disabled, dataTestId}: UserProfileLockProps) {
    const {formatMessage} = useIntl();

    const {state, control, handleSubmit, submit, watch} = useReduxForm<UserProfileLockModel, UserProfileLockModel>({
        initialModel: {
            uid,
            value: isLocked,
        },
        asyncAction: action,
        map: m => m,
    });
    const value = watch('value');
    useEffect(() => {
        if (isLocked !== value) {
            handleSubmit(submit)();
        }
    }, [value]);

    return (
        <form data-testid={dataTestId}>
            <Box display="none">
                <Controller control={control} name="uid" render={_ => <TextField hidden />} defaultValue={uid} />
            </Box>
            <Controller
                control={control}
                render={({field}) => (
                    <ToggleSwitch
                        value={field.value}
                        onChange={field.onChange}
                        fieldName={label}
                        label={formatMessage(field.value ? localized.locked : localized.unlocked)}
                        disabled={disabled || state.isProgress}
                        withBorder
                    />
                )}
                name="value"
            />
        </form>
    );
}
