import {MessageDescriptor} from 'react-intl';
import {ActionType, createAction} from 'typesafe-actions';

import {EntityType} from '@redux/entity';

import {Filter, Paging, Sorting} from 'src/common/types';

import {domain, ViewEntityData, ViewRealtimeData, ViewType} from './types';

export type ViewInitActionPayload = {
    view: ViewType;
    displayName: MessageDescriptor;
    entity: ViewEntityData<string>;
    realtime?: ViewRealtimeData;
    defaultFilters?: Filter<any, string>[];
    defaultSorting?: Sorting<string>;
    defaultPaging?: Paging;
    syncWithUrl?: boolean;
    blockFetchWithInvalidFilter?: boolean;
};

export type ViewInitFilterActionPayload = {
    view: ViewType;
    entity: EntityType;
    defaultFilters?: Filter<any, string>[];
    defaultSorting?: Sorting<string>;
    defaultPaging?: Paging;
    syncWithUrl?: boolean;
    blockFetchWithInvalidFilter?: boolean;
};

export type ViewSaveActionPayload = {
    view: ViewType;
    displayName: MessageDescriptor;
    entity: ViewEntityData<string>;
    realtime?: ViewRealtimeData;
};

export type ViewCloseActionPayload = {
    view: ViewType;
    entity: EntityType;
    delay: number;
};

export type ViewSaveFilterActionPayload = {
    view: ViewType;
    entity: EntityType;
    filter: string;
};

export type ViewUpdateKeysActionPayload = {
    entity: EntityType;
    keys: string[];
    filter: string;
    total: number;
};

export type ViewUpdateFilterActionPayload = {
    view: ViewType;
    entity: EntityType;
    filter: string;
    syncWithUrl?: boolean;
    blockFetchWithInvalidFilter?: boolean;
};

export type ViewUpdateFilterKeysActionPayload = {
    view: ViewType;
    entity: EntityType;
    filters?: Filter<any, string>[];
    sorting?: Sorting<string>;
    paging?: Paging;
    syncWithUrl?: boolean;
};

export type ViewCleanActionPayload = {
    view: ViewType;
};

export type ViewUpdateActionPayload = {
    views: ViewType[];
    entity: EntityType;
    blockFetchWithInvalidFilter?: boolean;
};

export type ViewMarkUsedActionPayload = {
    view: ViewType;
};

export type ViewMarkUnusedActionPayload = {
    view: ViewType;
};

export const viewActions = {
    init: createAction(`${domain}/init`)<ViewInitActionPayload>(),
    initFilter: createAction(`${domain}/initFilter`)<ViewInitFilterActionPayload>(),
    close: createAction(`${domain}/close`)<ViewCloseActionPayload>(),
    save: createAction(`${domain}/save`)<ViewSaveActionPayload>(),
    saveFilter: createAction(`${domain}/saveFilter`)<ViewSaveFilterActionPayload>(),
    saveKeys: createAction(`${domain}/saveKeys`)<ViewUpdateKeysActionPayload>(),
    updateFilterKeys: createAction(`${domain}/updateFilterKeys`)<ViewUpdateFilterKeysActionPayload>(),
    updateFilter: createAction(`${domain}/updateFilter`)<ViewUpdateFilterActionPayload>(),
    updateKeys: createAction(`${domain}/updateKeys`)<ViewUpdateKeysActionPayload>(),
    clean: createAction(`${domain}/clean`)<ViewCleanActionPayload>(),
    update: createAction(`${domain}/update`)<ViewUpdateActionPayload>(),
    collectGarbage: createAction(`${domain}/collectGarbage`)(),
    markUsed: createAction(`${domain}/markUsed`)<ViewMarkUsedActionPayload>(),
    markUnused: createAction(`${domain}/markUnused`)<ViewMarkUnusedActionPayload>(),
};

export type ViewActions = ActionType<typeof viewActions>;
