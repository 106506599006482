import React, {FC, useContext} from 'react';
import {IntlContext, MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';
import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

const useClasses = makeStyles()((theme: CustomTheme) => {
    return {
        wrappedComponentLabel: {
            ...(theme.typography.subtitle2 as CSSObject),
            color: theme.palette.text.secondary,
            marginBottom: theme.spacing(1),
            display: 'flex',
        },
    };
});

type LabelHoc = {
    label: MessageDescriptor;
};

export const withLabel =
    <TProps extends LabelHoc>(WrappedComponent: FC<TProps>) =>
    (props: TProps) => {
        const {classes} = useClasses();
        const {formatMessage} = useContext(IntlContext);
        const {label} = props;

        return (
            <Box>
                <Box component="span" className={classes.wrappedComponentLabel}>
                    {formatMessage(label)}
                </Box>
                <WrappedComponent {...props} />
            </Box>
        );
    };
