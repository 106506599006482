import React from 'react';
import {defineMessages} from 'react-intl';

import {CustomIcon} from '@components/icons/types';
import {MultiSelectDropdownButton} from '@components/select/MultiSelectDropdownButton';
import {AccountVerificationStatus, KycInitReasonCode} from '@models/generated/graphql';
import {agentReadPolicies} from '@models/permissions/permissions';

import {localizedKYCInitReasonCodes} from '../../app/intl/shared-resources/kycInitReasonCode';
import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {SelectOption} from '../../module-shared/types';
import {actionDisabledStatuses, usePaymentKycAction} from '../hooks';
import {initiatePaymentKycPermissions} from '../permissions';

const localized = defineMessages({
    initiatePaymentLabel: {
        id: 'WD_initiatePaymentLabel',
        defaultMessage: 'Initiate Payment KYC',
    },
    initiatePaymentOptionsLabel: {
        id: 'WD_initiatePaymentOptionsLabel',
        defaultMessage: 'KYC REASON',
    },
    tooltipTitle: {
        id: 'InitiatePaymentKyc_tooltipTitle',
        defaultMessage: 'Selected KYC Reason',
    },
});

type InitiatePaymentKycProps = {
    disabled?: boolean;
    uid: string;
    reasons: KycInitReasonCode[];
    status: AccountVerificationStatus;
};

function InitiatePaymentKycInner({disabled, uid, reasons = [], status}: InitiatePaymentKycProps) {
    const reasonsValue: KycInitReasonCode[] = actionDisabledStatuses.includes(status) ? reasons : [];
    const {initiateKyc, isActionDisabled} = usePaymentKycAction();

    function handleApplyClick(reasons: KycInitReasonCode[]) {
        initiateKyc(uid, reasons);
    }

    return (
        <MultiSelectDropdownButton
            label={localized.initiatePaymentLabel}
            iconClassName={CustomIcon.AddSquareOutline}
            optionsHeader={localized.initiatePaymentOptionsLabel}
            options={Object.values(KycInitReasonCode).map<SelectOption>((v: KycInitReasonCode) => ({
                label: localizedKYCInitReasonCodes[v],
                value: v,
            }))}
            onApply={handleApplyClick}
            value={reasonsValue}
            disabled={disabled || isActionDisabled(status)}
            tooltipTitle={localized.tooltipTitle}
            dataTestId="initiate-payment-kyc-action"
        />
    );
}

export const InitiatePaymentKyc = withMultiplePermission(
    withMultiplePermission(InitiatePaymentKycInner, {
        allowedPermissions: initiatePaymentKycPermissions,
        indicator: LackOfPermissionIndicator.Disabled,
    }),
    {restrictedPermissions: agentReadPolicies}
);
