import {defineMessages} from 'react-intl';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, filter} from 'rxjs/operators';
import {isActionOf, PayloadAction} from 'typesafe-actions';
import {v4 as uuid} from 'uuid';

import {fileActions} from '@file/actions';
import {ServiceTypes} from '@inversify';
import {
    ManualTransaction,
    ManualTransactionInput,
    MutationAddManualTransactionArgs,
    MutationClaimRevenueShareArgs,
    RevenueShareClaimResult,
    Transaction as GqlTransaction,
} from '@models/generated/graphql';
import {TransactionDownloadModel} from '@models/transaction';
import {map, mergeMap} from '@otel';
import {BaseEpicsBuilder, RootEpic} from '@redux';
import {entityActions, EntityFetchRequestPayload, EntityType, PlayerReferrerSummaryNormalized, TransactionNormalized} from '@redux/entity';
import {ITransactionService} from '@services/transactionService';
import {
    CustomErrorCodes,
    GqlMutationRequest,
    maxPageSize,
    RestRequest,
    ServerResponseStatus,
    ServiceResponsePayload,
} from '@services/types';

import {localizedTransactionStatus} from '../app/intl/shared-resources/transactionStatus';
import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

import {TransactionCsvFileFactory} from './services/TransactionCsvFileFactory';
import {
    AddManualTransactionRequestPayload,
    ClaimRevenueShareRequestPayload,
    OpenCashierPayload,
    SetWithdrawalStatusRequestPayload,
    transactionActions,
} from './actions';
import {ManualTransactionAddViewModel} from './types';

export const transactionActionsEpicsLocalized = defineMessages({
    setWithdrawalStatusSuccess: {
        id: 'TransactionActionsEpicsBuilder_setWithdrawalStatusSuccess',
        defaultMessage: 'Withdrawal status changed to {status}',
    },
    setWithdrawalStatusFailed: {
        id: 'TransactionActionsEpicsBuilder_setWithdrawalStatusFailed',
        defaultMessage: 'Failed to change withdrawal status',
    },
    openCashierFailed: {
        id: 'TransactionActionsEpicsBuilder_openCashierFailed',
        defaultMessage: 'Failed to open cashier',
    },
    totalCountErrorMessage: {
        id: 'TransactionActionsEpicsBuilder_totalCountErrorMessage',
        defaultMessage: 'Total count of downloaded items cannot be more than {maxPageSize}',
    },
    downloadFailure: {
        id: 'TransactionActionsEpicsBuilder_failureMessage',
        defaultMessage: 'Failed to download Agent Report',
    },
    redeemRevenueShareSuccess: {
        id: 'TransactionActionsEpicsBuilder_redeemRevenueShareSuccess',
        defaultMessage: 'Redeem successfully completed',
    },
    redeemRevenueShareFailed: {
        id: 'TransactionActionsEpicsBuilder_redeemRevenueShareFailed',
        defaultMessage: 'Failed to redeem',
    },
    addManualTransactionSuccess: {
        id: 'TransactionActionsEpicsBuilder_addManualTransactionSuccess',
        defaultMessage: 'Manual transaction successfully created',
    },
    addManualTransactionFailed: {
        id: 'TransactionActionsEpicsBuilder_addManualTransactionFailed',
        defaultMessage: 'Failed to create manual transaction',
    },
    addManualTransactionFailedWithTraceId: {
        id: 'TransactionActionsEpicsBuilder_addManualTransactionFailedWithTraceId',
        defaultMessage: 'Failed to create manual transaction{br}traceId: {traceId}',
    },
    addManualTransactionFailedNotEnoughFunds: {
        id: 'TransactionActionsEpicsBuilder_addManualTransactionFailedNotEnoughFunds',
        defaultMessage: "Player balance don't have enough funds",
    },
});

@injectable()
export class TransactionActionsEpicsBuilder extends BaseEpicsBuilder {
    private readonly _service: ITransactionService;
    private readonly _fileFactory: TransactionCsvFileFactory;
    private readonly _mapper: Mapper;
    private _manualTransactionId: string;

    constructor(
        @inject(ServiceTypes.TransactionService) service: ITransactionService,
        @inject(ServiceTypes.TransactionCsvFileFactory) fileFactory: TransactionCsvFileFactory,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super();
        this._service = service;
        this._fileFactory = fileFactory;
        this._mapper = mapper;
    }

    protected buildEpicList(): RootEpic[] {
        return [
            this.buildSetWithdrawalRiskStatusRequestEpic(),
            this.buildSetWithdrawalPaymentStatusRequestEpic(),
            this.buildSetWithdrawalStatusSuccessEpic(),
            this.buildSetWithdrawalStatusFailureEpic(),
            this.buildOpenCashierRequestEpic(),
            this.buildOpenCashierSuccessEpic(),
            this.buildOpenCashierFailureEpic(),
            this.buildTransactionDownloadEpic(),
            this.buildTransactionDownloadSuccessEpic(),
            this.buildTransactionDownloadFailureEpic(),
            this.buildRedeemRevenueShareRequestEpic(),
            this.buildRedeemRevenueShareSuccessEpic(),
            this.buildRedeemRevenueShareFailureEpic(),
            this.buildAddManualTransactionRequestEpic(),
            this.buildAddManualTransactionSuccessEpic(),
            this.buildAddManualTransactionFailureEpic(),
        ];
    }

    private buildSetWithdrawalRiskStatusRequestEpic(): RootEpic {
        return this.buildRequestEpic<SetWithdrawalStatusRequestPayload, RestRequest, unknown>(
            transactionActions.setWithdrawalRiskStatus,
            payload => this._service.setWithdrawalRiskStatus(payload)
        );
    }

    private buildSetWithdrawalPaymentStatusRequestEpic(): RootEpic {
        return this.buildRequestEpic<SetWithdrawalStatusRequestPayload, RestRequest, unknown>(
            transactionActions.setWithdrawalPaymentStatus,
            payload => this._service.setWithdrawalPaymentStatus(payload)
        );
    }

    private buildSetWithdrawalStatusSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(
                    isActionOf([transactionActions.setWithdrawalRiskStatus.success, transactionActions.setWithdrawalPaymentStatus.success])
                ),
                mergeMap(res => {
                    const requestBody = res?.payload?.requestPayload?.body as SetWithdrawalStatusRequestPayload;
                    const updatedItem: Partial<TransactionNormalized> = {transaction_status: requestBody.status};

                    return of(
                        showMessageAction({
                            message: transactionActionsEpicsLocalized.setWithdrawalStatusSuccess,
                            values: requestBody?.status ? {status: localizedTransactionStatus[requestBody.status].defaultMessage} : null,
                        }),
                        entityActions.updateItem({type: EntityType.Transaction, id: requestBody.transactionId, updatedItem})
                    );
                })
            );
    }

    private buildSetWithdrawalStatusFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(
                    isActionOf([transactionActions.setWithdrawalRiskStatus.failure, transactionActions.setWithdrawalPaymentStatus.failure])
                ),
                map(() => showErrorAction({message: transactionActionsEpicsLocalized.setWithdrawalStatusFailed}))
            );
    }

    private buildOpenCashierRequestEpic(): RootEpic {
        return this.buildRequestEpic<OpenCashierPayload, RestRequest, string>(transactionActions.openCashier, payload =>
            this._service.getCashierLink(payload.transactionId)
        );
    }

    private buildOpenCashierSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(transactionActions.openCashier.success)),
                mergeMap(res => {
                    window.open(res.payload.responsePayload, '_blank');
                    return of();
                })
            );
    }

    private buildOpenCashierFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(transactionActions.openCashier.failure)),
                map(() => showErrorAction({message: transactionActionsEpicsLocalized.openCashierFailed}))
            );
    }

    private buildTransactionDownloadEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(transactionActions.transactionDownloadCsv.request)),
                mergeMap(action => {
                    const {filter, fields, filename, worksheetKeys} = action.payload;
                    const fetchPayload: EntityFetchRequestPayload = {
                        type: EntityType.Transaction,
                        filter,
                        fields,
                    };
                    const failureObservable = of(
                        transactionActions.transactionDownloadCsv.failure({
                            message: transactionActionsEpicsLocalized.downloadFailure,
                        })
                    );

                    return this._service.get(fetchPayload).pipe(
                        mergeMap(response => {
                            let result: Observable<unknown>;
                            if (response?.status === ServerResponseStatus.Success) {
                                if (response?.responsePayload?.total <= maxPageSize) {
                                    const transactions: GqlTransaction[] = response?.responsePayload?.items as GqlTransaction[];
                                    const worksheetData = transactions?.map(i =>
                                        this._mapper.map(i, GqlTransaction, TransactionDownloadModel)
                                    );

                                    result = this._fileFactory.createFile(worksheetData, worksheetKeys).pipe(
                                        map(arrayBuffer => transactionActions.transactionDownloadCsv.success({arrayBuffer, filename})),
                                        catchError(() => failureObservable)
                                    );
                                } else {
                                    result = of(
                                        transactionActions.transactionDownloadCsv.failure({
                                            message: transactionActionsEpicsLocalized.totalCountErrorMessage,
                                            values: {maxPageSize: `${maxPageSize}`},
                                        })
                                    );
                                }
                            } else {
                                result = failureObservable;
                            }

                            return result;
                        }),
                        catchError(() => failureObservable)
                    );
                })
            );
    }

    private buildTransactionDownloadSuccessEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(transactionActions.transactionDownloadCsv.success)),
                map(action => {
                    const {filename, arrayBuffer} = action.payload;
                    return fileActions.download({
                        file: new Blob([arrayBuffer]),
                        title: filename,
                        type: 'csv',
                    });
                })
            );
    }

    private buildTransactionDownloadFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(transactionActions.transactionDownloadCsv.failure)),
                map(action => showErrorAction(action.payload))
            );
    }

    private buildRedeemRevenueShareRequestEpic(): Epic {
        return this.buildRequestEpic<
            ClaimRevenueShareRequestPayload,
            GqlMutationRequest<MutationClaimRevenueShareArgs>,
            RevenueShareClaimResult
        >(transactionActions.claimRevenueShare, ({amount, uid}) => this._service.claimRevenueShare({uid, claim: {amount}}));
    }

    private buildRedeemRevenueShareSuccessEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(transactionActions.claimRevenueShare.success)),
                mergeMap(
                    (
                        res: PayloadAction<
                            string,
                            ServiceResponsePayload<GqlMutationRequest<MutationClaimRevenueShareArgs>, RevenueShareClaimResult>
                        >
                    ) => {
                        const {
                            variables: {uid},
                        } = res.payload.requestPayload;
                        const updatedItem: Partial<PlayerReferrerSummaryNormalized> = {redeem_pending_total: 0};
                        return of(
                            showMessageAction({message: transactionActionsEpicsLocalized.redeemRevenueShareSuccess}),
                            entityActions.updateItem({type: EntityType.PlayerReferrerSummary, id: uid, updatedItem})
                        );
                    }
                )
            );
    }

    private buildRedeemRevenueShareFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(transactionActions.claimRevenueShare.failure)),
                map(() => showErrorAction({message: transactionActionsEpicsLocalized.redeemRevenueShareFailed}))
            );
    }

    private buildAddManualTransactionRequestEpic(): Epic {
        return this.buildRequestEpic<
            AddManualTransactionRequestPayload,
            GqlMutationRequest<MutationAddManualTransactionArgs>,
            ManualTransaction
        >(transactionActions.addManualTransaction, payload => {
            const transactionInput = this._mapper.map(payload, ManualTransactionAddViewModel, ManualTransactionInput);
            if (!this._manualTransactionId) {
                this._manualTransactionId = uuid();
            }
            transactionInput.id = this._manualTransactionId;
            return this._service.addManualTransaction(transactionInput);
        });
    }

    private buildAddManualTransactionSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(transactionActions.addManualTransaction.success)),
                map(() => {
                    this._manualTransactionId = null;
                    return showMessageAction({message: transactionActionsEpicsLocalized.addManualTransactionSuccess});
                })
            );
    }

    private buildAddManualTransactionFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(transactionActions.addManualTransaction.failure)),
                map(
                    (
                        res: PayloadAction<
                            string,
                            ServiceResponsePayload<GqlMutationRequest<MutationAddManualTransactionArgs>, ManualTransaction>
                        >
                    ) => {
                        const error = this.getManualTransactionError(res.payload);
                        let errorActionPayload;
                        if (error?.traceId) {
                            errorActionPayload = {
                                message: transactionActionsEpicsLocalized.addManualTransactionFailedWithTraceId,
                                values: {traceId: error.traceId},
                            };
                        } else if (error?.code === CustomErrorCodes.NotEnoughFunds) {
                            errorActionPayload = {message: transactionActionsEpicsLocalized.addManualTransactionFailedNotEnoughFunds};
                        } else {
                            errorActionPayload = {message: transactionActionsEpicsLocalized.addManualTransactionFailed};
                        }
                        return showErrorAction(errorActionPayload);
                    }
                )
            );
    }

    private getManualTransactionError(
        response: ServiceResponsePayload<GqlMutationRequest<MutationAddManualTransactionArgs>, ManualTransaction>
    ) {
        const errorMessage = response.errors?.[0]?.message;
        const errorMessagePayload = errorMessage
            .replace("API status code 500 return error - b'", '')
            .replace("}'", '}')
            .replace("\\'", "'");

        let resultError: ManualTransactionError;
        try {
            resultError = JSON.parse(errorMessagePayload);
        } catch {
            resultError = null;
        }

        return resultError;
    }
}

type ManualTransactionError = {
    code?: number;
    traceId: string;
};
