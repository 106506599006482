import React from 'react';
import {defineMessages} from 'react-intl';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    MuiDataGridClient,
    MuiDataGridServer,
    renderCellHeader,
    renderCellWithTooltip,
    renderStakesCell,
    StakesCellProps,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import LocalizedText from '@components/i18n/LocalizedText';
import {GameRoomViewModel, GameRoomViewModelKeys} from '@models/game-room';
import {GameFeaturesViewModel} from '@models/game-room/types';
import {gameTableDeletePermission} from '@models/permissions/permissions';
import {ManagedGameType} from '@redux/entity';

import {localizedManagedGameType} from '../../app/intl/shared-resources/managedGameType';
import {GamePlatformsCellProps, renderGamePlatformCell} from '../../block-game-management';
import {CloseRoomModalButtonCellProps, renderCloseRoomModalButtonCell} from '../../block-game-room-actions';

import {PlayersCellProps, renderPlayersCell} from './PlayersCell';

const localizedHeaders = defineMessages({
    gameType: {
        id: 'DataGridGameRoom_gameType',
        defaultMessage: 'Game',
    },
    roomId: {
        id: 'DataGridGameRoom_roomId',
        defaultMessage: 'Table ID',
    },
    players: {
        id: 'DataGridGameRoom_players',
        defaultMessage: 'Players',
    },
    templateId: {
        id: 'DataGridGameRoom_templateId',
        defaultMessage: 'Template',
    },
    platforms: {
        id: 'DataGridGameRoom_platforms',
        defaultMessage: 'Labels',
    },
    stakes: {
        id: 'DataGridGameRoom_stakes',
        defaultMessage: 'Stakes',
    },
    features: {
        id: 'DataGridGameRoom_features',
        defaultMessage: 'Features',
    },
});

class DataGridGameRoomConfigFactory extends DataGridEntityConfigFactory<GameRoomViewModelKeys, GameRoomViewModel> {
    getColumnConfig(): Partial<Record<GameRoomViewModelKeys, DataGridEntityColDef<GameRoomViewModelKeys>>> {
        const columnsMapping: Partial<Record<GameRoomViewModelKeys, DataGridEntityColDef<GameRoomViewModelKeys>>> = {
            gameType: {
                field: 'gameType',
                valueGetter: p => this.getGameTypeLocalized(p),
                renderHeader: () => renderCellHeader(localizedHeaders.gameType),
                renderCell: renderCellWithTooltip,
                flex: 1,
                sortable: false,
            },
            roomId: {
                field: 'roomId',
                valueGetter: p => this.getRoomId(p),
                renderHeader: () => renderCellHeader(localizedHeaders.roomId),
                renderCell: renderCellWithTooltip,
                flex: 1,
                sortable: false,
            },
            players: {
                field: 'players',
                valueGetter: p => this.getPlayers(p),
                renderHeader: () => renderCellHeader(localizedHeaders.players),
                renderCell: renderPlayersCell,
                flex: 1,
                sortable: false,
            },
            templateId: {
                field: 'templateId',
                valueGetter: p => this.getTemplateId(p),
                renderHeader: () => renderCellHeader(localizedHeaders.templateId),
                renderCell: renderCellWithTooltip,
                flex: 1,
                sortable: false,
            },
            platforms: {
                field: 'platforms',
                valueGetter: p => this.getPlatform(p),
                renderHeader: () => renderCellHeader(localizedHeaders.platforms),
                renderCell: renderGamePlatformCell,
                flex: 0.7,
                sortable: false,
            },
            stakes: {
                field: 'stakes',
                valueGetter: p => this.getStakes(p),
                renderHeader: () => renderCellHeader(localizedHeaders.stakes),
                renderCell: renderStakesCell,
                flex: 1.2,
                sortable: false,
            },
            close: {
                field: 'close',
                valueGetter: p => this.getCloseActionData(p),
                headerName: ' ',
                renderCell: renderCloseRoomModalButtonCell,
                flex: 1,
                sortable: false,
                ...gameTableDeletePermission,
            },
            features: {
                field: 'features',
                valueGetter: p => this.getGameFeaturesString(p),
                renderHeader: () => renderCellHeader(localizedHeaders.features),
                renderCell: renderCellWithTooltip,
                flex: 1,
                sortable: false,
            },
        };
        return columnsMapping;
    }

    private getGameTypeLocalized(p: GridValueGetterParams) {
        return <LocalizedText label={localizedManagedGameType[this.getGameType(p)]} />;
    }

    private getGameType(p: GridValueGetterParams): ManagedGameType {
        return this.getRow(p)?.gameType;
    }

    private getRoomId(p: GridValueGetterParams): string {
        return this.getRow(p)?.roomId?.toString();
    }

    private getTemplateId(p: GridValueGetterParams): string {
        return this.getRow(p)?.templateId?.toString();
    }

    private getStakes(p: GridValueGetterParams): StakesCellProps {
        return this.getRow(p)?.stakes;
    }

    private getPlayers(p: GridValueGetterParams): PlayersCellProps {
        return {...this.getRow(p)?.players, roomId: this.getRoomId(p), roomName: this.getRow(p)?.roomName, gameType: this.getGameType(p)};
    }

    private getPlatform(p: GridValueGetterParams): GamePlatformsCellProps {
        const platforms = this.getRow(p)?.platforms;
        return {visiblePlatform: platforms[0], hiddenPlatforms: platforms?.slice(1)};
    }

    private getCloseActionData(p: GridValueGetterParams): CloseRoomModalButtonCellProps {
        return {roomId: this.getRoomId(p), ownerId: this.getRow(p)?.ownerId.toString(), gameType: this.getGameType(p)};
    }

    private getGameFeaturesString(p: GridValueGetterParams): string {
        const features: GameFeaturesViewModel = this.getRow(p)?.features;
        return features
            ? Object.keys(features)
                  ?.filter((k: keyof GameFeaturesViewModel) => features[k])
                  ?.map(f => f.toUpperCase())
                  ?.join(', ')
            : null;
    }
}

export const DataGridGameRoomClient = withDataGridEntityClient<GameRoomViewModelKeys, GameRoomViewModel>(MuiDataGridClient, () =>
    new DataGridGameRoomConfigFactory().getColumnConfig()
);

export const DataGridGameRoomServer = withDataGridEntityServer<GameRoomViewModelKeys, GameRoomViewModel>(MuiDataGridServer, () =>
    new DataGridGameRoomConfigFactory().getColumnConfig()
);
