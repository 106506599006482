import React from 'react';
import {defineMessages} from 'react-intl';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {renderChip} from '@components/chip/ChipRenderer';
import {IModuleGridItem} from '@components/data-grid';
import {
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    MuiDataGridClient,
    MuiDataGridServer,
    renderCellHeader,
    renderCellWithTooltip,
    renderDefaultChipCell,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import {PlayerReferral, RevenueShareOfferingStatus} from '@models/generated/graphql';

import {PlayerReferralGridItem} from '../types';

import {UserReferralExpirationDate} from './UserReferralExpirationDate';
import {UserReferralRevenueShare} from './UserReferralRevenueShare';

const localizedHeaders = defineMessages({
    referee: {
        id: 'DataGridPlayerReferral_referee',
        defaultMessage: 'Referred Players',
    },
    referrerId: {
        id: 'DataGridPlayerReferral_referrerId',
        defaultMessage: 'Referrer Of Player',
    },
    revenueShare: {
        id: 'DataGridPlayerReferral_revenueShare',
        defaultMessage: 'Revenue Share',
    },
    revenueGeneratedTotal: {
        id: 'DataGridPlayerReferral_revenueGeneratedTotal',
        defaultMessage: 'Total Accumulated Revenue Share',
    },
    revenueShareOfferingStatus: {
        id: 'DataGridPlayerReferral_revenueShareOfferingStatus',
        defaultMessage: 'Revenue Share Offering Status',
    },
    expirationDate: {
        id: 'DataGridPlayerReferral_expirationDate',
        defaultMessage: 'Expiration Date',
    },
});

export const dataGridPlayerReferralColumnKeys = [
    'referrer_id',
    'referee.uid',
    'revenue_share',
    'revenue_generated_total',
    'revenue_share_offering_status',
    'expiration_date',
] as const;

export type DataGridPlayerReferralColumns = (typeof dataGridPlayerReferralColumnKeys)[number];

export class DataGridPlayerReferralConfigFactory extends DataGridEntityConfigFactory<
    DataGridPlayerReferralColumns,
    PlayerReferralGridItem
> {
    getColumnConfig(): Record<DataGridPlayerReferralColumns, DataGridEntityColDef<DataGridPlayerReferralColumns>> {
        const columnsMapping: Record<DataGridPlayerReferralColumns, DataGridEntityColDef<DataGridPlayerReferralColumns>> = {
            referrer_id: {
                field: 'referrer_id',
                valueGetter: p => this.getReferrerId(p),
                renderHeader: () => renderCellHeader(localizedHeaders.referrerId),
                renderCell: renderDefaultChipCell,
                sortable: false,
                flex: 0.12,
            },
            'referee.uid': {
                field: 'referee.uid',
                valueGetter: p => this.getRefereeUid(p),
                renderHeader: () => renderCellHeader(localizedHeaders.referee),
                renderCell: renderDefaultChipCell,
                sortable: false,
                flex: 0.1,
            },
            revenue_share: {
                field: 'revenue_share',
                valueGetter: p => this.getRevenueShare(p),
                renderHeader: () => renderCellHeader(localizedHeaders.revenueShare),
                renderCell: params => {
                    const row = params.row as PlayerReferralGridItem;
                    const initialRevenueShare = row.revenue_share ? row.revenue_share * 100 : 0;
                    return <UserReferralRevenueShare referee_uid={row.revenue_share_uid} revenue_share={initialRevenueShare} />;
                },
                sortable: false,
                flex: 0.2,
            },
            revenue_generated_total: {
                field: 'revenue_generated_total',
                valueGetter: p => this.getRevenueGeneratedTotal(p),
                renderHeader: () => renderCellHeader(localizedHeaders.revenueGeneratedTotal),
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.15,
            },
            revenue_share_offering_status: {
                field: 'revenue_share_offering_status',
                valueGetter: p => this.getRevenueShareOfferingStatus(p),
                renderHeader: () => renderCellHeader(localizedHeaders.revenueShareOfferingStatus),
                renderCell: params =>
                    renderChip<RevenueShareOfferingStatus>(
                        params.value as RevenueShareOfferingStatus,
                        nameof<RevenueShareOfferingStatus>()
                    ),
                sortable: false,
                flex: 0.15,
            },
            expiration_date: {
                field: 'expiration_date',
                valueGetter: p => this.getExpirationDateValue(p),
                renderHeader: () => renderCellHeader(localizedHeaders.expirationDate),
                renderCell: params => {
                    const value = params.row as PlayerReferralGridItem;
                    return <UserReferralExpirationDate referee_uid={value.revenue_share_uid} expiration_date={value.expiration_date} />;
                },
                sortable: false,
                flex: 0.25,
            },
        };

        return columnsMapping;
    }

    private getReferrerId(p: GridValueGetterParams) {
        return this.getRow(p)?.referrer_id;
    }

    private getRefereeUid(p: GridValueGetterParams) {
        return this.getRow(p)?.referee?.uid;
    }

    private getRevenueShare(p: GridValueGetterParams) {
        return this.getRow(p)?.revenue_share;
    }

    private getRevenueGeneratedTotal(p: GridValueGetterParams) {
        return this.getRow(p)?.revenue_generated_total;
    }

    private getRevenueShareOfferingStatus(p: GridValueGetterParams) {
        return this.getRow(p)?.revenue_share_offering_status;
    }

    private getExpirationDateValue(p: GridValueGetterParams) {
        return this.getRow(p)?.expiration_date;
    }
}

export const DataGridPlayerReferralsClient = withDataGridEntityClient<DataGridPlayerReferralColumns, PlayerReferral & IModuleGridItem>(
    MuiDataGridClient,
    () => new DataGridPlayerReferralConfigFactory().getColumnConfig()
);

export const DataGridPlayerReferralsServer = withDataGridEntityServer<DataGridPlayerReferralColumns, PlayerReferral & IModuleGridItem>(
    MuiDataGridServer,
    () => new DataGridPlayerReferralConfigFactory().getColumnConfig()
);
