import React from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {useService} from '@inversify';

import {ModuleName, SubmoduleName} from '../../../common/routeEnums';
import {withPermissionAndJurisdictionConfig} from '../../app/permission/PermissionHoc';
import {
    BulkActionKey,
    bulkActionsActions,
    BulkChooseActionStepLockItem,
    BulkLockItemValue,
    itemsSelector,
    withdrawalLockInitialValueSelector,
    withdrawalLockResultValueSelector,
} from '../../block-bulk-actions';
import {PermissionEnum} from '../../module-shared/types';
import {ApplyWithdrawalLockRequest, ApplyWithdrawalLockStrategy} from '../services/applyStrategy';

const localized = defineMessages({
    withdrawalLock: {
        id: 'BulkActionWithdrawalLock_withdrawalLock',
        defaultMessage: 'Withdrawal Lock',
    },
});

function ActionWithdrawalLock() {
    const dispatch = useDispatch();
    const initialValues: boolean[] = useSelector(withdrawalLockInitialValueSelector);
    const resultValue: boolean = useSelector(withdrawalLockResultValueSelector);
    const players = useSelector(itemsSelector);
    const strategy = useService<ApplyWithdrawalLockStrategy>('ApplyWithdrawalLockStrategy');

    function handleSubmit(toggleValue: BulkLockItemValue): void {
        if (toggleValue !== BulkLockItemValue.NoChanges) {
            dispatch(
                bulkActionsActions.apply({
                    request: {players, value: toggleValue === BulkLockItemValue.Checked} as ApplyWithdrawalLockRequest,
                    strategy,
                })
            );
        } else {
            dispatch(bulkActionsActions.removeBulkActionsWithKey(BulkActionKey.WithdrawalLock));
        }
    }

    return (
        <BulkChooseActionStepLockItem
            label={localized.withdrawalLock}
            initialValues={initialValues}
            resultValue={resultValue}
            onSubmit={handleSubmit}
        />
    );
}

export const BulkUserProfileWithdrawalLock = withPermissionAndJurisdictionConfig(ActionWithdrawalLock, {
    moduleName: ModuleName.ActionCenter,
    submoduleName: SubmoduleName.FinanceActions,
    permissions: [PermissionEnum.Update],
});
