import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';

import {OutlinedButton} from '@components/button/Buttons';
import {CustomIcon, Icon} from '@components/icons';
import {ModalContext} from '@components/modal';
import {agentReadPolicies} from '@models/permissions/permissions';

import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {manualTransactionAddPolicies} from '../permissions';

import {ManualTransactionAddModal} from './ManualTransactionAddModal';

const localized = defineMessages({
    title: {
        id: 'ManualTransactionAddButton_title',
        defaultMessage: 'Manual Debit/Credit',
    },
});

type ManualTransactionAddButtonProps = {
    uid: string;
    disabled?: boolean;
};

function ManualTransactionAddButtonInner({disabled, uid}: ManualTransactionAddButtonProps) {
    const {openModal} = useContext(ModalContext);
    function handleClick() {
        openModal({
            body: <ManualTransactionAddModal uid={uid} />,
            styleParams: {maxWidth: 'sm'},
        });
    }
    return (
        <OutlinedButton
            fullWidth={true}
            label={localized.title}
            startIcon={<Icon icon={CustomIcon.AddSquareOutline} />}
            onClick={handleClick}
            disabled={disabled}
            data-testid="manual-transaction-action"
        />
    );
}

export const ManualTransactionAddButton = withMultiplePermission(
    withMultiplePermission(ManualTransactionAddButtonInner, {
        allowedPermissions: manualTransactionAddPolicies,
        indicator: LackOfPermissionIndicator.Disabled,
    }),
    {restrictedPermissions: agentReadPolicies}
);
