import {MessageDescriptor} from 'react-intl';

import {PlayerReferral, Sorting} from '@models/generated/graphql';
import {EntityType, PlayerReferralFilterKeys, PlayerReferralQueryFields} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {useViewInit, ViewType} from '@redux/view';
import {isStringNullOrEmpty} from '@utils';

import {SearchFilter} from '../../common/types';
import {PlayerReferralGridItem} from '../block-player-referral-list';

type UseRefereeRevenueInfoResult = {
    items: PlayerReferralGridItem[];
    totalCount: number;
    searchFilter: SearchFilter;
    handlePageChange: (newPage: number) => void;
    handlePageSizeChange: (newPageSize: number) => void;
    handleSortChange: (newSorting: Sorting[]) => void;
};

export function usePlayerReferralData(
    view: ViewType,
    title: MessageDescriptor,
    uid: string,
    filterKey: PlayerReferralFilterKeys,
    realtimesMode: RealtimeUpdatesMode,
    getRevenueShareUid: (referral: PlayerReferral) => string
): UseRefereeRevenueInfoResult {
    const fields: PlayerReferralQueryFields[] = [
        'referrer_id',
        'invite_code',
        'referee.uid',
        'revenue_share',
        'revenue_generated_total',
        'revenue_share_offering_status',
        'expiration_date.seconds',
    ];
    const {items, totalCount, searchFilter, handlePageChange, handlePageSizeChange, handleSortChange} = useViewInit<
        PlayerReferral,
        PlayerReferralFilterKeys,
        PlayerReferralQueryFields
    >({
        viewType: view,
        displayName: title,
        entity: {
            entity: EntityType.PlayerReferral,
            fields,
        },
        defaultFilters: [{key: filterKey, value: uid}],
        realtime: {
            entity: EntityType.PlayerReferral,
            mode: realtimesMode,
            triggers: [RealtimeMessageTrigger.Add, RealtimeMessageTrigger.Update, RealtimeMessageTrigger.Delete],
        },
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === filterKey)?.value),
    });

    //TODO: [BO-2915] Refactor id column logic for data grids
    const refereeItems = items?.map(i => ({
        ...i,
        revenue_share_uid: getRevenueShareUid(i),
    })) as PlayerReferralGridItem[];

    return {items: refereeItems, totalCount, searchFilter, handlePageChange, handlePageSizeChange, handleSortChange};
}

export function useRefereeRevenueInfoData(view: ViewType, title: MessageDescriptor, uid: string): UseRefereeRevenueInfoResult {
    return usePlayerReferralData(view, title, uid, 'referrer_id', RealtimeUpdatesMode.Confirm, i => i?.referee?.uid);
}
