import React from 'react';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    MuiDataGridClient,
    MuiDataGridServer,
    renderCellHeader,
    renderCellWithTooltip,
    renderDateCell,
    renderStakesCell,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import LocalizedText from '@components/i18n/LocalizedText';
import {GameTemplateViewModel, GameTemplateViewModelKeys} from '@models/game-template';
import {gameTemplateUpdatePermission} from '@models/permissions/permissions';
import {Timestamp} from '@models/shared';
import {ManagedGameType} from '@redux/entity';

import {localizedManagedGameType} from 'src/features/app/intl/shared-resources/managedGameType';
import {renderGamePlatformCell} from 'src/features/block-game-management/components/GamePlatformsCell';
import {
    renderTemplateEnableToggleCell,
    TemplateEnableToggleCellProps,
} from '../../block-game-template-actions/components/TemplateEnableToggleCell';

import {localizedHeaders} from './DataGridGameTemplate.localize';
import {renderGameTemplateModifyCell} from './GameTemplateModifyCell';
import {renderGameTemplateTableCell} from './GameTemplateTableCell';

class DataGridGameTemplateConfigFactory extends DataGridEntityConfigFactory<GameTemplateViewModelKeys, GameTemplateViewModel> {
    getColumnConfig(): Partial<Record<GameTemplateViewModelKeys, DataGridEntityColDef<GameTemplateViewModelKeys>>> {
        const columnsMapping: Partial<Record<GameTemplateViewModelKeys, DataGridEntityColDef<GameTemplateViewModelKeys>>> = {
            gameType: {
                field: 'gameType',
                valueGetter: p => this.getLocalizedGameType(p),
                renderHeader: () => renderCellHeader(localizedHeaders.gameType),
                renderCell: renderCellWithTooltip,
                flex: 0.1,
                sortable: false,
            },
            id: {
                field: 'id',
                valueGetter: p => this.getId(p),
                renderHeader: () => renderCellHeader(localizedHeaders.id),
                renderCell: renderCellWithTooltip,
                flex: 0.11,
                sortable: false,
            },
            name: {
                field: 'name',
                valueGetter: p => this.getName(p),
                renderHeader: () => renderCellHeader(localizedHeaders.name),
                renderCell: renderCellWithTooltip,
                flex: 0.2,
                sortable: false,
            },
            is_enabled: {
                field: 'is_enabled',
                valueGetter: p => this.getEnableState(p),
                renderHeader: () => renderCellHeader(localizedHeaders.live),
                renderCell: renderTemplateEnableToggleCell,
                flex: 0.15,
                sortable: false,
            },
            labels: {
                field: 'labels',
                valueGetter: p => this.getLabels(p),
                renderHeader: () => renderCellHeader(localizedHeaders.labels),
                renderCell: renderGamePlatformCell,
                flex: 0.15,
                sortable: false,
            },
            stakes: {
                field: 'stakes',
                valueGetter: p => this.getStakes(p),
                renderHeader: () => renderCellHeader(localizedHeaders.stakes),
                renderCell: renderStakesCell,
                flex: 0.25,
                sortable: false,
            },
            players: {
                field: 'players',
                valueGetter: p => this.getPlayers(p),
                renderHeader: () => renderCellHeader(localizedHeaders.players),
                renderCell: renderCellWithTooltip,
                flex: 0.08,
                sortable: false,
            },
            tables: {
                field: 'tables',
                valueGetter: p => this.getTables(p),
                renderHeader: () => renderCellHeader(localizedHeaders.tables),
                renderCell: renderGameTemplateTableCell,
                flex: 0.2,
                sortable: false,
            },
            modify: {
                field: 'modify',
                valueGetter: p => this.getModifyData(p),
                renderHeader: () => renderCellHeader(localizedHeaders.modify),
                renderCell: renderGameTemplateModifyCell,
                flex: 0.15,
                sortable: false,
                ...gameTemplateUpdatePermission,
            },
            updated_at: {
                field: 'updated_at',
                valueGetter: p => this.getLastModified(p),
                renderHeader: () => renderCellHeader(localizedHeaders.date),
                renderCell: renderDateCell,
                flex: 0.2,
                sortable: false,
            },
        };
        return columnsMapping;
    }

    private getLocalizedGameType(p: GridValueGetterParams) {
        return <LocalizedText label={localizedManagedGameType[this.getGameType(p)]} />;
    }

    private getGameType(p: GridValueGetterParams): ManagedGameType {
        return this.getRow(p)?.gameType;
    }

    private getId(p: GridValueGetterParams) {
        return this.getRow(p)?.id;
    }

    private getName(p: GridValueGetterParams) {
        return this.getRow(p)?.name;
    }

    private getEnableState(p: GridValueGetterParams): TemplateEnableToggleCellProps {
        const templateId = Number(this.getId(p));
        return {
            value: this.getRow(p)?.is_enabled,
            templateId: isNaN(templateId) ? null : templateId,
            gameType: this.getGameType(p),
        };
    }

    private getLabels(p: GridValueGetterParams) {
        const labels = this.getRow(p)?.labels;
        return {visiblePlatform: labels?.[0], hiddenPlatforms: labels?.slice(1)};
    }

    private getStakes(p: GridValueGetterParams) {
        return this.getRow(p)?.stakes;
    }

    private getPlayers(p: GridValueGetterParams) {
        return this.getRow(p)?.players;
    }

    private getTables(p: GridValueGetterParams) {
        return this.getRow(p)?.tables;
    }

    private getLastModified(p: GridValueGetterParams) {
        const timestamp: Timestamp = {seconds: this.getRow(p)?.updated_at};
        return timestamp;
    }

    private getModifyData(p: GridValueGetterParams) {
        const gameType = this.getRow(p)?.gameType;
        return {gameType, id: this.getId(p)};
    }
}

export const DataGridGameTemplateClient = withDataGridEntityClient<GameTemplateViewModelKeys, GameTemplateViewModel>(
    MuiDataGridClient,
    () => new DataGridGameTemplateConfigFactory().getColumnConfig()
);

export const DataGridGameTemplateServer = withDataGridEntityServer<GameTemplateViewModelKeys, GameTemplateViewModel>(
    MuiDataGridServer,
    () => new DataGridGameTemplateConfigFactory().getColumnConfig()
);
