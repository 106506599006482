import React from 'react';
import {defineMessages} from 'react-intl';
import {Redirect, RouteComponentProps, withRouter} from 'react-router-dom';
import type {Location} from 'history';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {LayoutQueueTabs} from '@components/layout';
import {RoutingTabs, Tab} from '@components/tab/RoutingTabs';
import {withdrawalProcessingPermissions} from '@models/permissions/permissions';
import {Policy} from '@auth';
import {BaseFilterKeys, SortOrder, TransactionQueryFields} from '@redux/entity';

import {ModuleName, RouteUrl, SubmoduleName} from 'src/common/routeEnums';
import ModuleHeader from '../../../features/module-shared/components/ModuleHeader';
import {withModule} from '../../../features/module-shared/components/ModuleHoc';

import WithdrawalHistoryCases from './WithdrawalHistoryCases';
import WithdrawalPendingCases from './WithdrawalPendingCases';

const localized = defineMessages({
    title: {
        id: 'WithdrawalProcessing_title',
        defaultMessage: 'Withdrawal Processing',
    },
    pendingCases: {
        id: 'WithdrawalProcessing_pendingCases',
        defaultMessage: 'Pending Cases',
    },
    historyCases: {
        id: 'WithdrawalProcessing_historyCases',
        defaultMessage: 'History Cases',
    },
});

type WithdrawalProcessingProps = RouteComponentProps;

function replaceSortField(search: string): string {
    const sortFieldKey: BaseFilterKeys = 'sortField';
    const outdatedSortFieldStartedAt: TransactionQueryFields = 'transaction_started_ts.seconds';
    const outdatedSortFieldUpdatedAt: TransactionQueryFields = 'transaction_updated_ts.seconds';
    const sortFieldStartedAt: TransactionQueryFields = 'transaction_started_ts';
    const sortFieldUpdatedAt: TransactionQueryFields = 'transaction_updated_ts';

    const queryParams = new URLSearchParams(search);
    const sortField = queryParams.get(sortFieldKey);
    if (sortField?.includes(outdatedSortFieldStartedAt)) {
        queryParams.set(sortFieldKey, sortFieldStartedAt);
    } else if (sortField?.includes(outdatedSortFieldUpdatedAt)) {
        queryParams.set(sortFieldKey, sortFieldUpdatedAt);
    }

    return queryParams.toString();
}

function replaceSortOrder(search: string): string {
    const sortOrderKey: BaseFilterKeys = 'sortOrder';
    const sortOrderDesc: SortOrder = SortOrder.Desc;
    const sortOrderAsc: SortOrder = SortOrder.Asc;

    const queryParams = new URLSearchParams(search);
    const sortOrder = queryParams.get(sortOrderKey);
    if (sortOrder?.includes(sortOrderDesc) && sortOrder !== sortOrderDesc) {
        queryParams.set(sortOrderKey, sortOrderDesc);
    } else if (sortOrder?.includes(sortOrderAsc) && sortOrder !== sortOrderAsc) {
        queryParams.set(sortOrderKey, sortOrderAsc);
    }

    return queryParams.toString();
}

export function getRedirectUrl(location: Pick<Location, 'search' | 'pathname'>): string {
    const initialSearch = location?.search;

    let resultSearch = replaceSortField(initialSearch);
    resultSearch = replaceSortOrder(resultSearch);

    return resultSearch !== initialSearch ? `${location.pathname}?${resultSearch}` : null;
}

function WithdrawalProcessing({location}: WithdrawalProcessingProps) {
    const tabs: Tab[] = [
        {
            title: localized.pendingCases,
            component: WithdrawalPendingCases,
            path: `${RouteUrl.WithdrawalProcessing}${RouteUrl.WithdrawalPendingCases}`,
            id: 'withdrawalPendingCases',
        },
        {
            title: localized.historyCases,
            component: WithdrawalHistoryCases,
            path: `${RouteUrl.WithdrawalProcessing}${RouteUrl.WithdrawalHistoryCases}`,
            id: 'withdrawalHistoryCases',
        },
    ];

    const redirectUrl = getRedirectUrl(location);

    return (
        <>
            {redirectUrl ? <Redirect to={redirectUrl} /> : null}
            <LayoutQueueTabs
                data-testid="withdrawalProcessing"
                header={<ModuleHeader header={localized.title} removeBottomSpacing={true} />}
                body={<RoutingTabs tabs={tabs} />}
            />
        </>
    );
}

export const withdrawalProcessingResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'WithdrawalProcessing',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.WithdrawalProcessing, null, 'read'),
        new Policy(ModuleName.WithdrawalProcessing, SubmoduleName.Payment, 'read'),
        new Policy(ModuleName.WithdrawalProcessing, SubmoduleName.WithdrawalProcessingRisk, 'read'),
    ]),
});

export default withModule(withRouter(WithdrawalProcessing), withdrawalProcessingPermissions);
