import {gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {DocumentNode} from 'graphql';
import {inject, injectable} from 'inversify';

import {GlobalSearchFilter, GlobalSearchRecord, QuerySearchArgs} from '@models/generated/graphql';
import {Filter, GlobalSearchFilterKeys, GlobalSearchQueryFields, GlobalSearchTextFilterKeys} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';

import {DynamicConfig} from 'src/configuration';
import {JurisdictionConfigService} from 'src/features/app/config/services';
import {ServiceTypes} from '../inversify';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';

@injectable()
export class GlobalSearchService extends EntityBaseGqlService<QuerySearchArgs, GlobalSearchQueryFields, GlobalSearchFilterKeys> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGPMocked) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.JurisdictionConfigService) jurisdictionConfigService: JurisdictionConfigService,
        @inject(ServiceTypes.Config) config: DynamicConfig
    ) {
        super(client, mapper, new GlobalSearchRequestBuilder(jurisdictionConfigService, config));
    }
}

export class GlobalSearchRequestBuilder extends GqlRequestBuilder<QuerySearchArgs, GlobalSearchQueryFields, GlobalSearchFilterKeys> {
    public buildQuery(fields: GlobalSearchQueryFields[]): DocumentNode {
        return gql`
            fragment GlobalSearchFragment on GlobalSearchRecord {
                key ${this.hasQueryItem(fields, 'key')} @client
                source ${this.hasQueryItem(fields, 'source')} @client
                meta ${this.hasQueryItem(fields, 'meta')} @client
            }
            query Search($filter: GlobalSearchFilter, $limit: Int) {
                search(filter: $filter, limit: $limit) {
                    ...GlobalSearchFragment    
                }
            }
        `;
    }

    protected buildFilter(filter: Filter<GlobalSearchFilterKeys>): {filter: GlobalSearchFilter} {
        return {
            filter: {
                source: this.toGQLMultiselectFilter(filter, 'source'),
                text: this.getGQLTextFilter(
                    Object.keys(this.filterFieldsMapper).map((key: GlobalSearchTextFilterKeys) =>
                        this.toGQLTextFilter(this.filterFieldsMapper[key], filter[key] as string)
                    )
                ),
            },
        };
    }

    protected buildPaging(filter: Filter<GlobalSearchFilterKeys>) {
        const limit = filter['size'] as string;
        return limit ? {limit: parseInt(limit)} : undefined;
    }

    private filterFieldsMapper: Record<GlobalSearchTextFilterKeys, string[]> = {
        key: nameof.toArray<GlobalSearchRecord>(m => [m.key]),
        meta: nameof.toArray<GlobalSearchRecord>(m => [m.meta]),
        key_meta: nameof.toArray<GlobalSearchRecord>(m => [m.key, m.meta]),
    };
}
