//TODO: [BO-2692] Move files to src/common/model
export enum ModuleName {
    AccessManagement = 'admin',
    PlayerManagement = 'playermodule',
    LegalContentManagement = 'legal',
    ActionCenter = 'action-center',
    Comments = 'comments',
    WithdrawalProcessing = 'withdrawal-processing',
    KycProcessing = 'kyc-processing',
    SecurityWorkspace = 'security-workspace',
    CustomerSupport = 'customer-support',
    MarketingFunction = 'page-marketing-function',
    PageAgentReport = 'page-agent-report',
    PageAgentReportVn = 'page-agent-report-vn',
    PageAgentReportKr = 'page-agent-report-kr',
    TeamWorkspace = 'page-team-workspace',
    GameAdmin = 'page-plo5',
    EntityGameTable = 'entity-game-table',
    EntityGameTemplate = 'entity-game-template',
    EntityTransactions = 'entity-transaction',
    EntityBonus = 'entity-bonus',
    EntityBulk = 'entity-bulk',
    EntityAll = 'entity-all',
    Agent = 'agent',
    AgentVn = 'agent-vn',
    AgentKr = 'agent-kr',
    PageRules = 'page-rules',
    EntityRule = 'entity-rule',
    Club = 'club',
    EntityReport = 'entity-report',
    PageAgentManagement = 'page-agent-management',
    PageNdrp = 'page-ndrp',
    WptAsia = 'wpt-asia',
    WptPH = 'wpt-ph',
    WptGlobal = 'wpt-global',
    PageSportsbookOperation = 'page-sportsbook-history',
    PageEmbeddedReport = 'page-embedded-report',
}

export enum SubmoduleName {
    AccountActions = 'account',
    ActionCenter = 'action-center',
    AgentLevelReport = 'agent-level-report',
    BlacklistActions = 'blacklist',
    BonusEngine = 'bonus-engine',
    Cases = 'cases',
    CommunicationActions = 'communication',
    Details = 'details',
    FinanceActions = 'finance',
    P2PAction = 'p2p',
    GGRReport = 'ggr-report',
    GamesActions = 'games',
    KYC = 'kyc',
    LoginHistory = 'loginhistory',
    Marketing = 'mkt',
    MarketingBonusCode = 'marketing-bonus-code',
    None = 'none',
    NotesBotDetection = 'bot-detection',
    NotesCustomerSupport = 'customer-support',
    Payment = 'payment',
    NotesPlayer = 'player-details',
    NotesSecurity = 'security',
    P2PReferralOfferingAdmin = 'p2p-referral-offering-admin',
    P2PTransfer = 'p2p-transfer',
    PLO5 = 'plo5',
    PermanentBans = 'permanent-locks',
    PrivacyPolicy = 'privacypolicy',
    RegistrationInformation = 'registration',
    RevenueShare = 'revenue-share',
    Roles = 'roles',
    TermsAndConditions = 'tandc',
    TransactionHistory = 'payments',
    TransferReport = 'transfer',
    UserAcceptanceVersion = 'versionacceptance',
    Users = 'users',
    Verification = 'verification',
    WithdrawalProcessingRisk = 'risk',
    SportsbookTransactionHistory = 'transaction-history',
}

export enum RouteUrl {
    AccessManagement = '/access-management',
    Users = '/users',
    Roles = '/roles',
    PlayerManagement = '/player-management',
    RegistrationInformation = '/registration-information',
    LoginHistory = '/login-history',
    KYC = '/kyc',
    KYCDetails = '/kyc-details',
    NDRPDetails = '/ndrp-details',
    TransactionHistory = '/transaction-history',
    GameHistory = '/game-history',
    Finance = '/finance',
    BusinessMetrics = '/business-metrics',
    LegalContentManagement = '/legal-content-management',
    PrivacyPolicy = '/privacy-policy',
    TermsAndConditions = '/terms-and-conditions',
    UserAcceptanceVersion = '/user-acceptance-version',
    WithdrawalProcessing = '/withdrawal-processing',
    WithdrawalPendingCases = '/pending-cases',
    WithdrawalHistoryCases = '/history-cases',
    WithdrawalDetails = '/withdrawal-details',
    KycProcessing = '/kyc-processing',
    KycPendingCases = '/pending-cases',
    KycHistoryCases = '/history-cases',
    SecurityWorkspace = '/security-workspace',
    SecurityDetails = '/security-details',
    CustomerSupport = '/customer-support',
    CustomerSupportDetails = '/customer-support-details',
    MarketingFunction = '/marketing-function',
    MarketingBonusCode = '/marketing-bonus-code',
    BonusEngine = '/bonus-engine',
    BonusEngineDetails = '/bonus-engine-details',
    P2PReferralOfferingAdmin = '/p2p-referral-offering-admin',
    AgentManagement = '/agent-player-management',
    AgentReportPlayerDetails = '/agent-player-management-details',
    PlayerLevelReport = '/player-level-report',
    AgentLevelReport = '/agent-level-report',
    TransferReport = '/transfer',
    NoPermission = '/no-permission',
    TeamWorkspace = '/team-workspace',
    GameAdmin = '/game-admin',
    PLO5 = '/plo5',
    TemplateManagement = '/template-management',
    TableManagement = '/table-management',
    AgentReportVn = '/agent-player-management-vn',
    AgentReportKr = '/agent-player-management-kr',
    PlayerReportDaily = '/player-report-daily',
    PlayerReportWeekly = '/player-report-weekly',
    PathStatisticsVn = '/path-statistics',
    Rules = '/rules',
    AgentManagementPage = '/agent-management',
    NDRPProcessing = '/ndrp-processing',
    SportsbookOperation = '/sportsbook-operation',
    EmbeddedReport = '/embedded-report',
}
