import {defineMessages} from 'react-intl';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import moment from 'moment';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf, PayloadAction} from 'typesafe-actions';

import {ServiceTypes} from '@inversify';
import {PlayerReferral, ReferrerType, RevenueShareOfferingStatus, UserProfile} from '@models/generated/graphql';
import {map, mergeMap} from '@otel';
import {BaseEpicsBuilder} from '@redux';
import {IAsyncAction} from '@redux/async-action';
import {entityActions, EntityType, PlayerReferralNormalized, UserProfileNormalized} from '@redux/entity';
import {realtimeActions, RealtimeMessageTrigger} from '@redux/realtime';
import {RootEpic} from '@redux/types';
import {IUserProfileService} from '@services';
import {
    IPlayerReferralUpdateService,
    UserReferralExpirationDateFormModel,
    UserReferralFormModel,
    UserReferralRevenueShareFormModel,
} from '@services/playerReferralService';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';
import {UpdateAffiliateBtagModel} from '@services/userProfileService';
import {momentToTimestampSeconds} from '@utils';

import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

import {playerReferralActions} from './actions';

const localized = defineMessages({
    addReferrerInfoSuccess: {
        id: 'PlayerReferrerInfoEpicsBuilder_addReferrerInfoSuccess',
        defaultMessage: 'Referrer info is successfully added',
    },
    addReferrerInfoFailed: {
        id: 'PlayerReferrerInfoEpicsBuilder_addReferrerInfoFailed',
        defaultMessage: 'Failed to add referrer info',
    },
    updateReferrerInfoSuccess: {
        id: 'PlayerReferrerInfoEpicsBuilder_updateReferrerInfoSuccess',
        defaultMessage: 'Referrer info is successfully updated',
    },
    updateReferrerInfoFailed: {
        id: 'PlayerReferrerInfoEpicsBuilder_updateReferrerInfoFailed',
        defaultMessage: 'Failed to update referrer info',
    },
    removeReferrerInfoSuccess: {
        id: 'PlayerReferrerInfoEpicsBuilder_removeReferrerInfoSuccess',
        defaultMessage: 'Referrer info is successfully removed',
    },
    removeReferrerInfoFailed: {
        id: 'PlayerReferrerInfoEpicsBuilder_removeReferrerInfoFailed',
        defaultMessage: 'Failed to remove referrer info',
    },
    editExpirationDateSuccess: {
        id: 'PlayerReferrerInfoEpicsBuilder_editExpirationDateSuccess',
        defaultMessage: 'Expiration date is successfully updated',
    },
    editExpirationDateFailed: {
        id: 'PlayerReferrerInfoEpicsBuilder_editExpirationDateFailed',
        defaultMessage: 'Failed to update expiration date',
    },
    editRevenueShareSuccess: {
        id: 'PlayerReferrerInfoEpicsBuilder_editRevenueShareSuccess',
        defaultMessage: 'Revenue share is successfully updated',
    },
    editRevenueShareFailed: {
        id: 'PlayerReferrerInfoEpicsBuilder_editRevenueShareFailed',
        defaultMessage: 'Failed to update revenue share',
    },
});

@injectable()
export class PlayerReferralEpicsBuilder extends BaseEpicsBuilder {
    private readonly _referralsService: IPlayerReferralUpdateService;
    private readonly _userService: IUserProfileService;
    private readonly _mapper: Mapper;

    constructor(
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.PlayerReferralService) referralsService: IPlayerReferralUpdateService,
        @inject(ServiceTypes.UserProfileService) userService: IUserProfileService
    ) {
        super();
        this._referralsService = referralsService;
        this._userService = userService;
        this._mapper = mapper;
    }

    protected buildEpicList(): RootEpic[] {
        return [
            this.buildChangeReferrerInfoEpic(),
            this.buildAddReferrerInfoRequestEpic(),
            this.buildAddReferrerInfoSuccessEpic(),
            this.buildAddReferrerInfoFailureEpic(),
            this.buildUpdateReferrerInfoRequestEpic(),
            this.buildUpdateReferrerInfoSuccessEpic(),
            this.buildUpdateReferrerInfoFailureEpic(),
            this.buildRemoveReferrerInfoRequestEpic(),
            this.buildRemoveReferrerInfoSuccessEpic(),
            this.buildRemoveReferrerInfoFailureEpic(),
            this.buildEditExpirationDateRequestEpic(),
            this.buildEditExpirationDateSuccessEpic(),
            this.buildEditExpirationDateFailureEpic(),
            this.buildEditRevenueShareRequestEpic(),
            this.buildEditRevenueShareSuccessEpic(),
            this.buildEditRevenueShareFailureEpic(),
        ];
    }

    private buildChangeReferrerInfoEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(playerReferralActions.changeReferrerInfo.request)),
                mergeMap(action => {
                    const requestActionMapping: Record<string, IAsyncAction> = {
                        ['add']: playerReferralActions.addReferrerInfo,
                        ['update']: playerReferralActions.updateReferrerInfo,
                        ['remove']: playerReferralActions.removeReferrerInfo,
                    };
                    return of(requestActionMapping[action.payload?.mode].request(action.payload?.referral));
                })
            );
    }

    private buildAddReferrerInfoRequestEpic(): RootEpic {
        return this.buildRequestEpic<UserReferralFormModel, GqlMutationRequest | UpdateAffiliateBtagModel, UserProfile | PlayerReferral>(
            playerReferralActions.addReferrerInfo,
            payload =>
                payload?.referrer_type === ReferrerType.P2P
                    ? this._referralsService.addReferrerInfo(payload)
                    : this._userService.updateAffiliateBtag(
                          this._mapper.map(payload, UserReferralFormModel, nameof<UpdateAffiliateBtagModel>())
                      )
        );
    }

    private buildAddReferrerInfoSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(playerReferralActions.addReferrerInfo.success)),
                mergeMap(() =>
                    of(showMessageAction({message: localized.addReferrerInfoSuccess}), playerReferralActions.changeReferrerInfo.success())
                )
            );
    }

    private buildAddReferrerInfoFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(playerReferralActions.addReferrerInfo.failure)),
                mergeMap(() =>
                    of(showMessageAction({message: localized.addReferrerInfoFailed}), playerReferralActions.changeReferrerInfo.failure())
                )
            );
    }

    private buildUpdateReferrerInfoRequestEpic(): RootEpic {
        return this.buildRequestEpic<UserReferralFormModel, UserReferralFormModel | UpdateAffiliateBtagModel, UserProfile | PlayerReferral>(
            playerReferralActions.updateReferrerInfo,
            payload =>
                payload?.referrer_type === ReferrerType.P2P
                    ? this._referralsService.updateReferrerInfo(payload)
                    : this._userService.updateAffiliateBtag(
                          this._mapper.map(payload, UserReferralFormModel, nameof<UpdateAffiliateBtagModel>())
                      )
        );
    }

    private buildUpdateReferrerInfoSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(playerReferralActions.updateReferrerInfo.success)),
                mergeMap(({payload}) => {
                    const affiliateRequestPayload = payload?.requestPayload as UpdateAffiliateBtagModel;
                    const btag: string = affiliateRequestPayload?.affiliate?.btag;

                    const updateStateActions = btag
                        ? [
                              entityActions.updateItem({
                                  type: EntityType.UserProfile,
                                  id: affiliateRequestPayload?.uid,
                                  updatedItem: {affiliate: {btag}} as UserProfileNormalized,
                              }),
                              entityActions.clean({
                                  type: EntityType.PlayerReferral,
                                  ids: [affiliateRequestPayload.id],
                              }),
                          ]
                        : [];

                    return of(
                        ...updateStateActions,
                        showMessageAction({message: localized.updateReferrerInfoSuccess}),
                        playerReferralActions.changeReferrerInfo.success()
                    );
                })
            );
    }

    private buildUpdateReferrerInfoFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(playerReferralActions.updateReferrerInfo.failure)),
                mergeMap(() =>
                    of(showMessageAction({message: localized.updateReferrerInfoFailed}), playerReferralActions.changeReferrerInfo.failure())
                )
            );
    }

    private buildRemoveReferrerInfoRequestEpic(): RootEpic {
        return this.buildRequestEpic<UserReferralFormModel, GqlMutationRequest, UserProfile | PlayerReferral>(
            playerReferralActions.removeReferrerInfo,
            payload => this._referralsService.removeReferrerInfo(payload)
        );
    }

    private buildRemoveReferrerInfoSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(playerReferralActions.removeReferrerInfo.success)),
                mergeMap(() =>
                    of(
                        // event to update client state
                        realtimeActions.event({entity: EntityType.PlayerReferral, items: [], trigger: RealtimeMessageTrigger.Delete}),
                        showMessageAction({message: localized.removeReferrerInfoSuccess}),
                        playerReferralActions.changeReferrerInfo.success()
                    )
                )
            );
    }

    private buildRemoveReferrerInfoFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(playerReferralActions.removeReferrerInfo.failure)),
                mergeMap(() =>
                    of(showErrorAction({message: localized.removeReferrerInfoFailed}), playerReferralActions.changeReferrerInfo.failure())
                )
            );
    }

    private buildEditExpirationDateRequestEpic(): RootEpic {
        return this.buildRequestEpic<UserReferralExpirationDateFormModel, GqlMutationRequest, PlayerReferral>(
            playerReferralActions.editExpirationDate,
            payload => this._referralsService.editExpirationDate(payload)
        );
    }

    private buildEditExpirationDateSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(playerReferralActions.editExpirationDate.success)),
                mergeMap((res: PayloadAction<string, ServiceResponsePayload<GqlMutationRequest, PlayerReferral>>) => {
                    const {referrer_id, referee, expiration_date} = res.payload.responsePayload;
                    const updatedItem: Partial<PlayerReferralNormalized> = {
                        expiration_date: {
                            seconds: expiration_date.seconds,
                        },
                        revenue_share_offering_status:
                            expiration_date.seconds > momentToTimestampSeconds(moment())
                                ? RevenueShareOfferingStatus.Active
                                : RevenueShareOfferingStatus.Inactive,
                    };
                    return of(
                        showMessageAction({message: localized.editExpirationDateSuccess}),
                        entityActions.updateItem({
                            type: EntityType.PlayerReferral,
                            id: `${referrer_id}-${referee?.uid}`,
                            updatedItem,
                        })
                    );
                })
            );
    }

    private buildEditExpirationDateFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(playerReferralActions.editExpirationDate.failure)),
                map(() => showErrorAction({message: localized.editExpirationDateFailed}))
            );
    }

    private buildEditRevenueShareRequestEpic(): RootEpic {
        return this.buildRequestEpic<UserReferralRevenueShareFormModel, GqlMutationRequest, PlayerReferral>(
            playerReferralActions.editRevenueShare,
            payload => this._referralsService.editRevenueShare(payload)
        );
    }

    private buildEditRevenueShareSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(playerReferralActions.editRevenueShare.success)),
                mergeMap((res: PayloadAction<string, ServiceResponsePayload<GqlMutationRequest, PlayerReferral>>) => {
                    const {referrer_id, referee, revenue_share} = res.payload.responsePayload;
                    const updatedItem: Partial<PlayerReferralNormalized> = {revenue_share};
                    return of(
                        showMessageAction({message: localized.editRevenueShareSuccess}),
                        entityActions.updateItem({
                            type: EntityType.PlayerReferral,
                            id: `${referrer_id}-${referee?.uid}`,
                            updatedItem,
                        })
                    );
                })
            );
    }

    private buildEditRevenueShareFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(playerReferralActions.editRevenueShare.failure)),
                map(() => showErrorAction({message: localized.editRevenueShareFailed}))
            );
    }
}
