import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {CurrencyWithDateCell, KeyValueCell} from '@components/key-value-table';
import {TransactionType} from '@models/generated/graphql';
import {ViewType} from '@redux/view';

import {useLastTransaction} from '../hooks';

const localized = defineMessages({
    lastDepositTitle: {
        id: 'LastDepositCell_lastDepositTitle',
        defaultMessage: 'Last Deposit',
    },
});

export type LastDepositCellProps = {
    uid: string;
};

export function LastDepositCell({uid}: LastDepositCellProps) {
    const {formatMessage} = useIntl();
    const viewType: ViewType = 'LastDeposit';
    const transaction = useLastTransaction({
        uid,
        viewType,
        type: TransactionType.Deposit,
    });

    const value = transaction
        ? {amount: transaction.amount, currency: transaction.currency, date: transaction.transaction_started_ts}
        : null;
    return (
        <KeyValueCell title={formatMessage(localized.lastDepositTitle)}>
            <CurrencyWithDateCell value={value} />
        </KeyValueCell>
    );
}
