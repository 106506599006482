import {defineMessages} from 'react-intl';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    MuiDataGridClient,
    renderCellHeader,
    renderChipCell,
    renderDateCell,
    renderDefaultChipCell,
    renderSecurityCasesCell,
    withDataGridEntityClient,
} from '@components/data-grid/mui';
import {PlayersRelationType} from '@models/generated/graphql';

import {PlayersRelationViewModel, PlayersRelationViewModelKeys} from '../types';

const localized = defineMessages({
    uid: {
        id: 'DataGridPlayersRelation_Uid',
        defaultMessage: 'Uid',
    },
    username: {
        id: 'DataGridPlayersRelation_Username',
        defaultMessage: 'Log-In',
    },
    relationType: {
        id: 'DataGridPlayersRelation_RelationType',
        defaultMessage: 'Relation Type',
    },
    relevance: {
        id: 'DataGridPlayersRelation_Relevance',
        defaultMessage: 'Relevance',
    },
    caseId: {
        id: 'DataGridPlayersRelation_CaseId',
        defaultMessage: 'Case ID',
    },
    createdAt: {
        id: 'DataGridPlayersRelation_CreatedAt',
        defaultMessage: 'Record Time',
    },
});

class DataGridPlayersRelationConfigFactory extends DataGridEntityConfigFactory<PlayersRelationViewModelKeys, PlayersRelationViewModel> {
    getColumnConfig(): Partial<Record<PlayersRelationViewModelKeys, DataGridEntityColDef<PlayersRelationViewModelKeys>>> {
        return {
            id: {
                field: 'id',
                hide: true,
            },
            'related_player.uid': {
                field: 'related_player.uid',
                valueGetter: p => this.getRelatedPlayerUid(p),
                renderHeader: () => renderCellHeader(localized.uid),
                renderCell: renderDefaultChipCell,
                sortable: false,
                flex: 0.15,
            },
            'related_player.username': {
                field: 'related_player.username',
                valueGetter: p => this.getRelatedPlayerUsername(p),
                renderHeader: () => renderCellHeader(localized.username),
                sortable: false,
                flex: 0.2,
            },
            relation_type: {
                field: 'relation_type',
                valueGetter: p => this.getChipValue(this.getRelationType(p), nameof<PlayersRelationType>()),
                renderHeader: () => renderCellHeader(localized.relationType),
                renderCell: renderChipCell,
                sortable: false,
                flex: 0.2,
            },
            relevance: {
                field: 'relevance',
                valueGetter: p => this.getRelevance(p),
                renderHeader: () => renderCellHeader(localized.relevance),
                sortable: false,
                flex: 0.25,
            },
            'related_player.security_cases': {
                field: 'related_player.security_cases',
                valueGetter: p => this.getRelatedPlayerSecurityCases(p),
                renderHeader: () => renderCellHeader(localized.caseId),
                renderCell: renderSecurityCasesCell,
                sortable: false,
                flex: 0.25,
            },
            created_at: {
                field: 'created_at',
                valueGetter: p => this.getCreatedAt(p),
                renderHeader: () => renderCellHeader(localized.createdAt),
                renderCell: renderDateCell,
                sortable: false,
                flex: 0.3,
            },
        };
    }
    getCreatedAt(p: GridValueGetterParams) {
        return this.getRow(p)?.created_at;
    }
    getRelatedPlayerSecurityCases(p: GridValueGetterParams) {
        return this.getRow(p)?.related_player?.security_cases;
    }
    getRelevance(p: GridValueGetterParams) {
        return this.getRow(p)?.relevance;
    }
    getRelationType(p: GridValueGetterParams) {
        return this.getRow(p)?.relation_type;
    }
    getRelatedPlayerUsername(p: GridValueGetterParams) {
        return this.getRow(p)?.related_player?.username;
    }
    getRelatedPlayerUid(p: GridValueGetterParams) {
        return this.getRow(p)?.related_player?.uid;
    }
}

export const DataGridPlayersRelationClient = withDataGridEntityClient<PlayersRelationViewModelKeys, PlayersRelationViewModel>(
    MuiDataGridClient,
    () => new DataGridPlayersRelationConfigFactory().getColumnConfig()
);
