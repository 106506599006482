import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import {MultiStepFilterDatePicker} from '@components/filter';
import {DateRangeFilter, DateRangeFilterValue} from '@components/filter';
import {FilterGroupNew, FilterGroupNewFilter} from '@components/filter/FilterGroupNew';
import {TextInputWithDropdownFilter} from '@components/filter/TextFilterWithDropdown';
import {FilterProps, FiltersProps} from '@components/filter/types';
import {ViewType} from '@redux/view';
import {sharedLocalization} from '@localization';

import {useJurisdictionConfig} from '../../app/config/hooks';
import {AgentFilterNew, AgentFilterValue} from '../../block-agent-profile/components/AgentFilterNew';
import {MultiStepAgentFilter} from '../../block-agent-profile/components/MultiStepAgentFilter';
import {
    AgentPlayerReportingFilterModel,
    AgentPlayerReportingFilterName,
    AgentPlayerReportingTextFilterKeys,
    AgentPlayerReportingTextFilterValue,
} from '../types';
import {getStartDateOfAvailableDateRange} from '../utils';

const localized = defineMessages({
    textFullFilterPlaceholder: {
        id: 'AgentPlayerReportingFilter_textFullFilterPlaceholder',
        defaultMessage: 'Search in Agent Reporting',
    },
});

export type AgentPlayerReportingFiltersAdditionalProps = {boAgentPlayerId: string; viewType: ViewType};

type AgentPlayerReportingFiltersProps = FiltersProps<AgentPlayerReportingFilterModel, AgentPlayerReportingFilterName> &
    AgentPlayerReportingFiltersAdditionalProps;

export function AgentPlayerReportingFilters({
    viewType,
    model,
    onChange,
    availableFilters,
    boAgentPlayerId,
}: AgentPlayerReportingFiltersProps) {
    const placeholderMapper: Record<AgentPlayerReportingTextFilterKeys, MessageDescriptor> = {
        uid_email_uname_rmc: localized.textFullFilterPlaceholder,
        uid: sharedLocalization.userIdFilterPlaceholder,
        email: sharedLocalization.emailFilterPlaceholder,
        username: sharedLocalization.usernameFilterPlaceholder,
        marketing_code: sharedLocalization.registrationCodeFilterPlaceholder,
        uid_email: localized.textFullFilterPlaceholder,
    };
    const {locale} = useJurisdictionConfig();
    const availableDateRangeForSelection = {from: getStartDateOfAvailableDateRange().toDate()};

    const filters: FilterGroupNewFilter<AgentPlayerReportingFilterModel, AgentPlayerReportingFilterName>[] = [
        {
            filterName: 'uid',
            modelField: 'text',
            component: (props: FilterProps<AgentPlayerReportingTextFilterValue>) => (
                <TextInputWithDropdownFilter
                    options={[{label: sharedLocalization.userIdFilter, value: 'uid'}]}
                    inputPlaceholder={sharedLocalization.userIdFilterPlaceholder}
                    width="full"
                    {...props}
                />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'uid_email',
            modelField: 'text',
            component: (props: FilterProps<AgentPlayerReportingTextFilterValue>) => (
                <TextInputWithDropdownFilter
                    options={[
                        {label: sharedLocalization.textFullFilter, value: 'uid_email'},
                        {label: sharedLocalization.userIdFilter, value: 'uid'},
                        {
                            label: sharedLocalization.emailFilter,
                            value: 'email',
                        },
                    ]}
                    inputPlaceholder={props?.value?.option ? placeholderMapper[props?.value?.option] : localized.textFullFilterPlaceholder}
                    width="full"
                    {...props}
                />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'uid_email_uname_rmc',
            modelField: 'text',
            component: (props: FilterProps<AgentPlayerReportingTextFilterValue>) => (
                <TextInputWithDropdownFilter
                    options={[
                        {label: sharedLocalization.textFullFilter, value: 'uid_email_uname_rmc'},
                        {label: sharedLocalization.userIdFilter, value: 'uid'},
                        {
                            label: sharedLocalization.emailFilter,
                            value: 'email',
                        },
                        {
                            label: sharedLocalization.usernameFilter,
                            value: 'username',
                        },
                        {
                            label: sharedLocalization.registrationCodeFilter,
                            value: 'marketing_code',
                        },
                    ]}
                    inputPlaceholder={props?.value?.option ? placeholderMapper[props?.value?.option] : localized.textFullFilterPlaceholder}
                    width="full"
                    {...props}
                />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'agent',
            modelField: 'agent',
            component: (props: FilterProps<AgentFilterValue>) => (
                <AgentFilterNew boAgentPlayerId={boAgentPlayerId} width="full" viewType={viewType} withDownstream={true} {...props} />
            ),
            componentMobile: (props: FilterProps<AgentFilterValue>) => (
                <MultiStepAgentFilter defaultState={{viewType, withDownStream: true, boAgentPlayerId}} {...props} />
            ),
            collapseOnMobile: true,
        },
        {
            filterName: 'agent_no_downline',
            modelField: 'agent',
            component: (props: FilterProps<AgentFilterValue>) => (
                <AgentFilterNew boAgentPlayerId={boAgentPlayerId} width="full" viewType={viewType} withDownstream={false} {...props} />
            ),
            componentMobile: (props: FilterProps<AgentFilterValue>) => (
                <MultiStepAgentFilter defaultState={{viewType, withDownStream: false, boAgentPlayerId}} {...props} />
            ),
            collapseOnMobile: true,
        },
        {
            filterName: 'started_at',
            modelField: 'started_at',
            component: props => (
                <DateRangeFilter availableDateRangeForSelection={availableDateRangeForSelection} {...props} locale={locale} />
            ),
            componentMobile: (props: FilterProps<DateRangeFilterValue>) => (
                <MultiStepFilterDatePicker defaultState={{locale, availableDateRangeForSelection}} {...props} />
            ),
            collapseOnMobile: true,
        },
        {
            filterName: 'started_at_weeks',
            modelField: 'started_at',
            component: props => (
                <DateRangeFilter
                    availableDateRangeForSelection={availableDateRangeForSelection}
                    {...props}
                    locale={locale}
                    rangeMode="weeks"
                />
            ),
            componentMobile: (props: FilterProps<DateRangeFilterValue>) => (
                <MultiStepFilterDatePicker defaultState={{locale, rangeMode: 'weeks', availableDateRangeForSelection}} {...props} />
            ),
            collapseOnMobile: true,
        },
    ];

    return (
        <FilterGroupNew
            model={model}
            onChange={onChange}
            mode="update-on-change"
            allFilters={filters}
            availableFilters={availableFilters}
        />
    );
}
