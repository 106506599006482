import React from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import {UserProfileViewModelKeys} from '@models/user-profile';

import {useUserProfileDownloadButton} from '../hooks';

const localized = defineMessages({
    buttonLabel: {
        id: 'UserProfileDownloadCsvButton_label',
        defaultMessage: 'Download CSV',
    },
});

const useStyles = makeStyles()(theme => ({
    userProfileDownloadCsvButton: {
        borderRadius: theme.shape.borderRadius * 2,
    },
}));

type UserProfileDownloadCsvButtonProps = {
    filterString: string;
    keys: UserProfileViewModelKeys[];
    filename: string;
};

export function UserProfileDownloadCsvButton(props: UserProfileDownloadCsvButtonProps) {
    const {handleClick, isProgress} = useUserProfileDownloadButton(props);
    const {classes} = useStyles();
    return (
        <Button label={localized.buttonLabel} onClick={handleClick} loading={isProgress} className={classes.userProfileDownloadCsvButton} />
    );
}
