import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {columnConfigurationActions} from '../../../../../features/module-shared/actions';
import {ColumnConfigurationColDef} from '../types';

//TODO: [BO-2721] Move useGridColumnConfiguration/useGridPermissions/useJurisdictionConfigForColumns to DataGridEntity
export const useGridColumnConfiguration = (columns: ColumnConfigurationColDef[], domain: string) => {
    const actions = columnConfigurationActions(domain);
    const dispatch = useDispatch();

    useEffect(() => {
        if (domain) {
            const columnNames = columns.reduce(
                (result, c) => (c.configurationColumns?.length ? [...result, ...c.configurationColumns] : result),
                []
            );
            dispatch(actions.setColumns(columnNames));
        }
    }, []);
};
