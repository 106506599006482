import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {Policy} from '@auth';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {useJurisdictionConfig} from '../../../features/app/config/hooks';
import {withModule} from '../../../features/module-shared/components/ModuleHoc';
import {PermissionEnum} from '../../../features/module-shared/types';
import {domain, LegalDocType} from '../types';

import data from './data';
import LegalDocumentModule from './LegalDocumentModule';

const PrivacyPolicy = () => {
    const {jurisdiction} = useJurisdictionConfig();

    const stateData = useSelector((s: RootState) => s.privacyPolicy);
    return (
        <LegalDocumentModule
            domain={domain.privacyPolicy}
            docType={LegalDocType.PrivacyPolicy}
            jurisdiction={jurisdiction}
            docName={data.localized.privacyPolicy}
            data={stateData}
        />
    );
};

export const privacyPolicyResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'PrivacyPolicy',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.LegalContentManagement, null, 'read'),
        new Policy(ModuleName.LegalContentManagement, SubmoduleName.PrivacyPolicy, 'read'),
    ]),
});

export default withModule(PrivacyPolicy, [
    {moduleName: ModuleName.LegalContentManagement, submoduleName: SubmoduleName.PrivacyPolicy, permissions: [PermissionEnum.Read]},
]);
