import {useAutoMapper} from '@auto-mapper';
import {UserProfile} from '@models/generated/graphql';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {useAuthUser} from '@auth';
import {useColumnsSettings} from '@user-settings';
import {EntityType, UserProfileQueryFields, UserProfileServerFilterKeys} from '@redux/entity';
import {UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';

import {Filter, Sorting} from '../../common/types';
import {useJurisdictionConfig} from '../app/config/hooks';

import {UserProfileColumnSettings, UserProfileGridItem} from './types';

export function useUserProfiles({
    realtimeMode,
    triggers,
    ids,
    fields,
    viewType,
    displayName,
    defaultFilters,
    defaultPaging,
    defaultSorting,
    syncWithUrl,
    validateFilter,
    cleanDelay,
}: UseListViewEntityProps<UserProfileServerFilterKeys, UserProfileViewModelKeys>): UseListViewEntityResult<
    UserProfileGridItem,
    UserProfileViewModelKeys
> {
    const mapper = useAutoMapper();

    const {
        items,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useViewInit<UserProfile, UserProfileServerFilterKeys, UserProfileQueryFields>({
        viewType,
        displayName,
        entity: {
            entity: EntityType.UserProfile,
            fields: mapFields(fields),
        },
        realtime: realtimeMode && triggers ? {entity: EntityType.UserProfile, mode: realtimeMode, triggers} : null,
        defaultSorting: mapSorting(defaultSorting),
        defaultFilters: getDefaultFilters(),
        defaultPaging: ids?.length > 0 ? {page: 1, pageSize: getUniqueIds().length} : defaultPaging,
        syncWithUrl,
        validateFilter,
        cleanDelay,
    });

    const {currency} = useJurisdictionConfig();

    //TODO: [BO-2915] Refactor id column logic for data grids
    const userItems: UserProfileGridItem[] = items?.map(i => ({...i, id: i.uid, currency}));

    return {
        items: userItems,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange: handleVmSortChange,
        handleFilterChange,
    };

    function getDefaultFilters(): Filter<string, UserProfileServerFilterKeys>[] {
        const filters: Filter<string, UserProfileServerFilterKeys>[] = defaultFilters ? [...defaultFilters] : [];
        if (ids?.length > 0) {
            const uniqueUids: string[] = getUniqueIds();
            const uidFilter: string = uniqueUids.join('|');
            filters.push({key: 'uid', value: uidFilter});
        }
        return filters;
    }

    function getUniqueIds(): string[] {
        return [...new Set(ids)];
    }

    function mapFields(fields: UserProfileViewModelKeys[]): UserProfileQueryFields[] {
        return mapper.map(fields, nameof<UserProfileViewModelKeys>(), nameof<UserProfileQueryFields>());
    }

    function mapField(field: UserProfileViewModelKeys): UserProfileQueryFields {
        return mapFields([field])[0];
    }

    function mapSorting(sorting: Sorting<UserProfileViewModelKeys>[]): Sorting<UserProfileQueryFields>[] {
        return sorting?.map(s => ({...s, field: mapField(s.field)}));
    }

    function handleVmSortChange(sorting: Sorting<UserProfileViewModelKeys>[]): void {
        handleSortChange(mapSorting(sorting));
    }
}

type UseUserProfileSettingsPorps = {
    featureName: string;
    columns: UserProfileViewModelKeys[];
    pinnedColumns?: UserProfileViewModelKeys[];
};

type UseUserProfileSettingsResult = {
    visibleColumns?: UserProfileViewModelKeys[];
    allColumns?: UserProfileViewModelKeys[];
    onVisibleColumnsChange: (value: UserProfileViewModelKeys[]) => void;
};

export function useUserProfileSettings({
    featureName,
    columns,
    pinnedColumns = [],
}: UseUserProfileSettingsPorps): UseUserProfileSettingsResult {
    const {sub} = useAuthUser();

    const {visible, all, onVisibleColumnsChange} = useColumnsSettings<UserProfileColumnSettings>({
        userId: sub,
        featureName,
        defaultColumns: mapViewModelKeysToSettings(columns),
        pinnedColumns: mapViewModelKeysToSettings(pinnedColumns),
    });

    function mapViewModelKeysToSettings(keys: UserProfileViewModelKeys[]): UserProfileColumnSettings[] {
        const mapper: Record<UserProfileViewModelKeys, UserProfileColumnSettings> = {
            account_status: 'account_status',
            'agent_info.bo_agent_id': null,
            'agent_info.default_agent_revenue_share_history': null,
            balance: 'balance',
            'birthday.seconds': null,
            bonus_ratio: null,
            casino_status: null,
            sportsbook_status: null,
            country: 'country',
            'date_of_joining.seconds': 'date_of_joining',
            deposit_status: 'deposit_status',
            deposit_total: null,
            email: 'email',
            'finance.balance': 'balance',
            first_name: 'first_name',
            full_name: 'full_name',
            gender: null,
            ggr: null,
            is_2fa_enabled: null,
            is_challenge_questions_enabled: null,
            labels: 'labels',
            last_name: 'last_name',
            'latest_kyc.payment.account_verification_status': 'latest_payment_kyc_status',
            'latest_kyc.payment.account_verification_status_cs': 'latest_payment_kyc_status',
            'latest_kyc.security.account_verification_status': 'latest_security_kyc_status',
            'latest_kyc.security.id': null,
            'latest_login.logged_at_ts.seconds': 'latest_login_time',
            lifetime_bonus: null,
            lobby_access_status: null,
            national_id: 'national_id',
            networks_BL: 'networks_BL',
            networks_PKW: 'networks_PKW',
            nickname: 'nickname',
            p2p_transfer_status: 'p2p_transfer_status',
            phone: 'phone',
            'phone.area': null,
            'phone.number': null,
            platform: 'platform',
            platform_cs: 'platform',
            'referral.personal_referral_link': null,
            referred_date: 'referred_date',
            referrer: 'referrer',
            referrer_player_id: 'referrer_player_id',
            referrer_username: 'referrer_username',
            register_marketing_code: 'register_marketing_code',
            security_cases: 'security_cases',
            'security_cases.case_id': null,
            'security_cases.type': null,
            'security_cases.closed_at': null,
            'security_cases.opened_at': null,
            uid: 'uid',
            uid_link_agent: 'uid',
            uid_link_cs: 'uid',
            uid_link_security: 'uid',
            username: 'username',
            withdrawal_approved_amount: 'withdrawal_approved_amount',
            withdrawal_approved_count: 'withdrawal_approved_count',
            withdrawal_pending_amount: 'withdrawal_pending_amount',
            withdrawal_status: 'withdrawal_status',
            'latest_kyc.payment.id': null,
            'networks.uid': null,
            'networks.platform': null,
            aml_labels: null,
            login_countries: 'login_countries',
            vpn: null,
            brand: 'brand',
            license_type: 'license_type',
        };

        return [...new Set(keys?.map(i => mapper[i])?.filter(i => i) ?? [])];
    }

    function mapSettingsToViewModelKeys(settings: UserProfileColumnSettings[]): UserProfileViewModelKeys[] {
        const mapper: Record<UserProfileColumnSettings, UserProfileViewModelKeys[]> = {
            account_status: ['account_status'],
            balance: ['balance', 'finance.balance'],
            deposit_status: ['deposit_status'],
            email: ['email'],
            full_name: ['full_name'],
            labels: ['labels'],
            latest_payment_kyc_status: [
                'latest_kyc.payment.account_verification_status',
                'latest_kyc.payment.account_verification_status_cs',
            ],
            latest_security_kyc_status: ['latest_kyc.security.account_verification_status'],
            latest_login_time: ['latest_login.logged_at_ts.seconds'],
            national_id: ['national_id'],
            networks_BL: ['networks_BL'],
            networks_PKW: ['networks_PKW'],
            nickname: ['nickname'],
            p2p_transfer_status: ['p2p_transfer_status'],
            phone: ['phone'],
            platform: ['platform', 'platform_cs'],
            referred_date: ['referred_date'],
            referrer: ['referrer'],
            referrer_player_id: ['referrer_player_id'],
            referrer_username: ['referrer_username'],
            register_marketing_code: ['register_marketing_code'],
            security_cases: ['security_cases'],
            uid: ['uid', 'uid_link_agent', 'uid_link_cs', 'uid_link_security'],
            username: ['username'],
            withdrawal_approved_amount: ['withdrawal_approved_amount'],
            withdrawal_approved_count: ['withdrawal_approved_count'],
            withdrawal_pending_amount: ['withdrawal_pending_amount'],
            withdrawal_status: ['withdrawal_status'],
            country: ['country'],
            login_countries: ['login_countries'],
            first_name: ['first_name'],
            last_name: ['last_name'],
            date_of_joining: ['date_of_joining.seconds'],
            brand: ['brand'],
            license_type: ['license_type'],
        };

        return [...new Set(settings?.flatMap(i => mapper[i]) ?? [])];
    }

    function handleColumnSettingsChange(fields: UserProfileViewModelKeys[]) {
        const columns = mapViewModelKeysToSettings(fields);
        onVisibleColumnsChange(columns);
    }

    return {
        // filter is workaround for storing multiple keys in single setting key
        visibleColumns: mapSettingsToViewModelKeys(visible).filter(f => columns.includes(f)),
        allColumns: mapSettingsToViewModelKeys(all).filter(f => columns.includes(f)),
        onVisibleColumnsChange: handleColumnSettingsChange,
    };
}
