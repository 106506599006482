import {RefereeType, RevenueShareType} from '@models/generated/graphql';

export class BonusSummaryViewModel {
    mtt_ticket_bonus?: number;
    casino_bonus?: number;
    poker_bonus?: number;
    realized_bonus_total?: number;
    unrealized_bonus_total?: number;
    cost_global_spins_tickets?: number;
}

export class DeductionSummaryViewModel {
    casino_provider_fee?: number;
    ngr_cost?: number;
    psp_fee?: number;
    total?: number;
}

export class GgrSummaryViewModel {
    cash_ggr?: number;
    casino_ggr?: number;
    casino_share?: number;
    mtt_ggr?: number;
    poker_share?: number;
    total_share?: number;
    global_spin_ggr?: number;
    external_casino_ggr?: number;
    internal_casino_ggr?: number;
    external_casino_bet_amount?: number;
    internal_casino_bet_amount?: number;
    plo_ggr_high_stake?: number;
    plo_ggr_low_stake?: number;
    plo_ggr_medium_stake?: number;
    plo_ggr_micro_stake?: number;
    plo_ggr_total?: number;
    plo_ggr_unknown_stake?: number;
    poker_addons?: number;
    poker_ggr?: number;
    poker_mtt_fees?: number;
    poker_rake?: number;
    total?: number;
    mtt_total_buyin?: number;
}

export class NgrSummaryViewModel {
    cash_ngr?: number;
    casino_ngr?: number;
    mtt_ngr?: number;
    poker_ngr?: number;
    total?: number;
}

export class PlayerGamesSummaryViewModel {
    cash_game_hand_played?: number;
    mtt_played?: number;
    player_net_deposit?: number;
    player_profit_loss?: number;
}

export class OtherSummaryViewModel {
    cost_tournament_overlay_excl_freeroll?: number;
}

export class ProSummaryViewModel {
    cost_ftd_bonus_pro?: number;
    cost_psp_fee_pro?: number;
    cost_spins_tournament_tickets_pro?: number;
    is_pro_shortdeck?: number;
    nlhe_ggr_usd_pro?: number;
    nlhe_high_ggr_usd_pro?: number;
    nlhe_low_ggr_usd_pro?: number;
    nlhe_medium_ggr_usd_pro?: number;
    nlhe_micro_ggr_usd_pro?: number;
    poker_ggr_usd_pro?: number;
    shortdeck_ggr_usd_pro?: number;
}

export class AgentPlayerReportingViewModel {
    uid?: string;
    uid_link?: string;
    uid_text?: string;
    bonus_summary?: BonusSummaryViewModel;
    country?: string;
    currency?: string;
    deduction_summary?: DeductionSummaryViewModel;
    email?: string;
    ggr_summary?: GgrSummaryViewModel;
    id: string;
    ngr_summary?: NgrSummaryViewModel;
    phone?: string;
    player_games_summary?: PlayerGamesSummaryViewModel;
    other_summary?: OtherSummaryViewModel;
    referrer_agent_id?: string;
    referrer_agent_username?: string;
    username?: string;
    revenue_share_type?: RevenueShareType;
    referee_type?: RefereeType;
    rakes?: number;
    insurance_tool?: number;
    casino_ggr?: number;
    registered_player_number?: number;
    active_player_number?: number;
    revenue_share?: number;
    register_marketing_code?: string;
    total_share?: number;
    poker_share?: number;
    casino_share?: number;
    is_summary: boolean;
    pro_summary?: ProSummaryViewModel;
    highest_blind_level_is_pro_nlhe?: string;
}

export class AgentPlayerReportingSummaryViewModel {
    bonus_summary?: BonusSummaryViewModel;
    deduction_summary?: DeductionSummaryViewModel;
    ggr_summary?: GgrSummaryViewModel;
    ngr_summary?: NgrSummaryViewModel;
    player_games_summary?: PlayerGamesSummaryViewModel;
}

type ViewModelKeys = keyof AgentPlayerReportingViewModel;
const ggrSummaryKey: ViewModelKeys = 'ggr_summary';
const ngrSummaryKey: ViewModelKeys = 'ngr_summary';
const bonusSummaryKey: ViewModelKeys = 'bonus_summary';
const deductionSummaryKey: ViewModelKeys = 'deduction_summary';
const playerGamesSummaryKey: ViewModelKeys = 'player_games_summary';
const otherSummaryKey: ViewModelKeys = 'other_summary';
const proSummaryKey: ViewModelKeys = 'pro_summary';

export type AgentPlayerReportingViewModelKeys =
    | keyof Omit<
          AgentPlayerReportingViewModel,
          | 'ggr_summary'
          | 'ngr_summary'
          | 'bonus_summary'
          | 'deduction_summary'
          | 'player_games_summary'
          | 'other_summary'
          | 'summary'
          | 'pro_summary'
      >
    | `${typeof ggrSummaryKey}.${keyof GgrSummaryViewModel}`
    | `${typeof ngrSummaryKey}.${keyof NgrSummaryViewModel}`
    | `${typeof bonusSummaryKey}.${keyof BonusSummaryViewModel}`
    | `${typeof deductionSummaryKey}.${keyof DeductionSummaryViewModel}`
    | `${typeof playerGamesSummaryKey}.${keyof PlayerGamesSummaryViewModel}`
    | `${typeof otherSummaryKey}.${keyof OtherSummaryViewModel}`
    | `${typeof proSummaryKey}.${keyof ProSummaryViewModel}`
    | 'has_summary';

export class AgentReportingDownloadModel implements Record<AgentPlayerReportingViewModelKeys, string> {
    uid: string;
    uid_link: string;
    uid_text: string;
    id: string;
    email: string;
    phone: string;
    username: string;
    country: string;
    currency: string;
    referrer_agent_id: string;
    referrer_agent_username: string;
    'bonus_summary.mtt_ticket_bonus': string;
    'bonus_summary.poker_bonus': string;
    'bonus_summary.casino_bonus': string;
    'bonus_summary.realized_bonus_total': string;
    'bonus_summary.unrealized_bonus_total': string;
    'bonus_summary.cost_global_spins_tickets': string;
    'deduction_summary.casino_provider_fee': string;
    'deduction_summary.ngr_cost': string;
    'deduction_summary.psp_fee': string;
    'deduction_summary.total': string;
    'ggr_summary.cash_ggr': string;
    'ggr_summary.plo_ggr_high_stake': string;
    'ggr_summary.plo_ggr_low_stake': string;
    'ggr_summary.plo_ggr_medium_stake': string;
    'ggr_summary.plo_ggr_micro_stake': string;
    'ggr_summary.plo_ggr_total': string;
    'ggr_summary.plo_ggr_unknown_stake': string;
    'ggr_summary.casino_ggr': string;
    'ggr_summary.casino_share': string;
    'ggr_summary.total_share': string;
    'ggr_summary.global_spin_ggr': string;
    'ggr_summary.external_casino_ggr': string;
    'ggr_summary.internal_casino_ggr': string;
    'ggr_summary.external_casino_bet_amount': string;
    'ggr_summary.internal_casino_bet_amount': string;
    'ggr_summary.poker_addons': string;
    'ggr_summary.poker_ggr': string;
    'ggr_summary.poker_mtt_fees': string;
    'ggr_summary.poker_rake': string;
    'ggr_summary.mtt_ggr': string;
    'ggr_summary.poker_share': string;
    'ggr_summary.total': string;
    'ggr_summary.mtt_total_buyin': string;
    'ngr_summary.cash_ngr': string;
    'ngr_summary.casino_ngr': string;
    'ngr_summary.mtt_ngr': string;
    'ngr_summary.poker_ngr': string;
    'ngr_summary.total': string;
    'player_games_summary.cash_game_hand_played': string;
    'player_games_summary.mtt_played': string;
    'player_games_summary.player_net_deposit': string;
    'player_games_summary.player_profit_loss': string;
    'other_summary.cost_tournament_overlay_excl_freeroll': string;
    'register_marketing_code': string;
    'revenue_share_type': string;
    'referee_type': string;
    'rakes': string;
    'insurance_tool': string;
    'casino_ggr': string;
    'registered_player_number': string;
    'active_player_number': string;
    'revenue_share': string;
    'pro_summary.cost_ftd_bonus_pro': string;
    'pro_summary.cost_psp_fee_pro': string;
    'pro_summary.cost_spins_tournament_tickets_pro': string;
    'pro_summary.is_pro_shortdeck': string;
    'pro_summary.nlhe_ggr_usd_pro': string;
    'pro_summary.nlhe_high_ggr_usd_pro': string;
    'pro_summary.nlhe_low_ggr_usd_pro': string;
    'pro_summary.nlhe_medium_ggr_usd_pro': string;
    'pro_summary.nlhe_micro_ggr_usd_pro': string;
    'pro_summary.poker_ggr_usd_pro': string;
    'pro_summary.shortdeck_ggr_usd_pro': string;
    highest_blind_level_is_pro_nlhe: string;
    'is_summary': string;
    total_share: string;
    poker_share: string;
    casino_share: string;
    has_summary: string;
}
