import React from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';

import {ModuleName} from 'src/common/routeEnums';
import {withPermissionAndJurisdictionConfig} from '../../app/permission/PermissionHoc';
import {PermissionEnum} from '../../module-shared/types';
import {useDownloadCsvButton} from '../hooks';
import {UserLoginViewModelKeys} from '../types';

const localized = defineMessages({
    buttonLabel: {
        id: 'DownloadCsvButton_label',
        defaultMessage: 'Download CSV',
    },
});

const useStyles = makeStyles()(theme => ({
    userLoginDownloadCsvButton: {
        borderRadius: theme.shape.borderRadius * 2,
    },
}));

type UserLoginDownloadCsvButtonProps = {
    filterString: string;
    filename: string;
    keys: UserLoginViewModelKeys[];
};

function DownloadCsvButtonInner(props: UserLoginDownloadCsvButtonProps) {
    const {classes} = useStyles();
    const {handleClick, isProgress} = useDownloadCsvButton(props);
    return (
        <Button label={localized.buttonLabel} onClick={handleClick} loading={isProgress} className={classes.userLoginDownloadCsvButton} />
    );
}

export const downloadCsvButtonPermissions = {
    moduleName: ModuleName.PlayerManagement,
    permissions: [PermissionEnum.Read],
};

export const DownloadCsvButton = withPermissionAndJurisdictionConfig(DownloadCsvButtonInner, downloadCsvButtonPermissions);
