import React, {useEffect} from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {LayoutDetails} from '@components/layout';
import StyledAccordion from '@components/StyledAccordion';
import {ReferralSettings} from '@models/generated/graphql';
import {Policy} from '@auth';
import {EntityType} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {useViewInit, ViewType} from '@redux/view';
import {CustomTheme} from '@style';

import {ModuleName} from 'src/common/routeEnums';
import {ConfigurationKeyValuePair} from '../common/types';
import {setBreadcrumbAction} from '../features/app/breadcrumb/actions';
import {WorkspaceHeader} from '../features/block-workspace-details/components/WorkspaceHeader';
import {WorkspaceKeyValueTable} from '../features/block-workspace-details/components/WorkspaceKeyValueTable';
import {withModule} from '../features/module-shared/components/ModuleHoc';
import {PermissionEnum} from '../features/module-shared/types';
import {ReferralSettingsExpirationPeriod} from '../features/referral-settings/components/ReferralSettingsExpirationPeriod';
import {ReferralSettingsRevenueShare} from '../features/referral-settings/components/ReferralSettingsRevenueShare';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    p2pReferralOfferingAdminHeaderLabel: {
        color: theme.palette.text.secondary,
    },
    p2pReferralOfferingContainer: {
        width: '100%',
    },
    p2pReferralOfferingAdminHeader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: theme.spacing(1),
    },
}));

const localized = defineMessages({
    P2PReferralOfferingAdminDisplayName: {
        id: 'P2PReferralOfferingAdminDisplayName',
        defaultMessage: 'P2PReferralOfferingAdmin',
    },
    P2PReferralOfferingAdminHeader: {
        id: 'P2PReferralOfferingAdminHeader',
        defaultMessage: 'P2P Referral Offering Admin',
    },
    P2PReferralOfferingCollapseHeader: {
        id: 'P2PReferralOfferingCollapseHeader',
        defaultMessage: 'P2P Referral Offering',
    },
    P2PReferralOfferingRevenueShare: {
        id: 'P2PReferralOfferingCRevenueShare',
        defaultMessage: 'Revenue Share',
    },
    P2PReferralOfferingPeriodForRevenueShare: {
        id: 'P2PReferralOfferingPeriodForRevenueShare',
        defaultMessage: 'Period for Revenue share',
    },
});

export const getTableRows = (referralSettings: ReferralSettings[]): ConfigurationKeyValuePair[] => {
    const revenue_share = referralSettings?.[0]?.revenue_share ?? 0;
    const expiration_period = referralSettings?.[0]?.expiration_period ?? 0;

    return [
        {
            field: nameof.full<ReferralSettings>(i => i.revenue_share),
            key: localized.P2PReferralOfferingRevenueShare,
            value: <ReferralSettingsRevenueShare revenue_share={revenue_share * 100} expiration_period={expiration_period} />,
            configuration: [],
        },
        {
            field: nameof.full<ReferralSettings>(i => i.expiration_period),
            key: localized.P2PReferralOfferingPeriodForRevenueShare,
            value: <ReferralSettingsExpirationPeriod revenue_share={revenue_share} expiration_period={expiration_period} />,
            configuration: [],
        },
    ];
};

const P2PReferralOfferingAdmin = () => {
    const viewType: ViewType = 'P2PReferralOfferingAdmin';
    const {classes} = useClasses();
    const dispatch = useDispatch();
    const fields = [nameof<ReferralSettings>(i => i.revenue_share), nameof<ReferralSettings>(i => i.expiration_period)];

    const {items} = useViewInit<ReferralSettings, string, string>({
        viewType,
        displayName: localized.P2PReferralOfferingAdminDisplayName,
        entity: {
            entity: EntityType.ReferralSettings,
            fields,
        },
        realtime: {
            entity: EntityType.ReferralSettings,
            mode: RealtimeUpdatesMode.Confirm,
            triggers: [RealtimeMessageTrigger.Update],
        },
        validateFilter: () => true,
    });
    const dataSelector = (): ReferralSettings[] => items;

    useEffect(() => {
        dispatch(setBreadcrumbAction([{url: '', title: localized.P2PReferralOfferingAdminHeader, clickable: false}, null]));
    }, []);

    return (
        <LayoutDetails
            header={<WorkspaceHeader children={<></>} />}
            primaryInfo={
                <>
                    <StyledAccordion
                        header={localized.P2PReferralOfferingCollapseHeader}
                        componentId="personalInfo"
                        unmountOnCollapse
                        children={
                            <Box className={classes.p2pReferralOfferingContainer}>
                                <WorkspaceKeyValueTable
                                    domain={viewType}
                                    getTableRows={getTableRows}
                                    dataSelector={dataSelector}
                                    header={localized.P2PReferralOfferingCollapseHeader}
                                    fields={fields}
                                    hideTitle
                                    columnsCount={2}
                                />
                            </Box>
                        }
                    />
                </>
            }
            secondaryInfoTop={null}
            secondaryInfoBottom={null}
        />
    );
};

export const p2pReferralOfferingAdminResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'P2PReferralOfferingAdmin',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.MarketingFunction, null, 'read'),
        new Policy(ModuleName.MarketingFunction, null, 'update'),
    ]),
});

export default withModule(P2PReferralOfferingAdmin, [
    {moduleName: ModuleName.MarketingFunction, permissions: [PermissionEnum.Read, PermissionEnum.Update]},
]);
