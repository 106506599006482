import React, {FC} from 'react';

import {isStringNullOrEmpty} from '@utils';

type CheckValue<TValue> = {
    value: TValue;
};

type DefaultSymbol = 'none' | 'invisible' | 'dash';

type EmptyLabelProps = {
    defaultSymbol: DefaultSymbol;
};

export function EmptyLabel(props: EmptyLabelProps) {
    const symbol = props?.defaultSymbol ?? 'none';
    const symbolMap: Record<DefaultSymbol, string> = {
        dash: '—',
        invisible: '‍',
        none: '',
    };
    return <>{symbolMap[symbol]}</>;
}

export const withEmptyCheck =
    <TValue, TProps extends CheckValue<TValue>>(WrappedComponent: FC<TProps>) =>
    (props: TProps) => {
        const {value} = props;
        const isEmpty =
            value === null ||
            value === undefined ||
            (Array.isArray(value) && value?.length === 0) ||
            (typeof value === 'string' && isStringNullOrEmpty(value));
        return isEmpty ? <EmptyLabel defaultSymbol="dash" /> : <WrappedComponent {...props} />;
    };

export const TextWithEmptyCheck = withEmptyCheck(({value}: CheckValue<string>) => <>{value}</>);
