import {useAutoMapper} from '@auto-mapper';
import {Rule} from '@models/rule/types';
import {EntityType, RuleFields, RuleFilterKeys} from '@redux/entity';
import {RealtimeMessageTrigger} from '@redux/realtime';
import {
    getNonEmptyValueValidator,
    UseDetailsViewEntityProps,
    UseDetailsViewEntityResult,
    UseListViewEntityProps,
    UseListViewEntityResult,
    useViewInit,
} from '@redux/view';
import {updateKeyValueFilterArray} from '@utils';

import {Filter} from '../../common/types';

import {RuleViewModel, RuleViewModelKeys} from './types';

export function useRules({
    viewType,
    displayName,
    defaultFilters,
    realtimeMode,
    triggers,
    fields,
    validateFilter = () => true,
    cleanDelay,
}: UseListViewEntityProps<RuleFilterKeys, RuleViewModelKeys>): UseListViewEntityResult<RuleViewModel, RuleViewModelKeys> {
    const mapper = useAutoMapper();
    const queryFields: RuleFields[] = mapper.map(fields, nameof<RuleViewModelKeys>(), nameof<RuleFields>());
    const {
        items,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handleFilterChange,
    } = useViewInit<Rule, RuleFilterKeys, RuleFields>({
        viewType,
        entity: {
            entity: EntityType.Rule,
            fields: queryFields,
        },
        displayName,
        defaultFilters,
        realtime: realtimeMode && triggers ? {entity: EntityType.Rule, mode: realtimeMode, triggers} : null,
        validateFilter,
        cleanDelay,
    });

    const rules = items?.map(i => mapper?.map(i, Rule, RuleViewModel))?.sort((a, b) => a.order - b.order);

    function handlePageChange() {}
    function handlePageSizeChange() {}
    function handleSortChange() {}

    return {
        items: rules,
        totalCount,
        filterString,
        searchFilter,
        handleFilterChange,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
    };
}

export function useRule({
    id,
    viewType,
    displayName,
    fields,
    cleanDelay = 0,
    realtimeMode,
    defaultFilters,
    validateFilter,
}: UseDetailsViewEntityProps<RuleFilterKeys, RuleViewModelKeys>): UseDetailsViewEntityResult<RuleViewModel, RuleViewModelKeys> {
    const mapper = useAutoMapper();
    const defaultFilter: Filter<unknown, RuleFilterKeys>[] = updateKeyValueFilterArray(
        [{key: 'id', value: id ?? null}],
        ...(defaultFilters ?? [])
    );
    const serverModelFields: RuleFields[] = mapper.map(fields, nameof<RuleViewModelKeys>(), nameof<RuleFields>());

    const {
        items,
        searchFilter,
        viewEntity: {filter: filterString},
        handleFilterChange,
    } = useViewInit<Rule, RuleFilterKeys, RuleFields>({
        viewType,
        entity: {
            entity: EntityType.Rule,
            fields: serverModelFields,
        },
        realtime: realtimeMode ? {entity: EntityType.Rule, mode: realtimeMode, triggers: [RealtimeMessageTrigger.Update]} : null,
        displayName,
        defaultFilters: defaultFilter,
        defaultPaging: {page: 1, pageSize: 1},
        validateFilter: validateFilter ?? getNonEmptyValueValidator<RuleFilterKeys>('id'),
        cleanDelay,
    });

    return {item: items?.length ? mapper.map(items[0], Rule, RuleViewModel) : null, handleFilterChange, searchFilter, filterString};
}
