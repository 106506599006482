import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Observable, of} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {
    AccountVerification,
    AccountVerificationFilter,
    AccountVerificationStatus,
    AccountVerificationType,
    BulkItemInput,
    BulkMutationResponse,
    InitKycInput,
    Mutation,
    MutationInitPaymentKycArgs,
    MutationInitPaymentKycBulkOperationArgs,
    MutationInitSecurityKycArgs,
    MutationUpdateKycBulkOperationArgs,
    MutationUpdateNdrpKycArgs,
    MutationUpdatePaymentKycArgs,
    MutationUpdateSecurityKycArgs,
    QueryGetAccountVerificationsArgs,
    UserLabel,
} from '@models/generated/graphql';
import {map} from '@otel';
import {AccountVerificationFilterKeys, AccountVerificationQueryFields, AccountVerificationTextFilterKeys, Filter} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';

import {JurisdictionConfigService} from 'src/features/app/config/services';
import {DynamicConfig} from '../../configuration';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';
import {GqlMutationRequest, ServiceResponsePayload} from './types';

type KycUpdateActions = 'approve' | 'onHold' | 'reject';

export type UpdateKycStatusModel = {
    id: string;
    kycCaseId: string;
    uid: string;
    currentStatus: AccountVerificationStatus;
    type: AccountVerificationType;
    action: KycUpdateActions;
};

export type UpdatePaymentSecurityKycArgs = MutationUpdatePaymentKycArgs | MutationUpdateSecurityKycArgs;

export type UpdateKycArgs = UpdatePaymentSecurityKycArgs | (MutationUpdateNdrpKycArgs & {id: string});

export interface IAccountVerificationService {
    initiatePaymentKyc(input: InitKycInput): Observable<ServiceResponsePayload<GqlMutationRequest, AccountVerification>>;
    initiateSecurityKyc(
        uid: string,
        status: AccountVerificationStatus
    ): Observable<ServiceResponsePayload<GqlMutationRequest, AccountVerification>>;
    rejectKYCBulk(
        items: BulkItemInput[]
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationUpdateKycBulkOperationArgs>, BulkMutationResponse>>;

    initPaymentKYCBulk(
        items: (InitKycInput & {itemId: string})[]
    ): Observable<ServiceResponsePayload<GqlMutationRequest, BulkMutationResponse>>;

    updateKycStatus(
        model: UpdateKycStatusModel
    ): Observable<ServiceResponsePayload<GqlMutationRequest<UpdateKycArgs>, AccountVerification>>;
}

@injectable()
export class AccountVerificationService
    extends EntityBaseGqlService<QueryGetAccountVerificationsArgs, AccountVerificationQueryFields, AccountVerificationFilterKeys>
    implements IAccountVerificationService
{
    constructor(
        @inject(ServiceTypes.ApolloClientIGPMocked) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.JurisdictionConfigService) jurisdictionConfigService: JurisdictionConfigService,
        @inject(ServiceTypes.Config) config: DynamicConfig
    ) {
        super(client, mapper, new AccountVerificationRequestBuilder(jurisdictionConfigService, config));
    }

    public initiateSecurityKyc(
        uid: string,
        status: AccountVerificationStatus
    ): Observable<ServiceResponsePayload<GqlMutationRequest, AccountVerification>> {
        return this._service
            .mutate<Mutation, MutationInitSecurityKycArgs>(this.getInitiateSecurityKycMutation(), {uid, status})
            .pipe(map(res => ({...res, responsePayload: res.responsePayload?.initSecurityKYC})));
    }

    public initiatePaymentKyc(input: InitKycInput): Observable<ServiceResponsePayload<GqlMutationRequest, AccountVerification>> {
        return this._service
            .mutate<Mutation, MutationInitPaymentKycArgs>(this.getInitiatePaymentKycMutation(), {
                uid: input?.uid,
                kyc_input: input,
            })
            .pipe(
                map(res => ({
                    ...res,
                    requestPayload: {...res?.requestPayload},
                    responsePayload: res.responsePayload?.initPaymentKYC,
                }))
            );
    }

    public rejectKYCBulk(
        items: BulkItemInput[]
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationUpdateKycBulkOperationArgs>, BulkMutationResponse>> {
        const pendingStatuses = [
            AccountVerificationStatus.InitDepositKyc,
            AccountVerificationStatus.InitWithdrawalKyc,
            AccountVerificationStatus.InitLoginKyc,
            AccountVerificationStatus.InitPaymentKyc,
            AccountVerificationStatus.OnHoldDepositKyc,
            AccountVerificationStatus.OnHoldWithdrawalKyc,
            AccountVerificationStatus.OnHoldLoginKyc,
            AccountVerificationStatus.OnHoldPaymentKyc,
        ];

        const rejectedItems = items?.reduce<BulkItemInput[]>((inputs, i) => {
            const itemStatus = i?.data?.kyc_status?.status;
            if (pendingStatuses.includes(itemStatus)) {
                i.data = {...i.data, kyc_status: {...i.data.kyc_status, status: this.rejectKYCStatusMapper(i.data.kyc_status.status)}};
                inputs.push(i);
            }
            return inputs;
        }, []);

        return this._service
            .mutate<Mutation, MutationUpdateKycBulkOperationArgs>(this.getBulkKYCUpdateMutation(), {
                bulkData: {
                    items: rejectedItems,
                    retries: 3,
                    timeout: 10000,
                },
            })
            .pipe(map(res => ({...res, responsePayload: res?.responsePayload?.updateKYCBulkOperation})));
    }

    initPaymentKYCBulk(
        items: (InitKycInput & {itemId: string})[]
    ): Observable<ServiceResponsePayload<GqlMutationRequest, BulkMutationResponse>> {
        const requestItems: BulkItemInput[] = items.map(u => ({
            id: u.itemId,
            data: {kyc_init: {uid: u.uid, reason_codes: u.reason_codes}},
        }));
        return (
            this._service
                //TODO: Replace with real input type and mutation
                .mutate<Mutation, MutationInitPaymentKycBulkOperationArgs>(this.getBulkKYCInitMutation(), {
                    bulkData: {
                        items: requestItems,
                        retries: 3,
                        timeout: 10000,
                    },
                })
                .pipe(map(res => ({...res, responsePayload: res?.responsePayload?.initPaymentKYCBulkOperation})))
        );
    }

    public updateKycStatus(
        model: UpdateKycStatusModel
    ): Observable<ServiceResponsePayload<GqlMutationRequest<UpdateKycArgs>, AccountVerification>> {
        let result: Observable<ServiceResponsePayload<GqlMutationRequest<UpdateKycArgs>, AccountVerification>>;

        switch (model?.type) {
            case AccountVerificationType.NdrpVerification: {
                const newStatus: AccountVerificationStatus = this.getNewNdprKycStatus(model.action);
                result = this.updateNdrpKyc(model.kycCaseId, newStatus);
                break;
            }
            case AccountVerificationType.Security: {
                const newStatus: AccountVerificationStatus = this.getNewSecurityKycStatus(model.action, model.currentStatus);
                result = this.updateSecurityKyc(model.id, newStatus);
                break;
            }
            case AccountVerificationType.Payment: {
                const newStatus: AccountVerificationStatus = this.getNewPaymentKycStatus(model.action);
                result = this.updatePaymentKyc(model.id, newStatus);
                break;
            }
            default:
                result = of();
        }

        return result;
    }

    private rejectKYCStatusMapper(status: AccountVerificationStatus) {
        const mapper: Record<AccountVerificationStatus, AccountVerificationStatus> = {
            [AccountVerificationStatus.InitDepositKyc]: AccountVerificationStatus.DeniedDepositKyc,
            [AccountVerificationStatus.InitWithdrawalKyc]: AccountVerificationStatus.DeniedWithdrawalKyc,
            [AccountVerificationStatus.InitLoginKyc]: AccountVerificationStatus.DeniedLoginKyc,
            [AccountVerificationStatus.InitPaymentKyc]: AccountVerificationStatus.DeniedPaymentKyc,
            [AccountVerificationStatus.InitNdrpKyc]: AccountVerificationStatus.DeniedNdrpKyc,
            [AccountVerificationStatus.OnHoldDepositKyc]: AccountVerificationStatus.DeniedDepositKyc,
            [AccountVerificationStatus.OnHoldWithdrawalKyc]: AccountVerificationStatus.DeniedWithdrawalKyc,
            [AccountVerificationStatus.OnHoldLoginKyc]: AccountVerificationStatus.DeniedLoginKyc,
            [AccountVerificationStatus.OnHoldPaymentKyc]: AccountVerificationStatus.DeniedPaymentKyc,
            [AccountVerificationStatus.Approved]: null,
            [AccountVerificationStatus.DeniedDepositKyc]: null,
            [AccountVerificationStatus.DeniedWithdrawalKyc]: null,
            [AccountVerificationStatus.DeniedLoginKyc]: null,
            [AccountVerificationStatus.DeniedPaymentKyc]: null,
            [AccountVerificationStatus.DeniedNdrpKyc]: null,
        };
        return mapper[status];
    }

    private getInitiateSecurityKycMutation() {
        const fragment = this.getAccountVerificationFragment();
        return gql`
            ${fragment}

            mutation InitSecurityKYC($uid: String!, $status: AccountVerificationStatus!) {
                initSecurityKYC(uid: $uid, status: $status) {
                    ...AccountVerificationFragment
                }
            }
        `;
    }

    private getInitiatePaymentKycMutation() {
        const fragment = this.getAccountVerificationFragment();
        return gql`
            ${fragment}

            mutation InitPaymentKYC($uid: String!, $kyc_input: InitKYCInput) {
                initPaymentKYC(uid: $uid, kyc_input: $kyc_input) {
                    ...AccountVerificationFragment
                }
            }
        `;
    }

    private getBulkKYCUpdateMutation() {
        return gql`
            mutation UpdateKYCBulkOperation($bulkData: BulkMutationInput!) {
                updateKYCBulkOperation(bulkData: $bulkData) {
                    id
                }
            }
        `;
    }

    getBulkKYCInitMutation(): DocumentNode {
        return gql`
            mutation InitKYCBulkOperation($bulkData: BulkMutationInput!) {
                initPaymentKYCBulkOperation(bulkData: $bulkData) {
                    id
                }
            }
        `;
    }

    private getNewNdprKycStatus(action: KycUpdateActions) {
        const statusMap: Record<KycUpdateActions, AccountVerificationStatus> = {
            approve: AccountVerificationStatus.Approved,
            onHold: null,
            reject: AccountVerificationStatus.DeniedNdrpKyc,
        };

        return statusMap[action];
    }

    private getNewPaymentKycStatus(action: KycUpdateActions) {
        const statusMap: Record<KycUpdateActions, AccountVerificationStatus> = {
            approve: AccountVerificationStatus.Approved,
            onHold: AccountVerificationStatus.OnHoldPaymentKyc,
            reject: AccountVerificationStatus.DeniedPaymentKyc,
        };

        return statusMap[action];
    }

    private getNewSecurityKycStatus(action: KycUpdateActions, currentStatus: AccountVerificationStatus) {
        const onHoldStatusMapper: Partial<Record<AccountVerificationStatus, AccountVerificationStatus>> = {
            [AccountVerificationStatus.InitDepositKyc]: AccountVerificationStatus.OnHoldDepositKyc,
            [AccountVerificationStatus.InitWithdrawalKyc]: AccountVerificationStatus.OnHoldWithdrawalKyc,
            [AccountVerificationStatus.InitLoginKyc]: AccountVerificationStatus.OnHoldLoginKyc,
        };
        const rejectStatusMapper: Partial<Record<AccountVerificationStatus, AccountVerificationStatus>> = {
            [AccountVerificationStatus.InitDepositKyc]: AccountVerificationStatus.DeniedDepositKyc,
            [AccountVerificationStatus.InitWithdrawalKyc]: AccountVerificationStatus.DeniedWithdrawalKyc,
            [AccountVerificationStatus.InitLoginKyc]: AccountVerificationStatus.DeniedLoginKyc,
            [AccountVerificationStatus.OnHoldDepositKyc]: AccountVerificationStatus.DeniedDepositKyc,
            [AccountVerificationStatus.OnHoldWithdrawalKyc]: AccountVerificationStatus.DeniedWithdrawalKyc,
            [AccountVerificationStatus.OnHoldLoginKyc]: AccountVerificationStatus.DeniedLoginKyc,
        };

        const statusMap: Record<KycUpdateActions, AccountVerificationStatus> = {
            approve: AccountVerificationStatus.Approved,
            onHold: onHoldStatusMapper[currentStatus],
            reject: rejectStatusMapper[currentStatus],
        };

        return statusMap[action];
    }

    private updatePaymentKyc(id: string, status: AccountVerificationStatus) {
        return this._service
            .mutate<Mutation, MutationUpdatePaymentKycArgs>(this.getPaymentKYCMutation(), {id, status})
            .pipe(map(r => ({...r, responsePayload: r.responsePayload?.updatePaymentKYC})));
    }

    private updateSecurityKyc(id: string, status: AccountVerificationStatus) {
        return this._service
            .mutate<Mutation, MutationUpdateSecurityKycArgs>(this.getSecurityKYCMutation(), {id, status})
            .pipe(map(r => ({...r, responsePayload: r.responsePayload?.updateSecurityKYC})));
    }

    private updateNdrpKyc(kycCaseId: string, status: AccountVerificationStatus) {
        return this._service
            .mutate<Mutation, MutationUpdateNdrpKycArgs>(this.getNdrpKYCMutation(), {
                kyc_case_id: kycCaseId,
                status,
                auto_update: false,
            })
            .pipe(map(r => ({...r, responsePayload: r.responsePayload?.updateNdrpKYC})));
    }

    private getAccountVerificationFragment() {
        return gql`
            fragment AccountVerificationFragment on AccountVerification {
                id
                uid
                username
                initiated_at {
                    seconds
                }
                type
                account_verification_status
                status_log {
                    kyc_status
                    account_verification_status
                    logged_at {
                        seconds
                    }
                }
                documents {
                    did
                    doc_type
                    status
                    uploaded_ts {
                        seconds
                    }
                    location {
                        country
                    }
                    number
                }
            }
        `;
    }

    private getPaymentKYCMutation(): DocumentNode {
        return gql`
            mutation UpdatePaymentKYC($id: String!, $status: AccountVerificationStatus) {
                updatePaymentKYC(id: $id, status: $status) {
                    id
                }
            }
        `;
    }

    private getSecurityKYCMutation(): DocumentNode {
        return gql`
            mutation UpdateSecurityKYC($id: String!, $status: AccountVerificationStatus) {
                updateSecurityKYC(id: $id, status: $status) {
                    id
                }
            }
        `;
    }

    private getNdrpKYCMutation(): DocumentNode {
        return gql`
            mutation updateNdrpKYC($kyc_case_id: String!, $status: AccountVerificationStatus!, $auto_update: Boolean) {
                updateNdrpKYC(kyc_case_id: $kyc_case_id, status: $status, auto_update: $auto_update) {
                    id
                }
            }
        `;
    }
}

export class AccountVerificationRequestBuilder extends GqlRequestBuilder<
    QueryGetAccountVerificationsArgs,
    AccountVerificationQueryFields,
    AccountVerificationFilterKeys
> {
    protected buildFilter(filter: Filter<AccountVerificationFilterKeys>): {filter: AccountVerificationFilter} {
        return {
            filter: {
                text: this.getGQLTextFilter(
                    Object.keys(this.filterFieldsMapper).map((key: AccountVerificationTextFilterKeys) =>
                        this.toGQLTextFilter(this.filterFieldsMapper[key], filter[key] as string, this.transformTextMapper[key])
                    )
                ),
                initiated_at: this.toGQLDateRange(filter['initiatedAt.from'], filter['initiatedAt.to']),
                account_verification_status:
                    this.toGQLMultiselectFilter(filter, 'accountVerificationStatus') ??
                    this.toGQLMultiselectFilter(filter, 'defaultAccountVerificationStatus'),
                kyc_status: this.toGQLMultiselectFilter(filter, 'kycStatus'),
                type: this.toGQLMultiselectFilter(filter, 'type'),
                iso_alpha2_country_code: this.toGQLMultiselectFilter(filter, 'registrationCountry'),
                init_reason_codes: this.toGQLMultiselectFilter(filter, 'reasonCode'),
            },
        };
    }

    private getInitiatedAtQueryItems = (): AccountVerificationQueryFields[] => {
        return ['initiated_at.seconds'];
    };

    private getStatusLogQueryItems = (): AccountVerificationQueryFields[] => {
        return ['status_log.kyc_status', 'status_log.account_verification_status', ...this.getStatusLogLoggedAtQueryItems()];
    };

    private getStatusLogLoggedAtQueryItems = (): AccountVerificationQueryFields[] => {
        return ['status_log.logged_at.seconds'];
    };

    private getDocumentsQueryItems = (): AccountVerificationQueryFields[] => {
        return [
            'documents.doc_ref_id',
            'documents.doc_type',
            'documents.status',
            'documents.device_id',
            ...this.getDocumentsUploadedTsQueryItems(),
            ...this.getDocumentsCountryInfoQueryItems(),
        ];
    };

    private getDocumentsUploadedTsQueryItems = (): AccountVerificationQueryFields[] => {
        return ['documents.uploaded_ts.seconds'];
    };

    private getDocumentsCountryInfoQueryItems = (): AccountVerificationQueryFields[] => {
        return ['documents.country_info.name', 'documents.country_info.iso_alpha2_code'];
    };

    private getLabelsGroupQueryItems(): AccountVerificationQueryFields[] {
        return ['user_labels.group.id', 'user_labels.group.color', 'user_labels.group.name', 'user_labels.group.source_type'];
    }

    private getLabelsQueryItems(): AccountVerificationQueryFields[] {
        return ['user_labels.id', 'user_labels.name', ...this.getLabelsGroupQueryItems()];
    }

    public buildQuery(fields: AccountVerificationQueryFields[]): DocumentNode {
        return gql`
        query GetAccountVerificationsPage($filter: AccountVerificationFilter, $sort: Sorting, $start: Int, $end: Int) {
            getAccountVerifications(filter: $filter, sort: $sort, start: $start, end: $end) {
                items {
                    id
                    kyc_case_id @include(if: ${this.hasField(fields, 'kyc_case_id')})
                    uid @include(if: ${this.hasField(fields, 'uid')})
                    username @include(if: ${this.hasField(fields, 'username')})
                    initiated_at @include(if: ${this.hasAnyField(fields, this.getInitiatedAtQueryItems())}) {
                        seconds
                    }
                    type @include(if: ${this.hasField(fields, 'type')})
                    iso_alpha2_country_code @include(if: ${this.hasField(fields, 'iso_alpha2_country_code')})
                    account_verification_status @include(if: ${this.hasField(fields, 'account_verification_status')})
                    status_log @include(if: ${this.hasAnyField(fields, this.getStatusLogQueryItems())}) {
                        kyc_status @include(if: ${this.hasField(fields, 'status_log.kyc_status')})
                        account_verification_status @include(if: ${this.hasField(fields, 'status_log.account_verification_status')})
                        logged_at @include(if: ${this.hasAnyField(fields, this.getStatusLogLoggedAtQueryItems())}) { 
                            seconds
                        }
                    }
                    documents @include(if: ${this.hasAnyField(fields, this.getDocumentsQueryItems())})
                    {
                        doc_ref_id @include(if: ${this.hasField(fields, 'documents.doc_ref_id')})
                        doc_type @include(if: ${this.hasField(fields, 'documents.doc_type')})
                        status @include(if: ${this.hasField(fields, 'documents.status')})
                        uploaded_ts @include(if: ${this.hasAnyField(fields, this.getDocumentsUploadedTsQueryItems())}) {
                            seconds
                        }
                        country_info @include(if: ${this.hasAnyField(fields, this.getDocumentsCountryInfoQueryItems())}) {
                            name @include(if: ${this.hasField(fields, 'documents.country_info.name')})
                            iso_alpha2_code @include(if: ${this.hasField(fields, 'documents.country_info.iso_alpha2_code')})
                        }
                        device_id @include(if: ${this.hasField(fields, 'documents.device_id')})
                    }
                    email @include(if: ${this.hasField(fields, 'email')})
                    init_reason_codes @include(if: ${this.hasField(fields, 'init_reason_codes')})
                    user_labels @include(if: ${this.hasAnyField(fields, this.getLabelsQueryItems())}) {
                        id @include(if: ${this.hasField(fields, 'user_labels.id')})
                        name @include(if: ${this.hasField(fields, 'user_labels.name')})
                        group @include(if: ${this.hasAnyField(fields, this.getLabelsGroupQueryItems())}) {
                            id @include(if: ${this.hasField(fields, 'user_labels.group.id')})
                            color @include(if: ${this.hasField(fields, 'user_labels.group.color')})
                            name @include(if: ${this.hasField(fields, 'user_labels.group.name')})
                            source_type @include(if: ${this.hasField(fields, 'user_labels.group.source_type')})
                        }
                    }
                }
                total_count
            }
        }
    `;
    }

    private readonly filterFieldsMapper: Record<AccountVerificationTextFilterKeys, string[]> = {
        uid: nameof.toArray<AccountVerification>(m => [m.uid]),
        username: nameof.toArray<AccountVerification>(m => [m.username]),
        uidUsername: nameof.toArray<AccountVerification>(m => [m.uid, m.username]),
        id: nameof.toArray<AccountVerification>(m => [m.id]),
        email: nameof.toArray<AccountVerification>(m => [m.email]),
        uid_un_em: nameof.toArray<AccountVerification>(m => [m.uid, m.username, m.email]),
        uidIdEmail: nameof.toArray<AccountVerification>(m => [m.uid, m.id, m.email]),
        labels: [`${nameof.full<AccountVerification>(m => m.user_labels)}.${nameof.full<UserLabel>(m => m.id)}`],
    };

    private transformTextMapper: Partial<Record<AccountVerificationTextFilterKeys, (value: string) => string>> = {
        email: this.ignoreCase,
        labels: value => this.phraseListSearch(value, ' '),
        id: value => this.phraseListSearch(value, ' '),
    };
}
