import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';

import {Query, QueryGetGeoCheckSummaryArgs} from '@models/generated/graphql';
import {
    EntityFetchRequestPayload,
    EntityFetchServiceResponsePayload,
    Filter,
    GeoCheckSummaryFilterKeys,
    GeoCheckSummaryQueryFields,
} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';

import {DynamicConfig} from 'src/configuration';
import {JurisdictionConfigService} from 'src/features/app/config/services';
import {ServiceTypes} from '../inversify';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';
import {GqlResponse} from './types';

@injectable()
export class GeoCheckSummaryService extends EntityBaseGqlService<
    QueryGetGeoCheckSummaryArgs,
    GeoCheckSummaryQueryFields,
    GeoCheckSummaryFilterKeys
> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGPMocked) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.JurisdictionConfigService) jurisdictionConfigService: JurisdictionConfigService,
        @inject(ServiceTypes.Config) config: DynamicConfig
    ) {
        super(client, mapper, new GeoCheckSummaryRequestBuilder(jurisdictionConfigService, config));
    }
}

export class GeoCheckSummaryRequestBuilder extends GqlRequestBuilder<
    QueryGetGeoCheckSummaryArgs,
    GeoCheckSummaryQueryFields,
    GeoCheckSummaryFilterKeys
> {
    public toGQLResponse(req: EntityFetchRequestPayload, res: GqlResponse<Query>): EntityFetchServiceResponsePayload {
        const item = res.responsePayload?.getGeoCheckSummary;
        return {
            ...res,
            requestPayload: req,
            responsePayload: {
                items: [item],
                total: res.responsePayload?.getGeoCheckSummary ? 1 : 0,
            },
        };
    }

    protected buildFilter(filter: Filter<GeoCheckSummaryFilterKeys>) {
        return {uid: filter['uid'] as string};
    }

    protected buildQuery = (fields: GeoCheckSummaryQueryFields[]): DocumentNode => gql`
        query GetGeoCheckSummary($uid: String) {
            getGeoCheckSummary(uid: $uid) {
                uid ${this.hasQueryItem(fields, 'uid')}
                status ${this.hasQueryItem(fields, 'status')}
                fail_count ${this.hasQueryItem(fields, 'fail_count')}
                reason_categories ${this.hasQueryItem(fields, 'reason_categories')}
            }
        }
    `;
}
