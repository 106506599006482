import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {useJurisdictionFeature} from '../app/config/hooks';
import {FeatureName} from '../app/config/types';
import {usePermission} from '../app/permission/PermissionHoc';
import {PermissionEnum} from '../module-shared/types';

export const useModuleAccess = (
    permissions: PermissionEnum[],
    moduleName: ModuleName,
    submoduleName?: SubmoduleName,
    featureName?: FeatureName
) => {
    return (
        usePermission({permissions, moduleName, submoduleName}) &&
        useJurisdictionFeature({moduleName, submoduleName, permissions, featureName})
    );
};
