import {defineMessages} from 'react-intl';

export const localizedHeaders = defineMessages({
    transactionsGridUid: {
        id: 'DataGridTransaction_Uid',
        defaultMessage: 'User ID',
    },
    transactionsGridTransactionId: {
        id: 'DataGridTransaction_TransactionId',
        defaultMessage: 'Transaction ID',
    },
    transactionsGridDirection: {
        id: 'DataGridTransaction_Direction',
        defaultMessage: 'Direction',
    },
    transactionsGridAmount: {
        id: 'DataGridTransaction_Amount',
        defaultMessage: 'Amount',
    },
    transactionTimestamp: {
        id: 'DataGridTransaction_Timestamp',
        defaultMessage: 'Timestamp',
    },
    transactionsGridTransactionType: {
        id: 'DataGridTransaction_TransactionType',
        defaultMessage: 'Type',
    },
    transactionsGridPaymentMethod: {
        id: 'DataGridTransaction_PaymentMethod',
        defaultMessage: 'Method',
    },
    transactionsGridPaymentMethodDescription: {
        id: 'DataGridTransaction_PaymentMethodDescription',
        defaultMessage: 'Reference',
    },
    transactionsGridTransactionAmount: {
        id: 'DataGridTransaction_TransactionAmount',
        defaultMessage: 'Amount',
    },
    transactionsGridCurrency: {
        id: 'DataGridTransaction_Currency',
        defaultMessage: 'Currency',
    },
    transactionsGridTransactionStatus: {
        id: 'DataGridTransaction_TransactionStatus',
        defaultMessage: 'Status',
    },
    transactionsGridCurrentBalance: {
        id: 'DataGridTransaction_CurrentBalance',
        defaultMessage: 'Balance',
    },
    transactionsGridWithdrawalId: {
        id: 'DataGridTransaction_WithdrawalId',
        defaultMessage: 'Withdrawal ID',
    },
    transactionsGridWithdrawalAmount: {
        id: 'DataGridTransaction_WithdrawalAmount',
        defaultMessage: 'Withdrawal Amount',
    },
    transactionsGridEmail: {
        id: 'DataGridTransaction_Email',
        defaultMessage: 'Email Address',
    },
    transactionsGridDuration: {
        id: 'DataGridTransaction_Duration',
        defaultMessage: 'Duration',
    },
    transactionsGridP2PDirection: {
        id: 'DataGridTransaction_P2PDirection',
        defaultMessage: 'Direction',
    },
    transactionsGridKycStatus: {
        id: 'DataGridTransaction_KycStatus',
        defaultMessage: 'KYC Status',
    },
    transactionsGridSecurityCases: {
        id: 'DataGridTransaction_SecurityCases',
        defaultMessage: 'Security Cases',
    },
    transactionsGridAccountStatus: {
        id: 'DataGridTransaction_AccountStatus',
        defaultMessage: 'Account Status',
    },
    transactionsGridPhone: {
        id: 'DataGridTransaction_Phone',
        defaultMessage: 'Phone number',
    },
    transactionsGridUsername: {
        id: 'DataGridTransaction_Username',
        defaultMessage: 'Username',
    },
    transactionsGridMarketingCode: {
        id: 'DataGridTransaction_MarketingCode',
        defaultMessage: 'Marketing Code',
    },
    transactionsGridReferrerPlayerId: {
        id: 'DataGridTransaction_ReferrerPlayerId',
        defaultMessage: 'Referral ID',
    },
    transactionsGridCountry: {
        id: 'DataGridTransaction_Country',
        defaultMessage: 'Country',
    },
    transactionsGridCounterpartPlayerId: {
        id: 'DataGridTransaction_CounterpartPlayerId',
        defaultMessage: 'Counterpart player ID',
    },
    transactionsGridTransferAmount: {
        id: 'DataGridTransaction_TransferAmount',
        defaultMessage: 'Transfer Amount',
    },
});
