import {useDispatch} from 'react-redux';

import {useAutoMapper} from '@auto-mapper';
import {
    AccountVerificationMappingExtraArgs,
    AccountVerificationViewModel,
    AccountVerificationViewModelKeys,
} from '@models/account-verification';
import {
    AccountVerification,
    AccountVerification as GqlAccountVerification,
    AccountVerificationStatus,
    AccountVerificationType,
    KycInitReasonCode,
    UserProfile,
} from '@models/generated/graphql';
import {AccountVerificationMock} from '@models/mock/accountVerification';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {UserProfileViewModel} from '@models/user-profile/types';
import {useAuthUser} from '@auth';
import {useColumnsSettings} from '@user-settings';
import {
    AccountVerificationFilterKeys,
    AccountVerificationQueryFields,
    AccountVerificationSortingFields,
    EntityType,
    UserProfileServerFilterKeys,
} from '@redux/entity';
import {RealtimeMessageTrigger} from '@redux/realtime';
import {
    getNonEmptyValueValidator,
    UseDependentDetailsViewEntityProps,
    UseDetailsViewEntityProps,
    UseDetailsViewEntityResult,
    UseListViewEntityProps,
    UseListViewEntityResult,
    useViewInit,
} from '@redux/view';
import {updateKeyValueFilterArray} from '@utils';

import {Filter, Sorting} from '../../common/types';
import {useConfig} from '../../configuration';
import {useUserProfileDetails} from '../block-user-profile-details';
import {useUserProfiles} from '../block-user-profile-list';
import {useAsyncActionState} from '../shared/async-action/hooks';

import {accountVerificationActions} from './actions';
import {
    AccountVerificationWithUserProfileColumnSettings,
    AccountVerificationWithUserProfileGridItem,
    DataGridAccountVerificationWithUserProfileColumns,
} from './types';

export function useAccountVerifications(
    {
        viewType,
        fields,
        displayName,
        cleanDelay = 0,
        realtimeMode,
        triggers,
        defaultFilters,
        defaultPaging,
        defaultSorting,
        validateFilter,
        syncWithUrl = false,
    }: UseListViewEntityProps<AccountVerificationFilterKeys, AccountVerificationViewModelKeys>,
    userProfileProps?: UseListViewEntityProps<UserProfileServerFilterKeys, UserProfileViewModelKeys>
): UseListViewEntityResult<AccountVerificationWithUserProfileGridItem, AccountVerificationViewModelKeys> {
    const mapper = useAutoMapper();

    const queryFields: AccountVerificationQueryFields[] = mapFields(fields);

    const {
        items: accountVerifications,
        totalCount,
        viewEntity: {filter: filterString},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
        getSearchFilter,
    } = useViewInit<
        GqlAccountVerification,
        AccountVerificationFilterKeys,
        AccountVerificationQueryFields,
        AccountVerificationSortingFields
    >({
        viewType,
        displayName,
        entity: {
            entity: EntityType.AccountVerification,
            fields: queryFields,
        },
        realtime: realtimeMode ? {entity: EntityType.AccountVerification, mode: realtimeMode, triggers} : null,
        defaultSorting: getDefaultSorting(),
        defaultPaging,
        defaultFilters,
        syncWithUrl,
        validateFilter,
        cleanDelay,
    });

    const {items: users} = useUserProfiles({
        ...userProfileProps,
        validateFilter: userProfileProps?.validateFilter ?? (() => false),
        ids: accountVerifications?.map(i => i?.uid),
    });

    const items: AccountVerificationWithUserProfileGridItem[] = accountVerifications?.map(i => {
        const user = users.find(u => u.uid === i?.uid);
        const userViewModel: UserProfileViewModel = mapper.map(user, UserProfile, UserProfileViewModel);
        const accountVerification = mapper.map(i, AccountVerificationMock, AccountVerificationViewModel);

        return {
            ...userViewModel,
            ...accountVerification,
        };
    });

    function mapFields(fields: AccountVerificationViewModelKeys[]): AccountVerificationQueryFields[] {
        return mapper?.map<AccountVerificationViewModelKeys[], AccountVerificationQueryFields[]>(
            fields,
            nameof<AccountVerificationViewModelKeys>(),
            nameof<AccountVerificationQueryFields>()
        );
    }

    function mapVmSortingField(field: AccountVerificationViewModelKeys): AccountVerificationSortingFields {
        return mapper?.map<AccountVerificationViewModelKeys, AccountVerificationSortingFields>(
            field,
            nameof<AccountVerificationViewModelKeys>(),
            nameof<AccountVerificationSortingFields>()
        );
    }

    function mapSortingField(field: AccountVerificationSortingFields): AccountVerificationViewModelKeys {
        return mapper?.map<AccountVerificationSortingFields, AccountVerificationViewModelKeys>(
            field,
            nameof<AccountVerificationSortingFields>(),
            nameof<AccountVerificationViewModelKeys>()
        );
    }

    function mapVmSorting(sorting: Sorting<AccountVerificationViewModelKeys>[]): Sorting<AccountVerificationSortingFields>[] {
        const res: Sorting<AccountVerificationSortingFields>[] = sorting?.length
            ? sorting.map(s => ({...s, field: mapVmSortingField(s?.field)}))
            : [];
        return res;
    }

    function getDefaultSorting(): Sorting<AccountVerificationSortingFields>[] {
        return mapVmSorting(
            defaultSorting ?? [
                {
                    field: 'initiated_at.seconds',
                    sort: 'desc',
                },
            ]
        );
    }

    function handleVmSortChange(sorting: Sorting<AccountVerificationViewModelKeys>[]) {
        handleSortChange(mapVmSorting(sorting));
    }

    return {
        items,
        totalCount,
        searchFilter: getSearchFilter(mapSortingField),
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange: handleVmSortChange,
        handleFilterChange,
    };
}

export const useKycLink = (docRefId: string) => {
    const {netverifyUrl} = useConfig();
    const path = netverifyUrl?.replaceAll('{}', docRefId);

    const stopEvent = (e: React.BaseSyntheticEvent<Event>) => {
        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();
    };

    const openLink = (e: React.BaseSyntheticEvent<Event>) => {
        stopEvent(e);
        return docRefId && path && window.open(path, '_blank')?.focus();
    };

    return {openLink};
};

export function useAccountVerificationDetails(
    {
        id,
        fields,
        viewType,
        displayName,
        cleanDelay = 0,
        realtimeMode,
        defaultFilters,
        validateFilter,
    }: UseDetailsViewEntityProps<AccountVerificationFilterKeys, AccountVerificationViewModelKeys>,
    userProfileProps?: UseDependentDetailsViewEntityProps<UserProfileServerFilterKeys, UserProfileViewModelKeys>
): UseDetailsViewEntityResult<AccountVerificationViewModel, AccountVerificationViewModelKeys> {
    const mapper = useAutoMapper();
    const queryFields = mapper.map<AccountVerificationViewModelKeys[], AccountVerificationQueryFields[]>(
        fields,
        nameof<AccountVerificationViewModelKeys>(),
        nameof<AccountVerificationQueryFields>()
    );

    const defaultFilter: Filter<unknown, AccountVerificationFilterKeys>[] = updateKeyValueFilterArray(
        [{key: 'id', value: id ?? null}],
        ...(defaultFilters ?? [])
    );
    const defaultSortingField: AccountVerificationQueryFields = 'initiated_at.seconds';
    const {
        items,
        searchFilter,
        viewEntity: {filter: filterString},
        handleFilterChange,
    } = useViewInit<AccountVerification, AccountVerificationFilterKeys, AccountVerificationQueryFields>({
        viewType,
        entity: {
            entity: EntityType.AccountVerification,
            fields: queryFields,
        },
        displayName,
        realtime: realtimeMode
            ? {entity: EntityType.AccountVerification, mode: realtimeMode, triggers: [RealtimeMessageTrigger.Update]}
            : null,
        defaultFilters: defaultFilter,
        defaultPaging: {page: 1, pageSize: 1},
        defaultSorting: [{field: defaultSortingField, sort: 'desc'}],
        validateFilter: validateFilter ?? getNonEmptyValueValidator<AccountVerificationFilterKeys>('id'),
        cleanDelay,
    });

    const accountVerificationItem: AccountVerification = items?.length ? items[0] : null;

    const {item: user} = useUserProfileDetails({
        ...userProfileProps,
        id: accountVerificationItem?.uid,
        viewType: userProfileProps ? viewType : null,
        displayName,
        validateFilter: userProfileProps?.validateFilter ?? (() => false),
    });

    const item: AccountVerificationViewModel = mapper?.map(accountVerificationItem, AccountVerification, AccountVerificationViewModel, {
        extraArgs: (): AccountVerificationMappingExtraArgs => ({user}),
    });

    return {item, filterString, searchFilter, handleFilterChange};
}

export const actionDisabledStatuses: AccountVerificationStatus[] = [
    AccountVerificationStatus.InitPaymentKyc,
    AccountVerificationStatus.OnHoldPaymentKyc,
];

type UsePaymentKycAction = {
    initiateKyc: (uid: string, reasons: KycInitReasonCode[]) => void;
    isActionDisabled: (status: AccountVerificationStatus) => boolean;
};

export function usePaymentKycAction(): UsePaymentKycAction {
    const dispatch = useDispatch();
    const {isProgress} = useAsyncActionState(accountVerificationActions.initiatePaymentKyc);

    function isActionDisabled(status: AccountVerificationStatus) {
        return actionDisabledStatuses.includes(status) || isProgress;
    }

    function initiateKyc(uid: string, reasons: KycInitReasonCode[]) {
        dispatch(accountVerificationActions.initiatePaymentKyc.request({uid, reason_codes: reasons}));
    }

    return {initiateKyc, isActionDisabled};
}

type UseUpdateKycStatusProps = {
    actionType: 'kyc' | 'ndrp';
    id: string;
    uid: string;
    currentStatus: AccountVerificationStatus;
    accountVerificationType: AccountVerificationType;
    kycCaseId: string;
};

type KycUpdateActions = 'approve' | 'onHold' | 'reject';

type UseUpdateKycStatusResult = {
    approve: () => void;
    onHold: () => void;
    reject: () => void;
    isActionVisible: (action: KycUpdateActions) => boolean;
    isActionDisabled: (action: KycUpdateActions) => boolean;
};

export function useKYCAction({
    actionType,
    id,
    currentStatus,
    accountVerificationType,
    kycCaseId,
    uid,
}: UseUpdateKycStatusProps): UseUpdateKycStatusResult {
    const dispatch = useDispatch();
    const {isProgress} = useAsyncActionState(accountVerificationActions.updateKycStatus);

    function approve() {
        dispatch(
            accountVerificationActions.updateKycStatus.request({
                id,
                kycCaseId,
                uid,
                currentStatus,
                type: accountVerificationType,
                action: 'approve',
            })
        );
    }

    function onHold() {
        dispatch(
            accountVerificationActions.updateKycStatus.request({
                id,
                kycCaseId,
                currentStatus,
                type: accountVerificationType,
                action: 'onHold',
                uid,
            })
        );
    }

    function reject() {
        dispatch(
            accountVerificationActions.updateKycStatus.request({
                id,
                kycCaseId,
                currentStatus,
                type: accountVerificationType,
                action: 'reject',
                uid,
            })
        );
    }

    function isActionVisible(action: KycUpdateActions): boolean {
        return !(action === 'onHold' && actionType === 'ndrp');
    }

    function isActionDisabled(action: KycUpdateActions): boolean {
        return (
            kycCaseId === undefined ||
            kycCaseId === null ||
            isProgress ||
            (actionType === 'ndrp' ? isNdrpActionDisabled(action) : isPaymentOrSecurityKycActionDisabled(action))
        );
    }

    function isNdrpActionDisabled(action: KycUpdateActions): boolean {
        return (
            (currentStatus === AccountVerificationStatus.Approved && action === 'approve') ||
            (currentStatus === AccountVerificationStatus.DeniedNdrpKyc && action === 'reject')
        );
    }

    function isPaymentOrSecurityKycActionDisabled(action: KycUpdateActions): boolean {
        const pendingStatuses = [
            AccountVerificationStatus.InitDepositKyc,
            AccountVerificationStatus.InitLoginKyc,
            AccountVerificationStatus.InitWithdrawalKyc,
            AccountVerificationStatus.InitPaymentKyc,
        ];
        const onHoldStatuses = [
            AccountVerificationStatus.OnHoldDepositKyc,
            AccountVerificationStatus.OnHoldLoginKyc,
            AccountVerificationStatus.OnHoldWithdrawalKyc,
            AccountVerificationStatus.OnHoldPaymentKyc,
        ];

        let result;
        switch (action) {
            case 'onHold':
                result = !pendingStatuses.includes(currentStatus);
                break;
            case 'approve':
            case 'reject':
                result = !pendingStatuses.includes(currentStatus) && !onHoldStatuses.includes(currentStatus);
                break;
            default:
                result = true;
        }

        return result;
    }

    return {approve, onHold, reject, isActionVisible, isActionDisabled};
}

type UseKYCSettingsPorps = {
    featureName: string;
    columns: DataGridAccountVerificationWithUserProfileColumns[];
    pinnedColumns?: DataGridAccountVerificationWithUserProfileColumns[];
};

type UseKYCSettingsResult = {
    visibleColumns?: DataGridAccountVerificationWithUserProfileColumns[];
    onVisibleColumnsChange: (value: DataGridAccountVerificationWithUserProfileColumns[]) => void;
};

export function useKYCSettings({featureName, columns, pinnedColumns = []}: UseKYCSettingsPorps): UseKYCSettingsResult {
    const {sub} = useAuthUser();
    const {visible, onVisibleColumnsChange} = useColumnsSettings<AccountVerificationWithUserProfileColumnSettings>({
        userId: sub,
        featureName,
        defaultColumns: mapViewModelKeysToSettings(columns),
        pinnedColumns: mapViewModelKeysToSettings(pinnedColumns),
    });

    function mapViewModelKeysToSettings(
        keys: DataGridAccountVerificationWithUserProfileColumns[]
    ): AccountVerificationWithUserProfileColumnSettings[] {
        const mapper: Partial<Record<DataGridAccountVerificationWithUserProfileColumns, AccountVerificationWithUserProfileColumnSettings>> =
            {
                account_verification_status: 'account_verification_status',
                init_reason_codes: 'init_reason_codes',
                documents: 'documents',
                email: 'email',
                id: 'id',
                'initiated_at.seconds': 'initiated_at',
                iso_alpha2_country_code: 'playerCountry',
                kyc_case_id: null,
                status_log: null,
                type: 'type',
                uid: 'uid',
                username: 'username',
                player_country: null,
                duration: 'duration',
                serverId: null,
                platform: 'platform',
                labels: 'labels',
                'documents_object.id_card.uploaded_ts': 'documents_object.id_card.uploaded_ts',
            };

        return [...new Set(keys?.map(i => mapper[i])?.filter(i => i) ?? [])];
    }

    function mapSettingsToViewModelKeys(
        settings: AccountVerificationWithUserProfileColumnSettings[]
    ): DataGridAccountVerificationWithUserProfileColumns[] {
        const mapper: Record<AccountVerificationWithUserProfileColumnSettings, DataGridAccountVerificationWithUserProfileColumns[]> = {
            uid: ['uid'],
            initiated_at: ['initiated_at.seconds'],
            duration: ['duration'],
            account_verification_status: ['account_verification_status'],
            type: ['type'],
            documents: ['documents'],
            username: ['username'],
            init_reason_codes: ['init_reason_codes'],
            platform: ['platform'],
            id: ['id'],
            email: ['email'],
            labels: ['labels'],
            'documents_object.id_card.uploaded_ts': ['documents_object.id_card.uploaded_ts'],
            playerCountry: ['iso_alpha2_country_code'],
        };

        return [...new Set(settings?.flatMap(i => mapper[i]) ?? [])];
    }

    function handleColumnSettingsChange(fields: DataGridAccountVerificationWithUserProfileColumns[]) {
        const columns = mapViewModelKeysToSettings(fields);
        onVisibleColumnsChange(columns);
    }

    return {
        // filter is workaround for storing multiple keys in single setting key
        visibleColumns: mapSettingsToViewModelKeys(visible).filter(f => columns.includes(f)),
        onVisibleColumnsChange: handleColumnSettingsChange,
    };
}
