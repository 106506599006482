import {defineMessages, useIntl} from 'react-intl';
import {ModelIdentifier} from '@automapper/core';

import {useAutoMapper} from '@auto-mapper';
import {
    AgentPlayerReportingMappingArgs,
    AgentPlayerReportingViewModel,
    AgentPlayerReportingViewModelKeys,
} from '@models/agent-player-reporting';
import {agentReadPolicy} from '@models/permissions/permissions';
import {
    AgentPlayerReportingExtended,
    AgentPlayerReportingKrExtended,
    AgentPlayerReportingQueryFields,
    AgentPlayerReportingServerFilterKeys,
    AgentPlayerReportingVnExtended,
    AgentReportRegion,
    EntityType,
} from '@redux/entity';
import {getNonEmptyValueValidator, UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';
import {momentToTimestampSeconds} from '@utils/date';

import {Filter, Sorting} from '../../common/types';
import {useJurisdictionConfig} from '../app/config/hooks';
import {JurisdictionCurrency} from '../app/config/types';
import {usePermission} from '../app/permission/PermissionHoc';

import {getDefaultEndDate, getDefaultStartDate} from './utils';

const localized = defineMessages({
    agentPlayerReportingTotal: {
        id: 'useAgentPlayerReporting_agentPlayerReportingTotal',
        defaultMessage: 'Total',
    },
});

export function useBaseAgentPlayerReporting<TModel>(
    {
        viewType,
        fields,
        displayName,
        cleanDelay = 0,
        realtimeMode,
        triggers,
        defaultFilters,
        defaultPaging,
        defaultSorting,
        validateFilter,
        syncWithUrl = false,
    }: UseListViewEntityProps<AgentPlayerReportingServerFilterKeys, AgentPlayerReportingViewModelKeys>,
    modelType: ModelIdentifier<TModel>
): UseListViewEntityResult<AgentPlayerReportingViewModel, AgentPlayerReportingViewModelKeys> {
    const mapper = useAutoMapper();
    const {currency} = useJurisdictionConfig();

    const queryFields: AgentPlayerReportingQueryFields[] = mapFields(fields);

    const isAgent = usePermission(agentReadPolicy);
    const {
        items,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useViewInit<TModel, AgentPlayerReportingServerFilterKeys, AgentPlayerReportingQueryFields>({
        viewType,
        displayName,
        entity: {
            entity: EntityType.AgentPlayerReporting,
            fields: queryFields,
        },
        realtime: realtimeMode ? {entity: EntityType.AgentPlayerReporting, mode: realtimeMode, triggers} : null,
        defaultFilters,
        defaultSorting: mapSorting(defaultSorting),
        defaultPaging,
        syncWithUrl,
        validateFilter:
            validateFilter ?? isAgent
                ? getNonEmptyValueValidator<AgentPlayerReportingServerFilterKeys>('defaultReferrerPlayerId')
                : () => true,
        cleanDelay,
    });

    const mappingArgs: AgentPlayerReportingMappingArgs = {currency};
    const agents = items?.map(i => mapper.map(i, modelType, AgentPlayerReportingViewModel, {extraArgs: () => mappingArgs}));

    return {
        items: agents,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange: handleVmSortChange,
        handleFilterChange,
    };

    function mapFields(fields: AgentPlayerReportingViewModelKeys[]): AgentPlayerReportingQueryFields[] {
        return mapper.map<AgentPlayerReportingViewModelKeys[], AgentPlayerReportingQueryFields[]>(
            fields,
            nameof<AgentPlayerReportingViewModelKeys>(),
            nameof<AgentPlayerReportingQueryFields>()
        );
    }

    function mapField(field: AgentPlayerReportingViewModelKeys): AgentPlayerReportingQueryFields {
        return mapFields([field])[0];
    }

    function mapSorting(newSorting: Sorting<AgentPlayerReportingViewModelKeys>[]): Sorting<AgentPlayerReportingQueryFields>[] {
        return newSorting?.map(s => ({...s, field: mapField(s.field)}));
    }

    function handleVmSortChange(newSorting: Sorting<AgentPlayerReportingViewModelKeys>[]) {
        handleSortChange(mapSorting(newSorting));
    }
}

function getItemsWithSummary(
    items: AgentPlayerReportingViewModel[],
    firstColumnKey: AgentPlayerReportingViewModelKeys,
    totalLabel: string,
    currency: JurisdictionCurrency
): {items: AgentPlayerReportingViewModel[]; summary: AgentPlayerReportingViewModel} {
    const summaryItem: AgentPlayerReportingViewModel = items?.find(i => i.is_summary) ?? {id: '', is_summary: true};
    const listItems: AgentPlayerReportingViewModel[] = items?.filter(i => !i.is_summary);
    const summary: AgentPlayerReportingViewModel = listItems?.length
        ? {
              ...summaryItem,
              uid: '',
              currency,
              [firstColumnKey]: totalLabel,
          }
        : null;
    return {items: listItems, summary};
}

export function useAgentPlayerReporting(
    props: UseListViewEntityProps<AgentPlayerReportingServerFilterKeys, AgentPlayerReportingViewModelKeys>
): UseListViewEntityResult<AgentPlayerReportingViewModel, AgentPlayerReportingViewModelKeys> & {summary: AgentPlayerReportingViewModel} {
    const {currency} = useJurisdictionConfig();
    const {formatMessage} = useIntl();

    const result = useBaseAgentPlayerReporting(
        {
            ...props,
            defaultFilters: [
                {key: 'transactionStartedTs.from', value: momentToTimestampSeconds(getDefaultStartDate())},
                {key: 'transactionStartedTs.to', value: momentToTimestampSeconds(getDefaultEndDate())},
            ],
        },
        AgentPlayerReportingExtended
    );
    const {items, summary} = getItemsWithSummary(
        result.items,
        props.fields[0],
        formatMessage(localized.agentPlayerReportingTotal),
        currency
    );

    return {...result, items, summary};
}

export function useAgentPlayerReportingKr({
    defaultFilters,
    ...props
}: UseListViewEntityProps<AgentPlayerReportingServerFilterKeys, AgentPlayerReportingViewModelKeys>): UseListViewEntityResult<
    AgentPlayerReportingViewModel,
    AgentPlayerReportingViewModelKeys
> & {summary: AgentPlayerReportingViewModel} {
    const {currency} = useJurisdictionConfig();
    const {formatMessage} = useIntl();

    const country: AgentReportRegion = 'kr';
    const krDefaultFilters: Filter<any, AgentPlayerReportingServerFilterKeys>[] = [
        ...(defaultFilters ?? []),
        {key: 'region', value: country},
    ];

    const result = useBaseAgentPlayerReporting({...props, defaultFilters: krDefaultFilters}, AgentPlayerReportingKrExtended);
    const {items, summary} = getItemsWithSummary(
        result.items,
        props.fields[0],
        formatMessage(localized.agentPlayerReportingTotal),
        currency
    );

    return {...result, items, summary};
}

export function useAgentPlayerReportingVn({
    defaultFilters,
    ...props
}: UseListViewEntityProps<AgentPlayerReportingServerFilterKeys, AgentPlayerReportingViewModelKeys>): UseListViewEntityResult<
    AgentPlayerReportingViewModel,
    AgentPlayerReportingViewModelKeys
> & {summary: AgentPlayerReportingViewModel} {
    const {currency} = useJurisdictionConfig();
    const {formatMessage} = useIntl();
    const country: AgentReportRegion = 'vn';
    const vnDefaultFilters: Filter<any, AgentPlayerReportingServerFilterKeys>[] = [
        ...(defaultFilters ?? []),
        {key: 'region', value: country},
    ];

    const result = useBaseAgentPlayerReporting({...props, defaultFilters: vnDefaultFilters}, AgentPlayerReportingVnExtended);
    const {items, summary} = getItemsWithSummary(
        result.items,
        props.fields[0],
        formatMessage(localized.agentPlayerReportingTotal),
        currency
    );

    return {...result, items, summary};
}
