import {MessageDescriptor} from 'react-intl';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {ExtendedMuiGridColDef, PermissionColDef, renderCellHeader, renderCellWithTooltip} from '@components/data-grid/mui';
import {BoRole} from '@redux/entity';
import {isFutureDate} from '@utils';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import ModuleCreateAction from '../../../features/module-shared/components/ModuleCreateAction';
import {JurisdictionSelectOption, PermissionEnum, SelectOption} from '../../../features/module-shared/types';
import {renderEditableToggleCell} from '../../../features/module-temp-storage/components/editable/EditableToggle';
import {PermissionEditableColDef} from '../../../features/module-temp-storage/components/editable-components/types';
import {ModuleAction} from '../../../features/shared/actions/types';
import FilterSingleSelect from '../../../features/shared/filter/components/FilterSingleSelect';
import FilterTextWithDropdown from '../../../features/shared/filter/components/FilterTextWithDropdown';
import {Filter, FilterPlacement, MultipleKeysFilter} from '../../../features/shared/filter/types';
import actions from '../actions';
import {localizedAccessManagement as localized} from '../localize';
import {UserGridItem} from '../types';

import {renderAddPlayerIdAction} from './AddPlayerIdAction';
import {render2FAAction} from './Reset2FAAction';
import {renderResetPasswordAction} from './ResetPasswordAction';
import {renderEditAction} from './UserEditAction';

const getGridColumns = () => {
    const columns: ExtendedMuiGridColDef[] = [
        {
            field: nameof<UserGridItem>(u => u.id),
            hide: true,
        },
        {
            field: nameof<UserGridItem>(u => u.firstName),
            renderHeader: () => renderCellHeader(localized.firstName),
            width: 128,
        },
        {
            field: nameof<UserGridItem>(u => u.lastName),
            renderHeader: () => renderCellHeader(localized.lastName),
            width: 128,
        },
        {
            field: nameof<UserGridItem>(u => u.email),
            renderHeader: () => renderCellHeader(localized.email),
            width: 280,
        },
        {
            field: nameof<UserGridItem>(u => u.roles),
            renderHeader: () => renderCellHeader(localized.roles),
            sortable: false,
            valueFormatter: params => (params.value ? (params.value as BoRole[]).map(r => r.name).join() : ''),
        },
        {
            field: 'editAction',
            renderHeader: () => renderCellHeader(localized.editAction),
            renderCell: renderEditAction,
            sortable: false,
            permissions: [PermissionEnum.Update],
            moduleName: ModuleName.AccessManagement,
            submoduleName: SubmoduleName.Users,
            isEditable: true,
            width: 100,
        } as PermissionEditableColDef,
        {
            field: 'reset2FA',
            renderHeader: () => renderCellHeader(localized.reset2FAAction),
            renderCell: render2FAAction,
            sortable: false,
            permissions: [PermissionEnum.Update],
            moduleName: ModuleName.AccessManagement,
            submoduleName: SubmoduleName.Users,
            isEditable: true,
            width: 110,
        } as PermissionEditableColDef,
        {
            field: 'resetPassword',
            renderHeader: () => renderCellHeader(localized.resetPasswordAction),
            renderCell: renderResetPasswordAction,
            sortable: false,
            permissions: [PermissionEnum.Update],
            moduleName: ModuleName.AccessManagement,
            submoduleName: SubmoduleName.Users,
            isEditable: true,
            width: 150,
        } as PermissionEditableColDef,
        {
            field: 'loginAccess',
            renderHeader: () => renderCellHeader(localized.loginAccess),
            valueGetter: (params: GridValueGetterParams) => !isFutureDate((params.row as UserGridItem).lockoutEnd),
            renderCell: renderEditableToggleCell(actions.setLoginAccessStatus, localized.loginAccessEnable, localized.loginAccessDisable),
            permissions: [PermissionEnum.Update],
            moduleName: ModuleName.AccessManagement,
            submoduleName: SubmoduleName.Users,
            sortable: false,
            isEditable: true,
            idField: nameof<UserGridItem>(u => u.serverId),
            width: 220,
        } as PermissionEditableColDef,
        {
            field: 'playerId',
            renderHeader: () => renderCellHeader(localized.playerId),
            renderCell: renderAddPlayerIdAction,
            permissions: [PermissionEnum.Update],
            moduleName: ModuleName.AccessManagement,
            submoduleName: SubmoduleName.Users,
            sortable: false,
            isEditable: true,
            idField: nameof<UserGridItem>(u => u.playerId),
            width: 240,
        } as PermissionEditableColDef,
    ];

    const defaultCol: PermissionColDef = {
        field: '',
        width: 300,
        permissions: [PermissionEnum.Read],
        moduleName: ModuleName.AccessManagement,
        submoduleName: SubmoduleName.Users,
        renderCell: renderCellWithTooltip,
    };

    return columns.map(c => ({...defaultCol, ...c}));
};

/*const exportActions = [
    {
        key: toEnumString(DocumentType, DocumentType.Excel),
        name: localizedDocumentTypes[DocumentType.Excel],
    },
    {
        key: toEnumString(DocumentType, DocumentType.Pdf),
        name: localizedDocumentTypes[DocumentType.Pdf],
    },
];*/

const textSearchOptions: JurisdictionSelectOption[] = [
    {label: localized.firstName, value: nameof<UserGridItem>(w => w.firstName)},
    {label: localized.lastName, value: nameof<UserGridItem>(w => w.lastName)},
    {label: localized.email, value: nameof<UserGridItem>(w => w.email)},
    {label: localized.playerId, value: nameof<UserGridItem>(w => w.playerId)},
];

const getFilterLabel = (key: string): MessageDescriptor => {
    return {
        [nameof<UserGridItem>(w => w.firstName)]: localized.searchByFirstName,
        [nameof<UserGridItem>(w => w.lastName)]: localized.searchByLastName,
        [nameof<UserGridItem>(w => w.email)]: localized.searchByEmail,
        [nameof<UserGridItem>(w => w.playerId)]: localized.searchByPlayerId,
    }[key];
};

export const getActions = (): ModuleAction[] => [
    {
        component: () => ModuleCreateAction({openFormAction: actions.openUserForm, label: localized.addUser}),
        permissions: [PermissionEnum.Create],
        moduleName: ModuleName.AccessManagement,
        submoduleName: SubmoduleName.Users,
        placement: FilterPlacement.Secondary,
    },
    /*{
        component: () => ModuleExportAction({domain: domain, dropdownActions: exportActions}),
        permissions: [PermissionEnum.Read],
        moduleName: ModuleName.AccessManagement,
        submoduleName: SubmoduleName.Users,
        placement: FilterPlacement.Secondary,
    },*/
];

const getFilters = (roles: BoRole[]) =>
    [
        {
            keys: [
                nameof<UserGridItem>(w => w.firstName),
                nameof<UserGridItem>(w => w.lastName),
                nameof<UserGridItem>(w => w.email),
                nameof<UserGridItem>(w => w.playerId),
            ],
            component: FilterTextWithDropdown,
            options: textSearchOptions,
            placement: FilterPlacement.Primary,
            getLabel: getFilterLabel,
            limitMaxWidth: true,
        } as MultipleKeysFilter,
        {
            key: nameof<UserGridItem>(u => u.roles),
            name: localized.roles,
            label: localized.roles,
            component: FilterSingleSelect,
            options: roles?.map(r => ({label: r.name, value: r.id} as SelectOption)),
            placement: FilterPlacement.Primary,
        },
    ] as Filter[];

export default {
    getFilters: getFilters,
    columns: getGridColumns(),
    actions: getActions(),
    localized,
};
