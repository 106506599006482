import {useAutoMapper} from '@auto-mapper';
import {PlayerReferrerSummary} from '@models/generated/graphql';
import {PlayerReferrerSummaryViewModel, PlayerReferrerSummaryViewModelKeys} from '@models/player-referrer-summary/types';
import {EntityType, PlayerReferrerSummaryFilterKeys, PlayerReferrerSummaryQueryFields} from '@redux/entity';
import {RealtimeMessageTrigger} from '@redux/realtime';
import {getNonEmptyValueValidator, UseDetailsViewEntityProps, UseDetailsViewEntityResult, useViewInit} from '@redux/view';
import {updateKeyValueFilterArray} from '@utils';

import {Filter} from '../../common/types';

export function usePlayerReferrerSummaryDetails({
    id,
    defaultFilters,
    displayName,
    viewType,
    realtimeMode,
    cleanDelay,
    fields,
}: UseDetailsViewEntityProps<PlayerReferrerSummaryFilterKeys, PlayerReferrerSummaryViewModelKeys>): UseDetailsViewEntityResult<
    PlayerReferrerSummaryViewModel,
    PlayerReferrerSummaryViewModelKeys
> {
    const mapper = useAutoMapper();
    const queryFields: PlayerReferrerSummaryQueryFields[] = mapper.map(
        fields,
        nameof<PlayerReferrerSummaryViewModelKeys>(),
        nameof<PlayerReferrerSummaryQueryFields>()
    );

    const defaultFilter: Filter<unknown, PlayerReferrerSummaryFilterKeys>[] = updateKeyValueFilterArray(
        [{key: 'id', value: id ?? null}],
        ...(defaultFilters ?? [])
    );
    const {
        items,
        searchFilter,
        handleFilterChange,
        viewEntity: {filter: filterString},
    } = useViewInit<PlayerReferrerSummary, PlayerReferrerSummaryFilterKeys, PlayerReferrerSummaryQueryFields>({
        viewType,
        entity: {
            entity: EntityType.PlayerReferrerSummary,
            fields: queryFields,
        },
        displayName,
        realtime: realtimeMode
            ? {entity: EntityType.PlayerReferrerSummary, mode: realtimeMode, triggers: [RealtimeMessageTrigger.Update]}
            : null,
        defaultFilters: defaultFilter,
        validateFilter: getNonEmptyValueValidator<PlayerReferrerSummaryFilterKeys>('id'),
        cleanDelay,
    });

    const summaryModel: PlayerReferrerSummary = items?.length > 0 ? items[0] : null;
    const summaryViewModel: PlayerReferrerSummaryViewModel = summaryModel
        ? mapper.map(summaryModel, PlayerReferrerSummary, PlayerReferrerSummaryViewModel)
        : null;

    return {item: summaryViewModel, handleFilterChange, searchFilter, filterString};
}
