import React, {useCallback, useEffect, useState} from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';
import {
    DataGrid,
    DataGridProps,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridCellEditStartParams,
    GridCellEditStartReasons,
    GridColumnVisibilityModel,
    GridSortModel,
    MuiEvent,
} from '@mui/x-data-grid';
import type {} from '@mui/x-data-grid/themeAugmentation';
import {produce} from 'immer';
import {v4 as uuid} from 'uuid';

import {InfoAlert} from '@components/alerts/InfoAlert';
import {StyledCheckbox} from '@components/checkbox/StyledCheckbox';
import StyledPagination from '@components/StyledPagination';

import {IModuleGridItem} from '../types';

import {GridColumnsButton} from './GridColumnsButton';
import {GridColumnsPanel, GridColumnsPanelProps} from './GridColumnsPanel';
import {
    useColumnHeaderName,
    useGridCellStyle,
    useGridColumnConfiguration,
    useGridItemsSelected,
    useGridLocalization,
    useGridPaging,
    useGridPermissions,
    useGridSorting,
    useGridStyle,
    useJurisdictionConfigForColumns,
} from './hooks';
import {ExtendedMuiGridColDef, GridColDef, SortableColDef} from './types';

export type GridPageChangeParams = {
    page: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
    paginationMode: 'client' | 'server';
};

export type GridSortModelParams = {
    sortModel: GridSortModel;
    columns: SortableColDef[];
    api: any;
};

export type GridSelectionModelChangeParams = {
    selectionModel: (string | number)[];
};

export type MuiDataGridProps = Omit<
    DataGridProps,
    'columns' | 'pageSizeOptions' | 'paginationModel' | 'onPaginationModelChange' | 'onSortModelChange'
> & {
    rows: IModuleGridItem[];
    rowsPerPageOptions?: number[];
    page?: number;
    pageSize?: number;
    pinnedRow?: IModuleGridItem;
    columns: GridColDef[];
    domain?: string;
    style?: React.CSSProperties;
    emptyMessage?: MessageDescriptor;
    selectedItems?: IModuleGridItem[];
    defaultSortModel?: GridSortModel;
    hidePagination?: boolean;
    onSelect?: (ids: string[]) => void;
    onEdit?: (index: number, field: string, value: unknown) => void;
    hideHeader?: boolean;
    hideRows?: boolean;
    hasColumnSelection?: boolean;
    pinnedColumns?: string[];
    visibleColumns?: string[];
    onPageChange?: (params: GridPageChangeParams) => void;
    onPageSizeChange?: (params: GridPageChangeParams) => void;
    onColumnsVisibilityUpdate?: (visibleColumns: string[]) => void;
    onSortModelChange?: (params: GridSortModelParams) => void;
    onSelectionModelChange?: (params: GridSelectionModelChangeParams) => void;
    isFlexible?: boolean;
};

const MuiDataGrid = (props: MuiDataGridProps) => {
    const {classes, cx} = useGridStyle({autoHeight: props.autoHeight});
    const {localeText, components: localizationComponents} = useGridLocalization();
    const columnVisibilityModel: GridColumnVisibilityModel = props?.columnVisibilityModel ?? {};
    //paging
    const {rows, pinnedRow} = props;
    const rowsPerPageOptions = props.rowsPerPageOptions ?? pinnedRow ? [10, 25, 50, 99] : [10, 25, 50, 100];

    const {page, pageSize, paginationMode, rowCount, onPageChange, onPageSizeChange} = useGridPaging({...props, columns: props.columns});
    const [gridId] = useState<string>(uuid());

    const [visibleColumnNames, setVisibleColumnNames] = useState<string[]>(props.visibleColumns);
    const columnsToRender = [...(props.pinnedColumns ?? []), ...(visibleColumnNames ?? [])];

    useEffect(() => {
        setVisibleColumnNames(props.visibleColumns);
    }, [props.visibleColumns?.join()]);

    const namedColumns = useColumnHeaderName(props.columns);
    const {columns: styledColumns, className, rowHeight} = useGridCellStyle(namedColumns);
    const accessibleColumns = useJurisdictionConfigForColumns(styledColumns);
    const {sortModel, sortingMode, onSortModelChange} = useGridSorting({...props, columns: accessibleColumns});
    let columns = useGridPermissions(accessibleColumns);
    if (columnsToRender?.length) {
        const sortedColumns = sortColumns(columns, columnsToRender);
        columns = hideColumns(sortedColumns, columnsToRender);
    }
    columns = setColumnsWidth(columns);

    function hideColumns(columns: ExtendedMuiGridColDef[], visibleColumnNames: string[]): ExtendedMuiGridColDef[] {
        return produce(columns, draft => {
            draft.forEach((column: ExtendedMuiGridColDef) => {
                column.hide = visibleColumnNames.length ? !visibleColumnNames.includes(column.field) : column.hide ?? false;
            });
        });
    }

    function setColumnsWidth(columns: ExtendedMuiGridColDef[]): ExtendedMuiGridColDef[] {
        return produce(columns, draft => {
            draft.forEach((column: ExtendedMuiGridColDef) => {
                if (column.flex && column.width) {
                    if (props?.isFlexible === false) {
                        column.flex = undefined;
                    } else {
                        column.width = undefined;
                    }
                }
            });
        });
    }

    function sortColumns(columns: ExtendedMuiGridColDef[], visibleColumnNames: string[]): ExtendedMuiGridColDef[] {
        const sortedFieldNames: string[] = [...new Set([...visibleColumnNames, ...columns.filter(c => c).map(c => c.field)])];
        return sortedFieldNames.map<ExtendedMuiGridColDef>((name: string) => columns.find(c => c.field === name))?.filter(c => c);
    }

    function handleVisibilityUpdate(visibleColumns: string[]) {
        setVisibleColumnNames(visibleColumns);
        if (props?.onColumnsVisibilityUpdate) {
            props.onColumnsVisibilityUpdate(visibleColumns);
        }
    }

    //TODO: remove from DataGrid
    useGridColumnConfiguration(columns, props.domain);

    const {gridSelectionProps} = props.checkboxSelection
        ? useGridItemsSelected(props)
        : {
              gridSelectionProps: {},
          };
    const totalRows = pinnedRow ? [pinnedRow, ...(rows ?? [])] : rows;
    const fields = columns.map(c => c.field);
    const sortables = columns.map(c => c.sortable);
    const internalGridColumnNames: string[] = [GRID_CHECKBOX_SELECTION_COL_DEF?.field];

    columns.forEach((column: ExtendedMuiGridColDef) => (columnVisibilityModel[column.field] = !column.hide));

    const handleSortModelChange = useCallback(
        (model: GridSortModel) => onSortModelChange({sortModel: model, columns: accessibleColumns, api: undefined}),
        [onSortModelChange, `${fields}`, `${sortables}`]
    );

    const handleCellEditStart = useCallback((params: GridCellEditStartParams, event: MuiEvent<React.KeyboardEvent | React.MouseEvent>) => {
        if (params.reason === GridCellEditStartReasons.printableKeyDown || params.reason === GridCellEditStartReasons.deleteKeyDown) {
            event.defaultMuiPrevented = true;
        }
    }, []);
    return totalRows.length || !props.emptyMessage ? (
        <>
            <Box id={gridId} className={classes.dataGridWrapper} style={props.style}>
                <DataGrid
                    {...props}
                    {...gridSelectionProps}
                    localeText={localeText}
                    className={cx(
                        classes.dataGridRoot,
                        className,
                        {
                            [classes.dataGridHideHeader]: props.hideHeader,
                            [classes.dataGridHideRows]: props.hideRows,
                            [classes.dataGridWithPinnedRow]: !!props.pinnedRow,
                        },
                        props.className
                    )}
                    rows={totalRows}
                    getRowId={r => r.id ?? uuid()}
                    columns={columns}
                    columnBuffer={columns.length}
                    //sorting
                    sortModel={sortModel}
                    sortingMode={sortingMode}
                    sortingOrder={['desc', 'asc']}
                    columnVisibilityModel={columnVisibilityModel}
                    onSortModelChange={handleSortModelChange}
                    //paging
                    paginationModel={{
                        page: page,
                        pageSize: pinnedRow ? pageSize + 1 : pageSize,
                    }}
                    paginationMode={paginationMode}
                    rowCount={totalRows?.length ?? 0}
                    rowHeight={rowHeight}
                    //config
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector={!props.hasColumnSelection}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    hideFooter
                    disableColumnMenu
                    onCellEditStart={handleCellEditStart}
                    slots={{
                        ...props.slots,
                        ...localizationComponents,
                        baseCheckbox: StyledCheckbox,
                        toolbar: props.hasColumnSelection ? GridColumnsButton : null,
                        columnsPanel: props.hasColumnSelection ? GridColumnsPanel : null,
                    }}
                    slotProps={{
                        columnsPanel: {
                            allColumns: columns?.filter(c => !internalGridColumnNames.includes(c.field)),
                            pinnedColumnNames: props.pinnedColumns,
                            onColumnsVisibilityUpdate: handleVisibilityUpdate,
                        } as GridColumnsPanelProps,
                    }}
                />
            </Box>
            {!props.hidePagination && !props.hideRows ? (
                <StyledPagination
                    count={rowCount ?? 0}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={rowsPerPageOptions}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                />
            ) : null}
        </>
    ) : (
        <InfoAlert title={props.emptyMessage} className={classes.dataGridEmptyMessage} />
    );
};

export default MuiDataGrid;
