import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {OutlinedButton} from '@components/button/Buttons';
import {ChipType, ChipVariant} from '@components/chip/types';
import {OutlinedButtonDropdown} from '@components/dropdown/Dropdown';
import {DropdownChipItem} from '@components/dropdown/DropdownChipItem';
import {Icon, IconColor} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {PaymentVendor, TransactionStatus} from '@models/generated/graphql';

import {localizedReasonCode} from '../../app/intl/shared-resources/transactionReasonCode';
import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {useChangeWithdrawalStatus} from '../hooks';
import {withdrawalPaymentUpdatePermissions, withdrawalRiskUpdatePermissions} from '../permissions';

const localized = defineMessages({
    rejectionReason: {
        id: 'withdrawalChangeStatusButtons_RejectionReason',
        defaultMessage: 'Rejection reason',
    },
    withdrawalDecline: {
        id: 'WithdrawalChangeStatusButtons_withdrawalDecline',
        defaultMessage: 'Decline',
    },
    withdrawalApprove: {
        id: 'WithdrawalChangeStatusButtons_withdrawalApprove',
        defaultMessage: 'Approve',
    },
    withdrawalOnHold: {
        id: 'WithdrawalChangeStatusButtons_withdrawalOnHold',
        defaultMessage: 'On Hold',
    },
});

type WithdrawalChangeStatusButtonsProps = {
    transactionId: string;
    transactionStatus: TransactionStatus;
    isRiskStatus: boolean;
    disabled?: boolean;
    uid: string;
};

function WithdrawalChangeStatusButtonsInner({
    transactionId,
    transactionStatus,
    isRiskStatus,
    disabled,
    uid,
}: WithdrawalChangeStatusButtonsProps) {
    const {formatMessage} = useIntl();
    const {handleChangeStatus, isApproveDisabled, isOnHoldDisabled, isDeclineDisabled, rejectReasons} = useChangeWithdrawalStatus(
        transactionId,
        transactionStatus,
        isRiskStatus,
        uid
    );

    return (
        <>
            <OutlinedButton
                onClick={() => handleChangeStatus('Approve')}
                label={formatMessage(localized.withdrawalApprove)}
                startIcon={<Icon icon={CustomIcon.Confirm} color={IconColor.Success} />}
                disabled={disabled || isApproveDisabled}
                fullWidth
            />
            <OutlinedButton
                onClick={() => handleChangeStatus('OnHold')}
                label={formatMessage(localized.withdrawalOnHold)}
                startIcon={<Icon icon={CustomIcon.ClockOutline} color={IconColor.InProgress} />}
                disabled={disabled || isOnHoldDisabled}
                fullWidth
            />
            <OutlinedButtonDropdown
                label={formatMessage(localized.withdrawalDecline)}
                startIcon={<Icon icon={CustomIcon.CancelOutline} />}
                listTitle={localized.rejectionReason}
                disabled={disabled || isDeclineDisabled}
                fullWidth
            >
                {rejectReasons.map(reason => (
                    <DropdownChipItem
                        key={reason}
                        onClick={() => handleChangeStatus('Decline', reason)}
                        value={formatMessage(localizedReasonCode[reason])}
                        type={ChipType.Mark}
                        variant={ChipVariant.DropdownDefault}
                        data-testid={`${reason}_listItem`}
                    />
                ))}
            </OutlinedButtonDropdown>
        </>
    );
}

export const WithdrawalChangeRiskStatusButtons = withMultiplePermission(WithdrawalChangeStatusButtonsInner, {
    allowedPermissions: withdrawalRiskUpdatePermissions,
    indicator: LackOfPermissionIndicator.Disabled,
});

export const WithdrawalChangePaymentStatusButtons = withMultiplePermission(WithdrawalChangeStatusButtonsInner, {
    allowedPermissions: withdrawalPaymentUpdatePermissions,
    indicator: LackOfPermissionIndicator.Disabled,
});

type WithdrawalChangeStatusActionsProps = {
    transactionId: string;
    transactionStatus: TransactionStatus;
    uid: string;
    paymentVendor: PaymentVendor;
};

export function WithdrawalChangeStatusActions({transactionId, transactionStatus, paymentVendor, uid}: WithdrawalChangeStatusActionsProps) {
    const isRiskStatus = [TransactionStatus.Pending, TransactionStatus.OnHoldRisk].includes(transactionStatus);
    const isStatusChangeDisabled = [PaymentVendor.PremierCashier].includes(paymentVendor);

    return isRiskStatus ? (
        <WithdrawalChangeRiskStatusButtons
            uid={uid}
            transactionId={transactionId}
            transactionStatus={transactionStatus}
            isRiskStatus={isRiskStatus}
            disabled={transactionStatus === null || isStatusChangeDisabled}
        />
    ) : (
        <WithdrawalChangePaymentStatusButtons
            uid={uid}
            transactionId={transactionId}
            transactionStatus={transactionStatus}
            isRiskStatus={isRiskStatus}
            disabled={transactionStatus === null || isStatusChangeDisabled}
        />
    );
}
