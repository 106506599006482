import {defineMessages} from 'react-intl';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    MuiDataGridClient,
    MuiDataGridServer,
    renderCellHeader,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';

import {RuleViewModel, RuleViewModelKeys} from '../types';

import {EditDeleteActionsCellProps, renderEditDeleteActionsCell} from './EditDeleteActionsCell';
import {OrderCellProps, renderOrderCell} from './OrderCell';
import {renderRuleActiveToggleCell, RuleActiveToggleCellProps} from './RuleActiveToggleCell';

const localizedHeaders = defineMessages({
    order: {
        id: 'DataGridRuleConfigFactory_order',
        defaultMessage: 'Order',
    },
    name: {
        id: 'DataGridRuleConfigFactory_name',
        defaultMessage: 'Rule name',
    },
    updated_at: {
        id: 'DataGridRuleConfigFactory_updatedAt',
        defaultMessage: 'Last Updated',
    },
    active_status: {
        id: 'DataGridRuleConfigFactory_activeStatus',
        defaultMessage: 'Activation',
    },
});

export class DataGridRuleConfigFactory extends DataGridEntityConfigFactory<RuleViewModelKeys, RuleViewModel> {
    getColumnConfig(): Partial<Record<RuleViewModelKeys, DataGridEntityColDef<RuleViewModelKeys>>> {
        const columnsMapping: Partial<Record<RuleViewModelKeys, DataGridEntityColDef<RuleViewModelKeys>>> = {
            order: {
                field: 'order',
                valueGetter: p => this.getOrderCellProps(p),
                renderHeader: () => renderCellHeader(localizedHeaders.order),
                renderCell: renderOrderCell,
                sortable: false,
                flex: 0.3,
            },
            name: {
                field: 'name',
                valueGetter: p => this.getName(p),
                renderHeader: () => renderCellHeader(localizedHeaders.name),
                sortable: false,
                flex: 1,
            },
            status: {
                field: 'status',
                renderHeader: () => renderCellHeader(localizedHeaders.active_status),
                valueGetter: p => this.getRuleActivateToggleCellProps(p),
                renderCell: renderRuleActiveToggleCell,
                sortable: false,
                flex: 1,
            },
            actions: {
                field: 'actions',
                headerName: ' ',
                valueGetter: p => this.getGridActionsProps(p),
                renderCell: renderEditDeleteActionsCell,
                sortable: false,
                flex: 0.7,
            },
        };

        return columnsMapping;
    }

    private getOrderCellProps(p: GridValueGetterParams): OrderCellProps {
        return {id: this.getRow(p)?.id, order: this.getRow(p)?.order, total: this.getRowsCount(p)};
    }

    private getName(p: GridValueGetterParams) {
        return this.getRow(p)?.name;
    }

    private getGridActionsProps(p: GridValueGetterParams): EditDeleteActionsCellProps {
        return {id: this.getRow(p)?.id, name: this.getRow(p)?.name};
    }

    private getRuleActivateToggleCellProps(p: GridValueGetterParams): RuleActiveToggleCellProps {
        return {ruleId: this.getRow(p)?.id, value: this.getRow(p)?.status};
    }
}

export const DataGridRuleClient = withDataGridEntityClient<RuleViewModelKeys, RuleViewModel>(MuiDataGridClient, () =>
    new DataGridRuleConfigFactory().getColumnConfig()
);

export const DataGridRuleServer = withDataGridEntityServer<RuleViewModelKeys, RuleViewModel>(MuiDataGridServer, () =>
    new DataGridRuleConfigFactory().getColumnConfig()
);
