import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Controller, DeepPartial, Path, UnpackNestedValue, useForm} from 'react-hook-form';
import {useMediaQuery} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import Button, {IconButton} from '@components/button/Buttons';
import {MultiStepFilterDrawer} from '@components/filter/MultiStepFilterDrawer';
import {FilterProps, FilterUpdateMode} from '@components/filter/types';
import {CustomIcon, Icon} from '@components/icons';
import {CustomTheme, useCustomTheme} from '@style';
import {sharedLocalization} from '@localization';

const useStyles = makeStyles()((theme: CustomTheme) => {
    const gridGap = theme.spacing(1.25);
    return {
        filterGroupRootContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: gridGap,
            width: '100%',
        },
        filterGroupSixColumnContainer: {
            display: 'grid',
            gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
            gap: gridGap,
            alignItems: 'center',

            [theme.breakpoints.down('md')]: {
                display: 'flex',
            },
        },
        filterGroupContainer: {
            display: 'flex',
            flex: '1',
            gap: gridGap,
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                display: 'flex',
            },
        },
        filterGroupContainerFullWidth: {
            '& :first-child': {
                flexGrow: 'inherit',
            },
        },
        filterGroupContainerOnSubmit: {
            flex: 'unset',
        },
        filterGroupContainerFilter: {
            [theme.breakpoints.down('md')]: {
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto',
            },
        },
        filterGroupButtonContainer: {
            display: 'inline-flex',
            gap: theme.spacing(1.25),

            [theme.breakpoints.down('md')]: {
                flexWrap: 'wrap',
            },
        },
        filterGroupButton: {
            borderRadius: theme.shape.borderRadius * 2,
            maxHeight: theme.custom.denseButtonHeight,
        },
        filterGroupButtonIcon: {
            fontSize: `${theme.spacing(1.5)} !important`,
        },
        filterGroupExpandButton: {
            minWidth: 'unset',
            color: theme.palette.primary.main,
        },
        // TODO: [IGP-4877] Temporary solution for adding an empty space between primary and secondary filters
        __emptySpace: {
            width: '100%',
        },
    };
});

// TODO: [IGP-4877] Temporary solution for adding an empty space between primary and secondary filters
export const __empty_space_between_filters = '__empty_space';

export type FilterGroupNewFilter<TModel, TFilterName extends string> = {
    modelField: Path<TModel>;
    component: React.ComponentType<FilterProps<unknown>>;
    componentMobile?: React.ComponentType<FilterProps<unknown>>;
    collapseOnMobile?: boolean;
    filterName: TFilterName;
};

export type FilterGroupNewProps<TModel, TFilterName extends string> = {
    model: TModel;
    onChange: (model: TModel) => void;
    mode: FilterUpdateMode;
    allFilters: FilterGroupNewFilter<TModel, TFilterName>[];
    availableFilters: TFilterName[];
    groupContainerFullWidth?: boolean;
    viewMode?: 'flex' | 'six-column-view';
    submitButton?: React.ReactNode;
};

export function FilterGroupNew<TModel extends object, TFilterName extends string>({
    availableFilters,
    allFilters,
    model,
    mode,
    onChange,
    submitButton,
    groupContainerFullWidth = true,
    viewMode = 'flex',
}: FilterGroupNewProps<TModel, TFilterName>) {
    const {classes, cx} = useStyles();
    const theme = useCustomTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const maxVisibleItemsCountDesktop = 12;

    const [expandedDesktop, setExpandedDesktop] = useState(availableFilters?.length < maxVisibleItemsCountDesktop);
    const [expandedMobile, setExpandedMobile] = useState(false);
    const visibleFilters = getVisibleFilters();
    const collapsedFiltersMobile = getCollapsedFiltersMobile();
    const hasCollapsedFilters = collapsedFiltersMobile.length > 0;
    const hasMoreAvailableFiltersDesktop = !isMobile && availableFilters.length > maxVisibleItemsCountDesktop;
    const form = useForm<TModel>({defaultValues: model as UnpackNestedValue<DeepPartial<TModel>>});
    const formElement = useRef<HTMLFormElement>(null);

    const visibleFilterComponents = useMemo(() => visibleFilters.map(getFilterComponent), [visibleFilters?.join()]);
    const collapsedFiltersMobileComponents = useMemo(
        () => collapsedFiltersMobile?.map(getFilterComponent),
        [collapsedFiltersMobile?.join()]
    );

    useEffect(() => {
        //NOTE: This is a workaround for the case when input value changes by clearing it with the "x" button
        //      In this case the React synthetic event is not triggered.
        //      As a workaround we trigger the change event for the form manually when the input value changes.
        function handleFormChange() {
            if (mode === 'update-on-change') {
                setTimeout(() => {
                    const values = form.getValues();
                    handleSubmit(values);
                }, 0);
            }
        }

        formElement.current?.addEventListener('change', handleFormChange);

        return () => {
            formElement.current?.removeEventListener('change', handleFormChange);
        };
    }, []);

    useEffect(() => {
        // Reset form if auto-rendered and model prop changed without user participation
        // For example: new data was uploaded from server.
        if (isModelUpdated() && !form.formState.isDirty) {
            form.reset(model as UnpackNestedValue<DeepPartial<TModel>>, {keepDefaultValues: true});
        }
    }, [isModelUpdated(), form.formState.isDirty]);

    function handleExpandDesktop() {
        setExpandedDesktop(true);
    }

    function handleCollapseDesktop() {
        setExpandedDesktop(false);
    }

    function handleExpandMobile() {
        setExpandedMobile(true);
    }

    function handleCollapseMobile() {
        setExpandedMobile(false);
    }

    function handleSubmit(model: UnpackNestedValue<TModel>) {
        if (onChange) {
            onChange(model as TModel);
        }
    }

    function getVisibleFilters(): TFilterName[] {
        return isMobile ? getVisibleFiltersMobile() : getVisibleFiltersDesktop(expandedDesktop);
    }

    function getVisibleFiltersMobile(): TFilterName[] {
        const res = availableFilters.filter(f => !allFilters.find(af => af.filterName === f)?.collapseOnMobile);
        return res;
    }

    function getCollapsedFiltersMobile(): TFilterName[] {
        return isMobile ? availableFilters.filter(f => allFilters.find(af => af.filterName === f)?.collapseOnMobile) : [];
    }

    function getVisibleFiltersDesktop(expanded: boolean): TFilterName[] {
        const res = expanded ? availableFilters : availableFilters.slice(0, maxVisibleItemsCountDesktop);
        return res;
    }

    function getFilterComponent(filterName: TFilterName) {
        const {component, modelField, componentMobile} = allFilters.find(f => f.filterName === filterName);
        const FilterComponent = isMobile && componentMobile ? componentMobile : component;
        return (
            <div
                className={cx(classes.filterGroupContainerFilter, {[classes.__emptySpace]: filterName === __empty_space_between_filters})}
                key={filterName}
            >
                <Controller
                    render={({field}) => (
                        <FilterComponent
                            key={field.name}
                            value={field.value}
                            onChange={e => {
                                formElement.current?.dispatchEvent(new Event('change'));
                                return field.onChange(e);
                            }}
                            mode={mode}
                        />
                    )}
                    name={modelField}
                    control={form.control}
                />
            </div>
        );
    }

    function isModelUpdated() {
        const values = form.getValues();
        return JSON.stringify(model) !== JSON.stringify(values);
    }

    const updateOnSubmitButton = submitButton ?? (
        <Button
            type="submit"
            color="primary"
            label={sharedLocalization.submitButtonLabel}
            className={classes.filterGroupButton}
            startIcon={<Icon icon={CustomIcon.Search} className={classes.filterGroupButtonIcon} />}
        />
    );

    return (
        <form ref={formElement} className={classes.filterGroupRootContainer} onSubmit={form.handleSubmit(handleSubmit)}>
            <div
                className={
                    viewMode === 'six-column-view'
                        ? classes.filterGroupSixColumnContainer
                        : cx(classes.filterGroupContainer, {
                              [classes.filterGroupContainerFullWidth]: groupContainerFullWidth,
                              [classes.filterGroupContainerOnSubmit]: mode === 'update-on-submit',
                          })
                }
            >
                {visibleFilterComponents}
                {hasMoreAvailableFiltersDesktop || mode === 'update-on-submit' || hasCollapsedFilters ? (
                    <div className={classes.filterGroupButtonContainer}>
                        {hasMoreAvailableFiltersDesktop ? (
                            expandedDesktop ? (
                                <Button
                                    label={sharedLocalization.collapseButtonLabel}
                                    onClick={handleCollapseDesktop}
                                    className={cx(classes.filterGroupExpandButton, classes.filterGroupButton)}
                                    endIcon={<Icon icon={CustomIcon.ArrowUp} className={classes.filterGroupButtonIcon} />}
                                />
                            ) : (
                                <Button
                                    label={sharedLocalization.expandButtonLabel}
                                    onClick={handleExpandDesktop}
                                    className={cx(classes.filterGroupExpandButton, classes.filterGroupButton)}
                                    endIcon={<Icon icon={CustomIcon.ArrowDown} className={classes.filterGroupButtonIcon} />}
                                />
                            )
                        ) : null}
                        {mode === 'update-on-submit' ? updateOnSubmitButton : null}

                        {hasCollapsedFilters ? (
                            <>
                                <IconButton
                                    round
                                    iconClassName={CustomIcon.Filter}
                                    iconFontSize={16}
                                    onClick={expandedMobile ? handleCollapseMobile : handleExpandMobile}
                                ></IconButton>
                            </>
                        ) : null}
                    </div>
                ) : null}
            </div>
            {expandedMobile ? (
                <MultiStepFilterDrawer isOpen={expandedMobile} onClose={handleCollapseMobile} onOpen={handleExpandMobile}>
                    {collapsedFiltersMobileComponents}
                </MultiStepFilterDrawer>
            ) : null}
        </form>
    );
}
