import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {usePolicyAccessCheck} from '@access-control';
import {useAutoMapper} from '@auto-mapper';
import {ToggleGroup} from '@components/toggle';
import {AccountVerificationViewModelKeys} from '@models/account-verification';
import {AccountVerificationType} from '@models/generated/graphql';
import {agentReadPolicies} from '@models/permissions/permissions';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {AccountVerificationFilterKeys} from '@redux/entity';
import {RealtimeUpdatesMode} from '@redux/realtime';
import {getNonEmptyValueValidator, ViewType} from '@redux/view';

import {useMultiplePermissions} from 'src/features/app/permission/PermissionHoc';
import {InitiatePaymentKyc} from 'src/features/block-account-verification';
import {ManualTransactionAddButton} from 'src/features/block-transaction-actions';
import {Filter} from '../../../common/types';
import {useJurisdictionFeature} from '../../app/config/hooks';
import {FeatureName} from '../../app/config/types';
import {useAccountVerificationDetails} from '../../block-account-verification/hooks';
import {useUserProfileDetails} from '../../block-user-profile-details';
import {
    accountActionsReadPermissions,
    financeActionsReadPermissions,
    gamesActionsReadPermissions,
    p2pActionReadResources,
} from '../permissions';

import {AccountLock} from './AccountLock';
import {AssignSecurityCaseIdButton} from './AssignSecurityCaseIdButton';
import {CasinoLock} from './CasinoLock';
import {DepositLock} from './DepositLock';
import {LobbyLock} from './LobbyLock';
import {P2PTransferLock} from './P2PTransferLock';
import {SendMessageCommunicationAction} from './SendMessageCommunicationAction';
import {SportsbookLock} from './SportsbookLock';
import {WithdrawalLock} from './WithdrawalLock';

const localized = defineMessages({
    displayName: {
        id: 'UserProfileActionsCollapsed_displayName',
        defaultMessage: 'User Profile Actions',
    },
    accessTitle: {
        id: 'UserProfileActionsCollapsed_accessTitle',
        defaultMessage: 'Account / Financial Lock',
    },
    featureTitle: {
        id: 'UserProfileActionsCollapsed_featureTitle',
        defaultMessage: 'Feature Lock',
    },
});

export type AccessLocks =
    | 'account-status'
    | 'deposit-status'
    | 'lobby-status'
    | 'withdrawal-status'
    | 'casino-status'
    | 'sportsbook-status';
export type FeatureLocks = 'p2p-transfer-status';
export type UserProfileActionLocks = AccessLocks | FeatureLocks;
export type UserProfileAction =
    | UserProfileActionLocks
    | 'init-payment-kyc'
    | 'send-message'
    | 'assign-security-case-id'
    | 'manual-transaction';

export type UserProfileActionsProps = {
    uid: string;
    includeGroupTitle?: boolean;
    actions?: UserProfileAction[];
};

export type LockActionProps<T extends string = string> = {
    uid: string;
    status: T;
    disabled?: boolean;
};

export function UserProfileActions({uid, actions, includeGroupTitle: includeTitle}: UserProfileActionsProps) {
    const viewType: ViewType = 'UserProfileActions';
    const accessLocks: AccessLocks[] = [
        'account-status',
        'deposit-status',
        'lobby-status',
        'withdrawal-status',
        'casino-status',
        'sportsbook-status',
    ];
    const featureLocks: FeatureLocks[] = ['p2p-transfer-status'];
    const mapper = useAutoMapper();
    const userProfileFields: UserProfileViewModelKeys[] = mapper.map<UserProfileAction[], UserProfileViewModelKeys[]>(
        actions,
        nameof<UserProfileAction>(),
        nameof<UserProfileViewModelKeys>()
    );
    const accountVerificationFields: AccountVerificationViewModelKeys[] = mapper.map<
        UserProfileAction[],
        AccountVerificationViewModelKeys[]
    >(actions, nameof<UserProfileAction>(), nameof<AccountVerificationViewModelKeys>());
    const {formatMessage} = useIntl();

    const isAccountVerificationAvailable = useMultiplePermissions({restrictedPermissions: agentReadPolicies});
    const {item: user} = useUserProfileDetails({
        id: uid,
        viewType,
        fields: userProfileFields,
        realtimeMode: RealtimeUpdatesMode.Confirm,
        displayName: localized.displayName,
    });
    const {item: accountVerification} = useAccountVerificationDetails({
        viewType,
        fields: accountVerificationFields,
        displayName: localized.displayName,
        realtimeMode: RealtimeUpdatesMode.Silent,
        defaultFilters: [
            {key: 'uid', value: uid},
            {key: 'type', value: [AccountVerificationType.Payment]},
        ],
        validateFilter: (filter: Filter<any, AccountVerificationFilterKeys>[]) =>
            isAccountVerificationAvailable && getNonEmptyValueValidator<AccountVerificationFilterKeys>('uid')(filter),
    });

    const isUserProfileLocksAvailable = useMultiplePermissions({
        allowedPermissions: [...financeActionsReadPermissions, ...accountActionsReadPermissions, ...gamesActionsReadPermissions],
    });
    const isFeatureAvailable = useJurisdictionFeature({featureName: FeatureName.P2POptionsHideForPHRoles});
    const isFinanceActionsAvailable = isFeatureAvailable
        ? usePolicyAccessCheck(p2pActionReadResources)
        : useMultiplePermissions({
              allowedPermissions: financeActionsReadPermissions,
          });

    return (
        <>
            {isUserProfileLocksAvailable && (
                <ToggleGroup title={includeTitle && accessLocks?.some(a => actions?.includes(a)) && formatMessage(localized.accessTitle)}>
                    {actions?.includes('account-status') && <AccountLock uid={uid} status={user?.account_status} />}
                    {actions?.includes('deposit-status') && <DepositLock uid={uid} status={user?.deposit_status} />}
                    {actions?.includes('lobby-status') && <LobbyLock uid={uid} status={user?.lobby_access_status} />}
                    {actions?.includes('withdrawal-status') && <WithdrawalLock uid={uid} status={user?.withdrawal_status} />}
                    {actions?.includes('casino-status') && <CasinoLock uid={uid} status={user?.casino_status} />}
                    {actions?.includes('sportsbook-status') && <SportsbookLock uid={uid} status={user?.sportsbook_status} />}
                </ToggleGroup>
            )}
            {isFinanceActionsAvailable && (
                <ToggleGroup title={includeTitle && featureLocks?.some(a => actions?.includes(a)) && formatMessage(localized.featureTitle)}>
                    {actions?.includes('p2p-transfer-status') && <P2PTransferLock uid={uid} status={user?.p2p_transfer_status} />}
                </ToggleGroup>
            )}
            {actions?.includes('assign-security-case-id') && (
                <AssignSecurityCaseIdButton uid={user?.uid} securityCases={user?.security_cases} />
            )}
            {actions?.includes('init-payment-kyc') && (
                <InitiatePaymentKyc
                    uid={uid}
                    status={accountVerification?.account_verification_status}
                    reasons={accountVerification?.init_reason_codes}
                    disabled={uid === undefined}
                />
            )}
            {actions?.includes('send-message') && <SendMessageCommunicationAction uid={user?.uid} />}
            {actions?.includes('manual-transaction') && <ManualTransactionAddButton uid={user?.uid} />}
        </>
    );
}
