import {defineMessages} from 'react-intl';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {
    AmountCurrencyCellProps,
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    MuiDataGridClient,
    MuiDataGridServer,
    renderCellHeader,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import {TransactionEditableViewModel, TransactionViewModelEditableKeys} from '@models/transaction/types';

import {DataGridTransactionConfigFactory} from './DataGridTransaction';
import {renderP2PTransferAmountEditableCell} from './TransactionAmount';
import {renderP2PTransferDirectionEditableCell} from './TransactionType';

const localized = defineMessages({
    transactionsGridP2PDirection: {
        id: 'DataGridTransaction_P2PDirection',
        defaultMessage: 'Direction',
    },
    transactionsGridTransferAmount: {
        id: 'DataGridTransaction_TransferAmount',
        defaultMessage: 'Transfer Amount',
    },
});

export class DataGridTransactionEditableConfigFactory extends DataGridEntityConfigFactory<
    TransactionViewModelEditableKeys,
    TransactionEditableViewModel
> {
    getColumnConfig(): Partial<Record<TransactionViewModelEditableKeys, DataGridEntityColDef<TransactionViewModelEditableKeys>>> {
        const columnsMapping: Partial<Record<TransactionViewModelEditableKeys, DataGridEntityColDef<TransactionViewModelEditableKeys>>> = {
            p2p_direction_editable: {
                field: 'p2p_direction_editable',
                valueGetter: p => this.getType(p),
                renderHeader: () => renderCellHeader(localized.transactionsGridP2PDirection),
                renderCell: renderP2PTransferDirectionEditableCell,
                renderEditCell: renderP2PTransferDirectionEditableCell,
                flex: 0.15,
                editable: true,
                sortable: false,
            },
            p2p_transfer_amount_editable: {
                field: 'p2p_transfer_amount_editable',
                valueGetter: p => this.getP2PTransferAmount(p),
                renderHeader: () => renderCellHeader(localized.transactionsGridTransferAmount),
                renderCell: renderP2PTransferAmountEditableCell,
                renderEditCell: renderP2PTransferAmountEditableCell,
                flex: 0.15,
                editable: true,
                sortable: false,
            },
        };

        return columnsMapping;
    }

    private getType(p: GridValueGetterParams) {
        return this.getRow(p)?.transaction_type;
    }

    private getP2PTransferAmount(p: GridValueGetterParams) {
        const value: AmountCurrencyCellProps = {amount: this.getRow(p)?.amount, currency: this.getCurrency(p)};
        return value;
    }

    private getCurrency(p: GridValueGetterParams) {
        return this.getRow(p)?.currency;
    }
}

export const DataGridTransactionsEditableClient = withDataGridEntityClient<TransactionViewModelEditableKeys, TransactionEditableViewModel>(
    MuiDataGridClient,
    () => ({
        ...new DataGridTransactionConfigFactory().getColumnConfig(),
        ...new DataGridTransactionEditableConfigFactory().getColumnConfig(),
    })
);

export const DataGridTransactionsEditableServer = withDataGridEntityServer<TransactionViewModelEditableKeys, TransactionEditableViewModel>(
    MuiDataGridServer,
    () => ({
        ...new DataGridTransactionConfigFactory().getColumnConfig(),
        ...new DataGridTransactionEditableConfigFactory().getColumnConfig(),
    })
);
