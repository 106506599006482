import {createSelector} from 'reselect';
import {RootState} from 'typesafe-actions';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {Module, Submodule} from '../../features/modules/types';

export const filterSelector = (state: RootState) => state.roles.data.state.filter;

const oldModules: ModuleName[] = [
    ModuleName.ActionCenter,
    ModuleName.AccessManagement,
    ModuleName.Comments,
    ModuleName.CustomerSupport,
    ModuleName.KycProcessing,
    ModuleName.LegalContentManagement,
    ModuleName.PlayerManagement,
    ModuleName.SecurityWorkspace,
    ModuleName.WithdrawalProcessing,
];
const pageModules: ModuleName[] = [
    ModuleName.MarketingFunction,
    ModuleName.PageAgentReport,
    ModuleName.PageAgentReportVn,
    ModuleName.PageAgentReportKr,
    ModuleName.TeamWorkspace,
    ModuleName.GameAdmin,
    ModuleName.PageRules,
    ModuleName.PageNdrp,
    ModuleName.PageSportsbookOperation,
    ModuleName.PageEmbeddedReport,
];
const entityModules: ModuleName[] = [
    ModuleName.EntityAll,
    ModuleName.EntityGameTable,
    ModuleName.EntityGameTemplate,
    ModuleName.EntityTransactions,
    ModuleName.EntityBonus,
    ModuleName.EntityBulk,
    ModuleName.EntityRule,
    ModuleName.EntityReport,
];
const businessRulesModules: ModuleName[] = [
    ModuleName.Agent,
    ModuleName.AgentVn,
    ModuleName.AgentKr,
    ModuleName.Club,
    ModuleName.WptGlobal,
    ModuleName.WptAsia,
    ModuleName.WptPH,
];

// TODO: Remove when old submodules will be removed from auth service
function getFilteredSubmodules(submodules: Submodule[]) {
    const availableSubmodules: string[] = [
        // Player Actions
        SubmoduleName.AccountActions,
        SubmoduleName.FinanceActions,
        SubmoduleName.CommunicationActions,
        SubmoduleName.GamesActions,
        SubmoduleName.BlacklistActions,
        SubmoduleName.PermanentBans,
        SubmoduleName.Cases,
        SubmoduleName.Verification,
        SubmoduleName.P2PAction,

        // Login/Access Management
        SubmoduleName.Roles,
        SubmoduleName.Users,

        // Comments
        SubmoduleName.NotesPlayer,
        SubmoduleName.NotesBotDetection,
        SubmoduleName.NotesSecurity,
        SubmoduleName.NotesCustomerSupport,
        SubmoduleName.Payment,
        SubmoduleName.KYC,
        SubmoduleName.ActionCenter,

        // Legal Content Management
        SubmoduleName.PrivacyPolicy,
        SubmoduleName.TermsAndConditions,
        SubmoduleName.UserAcceptanceVersion,

        // Player Management
        SubmoduleName.RegistrationInformation,
        SubmoduleName.LoginHistory,
        SubmoduleName.TransactionHistory,

        // Withdrawal Processing
        SubmoduleName.WithdrawalProcessingRisk,
    ];

    return submodules?.filter(s => availableSubmodules.includes(s.name));
}

export const oldModulesSelector = createSelector<RootState, Module[], Module[]>(
    (state: RootState) => state.roles.additional.modules,
    modules => modules.filter(m => oldModules.some(h => h === m.name)).map(m => ({...m, submodules: getFilteredSubmodules(m.submodules)}))
);

export const pageModulesSelector = createSelector<RootState, Module[], Module[]>(
    (state: RootState) => state.roles.additional.modules,
    modules => modules.filter(m => pageModules.some(h => h === m.name))
);

export const entityModulesSelector = createSelector<RootState, Module[], Module[]>(
    (state: RootState) => state.roles.additional.modules,
    modules => modules.filter(m => entityModules.some(h => h === m.name))
);

export const businessRulesModulesSelector = createSelector<RootState, Module[], Module[]>(
    (state: RootState) => state.roles.additional.modules,
    modules => modules.filter(m => businessRulesModules.some(h => h === m.name))
);

export const submodulesSelector = (state: RootState) => state.roles.additional.submodules;
