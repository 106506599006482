import React from 'react';
import {makeStyles} from 'tss-react/mui';

import {CustomIcon, Icon} from '@components/icons';
import {FormTextInputCleanable} from '@components/input';

import {getFilterValueFromKey} from '../utils';

import withModuleFilter, {FilterInnerProps} from './ModuleFilterHoc';

const useStyles = makeStyles()(theme => ({
    filterTextInputStartIcon: {
        color: theme.palette.secondary.main,
        fontSize: 12,
    },
}));

function FilterTextInputInner({filter, value, onSubmit}: FilterInnerProps<string>) {
    const {classes} = useStyles();

    const key = filter.key as string;
    const currentValue = getFilterValueFromKey(key, value);
    const defaultValue = (filter?.defaultValue ?? '') as string;

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target?.value;
        onSubmit([{key: filter.key, value}]);
    }

    function clear() {
        onSubmit([{key: filter.key, value: ''}]);
    }

    return (
        <FormTextInputCleanable
            value={currentValue ?? defaultValue}
            placeholder={filter.label}
            startAdornment={<Icon icon={CustomIcon.Search} className={classes.filterTextInputStartIcon} />}
            style={{marginBottom: 0}}
            onChange={handleChange}
            clear={clear}
        />
    );
}

export const FilterTextInput = withModuleFilter(FilterTextInputInner);
