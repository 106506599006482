import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {
    LabelGroup,
    LabelGroupFilter,
    LabelGroupInput,
    LabelInput,
    Mutation,
    MutationAddLabelArgs,
    MutationAddLabelGroupArgs,
    MutationUpdateLabelArgs,
    MutationUpdateLabelGroupArgs,
    QueryGetLabelGroupsArgs,
} from '@models/generated/graphql';
import {Filter, LabelGroupFilterKeys, LabelGroupQueryFields, LabelGroupTextFilterKeys} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';

import {DynamicConfig} from 'src/configuration';
import {JurisdictionConfigService} from 'src/features/app/config/services';
import {ServiceTypes} from '../inversify';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';
import {GqlMutationRequest, ServiceResponsePayload} from './types';

export interface ILabelGroupService {
    addLabelGroup(labelGroup: LabelGroupInput): Observable<ServiceResponsePayload<GqlMutationRequest, LabelGroup>>;

    updateLabelGroup(id: string, labelGroup: LabelGroupInput): Observable<ServiceResponsePayload<GqlMutationRequest, LabelGroup>>;

    addLabel(label: LabelInput): Observable<ServiceResponsePayload<GqlMutationRequest, LabelGroup>>;

    updateLabel(id: string, label: LabelInput): Observable<ServiceResponsePayload<GqlMutationRequest, LabelGroup>>;
}

@injectable()
export class LabelGroupService
    extends EntityBaseGqlService<QueryGetLabelGroupsArgs, LabelGroupQueryFields, LabelGroupFilterKeys>
    implements ILabelGroupService
{
    constructor(
        @inject(ServiceTypes.ApolloClientIGPMocked) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.JurisdictionConfigService) jurisdictionConfigService: JurisdictionConfigService,
        @inject(ServiceTypes.Config) config: DynamicConfig
    ) {
        super(client, mapper, new LabelGroupRequestBuilder(jurisdictionConfigService, config));
    }

    public addLabelGroup(labelGroup: LabelGroupInput): Observable<ServiceResponsePayload<GqlMutationRequest, LabelGroup>> {
        return this._service
            .mutate<Mutation, MutationAddLabelGroupArgs>(this.getAddLabelGroupMutation(), {label_group: labelGroup})
            .pipe(map(res => ({...res, responsePayload: res.responsePayload?.addLabelGroup})));
    }

    public updateLabelGroup(id: string, labelGroup: LabelGroupInput): Observable<ServiceResponsePayload<GqlMutationRequest, LabelGroup>> {
        return this._service
            .mutate<Mutation, MutationUpdateLabelGroupArgs>(this.getUpdateLabelGroupMutation(), {id, label_group: labelGroup})
            .pipe(map(res => ({...res, responsePayload: res.responsePayload?.updateLabelGroup})));
    }

    public addLabel(label: LabelInput): Observable<ServiceResponsePayload<GqlMutationRequest, LabelGroup>> {
        return this._service
            .mutate<Mutation, MutationAddLabelArgs>(this.getAddLabelMutation(), {label})
            .pipe(map(res => ({...res, responsePayload: res.responsePayload?.addLabel})));
    }

    public updateLabel(id: string, label: LabelInput): Observable<ServiceResponsePayload<GqlMutationRequest, LabelGroup>> {
        return this._service
            .mutate<Mutation, MutationUpdateLabelArgs>(this.getUpdateLabelMutation(), {id, label})
            .pipe(map(res => ({...res, responsePayload: res.responsePayload?.updateLabel})));
    }

    private getAddLabelGroupMutation() {
        return gql`
            mutation AddLabelGroup($label_group: LabelGroupInput!) {
                addLabelGroup(label_group: $label_group) {
                    id
                    name
                    color
                }
            }
        `;
    }

    private getUpdateLabelGroupMutation() {
        return gql`
            mutation UpdateLabelGroup($id: String!, $label_group: LabelGroupInput!) {
                updateLabelGroup(id: $id, label_group: $label_group) {
                    id
                    name
                    color
                }
            }
        `;
    }

    private getAddLabelMutation() {
        return gql`
            mutation AddLabel($label: LabelInput!) {
                addLabel(label: $label) {
                    id
                    labels {
                        id
                        name
                    }
                }
            }
        `;
    }

    private getUpdateLabelMutation() {
        return gql`
            mutation UpdateLabel($id: String!, $label: LabelInput!) {
                updateLabel(id: $id, label: $label) {
                    id
                    labels {
                        id
                        name
                    }
                }
            }
        `;
    }
}

export class LabelGroupRequestBuilder extends GqlRequestBuilder<QueryGetLabelGroupsArgs, LabelGroupQueryFields, LabelGroupFilterKeys> {
    protected buildFilter(filter: Filter<LabelGroupFilterKeys>): {filter: LabelGroupFilter} {
        return {
            filter: {
                text: this.getGQLTextFilter(
                    Object.keys(this.labelGroupTextFilterFieldsMapper).map((key: LabelGroupTextFilterKeys) =>
                        this.toGQLTextFilter(
                            this.labelGroupTextFilterFieldsMapper[key],
                            filter[key] as string,
                            this.transformTextMapper[key]
                        )
                    )
                ),
                source_type: this.toGQLMultiselectFilter(filter, 'source_type'),
            },
        };
    }

    private getLabelsQueryItems(): LabelGroupQueryFields[] {
        return ['labels.id', 'labels.name'];
    }

    public buildQuery(fields: LabelGroupQueryFields[]): DocumentNode {
        return gql`
            query GetLabelGroups($filter: LabelGroupFilter, $sort: Sorting, $start: Int, $end: Int) {
                getLabelGroups(filter: $filter, sort: $sort, end: $end, start: $start) {
                    items {
                        id @include(if: ${this.hasField(fields, 'id')})
                        name @include(if: ${this.hasField(fields, 'name')})
                        color @include(if: ${this.hasField(fields, 'color')})
                        labels @include(if: ${this.hasAnyField(fields, this.getLabelsQueryItems())}) {
                            id @include(if: ${this.hasField(fields, 'labels.id')})
                            name @include(if: ${this.hasField(fields, 'labels.name')})
                        }
                        source_type @include(if: ${this.hasField(fields, 'source_type')})
                    }
                    total_count
                }
            }
        `;
    }

    private labelGroupTextFilterFieldsMapper: Record<LabelGroupTextFilterKeys, string[]> = {
        id: nameof.toArray<LabelGroup>(m => [m.id]),
        name: nameof.toArray<LabelGroup>(m => [m.name]),
        color: nameof.toArray<LabelGroup>(m => [m.color]),
        idNameColor: nameof.toArray<LabelGroup>(m => [m.id, m.name, m.color]),
    };

    private transformTextMapper: Partial<Record<LabelGroupTextFilterKeys, (value: string) => string>> = {
        id: value => this.phraseListSearch(value, ','),
    };
}
