import React, {useState} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Button, FormControlLabel, Switch} from '@mui/material';
import {GridColumnIcon} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon, Icon} from '@components/icons';
import {FormTextInputCleanable} from '@components/input';
import {SelectButton} from '@components/select';
import {CustomTheme} from '@style';

import {DataGridColumn} from './types';

const localized = defineMessages({
    searchInputPlaceholder: {
        id: 'DataGridColumnsPanel_searchInputPlaceholder',
        defaultMessage: 'Find column',
    },
    hideAllButtonLabel: {
        id: 'DataGridColumnsPanel_hideAllButtonLabel',
        defaultMessage: 'Hide all',
    },
    showAllButtonLabel: {
        id: 'DataGridColumnsPanel_showAllButtonLabel',
        defaultMessage: 'Show all',
    },
    openPanel: {
        id: 'DataGridColumnsPanel_openPanel',
        defaultMessage: 'Columns',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    gridColumnsPanelWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        height: 'max-content',
        maxHeight: '550px',
        backgroundColor: theme.custom.palette.content.backgroundWhite,
        borderRadius: theme.shape.borderRadius * 2,
    },
    gridColumnsPanelHeader: {
        padding: theme.spacing(1.5),
        '& .MuiFormControl-root': {
            margin: 0,
        },
    },
    gridColumnsPanelSearchInputIcon: {
        fontSize: theme.custom.inputIconFontSize,
        color: theme.palette.secondary.main,
    },
    gridColumnsPanelContent: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        flex: '1 1',
        maxHeight: 'unset',
        gap: '16px',
        padding: theme.spacing(0, 1.5, 1),
    },
    gridColumnsPanelColumn: {
        marginLeft: theme.spacing(1.5),
        '& .MuiSwitch-root': {
            marginRight: theme.spacing(1),
        },
    },
    gridColumnsPanelFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1.75, 1.5),
        borderTop: `1px solid ${theme.palette.secondary.light}`,
        '& .MuiButton-text': {
            padding: 0,
            minWidth: 'unset',
        },
    },
    gridColumnButton: {
        color: theme.palette.text.primary,
        marginLeft: theme.spacing(5),
        marginBottom: theme.spacing(2),
        '& .MuiButtonBase-root': {
            backgroundColor: theme.custom.palette.content.backgroundWhite,
            fontSize: theme.typography.body1.fontSize,
            padding: theme.spacing(2),
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.secondary.light}`,
            boxShadow: `0px 1px 1px ${theme.custom.palette.content.boxShadow}`,
            '& .MuiButton-startIcon': {
                color: theme.palette.secondary.main,
            },
            '&:hover': {
                color: theme.palette.primary.main,
                '& .MuiButton-startIcon': {
                    color: theme.palette.primary.main,
                },
            },
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(2),
        },
    },
}));

type DataGridColumnsPanelProps = {
    pinnedColumnNames: string[];
    visibleColumns?: string[];
    onColumnsVisibilityUpdate: (updatedColumns: string[]) => void;
    allColumns: DataGridColumn<unknown>[];
};

export function DataGridColumnsPanel({
    pinnedColumnNames,
    visibleColumns,
    onColumnsVisibilityUpdate,
    allColumns,
}: DataGridColumnsPanelProps) {
    const {classes} = useStyles();
    const [searchValue, setSearchValue] = useState('');
    const {formatMessage} = useIntl();
    const columns = allColumns?.filter(c => !pinnedColumnNames?.includes(c.field));

    const searchedColumns = React.useMemo(() => {
        let result: DataGridColumn<unknown>[] = columns;
        if (searchValue) {
            result = columns.filter(column => formatMessage(column.title).toLowerCase().includes(searchValue.toLowerCase()));
        }
        return result;
    }, [columns?.map(c => c.field)?.join(), searchValue]);

    function handleSearchValueChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchValue(event.target.value);
    }

    function handleColumnClick(event: React.MouseEvent<HTMLButtonElement>) {
        const name = (event.target as HTMLInputElement).name;
        const column = allColumns.find(c => c.field === name);
        const newColumns = !visibleColumns.includes(column.field) ? showColumns([name]) : hideColumns([name]);
        onColumnsVisibilityUpdate(newColumns);
    }

    function handleToggleAllColumns(value: boolean) {
        const searchedColumnNames = searchedColumns?.map(c => c.field);
        const newColumns = value ? hideColumns(searchedColumnNames) : showColumns([...searchedColumnNames]);
        onColumnsVisibilityUpdate(newColumns);
    }

    function hideColumns(names: string[]): string[] {
        return allColumns.filter(c => visibleColumns.includes(c.field) && !names.includes(c.field)).map(c => c.field);
    }

    function showColumns(names: string[]): string[] {
        return [...new Set([...allColumns.filter(c => visibleColumns.includes(c.field)).map(c => c.field), ...names])];
    }

    function handleClearSearch() {
        setSearchValue('');
    }

    return (
        <SelectButton
            className={classes.gridColumnButton}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            transformOrigin={{vertical: 'top', horizontal: 'left'}}
            selectedText={localized.openPanel}
            startIcon={<GridColumnIcon fontSize="small" />}
            hasArrow={false}
            isMobileFullWidth={false}
        >
            <div className={classes.gridColumnsPanelWrapper}>
                <div className={classes.gridColumnsPanelHeader}>
                    <FormTextInputCleanable
                        startAdornment={<Icon icon={CustomIcon.Search} className={classes.gridColumnsPanelSearchInputIcon} />}
                        placeholder={localized.searchInputPlaceholder}
                        value={searchValue}
                        onChange={handleSearchValueChange}
                        clear={handleClearSearch}
                    />
                </div>
                <div className={classes.gridColumnsPanelContent}>
                    {searchedColumns.map(column => (
                        <div key={column.field} className={classes.gridColumnsPanelColumn}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={visibleColumns.includes(column.field)}
                                        onClick={handleColumnClick}
                                        name={column.field}
                                        color="secondary"
                                    />
                                }
                                label={formatMessage(column.title) || column.field}
                            />
                        </div>
                    ))}
                </div>
                <div className={classes.gridColumnsPanelFooter}>
                    <Button onClick={() => handleToggleAllColumns(true)} color="primary">
                        <LocalizedText label={localized.hideAllButtonLabel} />
                    </Button>
                    <Button onClick={() => handleToggleAllColumns(false)} color="primary">
                        <LocalizedText label={localized.showAllButtonLabel} />
                    </Button>
                </div>
            </div>
        </SelectButton>
    );
}
