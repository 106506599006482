import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {FeatureAccessStatus} from '@models/generated/graphql';

import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {userProfileActions} from '../actions';
import {financeActionsReadPermissions, financeActionsUpdatePermissions} from '../permissions';

import {LockActionProps} from './UserProfileActions';
import {UserProfileLock} from './UserProfileLock';

const localized = defineMessages({
    label: {
        id: 'DepositLock_label',
        defaultMessage: 'Deposit Lock',
    },
});

type DepositLockProps = LockActionProps<FeatureAccessStatus>;

function DepositLockInner({uid, status, disabled}: DepositLockProps) {
    const {formatMessage} = useIntl();

    return (
        <UserProfileLock
            action={userProfileActions.changeDepositStatus}
            label={formatMessage(localized.label)}
            uid={uid}
            isLocked={status === FeatureAccessStatus.Locked}
            disabled={disabled}
            dataTestId="deposit-status-lock"
        />
    );
}

export const DepositLock = withMultiplePermission(
    withMultiplePermission(DepositLockInner, {
        allowedPermissions: financeActionsUpdatePermissions,
        indicator: LackOfPermissionIndicator.Disabled,
    }),
    {allowedPermissions: financeActionsReadPermissions, indicator: LackOfPermissionIndicator.Hidden}
);
