import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {CurrencyCell} from '@components/data-grid/mui';
import {KeyValueCell, KeyValueColumn, KeyValueTable} from '@components/key-value-table';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {RealtimeUpdatesMode} from '@redux/realtime';

import {LastBonusCell} from 'src/features/block-transaction-details/cells/LastBonusCell';
import {LastDepositCell} from 'src/features/block-transaction-details/cells/LastDepositCell';
import {useJurisdictionConfig} from '../../app/config/hooks';
import {useUserProfileDetails} from '../../block-user-profile-details/hooks';

const localized = defineMessages({
    title: {
        id: 'FinanceInfo_title',
        defaultMessage: 'Finance Info',
    },
    depositTotalTitle: {
        id: 'FinanceInfo_depositTotalTitle',
        defaultMessage: 'Total Deposit',
    },
    balanceTitle: {
        id: 'FinanceInfo_balanceTitle',
        defaultMessage: 'Current Balance',
    },
    lifetimeBonusTitle: {
        id: 'FinanceInfo_lifetimeBonusTitle',
        defaultMessage: 'Lifetime Bonus',
    },
    withdrawalTotalTitle: {
        id: 'FinanceInfo_withdrawalTotalTitle',
        defaultMessage: 'Total Withdrawal',
    },
    ggrTitle: {
        id: 'FinanceInfo_ggrTitle',
        defaultMessage: 'GGR',
    },
    bonusRatioTitle: {
        id: 'FinanceInfo_bonusRatioTitle',
        defaultMessage: 'Bonus Ratio',
    },
});

type FinancialInfoProps = {
    userId: string;
};

export function FinancialInfo({userId}: FinancialInfoProps) {
    const {formatMessage} = useIntl();
    const {currency} = useJurisdictionConfig();
    const keys: UserProfileViewModelKeys[] = [
        'uid',
        'deposit_total',
        'withdrawal_approved_amount',
        'balance',
        'ggr',
        'lifetime_bonus',
        'bonus_ratio',
    ];

    const {item} = useUserProfileDetails({
        id: userId,
        fields: keys,
        viewType: 'FinanceInfo',
        realtimeMode: RealtimeUpdatesMode.Confirm,
        displayName: localized.title,
    });

    return (
        <KeyValueTable>
            <KeyValueColumn>
                <KeyValueCell title={formatMessage(localized.depositTotalTitle)}>
                    <CurrencyCell value={item?.finance?.deposit?.total?.successful_amount} currency={currency} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.balanceTitle)}>
                    <CurrencyCell value={item?.finance?.balance} currency={currency} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.lifetimeBonusTitle)}>
                    <CurrencyCell value={item?.finance?.lifetime_bonus} currency={currency} />
                </KeyValueCell>
                <LastBonusCell uid={userId} />
            </KeyValueColumn>
            <KeyValueColumn>
                <KeyValueCell title={formatMessage(localized.withdrawalTotalTitle)}>
                    <CurrencyCell value={item?.finance?.withdrawal?.total?.successful_amount} currency={currency} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.ggrTitle)}>
                    <CurrencyCell value={item?.finance?.ggr} currency={currency} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.bonusRatioTitle)}>
                    <CurrencyCell value={item?.finance?.bonus_ratio} currency={currency} />
                </KeyValueCell>
                <LastDepositCell uid={userId} />
            </KeyValueColumn>
        </KeyValueTable>
    );
}
