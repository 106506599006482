import {constructUsing, createMap, forMember, mapFrom, Mapper, mapWith} from '@automapper/core';
import produce from 'immer';

import {IMapping} from '@auto-mapper';
import {AgentInfo, SecurityCase, UserProfile} from '@models/generated/graphql';
import {UserProfileQueryFields} from '@redux/entity';

import {SecurityCaseViewModel} from 'src/features/block-security-case';

import {AgentInfoViewModel, AgentRevenueShareViewModel, UserProfileViewModel, UserProfileViewModelKeys} from './types';

export class UserProfileMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap<UserProfileViewModelKeys[], UserProfileQueryFields[]>(
            mapper,
            nameof<UserProfileViewModelKeys>(),
            nameof<UserProfileQueryFields>(),
            constructUsing(m => {
                const mapper: Record<UserProfileViewModelKeys, UserProfileQueryFields[]> = {
                    uid: ['uid'],
                    full_name: ['first_name', 'last_name'],
                    nickname: ['nickname'],
                    uid_link_security: ['uid'],
                    uid_link_cs: ['uid'],
                    uid_link_agent: ['uid'],
                    account_status: ['account_status'],
                    withdrawal_status: ['withdrawal_status'],
                    deposit_status: ['deposit_status'],
                    casino_status: ['casino_status'],
                    sportsbook_status: ['sportsbook_status'],
                    security_cases: ['security_cases.type', 'security_cases.case_id'],
                    'latest_kyc.payment.account_verification_status': [
                        'latest_kyc.payment.id',
                        'latest_kyc.payment.account_verification_status',
                    ],
                    'latest_kyc.payment.account_verification_status_cs': [
                        'latest_kyc.payment.id',
                        'latest_kyc.payment.account_verification_status',
                    ],
                    phone: ['contact.mobile.full_number'],
                    referrer_player_id: ['referral.inviting_user_id'],
                    country: ['address.country_info.iso_alpha2_code'],
                    lobby_access_status: ['lobby_access_status'],
                    'security_cases.type': ['security_cases.type'],
                    'security_cases.case_id': ['security_cases.case_id'],
                    'security_cases.closed_at': ['security_cases.closed_at'],
                    'security_cases.opened_at': ['security_cases.opened_at'],
                    'latest_kyc.security.id': ['latest_kyc.security.id'],
                    'latest_kyc.security.account_verification_status': [
                        'latest_kyc.security.id',
                        'latest_kyc.security.account_verification_status',
                    ],
                    username: ['username'],
                    p2p_transfer_status: ['p2p_transfer_status'],
                    platform: ['platform'],
                    platform_cs: ['platform'],
                    deposit_total: ['finance.deposit.total.successful_amount'],
                    withdrawal_approved_amount: ['finance.withdrawal.total.successful_amount'],
                    withdrawal_approved_count: ['finance.withdrawal.total.successful_count'],
                    withdrawal_pending_amount: ['finance.withdrawal.total.pending_amount'],
                    balance: ['finance.balance'],
                    ggr: ['finance.ggr'],
                    lifetime_bonus: ['finance.lifetime_bonus'],
                    bonus_ratio: ['finance.bonus_ratio'],
                    'agent_info.default_agent_revenue_share_history': ['agent_info.default_agent_revenue_share_history'],
                    'agent_info.bo_agent_id': ['agent_info.bo_agent_id'],
                    referrer_username: ['referral.inviting_user_username'],
                    email: ['contact.email'],
                    'finance.balance': ['finance.balance'],
                    referred_date: ['referral.referred_at'],
                    gender: ['gender'],
                    first_name: ['first_name'],
                    last_name: ['last_name'],
                    'phone.area': ['contact.mobile.area'],
                    'phone.number': ['contact.mobile.mobile'],
                    'birthday.seconds': ['birthday.seconds'],
                    'date_of_joining.seconds': ['date_of_joining.seconds'],
                    is_2fa_enabled: ['security_settings.is_2fa_enabled'],
                    is_challenge_questions_enabled: ['security_settings.is_challenge_questions_enabled'],
                    referrer: ['referral.inviting_user_id', 'affiliate.btag'],
                    register_marketing_code: ['marketing_info.register_marketing_code'],
                    labels: ['labels.id', 'labels.name', 'labels.group.id', 'labels.group.name', 'labels.group.color'],
                    national_id: ['region_player_info.national_id'],
                    networks_BL: ['networks.uid', 'networks.platform'],
                    networks_PKW: ['networks.uid', 'networks.platform'],
                    'referral.personal_referral_link': ['referral.personal_referral_link'],
                    'latest_login.logged_at_ts.seconds': ['latest_login.logged_at_ts.seconds'],
                    'latest_kyc.payment.id': ['latest_kyc.payment.id'],
                    'networks.uid': ['networks.uid'],
                    'networks.platform': ['networks.platform'],
                    aml_labels: ['aml_labels'],
                    login_countries: ['countries'],
                    vpn: ['vpn'],
                    brand: ['brand'],
                    license_type: ['license_type'],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
        createMap(
            mapper,
            UserProfile,
            UserProfileViewModel,
            forMember(
                vm => vm.uid,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.agent_info,
                mapWith(AgentInfoViewModel, AgentInfo, m => m.agent_info)
            ),
            forMember(
                vm => vm.username,
                mapFrom(m => m.username)
            ),
            forMember(
                vm => vm.security_cases,
                mapWith(SecurityCaseViewModel, SecurityCase, m => m.security_cases)
            ),
            forMember(
                vm => vm.account_status,
                mapFrom(m => m.account_status)
            ),
            forMember(
                vm => vm.deposit_status,
                mapFrom(m => m.deposit_status)
            ),
            forMember(
                vm => vm.casino_status,
                mapFrom(m => m.casino_status)
            ),
            forMember(
                vm => vm.withdrawal_status,
                mapFrom(m => m.withdrawal_status)
            ),
            forMember(
                vm => vm.lobby_access_status,
                mapFrom(m => m.lobby_access_status)
            ),
            forMember(
                vm => vm.p2p_transfer_status,
                mapFrom(m => m.p2p_transfer_status)
            ),
            forMember(
                vm => vm.sportsbook_status,
                mapFrom(m => m.sportsbook_status)
            ),
            forMember(
                vm => vm.brand,
                mapFrom(m => m.brand)
            ),
            forMember(
                vm => vm.license_type,
                mapFrom(m => m.license_type)
            )
        );
        createMap(
            mapper,
            AgentInfo,
            AgentInfoViewModel,
            forMember(
                vm => vm.bo_agent_id,
                mapFrom(m => m.bo_agent_id)
            ),
            forMember(
                vm => vm.revenue_share_history,
                mapWith(String, nameof<AgentRevenueShareViewModel>(), m => m.default_agent_revenue_share_history)
            )
        );
        createMap<String, AgentRevenueShareViewModel[]>(
            mapper,
            String,
            nameof<AgentRevenueShareViewModel>(),
            constructUsing(m => {
                let parsedVms: AgentRevenueShareViewModel[] = null;
                try {
                    parsedVms = JSON.parse(m.toString());
                } catch (e) {
                    parsedVms = [];
                }

                const convertToPercents = (value: number) => +(value * 100).toFixed(6);
                const updatedVms = produce(parsedVms, draftState => {
                    draftState.forEach(parsedVm => {
                        parsedVm.casino_share = convertToPercents(parsedVm.casino_share);
                        parsedVm.gross_deposit = convertToPercents(parsedVm.gross_deposit);
                        parsedVm.net_deposit_share = convertToPercents(parsedVm.net_deposit_share);
                        parsedVm.player_winloss = convertToPercents(parsedVm.player_winloss);
                        parsedVm.poker_share.weekly_bands = parsedVm.poker_share.weekly_bands.map(weeklyBand => ({
                            ...weeklyBand,
                            revenue_share: convertToPercents(weeklyBand.revenue_share),
                        }));
                    });
                });
                return updatedVms;
            })
        );
        createMap<AgentRevenueShareViewModel[], String>(
            mapper,
            nameof<AgentRevenueShareViewModel>(),
            String,
            constructUsing(vms => {
                const updatedVms = produce(vms, draftState => {
                    const convertToDecimal = (value: number) => +(value / 100).toFixed(6);

                    draftState.forEach(vm => {
                        vm.poker_share.weekly_bands.forEach(weeklyBand => {
                            weeklyBand.revenue_share = convertToDecimal(weeklyBand.revenue_share);
                        });
                        vm.casino_share = convertToDecimal(vm.casino_share);
                        vm.gross_deposit = convertToDecimal(vm.gross_deposit);
                        vm.net_deposit_share = convertToDecimal(vm.net_deposit_share);
                        vm.player_winloss = convertToDecimal(vm.player_winloss);
                    });
                });
                return JSON.stringify(updatedVms);
            })
        );
    }
}
