import {Country, EntityType} from '@redux/entity';
import {UseListViewEntityProps, useViewInit} from '@redux/view';
import {CountriesApiFilterKeys} from '@services/rest-api/countriesApiService';

type UseCountriesResult = {
    countries: Country[];
};

export function useCountries({
    viewType,
    displayName,
    validateFilter,
}: Pick<UseListViewEntityProps<CountriesApiFilterKeys, keyof Country>, 'viewType' | 'displayName' | 'validateFilter'>): UseCountriesResult {
    const entityType: EntityType = EntityType.Country;
    const {items} = useViewInit<Country, CountriesApiFilterKeys, keyof Country>({
        defaultFilters: [],
        viewType,
        displayName,
        entity: {
            entity: entityType,
            fields: ['iso2Code'],
        },
        validateFilter,
    });

    return {countries: items?.filter(i => i)};
}
