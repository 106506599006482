import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {Policy} from '@auth';

import {ModuleName, SubmoduleName} from '../../common/routeEnums';

export const entityTransactionCreateResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'EntityTransactionCreateResource',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.EntityTransactions, null, 'create'),
        new Policy(ModuleName.EntityTransactions, SubmoduleName.P2PTransfer, 'create'),
    ]),
});
