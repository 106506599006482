import React from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import {TransactionViewModelKeys} from '@models/transaction';

import {useTransactionDownloadButton} from '../hooks';

const localized = defineMessages({
    buttonLabel: {
        id: 'TransactionDownloadCsvButton_buttonLabel',
        defaultMessage: 'Download CSV',
    },
});

const useStyles = makeStyles()(theme => ({
    transactionDownloadCsvButton: {
        borderRadius: theme.shape.borderRadius * 2,
    },
}));

type TransactionDownloadCsvButtonProps = {
    filterString: string;
    keys: TransactionViewModelKeys[];
    filename: string;
};

export function TransactionDownloadCsvButton(props: TransactionDownloadCsvButtonProps) {
    const {classes} = useStyles();
    const {isProgress, handleClick} = useTransactionDownloadButton(props);
    return (
        <Button label={localized.buttonLabel} onClick={handleClick} loading={isProgress} className={classes.transactionDownloadCsvButton} />
    );
}
