import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import LocalizedText from '@components/i18n/LocalizedText';
import {TransactionStatus} from '@models/generated/graphql';
import {StatusLog} from '@models/transaction';
import {TransactionDuration as TransactionDurationType} from '@models/transaction';
import {sortByTimestamp, timestampSecondsToMoment} from '@utils';

function TransactionDuration({history, started_at}: TransactionDurationType) {
    function sortHistory(first: StatusLog, second: StatusLog): number {
        return sortByTimestamp(first.logged_at, second.logged_at);
    }

    const sortedTransactionHistory: StatusLog[] = history ? [...history].sort(sortHistory) : [];

    const startedDate = timestampSecondsToMoment(started_at?.seconds);
    const endDateTimestamp = sortedTransactionHistory.find(
        i => i.status === TransactionStatus.InProgress || i.status === TransactionStatus.Failed || i.status === TransactionStatus.Rejected
    )?.logged_at;
    const endDate = timestampSecondsToMoment(endDateTimestamp?.seconds);

    const formattedValue = endDate ? startedDate?.from(endDate, true) : startedDate?.fromNow(true);

    return <LocalizedText label={formattedValue} />;
}

export const renderTransactionDuration = (params: GridCellParams) => {
    const transactionDurationProps: TransactionDurationType = params.value as TransactionDurationType;

    return <TransactionDuration history={transactionDurationProps?.history} started_at={transactionDurationProps?.started_at} />;
};
