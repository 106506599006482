import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {Box} from '@mui/material';
import equal from 'fast-deep-equal/es6';

import {MessageOrientation} from '@components/alerts/Message';
import {EditableText} from '@components/editable/EditableText';
import {FormError, numberRegex, RuleType, useValidationFormatter} from '@components/input';
import {ReferralSettings} from '@models/generated/graphql';

import {ModuleName} from 'src/common/routeEnums';
import {useMessageFormatter, usePercentage} from '../../app/intl/hooks';
import {LackOfPermissionIndicator, useMultiplePermissions, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {PermissionEnum} from '../../module-shared/types';
import {useReduxForm, useReduxFormRequestMode} from '../../shared/form/hooks';
import {referralSettingsActions} from '../actions';

import {localizedReferralSettingsRevenueShare} from './ReferralSettingsRevenueShare.localized';
import {useReferralSettingsRevenueShareClasses} from './ReferralSettingsRevenueShare.style';

type ReferralSettingsRevenueShareProps = ReferralSettings & {
    disabled?: boolean;
};

const editPermission = {
    moduleName: ModuleName.MarketingFunction,
    permissions: [PermissionEnum.Update],
};

const minRevenueShare = 0;
const maxRevenueShare = 100;

const ReferralSettingsRevenueShareInternal = (props: ReferralSettingsRevenueShareProps) => {
    const isEditable = useMultiplePermissions({allowedPermissions: [editPermission]});
    const {classes} = useReferralSettingsRevenueShareClasses();
    const validationMessageFormatter = useValidationFormatter();
    const formatter = useMessageFormatter();
    const {formatMessage} = useIntl();
    const percentageFormatter = usePercentage();

    const [fieldValueModel, setFieldValueModel] = useState<ReferralSettings>(props);

    const {control, state, submit, cancel, handleSubmit} = useReduxForm<ReferralSettings, ReferralSettings>({
        initialModel: fieldValueModel,
        asyncAction: referralSettingsActions.editReferralSettingsRevenueShare,
        map: m => ({
            revenue_share: Number(m?.revenue_share) / 100,
            expiration_period: props.expiration_period,
        }),
    });

    const {requestMode, resetRequestMode} = useReduxFormRequestMode<ReferralSettings>(
        referralSettingsActions.editReferralSettingsRevenueShare
    );

    useEffect(() => {
        if (!equal(fieldValueModel, props)) {
            setFieldValueModel(props);
        }
    }, [props]);

    return (
        <Box className={classes.referralSettingsRevenueShareContainer}>
            <form onSubmit={handleSubmit(submit)}>
                <Controller
                    render={({field, fieldState}) => (
                        <Box>
                            <EditableText
                                type="number"
                                mode={requestMode}
                                value={field.value}
                                onChange={e => {
                                    field.onChange(e);
                                    resetRequestMode();
                                }}
                                formatter={percentageFormatter}
                                onSave={() => handleSubmit(submit)}
                                onCancel={cancel}
                                placeholder={formatter(localizedReferralSettingsRevenueShare.enteraNumber)}
                                disabled={!isEditable || props.disabled}
                                errorMessage={state.errorMessage ? formatMessage(state.errorMessage) : null}
                                errorMessageOrientation={MessageOrientation.Floating}
                            ></EditableText>
                            {fieldState.invalid ? <FormError>{fieldState.error.message}</FormError> : <></>}
                        </Box>
                    )}
                    control={control}
                    name="revenue_share"
                    defaultValue={props.revenue_share}
                    rules={{
                        required: validationMessageFormatter(RuleType.Required, localizedReferralSettingsRevenueShare.revenueShare),
                        pattern: {
                            value: numberRegex,
                            message: validationMessageFormatter(RuleType.Number, localizedReferralSettingsRevenueShare.revenueShare),
                        },
                        min: {
                            value: minRevenueShare,
                            message: validationMessageFormatter(
                                RuleType.Min,
                                localizedReferralSettingsRevenueShare.revenueShare,
                                minRevenueShare
                            ),
                        },
                        max: {
                            value: maxRevenueShare,
                            message: validationMessageFormatter(
                                RuleType.Max,
                                localizedReferralSettingsRevenueShare.revenueShare,
                                maxRevenueShare
                            ),
                        },
                    }}
                ></Controller>
            </form>
        </Box>
    );
};

export const ReferralSettingsRevenueShare = withMultiplePermission(ReferralSettingsRevenueShareInternal, {
    allowedPermissions: [editPermission],
    indicator: LackOfPermissionIndicator.Disabled,
});
