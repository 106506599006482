import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {GridColDef} from '@components/data-grid/mui';
import {Policy} from '@auth';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {withModule} from '../../../features/module-shared/components/ModuleHoc';
import ModulePage from '../../../features/module-shared/components/ModulePage';
import {PermissionEnum} from '../../../features/module-shared/types';
import {domain} from '../types';

import data from './RegistrationInfo.config';

const RegistrationInfo = () => {
    const stateData = useSelector((state: RootState) => state.registrationInfo);
    return (
        <Fragment>
            <ModulePage
                domain={domain}
                data={stateData}
                header={data.localized.title}
                filters={data.filters}
                columns={data.columns as GridColDef[]}
                defaultSorting={data.defaultSorting}
            ></ModulePage>
        </Fragment>
    );
};

export const registrationInfoResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'RegistrationInfo',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.PlayerManagement, null, 'read'),
        new Policy(ModuleName.PlayerManagement, SubmoduleName.RegistrationInformation, 'read'),
    ]),
});

export default withModule(RegistrationInfo, [
    {moduleName: ModuleName.PlayerManagement, submoduleName: SubmoduleName.RegistrationInformation, permissions: [PermissionEnum.Read]},
]);
