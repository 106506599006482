import React, {useEffect, useState} from 'react';
import equal from 'fast-deep-equal/es6';
import {makeStyles} from 'tss-react/mui';

import {Affiliates, PlayerReferral, ReferrerType} from '@models/generated/graphql';
import {ViewModel} from '@redux/view';
import {UserReferralFormModel} from '@services/playerReferralService';
import {CustomTheme} from '@style';

import {useReduxForm} from 'src/features/shared/form/hooks';
import {playerReferralActions, UpdateReferrerInfoActionPayload} from '../actions';
import {useReferralActionAccess} from '../hooks';

import {UserReferralInfoFormContent} from './UserReferralInfoFormContent';

type UserReferralInfoFormProps = {
    userId: string;
    referral: ViewModel<PlayerReferral>;
    affiliate: Affiliates;
    isAgentReferrer: boolean;
};

const useClasses = makeStyles()((_theme: CustomTheme) => ({
    referralInfoForm: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
}));

export const UserReferralInfoForm = ({userId, affiliate, referral, isAgentReferrer}: UserReferralInfoFormProps) => {
    const {classes} = useClasses();

    const getUserReferral = (): UserReferralFormModel =>
        referral?.referrer_id
            ? {
                  referrer_id: referral?.referrer_id,
                  referee_uid: userId,
                  referrer_type: ReferrerType.P2P,
                  id: referral?.id,
              }
            : affiliate?.btag
            ? {
                  referrer_id: affiliate?.btag,
                  referee_uid: userId,
                  referrer_type: ReferrerType.Affiliate,
                  id: referral?.id,
              }
            : ({} as UserReferralFormModel);

    const [userReferral, setUserReferral] = useState(getUserReferral());

    useEffect(() => {
        setUserReferral(getUserReferral());
    }, [affiliate?.btag, referral?.referrer_id]);

    const isReferralEmpty = userReferral === null || userReferral === undefined || equal(userReferral, {});
    const {control, state, submit, cancel, handleSubmit} = useReduxForm<UserReferralFormModel, UpdateReferrerInfoActionPayload>({
        initialModel: userReferral,
        asyncAction: playerReferralActions.changeReferrerInfo,
        map: m => {
            const isRemove = !m?.referrer_id;
            const isUpdate = !isReferralEmpty && m?.referrer_id;
            const mode = isRemove ? 'remove' : isUpdate ? 'update' : 'add';
            return {
                mode,
                referral: {
                    referrer_id: m?.referrer_id ?? userReferral?.referrer_id,
                    referee_uid: userId,
                    referrer_type: m?.referrer_type,
                    id: userReferral?.id,
                },
            };
        },
    });

    const referralInfoEditMode = useReferralActionAccess({viewType: 'UserReferralInfoForm', referrerId: referral?.referrer_id});

    return (
        <form onSubmit={handleSubmit(submit)} className={classes.referralInfoForm}>
            <UserReferralInfoFormContent
                control={control}
                mode={state.isProgress ? 'InProgress' : 'Read'}
                disabled={referralInfoEditMode === 'read-only'}
                onSave={() => handleSubmit(submit)}
                onCancel={cancel}
                referral={userReferral}
                userId={userId}
                isAgentReferrer={isAgentReferrer}
                referrerInfoMode={referralInfoEditMode}
            />
        </form>
    );
};
