import React from 'react';
import {defineMessages} from 'react-intl';
import {Box, Card, CardContent} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {defineAccessControlledResource, Resource, usePolicyAccessCheck} from '@access-control';
import {SortButton, SortDirection} from '@components/button/SortButton';
import {CommentItem} from '@components/comment/CommentItem';
import StyledPagination from '@components/StyledPagination';
import {NoteType, Workspace} from '@models/generated/graphql';
import {Policy} from '@auth';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {extendedViewCleanDelay, ViewType} from '@redux/view';
import {CustomTheme} from '@style';

import {useAttachmentsActions, useNotePin, useNotes, useNotesFilter, useNotesUserSuggestions} from '../hooks';
import {commentReadPolicyRule} from '../policies';
import {EntityViewModel} from '../types';

import NotesFilters from './NotesFilters';
import {NotesForm} from './NotesForm';
import {BasicNotesProps} from './types';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    notesCard: {
        height: '100%',
        overflowY: 'auto',
    },
    notesContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        rowGap: theme.spacing(2),
    },
    notesFilter: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(-0.5, 0),
    },
    notesList: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        margin: theme.spacing(0, -2),
    },
    notesPaging: {
        margin: theme.spacing(0, -2),
    },
}));

export type NotesProps = BasicNotesProps & {
    createEntity: EntityViewModel;
    isPinned?: boolean;
};

const localized = defineMessages({
    titleAll: {
        id: 'Notes_titleAll',
        defaultMessage: 'Notes',
    },
    titlePinned: {
        id: 'Notes_titlePinned',
        defaultMessage: 'Pinned Notes',
    },
});

const resource: Resource<Policy[]> = defineAccessControlledResource({
    name: 'Notes',
    rules: commentReadPolicyRule,
});

export function Notes({createEntity, readEntity, filterType, isPinned}: NotesProps) {
    const {classes} = useClasses();
    const hasAccess = usePolicyAccessCheck(resource);
    const {isProgress: isPinInProgress, handlePin} = useNotePin();

    const viewType: ViewType = isPinned ? 'PinnedNotes' : 'Notes';
    const {defaultFilters, defaultSorting, getNotesSorting} = useNotesFilter({entity: readEntity, filterType});
    const {items, totalCount, searchFilter, filterString, handlePageChange, handlePageSizeChange, handleSortChange, handleFilterChange} =
        useNotes({
            viewType,
            displayName: isPinned ? localized.titlePinned : localized.titleAll,
            fields: [
                'id',
                'body',
                'note_type',
                'posted_at.seconds',
                'posted_by_uid',
                'users_tagged',
                'workspace',
                'entity.id',
                'entity.type',
                'entity.parent.id',
                'entity.parent.type',
                'attachments.extension',
                'attachments.filename',
                'attachments.id',
                'attachments.url',
                'attachments.size',
                'is_pinned',
            ],
            realtimeMode: RealtimeUpdatesMode.Silent,
            triggers: isPinned ? [RealtimeMessageTrigger.Update] : [RealtimeMessageTrigger.Add, RealtimeMessageTrigger.Update],
            defaultFilters: [...defaultFilters, {key: 'is_pinned', value: isPinned}],
            defaultSorting,
            validateFilter: () => true,
            syncWithUrl: false,
            cleanDelay: extendedViewCleanDelay,
        });
    const notes = items?.filter(i => !isPinned || i.is_pinned === isPinned);
    const allUsers = useNotesUserSuggestions(viewType, false);
    const taggingSuggestions = useNotesUserSuggestions(viewType);
    const {download} = useAttachmentsActions();

    const {page, pageSize} = searchFilter?.paging;
    const sortDirection = searchFilter?.sorting?.length && searchFilter.sorting[0].sort === 'asc' ? SortDirection.Old : SortDirection.New;

    return hasAccess ? (
        <Card className={classes.notesCard} data-testid="notes">
            <CardContent className={classes.notesContainer}>
                {!isPinned ? <NotesForm entity={createEntity} suggestions={taggingSuggestions}></NotesForm> : null}
                <Box className={classes.notesFilter}>
                    <NotesFilters onFilterChange={handleFilterChange} filter={filterString} filters={['workspace']} />
                    <SortButton onSortChanged={s => handleSortChange(getNotesSorting(s))} direction={sortDirection} />
                </Box>
                <Box className={classes.notesList}>
                    {notes.map(item => {
                        const author = allUsers?.find(u => u.url === item.posted_by_uid);
                        const entityId =
                            item.note_type === NoteType.Global && item.entity?.parent?.id ? item.entity.parent.id : item.entity.id;
                        return (
                            <CommentItem
                                key={item.id}
                                chipName={nameof<Workspace>()}
                                chipValue={item.workspace === Workspace.Global ? 'GlobalRead' : item.workspace}
                                authorName={author ? author.text : item.posted_by_uid}
                                markdown={item.body}
                                attachments={item.attachments?.map(a => ({...a, onDownload: () => download(a)}))}
                                date={item.posted_at}
                                id={item.id}
                                entityId={entityId}
                                isPinned={item.is_pinned}
                                isPinDisabled={isPinInProgress}
                                onPin={() => handlePin(item.id, item)}
                            />
                        );
                    })}
                </Box>
                {totalCount > 0 ? (
                    <Box className={classes.notesPaging}>
                        <StyledPagination
                            count={totalCount}
                            page={page - 1}
                            labelRowsPerPage=""
                            onPageChange={p => handlePageChange(p + 1)}
                            rowsPerPage={pageSize}
                            onPageSizeChange={handlePageSizeChange}
                        />
                    </Box>
                ) : (
                    <></>
                )}
            </CardContent>
        </Card>
    ) : null;
}
