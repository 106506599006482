import {useState} from 'react';
import {GridFeatureMode} from '@mui/x-data-grid';

import {MuiDataGridProps} from '../MuiDataGrid';

//TODO: [BO-2704] Move files to src/common/components/data-grid (renamed grid folder)
type UseGridPagingResult = {
    page: number;
    pageSize: number;
    pageCount: number;
    paginationMode: GridFeatureMode;
    rowCount: number;
    onPageSizeChange: (newPage: number) => void;
    onPageChange: (newPageNumber: number) => void;
};

export const useGridPaging = ({
    paginationMode,
    sortingMode,
    page,
    pageSize,
    rowCount,
    rowsPerPageOptions,
    rows,
    onPageChange,
    onPageSizeChange,
}: MuiDataGridProps): UseGridPagingResult => {
    const isServer = paginationMode === 'server' || sortingMode === 'server';
    const gridRowCount = rowCount ?? rows?.length;
    // data grid component paginiation starts with 0 but server with 1
    let gridPage = 0;
    if (page > -1) {
        gridPage = isServer ? (gridRowCount > 0 ? page - 1 : 0) : page;
    }
    const gridPageSize = pageSize ?? (rowsPerPageOptions?.length > 0 ? rowsPerPageOptions[0] : 10);
    const pageCount = Math.ceil(gridRowCount / gridPageSize);

    //internal state used to sync pagination and data grid
    const [internalPage, setInternalPage] = useState(gridPage ?? 0);
    const [internalPageSize, setInternalPageSize] = useState(gridPageSize ?? 10);

    const handlePageChange = (newPage: number) => {
        if (onPageChange) {
            onPageChange({page: isServer ? newPage + 1 : newPage, pageCount, pageSize, paginationMode, rowCount: gridRowCount});
        }

        setInternalPage(newPage);
    };

    const handlePageSizeChange = (newPageSize: number) => {
        if (onPageSizeChange) {
            onPageSizeChange({page, pageCount, pageSize: newPageSize, paginationMode, rowCount: gridRowCount});
        }

        if (!isServer) {
            const lastPage = Math.ceil(rowCount / newPageSize) - 1;
            if (newPageSize > lastPage) {
                setInternalPage(lastPage);
            }
        }

        setInternalPageSize(newPageSize);
    };

    return {
        page: isServer ? gridPage : internalPage,
        pageSize: isServer ? gridPageSize : internalPageSize,
        paginationMode: paginationMode ?? sortingMode ?? 'client',
        pageCount: pageCount,
        rowCount: gridRowCount,
        onPageChange: handlePageChange,
        onPageSizeChange: handlePageSizeChange,
    };
};
