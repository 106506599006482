import {useAutoMapper} from '@auto-mapper';
import {Transaction as GqlTransaction} from '@models/generated/graphql';
import {TransactionViewModel, TransactionViewModelKeys} from '@models/transaction';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {EntityType, TransactionFilterKeys, TransactionQueryFields, UserProfileServerFilterKeys} from '@redux/entity';
import {UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';
import {CountriesApiFilterKeys} from '@services/rest-api/countriesApiService';

import {Sorting} from 'src/common/types';
import {useCountries} from '../block-country';
import {useUserProfiles} from '../block-user-profile-list';

import {TransactionWithUserProfileGridItem} from './types';

type TransactionSorting = 'started' | 'updated';

type UseTransactionsProps = Omit<UseListViewEntityProps<TransactionFilterKeys, TransactionViewModelKeys>, 'defaultSorting'> & {
    defaultSorting?: TransactionSorting;
};

export function useTransactions(
    {
        viewType,
        fields,
        displayName,
        cleanDelay = 0,
        realtimeMode,
        triggers,
        defaultFilters,
        defaultPaging,
        defaultSorting = 'started',
        validateFilter,
        syncWithUrl = false,
    }: UseTransactionsProps,
    userProfileProps?: UseListViewEntityProps<UserProfileServerFilterKeys, UserProfileViewModelKeys>,
    countriesProps?: Pick<UseListViewEntityProps<CountriesApiFilterKeys, string>, 'viewType' | 'displayName' | 'validateFilter'>
): UseListViewEntityResult<TransactionWithUserProfileGridItem, TransactionViewModelKeys | UserProfileViewModelKeys> {
    const mapper = useAutoMapper();

    const {
        items: transactions,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useViewInit<GqlTransaction, TransactionFilterKeys, TransactionQueryFields>({
        viewType,
        displayName,
        entity: {
            entity: EntityType.Transaction,
            fields: mapFields(fields),
        },
        realtime: realtimeMode ? {entity: EntityType.Transaction, mode: realtimeMode, triggers} : null,
        defaultSorting: getDefaultSorting(),
        defaultPaging,
        defaultFilters,
        syncWithUrl,
        validateFilter,
        cleanDelay,
    });

    const {items: users} = useUserProfiles({
        ...userProfileProps,
        validateFilter: userProfileProps?.validateFilter ?? (() => false),
        ids: transactions?.map(i => i.uid),
    });

    const {countries} = useCountries({
        viewType: countriesProps?.viewType,
        displayName: countriesProps?.displayName,
        validateFilter: countriesProps?.validateFilter ?? (() => false),
    });

    const items: TransactionWithUserProfileGridItem[] = transactions?.map(i => {
        const user = users.find(u => u.uid === i.uid);
        const transaction = mapper.map(i, GqlTransaction, TransactionViewModel, {extraArgs: () => ({countries, users})});
        return {
            ...user,
            ...transaction,
        };
    });

    return {
        items,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange: handleVmSortChange,
        handleFilterChange,
    };

    function getDefaultSorting(): Sorting<TransactionQueryFields>[] {
        const sorting: Record<TransactionSorting, Sorting<TransactionQueryFields>[]> = {
            started: [
                {
                    field: 'transaction_started_ts',
                    sort: 'desc',
                },
            ],
            updated: [
                {
                    field: 'transaction_updated_ts',
                    sort: 'desc',
                },
            ],
        };
        return sorting[defaultSorting];
    }

    function mapFields(fields: TransactionViewModelKeys[]): TransactionQueryFields[] {
        return mapper?.map<TransactionViewModelKeys[], TransactionQueryFields[]>(
            fields,
            nameof<TransactionViewModelKeys>(),
            nameof<TransactionQueryFields>()
        );
    }

    function mapField(field: TransactionViewModelKeys): TransactionQueryFields {
        return mapFields([field])[0];
    }

    function mapSorting(sorting: Sorting<TransactionViewModelKeys>[]): Sorting<TransactionQueryFields>[] {
        return sorting?.map(s => ({...s, field: mapField(s?.field)}));
    }

    function handleVmSortChange(newSorting: Sorting<TransactionViewModelKeys>[]) {
        handleSortChange(mapSorting(newSorting));
    }
}
