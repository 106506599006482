import React from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch} from 'react-redux';

import {CustomIcon} from '@components/icons';
import {MultiSelectDropdownButton} from '@components/select';
import {KycInitReasonCode} from '@models/generated/graphql';

import {BulkActionKey, bulkActionsActions, useApply} from 'src/features/block-bulk-actions';
import {SelectOption} from 'src/features/module-shared/types';
import {localizedKYCInitReasonCodes} from '../../app/intl/shared-resources/kycInitReasonCode';
import {ApplyKYCInitRequest, ApplyKYCInitStrategy, InitKYCActionData} from '../services/initKYCStrategy';
import {ServiceTypes} from '../types';

const localized = defineMessages({
    buttonLabel: {
        id: 'BulkKYCInitButton_buttonLabel',
        defaultMessage: 'Initiate Payment KYC',
    },
    initiatePaymentOptionsLabel: {
        id: 'BulkKYCInitButton_initiatePaymentOptionsLabel',
        defaultMessage: 'KYC Reason',
    },
    initOptionLabel: {
        id: 'BulkKYCInitButton_initOptionLabel',
        defaultMessage: 'Init',
    },
    nopeOptionLabel: {
        id: 'BulkKYCInitButton_noteOptionLabel',
        defaultMessage: 'Nope',
    },
    tooltipTitle: {
        id: 'BulkKYCInitButton_tooltipTitle',
        defaultMessage: 'Selected KYC Reason',
    },
});

type BulkInitButtonProps = {
    players: {uid: string; id: string}[];
};

export function BulkInitKYCButton({players}: BulkInitButtonProps) {
    const dispatch = useDispatch();
    const action = BulkActionKey.InitiatePaymentKYC;
    const {strategy, applyValue} = useApply<ApplyKYCInitStrategy, InitKYCActionData>(ServiceTypes.ApplyKYCInitStrategy, action);

    function handleApply(reasons: KycInitReasonCode[]) {
        const request: ApplyKYCInitRequest = {
            items: players.map(p => ({...p, reasons})),
        };

        dispatch(bulkActionsActions.apply({request, strategy}));
    }

    return (
        <MultiSelectDropdownButton
            label={localized.buttonLabel}
            iconClassName={CustomIcon.AddSquareOutline}
            optionsHeader={localized.initiatePaymentOptionsLabel}
            options={Object.values(KycInitReasonCode).map<SelectOption>((v: KycInitReasonCode) => ({
                label: localizedKYCInitReasonCodes[v],
                value: v,
            }))}
            onApply={handleApply}
            value={applyValue?.reasons}
            tooltipTitle={localized.tooltipTitle}
        />
    );
}
