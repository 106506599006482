import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource, withPolicyAccessCheck} from '@access-control';
import {ErrorModal} from '@components/error';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar from '@components/toolbar/Toolbar';
import {AgentPlayerReportingViewModelKeys} from '@models/agent-player-reporting';
import {AgentPlayerReportVersion} from '@models/generated/graphql';
import {withTraceErrorBoundary} from '@otel';
import {Policy} from '@auth';
import {extendedViewCleanDelay, ViewType} from '@redux/view';

import {ModuleName} from '../common/routeEnums';
import {useJurisdictionFeature} from '../features/app/config/hooks';
import {FeatureName} from '../features/app/config/types';
import {AgentPlayerReportDownloadCsvButton} from '../features/block-agent-player-reporting-actions';
import {
    AgentPlayerReportingQueryFilters,
    DataGridAgentPlayerReporting,
    useAgentPlayerReportingVn,
} from '../features/block-agent-player-reporting-list';
import {useDefaultReferrerFilter} from '../features/block-agent-profile';

const localized = defineMessages({
    title: {
        id: 'AgentManagementPlayerReportVnDaily_title',
        defaultMessage: 'VN Agent Player Report Daily',
    },
});

function AgentVnDailyReport() {
    const {formatMessage} = useIntl();
    const viewType: ViewType = 'AgentVnDailyReport';
    const columns: AgentPlayerReportingViewModelKeys[] = [
        'referrer_agent_id',
        'referrer_agent_username',
        'uid_link',
        'revenue_share_type',
        'referee_type',
        'rakes',
        'insurance_tool',
        'casino_ggr',
    ];
    const nonColumnKeys: AgentPlayerReportingViewModelKeys[] = ['has_summary'];
    const keys: AgentPlayerReportingViewModelKeys[] = [...columns, ...nonColumnKeys];

    const {
        items,
        summary,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useAgentPlayerReportingVn({
        viewType,
        fields: keys,
        displayName: localized.title,
        defaultFilters: [{key: 'reportVersion', value: AgentPlayerReportVersion.Daily}],
        defaultSorting: [{field: 'referrer_agent_id', sort: 'desc'}],
        syncWithUrl: true,
        validateFilter: null,
        cleanDelay: extendedViewCleanDelay,
    });
    const isTotalPinnedRowAvailable = useJurisdictionFeature({featureName: FeatureName.AgentReportingVnTotalGridRow});

    const {boAgentPlayerId} = useDefaultReferrerFilter({viewType, onFilterChange: handleFilterChange});

    return (
        <LayoutQueue
            header={
                <PageHeader
                    header={localized.title}
                    actions={
                        <AgentPlayerReportDownloadCsvButton
                            filterString={filterString}
                            filename={formatMessage(localized.title)}
                            keys={keys}
                            agentReportType="VN"
                        />
                    }
                />
            }
            toolbar={
                <Toolbar
                    primary={
                        <AgentPlayerReportingQueryFilters
                            viewType="AgentVnDailyReportFilters"
                            model={filterString}
                            onChange={handleFilterChange}
                            availableFilters={['uid', 'agent', 'started_at']}
                            boAgentPlayerId={boAgentPlayerId}
                        />
                    }
                />
            }
            body={
                <DataGridAgentPlayerReporting
                    columns={columns}
                    rows={items}
                    paging={{page: searchFilter?.paging?.page, pageSize: searchFilter?.paging?.pageSize, rowCount: totalCount}}
                    sortModel={{sortModel: searchFilter?.sorting}}
                    pinnedRow={isTotalPinnedRowAvailable ? summary : null}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortChange={handleSortChange}
                />
            }
        />
    );
}

export const agentVnDailyReportResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'AgentVnDailyReport',
    rules: new PoliciesAccessRule([new Policy(ModuleName.PageAgentReportVn), new Policy(ModuleName.PageAgentReportVn, null, 'read')]),
});

export default withTraceErrorBoundary(withPolicyAccessCheck(AgentVnDailyReport, agentVnDailyReportResource), ErrorModal);
