import React, {Fragment} from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource, withPolicyAccessCheck} from '@access-control';
import {ErrorModal} from '@components/error';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar from '@components/toolbar/Toolbar';
import {withTraceErrorBoundary} from '@otel/TraceErrorBoundary';
import {Policy} from '@auth';
import {ViewType} from '@redux/view';

import {Sorting} from 'src/common/types';
import {AgentLevelReportQueryFilters} from 'src/features/block-agent-level-report/components/AgentLevelReportQueryFilters';
import {DataGridAgentLevelReportServer} from 'src/features/block-agent-level-report/components/DataGridAgentLevelReport';
import {useAgentLevelReport} from 'src/features/block-agent-level-report/hooks';
import {AgentLevelReportViewModelKeys} from 'src/features/block-agent-level-report/types';
import {ModuleName} from '../common/routeEnums';
import {AgentLevelReportDownloadButton} from '../features/block-agent-level-report/components/AgentLevelReportDownloadButton';

const localized = defineMessages({
    title: {
        id: 'AgentLevelReport_title',
        defaultMessage: 'Agent Level Report',
    },
});

function AgentLevelReport() {
    const {formatMessage} = useIntl();
    const viewType: ViewType = 'AgentLevelReport';

    const keys: AgentLevelReportViewModelKeys[] = [
        'week_start_date_ts',
        'referrer_id',
        'referrer_username',
        'poker_ngr',
        'casino_ggr',
        'internal_casino_ggr',
        'external_casino_ggr',
        'revenue_shared_total',
        'referrer_psp_fees',
        'carry_over_negative_balance',
        'adjusted_revenue_shared_total',
        'tier_1_revenue_shared',
        'tier_1_poker_revenue_share',
        'tier_1_casino_revenue_share',
        'tier_1_generated_internal_casino_ggr',
        'tier_1_generated_external_casino_ggr',
        'tier_2_revenue_shared',
        'tier_2_poker_revenue_share',
        'tier_2_casino_revenue_share',
        'tier_2_generated_internal_casino_ggr',
        'tier_2_generated_external_casino_ggr',
        'tier_3_revenue_shared',
        'tier_3_poker_revenue_share',
        'tier_3_casino_revenue_share',
        'tier_3_generated_internal_casino_ggr',
        'tier_3_generated_external_casino_ggr',
        'tier_4_revenue_shared',
        'tier_4_poker_revenue_share',
        'tier_4_casino_revenue_share',
        'tier_4_generated_internal_casino_ggr',
        'tier_4_generated_external_casino_ggr',
        'tier_5_revenue_shared',
        'tier_5_poker_revenue_share',
        'tier_5_casino_revenue_share',
        'tier_5_generated_internal_casino_ggr',
        'tier_5_generated_external_casino_ggr',
        'tier_6_revenue_shared',
        'tier_6_poker_revenue_share',
        'tier_6_casino_revenue_share',
        'tier_6_generated_internal_casino_ggr',
        'tier_6_generated_external_casino_ggr',
    ];

    const defaultSorting: Sorting<AgentLevelReportViewModelKeys>[] = [{field: 'week_start_date_ts', sort: 'desc'}];
    const {items, totalCount, searchFilter, filterString, handlePageChange, handlePageSizeChange, handleSortChange, handleFilterChange} =
        useAgentLevelReport({
            viewType,
            fields: keys,
            displayName: localized.title,
            defaultSorting: defaultSorting,
            validateFilter: () => true,
        });

    return (
        <Fragment>
            <LayoutQueue
                header={
                    <PageHeader
                        header={localized.title}
                        actions={
                            <AgentLevelReportDownloadButton
                                filterString={filterString}
                                filename={formatMessage(localized.title)}
                                keys={keys}
                            />
                        }
                    />
                }
                toolbar={
                    <Toolbar
                        primary={
                            <AgentLevelReportQueryFilters
                                model={filterString}
                                onChange={handleFilterChange}
                                availableFilters={['ref_id_uname']}
                            />
                        }
                    />
                }
                body={
                    <DataGridAgentLevelReportServer
                        columns={keys}
                        rows={items}
                        rowCount={totalCount}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSortModelChange={handleSortChange}
                        page={searchFilter?.paging?.page}
                        pageSize={searchFilter?.paging?.pageSize}
                        sortModel={searchFilter?.sorting}
                    />
                }
            />
        </Fragment>
    );
}

export const agentLevelReportResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'AgentLevelReport',
    rules: new PoliciesAccessRule([new Policy(ModuleName.PageAgentReport, null, 'read')]),
});

export default withTraceErrorBoundary(withPolicyAccessCheck(AgentLevelReport, agentLevelReportResource), ErrorModal);
