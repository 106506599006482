import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {UserAccountStatus} from '@models/generated/graphql';

import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {userProfileActions} from '../actions';
import {accountActionsReadPermissions, accountActionsUpdatePermissions, accountActionsUpdateRestrictPermissions} from '../permissions';

import {LockActionProps} from './UserProfileActions';
import {UserProfileLock} from './UserProfileLock';

const localized = defineMessages({
    label: {
        id: 'AccountLock_label',
        defaultMessage: 'Account Lock',
    },
});

type AccountLockProps = LockActionProps<UserAccountStatus>;

function AccountLockInner({uid, status, disabled}: AccountLockProps) {
    const {formatMessage} = useIntl();

    return (
        <UserProfileLock
            action={userProfileActions.changeAccountStatus}
            label={formatMessage(localized.label)}
            uid={uid}
            isLocked={status === UserAccountStatus.Locked}
            disabled={disabled}
            dataTestId="account-status-lock"
        />
    );
}

export const AccountLock = withMultiplePermission(
    withMultiplePermission(AccountLockInner, {
        allowedPermissions: accountActionsUpdatePermissions,
        restrictedPermissions: accountActionsUpdateRestrictPermissions,
        indicator: LackOfPermissionIndicator.Disabled,
    }),
    {allowedPermissions: accountActionsReadPermissions, indicator: LackOfPermissionIndicator.Hidden}
);
