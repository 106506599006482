import React from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {useService} from '@inversify';

import {ModuleName, SubmoduleName} from '../../../common/routeEnums';
import {withPermissionAndJurisdictionConfig} from '../../app/permission/PermissionHoc';
import {
    BulkActionKey,
    bulkActionsActions,
    BulkChooseActionStepLockItem,
    BulkLockItemValue,
    itemsSelector,
    lobbyLockInitialValueSelector,
    lobbyLockResultValueSelector,
} from '../../block-bulk-actions';
import {PermissionEnum} from '../../module-shared/types';
import {ApplyLobbyLockRequest, ApplyLobbyLockStrategy} from '../services/applyStrategy';

const localized = defineMessages({
    lobbyLock: {
        id: 'BulkActionLobbyLock_lobbyLock',
        defaultMessage: 'Lobby Lock',
    },
});

function ActionLobbyLockInner() {
    const dispatch = useDispatch();
    const initialValues: boolean[] = useSelector(lobbyLockInitialValueSelector);
    const resultValue: boolean = useSelector(lobbyLockResultValueSelector);
    const players = useSelector(itemsSelector);
    const strategy = useService<ApplyLobbyLockStrategy>('ApplyLobbyLockStrategy');

    function handleSubmit(toggleValue: BulkLockItemValue): void {
        if (toggleValue !== BulkLockItemValue.NoChanges) {
            dispatch(
                bulkActionsActions.apply({
                    request: {players, value: toggleValue === BulkLockItemValue.Checked} as ApplyLobbyLockRequest,
                    strategy,
                })
            );
        } else {
            dispatch(bulkActionsActions.removeBulkActionsWithKey(BulkActionKey.LobbyLock));
        }
    }

    return (
        <BulkChooseActionStepLockItem
            label={localized.lobbyLock}
            initialValues={initialValues}
            resultValue={resultValue}
            onSubmit={handleSubmit}
        />
    );
}

export const BulkUserProfileLobbyLock = withPermissionAndJurisdictionConfig(ActionLobbyLockInner, {
    moduleName: ModuleName.ActionCenter,
    submoduleName: SubmoduleName.GamesActions,
    permissions: [PermissionEnum.Update],
});
