import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {ChipAccordion} from '@components/chip/ChipAccordion';
import {agentReadPolicies} from '@models/permissions/permissions';
import {CustomTheme} from '@style';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {BlockPlayerLabelAssign} from '../../block-label-group-actions/components/BlockPlayerLabelAssign';
import {BlockPlayerLabelAssignButton} from '../../block-label-group-actions/components/BlockPlayerLabelAssignButton';
import {BlockPlayerLabelCreateLabel} from '../../block-label-group-actions/components/BlockPlayerLabelCreateLabel';
import {BlockPlayerLabelGroupCreate} from '../../block-label-group-actions/components/BlockPlayerLabelGroupCreate';
import {BlockPlayerLabel} from '../../block-label-group-details/components/BlockPlayerLabel';
import {PermissionAccessibleItem, PermissionEnum} from '../../module-shared/types';
import {usePlayerLabelsData} from '../hooks';

type BlockPlayerActionLabelProps = {
    uid: string;
};

const useStyles = makeStyles()((theme: CustomTheme) => ({
    blockPlayerActionLabelContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginRight: theme.spacing(-4),
    },

    blockPlayerActionLabelAssignButton: {
        marginTop: theme.spacing(1.5),
    },
}));

function BlockPlayerLabelsInternal({uid}: BlockPlayerActionLabelProps) {
    const {classes} = useStyles();
    const {hiddenLabels, playerLabelIds, visibleLabels} = usePlayerLabelsData(uid);

    return (
        <Box className={classes.blockPlayerActionLabelContainer}>
            <ChipAccordion
                visibleChips={visibleLabels.map(label => (
                    <BlockPlayerLabel key={label.id} uid={uid} label={label} size="medium" />
                ))}
                collapsedChips={hiddenLabels.map(label => (
                    <BlockPlayerLabel key={label.id} uid={uid} label={label} size="medium" />
                ))}
            ></ChipAccordion>
            <Box className={classes.blockPlayerActionLabelAssignButton}>
                <BlockPlayerLabelAssignButton>
                    <BlockPlayerLabelCreateLabel />
                    <BlockPlayerLabelGroupCreate />
                    <BlockPlayerLabelAssign uid={uid} playerLabelIds={playerLabelIds} />
                </BlockPlayerLabelAssignButton>
            </Box>
        </Box>
    );
}

export const blockPlayerLabelsReadPermissions = [
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesSecurity, permissions: [PermissionEnum.Read]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.Payment, permissions: [PermissionEnum.Read]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.KYC, permissions: [PermissionEnum.Read]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesCustomerSupport, permissions: [PermissionEnum.Read]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesPlayer, permissions: [PermissionEnum.Read]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesBotDetection, permissions: [PermissionEnum.Read]},
];

export const restrictedPermissions: PermissionAccessibleItem[] = agentReadPolicies;

export const BlockPlayerLabels = withMultiplePermission(BlockPlayerLabelsInternal, {
    allowedPermissions: blockPlayerLabelsReadPermissions,
    restrictedPermissions,
});
