import {inject, injectable} from 'inversify';
import {map, Observable} from 'rxjs';

import {ServiceTypes} from '@inversify/inversifyTypes';
import {BulkItemStatus, KycInitReasonCode} from '@models/generated/graphql';
import {IAccountVerificationService} from '@services/accountVerificationService';
import {ServerResponseStatus} from '@services/types';

import {
    ApplyStrategyResponse,
    BulkActionKey,
    failedOperationId,
    IBulkApplyStrategy,
    IBulkStrategy,
    PerformStrategyOperationResponse,
    PerformStrategyRequest,
} from 'src/features/block-bulk-actions';

export type InitKYCActionData = {
    uid: string;
    reasons: KycInitReasonCode[];
    id?: string;
};

export type ApplyKYCInitRequest = {items: InitKYCActionData[]};

@injectable()
export class ApplyKYCInitStrategy implements IBulkApplyStrategy<ApplyKYCInitRequest, InitKYCActionData> {
    process(request: ApplyKYCInitRequest): ApplyStrategyResponse<InitKYCActionData> {
        const applyOptions: ApplyStrategyResponse<InitKYCActionData> = {
            actionKey: BulkActionKey.InitiatePaymentKYC,
            items: request.items.map(item => ({
                actionKey: BulkActionKey.InitiatePaymentKYC,
                value: {uid: item.uid, reasons: item.reasons},
                itemId: item.id,
                status: BulkItemStatus.Pending,
            })),
        };
        return applyOptions;
    }
}

@injectable()
export class PerformKYCInitStrategy implements IBulkStrategy<PerformStrategyRequest<InitKYCActionData>, PerformStrategyOperationResponse> {
    private _accountVerificationService: IAccountVerificationService;

    constructor(@inject(ServiceTypes.AccountVerificationService) accountVerificationService: IAccountVerificationService) {
        this._accountVerificationService = accountVerificationService;
    }

    process(request: PerformStrategyRequest<InitKYCActionData>): Observable<PerformStrategyOperationResponse> {
        return this._accountVerificationService
            .initPaymentKYCBulk(request?.items?.map(i => ({uid: i?.value?.uid, reason_codes: i?.value?.reasons, itemId: i?.itemId})))
            .pipe(
                map(response => ({
                    actionKey: BulkActionKey.InitiatePaymentKYC,
                    id: response.status === ServerResponseStatus.Success ? response.responsePayload.id : failedOperationId,
                }))
            );
    }
}
