import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {
    getContentModuleDataReducer,
    getContentModuleStateReducer,
    getGridModuleDataReducer,
    getGridModuleStateReducer,
} from '../../features/module-shared/reducers';

import {userAcceptanceVersionActions} from './actions';
import {domain, LegalDocumentRead} from './types';

const createLegalModuleReducers = (domain: string, moduleName: ModuleName, submoduleName?: SubmoduleName) => {
    const contentModuleReducer = combineReducers({
        ...getContentModuleDataReducer(domain, moduleName, submoduleName),
        state: combineReducers(getContentModuleStateReducer(domain)),
    });

    const combined = combineReducers({
        domain: createReducer(domain),
        data: contentModuleReducer,
    });
    return combined;
};

export const privacyPolicyReducers = createLegalModuleReducers(
    domain.privacyPolicy,
    ModuleName.LegalContentManagement,
    SubmoduleName.PrivacyPolicy
);

export const termsReducers = createLegalModuleReducers(
    domain.termsAndConditions,
    ModuleName.LegalContentManagement,
    SubmoduleName.TermsAndConditions
);

const createUserAcceptanceReducer = () => {
    const modalDocTypeReducer = createReducer(null as string).handleAction(
        userAcceptanceVersionActions.loadDocument.request,
        (_, action) => action.payload.name
    );

    const documentContentReducer = createReducer(null as LegalDocumentRead)
        .handleAction(userAcceptanceVersionActions.loadDocument.request, () => null)
        .handleAction(userAcceptanceVersionActions.loadDocument.success, (_, action) => action.payload);

    const contentModuleReducer = combineReducers({
        ...getGridModuleDataReducer(domain.userAcceptanceVersion, ModuleName.LegalContentManagement, SubmoduleName.UserAcceptanceVersion),
        documentContent: documentContentReducer,
        state: combineReducers({
            ...getGridModuleStateReducer(domain.userAcceptanceVersion),
            modalDocType: modalDocTypeReducer,
        }),
    });

    return combineReducers({
        domain: createReducer(domain.userAcceptanceVersion),
        data: contentModuleReducer,
    });
};

export const userAcceptanceReducers = createUserAcceptanceReducer();
