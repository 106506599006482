import {GridValueGetterParams} from '@mui/x-data-grid';

import {
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    MuiDataGridClient,
    MuiDataGridServer,
    renderCellHeader,
    renderCellWithTooltip,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import {sharedLocalization} from '@localization';

import {BoUserViewModel, BoUserViewModelKeys} from '../types';

import {EditablePlayerIdValue, renderEditablePlayerIdCell} from './AgentPlayerIdCell';

export class DataGridBoUserConfigFactory extends DataGridEntityConfigFactory<BoUserViewModelKeys, BoUserViewModel> {
    getColumnConfig(): Partial<Record<BoUserViewModelKeys, DataGridEntityColDef<BoUserViewModelKeys>>> {
        return {
            email: {
                field: 'email',
                localizedHeaderName: sharedLocalization.emailFilter,
                renderHeader: renderCellHeader,
                valueGetter: p => this.getEmail(p),
                renderCell: renderCellWithTooltip,
                flex: 0.2,
                sortable: false,
            },
            firstName: {
                field: 'firstName',
                localizedHeaderName: sharedLocalization.firstName,
                renderHeader: renderCellHeader,
                valueGetter: p => this.getFirstName(p),
                renderCell: renderCellWithTooltip,
                flex: 0.2,
                sortable: false,
            },
            lastName: {
                field: 'lastName',
                localizedHeaderName: sharedLocalization.lastName,
                renderHeader: renderCellHeader,
                valueGetter: p => this.getLastName(p),
                renderCell: renderCellWithTooltip,
                flex: 0.2,
                sortable: false,
            },
            boAgentPlayerId: {
                field: 'boAgentPlayerId',
                localizedHeaderName: sharedLocalization.agentPlayerId,
                renderHeader: renderCellHeader,
                valueGetter: p => this.getAgentPlayerId(p),
                renderCell: renderEditablePlayerIdCell,
                width: 240,
                sortable: false,
            },
        };
    }

    private getEmail(p: GridValueGetterParams) {
        return this.getRow(p)?.email;
    }

    private getFirstName(p: GridValueGetterParams) {
        return this.getRow(p)?.firstName;
    }

    private getLastName(p: GridValueGetterParams) {
        return this.getRow(p)?.lastName;
    }

    private getAgentPlayerId(p: GridValueGetterParams): EditablePlayerIdValue {
        return {boUserId: this.getRow(p)?.id, playerId: this.getRow(p)?.boAgentPlayerId};
    }
}

export const DataGridBoUserClient = withDataGridEntityClient<BoUserViewModelKeys, BoUserViewModel>(MuiDataGridClient, () =>
    new DataGridBoUserConfigFactory().getColumnConfig()
);

export const DataGridBoUserServer = withDataGridEntityServer<BoUserViewModelKeys, BoUserViewModel>(MuiDataGridServer, () =>
    new DataGridBoUserConfigFactory().getColumnConfig()
);
