import {constructUsing, createMap, extend, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {AgentRevenueShareWeeklyReport} from '@models/generated/graphql';
import {AgentLevelReportQueryFields, AgentLevelReportSortingFields} from '@redux/entity';
import {formatTimestamp} from '@utils';

import {AgentLevelReportDownloadViewModel, AgentLevelReportViewModel, AgentLevelReportViewModelKeys} from './types';

export class AgentLevelReportMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            AgentRevenueShareWeeklyReport,
            AgentLevelReportViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => `${m.referrer_id}_${m.week_start_date_ts.seconds}`)
            ),
            forMember(
                vm => vm.week_start_date_ts.seconds,
                mapFrom(m => m.week_start_date_ts.seconds)
            ),
            forMember(
                vm => vm.adjusted_revenue_shared_total,
                mapFrom(m => m.adjusted_revenue_shared_total)
            ),
            forMember(
                vm => vm.carry_over_negative_balance,
                mapFrom(m => m.carry_over_negative_balance)
            ),
            forMember(
                vm => vm.casino_ggr,
                mapFrom(m => m.casino_ggr)
            ),
            forMember(
                vm => vm.external_casino_ggr,
                mapFrom(m => m.external_casino_ggr)
            ),
            forMember(
                vm => vm.internal_casino_ggr,
                mapFrom(m => m.internal_casino_ggr)
            ),
            forMember(
                vm => vm.poker_ngr,
                mapFrom(m => m.poker_ngr)
            ),
            forMember(
                vm => vm.referrer_id,
                mapFrom(m => m.referrer_id)
            ),
            forMember(
                vm => vm.referrer_psp_fees,
                mapFrom(m => m.referrer_psp_fees)
            ),
            forMember(
                vm => vm.referrer_username,
                mapFrom(m => m.referrer_username)
            ),
            forMember(
                vm => vm.revenue_shared_total,
                mapFrom(m => m.revenue_shared_total)
            ),
            forMember(
                vm => vm.tier_1_casino_revenue_share,
                mapFrom(m => m.tiers[0].casino_revenue_share)
            ),
            forMember(
                vm => vm.tier_2_casino_revenue_share,
                mapFrom(m => m.tiers[1].casino_revenue_share)
            ),
            forMember(
                vm => vm.tier_3_casino_revenue_share,
                mapFrom(m => m.tiers[2].casino_revenue_share)
            ),
            forMember(
                vm => vm.tier_4_casino_revenue_share,
                mapFrom(m => m.tiers[3].casino_revenue_share)
            ),
            forMember(
                vm => vm.tier_5_casino_revenue_share,
                mapFrom(m => m.tiers[4].casino_revenue_share)
            ),
            forMember(
                vm => vm.tier_6_casino_revenue_share,
                mapFrom(m => m.tiers[5].casino_revenue_share)
            ),
            forMember(
                vm => vm.tier_1_revenue_shared,
                mapFrom(m => m.tiers[0].revenue_shared)
            ),
            forMember(
                vm => vm.tier_2_revenue_shared,
                mapFrom(m => m.tiers[1].revenue_shared)
            ),
            forMember(
                vm => vm.tier_3_revenue_shared,
                mapFrom(m => m.tiers[2].revenue_shared)
            ),
            forMember(
                vm => vm.tier_4_revenue_shared,
                mapFrom(m => m.tiers[3].revenue_shared)
            ),
            forMember(
                vm => vm.tier_5_revenue_shared,
                mapFrom(m => m.tiers[4].revenue_shared)
            ),
            forMember(
                vm => vm.tier_6_revenue_shared,
                mapFrom(m => m.tiers[5].revenue_shared)
            ),
            forMember(
                vm => vm.tier_1_poker_revenue_share,
                mapFrom(m => m.tiers[0].poker_revenue_share)
            ),
            forMember(
                vm => vm.tier_2_poker_revenue_share,
                mapFrom(m => m.tiers[1].poker_revenue_share)
            ),
            forMember(
                vm => vm.tier_3_poker_revenue_share,
                mapFrom(m => m.tiers[2].poker_revenue_share)
            ),
            forMember(
                vm => vm.tier_4_poker_revenue_share,
                mapFrom(m => m.tiers[3].poker_revenue_share)
            ),
            forMember(
                vm => vm.tier_5_poker_revenue_share,
                mapFrom(m => m.tiers[4].poker_revenue_share)
            ),
            forMember(
                vm => vm.tier_6_poker_revenue_share,
                mapFrom(m => m.tiers[5].poker_revenue_share)
            ),
            forMember(
                vm => vm.tier_1_generated_internal_casino_ggr,
                mapFrom(m => m.tiers[0].generated_internal_casino_ggr)
            ),
            forMember(
                vm => vm.tier_2_generated_internal_casino_ggr,
                mapFrom(m => m.tiers[1].generated_internal_casino_ggr)
            ),
            forMember(
                vm => vm.tier_3_generated_internal_casino_ggr,
                mapFrom(m => m.tiers[2].generated_internal_casino_ggr)
            ),
            forMember(
                vm => vm.tier_4_generated_internal_casino_ggr,
                mapFrom(m => m.tiers[3].generated_internal_casino_ggr)
            ),
            forMember(
                vm => vm.tier_5_generated_internal_casino_ggr,
                mapFrom(m => m.tiers[4].generated_internal_casino_ggr)
            ),
            forMember(
                vm => vm.tier_6_generated_internal_casino_ggr,
                mapFrom(m => m.tiers[5].generated_internal_casino_ggr)
            ),
            forMember(
                vm => vm.tier_1_generated_external_casino_ggr,
                mapFrom(m => m.tiers[0].generated_external_casino_ggr)
            ),
            forMember(
                vm => vm.tier_2_generated_external_casino_ggr,
                mapFrom(m => m.tiers[1].generated_external_casino_ggr)
            ),
            forMember(
                vm => vm.tier_3_generated_external_casino_ggr,
                mapFrom(m => m.tiers[2].generated_external_casino_ggr)
            ),
            forMember(
                vm => vm.tier_4_generated_external_casino_ggr,
                mapFrom(m => m.tiers[3].generated_external_casino_ggr)
            ),
            forMember(
                vm => vm.tier_5_generated_external_casino_ggr,
                mapFrom(m => m.tiers[4].generated_external_casino_ggr)
            ),
            forMember(
                vm => vm.tier_6_generated_external_casino_ggr,
                mapFrom(m => m.tiers[5].generated_external_casino_ggr)
            )
        );

        createMap<AgentRevenueShareWeeklyReport, AgentLevelReportDownloadViewModel>(
            mapper,
            AgentRevenueShareWeeklyReport,
            AgentLevelReportDownloadViewModel,
            extend(AgentRevenueShareWeeklyReport, AgentLevelReportViewModel),
            forMember(
                vm => vm.week_start_date_ts,
                mapFrom(m => formatTimestamp(m.week_start_date_ts, 'date-time'))
            )
        );

        createMap<AgentLevelReportViewModelKeys[], AgentLevelReportQueryFields[]>(
            mapper,
            nameof<AgentLevelReportViewModelKeys>(),
            nameof<AgentLevelReportQueryFields>(),
            constructUsing(m => {
                const mapper: Record<AgentLevelReportViewModelKeys, AgentLevelReportQueryFields> = {
                    week_start_date_ts: 'week_start_date_ts.seconds',
                    referrer_id: 'referrer_id',
                    referrer_username: 'referrer_username',
                    poker_ngr: 'poker_ngr',
                    casino_ggr: 'casino_ggr',
                    internal_casino_ggr: 'internal_casino_ggr',
                    external_casino_ggr: 'external_casino_ggr',
                    revenue_shared_total: 'revenue_shared_total',
                    referrer_psp_fees: 'referrer_psp_fees',
                    carry_over_negative_balance: 'carry_over_negative_balance',
                    adjusted_revenue_shared_total: 'adjusted_revenue_shared_total',
                    tier_1_casino_revenue_share: 'tiers.casino_revenue_share',
                    tier_1_generated_external_casino_ggr: 'tiers.generated_external_casino_ggr',
                    tier_1_poker_revenue_share: 'tiers.poker_revenue_share',
                    tier_1_generated_internal_casino_ggr: 'tiers.generated_internal_casino_ggr',
                    tier_1_revenue_shared: 'tiers.revenue_shared',
                    tier_2_casino_revenue_share: 'tiers.casino_revenue_share',
                    tier_2_generated_external_casino_ggr: 'tiers.generated_external_casino_ggr',
                    tier_2_poker_revenue_share: 'tiers.poker_revenue_share',
                    tier_2_generated_internal_casino_ggr: 'tiers.generated_internal_casino_ggr',
                    tier_2_revenue_shared: 'tiers.revenue_shared',
                    tier_3_casino_revenue_share: 'tiers.casino_revenue_share',
                    tier_3_generated_external_casino_ggr: 'tiers.generated_external_casino_ggr',
                    tier_3_poker_revenue_share: 'tiers.poker_revenue_share',
                    tier_3_generated_internal_casino_ggr: 'tiers.generated_internal_casino_ggr',
                    tier_3_revenue_shared: 'tiers.revenue_shared',
                    tier_4_casino_revenue_share: 'tiers.casino_revenue_share',
                    tier_4_generated_external_casino_ggr: 'tiers.generated_external_casino_ggr',
                    tier_4_poker_revenue_share: 'tiers.poker_revenue_share',
                    tier_4_generated_internal_casino_ggr: 'tiers.generated_internal_casino_ggr',
                    tier_4_revenue_shared: 'tiers.revenue_shared',
                    tier_5_casino_revenue_share: 'tiers.casino_revenue_share',
                    tier_5_generated_external_casino_ggr: 'tiers.generated_external_casino_ggr',
                    tier_5_poker_revenue_share: 'tiers.poker_revenue_share',
                    tier_5_generated_internal_casino_ggr: 'tiers.generated_internal_casino_ggr',
                    tier_5_revenue_shared: 'tiers.revenue_shared',
                    tier_6_casino_revenue_share: 'tiers.casino_revenue_share',
                    tier_6_generated_external_casino_ggr: 'tiers.generated_external_casino_ggr',
                    tier_6_poker_revenue_share: 'tiers.poker_revenue_share',
                    tier_6_generated_internal_casino_ggr: 'tiers.generated_internal_casino_ggr',
                    tier_6_revenue_shared: 'tiers.revenue_shared',
                };

                return [...new Set(m.map(i => mapper[i]))];
            })
        );

        createMap<AgentLevelReportSortingFields, AgentLevelReportViewModelKeys>(
            mapper,
            nameof<AgentLevelReportSortingFields>(),
            nameof<AgentLevelReportViewModelKeys>(),
            constructUsing(m => {
                const mapper: Record<AgentLevelReportSortingFields, AgentLevelReportViewModelKeys> = {
                    'week_start_date_ts.seconds': 'week_start_date_ts',
                    referrer_id: 'referrer_id',
                    referrer_username: 'referrer_username',
                    poker_ngr: 'poker_ngr',
                    casino_ggr: 'casino_ggr',
                    internal_casino_ggr: 'internal_casino_ggr',
                    external_casino_ggr: 'external_casino_ggr',
                    revenue_shared_total: 'revenue_shared_total',
                    referrer_psp_fees: 'referrer_psp_fees',
                    carry_over_negative_balance: 'carry_over_negative_balance',
                    adjusted_revenue_shared_total: 'adjusted_revenue_shared_total',
                    'tiers.tier_1.casino_revenue_share': 'tier_1_casino_revenue_share',
                    'tiers.tier_1.generated_external_casino_ggr': 'tier_1_generated_external_casino_ggr',
                    'tiers.tier_1.generated_internal_casino_ggr': 'tier_1_generated_internal_casino_ggr',
                    'tiers.tier_1.poker_revenue_share': 'tier_1_poker_revenue_share',
                    'tiers.tier_1.revenue_shared': 'tier_1_revenue_shared',
                    'tiers.tier_2.casino_revenue_share': 'tier_2_casino_revenue_share',
                    'tiers.tier_2.generated_external_casino_ggr': 'tier_2_generated_external_casino_ggr',
                    'tiers.tier_2.generated_internal_casino_ggr': 'tier_2_generated_internal_casino_ggr',
                    'tiers.tier_2.poker_revenue_share': 'tier_2_poker_revenue_share',
                    'tiers.tier_2.revenue_shared': 'tier_2_revenue_shared',
                    'tiers.tier_3.casino_revenue_share': 'tier_3_casino_revenue_share',
                    'tiers.tier_3.generated_external_casino_ggr': 'tier_3_generated_external_casino_ggr',
                    'tiers.tier_3.generated_internal_casino_ggr': 'tier_3_generated_internal_casino_ggr',
                    'tiers.tier_3.poker_revenue_share': 'tier_3_poker_revenue_share',
                    'tiers.tier_3.revenue_shared': 'tier_3_revenue_shared',
                    'tiers.tier_4.casino_revenue_share': 'tier_4_casino_revenue_share',
                    'tiers.tier_4.generated_external_casino_ggr': 'tier_4_generated_external_casino_ggr',
                    'tiers.tier_4.generated_internal_casino_ggr': 'tier_4_generated_internal_casino_ggr',
                    'tiers.tier_4.poker_revenue_share': 'tier_4_poker_revenue_share',
                    'tiers.tier_4.revenue_shared': 'tier_4_revenue_shared',
                    'tiers.tier_5.casino_revenue_share': 'tier_5_casino_revenue_share',
                    'tiers.tier_5.generated_external_casino_ggr': 'tier_5_generated_external_casino_ggr',
                    'tiers.tier_5.generated_internal_casino_ggr': 'tier_5_generated_internal_casino_ggr',
                    'tiers.tier_5.poker_revenue_share': 'tier_5_poker_revenue_share',
                    'tiers.tier_5.revenue_shared': 'tier_5_revenue_shared',
                    'tiers.tier_6.casino_revenue_share': 'tier_6_casino_revenue_share',
                    'tiers.tier_6.generated_external_casino_ggr': 'tier_6_generated_external_casino_ggr',
                    'tiers.tier_6.generated_internal_casino_ggr': 'tier_6_generated_internal_casino_ggr',
                    'tiers.tier_6.poker_revenue_share': 'tier_6_poker_revenue_share',
                    'tiers.tier_6.revenue_shared': 'tier_6_revenue_shared',
                };

                return mapper[m];
            })
        );

        createMap<AgentLevelReportViewModelKeys, AgentLevelReportSortingFields>(
            mapper,
            nameof<AgentLevelReportViewModelKeys>(),
            nameof<AgentLevelReportSortingFields>(),
            constructUsing(m => {
                const mapper: Record<AgentLevelReportViewModelKeys, AgentLevelReportSortingFields> = {
                    week_start_date_ts: 'week_start_date_ts.seconds',
                    referrer_id: 'referrer_id',
                    referrer_username: 'referrer_username',
                    poker_ngr: 'poker_ngr',
                    casino_ggr: 'casino_ggr',
                    internal_casino_ggr: 'internal_casino_ggr',
                    external_casino_ggr: 'external_casino_ggr',
                    revenue_shared_total: 'revenue_shared_total',
                    referrer_psp_fees: 'referrer_psp_fees',
                    carry_over_negative_balance: 'carry_over_negative_balance',
                    adjusted_revenue_shared_total: 'adjusted_revenue_shared_total',
                    tier_1_casino_revenue_share: 'tiers.tier_1.casino_revenue_share',
                    tier_1_generated_external_casino_ggr: 'tiers.tier_1.generated_external_casino_ggr',
                    tier_1_generated_internal_casino_ggr: 'tiers.tier_1.generated_internal_casino_ggr',
                    tier_1_poker_revenue_share: 'tiers.tier_1.poker_revenue_share',
                    tier_1_revenue_shared: 'tiers.tier_1.revenue_shared',
                    tier_2_casino_revenue_share: 'tiers.tier_2.casino_revenue_share',
                    tier_2_generated_external_casino_ggr: 'tiers.tier_2.generated_external_casino_ggr',
                    tier_2_generated_internal_casino_ggr: 'tiers.tier_2.generated_internal_casino_ggr',
                    tier_2_poker_revenue_share: 'tiers.tier_2.poker_revenue_share',
                    tier_2_revenue_shared: 'tiers.tier_2.revenue_shared',
                    tier_3_casino_revenue_share: 'tiers.tier_3.casino_revenue_share',
                    tier_3_generated_external_casino_ggr: 'tiers.tier_3.generated_external_casino_ggr',
                    tier_3_generated_internal_casino_ggr: 'tiers.tier_3.generated_internal_casino_ggr',
                    tier_3_poker_revenue_share: 'tiers.tier_3.poker_revenue_share',
                    tier_3_revenue_shared: 'tiers.tier_3.revenue_shared',
                    tier_4_casino_revenue_share: 'tiers.tier_4.casino_revenue_share',
                    tier_4_generated_external_casino_ggr: 'tiers.tier_4.generated_external_casino_ggr',
                    tier_4_generated_internal_casino_ggr: 'tiers.tier_4.generated_internal_casino_ggr',
                    tier_4_poker_revenue_share: 'tiers.tier_4.poker_revenue_share',
                    tier_4_revenue_shared: 'tiers.tier_4.revenue_shared',
                    tier_5_casino_revenue_share: 'tiers.tier_5.casino_revenue_share',
                    tier_5_generated_external_casino_ggr: 'tiers.tier_5.generated_external_casino_ggr',
                    tier_5_generated_internal_casino_ggr: 'tiers.tier_5.generated_internal_casino_ggr',
                    tier_5_poker_revenue_share: 'tiers.tier_5.poker_revenue_share',
                    tier_5_revenue_shared: 'tiers.tier_5.revenue_shared',
                    tier_6_casino_revenue_share: 'tiers.tier_6.casino_revenue_share',
                    tier_6_generated_external_casino_ggr: 'tiers.tier_6.generated_external_casino_ggr',
                    tier_6_generated_internal_casino_ggr: 'tiers.tier_6.generated_internal_casino_ggr',
                    tier_6_poker_revenue_share: 'tiers.tier_6.poker_revenue_share',
                    tier_6_revenue_shared: 'tiers.tier_6.revenue_shared',
                };

                return mapper[m];
            })
        );
    }
}
