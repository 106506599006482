import {useContext} from 'react';
import {IntlContext} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {AccountVerificationStatus} from '@models/generated/graphql';

import {localizedAccountVerificationStatus} from '../../app/intl/shared-resources/accountVerificationStatus';

export type AccountVerificationStatusValue = {
    account_verification_status: AccountVerificationStatus;
};

export const useAccountVerificationStatusFormatter = (json: string): ChipKeyValue[] => {
    const {formatMessage} = useContext(IntlContext);
    const value: AccountVerificationStatusValue = JSON.parse(json);
    return value?.account_verification_status
        ? [{key: formatMessage(localizedAccountVerificationStatus[value?.account_verification_status])}]
        : null;
};
