import React from 'react';

import MuiDataGrid, {MuiDataGridProps} from './MuiDataGrid';

export type MuiDataGridClientProps = Omit<MuiDataGridProps, 'sortingMode' | 'paginationMode' | 'rowCount'>;

const withDataGridClient = (WrappedDataGrid: (props: MuiDataGridProps) => JSX.Element) => (props: MuiDataGridClientProps) => {
    const {rows} = props;
    return <WrappedDataGrid {...props} rowCount={rows?.length} sortingMode="client" paginationMode="client" />;
};

export const MuiDataGridClient = withDataGridClient(MuiDataGrid);
