import React, {Fragment} from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {useDataGridSelectedIds} from '@components/data-grid/mui';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar from '@components/toolbar/Toolbar';
import {securityWorkspaceReadPermissions} from '@models/permissions/permissions';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {Policy} from '@auth';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {ViewType} from '@redux/view';
import {removeItemsFromArray} from '@utils/array';

import {Sorting} from 'src/common/types';
import {useJurisdictionFeature} from 'src/features/app/config/hooks';
import {FeatureName} from 'src/features/app/config/types';
import {UserProfileDownloadCsvButton} from 'src/features/block-user-profile-actions';
import {
    DataGridUserProfileServer,
    UserProfileFiltersObsolete,
    UserProfileQueryFilters,
    useUserProfiles,
} from 'src/features/block-user-profile-list';
import {useUserProfileSettings} from 'src/features/block-user-profile-list/hooks';
import {SecurityWorkspaceBulkPlayerButton, SecurityWorkspaceManageLabelButton} from 'src/features/block-workspace-security-toolbar';
import {ModuleName} from '../common/routeEnums';
import {withModule} from '../features/module-shared/components/ModuleHoc';

const localized = defineMessages({
    title: {
        id: 'SecurityWorkspace_title',
        defaultMessage: 'Security Workspace',
    },
});

function SecurityWorkspace() {
    const is_FN_LN_Date_Countries_ColumsAndFiltersAvailiable = useJurisdictionFeature({
        featureName: FeatureName.SecurityWorkspace_FN_LN_Date_Countries,
    });
    const {formatMessage} = useIntl();
    const viewType: ViewType = 'SecurityWorkspace';
    const triggers = [RealtimeMessageTrigger.Add, RealtimeMessageTrigger.Update];
    const realtimeMode = RealtimeUpdatesMode.Confirm;
    const pinnedColumns: UserProfileViewModelKeys[] = ['uid_link_security', 'username'];
    const columns: UserProfileViewModelKeys[] = [
        'uid_link_security',
        'networks_PKW',
        'networks_BL',
        is_FN_LN_Date_Countries_ColumsAndFiltersAvailiable ? 'country' : null,
        'username',
        is_FN_LN_Date_Countries_ColumsAndFiltersAvailiable ? 'first_name' : null,
        is_FN_LN_Date_Countries_ColumsAndFiltersAvailiable ? 'last_name' : null,
        'email',
        'phone',
        'referrer',
        'finance.balance',
        is_FN_LN_Date_Countries_ColumsAndFiltersAvailiable ? 'account_status' : null,
        'labels',
        'security_cases',
        is_FN_LN_Date_Countries_ColumsAndFiltersAvailiable ? null : 'account_status',
        'latest_login.logged_at_ts.seconds',
        is_FN_LN_Date_Countries_ColumsAndFiltersAvailiable ? 'date_of_joining.seconds' : null,
        is_FN_LN_Date_Countries_ColumsAndFiltersAvailiable ? 'login_countries' : null,
    ].filter(key => key) as UserProfileViewModelKeys[];

    const defaultSorting: Sorting<UserProfileViewModelKeys>[] = [
        {
            field: 'date_of_joining.seconds',
            sort: 'desc',
        },
    ];
    const {items, totalCount, searchFilter, filterString, handlePageChange, handlePageSizeChange, handleSortChange, handleFilterChange} =
        useUserProfiles({
            viewType,
            displayName: localized.title,
            fields: columns,
            realtimeMode,
            triggers,
            defaultSorting,
            validateFilter: () => true,
            syncWithUrl: true,
        });
    const {selectedIds, handleSelect} = useDataGridSelectedIds();
    const {visibleColumns, allColumns, onVisibleColumnsChange} = useUserProfileSettings({featureName: 'SecurityWorkspaceGrid', columns});

    return (
        <Fragment>
            <LayoutQueue
                header={
                    <PageHeader
                        header={localized.title}
                        actions={
                            <>
                                <SecurityWorkspaceManageLabelButton selectedIds={selectedIds} />
                                <SecurityWorkspaceBulkPlayerButton selectedIds={selectedIds} />
                                <UserProfileDownloadCsvButton
                                    filterString={filterString}
                                    filename={formatMessage(localized.title)}
                                    keys={removeItemsFromArray(allColumns, 'finance.balance', 'email', 'phone')}
                                />
                            </>
                        }
                    />
                }
                toolbar={
                    <Toolbar
                        primary={
                            is_FN_LN_Date_Countries_ColumsAndFiltersAvailiable ? (
                                <UserProfileQueryFilters
                                    model={filterString}
                                    onChange={handleFilterChange}
                                    availableFilters={[
                                        'uidWithPlaceholder',
                                        'username',
                                        'nickname',
                                        'firstName',
                                        'lastName',
                                        'labels',
                                        'email',
                                        'phone',
                                        'security_cases',
                                        'networks_PKW',
                                        'networks_BL',
                                        'referrerId',
                                        'accountStatus',
                                        'registrationCountry',
                                        'loginCountry',
                                        'registrationDate',
                                    ]}
                                />
                            ) : (
                                <UserProfileFiltersObsolete
                                    mode="multi"
                                    filter={filterString}
                                    onFilterChange={handleFilterChange}
                                    filters={[
                                        'uid',
                                        'username',
                                        'nickname',
                                        'labels',
                                        'email',
                                        'phone',
                                        'security_cases',
                                        'networks_PKW',
                                        'networks_BL',
                                        'referrerId',
                                        'accountStatus',
                                    ]}
                                />
                            )
                        }
                    />
                }
                body={
                    <DataGridUserProfileServer
                        columns={columns}
                        rows={items}
                        rowCount={totalCount}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSortModelChange={handleSortChange}
                        page={searchFilter?.paging?.page}
                        pageSize={searchFilter?.paging?.pageSize}
                        sortModel={searchFilter?.sorting}
                        checkboxSelection={true}
                        selectedIds={selectedIds}
                        onSelect={handleSelect}
                        hasColumnSelection
                        pinnedColumns={pinnedColumns}
                        isFlexible={!is_FN_LN_Date_Countries_ColumsAndFiltersAvailiable}
                        visibleColumns={visibleColumns}
                        onColumnsVisibilityUpdate={onVisibleColumnsChange}
                    />
                }
            />
        </Fragment>
    );
}

export const securityWorkspaceResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'SecurityWorkspace',
    rules: new PoliciesAccessRule([new Policy(ModuleName.SecurityWorkspace, null, 'read')]),
});

export default withModule(SecurityWorkspace, securityWorkspaceReadPermissions);
