import React, {useEffect, useRef, useState} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {v4 as uuid} from 'uuid';

import {toSearchFilter} from '@utils';

import {Filter as FilterKeyValue} from 'src/common/types';
import {useJurisdictionFeature} from '../../../app/config/hooks';
import {gridModuleActions} from '../../../module-shared/actions';
import {Filter, FilterProps, MultipleKeysFilter} from '../types';

export type FilterInnerProps<T> = {
    filterKey?: string;
    onKeyChange?: (key: string) => void;
    onSubmit?: (value: any) => void;
    filter: Filter;
    value?: T;
};

const useClasses = makeStyles()(theme => ({
    filter: {
        display: 'flex',
        flexDirection: 'row',
        margin: theme.spacing(0, 0, 'auto', 0),
    },

    filterForm: {
        marginBottom: 'auto',
    },
    inheritParentComponent: {
        flexGrow: 'inherit',
    },
}));

export type SingleInputModel = {
    inputValue: string;
};

const withFilter =
    <T,>(WrappedComponent: React.ComponentType<FilterInnerProps<T>>) =>
    ({filter, filterString, domain, onFilterChange}: FilterProps) => {
        const actions = gridModuleActions(domain);
        const dispatch = useDispatch();
        const {classes, cx} = useClasses();

        const filterKey = filter.key as string;
        const [key, setKey] = useState(filterKey ?? filter?.options?.[0]?.value);

        const getFilterKeyValueFromFilterString = () => {
            const hasFilter = (filterKeyValue: FilterKeyValue): boolean => {
                return filterKey ? filterKeyValue.key === filterKey : (filter as MultipleKeysFilter).keys.includes(filterKeyValue.key);
            };

            const searchFilter = toSearchFilter(filterString);
            return searchFilter.filter.find(hasFilter);
        };

        const filterKeyValue = getFilterKeyValueFromFilterString();
        if (filterKeyValue?.key && key !== filterKeyValue.key) {
            setKey(filterKeyValue.key);
        }
        const value = filterKeyValue?.value ?? null;

        const isAvailable = useJurisdictionFeature({
            moduleName: filter.moduleName,
            submoduleName: filter.submoduleName,
            featureName: filter.featureName,
            permissions: filter.permissions,
        });
        const form = useForm<SingleInputModel>({defaultValues: {inputValue: value}});
        const watched = useWatch({control: form.control});
        const formId = useRef(uuid());

        useEffect(() => {
            form.handleSubmit(onSubmit)();
        }, [watched]);

        const onKeyChange = (newKey: string) => {
            if (value) {
                const filter: FilterKeyValue[] = [
                    {key, value: ''},
                    {key: newKey, value: value.toString()},
                ];
                submitFilter(filter);
            }
            setKey(newKey);
        };

        const onSubmit = (submittingValue: SingleInputModel) => {
            if (form.formState.isDirty) {
                const filter: FilterKeyValue = {key, value: submittingValue.inputValue};
                submitFilter(filter);
            }
        };

        const submitFilter = (filter: FilterKeyValue<any, string> | FilterKeyValue<any, string>[]) => {
            if (domain) {
                dispatch(actions.itemsFilter(filter));
            }
            if (onFilterChange) {
                onFilterChange(Array.isArray(filter) ? filter : [filter]);
            }
        };

        return isAvailable ? (
            <FormProvider {...form}>
                <form
                    name={`filterForm-${formId.current}`}
                    className={cx(filter.limitMaxWidth ? '' : classes.inheritParentComponent, classes.filterForm)}
                    onSubmit={e => {
                        e.preventDefault();
                    }}
                >
                    <Box className={classes.filter}>
                        <WrappedComponent value={value} filter={filter} filterKey={key} onKeyChange={onKeyChange} />
                    </Box>
                </form>
            </FormProvider>
        ) : (
            <></>
        );
    };

export default withFilter;
