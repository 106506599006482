import React from 'react';
import {Control, Controller, useWatch} from 'react-hook-form';
import {defineMessages, useIntl} from 'react-intl';
import {Box, Chip, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {EditMode} from '@components/button/EditButton';
import {MemoizedDefaultChip} from '@components/chip/ChipRenderer';
import {ChipType, ChipVariant} from '@components/chip/types';
import {withEditButton} from '@components/editable/EditButtonHoc';
import {
    FormError,
    FormSelect,
    FormTextInputCleanable,
    FormTextInputDefault,
    RuleType,
    useCustomValidationFormatter,
    useValidationFormatter,
} from '@components/input';
import {ReferrerType, UserReferralFormModel} from '@services/playerReferralService';
import {CustomTheme} from '@style';

import {localizedReferrerType} from 'src/features/app/intl/shared-resources/referrerType';
import {SelectOption} from 'src/features/module-shared/types';
import {emptyCellCharacter} from 'src/features/module-shared/utils';
import {PlayerIdAutocomplete} from 'src/features/view-autocomplete/components/PlayerIdAutocomplete';
import {ReferralInfoMode} from '../types';

const localized = defineMessages({
    type: {
        id: 'ReferrerInfo_Type',
        defaultMessage: 'Type',
    },
    typeFieldName: {
        id: 'ReferrerInfo_typeFieldName',
        defaultMessage: 'Type',
    },
    trackingIdFieldName: {
        id: 'ReferrerInfo_trackingIdFieldName',
        defaultMessage: 'Tracking ID',
    },
    invalidReferenceMessage: {
        id: 'ReferrerInfo_invalidReferenceMessage',
        defaultMessage: 'Invalid Reference',
    },
    selfReferrerMessage: {
        id: 'ReferrerInfo_selfReferrerMessage',
        defaultMessage: 'Not allowed to set the user as the referrer of himself',
    },
    agentLabel: {
        id: 'ReferrerInfo_agentLabel',
        defaultMessage: 'Agent',
    },
});

const useClasses = makeStyles()((theme: CustomTheme) => ({
    userReferralReferrerTypeSelect: {
        margin: 0,
        minWidth: '230px',
    },
    userReferralBtagInput: {
        marginBottom: 0,
        '& .MuiOutlinedInput-root': {
            height: '36px',
        },
    },
    userReferralInfoReadMode: {
        display: 'flex',
        columnGap: theme.spacing(1.25),
        marginLeft: theme.spacing(2),
        alignItems: 'center',
    },
    userReferralInput: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            height: '36px',
        },
    },
}));

type UserReferralInfoFormContentProps = {
    control: Control<UserReferralFormModel>;
    mode: EditMode;
    referrerInfoMode: ReferralInfoMode;
    referral: UserReferralFormModel;
    userId: string;
    isAgentReferrer: boolean;
};

export const getBtagRegex = () => new RegExp('^((a|A)_(\\d+)(b|B)_(\\d+)((c|C)_([a-zA-Z0-9_-]*))?)$');

const UserReferralInfoFormContentInternal = ({
    control,
    referral,
    userId,
    isAgentReferrer,
    mode,
    referrerInfoMode,
}: UserReferralInfoFormContentProps) => {
    const {classes} = useClasses();
    const validationMessageFormatter = useValidationFormatter();
    const customValidationFormatter = useCustomValidationFormatter();
    const {formatMessage} = useIntl();

    const watched = useWatch({
        control,
    });

    const typeSelectOptions: SelectOption[] = Object.values(ReferrerType).map<SelectOption>(type => ({
        value: type,
        label: formatMessage(localizedReferrerType[type]),
    }));

    return mode === 'Edit' ? (
        <>
            <Controller
                render={({field, fieldState}) => (
                    <Box>
                        <FormSelect
                            key="referrer_type"
                            value={field.value}
                            onChange={field.onChange}
                            options={typeSelectOptions}
                            fieldState={fieldState}
                            label={localized.type}
                            displayEmpty
                            hideError
                            className={classes.userReferralReferrerTypeSelect}
                            disabled={referrerInfoMode === 'remove-only'}
                        />
                        {fieldState.invalid ? <FormError>{fieldState.error?.message}</FormError> : <></>}
                    </Box>
                )}
                name="referrer_type"
                control={control}
                defaultValue={referral?.referrer_type}
                rules={{
                    required: validationMessageFormatter(RuleType.Required, localized.typeFieldName),
                }}
            />
            <Controller
                render={({field, fieldState}) => {
                    return watched?.referrer_type === ReferrerType.P2P ? (
                        referrerInfoMode === 'remove-only' ? (
                            <FormTextInputCleanable
                                className={classes.userReferralInput}
                                value={field.value}
                                onChange={field.onChange}
                                clear={() => field.onChange(null)}
                                placeholder={localized.trackingIdFieldName}
                                hasBottomSpacing={false}
                                fullWidth={false}
                                disabled
                            />
                        ) : (
                            <PlayerIdAutocomplete
                                placeholder={localized.trackingIdFieldName}
                                optionsFilter={v => v !== userId}
                                value={field.value}
                                onValueChange={field.onChange}
                                errorMessage={fieldState.invalid ? fieldState.error?.message : null}
                            ></PlayerIdAutocomplete>
                        )
                    ) : (
                        <FormTextInputDefault
                            className={classes.userReferralBtagInput}
                            value={field.value}
                            onChange={field.onChange}
                            label={localized.trackingIdFieldName}
                            placeholder={localized.trackingIdFieldName}
                            fieldState={fieldState}
                            hideLabel={true}
                        />
                    );
                }}
                name="referrer_id"
                control={control}
                defaultValue={referral?.referrer_id}
                rules={{
                    pattern:
                        watched?.referrer_type === ReferrerType.Affiliate
                            ? {
                                  value: getBtagRegex(),
                                  message: customValidationFormatter(localized.invalidReferenceMessage),
                              }
                            : null,
                    validate:
                        watched?.referrer_type === ReferrerType.P2P
                            ? {
                                  notAllowed: value => (value === userId ? customValidationFormatter(localized.selfReferrerMessage) : null),
                              }
                            : null,
                }}
            />
        </>
    ) : (
        <Box className={classes.userReferralInfoReadMode}>
            <Typography variant="body1">{referral?.referrer_type ?? emptyCellCharacter}</Typography>
            {referral?.referrer_id ? (
                <MemoizedDefaultChip value={referral?.referrer_id} variant={ChipVariant.Grey} type={ChipType.Status} />
            ) : null}
            {isAgentReferrer && referral?.referrer_id ? <Chip label={formatMessage(localized.agentLabel)} size="small" /> : null}
        </Box>
    );
};

export const UserReferralInfoFormContent = withEditButton(UserReferralInfoFormContentInternal);
