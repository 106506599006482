import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';

import {QueryGetUsersLoginHistoryArgs, UserLogin, UserLoginFilter} from '@models/generated/graphql';
import {Filter, UserLoginQueryFields, UserLoginServerFilterKeys, UserLoginServerTextFilterKeys} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';

import {DynamicConfig} from 'src/configuration';
import {JurisdictionConfigService} from 'src/features/app/config/services';
import {ServiceTypes} from '../inversify';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';

@injectable()
export class UserLoginService extends EntityBaseGqlService<
    QueryGetUsersLoginHistoryArgs,
    UserLoginQueryFields,
    UserLoginServerTextFilterKeys
> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.JurisdictionConfigService) jurisdictionConfigService: JurisdictionConfigService,
        @inject(ServiceTypes.Config) config: DynamicConfig
    ) {
        super(client, mapper, new UserLoginRequestBuilder(jurisdictionConfigService, config));
    }
}

export class UserLoginRequestBuilder extends GqlRequestBuilder<
    QueryGetUsersLoginHistoryArgs,
    UserLoginQueryFields,
    UserLoginServerTextFilterKeys
> {
    protected buildFilter(filter: Filter<UserLoginServerFilterKeys>): {filter: UserLoginFilter} {
        return {
            filter: {
                text: this.getGQLTextFilter([
                    ...Object.keys(this.filterFieldsMapper).map((key: UserLoginServerTextFilterKeys) =>
                        this.toGQLTextFilter(this.filterFieldsMapper[key], filter[key] as string, this.transformTextMapper[key])
                    ),
                ]),
                logged_at_ts: this.toGQLDateRange(filter['logged_at_ts.from'], filter['logged_at_ts.to']),
            },
        };
    }

    public buildQuery(fields: UserLoginQueryFields[]): DocumentNode {
        return gql`
            query getGeoChecks($filter: UserLoginFilter, $sort: Sorting, $start: Int, $end: Int) {
                getUsersLoginHistory(filter: $filter, sort: $sort, end: $end, start: $start) {
                    items {
                        connection_type @include(if: ${this.hasField(fields, 'connection_type')})
                        correlation_id @include(if: ${this.hasField(fields, 'correlation_id')})
                        current_balance @include(if: ${this.hasField(fields, 'current_balance')})
                        device @include(if: ${this.hasAnyField(fields, this.getDeviceQueryItems())}) {
                            agent @include(if: ${this.hasField(fields, 'device.agent')})
                            mac_address @include(if: ${this.hasField(fields, 'device.mac_address')})
                            model @include(if: ${this.hasField(fields, 'device.model')})
                            name @include(if: ${this.hasField(fields, 'device.name')})
                            operating_system @include(if: ${this.hasField(fields, 'device.operating_system')})
                            uuid @include(if: ${this.hasField(fields, 'device.uuid')})
                        }                    
                        ip @include(if: ${this.hasField(fields, 'ip')})
                        ip_quality_score @include(if: ${this.hasField(fields, 'ip_quality_score')})
                        isp @include(if: ${this.hasField(fields, 'isp')})
                        language @include(if: ${this.hasField(fields, 'language')})
                        location @include(if: ${this.hasAnyField(fields, this.getLocationQueryItems())}) {
                            city @include(if: ${this.hasField(fields, 'location.city')})
                            country @include(if: ${this.hasField(fields, 'location.country')})
                            latitude @include(if: ${this.hasField(fields, 'location.latitude')})
                            longitude @include(if: ${this.hasField(fields, 'location.longitude')})
                            state @include(if: ${this.hasField(fields, 'location.state')})
                        }                    
                        logged_at_ts @include(if: ${this.hasAnyField(fields, this.getLoggedAtQueryItems())}) {
                            seconds @include(if: ${this.hasField(fields, 'logged_at_ts.seconds')})
                        }
                        login_status @include(if: ${this.hasField(fields, 'login_status')})
                        login_type @include(if: ${this.hasField(fields, 'login_type')})
                        logout_info ${this.hasField(fields, 'logout_info') ? '' : '@client'} {
                            logged_out_at_ts {
                                seconds
                            }
                        }
                        recaptcha_reasons @include(if: ${this.hasField(fields, 'recaptcha_reasons')})
                        recaptcha_score @include(if: ${this.hasField(fields, 'recaptcha_score')})
                        session_id @include(if: ${this.hasField(fields, 'session_id')})
                        uid @include(if: ${this.hasField(fields, 'uid')})
                        username @include(if: ${this.hasField(fields, 'username')})
                        vpn @include(if: ${this.hasField(fields, 'vpn')})
                    }
                    total_count
                }
            }
        `;
    }

    private getLoggedAtQueryItems = (): UserLoginQueryFields[] => {
        return ['logged_at_ts.seconds'];
    };

    private getDeviceQueryItems = (): UserLoginQueryFields[] => {
        return ['device.agent', 'device.mac_address', 'device.model', 'device.name', 'device.operating_system', 'device.uuid'];
    };

    private getLocationQueryItems = (): UserLoginQueryFields[] => {
        return ['location.city', 'location.country', 'location.latitude', 'location.longitude', 'location.state'];
    };

    private filterFieldsMapper: Partial<Record<UserLoginServerTextFilterKeys, string[]>> = {
        id: [nameof<UserLogin>(m => m.session_id)],
        uid: [nameof<UserLogin>(m => m.uid)],
        connection_type: [nameof<UserLogin>(m => m.connection_type)],
        correlation_id: [nameof<UserLogin>(m => m.correlation_id)],
        current_balance: [nameof<UserLogin>(m => m.current_balance)],
        'device.agent': [nameof.full<UserLogin>(m => m.device.agent)],
        'device.mac_address': [nameof.full<UserLogin>(m => m.device.mac_address)],
        'device.model': [nameof.full<UserLogin>(m => m.device.model)],
        'device.name': [nameof.full<UserLogin>(m => m.device.name)],
        'device.operating_system': [nameof.full<UserLogin>(m => m.device.operating_system)],
        'device.uuid': [nameof.full<UserLogin>(m => m.device.uuid)],
        ip: [nameof<UserLogin>(m => m.ip)],
        ip_quality_score: [nameof<UserLogin>(m => m.ip_quality_score)],
        isp: [nameof<UserLogin>(m => m.isp)],
        language: [nameof<UserLogin>(m => m.language)],
        'location.city': [nameof.full<UserLogin>(m => m.location.city)],
        'location.country': [nameof.full<UserLogin>(m => m.location.country)],
        'location.latitude': [nameof.full<UserLogin>(m => m.location.latitude)],
        'location.longitude': [nameof.full<UserLogin>(m => m.location.longitude)],
        'location.state': [nameof.full<UserLogin>(m => m.location.state)],
        'logged_at_ts.seconds': [nameof.full<UserLogin>(m => m.logged_at_ts.seconds)],
        login_status: [nameof<UserLogin>(m => m.login_status)],
        login_type: [nameof<UserLogin>(m => m.login_type)],
        recaptcha_reasons: [nameof<UserLogin>(m => m.recaptcha_reasons)],
        recaptcha_score: [nameof<UserLogin>(m => m.recaptcha_score)],
        session_id: [nameof<UserLogin>(m => m.session_id)],
        username: [nameof<UserLogin>(m => m.username)],
        vpn: [nameof<UserLogin>(m => m.vpn)],
        uidUsernameIpDeviceIdCountry: [
            nameof.full<UserLogin>(m => m.uid),
            nameof.full<UserLogin>(m => m.username),
            nameof.full<UserLogin>(m => m.ip),
            nameof.full<UserLogin>(m => m.device.uuid),
            nameof.full<UserLogin>(m => m.location.country),
        ],
    };

    private transformTextMapper: Partial<Record<UserLoginServerTextFilterKeys, (value: string) => string>> = {
        'location.country': this.phraseSearch,
    };
}
