import {defineMessages} from 'react-intl';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {renderChip} from '@components/chip/ChipRenderer';
import {
    AmountCurrencyCellProps,
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    MuiDataGridClient,
    MuiDataGridServer,
    renderAmountCurrencyCellWithTooltip,
    renderCellHeader,
    renderCellWithTooltip,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import {BackpackViewModel, BackpackViewModelKeys} from '@models/backpack';
import {BackpackItemStatus} from '@models/generated/graphql';

const localizedHeaders = defineMessages({
    bonusName: {
        id: 'DataGridBackpack_bonusName',
        defaultMessage: 'Bonus Name',
    },
    bonusStatus: {
        id: 'DataGridBackpack_bonusStatus',
        defaultMessage: 'Ticket Status',
    },
    createdAt: {
        id: 'DataGridBackpack_createdAt',
        defaultMessage: 'Timestamp',
    },
    marketingCode: {
        id: 'DataGridBackpack_marketingCode',
        defaultMessage: 'Bonus Code',
    },
    serialNumber: {
        id: 'DataGridBackpack_serialNumber',
        defaultMessage: 'Serial Number',
    },
    usedAt: {
        id: 'DataGridBackpack_usedAt',
        defaultMessage: 'Used At',
    },
    value: {
        id: 'DataGridBackpack_value',
        defaultMessage: 'Value of Ticket',
    },
    toolLabel: {
        id: 'DataGridBackpack_toolLabel',
        defaultMessage: 'MTT Label',
    },
});

export class DataGridBackpackConfigFactory extends DataGridEntityConfigFactory<BackpackViewModelKeys, BackpackViewModel> {
    getColumnConfig(): Partial<Record<BackpackViewModelKeys, DataGridEntityColDef<BackpackViewModelKeys>>> {
        const columnsMapping: Partial<Record<BackpackViewModelKeys, DataGridEntityColDef<BackpackViewModelKeys>>> = {
            bonus_name: {
                field: 'bonus_name',
                valueGetter: p => this.getBonusName(p),
                renderHeader: () => renderCellHeader(localizedHeaders.bonusName),
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 1.2,
            },
            bonus_status: {
                field: 'bonus_status',
                valueGetter: p => this.getBonusStatus(p),
                renderHeader: () => renderCellHeader(localizedHeaders.bonusStatus),
                renderCell: p => renderChip<BackpackItemStatus>(p.value as BackpackItemStatus, nameof<BackpackItemStatus>()),
                sortable: false,
                flex: 1,
            },
            'created_at.seconds': {
                field: 'created_at.seconds',
                valueGetter: p => this.getCreatedDate(p),
                renderHeader: () => renderCellHeader(localizedHeaders.createdAt),
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 1.5,
            },
            marketing_code: {
                field: 'marketing_code',
                valueGetter: p => this.getMarketingCode(p),
                renderHeader: () => renderCellHeader(localizedHeaders.marketingCode),
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 1,
            },
            serial_number: {
                field: 'serial_number',
                valueGetter: p => this.getSerialNumber(p),
                renderHeader: () => renderCellHeader(localizedHeaders.serialNumber),
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.7,
            },
            'used_at.seconds': {
                field: 'used_at.seconds',
                valueGetter: p => this.getUsedDate(p),
                renderHeader: () => renderCellHeader(localizedHeaders.usedAt),
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 1.5,
            },
            value: {
                field: 'value',
                valueGetter: p => this.getValueWithCurrency(p),
                renderHeader: () => renderCellHeader(localizedHeaders.value),
                renderCell: renderAmountCurrencyCellWithTooltip,
                sortable: false,
                flex: 1,
            },
            tool_label: {
                field: 'tool_label',
                valueGetter: p => this.getToolLabel(p),
                renderHeader: () => renderCellHeader(localizedHeaders.toolLabel),
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 1.2,
            },
        };

        return columnsMapping;
    }

    private getBonusName(p: GridValueGetterParams) {
        return this.getRow(p)?.bonus_name;
    }

    private getBonusStatus(p: GridValueGetterParams) {
        return this.getRow(p)?.bonus_status;
    }

    private getCreatedDate(p: GridValueGetterParams) {
        return this.getRow(p)?.created_at;
    }

    private getMarketingCode(p: GridValueGetterParams) {
        return this.getRow(p)?.marketing_code;
    }

    private getSerialNumber(p: GridValueGetterParams) {
        return this.getRow(p)?.serial_number;
    }

    private getUsedDate(p: GridValueGetterParams) {
        return this.getRow(p)?.used_at;
    }

    private getValueWithCurrency(p: GridValueGetterParams): AmountCurrencyCellProps {
        return {amount: this.getRow(p)?.value, currency: this.getCurrency(p)};
    }

    private getCurrency(p: GridValueGetterParams) {
        return this.getRow(p)?.currency;
    }

    private getToolLabel(p: GridValueGetterParams) {
        return this.getRow(p)?.tool_label;
    }
}

export const DataGridBackpackClient = withDataGridEntityClient<BackpackViewModelKeys, BackpackViewModel>(MuiDataGridClient, () =>
    new DataGridBackpackConfigFactory().getColumnConfig()
);

export const DataGridBackpackServer = withDataGridEntityServer<BackpackViewModelKeys, BackpackViewModel>(MuiDataGridServer, () =>
    new DataGridBackpackConfigFactory().getColumnConfig()
);
