import {injectable} from 'inversify';

import {BulkItemStatus, FeatureAccessLockInput, FeatureAccessStatus, UserAccountStatus} from '@models/generated/graphql';

import {
    AccountLockActionItem,
    AddSecurityCaseActionItem,
    ApplyStrategyResponse,
    BulkActionItemPayload,
    BulkActionKey,
    DepositLockActionItem,
    IBulkApplyStrategy,
    LobbyLockActionItem,
    RemoveSecurityCaseActionItem,
    SendMessageActionItem,
    UpdateSecurityCaseActionItem,
    WithdrawalLockActionItem,
} from '../../block-bulk-actions';
import {UserProfileGridItem} from '../../data-grids/types';
import {EditSecurityCasesSubmitModel} from '../../player-actions/cases-actions/types';
import {Message} from '../../player-message/types';

export type ApplyAccountLockRequest = {players: UserProfileGridItem[]; value: boolean};

@injectable()
export class ApplyAccountLockStrategy implements IBulkApplyStrategy<ApplyAccountLockRequest> {
    process({players, value}: ApplyAccountLockRequest): ApplyStrategyResponse {
        const actionItems: BulkActionItemPayload[] = players.reduce<BulkActionItemPayload[]>((result, item) => {
            const isStatusChanged = value !== null && (item.account_status === UserAccountStatus.Locked) !== value;
            const actionItem = {
                actionKey: BulkActionKey.AccountLock,
                value: {uid: item.uid, isLocked: value} as AccountLockActionItem,
                itemId: item.serverId,
                status: BulkItemStatus.Pending,
            };
            return isStatusChanged ? [...result, actionItem] : result;
        }, []);

        return {actionKey: BulkActionKey.AccountLock, items: actionItems};
    }
}

export type ApplyWithdrawalLockRequest = {players: UserProfileGridItem[]; value: boolean};

@injectable()
export class ApplyWithdrawalLockStrategy implements IBulkApplyStrategy<ApplyWithdrawalLockRequest> {
    process({players, value}: ApplyWithdrawalLockRequest): ApplyStrategyResponse {
        const actionItems: BulkActionItemPayload[] = players.reduce<BulkActionItemPayload[]>((result, item) => {
            const isStatusChanged = value !== null && (item.withdrawal_status === FeatureAccessStatus.Locked) !== value;
            const actionItem = {
                actionKey: BulkActionKey.WithdrawalLock,
                value: {uid: item.uid, isLocked: value} as WithdrawalLockActionItem,
                itemId: item.serverId,
                status: BulkItemStatus.Pending,
            };
            return isStatusChanged ? [...result, actionItem] : result;
        }, []);

        return {actionKey: BulkActionKey.WithdrawalLock, items: actionItems};
    }
}

export type ApplyDepositLockRequest = {players: UserProfileGridItem[]; value: boolean};

@injectable()
export class ApplyDepositLockStrategy implements IBulkApplyStrategy<ApplyDepositLockRequest> {
    process({players, value}: ApplyDepositLockRequest): ApplyStrategyResponse {
        const actionItems: BulkActionItemPayload[] = players.reduce<BulkActionItemPayload[]>((result, item) => {
            const isStatusChanged = value !== null && (item.deposit_status === FeatureAccessStatus.Locked) !== value;
            const actionItem = {
                actionKey: BulkActionKey.DepositLock,
                value: {uid: item.uid, isLocked: value} as DepositLockActionItem,
                itemId: item.serverId,
                status: BulkItemStatus.Pending,
            };
            return isStatusChanged ? [...result, actionItem] : result;
        }, []);

        return {actionKey: BulkActionKey.DepositLock, items: actionItems};
    }
}

export type ApplyLobbyLockRequest = {players: UserProfileGridItem[]; value: boolean};

@injectable()
export class ApplyLobbyLockStrategy implements IBulkApplyStrategy<ApplyLobbyLockRequest> {
    process({players, value}: ApplyLobbyLockRequest): ApplyStrategyResponse {
        const actionItems: BulkActionItemPayload[] = players.reduce<BulkActionItemPayload[]>((result, item) => {
            const isStatusChanged = value !== null && (item.lobby_access_status === FeatureAccessStatus.Locked) !== value;
            const actionItem = {
                actionKey: BulkActionKey.LobbyLock,
                value: {uid: item.uid, isLocked: value} as LobbyLockActionItem,
                itemId: item.serverId,
                status: BulkItemStatus.Pending,
            };
            return isStatusChanged ? [...result, actionItem] : result;
        }, []);

        return {actionKey: BulkActionKey.LobbyLock, items: actionItems};
    }
}

export type ApplyCasinoLockRequest = {
    players: UserProfileGridItem[];
    value: FeatureAccessStatus;
};

@injectable()
export class ApplyCasinoLockStrategy implements IBulkApplyStrategy<ApplyCasinoLockRequest> {
    process({players, value}: ApplyCasinoLockRequest): ApplyStrategyResponse {
        const items = players.map<BulkActionItemPayload<FeatureAccessLockInput>>(item => ({
            actionKey: BulkActionKey.CasinoLock,
            value: value && item.casino_status !== value ? {uid: item.uid, feature_status: value} : null,
            itemId: item.serverId,
            status: BulkItemStatus.Pending,
        }));

        return {actionKey: BulkActionKey.CasinoLock, items};
    }
}

export type ApplySportsbookLockRequest = {
    players: UserProfileGridItem[];
    value: FeatureAccessStatus;
};

@injectable()
export class ApplySportsbookLockStrategy implements IBulkApplyStrategy<ApplySportsbookLockRequest> {
    process({players, value}: ApplySportsbookLockRequest): ApplyStrategyResponse {
        const items = players.map<BulkActionItemPayload<FeatureAccessLockInput>>(item => ({
            actionKey: BulkActionKey.SportsbookLock,
            value: value && item.sportsbook_status !== value ? {uid: item.uid, feature_status: value} : null,
            itemId: item.serverId,
            status: BulkItemStatus.Pending,
        }));

        return {actionKey: BulkActionKey.SportsbookLock, items};
    }
}

export type ApplyP2PLockRequest = {
    players: UserProfileGridItem[];
    value: FeatureAccessStatus;
};

@injectable()
export class ApplyP2PLockStrategy implements IBulkApplyStrategy<ApplyP2PLockRequest> {
    process({players, value}: ApplyP2PLockRequest): ApplyStrategyResponse {
        const items = players.map<BulkActionItemPayload>(item => ({
            actionKey: BulkActionKey.P2PTransferLock,
            value: value && item.p2p_transfer_status !== value ? ({uid: item.uid, feature_status: value} as FeatureAccessLockInput) : null,
            itemId: item.serverId,
            status: BulkItemStatus.Pending,
        }));

        return {actionKey: BulkActionKey.P2PTransferLock, items};
    }
}

export type ApplySecurityCasesRequest = {
    players: UserProfileGridItem[];
    value: EditSecurityCasesSubmitModel;
};

@injectable()
export class ApplySecurityCasesAddStrategy implements IBulkApplyStrategy<ApplySecurityCasesRequest> {
    process({players, value}: ApplySecurityCasesRequest): ApplyStrategyResponse {
        const items = players
            ?.reduce<BulkActionItemPayload[]>(
                (result: BulkActionItemPayload[], i) => [...result, this.getSecurityCasesAddItems(i, value)],
                []
            )
            ?.filter(i => i.value !== null);

        return {actionKey: BulkActionKey.SecurityCasesAdd, items};
    }

    private getSecurityCasesAddItems(player: UserProfileGridItem, value: EditSecurityCasesSubmitModel): BulkActionItemPayload {
        const addItemCases = value?.add?.filter(i => !player.security_cases?.map(c => c.type)?.includes(i.type));

        return {
            actionKey: BulkActionKey.SecurityCasesAdd,
            value: addItemCases.length ? ({add: addItemCases, uid: player.uid} as AddSecurityCaseActionItem) : null,
            itemId: player.serverId,
            status: BulkItemStatus.Pending,
        };
    }
}

@injectable()
export class ApplySecurityCasesUpdateStrategy implements IBulkApplyStrategy<ApplySecurityCasesRequest> {
    process({players, value}: ApplySecurityCasesRequest): ApplyStrategyResponse {
        const items = players
            ?.reduce<BulkActionItemPayload[]>(
                (result: BulkActionItemPayload[], i) => [...result, this.getSecurityCasesUpdateItems(i, value)],
                []
            )
            ?.filter(i => i.value !== null);

        return {actionKey: BulkActionKey.SecurityCasesUpdate, items};
    }

    private getSecurityCasesUpdateItems(player: UserProfileGridItem, value: EditSecurityCasesSubmitModel): BulkActionItemPayload {
        const allRemoveItemCases = value?.remove?.filter(i =>
            player.security_cases?.map(c => `${c.type}-${c.case_id}`)?.includes(`${i.type}-${i.case_id}`)
        );

        const updateItemCasesForAdd = value?.add?.filter(i => allRemoveItemCases?.map(c => c.type)?.includes(i.type));
        const updateItemCasesForRemove = allRemoveItemCases?.filter(i => updateItemCasesForAdd?.map(c => c.type)?.includes(i.type));

        return {
            actionKey: BulkActionKey.SecurityCasesUpdate,
            value:
                updateItemCasesForAdd?.length || updateItemCasesForRemove?.length
                    ? ({add: updateItemCasesForAdd, remove: updateItemCasesForRemove, uid: player.uid} as UpdateSecurityCaseActionItem)
                    : null,
            itemId: player.serverId,
            status: BulkItemStatus.Pending,
        };
    }
}

@injectable()
export class ApplySecurityCasesRemoveStrategy implements IBulkApplyStrategy<ApplySecurityCasesRequest> {
    process({players, value}: ApplySecurityCasesRequest): ApplyStrategyResponse {
        const items = players
            ?.reduce<BulkActionItemPayload[]>(
                (result: BulkActionItemPayload[], i) => [...result, this.getSecurityCasesRemoveItems(i, value)],
                []
            )
            ?.filter(i => i.value !== null);

        return {actionKey: BulkActionKey.SecurityCasesRemove, items};
    }

    private getSecurityCasesRemoveItems(player: UserProfileGridItem, value: EditSecurityCasesSubmitModel): BulkActionItemPayload {
        const removeItemCases = value?.remove?.filter(
            i =>
                player.security_cases?.map(c => c.case_id)?.includes(i.case_id) &&
                player.security_cases?.map(c => c.type)?.includes(i.type) &&
                !value?.add?.some(c => c.type === i.type)
        );

        return {
            actionKey: BulkActionKey.SecurityCasesRemove,
            value: removeItemCases?.length ? ({remove: removeItemCases, uid: player.uid} as RemoveSecurityCaseActionItem) : null,
            itemId: player.serverId,
            status: BulkItemStatus.Pending,
        };
    }
}

export type ApplySendMessageRequest = {
    players: UserProfileGridItem[];
    value: Message;
};

@injectable()
export class ApplySendMessageStrategy implements IBulkApplyStrategy<ApplySendMessageRequest> {
    process({players, value}: ApplySendMessageRequest): ApplyStrategyResponse {
        const items = players
            .map<BulkActionItemPayload>(item => ({
                actionKey: BulkActionKey.Message,
                value: value ? ({message: value, uid: item.uid} as SendMessageActionItem) : null,
                itemId: item.serverId,
                status: BulkItemStatus.Pending,
            }))
            .filter(i => i?.value !== null);

        return {actionKey: BulkActionKey.Message, items};
    }
}
