import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';

import {ServiceTypes} from '../../inversify';
import {map} from '../../otel';
import {ServiceResponsePayload} from '../types';

import {ApiRoutesService} from './apiRoutesService';
import {RestService} from './restService';

@injectable()
export class PlayerManagementApiService {
    private readonly _apiRouteService: ApiRoutesService;
    protected readonly _restService: RestService;

    constructor(
        @inject(ServiceTypes.RestService) restService: RestService,
        @inject(ServiceTypes.ApiRoutesService) apiRouteService: ApiRoutesService
    ) {
        this._restService = restService;
        this._apiRouteService = apiRouteService;
    }

    public editEmail(uid: string, email: string): Observable<ServiceResponsePayload<EditEmailRequestModel, null>> {
        const endpoint = this._apiRouteService.getEditPlayerEmailEndpoint(uid);
        return this._restService
            .patch({endpoint, body: {email}})
            .pipe(map(r => ({...r, requestPayload: {uid, email}, responsePayload: null})));
    }

    public editPhone(uid: string, areaCode: string, mobile: string): Observable<ServiceResponsePayload<EditPhoneRequestModel, null>> {
        const endpoint = this._apiRouteService.getEditPlayerPhoneEndpoint(uid);
        return this._restService
            .patch({endpoint, body: {areaCode, mobile}})
            .pipe(map(r => ({...r, requestPayload: {uid, area: areaCode, mobile}, responsePayload: null})));
    }

    public changeAccountLockStatus(model: ChangeLockStatusModel): Observable<ServiceResponsePayload<ChangeLockStatusModel, null>> {
        const endpoint = this._apiRouteService.getChangeAccountLockStatusEndpoint();
        return this.changeLockStatus(model, endpoint);
    }

    public changeLobbyLockStatus(model: ChangeLockStatusModel): Observable<ServiceResponsePayload<ChangeLockStatusModel, null>> {
        const endpoint = this._apiRouteService.getChangeLobbyLockStatusEndpoint();
        return this.changeLockStatus(model, endpoint);
    }

    private changeLockStatus(
        model: ChangeLockStatusModel,
        endpoint: string
    ): Observable<ServiceResponsePayload<ChangeLockStatusModel, null>> {
        return this._restService
            .post({endpoint, body: {userId: model.uid, locked: model.value}})
            .pipe(map(r => ({...r, requestPayload: model, responsePayload: null})));
    }
}

//#region Types

export type EditEmailRequestModel = {
    uid: string;
    email: string;
};

export type EditPhoneRequestModel = {
    uid: string;
    area: string;
    mobile: string;
};

export type ChangeLockStatusModel = {
    uid: string;
    value: boolean;
};

//#endregion
