import {
    CompositeAccessRule,
    defineAccessControlledResource,
    PoliciesAccessRule,
    PolicyRestrictedResource,
    RestrictedPoliciesAccessRule,
} from '@access-control';
import {agentReadPolicy, agentUpdatePolicy} from '@models/permissions/permissions';
import {Policy} from '@auth';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {PermissionAccessibleItem, PermissionEnum} from '../module-shared/types';

export const accountActionsUpdatePermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.ActionCenter, permissions: [PermissionEnum.Update]},
    {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.AccountActions, permissions: [PermissionEnum.Update]},
];

export const accountActionsUpdateRestrictPermissions: PermissionAccessibleItem[] = [agentReadPolicy, agentUpdatePolicy];

export const accountActionsReadPermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.ActionCenter, permissions: [PermissionEnum.Read]},
    {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.AccountActions, permissions: [PermissionEnum.Read]},
];

export const financeActionsUpdatePermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.ActionCenter, permissions: [PermissionEnum.Update]},
    {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.FinanceActions, permissions: [PermissionEnum.Update]},
];

export const financeActionsReadPermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.ActionCenter, permissions: [PermissionEnum.Read]},
    {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.FinanceActions, permissions: [PermissionEnum.Read]},
];

export const p2pActionUpdateResources: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'P2PActionUpdateResources',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.ActionCenter, null, 'update'),
        new Policy(ModuleName.ActionCenter, SubmoduleName.P2PAction, 'update'),
    ]),
});

export const p2pActionReadResources: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'P2PActionReadResources',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.ActionCenter, null, 'read'),
        new Policy(ModuleName.ActionCenter, SubmoduleName.P2PAction, 'read'),
    ]),
});

export const gamesActionsUpdatePermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.ActionCenter, permissions: [PermissionEnum.Update]},
    {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.GamesActions, permissions: [PermissionEnum.Update]},
];

export const gamesActionsUpdateRestrictPermissions: PermissionAccessibleItem[] = [agentReadPolicy, agentUpdatePolicy];

export const gamesActionsReadPermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.ActionCenter, permissions: [PermissionEnum.Read]},
    {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.GamesActions, permissions: [PermissionEnum.Read]},
];

export const gamesActionsUpdateResources: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'GamesActionsUpdateResources',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.ActionCenter, null, 'update'),
        new Policy(ModuleName.ActionCenter, SubmoduleName.GamesActions, 'update'),
    ]),
});

export const gamesActionsReadResources: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'GamesActionsReadResources',
    rules: new CompositeAccessRule(
        [
            new PoliciesAccessRule([
                new Policy(ModuleName.ActionCenter, null, 'read'),
                new Policy(ModuleName.ActionCenter, SubmoduleName.GamesActions, 'read'),
            ]),
            new RestrictedPoliciesAccessRule([new Policy(ModuleName.Agent, null, 'read'), new Policy(ModuleName.Agent, null, 'update')]),
        ],
        'and'
    ),
});
