import React from 'react';

import MuiDataGrid from '@components/data-grid/mui';
import {LayoutDetailsBlock} from '@components/layout/LayoutDetailsBlock';
import {TransactionType, UserProfile} from '@models/generated/graphql';
import {EntityType, UserProfileQueryFields, UserProfileServerFilterKeys} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {useViewInit} from '@redux/view';
import {isStringNullOrEmpty} from '@utils';

import {Filter} from 'src/common/types';
import {useJurisdictionConfig} from '../../../app/config/hooks';
import {GridToolbar} from '../../../data-grids/components/GridToolbar';
import {filterClient} from '../../../shared/filter/utils';
import {localized} from '../localized';
import {domain, FinanceSummaryModel} from '../types';

import {userFinanceSummaryData} from './data';

type FinanceSummaryProps = {
    userId: string;
};

export const FinanceSummary = ({userId}: FinanceSummaryProps) => {
    const {currency} = useJurisdictionConfig();

    const viewType = 'FinanceSummary';
    const entityType = EntityType.UserProfile;
    const fields: UserProfileQueryFields[] = [
        'finance.deposit.per_method.category',
        'finance.deposit.per_method.group_name',
        'finance.deposit.per_method.successful_amount',
        'finance.deposit.per_method.failed_amount',
        'finance.deposit.per_method.rejected_amount',
        'finance.deposit.per_method.successful_count',
        'finance.deposit.per_method.failed_count',
        'finance.deposit.per_method.rejected_count',
        'finance.deposit.per_method.currency',
        'finance.withdrawal.per_method.category',
        'finance.withdrawal.per_method.group_name',
        'finance.withdrawal.per_method.successful_amount',
        'finance.withdrawal.per_method.failed_amount',
        'finance.withdrawal.per_method.rejected_amount',
        'finance.withdrawal.per_method.successful_count',
        'finance.withdrawal.per_method.failed_count',
        'finance.withdrawal.per_method.rejected_count',
        'finance.withdrawal.per_method.currency',
    ];
    const defaultFilters: Filter<string, UserProfileServerFilterKeys>[] = [{key: 'uid', value: userId}];

    const {
        items,
        viewEntity: {filter: stateFilter},
        handleFilterChange,
    } = useViewInit<UserProfile, string, string>({
        viewType,
        displayName: localized.title,
        entity: {
            entity: entityType,
            fields,
        },
        realtime: {
            entity: entityType,
            mode: RealtimeUpdatesMode.Confirm,
            triggers: [RealtimeMessageTrigger.Update],
        },
        defaultFilters,
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'uid')?.value),
        defaultPaging: {page: 1, pageSize: 1},
    });
    const userProfile = items[0];

    const financeSummaryList: FinanceSummaryModel[] = [];
    if (userProfile?.finance?.deposit?.per_method?.length > 0) {
        financeSummaryList.push(...userProfile.finance.deposit.per_method.map(i => new FinanceSummaryModel(TransactionType.Deposit, i)));
    }
    if (userProfile?.finance?.withdrawal?.per_method?.length > 0) {
        financeSummaryList.push(
            ...userProfile.finance.withdrawal.per_method.map(i => new FinanceSummaryModel(TransactionType.Withdrawal, i))
        );
    }

    const filteredFinanceSummary = filterClient(financeSummaryList, stateFilter, userFinanceSummaryData.clientFilters);

    return (
        <LayoutDetailsBlock
            title={localized.title}
            toolbar={
                <GridToolbar
                    filters={userFinanceSummaryData.getFilters(financeSummaryList)}
                    filterString={stateFilter}
                    domain={domain}
                    onFilterChange={handleFilterChange}
                />
            }
            content={
                <MuiDataGrid
                    autoHeight={true}
                    rows={filteredFinanceSummary}
                    columns={userFinanceSummaryData.getGridColumns(currency)}
                    emptyMessage={localized.emptyList}
                />
            }
        ></LayoutDetailsBlock>
    );
};
