import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';

import {ServiceTypes} from '@inversify';
import {QueryGetAgentProfilesArgs, UserProfile} from '@models/generated/graphql';
import {AgentProfileFilterKeys, AgentProfileQueryFields, AgentProfileTextFilterKeys, Filter} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';
import {partialMatch} from '@utils';

import {DynamicConfig} from 'src/configuration';
import {JurisdictionConfigService} from 'src/features/app/config/services';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';

@injectable()
export class AgentProfileService extends EntityBaseGqlService<QueryGetAgentProfilesArgs, AgentProfileQueryFields, AgentProfileFilterKeys> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGPMocked) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.JurisdictionConfigService) jurisdictionConfigService: JurisdictionConfigService,
        @inject(ServiceTypes.Config) config: DynamicConfig
    ) {
        super(client, mapper, new AgentProfileRequestBuilder(jurisdictionConfigService, config));
    }
}

export class AgentProfileRequestBuilder extends GqlRequestBuilder<
    QueryGetAgentProfilesArgs,
    AgentProfileQueryFields,
    AgentProfileFilterKeys
> {
    public buildQuery = (fields: AgentProfileQueryFields[]): DocumentNode => gql`
        query GetAgentProfiles($filter: AgentProfileFilter) {
            getAgentProfiles(filter: $filter) {
                uid @include(if: ${this.hasField(fields, 'uid')})
                username @include(if: ${this.hasField(fields, 'username')})
                referral @include(if: ${this.hasAnyField(fields, this.getReferralQueryItems())}) {
                    inviting_user_id @include(if: ${this.hasField(fields, 'referral.inviting_user_id')})
                }
            }
        }
    `;

    protected buildFilter(filter: Filter<AgentProfileFilterKeys>): QueryGetAgentProfilesArgs {
        return {
            filter: {
                text: this.getGQLTextFilter(
                    Object.keys(this.filterFieldsMapper).map((key: AgentProfileTextFilterKeys) =>
                        this.toGQLTextFilter(this.filterFieldsMapper[key], filter[key] as string, this.transformTextMapper[key])
                    )
                ),
                referrer_agent_id: this.toGQLStringFilter(filter, 'referrer_agent_id'),
            },
        };
    }

    protected getGqlPageArgs(filter: Filter<AgentProfileFilterKeys>): QueryGetAgentProfilesArgs {
        return (filter ? this.buildFilter(filter) : {}) as QueryGetAgentProfilesArgs;
    }

    private readonly filterFieldsMapper: Record<AgentProfileTextFilterKeys, string[]> = {
        uid: nameof.toArray<UserProfile>(m => [m.uid]),
        username: nameof.toArray<UserProfile>(m => [m.username]),
    };

    private transformTextMapper: Partial<Record<AgentProfileTextFilterKeys, (value: string) => string>> = {
        username: partialMatch,
    };

    private getReferralQueryItems(): AgentProfileQueryFields[] {
        return ['referral.inviting_user_id'];
    }
}
