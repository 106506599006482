import React from 'react';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import StyledAccordion from '@components/StyledAccordion';
import {agentReadPolicies} from '@models/permissions/permissions';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {PermissionEnum} from '../../module-shared/types';
import {NotificationHistory} from '../../workspace-details/user-notification-history/components/NotificationHistory';

type UserDetailsNotificationHistoryInfoProps = {
    userId: string;
};

const useUserDetailsNotificationHistoryInfoClasses = makeStyles()(() => ({
    userDetailsNotificationHistoryInfoContainer: {
        width: '100%',
    },
}));

const localizedUserDetailsNotificationHistoryInfo = defineMessages({
    notificationHistory: {
        id: 'userDetailsNotificationHistory_notificationHistory',
        defaultMessage: 'Notification History',
    },
});

export const UserDetailsNotificationHistoryInfo = withMultiplePermission(
    ({userId}: UserDetailsNotificationHistoryInfoProps) => {
        const {classes} = useUserDetailsNotificationHistoryInfoClasses();

        return (
            <StyledAccordion
                header={localizedUserDetailsNotificationHistoryInfo.notificationHistory}
                componentId="notificationHistory"
                isCollapsed
                unmountOnCollapse
                children={
                    <Box className={classes.userDetailsNotificationHistoryInfoContainer}>
                        <NotificationHistory userId={userId} />
                    </Box>
                }
            />
        );
    },
    {
        allowedPermissions: [
            {moduleName: ModuleName.ActionCenter, permissions: [PermissionEnum.Read]},
            {moduleName: ModuleName.PageAgentReport, permissions: [PermissionEnum.Read]},
            {
                moduleName: ModuleName.ActionCenter,
                submoduleName: SubmoduleName.CommunicationActions,
                permissions: [PermissionEnum.Read],
            },
        ],
        restrictedPermissions: agentReadPolicies,
    }
);
