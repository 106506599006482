import React from 'react';
import {defineMessages} from 'react-intl';

import {EmptyPlaceholder} from '@components/select';
import {KycInitReasonCode} from '@models/generated/graphql';
import {AccountVerificationFilterKeys} from '@redux/entity';

import {localizedKYCInitReasonCodes} from '../../app/intl/shared-resources/kycInitReasonCode';
import {SelectOption} from '../../module-shared/types';
import FilterMultiSelect from '../../shared/filter/components/FilterMultiSelect';
import {localizedFilters} from '../../shared/filter/localize';
import {PageModuleSelectFilter} from '../../shared/filter/types';

import {AccountVerificationsFilterProps} from './AccountVerificationsFilters';

const localized = defineMessages({
    reasonCodeFilterLabel: {
        id: 'InitKYCReasonCodeFilter_label',
        defaultMessage: 'KYC Reason',
    },
});

function InitKYCReasonCodeFilter({filter, onFilterChange}: AccountVerificationsFilterProps) {
    const filterKey: AccountVerificationFilterKeys = 'reasonCode';
    return (
        <FilterMultiSelect
            onFilterChange={onFilterChange}
            domain={null}
            filter={
                {
                    key: filterKey,
                    label: localized.reasonCodeFilterLabel,
                    emptyPlaceholder: EmptyPlaceholder.Any,
                    placeholder: localizedFilters.statusFilterPlaceholder,
                    options: Object.values(KycInitReasonCode).map(s => ({label: localizedKYCInitReasonCodes[s], value: s} as SelectOption)),
                } as PageModuleSelectFilter
            }
            filterString={filter}
        />
    );
}

export default InitKYCReasonCodeFilter;
