import React, {useRef} from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomIcon} from '@components/icons';
import {FormTextInputCleanable} from '@components/input';
import {CustomTheme} from '@style';

import {TextInputBasedFilterProps} from './types';

type TextFilterProps = TextInputBasedFilterProps<string>;

export const useClasses = makeStyles()((theme: CustomTheme) => ({
    filterText: {
        '& .MuiInputBase-input': {
            height: theme.custom.denseButtonHeight - 2,
            textOverflow: 'ellipsis',
        },
    },
    filterTextStartInputAdornment: {
        color: theme.palette.secondary.main,
        fontSize: 12,
    },
    filterTextCleanableLimitMaxWidth: {
        maxWidth: theme.custom.filterTextInputMaxWidth,
        width: theme.custom.filterTextInputMaxWidth,
    },
}));

export const TextFilter = ({value, onChange, onEnter, width, inputPlaceholder, mode}: TextFilterProps) => {
    const {classes, cx} = useClasses();
    const debounceTimer = useRef(null);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }

        const delay = mode === 'update-on-submit' ? 0 : 500;
        debounceTimer.current = setTimeout(() => {
            onChange(e.target.value);
        }, delay);
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            onEnter?.((e.target as HTMLInputElement).value);
        }
    }

    function handleClear() {
        onChange('');
    }

    return (
        <FormTextInputCleanable
            value={value}
            className={cx({[classes.filterTextCleanableLimitMaxWidth]: width === 'fixed'}, classes.filterText)}
            startAdornment={<Box component="span" className={`${CustomIcon.Search} ${classes.filterTextStartInputAdornment}`} />}
            style={{marginBottom: 0}}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            clear={handleClear}
            placeholder={inputPlaceholder}
        />
    );
};

export const TextFilterFixedWidth = (props: TextFilterProps) => <TextFilter {...props} width="fixed" />;

export const TextFilterFullWidth = (props: TextFilterProps) => <TextFilter {...props} width="full" />;
