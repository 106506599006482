import React from 'react';
import {defineMessages} from 'react-intl';

import {ValueButton} from '@components/button';
import {CustomIcon} from '@components/icons/types';

import {ModuleName, SubmoduleName} from '../../../common/routeEnums';
import {withPermissionAndJurisdictionConfig} from '../../app/permission/PermissionHoc';
import {PermissionEnum} from '../../module-shared/types';

import {BulkUserProfileSecurityCasesDropdown} from './BulkUserProfileSecurityCasesDropdown';

const localized = defineMessages({
    securityCaseId: {
        id: 'BulkActionSecurityCase_SecurityCaseId',
        defaultMessage: 'Security Case ID',
    },
});

type ActionSecurityCasesProps = {onClick: () => void};

function ActionSecurityCases({onClick}: ActionSecurityCasesProps) {
    return (
        <ValueButton onClick={onClick} label={localized.securityCaseId} iconClassName={CustomIcon.Bag}>
            <BulkUserProfileSecurityCasesDropdown />
        </ValueButton>
    );
}

export const BulkUserProfielSecurityCaseButton = withPermissionAndJurisdictionConfig(ActionSecurityCases, {
    moduleName: ModuleName.ActionCenter,
    submoduleName: SubmoduleName.Cases,
    permissions: [PermissionEnum.Create, PermissionEnum.Delete],
});
