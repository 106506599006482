import React from 'react';
import {defineMessages} from 'react-intl';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource, withPolicyAccessCheck} from '@access-control';
import {ErrorModal} from '@components/error';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar from '@components/toolbar/Toolbar';
import {withTraceErrorBoundary} from '@otel';
import {Policy} from '@auth';
import {extendedViewCleanDelay, ViewType} from '@redux/view';

import {ModuleName} from '../../common/routeEnums';
import {BoUserQueryFilters} from '../../features/block-bo-user/components/BoUserFiltersQueryFilters';
import {DataGridBoUserServer} from '../../features/block-bo-user/components/DataGridBoUser';
import {useBoUsers} from '../../features/block-bo-user/hooks';
import {BoUserViewModelKeys} from '../../features/block-bo-user/types';

export const agentManagementPageLocalized = defineMessages({
    title: {
        id: 'page.AgentManagement.title',
        defaultMessage: 'Agent Management',
    },
});

function AgentManagementPage() {
    const viewType: ViewType = 'AgentManagement';
    const keys: BoUserViewModelKeys[] = ['firstName', 'lastName', 'email', 'boAgentPlayerId'];

    const {items, totalCount, handleFilterChange, handlePageChange, handlePageSizeChange, handleSortChange, searchFilter, filterString} =
        useBoUsers({
            viewType,
            displayName: agentManagementPageLocalized.title,
            fields: keys,
            syncWithUrl: true,
            validateFilter: () => true,
            cleanDelay: extendedViewCleanDelay,
        });

    return (
        <LayoutQueue
            header={<PageHeader header={agentManagementPageLocalized.title} />}
            toolbar={
                <Toolbar
                    primary={<BoUserQueryFilters model={filterString} onChange={handleFilterChange} availableFilters={['em_fn_ln_apId']} />}
                />
            }
            body={
                <DataGridBoUserServer
                    columns={keys}
                    rows={items}
                    rowCount={totalCount}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={handleSortChange}
                    page={searchFilter?.paging?.page}
                    pageSize={searchFilter?.paging?.pageSize}
                    sortModel={searchFilter?.sorting}
                />
            }
        />
    );
}

export const agentManagementAccessControlled: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'Agent Management Page',
    rules: new PoliciesAccessRule([new Policy(ModuleName.PageAgentManagement), new Policy(ModuleName.PageAgentManagement, null, 'read')]),
});

export default withTraceErrorBoundary(withPolicyAccessCheck(AgentManagementPage, agentManagementAccessControlled), ErrorModal);
