import {Container} from 'inversify';
import {combineEpics} from 'redux-observable';
import {filter, takeUntil} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {actionFileEpics} from '@file/epics';
import {ServiceTypes} from '@inversify';
import {RootEpic} from '@redux';
import {IEpicsBuilder} from '@redux';
import {ViewEpicsBuilder} from '@redux/view';

import {terminateAllEpics} from 'src/common/actions';
import actionHistoryEpics from '../features/action-history/epics';
import breadcrumbEpics from '../features/app/breadcrumb/epics';
import appConfigEpics from '../features/app/config/epics';
import {initDefaultErrorHandlerEpic, protectEpics} from '../features/app/error-handling/epics';
import {logErrorEpic} from '../features/app/error-handling/epics';
import localizationEpics from '../features/app/intl/epics';
import appSharedEpics from '../features/app-shared/epics';
import {beBonusEpics} from '../features/be-bonus/epics';
import {beBonusHistoryEpics} from '../features/be-bonus-history/epics';
import {kycEntityEpics} from '../features/kyc/epics';
import legalDocsEpics from '../features/legal-docs/epics';
import {marketingCodeEpics} from '../features/marketing-code/epics';
import modulesEpics from '../features/modules/epics';
import securityCasesEpics from '../features/player-actions/cases-actions/epics';
import {playerActionsEpics} from '../features/player-actions/epics';
import {playerMessageEpics} from '../features/player-message/epics';
import {realtimeNotificationEpic} from '../features/realtime-updates/epics';
import {referrerSummaryEpics} from '../features/referrer-summary/epics';
import {AsyncActionEpicsBuilder} from '../features/shared/async-action/epics';
import transactionsEpics from '../features/transactions/epics';
import {userProfileEpics} from '../features/user-profile/epics';
import {userNotificationHistoryEpics} from '../features/workspace-details/user-notification-history/epics';
import userRegionRelatedInfoEpics from '../features/workspace-details/user-region-related-info/epics';
import usersEpics from '../pages/access-management/epics';
import bonusEngineDetailsEpics from '../pages/bonus-engine-details/epics';
import kycDetailsEpics from '../pages/kyc-details/epics';
import legalDocManagementEpics from '../pages/legal-docs/epics';
import legalHistoryEpics from '../pages/legal-docs/history/epics';
import marketingBonusCodeEpics from '../pages/marketing-bonus-code/epics';
import registrationInfoEpics from '../pages/registration-info/epics';
import rolesEpics from '../pages/role-management/epics';
import transactionHistoryEpics from '../pages/transaction-history/epics';

import actions from './rootActions';

type InitEpicsReturn = {
    rootEpic: RootEpic;

    /**
     * @deprecated
     * <p>Should be removed together with {@link runTestsWithStore}</p>
     */
    terminableRootEpic: RootEpic;
};

export function initEpics(container: Container): InitEpicsReturn {
    const protectedEpics = [
        modulesEpics,
        usersEpics,
        rolesEpics,
        registrationInfoEpics,
        transactionHistoryEpics,
        legalDocManagementEpics,
        playerActionsEpics,
        localizationEpics,
        securityCasesEpics,
        transactionsEpics,
        actionHistoryEpics,
        userProfileEpics,
        legalHistoryEpics,
        userRegionRelatedInfoEpics,
        kycDetailsEpics,
        appConfigEpics,
        breadcrumbEpics,
        appSharedEpics,
        realtimeNotificationEpic,
        kycEntityEpics,
        referrerSummaryEpics,
        legalDocsEpics,
        marketingCodeEpics,
        marketingBonusCodeEpics,
        playerMessageEpics,
        beBonusEpics,
        beBonusHistoryEpics,
        bonusEngineDetailsEpics,
        new AsyncActionEpicsBuilder(actions).buildEpics(),
        new ViewEpicsBuilder().buildEpics(),
        userNotificationHistoryEpics,
        container.get<IEpicsBuilder>(ServiceTypes.EntityEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.ActionBulkEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.ActionBulkTransactionEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.BlockUserLabelEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.RealtimeEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.ReferralSettingsEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.AccountVerificationActionsEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.TransactionActionsEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.UserProfileActionsEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.UserProfilePersonalInfoEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.UserAddressInfoEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.AsyncActionsEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.RoutingEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.UserLoginActionsEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.PlayerReferralEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.AgentReportingActionsEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.GameTemplateActionsEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.GameRoomActionsEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.BonusCodeEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.NoteActionsEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.RuleActionsEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.PlayerBonusEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.AgentLevelReportEpicsBuilder).buildEpics(),
        container.get<IEpicsBuilder>(ServiceTypes.EmbeddedReportEpicsBuilder).buildEpics(),
        actionFileEpics,
    ].map(epic => protectEpics(epic));

    const rootEpic: RootEpic = combineEpics(...protectedEpics, logErrorEpic, initDefaultErrorHandlerEpic);

    const terminableRootEpic: RootEpic = (action$, state$, dependencies) =>
        rootEpic(action$, state$, dependencies).pipe(takeUntil(action$.pipe(filter(isActionOf(terminateAllEpics)))));

    return {rootEpic, terminableRootEpic};
}
