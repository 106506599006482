import {GridCellParams, GridColDef} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {IModuleGridItem} from '@components/data-grid';
import {CustomTheme} from '@style';

//TODO: [BO-2704] Move files to src/common/components/data-grid (renamed grid folder)
const useClasses = makeStyles()((theme: CustomTheme) => ({
    dataGrid: {
        //for overflow when expand
        '& .MuiDataGrid-viewport': {
            maxHeight: 'unset!important' as 'unset',
            height: 'auto',
        },
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'unset!important' as 'unset',
        },
    },
    dataGridNewCell: {
        background: theme.custom.palette.grid.new,
    },
}));

const gridHeightClassPrefix = 'height-';

const parseGridHeightClass = (heightClass: string) => {
    const heightStr = heightClass?.includes(gridHeightClassPrefix) ? heightClass.replace(gridHeightClassPrefix, '') : null;
    return heightStr ? parseInt(heightStr) : null;
};

const getGridRowHeight = (columns: GridColDef[]) => {
    return columns.map(c => parseGridHeightClass(c?.cellClassName?.toString())).find(c => c > 0) ?? 48;
};

export const makeGridHeightClass = (height: number) => {
    return height ? `${gridHeightClassPrefix}${height}` : null;
};

export const useGridCellStyle = (columns: GridColDef[]) => {
    const {classes} = useClasses();

    const rowHeight = getGridRowHeight(columns);

    const styledColumns: GridColDef[] = columns?.map(c => ({
        ...c,
        cellClassName: (params: GridCellParams) => {
            const gridCellClassName = c.cellClassName
                ? typeof c.cellClassName === 'string'
                    ? c.cellClassName
                    : c.cellClassName(params)
                : '';
            const commonCellClassName = (params.row as IModuleGridItem)?.isNew ? classes.dataGridNewCell : '';
            return `${gridCellClassName} ${commonCellClassName}`;
        },
    }));

    return {columns: styledColumns, className: classes.dataGrid, rowHeight};
};
