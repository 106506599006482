import React, {useEffect, useState} from 'react';
import {FC} from 'react';
import {MessageDescriptor} from 'react-intl';
import 'react-day-picker/dist/style.css';
import {FilledInputProps, InputProps, OutlinedInputProps} from '@mui/material';

import {Locale} from '@components/types';
import {formatDate} from '@utils';

import {DatePicker} from './DatePicker';
import {localized} from './DateSinglePicker.localize';
import {StyledInputProps} from './StyledFormInputHoc';

export type DateSinglePickerProps = StyledInputProps & {
    open?: Boolean;
    value?: Date;
    disabled?: Boolean;
    formatter?: (value: Date) => string;
    disableFutureDates?: Boolean;
    onChange: (value: Date) => void;
    onDropdownOpen?: () => void;
    onDropdownClose?: () => void;
    placeholder?: string | MessageDescriptor;
    InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>;
    locale: Locale;
};

export const DateSinglePicker: FC<DateSinglePickerProps> = ({
    open,
    value,
    formatter,
    disableFutureDates,
    onChange,
    onDropdownOpen,
    onDropdownClose,
    placeholder,
    disabled,
    locale,
    ...textInputProps
}) => {
    const [selectedDate, setSelectedDate] = useState(value);
    const isDateSelected = value !== null && value !== undefined;

    const formattedValue = formatter ? formatter(selectedDate) : formatDate(selectedDate, 'date');

    useEffect(() => {
        setSelectedDate(value);
    }, [value]);

    const handleSelect = (value: Date) => {
        setSelectedDate(value);
        onChange(value);
        onDropdownClose();
    };

    return disabled ? (
        <>{formattedValue}</>
    ) : (
        <DatePicker
            isOpen={open}
            value={selectedDate}
            isDateSelected={isDateSelected}
            formattedValue={isDateSelected ? formattedValue : null}
            onSelect={handleSelect}
            defaultMonthDate={selectedDate}
            disableFutureDates={disableFutureDates}
            onDropdownOpen={onDropdownOpen}
            onDropdownClose={onDropdownClose}
            placeholder={placeholder ?? localized.datePickerPlaceholder}
            iconPlacement="end"
            type="small"
            applyAction={false}
            cancelAction={false}
            locale={locale}
            {...textInputProps}
        ></DatePicker>
    );
};
