import React, {useState} from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {ValueButtonProps} from '@components/button';
import {ChipCounter} from '@components/chip/ChipCounter';
import {Chip} from '@components/chip/Chips';
import {ChipType, ChipVariant} from '@components/chip/types';
import {ValueButtonDropdown} from '@components/dropdown/Dropdown';
import {DropdownType} from '@components/dropdown/DropdownHoc';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons';
import {MultiSelectItem} from '@components/select/MultiSelectItem';
import {MultiSelectTextCounterWithTooltip} from '@components/select/MultiSelectTextCounterWithTooltip';
import {CustomTheme, SystemColorsEnum} from '@style';

import {SelectOption} from 'src/features/module-shared/types';

const localized = defineMessages({
    confirm: {
        id: 'MultiSelectDropdownButton_confirmAction',
        defaultMessage: 'Confirm',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    multiSelectChipButtonContainer: {
        position: 'relative',
        overflow: 'visible !important',
        padding: '0 !important',
    },
    multiSelectChipButtonDropdownButtonsContainer: {
        display: 'flex',
        justifyContent: 'end',
        padding: theme.spacing(1.5, 2),
        gap: theme.spacing(1.5),
        borderTop: `1px solid ${theme.custom.palette.content.border}`,
    },
    multiSelectChipButtonConfirmButtonCounter: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(0.75),
        height: theme.spacing(2.5),
        backgroundColor: theme.palette.primary.contrastText,
    },
}));

type MultiSelectButtonProps<T extends string> = Omit<ValueButtonProps, 'onClick'> & {
    options: SelectOption[];
    optionsHeader?: string | MessageDescriptor;
    onApply: (result: T[]) => void;
    value?: T[];
    tooltipTitle?: string | MessageDescriptor;
    dataTestId?: string;
};

export function MultiSelectDropdownButton<T extends string>({
    options,
    optionsHeader,
    onApply,
    value,
    tooltipTitle,
    dataTestId,
    ...props
}: MultiSelectButtonProps<T>) {
    const {classes} = useStyles();
    const [selected, setSelected] = useState<T[]>((value as T[]) ?? []);

    function handleClick(value: T) {
        setSelected(prevState => {
            const result: T[] = [...prevState];
            const index = prevState.indexOf(value);
            if (index > -1) {
                result.splice(index, 1);
            } else {
                result.push(value);
            }
            return result;
        });
    }

    const hasSelectedItems = selected?.length > 0;

    return (
        <div className={classes.multiSelectChipButtonContainer} data-testid={dataTestId}>
            <ValueButtonDropdown
                closeOnClickInside={false}
                dropdownType={DropdownType.Attached}
                endIconClassName={CustomIcon.WriteOutline}
                endIconSize={13}
                buttonContent={
                    value?.length ? (
                        <MultiSelectTextCounterWithTooltip
                            tooltipTitle={tooltipTitle}
                            selectedTotal={value?.length}
                            values={value}
                            options={options}
                            element={
                                <ChipCounter
                                    label={<LocalizedText label={options?.find(o => o.value === value?.[0])?.label} />}
                                    count={`${value?.length}`}
                                    chipType={ChipType.Mark}
                                    chipVariant={ChipVariant.Disabled}
                                />
                            }
                        />
                    ) : null
                }
                hasMenuList
                listTitle={optionsHeader}
                cancelAction
                onCancelClick={() => setSelected((value as T[]) ?? [])}
                onApplyClick={() => onApply(selected)}
                applyAction={localized.confirm}
                applyActionContent={
                    hasSelectedItems ? (
                        <Chip
                            label={selected?.length}
                            chipType={ChipType.Mark}
                            chipVariant={SystemColorsEnum.White}
                            size="small"
                            className={classes.multiSelectChipButtonConfirmButtonCounter}
                        />
                    ) : null
                }
                actionsFullWidth={false}
                isApplyDisabled={!hasSelectedItems}
                styles={{dropdownActions: classes.multiSelectChipButtonDropdownButtonsContainer}}
                {...props}
            >
                {options.map(option => {
                    const value = option.value as T;
                    return (
                        <MultiSelectItem
                            key={value}
                            value={value}
                            chipValue={value}
                            label={option.label}
                            isSelected={selected?.includes(value)}
                            onClick={() => handleClick(value)}
                        />
                    );
                })}
            </ValueButtonDropdown>
        </div>
    );
}
