import React, {useCallback} from 'react';
import {defineMessages} from 'react-intl';
import {GridRowParams} from '@mui/x-data-grid';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {useDataGridSelectedIds} from '@components/data-grid/mui';
import {__empty_space_between_filters} from '@components/filter/FilterGroupNew';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar from '@components/toolbar/Toolbar';
import {BonusCreditType, BonusStatus} from '@models/generated/graphql';
import {Policy} from '@auth';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {extendedViewCleanDelay} from '@redux/view';

import {ModuleName} from 'src/common/routeEnums';
import {
    BonusCodeDetailsViewModel,
    BonusCodeDetailsViewModelKeys,
    BonusCodeQueryFilters,
    BonusEngineAddBonusCode,
    BulkBonusEngineButton,
    DataGridBonusCodeKeys,
    DataGridBonusCodeServer,
    useBonusCodes,
} from '../features/block-bonus-code';

const localized = defineMessages({
    bonusEngineHeader: {
        id: 'BE_bonusEngineHeader',
        defaultMessage: 'Bonus Engine',
    },
});

export function BonusEnginePage() {
    const keys: BonusCodeDetailsViewModelKeys[] = [
        'bonus_id',
        'bonus_name',
        'credit_type',
        'total_paid_out_amount',
        'create_time.seconds',
        'bonus_status',
        'description',
    ];
    const columns: DataGridBonusCodeKeys[] = [...keys, 'deactivate_action', 'details_action'];
    const {items, totalCount, handleFilterChange, filterString, searchFilter, handlePageChange, handlePageSizeChange, handleSortChange} =
        useBonusCodes({
            viewType: 'BonusEngine',
            fields: keys,
            realtimeMode: RealtimeUpdatesMode.Confirm,
            triggers: [RealtimeMessageTrigger.Add],
            syncWithUrl: true,
            validateFilter: null,
            defaultSorting: [{field: 'create_time.seconds', sort: 'desc'}],
            cleanDelay: extendedViewCleanDelay,
        });
    const {selectedIds, handleSelect} = useDataGridSelectedIds();

    const isRowSelectable = useCallback((params: GridRowParams): boolean => {
        const row = params.row as BonusCodeDetailsViewModel;
        const isSelectable = row?.bonus_status === BonusStatus.Active && row?.credit_type === BonusCreditType.MttToken;
        return isSelectable;
    }, []);

    return (
        <LayoutQueue
            header={
                <PageHeader
                    header={localized.bonusEngineHeader}
                    actions={
                        <>
                            <BonusEngineAddBonusCode />
                            <BulkBonusEngineButton selectedIds={selectedIds} />
                        </>
                    }
                />
            }
            toolbar={
                <Toolbar
                    primary={
                        <BonusCodeQueryFilters
                            model={filterString}
                            onChange={handleFilterChange}
                            availableFilters={['id_name', 'credit_type', 'bonus_status', __empty_space_between_filters, 'create_time']}
                        />
                    }
                />
            }
            body={
                <DataGridBonusCodeServer
                    checkboxSelection
                    selectedIds={selectedIds}
                    columns={columns}
                    rows={items}
                    rowCount={totalCount}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={handleSortChange}
                    page={searchFilter?.paging?.page}
                    pageSize={searchFilter?.paging?.pageSize}
                    sortModel={searchFilter?.sorting}
                    isRowSelectable={isRowSelectable}
                    onSelect={handleSelect}
                />
            }
        />
    );
}

export const bonusEngineResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'BonusEngine',
    rules: new PoliciesAccessRule([new Policy(ModuleName.MarketingFunction, null, 'read')]),
});
