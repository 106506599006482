import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme, SystemColorsEnum} from '@style';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    circle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: '50%',
        color: theme.palette.warning.contrastText,
    },
    circleLarge: {
        width: theme.spacing(6),
        minWidth: theme.spacing(6),
        height: theme.spacing(6),
    },
    circleMedium: {
        width: theme.spacing(4),
        minWidth: theme.spacing(4),
        height: theme.spacing(4),
    },
    circleSmall: {
        width: theme.spacing(3),
        minWidth: theme.spacing(3),
        height: theme.spacing(3),
    },
    circleLabelSmall: {
        fontSize: '12px',
        lineHeight: '23px',
    },
    circleLabel: {
        top: theme.spacing(2),
        fontSize: '20px',
        lineHeight: 0,
    },
    circleLabelDefault: {
        color: theme.palette.primary.contrastText,
    },
    circleLabelInverted: {
        color: theme.palette.text.primary,
    },
}));

export enum CircleSizes {
    Large = 'large',
    Medium = 'medium',
    Small = 'small',
}

export type CircleBoxProps = {
    letter?: React.ReactNode;
    color: SystemColorsEnum;
    size?: CircleSizes;
    className?: string;
};

export function CircleBox({letter, color, size = CircleSizes.Large, className}: CircleBoxProps) {
    const {classes: styles, cx} = useStyles();

    function getSizeClass() {
        const circleSizeMapping: Record<CircleSizes, string> = {
            [CircleSizes.Large]: styles.circleLarge,
            [CircleSizes.Medium]: styles.circleMedium,
            [CircleSizes.Small]: styles.circleSmall,
        };

        return circleSizeMapping[size];
    }

    function getLetterColorClass() {
        switch (color) {
            case SystemColorsEnum.Beige:
            case SystemColorsEnum.WarmYellow:
            case SystemColorsEnum.YellowNeon:
            case SystemColorsEnum.Mint:
                return styles.circleLabelInverted;
            default:
                return styles.circleLabelDefault;
        }
    }

    return (
        <Box className={cx(styles.circle, getSizeClass(), className)} bgcolor={color}>
            <Box
                component="span"
                className={cx(getLetterColorClass(), {
                    [styles.circleLabel]: size === CircleSizes.Large,
                    [styles.circleLabelSmall]: size !== CircleSizes.Large,
                })}
            >
                {letter}
            </Box>
        </Box>
    );
}
