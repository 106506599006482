import {gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {DocumentNode} from 'graphql';
import {inject, injectable} from 'inversify';

import {ServiceTypes} from '@inversify';
import {QueryGetRelatedPlayersArgs} from '@models/generated/graphql';
import {PlayersRelationFilterKeys, PlayersRelationQueryFields} from '@redux/entity';

import {DynamicConfig} from 'src/configuration';
import {JurisdictionConfigService} from 'src/features/app/config/services';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';
import {EntityBaseGqlService} from './entity';
import {ApolloClientProxy} from './gql-api';

@injectable()
export class PlayersRelationService extends EntityBaseGqlService<
    QueryGetRelatedPlayersArgs,
    PlayersRelationQueryFields,
    PlayersRelationFilterKeys
> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.JurisdictionConfigService) jurisdictionConfigService: JurisdictionConfigService,
        @inject(ServiceTypes.Config) config: DynamicConfig
    ) {
        super(client, mapper, new PlayersRelationRequestBuilder(jurisdictionConfigService, config));
    }
}
export class PlayersRelationRequestBuilder extends GqlRequestBuilder<
    QueryGetRelatedPlayersArgs,
    PlayersRelationQueryFields,
    PlayersRelationFilterKeys
> {
    protected buildQuery(fields: PlayersRelationQueryFields[]): DocumentNode {
        const playerFields: PlayersRelationQueryFields[] = ['player', 'player.uid', 'player.security_cases', 'player.username'];
        const relatedPlayerFields: PlayersRelationQueryFields[] = [
            'related_player',
            'related_player.uid',
            'related_player.username',
            'related_player.security_cases',
        ];
        return gql`
            query GetRelatedPlayers($filter: PlayersRelationFilter, $start: Int, $end: Int) {
                getRelatedPlayers(filter: $filter, start: $start, end: $end) {
                    items {
                        id
                        created_at  @include(if: ${this.hasField(fields, 'created_at')}) {
                            seconds
                        }
                        player @include(if: ${this.hasAnyField(fields, playerFields)}){
                            uid
                            security_cases @include(if: ${this.hasAnyField(fields, ['player.security_cases', 'player'])}){
                                case_id
                                type 
                                opened_at {
                                    seconds
                                }
                            }
                            username @include(if: ${this.hasAnyField(fields, ['player.username', 'player'])})
                        }
                        related_player @include(if: ${this.hasAnyField(fields, relatedPlayerFields)}){
                            uid
                            security_cases @include(if: ${this.hasAnyField(fields, ['related_player.security_cases', 'related_player'])}){
                                case_id
                                type
                                opened_at {
                                    seconds
                                }
                            }
                            username @include(if: ${this.hasAnyField(fields, ['related_player.username', 'related_player'])})
                        }
                        security_case_types @include(if: ${this.hasField(fields, 'security_case_types')})
                        relevance @include(if: ${this.hasField(fields, 'relevance')})
                        relation_type @include(if: ${this.hasField(fields, 'relation_type')})
                    }
                    total_count
                }
            }
        `;
    }

    protected buildFilter(filter: Partial<Record<PlayersRelationFilterKeys, unknown>>): Pick<QueryGetRelatedPlayersArgs, 'filter'> {
        const textFilters = [this.toGQLTextFilter(['player.uid'], filter.player_uid as string)];
        return {
            filter: {
                text: this.getGQLTextFilter(textFilters),
                security_case_types: this.toGQLMultiselectFilter(filter, 'security_case_types'),
            },
        };
    }
}
