import React, {FC, memo} from 'react';
import {FormattedNumber} from 'react-intl';
import {GridCellParams} from '@mui/x-data-grid';

import {CurrencyColumnValue} from '@components/data-grid/types';
import {withEmptyCheck} from '@components/label';
import {MultiLineAutoAdjustedWidthTooltip} from '@components/tooltip/MultiLineAutoAdjustedWidthTooltip';

import {renderValueWithTooltip} from './GridCellWithTooltip';

type CurrencyCellProps = {
    value: number;
    currency: string;
};

export const CurrencyCellFormatter = memo(({value, currency}: CurrencyCellProps) => {
    return <FormattedNumber value={value ?? 0} style="currency" currency={currency} />;
});

/**
 * @deprecated Should be removed. Use {@link renderMuiGridCurrencyCell}
 * */
export const CurrencyCell: FC<CurrencyCellProps> = withEmptyCheck(({value, currency}: CurrencyCellProps) => {
    return <CurrencyCellFormatter value={value ?? 0} currency={currency} />;
});

/**
 * @deprecated Should be removed. Use {@link renderMuiGridCurrencyCell}
 * */
export const renderCurrencyCell = (params: GridCellParams, currency: string) => {
    const value = params.value;
    return <CurrencyCell value={value as number} currency={currency}></CurrencyCell>;
};

/**
 * @deprecated Should be removed. Use {@link renderMuiGridCurrencyCell}
 * */
export const renderGridCurrencyWithTooltip = (params: GridCellParams, currency: string) => {
    const value = params.value as number;
    return renderValueWithTooltip(<CurrencyCellFormatter value={value} currency={currency} />);
};

export type AmountCurrencyCellProps = {
    amount: number;
    currency: string;
};

/**
 * @deprecated Should be removed. Use {@link renderMuiGridCurrencyCell}
 * */
export const renderAmountCurrencyCellWithTooltip = (params: GridCellParams) => {
    const value: AmountCurrencyCellProps = params.value as AmountCurrencyCellProps;
    return renderValueWithTooltip(<CurrencyCell value={value?.amount} currency={value?.currency} />);
};

export function renderMuiGridCurrencyCell(params: GridCellParams) {
    const {amount, currency} = params.value as CurrencyColumnValue;

    return currency ? (
        <MultiLineAutoAdjustedWidthTooltip linesCount={1}>
            <CurrencyCellFormatter value={amount} currency={currency} />
        </MultiLineAutoAdjustedWidthTooltip>
    ) : (
        <>{amount}</>
    );
}
