import React, {Fragment, useEffect} from 'react';
import {MessageDescriptor} from 'react-intl';
import {useDispatch} from 'react-redux';
import {makeStyles} from 'tss-react/mui';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {useJurisdictionFeature} from '../../../features/app/config/hooks';
import {Jurisdiction} from '../../../features/app/config/types';
import {ModuleProps, withModuleContentLoad} from '../../../features/module-shared/components/ModuleContentLoadHoc';
import ModuleHeader from '../../../features/module-shared/components/ModuleHeader';
import {FormModuleData, IModuleItem} from '../../../features/module-shared/types';
import {legalModuleActions} from '../actions';
import {LegalDocType, LegalDocumentRead} from '../types';

import legalModuleData from './data';
import LegalDocumentForm from './LegalDocumentForm';
import LegalDocumentView from './LegalDocumentView';

const useClasses = makeStyles()(() => ({
    formFullscreen: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
}));

class LegalDocumentModuleProps extends ModuleProps {
    docType: LegalDocType;
    docName: MessageDescriptor;
    jurisdiction: Jurisdiction;
    data: {
        domain: string;
        data: {
            moduleName: ModuleName;
            submoduleName: SubmoduleName;
            route: string;
            content: FormModuleData<IModuleItem>;
            state: {
                filter: string;
                isEditMode: boolean;
            };
        };
    };
}

const LegalDocumentModule = ({docName, docType, jurisdiction, data: stateData}: LegalDocumentModuleProps) => {
    const {classes} = useClasses();
    const {data, domain} = stateData;
    const actions = legalModuleActions(domain);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.initLegalDocsModule(docType, jurisdiction));
    }, []);

    const accessibleActions = legalModuleData
        .getActions(domain, data.state.isEditMode, data.submoduleName)
        ?.filter(a => useJurisdictionFeature(a));

    return !data?.content?.item ? null : (
        <Fragment>
            <ModuleHeader header={docName}>
                {accessibleActions?.map((action, index) => {
                    const ActionComponent = action.render;
                    return <ActionComponent key={index} />;
                })}
            </ModuleHeader>
            {data.state.isEditMode ? (
                <LegalDocumentForm
                    className={classes.formFullscreen}
                    domain={domain}
                    headers={[]}
                    stateSelector={() => data.content}
                    submitAction={actions.itemSave.request}
                    closeAction={actions.changeMode}
                />
            ) : (
                <LegalDocumentView documentName={docName} document={data?.content?.item as LegalDocumentRead} />
            )}
        </Fragment>
    );
};

export default withModuleContentLoad(LegalDocumentModule);
