import React from 'react';
import {Box} from '@mui/material';
import {Variant} from '@mui/material/styles/createTypography';
import Typography from '@mui/material/Typography';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    keyValueCellTitle: {
        padding: theme.spacing(2, 0),
        lineHeight: '32px',
    },
    keyValueCellValue: {
        padding: theme.spacing(2, 0),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        lineHeight: '32px',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            width: '100%',
        },
    },
    keyValueCellUnderlined: {
        '&:not(:nth-last-child(-n+2))': {
            borderBottom: `1px solid ${theme.palette.secondary.light}`,
        },
    },
    keyValueCellWithOverflow: {
        overflow: 'visible',
    },
}));

type KeyValueCellProps = {
    title: React.ReactNode;
    children: React.ReactNode;
    withOverflow?: boolean;
    titleVariant?: Variant | 'inherit';
    valueClassName?: string;
    isGrid?: boolean;
};

export function KeyValueCell({
    title,
    children,
    withOverflow = false,
    titleVariant = 'subtitle1',
    valueClassName = '',
    isGrid = true,
}: KeyValueCellProps) {
    const {classes, cx} = useClasses();

    return (
        <>
            <Box className={isGrid ? classes.keyValueCellUnderlined : ''}>
                <Typography variant={titleVariant} className={isGrid ? classes.keyValueCellTitle : ''}>
                    {title}
                </Typography>
            </Box>
            <Box
                className={
                    isGrid
                        ? cx(classes.keyValueCellUnderlined, classes.keyValueCellValue, {
                              [classes.keyValueCellWithOverflow]: withOverflow,
                          })
                        : valueClassName
                }
            >
                {children}
            </Box>
        </>
    );
}
