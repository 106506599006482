import {MessageDescriptor} from 'react-intl';

import {CustomIconValues} from '@components/icons/types';

import {ModuleName, SubmoduleName} from 'src/common/routeEnums';
import {FeatureName, JurisdictionFeature} from '../app/config/types';

export type IFormItem = {};

//TODO: suggest renaming IModuleItem -> IServerItem
export interface IModuleItem extends IFormItem {
    id: string;
}

export interface IFailureResponse {
    success: boolean;
    code: string;
    message: string;
    description: string;
    payload: any;
    values: Record<string, string>;
}

export interface IFailurePayloadError {
    code: number;
    description: string;
}

export class PageModuleAction implements JurisdictionFeature {
    moduleName?: ModuleName;
    submoduleName?: SubmoduleName;
    featureName?: FeatureName;
    permissions?: PermissionEnum[];
    //TODO: think about changing from function to increase performance
    render: () => JSX.Element;
}

export class PageModuleDropdownAction {
    name: MessageDescriptor;
    key: string;
}

export type SelectOption<TValue = string | number | string[] | boolean> = {
    label: string | MessageDescriptor;
    value: TValue;
    disabled?: boolean;
    startIcon?: CustomIconValues;
};

export type JurisdictionSelectOption = SelectOption & JurisdictionFeature;

export interface ToggleListItem {
    checked: boolean;
}

export class FieldToggleValue implements ToggleListItem {
    fieldValue: string;
    checked: boolean;
}

export type ToggleListEditModel<T> = {
    initial: T[];
    add: T[];
    remove: T[];
};

export class FormModuleData<T> {
    item: T;
    validationMessage: IFailureResponse;
    isNew: boolean;
}

export enum PermissionEnum {
    Create = 0,
    Read = 1,
    Update = 2,
    Delete = 3,
    All = 4, // this is client only permission (no support on auth server side, int value can be changed in case of conflicts)
}

export const allPermissions = [PermissionEnum.Read, PermissionEnum.Create, PermissionEnum.Update, PermissionEnum.Delete];

export interface PermissionAccessibleItem {
    permissions: PermissionEnum[];
    moduleName: ModuleName;
    submoduleName?: SubmoduleName;
}

export type KeyValueStorageData<T> = {
    [key: string]: T;
};

export type KeyValueDataStorageItem<T> = {
    key: string;
    value: T;
};
