import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {LayoutDetailsBlock} from '@components/layout/LayoutDetailsBlock';
import Toolbar from '@components/toolbar/Toolbar';
import {TransactionViewModelKeys} from '@models/transaction';
import {isStringNullOrEmpty} from '@utils';

import {TransactionDownloadCsvButton} from '../../block-transaction-actions';
import {
    availableTransactionFilterValues,
    DataGridTransactionsServer,
    TransactionFilters,
    useTransactions,
} from '../../block-transaction-list';

type TransactionHistoryProps = {
    userId: string;
};

const localized = defineMessages({
    title: {
        id: 'TransactionHistory_title',
        defaultMessage: 'Transaction History',
    },
});

export function TransactionHistory({userId}: TransactionHistoryProps) {
    const keys: TransactionViewModelKeys[] = [
        'transaction_updated_ts',
        'transaction_type',
        'payment_method_name',
        'payment_method_description',
        'amount_without_currency',
        'currency',
        'transaction_status',
        'current_balance',
    ];
    const {items, totalCount, searchFilter, filterString, handlePageChange, handlePageSizeChange, handleSortChange, handleFilterChange} =
        useTransactions({
            viewType: 'TransactionHistory',
            displayName: localized.title,
            fields: keys,
            defaultFilters: [
                {key: 'uid', value: userId},
                {key: 'defaultTransactionTypes', value: availableTransactionFilterValues},
            ],
            defaultSorting: 'updated',
            validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'uid')?.value),
        });
    const {formatMessage} = useIntl();

    return (
        <LayoutDetailsBlock
            title={localized.title}
            toolbar={
                <Toolbar
                    primary={
                        <>
                            <TransactionFilters
                                filter={filterString}
                                onFilterChange={handleFilterChange}
                                filters={['transactionHistoryTypes', 'paymentMethod', 'amount', 'status', 'date']}
                            />
                            <TransactionDownloadCsvButton
                                keys={keys}
                                filterString={filterString}
                                filename={formatMessage(localized.title)}
                            />
                        </>
                    }
                />
            }
            content={
                <DataGridTransactionsServer
                    autoHeight
                    columns={keys}
                    rows={items}
                    rowCount={totalCount}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={handleSortChange}
                    page={searchFilter?.paging?.page}
                    pageSize={searchFilter?.paging?.pageSize}
                    sortModel={searchFilter?.sorting}
                />
            }
        ></LayoutDetailsBlock>
    );
}
