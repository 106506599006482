import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {useAutoMapper} from '@auto-mapper';
import {AgentRevenueShareWeeklyReport} from '@models/generated/graphql';
import {
    AgentLevelReportQueryFields,
    AgentLevelReportServerFilterKeys,
    AgentLevelReportSortingFields,
    BaseFilterKeys,
    EntityType,
} from '@redux/entity';
import {extendedViewCleanDelay, UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';
import {maxPageSize} from '@services/types';
import {getFilterString} from '@utils';

import {Sorting} from 'src/common/types';
import {useAsyncActionState} from '../shared/async-action/hooks';

import {localizedHeaders} from './components/DataGridAgentLevelReport.localize';
import {agentLevelReportActions} from './actions';
import {AgentLevelReportViewModel, AgentLevelReportViewModelKeys} from './types';

export function useAgentLevelReport({
    viewType,
    displayName,
    fields,
    defaultSorting,
    validateFilter,
}: UseListViewEntityProps<AgentLevelReportServerFilterKeys, AgentLevelReportViewModelKeys>): UseListViewEntityResult<
    AgentLevelReportViewModel,
    AgentLevelReportViewModelKeys
> {
    const mapper = useAutoMapper();
    const queryFields: AgentLevelReportQueryFields[] = mapFields(fields);
    const {handleSortChange, getSearchFilter, ...result} = useViewInit<
        AgentRevenueShareWeeklyReport,
        AgentLevelReportServerFilterKeys,
        AgentLevelReportQueryFields,
        AgentLevelReportSortingFields
    >({
        viewType,
        displayName,
        entity: {
            entity: EntityType.AgentLevelReport,
            fields: queryFields,
        },
        realtime: null,
        defaultSorting: mapSortingVm(defaultSorting),
        syncWithUrl: true,
        validateFilter,
        cleanDelay: extendedViewCleanDelay,
    });

    const items = result?.items?.map(item =>
        mapper.map<AgentRevenueShareWeeklyReport, AgentLevelReportViewModel>(item, AgentRevenueShareWeeklyReport, AgentLevelReportViewModel)
    );

    function mapFields(fields: AgentLevelReportViewModelKeys[]): AgentLevelReportQueryFields[] {
        return mapper.map<AgentLevelReportViewModelKeys[], AgentLevelReportQueryFields[]>(
            fields,
            nameof<AgentLevelReportViewModelKeys>(),
            nameof<AgentLevelReportQueryFields>()
        );
    }

    function mapSortingField(fields: AgentLevelReportSortingFields): AgentLevelReportViewModelKeys {
        return mapper.map<AgentLevelReportSortingFields, AgentLevelReportViewModelKeys>(
            fields,
            nameof<AgentLevelReportSortingFields>(),
            nameof<AgentLevelReportViewModelKeys>()
        );
    }

    function mapSortingVmField(fields: AgentLevelReportViewModelKeys): AgentLevelReportSortingFields {
        return mapper.map<AgentLevelReportViewModelKeys, AgentLevelReportSortingFields>(
            fields,
            nameof<AgentLevelReportViewModelKeys>(),
            nameof<AgentLevelReportSortingFields>()
        );
    }

    function mapSortingVm(sorting: Sorting<AgentLevelReportViewModelKeys>[]): Sorting<AgentLevelReportSortingFields>[] {
        return sorting?.map(s => ({...s, field: mapSortingVmField(s.field)}));
    }

    function handleVmSortChange(sorting: Sorting<AgentLevelReportViewModelKeys>[]) {
        handleSortChange(mapSortingVm(sorting));
    }

    return {
        ...result,
        handleSortChange: handleVmSortChange,
        items,
        filterString: result?.viewEntity?.filter,
        searchFilter: getSearchFilter(mapSortingField),
    };
}

type UseAgentLevelReportDownloadProps = {
    filterString: string;
    keys: AgentLevelReportViewModelKeys[];
    filename: string;
};

type UseAgentLevelReportDownloadResult = {
    handleClick: () => void;
    isProgress: boolean;
};

export function useAgentLevelReportDownload({
    keys,
    filename,
    filterString,
}: UseAgentLevelReportDownloadProps): UseAgentLevelReportDownloadResult {
    const dispatch = useDispatch();
    const mapper = useAutoMapper();
    const {formatMessage} = useIntl();
    const {isProgress} = useAsyncActionState(agentLevelReportActions.download);

    const headers = keys?.reduce<Record<AgentLevelReportViewModelKeys, string>>((previousValue, key) => {
        previousValue[key] = formatMessage(localizedHeaders[key]);
        return previousValue;
    }, {} as Record<AgentLevelReportViewModelKeys, string>);
    const pageFilterKey: BaseFilterKeys = 'page';
    const sizeFilterKey: BaseFilterKeys = 'size';
    const filter = getFilterString(filterString, true, {key: pageFilterKey, value: '1'}, {key: sizeFilterKey, value: `${maxPageSize}`});

    const fields = mapper.map<AgentLevelReportViewModelKeys[], AgentLevelReportQueryFields[]>(
        keys,
        nameof<AgentLevelReportViewModelKeys>(),
        nameof<AgentLevelReportQueryFields>()
    );

    function handleClick() {
        dispatch(agentLevelReportActions.download.request({filter, fields, filename, worksheetKeys: keys, headers}));
    }

    return {handleClick, isProgress};
}
