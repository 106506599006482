import {CountryInfoViewModel} from '@models/country';
import {
    AccountVerificationStatus,
    AccountVerificationType,
    JumioDocumentSubType,
    JumioDocumentType,
    KycInitReasonCode,
    KycStatus,
    PlayerVerificationStatus,
} from '@models/generated/graphql';
import {Timestamp, TimestampKeys} from '@models/shared';
import {UserLabelViewModel} from '@models/transaction/types';

export class JumioLocationViewModel {
    city?: string;
    country?: string;
    formatted?: string;
    line1?: string;
    line2?: string;
    postal_code?: string;
    subdivision?: string;
}

export class AccountVerificationStatusLogViewModel {
    account_verification_status?: AccountVerificationStatus;
    kyc_status?: KycStatus;
    logged_at?: Timestamp;
}

export class AccountVerificationDocumentViewModel {
    country_code?: string;
    device_id?: string;
    doc_ref_id?: string;
    doc_type?: JumioDocumentType;
    status?: KycStatus;
    uploaded_ts?: Timestamp;
}

export class AccountVerificationDurationViewModel {
    history: AccountVerificationStatusLogViewModel[];
    initiated_at: Timestamp;
}

export class KycDocumentViewModel {
    client_ip?: string;
    country_info?: CountryInfoViewModel;
    device_id?: string;
    device_verified?: PlayerVerificationStatus;
    did?: string;
    dob?: string;
    doc_id_sub_type?: JumioDocumentSubType;
    doc_ref_id?: string;
    doc_type?: JumioDocumentType;
    first_name?: string;
    gender?: string;
    geolocation_verified?: PlayerVerificationStatus;
    ip_verified?: PlayerVerificationStatus;
    last_name?: string;
    location?: JumioLocationViewModel;
    number?: string;
    status?: KycStatus;
    uploaded_ts?: Timestamp;
}

type AccountVerificationDocumentsViewModel = {
    id_card: KycDocumentViewModel;
};

export class AccountVerificationViewModel {
    account_verification_status?: AccountVerificationStatus;
    init_reason_codes?: KycInitReasonCode[];
    documents?: KycDocumentViewModel[];
    documents_with_link?: KycDocumentViewModel[];
    email?: string;
    id: string;
    id_ndrp?: string;
    initiated_at?: Timestamp;
    iso_alpha2_country_code?: string;
    kyc_case_id?: string;
    status_log?: AccountVerificationStatusLogViewModel[];
    type: AccountVerificationType;
    uid: string;
    username?: string;
    player_country?: string;
    duration?: AccountVerificationDurationViewModel;
    serverId: string;
    labels?: UserLabelViewModel[];
    documents_object?: AccountVerificationDocumentsViewModel;
}

type ViewModelKeys = keyof AccountVerificationViewModel;
const initiatedKey: ViewModelKeys = 'initiated_at';
const documentsKey: ViewModelKeys = 'documents_object';
type AccountVerificationDocumentsViewModelKeys = keyof AccountVerificationDocumentsViewModel;
const idCardKey: AccountVerificationDocumentsViewModelKeys = 'id_card';

const secondsKey: TimestampKeys = 'seconds';
export type AccountVerificationViewModelKeys =
    | keyof Omit<AccountVerificationViewModel, 'initiated_at'>
    | `${typeof initiatedKey}.${typeof secondsKey}`
    | `${typeof documentsKey}.${typeof idCardKey}.${keyof Pick<KycDocumentViewModel, 'uploaded_ts'>}`;
