import {gql} from '@apollo/client';

import {GqlEntityArgs} from './types';

//TODO: [BO-2938] Cleanup file after Bonus module is moved to view feature
const fragmentAll = gql`
    fragment GlobalSearchFragment on GlobalSearchRecord {
        key @client
        source @client
        meta @client
    }
`;

export const globalSearch: GqlEntityArgs = {
    fragmentAll,
    queryAll: gql`
        ${fragmentAll}
        query Search($filter: GlobalSearchFilter, $limit: Int) {
            search(filter: $filter, limit: $limit) {
                ...GlobalSearchFragment
            }
        }
    `,
};
