import {LabelGroupSourceType, Timestamp, TransactionStatus, TransactionType as GqlTransactionType} from '@models/generated/graphql';

export class StatusLog {
    logged_at: Timestamp;
    status: TransactionStatus;
}

export class TransactionDuration {
    history: StatusLog[];
    started_at: Timestamp;
}

export class UserLabelViewModel {
    group: UserLabelGroupViewModel;
    id: string;
    name: string;
}
export class UserLabelGroupViewModel {
    color: string;
    id: string;
    name: string;
    source_type?: LabelGroupSourceType;
}

export type TransactionFilterType = GqlTransactionType | 'FEES';

export class TransactionViewModel {
    amount?: number;
    amount_without_currency?: number;
    counterpart_player_id?: string;
    created_by_uid?: string;
    currency?: string;
    current_balance?: number;
    duration?: TransactionDuration;
    email?: string;
    id: string;
    payment_method_description?: string;
    payment_method_name?: string;
    phone?: string;
    player_country?: string;
    referrer_player_id?: string;
    register_marketing_code?: string;
    status_log?: StatusLog[];
    transaction_id: string;
    transaction_started_ts?: Timestamp;
    transaction_updated_ts?: Timestamp;
    transaction_status?: TransactionStatus;
    transaction_type?: GqlTransactionType;
    type?: GqlTransactionType;
    uid?: string;
    username?: string;
    withdrawal_id?: string;
    user_labels: UserLabelViewModel[];
    p2p_direction?: GqlTransactionType;
}

const started: keyof TransactionViewModel = 'transaction_started_ts';
const updated: keyof TransactionViewModel = 'transaction_updated_ts';

export type TransactionViewModelKeys =
    | keyof TransactionViewModel
    | `${typeof started}.${keyof Omit<Timestamp, '__typename'>}`
    | `${typeof updated}.${keyof Omit<Timestamp, '__typename'>}`;

export type TransactionEditableViewModel = Partial<TransactionViewModel> & {id: string};

export type TransactionViewModelEditableKeys =
    | keyof TransactionEditableViewModel
    | 'p2p_transfer_amount_editable'
    | 'p2p_direction_editable';

export class TransactionDownloadModel implements Record<TransactionViewModelKeys, string> {
    amount: string;
    amount_without_currency: string;
    counterpart_player_id: string;
    created_by_uid: string;
    currency: string;
    current_balance: string;
    duration: string;
    email: string;
    id: string;
    payment_method_description: string;
    payment_method_name: string;
    phone: string;
    player_country: string;
    referrer_player_id: string;
    status_log: string;
    transaction_id: string;
    transaction_started_ts: string;
    transaction_updated_ts: string;
    'transaction_updated_ts.seconds': string;
    'transaction_updated_ts.nanos': string;
    'transaction_started_ts.seconds': string;
    'transaction_started_ts.nanos': string;
    transaction_status: string;
    transaction_type: string;
    type: string;
    uid: string;
    username: string;
    withdrawal_id: string;
    register_marketing_code: string;
    user_labels: string;
    p2p_direction: string;
}
