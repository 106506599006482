import {PermissionAccessibleItem, PermissionEnum} from '@models/permissions/types';

import {ModuleName, SubmoduleName} from '../../routeEnums';

export const agentReadPolicy: PermissionAccessibleItem = {moduleName: ModuleName.Agent, permissions: [PermissionEnum.Read]};

export const agentUpdatePolicy: PermissionAccessibleItem = {moduleName: ModuleName.Agent, permissions: [PermissionEnum.Update]};

export const agentReadPolicies: PermissionAccessibleItem[] = [agentReadPolicy];

export const agentVnReadPolicy: PermissionAccessibleItem = {moduleName: ModuleName.AgentVn, permissions: [PermissionEnum.Read]};
export const agentKrReadPolicy: PermissionAccessibleItem = {moduleName: ModuleName.AgentKr, permissions: [PermissionEnum.Read]};

export const customerSupportUpdatePermission: PermissionAccessibleItem = {
    moduleName: ModuleName.CustomerSupport,
    permissions: [PermissionEnum.Update],
};

export const customerSupportUpdatePermissions: PermissionAccessibleItem[] = [customerSupportUpdatePermission];

export const customerSupportReadPermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.CustomerSupport, permissions: [PermissionEnum.Read]},
];

export const securityWorkspaceReadPermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.SecurityWorkspace, permissions: [PermissionEnum.Read]},
];

export const securityWorkspaceUpdatePermission: PermissionAccessibleItem = {
    moduleName: ModuleName.SecurityWorkspace,
    permissions: [PermissionEnum.Update],
};

export const securityWorkspaceUpdatePermissions: PermissionAccessibleItem[] = [securityWorkspaceUpdatePermission];

export const withdrawalProcessingPermissions = [
    {
        moduleName: ModuleName.WithdrawalProcessing,
        permissions: [PermissionEnum.Read],
    },
    {
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.Payment,
        permissions: [PermissionEnum.Read],
    },
    {
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.WithdrawalProcessingRisk,
        permissions: [PermissionEnum.Read],
    },
];

export const accessManagementUsersUpdatePermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.AccessManagement, submoduleName: SubmoduleName.Users, permissions: [PermissionEnum.Update]},
];

export const kycProcessingReadPermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.KycProcessing, permissions: [PermissionEnum.Read]},
];

export const teamWorkspaceReadPermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.TeamWorkspace, permissions: [PermissionEnum.Read]},
];

export const gameAdminReadPermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.GameAdmin, permissions: [PermissionEnum.Read]},
];

export const gameTableUpdatePermission: PermissionAccessibleItem = {
    moduleName: ModuleName.EntityGameTable,
    permissions: [PermissionEnum.Update],
};
export const gameTableDeletePermission: PermissionAccessibleItem = {
    moduleName: ModuleName.EntityGameTable,
    permissions: [PermissionEnum.Delete],
};

export const gameTemplateCreatePermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.EntityGameTemplate, permissions: [PermissionEnum.Create]},
];
export const gameTemplateUpdatePermission: PermissionAccessibleItem = {
    moduleName: ModuleName.EntityGameTemplate,
    permissions: [PermissionEnum.Update],
};
export const gameTemplateUpdatePermissions: PermissionAccessibleItem[] = [gameTemplateUpdatePermission];

export const bonusCodeUpdatePermission: PermissionAccessibleItem = {
    moduleName: ModuleName.EntityBonus,
    permissions: [PermissionEnum.Update],
};

export const pageRuleReadPermissions: PermissionAccessibleItem[] = [{moduleName: ModuleName.PageRules, permissions: [PermissionEnum.Read]}];
export const ruleUpdatePermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.EntityRule, permissions: [PermissionEnum.Update]},
];

export const notesReadPermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesSecurity, permissions: [PermissionEnum.Read]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.Payment, permissions: [PermissionEnum.Read]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.KYC, permissions: [PermissionEnum.Read]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesCustomerSupport, permissions: [PermissionEnum.Read]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesPlayer, permissions: [PermissionEnum.Read]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesBotDetection, permissions: [PermissionEnum.Read]},
];

export const notesCreatePermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.Comments, permissions: [PermissionEnum.Create]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesSecurity, permissions: [PermissionEnum.Create]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.Payment, permissions: [PermissionEnum.Create]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.KYC, permissions: [PermissionEnum.Create]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesCustomerSupport, permissions: [PermissionEnum.Create]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesPlayer, permissions: [PermissionEnum.Create]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesBotDetection, permissions: [PermissionEnum.Create]},
];
