import {constructUsing, createMap, extend, forMember, mapFrom, Mapper, mapWith} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {Device, Location, Timestamp, UserLogin} from '@models/generated/graphql';
import {Timestamp as TimestampViewModel} from '@models/shared';
import {UserLoginQueryFields} from '@redux/entity';
import {formatTimestamp} from '@utils';

import {
    DeviceViewModel,
    LocationViewModel,
    UserLoginDownloadModel,
    UserLoginSortingFields,
    UserLoginViewModel,
    UserLoginViewModelKeys,
} from './types';

export class UserLoginMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap<Device, DeviceViewModel>(
            mapper,
            Device,
            DeviceViewModel,
            forMember(
                vm => vm.agent,
                mapFrom(m => m.agent)
            ),
            forMember(
                vm => vm.mac_address,
                mapFrom(m => m.mac_address)
            ),
            forMember(
                vm => vm.model,
                mapFrom(m => m.model)
            ),
            forMember(
                vm => vm.name,
                mapFrom(m => m.name)
            ),
            forMember(
                vm => vm.operating_system,
                mapFrom(m => m.operating_system)
            ),
            forMember(
                vm => vm.uuid,
                mapFrom(m => m.uuid)
            )
        );

        createMap<Location, LocationViewModel>(
            mapper,
            Location,
            LocationViewModel,
            forMember(
                vm => vm.city,
                mapFrom(m => m.city)
            ),
            forMember(
                vm => vm.country,
                mapFrom(m => m.country)
            ),
            forMember(
                vm => vm.latitude,
                mapFrom(m => m.latitude)
            ),
            forMember(
                vm => vm.longitude,
                mapFrom(m => m.longitude)
            ),
            forMember(
                vm => vm.state,
                mapFrom(m => m.state)
            )
        );

        createMap<UserLogin, UserLoginViewModel>(
            mapper,
            UserLogin,
            UserLoginViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => m.session_id)
            ),
            forMember(
                vm => vm.connection_type,
                mapFrom(m => m.connection_type)
            ),
            forMember(
                vm => vm.device,
                mapWith(DeviceViewModel, Device, m => m.device)
            ),
            forMember(
                vm => vm.ip,
                mapFrom(m => m.ip)
            ),
            forMember(
                vm => vm.isp,
                mapFrom(m => m.isp)
            ),
            forMember(
                vm => vm.location,
                mapWith(LocationViewModel, Location, m => m.location)
            ),
            forMember(
                vm => vm.logged_at_ts,
                mapWith(TimestampViewModel, Timestamp, m => m.logged_at_ts)
            ),
            forMember(
                vm => vm.login_status,
                mapFrom(m => m.login_status)
            ),
            forMember(
                vm => vm.logged_out_at_ts,
                mapWith(TimestampViewModel, Timestamp, m => m.logout_info?.logged_out_at_ts)
            ),
            forMember(
                vm => vm.session_id,
                mapFrom(m => m.session_id)
            ),
            forMember(
                vm => vm.uid,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.username,
                mapFrom(m => m.username)
            )
        );

        createMap<UserLoginViewModelKeys[], UserLoginQueryFields[]>(
            mapper,
            nameof<UserLoginViewModelKeys>(),
            nameof<UserLoginQueryFields>(),
            constructUsing((m, _) => {
                const mapper: Record<UserLoginViewModelKeys, UserLoginQueryFields[]> = {
                    id: ['session_id'],
                    connection_type: ['connection_type'],
                    'device.uuid': ['device.uuid'],
                    'device.name': ['device.name'],
                    'device.model': ['device.model'],
                    'device.mac_address': ['device.mac_address'],
                    'device.operating_system': ['device.operating_system'],
                    ip: ['ip'],
                    isp: ['isp'],
                    'location.city': ['location.city'],
                    'location.country': ['location.country'],
                    'location.state': ['location.state'],
                    logged_at_ts: ['logged_at_ts.seconds'],
                    login_status: ['login_status'],
                    logged_out_at_ts: ['logout_info'],
                    session_id: ['session_id'],
                    uid: ['uid'],
                    username: ['username'],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );

        createMap<UserLogin, UserLoginDownloadModel>(
            mapper,
            UserLogin,
            UserLoginDownloadModel,
            extend(UserLogin, UserLoginViewModel),
            forMember(
                vm => vm.logged_at_ts,
                mapFrom(m => formatTimestamp(m.logged_at_ts, 'date-time'))
            ),
            forMember(
                vm => vm.logged_out_at_ts,
                mapFrom(m => formatTimestamp(m.logout_info?.logged_out_at_ts, 'date-time'))
            ),
            forMember(
                vm => vm['device.mac_address'],
                mapFrom(m => m.device?.mac_address)
            ),
            forMember(
                vm => vm['device.model'],
                mapFrom(m => m.device?.model)
            ),
            forMember(
                vm => vm['device.operating_system'],
                mapFrom(m => m.device?.operating_system)
            ),
            forMember(
                vm => vm['device.name'],
                mapFrom(m => m.device?.name)
            ),
            forMember(
                vm => vm['device.uuid'],
                mapFrom(m => m.device?.uuid)
            ),
            forMember(
                vm => vm['location.city'],
                mapFrom(m => m.location?.city)
            ),
            forMember(
                vm => vm['location.country'],
                mapFrom(m => m.location?.country)
            ),
            forMember(
                vm => vm['location.state'],
                mapFrom(m => m.location?.state)
            )
        );

        createMap<UserLoginSortingFields, UserLoginViewModelKeys>(
            mapper,
            nameof<UserLoginSortingFields>(),
            nameof<UserLoginViewModelKeys>(),
            constructUsing(m => {
                const mapper: Record<UserLoginSortingFields, UserLoginViewModelKeys> = {
                    connection_type: 'connection_type',
                    ip: 'ip',
                    isp: 'isp',
                    'logged_at_ts.seconds': 'logged_at_ts',
                    login_status: 'login_status',
                    username: 'username',
                    'logout_info.logged_out_at_ts.seconds': 'logged_out_at_ts',
                    'device.mac_address': 'device.mac_address',
                    'device.model': 'device.model',
                    'device.name': 'device.name',
                    'device.operating_system': 'device.operating_system',
                    'device.uuid': 'device.uuid',
                    'location.city': 'location.city',
                    'location.country': 'location.country',
                    'location.state': 'location.state',
                    session_id: 'session_id',
                    uid: 'uid',
                };

                return mapper[m];
            })
        );

        createMap<UserLoginViewModelKeys, UserLoginSortingFields>(
            mapper,
            nameof<UserLoginViewModelKeys>(),
            nameof<UserLoginSortingFields>(),
            constructUsing(m => {
                const mapper: Record<UserLoginViewModelKeys, UserLoginSortingFields> = {
                    'device.mac_address': 'device.mac_address',
                    'device.model': 'device.model',
                    'device.name': 'device.name',
                    'device.operating_system': 'device.operating_system',
                    'device.uuid': 'device.uuid',
                    'location.city': 'location.city',
                    'location.country': 'location.country',
                    'location.state': 'location.state',
                    connection_type: 'connection_type',
                    id: 'session_id',
                    ip: 'ip',
                    isp: 'isp',
                    logged_at_ts: 'logged_at_ts.seconds',
                    logged_out_at_ts: 'logout_info.logged_out_at_ts.seconds',
                    login_status: 'login_status',
                    session_id: 'session_id',
                    uid: 'uid',
                    username: 'username',
                };

                return mapper[m];
            })
        );
    }
}
