import React, {useEffect} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useIntl} from 'react-intl';

import {FilterWithDropdownValue, TextInputWithDropdownFilter} from '@components/filter/TextFilterWithDropdown';

import {Filter} from 'src/common/types';

import withFilter, {FilterInnerProps, SingleInputModel} from './FilterHoc';

const TextInputWithDropdownFilterObsolete = ({filter, value, filterKey, onKeyChange}: FilterInnerProps<Filter>) => {
    const {formatMessage} = useIntl();

    const {reset, control} = useFormContext<SingleInputModel>();
    // FilterHoc and this component interface are not compatible. Component expects value of Filter type, but string is passed
    const defaultValue: any = {inputValue: value};
    useEffect(() => {
        if (!filterKey) {
            onKeyChange(filter?.options?.[0]?.value);
        }
    }, [filterKey]);

    useEffect(() => {
        reset(defaultValue);
    }, [defaultValue.inputValue]);

    function handleChange(newValue: FilterWithDropdownValue, inputChange: (value: string) => void) {
        if (newValue.text !== null) {
            inputChange(newValue.text);
        }
        if (newValue.option !== filterKey) {
            onKeyChange(newValue.option);
        }
    }

    const placeholder = filter?.getLabel(filterKey ?? filter?.options?.[0]?.value);

    return (
        <Controller
            render={({field}) => {
                return (
                    <TextInputWithDropdownFilter
                        value={{text: field.value, option: filterKey}}
                        onChange={value => handleChange(value, field.onChange)}
                        options={filter?.options}
                        inputPlaceholder={placeholder ? formatMessage(placeholder) : null}
                        width="full"
                    />
                );
            }}
            name="inputValue"
            control={control}
        />
    );
};

const FilterTextWithDropdown = withFilter(TextInputWithDropdownFilterObsolete);

/**
 * @deprecated Use {@link TextInputWithDropdownFilter} instead
 */
export default FilterTextWithDropdown;
