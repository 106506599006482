import {useContext, useEffect, useState} from 'react';

import {UserSettings} from '@user-settings';

import {UserSettingsContext} from './components/UserSettingsProvider';
import {IDbUserSettingsStorage} from './types';

export function useUserStorage(): IDbUserSettingsStorage {
    const {storage} = useContext(UserSettingsContext);

    return storage;
}

type UseUserSettingsProps = {
    userId: string;
    featureName: string;
    settingName: string;
};

export type UseUserSettingsResult<TValue> = {
    settings?: UserSettings<TValue>;
    onSettingsChange?: (value: TValue) => void;
};

export function useUserSettings<TValue>(props: UseUserSettingsProps): UseUserSettingsResult<TValue> {
    const {userId, featureName, settingName} = props;
    const userSettingsStorage = useUserStorage();
    const [settingsId, setSettingsId] = useState<number>(null);
    const [settings, setSettings] = useState<UserSettings<TValue>>(null);
    useEffect(() => {
        if (userId && featureName && settingName) {
            userSettingsStorage.userSettings
                .get({
                    userId,
                    featureName,
                    settingName,
                })
                .then((value: UserSettings<TValue>) => {
                    if (value) {
                        setSettings(value);
                        setSettingsId(value.id);
                    }
                });
        }
    }, [userId, featureName, settingName]);

    function handleSettingsChange(value: TValue) {
        function updateState() {
            setSettings({...settings, value});
        }
        if (settingsId) {
            userSettingsStorage.userSettings.update(settingsId, {value}).then(updateState);
        } else {
            userSettingsStorage.userSettings.add({userId, featureName, settingName, value}).then(id => {
                updateState();
                setSettingsId(id);
            });
        }
    }
    return {
        settings,
        onSettingsChange: handleSettingsChange,
    };
}

type UseColumnsSettingsProps<TSettingKey extends string> = {
    userId: string;
    featureName: string;
    defaultColumns: TSettingKey[];
    pinnedColumns?: TSettingKey[];
};

type UseColumnsSettingsResult<TSettingKey extends string> = {
    visible: TSettingKey[];
    pinned: TSettingKey[];
    all: TSettingKey[];
    onVisibleColumnsChange: (value: TSettingKey[]) => void;
};

export function useColumnsSettings<TSettingKey extends string>(
    {pinnedColumns, defaultColumns, ...props}: UseColumnsSettingsProps<TSettingKey>,
    _useUserSettings = useUserSettings
): UseColumnsSettingsResult<TSettingKey> {
    const {settings, onSettingsChange} = _useUserSettings<TSettingKey[]>({...props, settingName: 'visible_columns'});
    const visibleColumns = settings?.value ? settings.value : defaultColumns;
    const pinned = pinnedColumns ?? [];
    return {
        visible: pinnedColumns ? visibleColumns.filter(col => !pinnedColumns.includes(col)) : visibleColumns,
        pinned,
        all: [...new Set([...pinned, ...visibleColumns])],
        onVisibleColumnsChange: onSettingsChange,
    };
}
