import {gql} from '@apollo/client';
import {DocumentNode} from 'graphql';

import {Subscription} from '@models/generated/graphql';
import {entityReservedId} from '@redux/entity';
import {RealtimeMessageTrigger} from '@redux/realtime';

export abstract class EntitySubscription {
    protected abstract getEntityFragment(): DocumentNode;

    protected abstract getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode>;

    protected abstract getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]>;

    public getSubscription(trigger: RealtimeMessageTrigger) {
        return this.getSubscriptions()[trigger];
    }

    public getResponseMapper(trigger: RealtimeMessageTrigger) {
        return this.getResponseMappers()[trigger];
    }
}

export class UserProfileSubscription extends EntitySubscription {
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.userProfileAdded ? [s.userProfileAdded] : []),
            Update: s => (s.userProfileUpdated ? [s.userProfileUpdated] : []),
            Delete: undefined,
        };
    }

    //TODO: [BO-2494] Refactor query fragments for GQL
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment UserProfileFragment on UserProfile {
                uid
            }
        `;
    }

    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}

                subscription OnUserProfileAdded {
                    userProfileAdded {
                        ...UserProfileFragment
                    }
                }
            `,
            Update: gql`
                ${fragment}

                subscription OnUserProfileUpdated {
                    userProfileUpdated {
                        ...UserProfileFragment
                    }
                }
            `,
            Delete: undefined,
        };
    }
}

export class TransactionSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment TransactionFragment on Transaction {
                transaction_id
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnUserTransactionAdded {
                    userTransactionAdded {
                        ...TransactionFragment
                    }
                }
            `,
            Update: gql`
                ${fragment}
                subscription OnUserTransactionUpdated {
                    userTransactionUpdated {
                        ...TransactionFragment
                    }
                }
            `,
            Delete: undefined,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.userTransactionAdded ? [s.userTransactionAdded] : []),
            Update: s => (s.userTransactionUpdated ? [s.userTransactionUpdated] : []),
            Delete: undefined,
        };
    }
}

export class AccountVerificationSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment AccountVerificationFragment on AccountVerification {
                id
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnAccountVerificationAdded {
                    accountVerificationAdded {
                        ...AccountVerificationFragment
                    }
                }
            `,
            Update: gql`
                ${fragment}
                subscription OnAccountVerificationUpdated {
                    accountVerificationUpdated {
                        ...AccountVerificationFragment
                    }
                }
            `,
            Delete: undefined,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.accountVerificationAdded ? [s.accountVerificationAdded] : []),
            Update: s => (s.accountVerificationUpdated ? [s.accountVerificationUpdated] : []),
            Delete: undefined,
        };
    }
}

export class UserActionSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment UserActionFragment on UserAction {
                id
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnUserActionAdded {
                    userActionAdded {
                        ...UserActionFragment
                    }
                }
            `,
            Update: undefined,
            Delete: undefined,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.userActionAdded ? [s.userActionAdded] : []),
            Update: undefined,
            Delete: undefined,
        };
    }
}

export class NotesSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment NoteFragment on Note {
                id
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnNoteAdded {
                    noteAdded {
                        ...NoteFragment
                    }
                }
            `,
            Update: undefined,
            Delete: undefined,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.noteAdded ? [s.noteAdded] : []),
            Update: undefined,
            Delete: undefined,
        };
    }
}

export class PlayersRelationSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment PlayersRelationFragment on PlayersRelation {
                id
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnPlayerRelationAdded {
                    playerRelationAdded {
                        ...PlayersRelationFragment
                    }
                }
            `,
            Update: undefined,
            Delete: gql`
                ${fragment}
                subscription OnPlayerRelationDeleted {
                    playerRelationDeleted {
                        ...PlayersRelationFragment
                    }
                }
            `,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.playerRelationAdded ? [s.playerReferrerAdded] : []),
            Update: undefined,
            Delete: s => (s.playerRelationDeleted ? [s.playerRelationDeleted] : []),
        };
    }
}

export class MarketingCodeSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment MarketingCodeFragment on MarketingCode {
                marketing_code
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnMarketingCodeAdded {
                    marketingCodeAdded {
                        ...MarketingCodeFragment
                    }
                }
            `,
            Update: undefined,
            Delete: gql`
                ${fragment}
                subscription OnMarketingCodesDeleted {
                    marketingCodesDeleted {
                        ...MarketingCodeFragment
                    }
                }
            `,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.marketingCodeAdded ? [s.marketingCodeAdded] : []),
            Update: undefined,
            Delete: s => (s.marketingCodesDeleted ? [...s.marketingCodesDeleted] : []),
        };
    }
}

export class PlayerBonusSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment PlayerBonusFragment on PlayerBonus {
                uid
                bonus_id
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnPlayerBonusAdded {
                    playerBonusAdded {
                        ...PlayerBonusFragment
                    }
                }
            `,
            Update: gql`
                ${fragment}
                subscription OnPlayerBonusUpdated {
                    playerBonusUpdated {
                        ...PlayerBonusFragment
                    }
                }
            `,
            Delete: undefined,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.playerBonusAdded ? [s.playerBonusAdded] : []),
            Update: s => (s.playerBonusUpdated ? [s.playerBonusUpdated] : []),
            Delete: undefined,
        };
    }
}

export class PlayerReferralSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment PlayerReferralFragment on PlayerReferral {
                referrer_id
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnPlayerReferralAdded {
                    playerReferralAdded {
                        ...PlayerReferralFragment
                    }
                }
            `,
            Update: gql`
                ${fragment}
                subscription OnPlayerReferralUpdated {
                    playerReferralUpdated {
                        ...PlayerReferralFragment
                    }
                }
            `,
            Delete: undefined,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.playerReferralAdded ? [s.playerBonusAdded] : []),
            Update: s => (s.playerReferralUpdated ? [s.playerReferralUpdated] : []),
            Delete: undefined,
        };
    }
}

export class PlayerReferrerSummarySubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment PlayerReferrerSummaryFragment on PlayerReferrerSummary {
                invite_code
                referrer {
                    uid
                }
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnPlayerReferrerSummaryAdded {
                    playerReferrerAdded {
                        ...PlayerReferrerSummaryFragment
                    }
                }
            `,
            Update: gql`
                ${fragment}
                subscription OnPlayerReferrerSummaryUpdated {
                    playerReferrerUpdated {
                        ...PlayerReferrerSummaryFragment
                    }
                }
            `,
            Delete: undefined,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.playerReferrerAdded ? [s.playerReferrerAdded] : []),
            Update: s => (s.playerReferrerUpdated ? [s.playerReferrerUpdated] : []),
            Delete: undefined,
        };
    }
}

export class BotGroupSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment BotGroupFragment on BotGroup {
                group_id
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnBotGroupAdded {
                    botGroupAdded {
                        ...BotGroupFragment
                    }
                }
            `,
            Update: gql`
                ${fragment}
                subscription OnBotGroupUpdated {
                    botGroupUpdated {
                        ...BotGroupFragment
                    }
                }
            `,
            Delete: undefined,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.botGroupAdded ? [s.botGroupAdded] : []),
            Update: s => (s.botGroupUpdated ? [s.botGroupUpdated] : []),
            Delete: undefined,
        };
    }
}

export class UserLoginSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment UserLoginFragment on UserLogin {
                uid
                session_id
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        return {
            Add: undefined,
            Update: undefined,
            Delete: undefined,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: undefined,
            Update: undefined,
            Delete: undefined,
        };
    }
}

export class ReferralSettingsSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment ReferralSettingsFragment on ReferralSettings {
                expiration_period
                revenue_share
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: undefined,
            Update: gql`
                ${fragment}
                subscription OnReferralSettingsUpdated {
                    referralSettingsUpdated {
                        ...ReferralSettingsFragment
                    }
                }
            `,
            Delete: undefined,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: undefined,
            Update: s => (s.referralSettingsUpdated ? [{entityReservedId, ...s.referralSettingsUpdated}] : []),
            Delete: undefined,
        };
    }
}

export class BulkOperationSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment BulkOperationFragment on BulkOperation {
                id
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnBulkOperationAdded {
                    bulkOperationAdded {
                        ...BulkOperationFragment
                    }
                }
            `,
            Update: gql`
                ${fragment}
                subscription OnBulkOperationUpdated {
                    bulkOperationUpdated {
                        ...BulkOperationFragment
                    }
                }
            `,
            Delete: undefined,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: undefined,
            Update: s => (s.referralSettingsUpdated ? [{entityReservedId, ...s.referralSettingsUpdated}] : []),
            Delete: undefined,
        };
    }
}

export class EventLogRecordSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment EventLogRecordFragment on EventLogRecord {
                id
            }
        `;
    }

    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnEventLogRecordAdded {
                    eventLogAdded {
                        ...EventLogRecordFragment
                    }
                }
            `,
            Update: undefined,
            Delete: undefined,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.eventLogAdded ? [s.eventLogAdded] : []),
            Update: undefined,
            Delete: undefined,
        };
    }
}

export class LabelGroupSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment LabelGroupFragment on LabelGroup {
                id
            }
        `;
    }

    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.labelGroupAdded ? [s.labelGroupAdded] : []),
            Update: s => (s.labelGroupUpdated ? [s.labelGroupUpdated] : []),
            Delete: undefined,
        };
    }

    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnLabelGroupAdded {
                    labelGroupAdded {
                        ...LabelGroupFragment
                    }
                }
            `,
            Update: gql`
                ${fragment}
                subscription OnLabelGroupUpdated {
                    labelGroupUpdated {
                        ...LabelGroupFragment
                    }
                }
            `,
            Delete: undefined,
        };
    }
}

export class GeoCheckSummarySubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment GeoCheckSummaryFragment on GeoCheckSummary {
                uid
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnGeoCheckSummaryAdded {
                    geoCheckSummaryAdded {
                        ...GeoCheckSummaryFragment
                    }
                }
            `,
            Update: gql`
                ${fragment}
                subscription OnGeoCheckSummaryUpdated {
                    geoCheckSummaryUpdated {
                        ...GeoCheckSummaryFragment
                    }
                }
            `,
            Delete: undefined,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.geoCheckSummaryAdded ? [s.geoCheckSummaryAdded] : []),
            Update: s => (s.geoCheckSummaryUpdated ? [s.geoCheckSummaryUpdated] : []),
            Delete: undefined,
        };
    }
}

export class BackpackSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment BackpackItemFragment on BackpackItem {
                uid
                bonus_id
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnBackpackItemAdded {
                    backpackItemAdded {
                        ...BackpackItemFragment
                    }
                }
            `,
            Update: gql`
                ${fragment}
                subscription OnBackpackItemUpdated {
                    backpackItemUpdated {
                        ...BackpackItemFragment
                    }
                }
            `,
            Delete: undefined,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.backpackItemAdded ? [s.backpackItemAdded] : []),
            Update: s => (s.backpackItemUpdated ? [s.backpackItemUpdated] : []),
            Delete: undefined,
        };
    }
}

export class BonusCodeSubscription extends EntitySubscription {
    protected getEntityFragment(): DocumentNode {
        return gql`
            fragment BonusCodeFragment on BonusCodeDetails {
                bonus_id
            }
        `;
    }
    protected getSubscriptions(): Record<RealtimeMessageTrigger, DocumentNode> {
        const fragment = this.getEntityFragment();
        return {
            Add: gql`
                ${fragment}
                subscription OnBonusCodeAdded {
                    beBonusAdded {
                        ...BonusCodeFragment
                    }
                }
            `,
            Update: undefined,
            Delete: undefined,
        };
    }
    protected getResponseMappers(): Record<RealtimeMessageTrigger, (s: Subscription) => unknown[]> {
        return {
            Add: s => (s.beBonusAdded ? [s.beBonusAdded] : []),
            Update: undefined,
            Delete: undefined,
        };
    }
}
