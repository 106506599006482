import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {merge, Observable, of} from 'rxjs';
import {toArray} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {BulkOperationFilterInput, QueryGetBulkOperationResultArgs} from '@models/generated/graphql';
import {
    BulkOperationFilterKeys,
    BulkOperationQueryFields,
    EntityFetchRequestPayload,
    EntityFetchServiceResponsePayload,
    Filter,
} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';
import {ServerResponseStatus} from '@services/types';

import {DynamicConfig} from 'src/configuration';
import {JurisdictionConfigService} from 'src/features/app/config/services';
import {map} from '../otel';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';

@injectable()
export class BulkOperationService extends EntityBaseGqlService<
    QueryGetBulkOperationResultArgs,
    BulkOperationQueryFields,
    BulkOperationFilterKeys
> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.JurisdictionConfigService) jurisdictionConfigService: JurisdictionConfigService,
        @inject(ServiceTypes.Config) config: DynamicConfig
    ) {
        super(client, mapper, new BulkOperationRequestBuilder(jurisdictionConfigService, config));
    }

    public get(requestPayload: EntityFetchRequestPayload<BulkOperationQueryFields>): Observable<EntityFetchServiceResponsePayload> {
        const filterParams = new URLSearchParams(requestPayload?.filter);
        const invalidFilterKey: BulkOperationFilterKeys = 'invalid';
        const invalidFilterValue = filterParams.get(invalidFilterKey);
        const idFilterKey: BulkOperationFilterKeys = 'id';
        const idFilterValue = filterParams.get(idFilterKey);
        const bulkOperations = idFilterValue?.split(/[,;|\s]/)?.map(id => super.get({...requestPayload, filter: `${idFilterKey}=${id}`}));

        return !invalidFilterValue
            ? merge(...bulkOperations).pipe(
                  toArray(),
                  map(responses => {
                      let result: EntityFetchServiceResponsePayload;
                      const isAvailableResponse = responses.every(r => r.status === ServerResponseStatus.Success);

                      if (isAvailableResponse) {
                          const items: unknown[] = responses.reduce((p, c) => p.concat(c.responsePayload?.items), []);
                          result = {
                              status: ServerResponseStatus.Success,
                              requestPayload,
                              responsePayload: {
                                  items,
                                  total: items.length,
                              },
                          };
                      } else {
                          result = {
                              status: ServerResponseStatus.Failed,
                              requestPayload,
                              responsePayload: {items: [], total: 0},
                          };
                      }
                      return result;
                  })
              )
            : of({
                  status: ServerResponseStatus.Success,
                  requestPayload,
                  responsePayload: {items: [], total: 0},
              });
    }
}

export class BulkOperationRequestBuilder extends GqlRequestBuilder<
    QueryGetBulkOperationResultArgs,
    BulkOperationQueryFields,
    BulkOperationFilterKeys
> {
    public buildQuery(fields: BulkOperationQueryFields[]): DocumentNode {
        return gql`
            query GetBulkOperationResult($filter: BulkOperationFilterInput!) {
                getBulkOperationResult(filter: $filter) {
                    id @include(if: ${fields.includes('id')})
                    status @include(if: ${fields.includes('status')})
                    items @include(if: ${this.getItemsQueryFields().some(f => fields.includes(f))}) {
                        id @include(if: ${fields.includes('items.id')})
                        status @include(if: ${fields.includes('items.status')})
                        message @include(if: ${fields.includes('items.message')})
                    }
                    start_at @include(if: ${fields.includes('start_at.seconds')}) {
                        seconds
                    }
                    end_at @include(if: ${fields.includes('end_at.seconds')}) {
                        seconds
                    }
                    entity_type @include(if: ${fields.includes('entity_type')})
                    timeout @include(if: ${fields.includes('timeout')})
                    retries @include(if: ${fields.includes('retries')})
                }
            }
        `;
    }

    protected buildFilter(filter: Filter<BulkOperationFilterKeys>): {filter: BulkOperationFilterInput} {
        return {
            filter: {
                id: filter.id as string,
            },
        };
    }

    private getItemsQueryFields(): BulkOperationQueryFields[] {
        return ['items.id', 'items.status', 'items.message'];
    }
}
