import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

export enum Placement {
    Primary = 'primary', // for left aligned filter
    Secondary = 'secondary', // for right aligned filter
    Advanced = 'advanced', // for second row (or side panel in the future)
    Tertiary = 'tertiary', // for third row (or side panel in the future)
    AdvancedSecondary = 'advancedSecondary', // right aligned advanced filters
    TertiarySecondary = 'tertiarySecondary', // right aligned tertiary filters
}

const useClasses = makeStyles()(theme => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: theme.spacing(1.25),
    },
    toolbarItemsContainer: {
        display: 'flex',
        columnGap: theme.spacing(1.25),
        [theme.breakpoints.down('sm')]: {
            columnGap: 0,
        },
    },
    toolbarItemsContainerPrimary: {
        width: '100%',
    },
    toolbarItems: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1.25),
    },
    toolbarItemsSecondary: {
        marginLeft: 'auto',
    },
    toolbarItemsStretched: {
        flex: 1,
    },
}));

type ToolbarProps = Partial<Record<Placement, JSX.Element>>;

function Toolbar(components: ToolbarProps) {
    const {classes, cx} = useClasses();

    const propsAreNotEmpty = !!Object.keys(components)
        .map((k: Placement) => components[k] ?? [])
        .flat().length;

    return propsAreNotEmpty ? (
        <Box className={classes.toolbar}>
            <Box className={cx(classes.toolbarItemsContainerPrimary, classes.toolbarItemsContainer)}>
                <Box className={cx(classes.toolbarItemsStretched, classes.toolbarItems)}>{components[Placement.Primary]}</Box>
                <Box className={cx(classes.toolbarItemsSecondary, classes.toolbarItems)}>{components[Placement.Secondary]}</Box>
            </Box>
            {components[Placement.Advanced] || components[Placement.AdvancedSecondary] ? (
                <Box className={classes.toolbarItemsContainer}>
                    <Box className={classes.toolbarItems}>{components[Placement.Advanced]}</Box>
                    <Box className={cx(classes.toolbarItemsSecondary, classes.toolbarItems)}>{components[Placement.AdvancedSecondary]}</Box>
                </Box>
            ) : null}
            {components[Placement.Tertiary] || components[Placement.TertiarySecondary] ? (
                <Box className={classes.toolbarItemsContainer}>
                    <Box className={classes.toolbarItems}>{components[Placement.Tertiary]}</Box>
                    <Box className={cx(classes.toolbarItemsSecondary, classes.toolbarItems)}>{components[Placement.TertiarySecondary]}</Box>
                </Box>
            ) : null}
        </Box>
    ) : null;
}

export default Toolbar;
