import {Table} from 'dexie';

export class UserSettings<TValue = unknown> {
    id?: number;
    userId: string;
    featureName: string;
    settingName: string;
    value: TValue;
}

export interface IDbUserSettingsStorage {
    userSettings: Table<UserSettings, number>;
}

const dbUserSettingsIndex = `[${nameof.toArray<UserSettings>(u => [u.userId, u.featureName, u.settingName]).join('+')}]`;

export const dbUserSettingsStorageConfig = {userSettings: `++${nameof<UserSettings>(i => i.id)}, ${dbUserSettingsIndex}`};
