import {constructUsing, createMap, forMember, fromValue, mapFrom, Mapper, mapWith} from '@automapper/core';
import moment from 'moment/moment';

import {IMapping} from '@auto-mapper';
import {MultiSelectWithExcludeModel} from '@components/select';
import {CasinoGame, CasinoSourceType, CasinoSupplier} from '@models/casino-game';
import {
    BonusCasinoInput,
    BonusCasinoSourceType,
    BonusCodeDetails,
    BonusCodeInput,
    BonusCreditType,
    BonusMetaInput,
    BonusReleaseMechanism,
    BonusReleaseMetric,
    BonusReleaseRatioType,
    BonusStatus,
    BonusToCredit,
    Platform,
    TimestampInput,
} from '@models/generated/graphql';
import {Timestamp} from '@models/shared';
import {BonusCodeQueryFields} from '@redux/entity';
import {CasinoArrayFilterMode, CasinoArrayFilterWithMode} from '@services/rest-api/casinoCmsApiService';
import {convertDaysToSeconds, momentToTimestampSeconds} from '@utils';

import {
    BonusCodeDetailsViewModel,
    BonusCodeDetailsViewModelKeys,
    CasinoGameViewModel,
    CasinoSupplierViewModel,
    DepositCashBonusFormViewModel,
} from './types';

const bonusStartTimeDefault = 1640139000; //Wednesday, December 22, 2021 2:10:00 AM
const bonusEndTimeDefault = 2147483000; //Tuesday, January 19, 2038 3:03:20 AM

export class BonusCodeMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            BonusCodeDetails,
            BonusCodeDetailsViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => m.bonus_id)
            ),
            forMember(
                vm => vm.bonus_id,
                mapFrom(m => m.bonus_id)
            ),
            forMember(
                vm => vm.bonus_name,
                mapFrom(m => m.bonus_name)
            ),
            forMember(
                vm => vm.bonus_status,
                mapFrom(m => m.bonus_status)
            ),
            forMember(
                vm => vm.bonus_to_credit,
                mapFrom(m => m.bonus_to_credit)
            ),
            forMember(
                vm => vm.create_time,
                mapFrom(m => m.create_time)
            ),
            forMember(
                vm => vm.credit_type,
                mapFrom(m => m.credit_type)
            ),
            forMember(
                vm => vm.description,
                mapFrom(m => m.description)
            ),
            forMember(
                vm => vm.end_time,
                mapFrom(m => m.end_time)
            ),
            forMember(
                vm => vm.last_update,
                mapFrom(m => m.last_update)
            ),
            forMember(
                vm => vm.marketing_codes,
                mapFrom(m => m.marketing_codes)
            ),
            forMember(
                vm => vm.meta,
                mapFrom(m => m.meta)
            ),
            forMember(
                vm => vm.platform,
                mapFrom(m => m.platform)
            ),
            forMember(
                vm => vm.release_mechanism,
                mapFrom(m => m.release_mechanism)
            ),
            forMember(
                vm => vm.release_metric,
                mapFrom(m => m.release_metric)
            ),
            forMember(
                vm => vm.release_ratio_type,
                mapFrom(m => m.release_ratio_type)
            ),
            forMember(
                vm => vm.start_time,
                mapFrom(m => m.start_time)
            ),
            forMember(
                vm => vm.threshold,
                mapFrom(m => m.threshold)
            ),
            forMember(
                vm => vm.total_paid_out_amount,
                mapFrom(m => m.total_paid_out_amount)
            )
        );
        createMap(
            mapper,
            CasinoGame,
            CasinoGameViewModel,
            forMember(
                vm => vm.code,
                mapFrom(m => m.code)
            ),
            forMember(
                vm => vm.supplier,
                mapFrom(m => m.supplier?.data?.attributes?.code)
            ),
            forMember(
                vm => vm.aggregator,
                mapFrom(m => m.aggregator)
            ),
            forMember(
                vm => vm.title,
                mapFrom(m => m.title)
            ),
            forMember(
                vm => vm.isActive,
                mapFrom(m => m.isActive)
            ),
            forMember(
                vm => vm.createdAt,
                mapWith(Timestamp, Date, m => new Date(m.createdAt))
            ),
            forMember(
                vm => vm.updatedAt,
                mapWith(Timestamp, Date, m => new Date(m.updatedAt))
            )
        );
        createMap(
            mapper,
            CasinoSupplier,
            CasinoSupplierViewModel,
            forMember(
                vm => vm.code,
                mapFrom(m => m.code)
            ),
            forMember(
                vm => vm.name,
                mapFrom(m => m.name)
            ),
            forMember(
                vm => vm.isActive,
                mapFrom(m => m.isActive)
            ),
            forMember(
                vm => vm.createdAt,
                mapWith(Timestamp, Date, m => new Date(m.createdAt))
            ),
            forMember(
                vm => vm.updatedAt,
                mapWith(Timestamp, Date, m => new Date(m.updatedAt))
            )
        );
        createMap(
            mapper,
            MultiSelectWithExcludeModel,
            CasinoArrayFilterWithMode,
            forMember(
                m => m.items,
                mapFrom(vm => (vm?.include?.length > 0 ? vm?.include : vm?.exclude) ?? [])
            ),
            forMember(
                m => m.mode,
                mapFrom<MultiSelectWithExcludeModel, CasinoArrayFilterWithMode, CasinoArrayFilterMode>(vm =>
                    vm?.include?.length > 0 ? 'included' : 'excluded'
                )
            )
        );

        createMap(
            mapper,
            DepositCashBonusFormViewModel,
            BonusMetaInput,
            forMember(m => m.time_range_by_date, fromValue(1)),
            forMember(
                m => m.min_deposit,
                mapFrom(vm => Number(vm.min_deposit))
            ),
            forMember(
                m => m.bonus_value_param,
                mapFrom(vm => Number(vm.bonus_value_param))
            ),
            forMember(
                m => m.max_bonus_get,
                mapFrom(vm => Number(vm.maximum_bonus))
            ),
            forMember(
                m => m.overall_max_bonus_get,
                mapFrom(vm => Number(vm.maximum_bonus))
            ),
            forMember(
                m => m.metric_qualify,
                mapFrom(vm => Number(vm.metric_qualify))
            ),
            forMember(
                m => m.metric_amount,
                mapFrom(vm => Number(vm.metric_amount))
            ),
            forMember<DepositCashBonusFormViewModel, BonusMetaInput, BonusCasinoInput>(
                m => m.casino_excluded,
                mapFrom(vm => {
                    return vm.release_metric === BonusReleaseMetric.WagerGenerated
                        ? {
                              source_types: vm?.source_types?.exclude?.map(this.mapCasinoSourceTypeEnum) ?? [],
                              aggregators: vm?.aggregators?.exclude ?? [],
                              supplier_codes: vm?.suppliers?.exclude ?? [],
                              game_codes: vm?.games?.exclude ?? [],
                          }
                        : undefined;
                })
            ),
            forMember<DepositCashBonusFormViewModel, BonusMetaInput, BonusCasinoInput>(
                m => m.casino_included,
                mapFrom(vm => {
                    return vm.release_metric === BonusReleaseMetric.WagerGenerated
                        ? {
                              source_types: vm?.source_types?.include?.map(this.mapCasinoSourceTypeEnum) ?? [],
                              aggregators: vm?.aggregators?.include ?? [],
                              supplier_codes: vm?.suppliers?.include ?? [],
                              game_codes: vm?.games?.include ?? [],
                          }
                        : undefined;
                })
            ),
            forMember(
                m => m.expire_period,
                mapFrom(vm => convertDaysToSeconds(Number(vm.expire_period)))
            ),
            forMember(
                m => m.unrealized_expire_period,
                mapFrom(vm => convertDaysToSeconds(Number(vm.expire_period)))
            )
        );
        createMap(
            mapper,
            DepositCashBonusFormViewModel,
            BonusCodeInput,
            forMember(
                m => m.bonus_name,
                mapFrom(vm => vm.bonus_name)
            ),
            forMember(
                m => m.description,
                mapFrom(vm => vm.description)
            ),
            forMember(m => m.bonus_status, fromValue(BonusStatus.Active)),
            forMember(m => m.platform, fromValue(Platform.Wpto)),
            forMember<DepositCashBonusFormViewModel, BonusCodeInput, TimestampInput>(
                m => m.create_time,
                mapFrom(() => ({seconds: momentToTimestampSeconds(moment())}))
            ),
            forMember<DepositCashBonusFormViewModel, BonusCodeInput, TimestampInput>(
                m => m.start_time,
                fromValue({seconds: bonusStartTimeDefault})
            ),
            forMember<DepositCashBonusFormViewModel, BonusCodeInput, TimestampInput>(
                m => m.end_time,
                fromValue({seconds: bonusEndTimeDefault})
            ),
            forMember(m => m.credit_type, fromValue(BonusCreditType.CashBonus)),
            forMember(m => m.bonus_to_credit, fromValue(BonusToCredit.PercentOfTriggerAmount)),
            forMember(m => m.release_mechanism, fromValue(BonusReleaseMechanism.AmountPerReleaseMetricAmount)),
            forMember(
                m => m.release_metric,
                mapFrom(vm => vm.release_metric)
            ),
            forMember(m => m.release_ratio_type, fromValue(BonusReleaseRatioType.Fixed)),
            forMember(
                m => m.meta,
                mapWith(BonusMetaInput, DepositCashBonusFormViewModel, vm => vm)
            )
        );
        createMap<BonusCodeDetailsViewModelKeys, BonusCodeQueryFields>(
            mapper,
            nameof<BonusCodeDetailsViewModelKeys>(),
            nameof<BonusCodeQueryFields>(),
            constructUsing(m => {
                const mapper: Record<BonusCodeDetailsViewModelKeys, BonusCodeQueryFields> = {
                    id: 'bonus_id',
                    bonus_id: 'bonus_id',
                    bonus_name: 'bonus_name',
                    bonus_status: 'bonus_status',
                    bonus_to_credit: 'bonus_to_credit',
                    'create_time.seconds': 'create_time.seconds',
                    credit_type: 'credit_type',
                    description: 'description',
                    end_time: 'end_time',
                    last_update: 'last_update',
                    marketing_codes: 'marketing_codes',
                    'meta.bonus_value_param': 'meta.bonus_value_param',
                    'meta.casino_excluded': 'meta.casino_excluded',
                    'meta.casino_included': 'meta.casino_included',
                    'meta.expire_period': 'meta.expire_period',
                    'meta.max_bonus_get': 'meta.max_bonus_get',
                    'meta.metric_amount': 'meta.metric_amount',
                    'meta.metric_qualify': 'meta.metric_qualify',
                    'meta.min_deposit': 'meta.min_deposit',
                    'meta.overall_max_bonus_get': 'meta.overall_max_bonus_get',
                    'meta.rake_amount': 'meta.rake_amount',
                    'meta.rake_qualify': 'meta.rake_qualify',
                    'meta.release_ratios': 'meta.release_ratios',
                    'meta.show_max_bonus_in_total': 'meta.show_max_bonus_in_total',
                    'meta.ticket_serial': 'meta.ticket_serial',
                    'meta.time_range_by_date': 'meta.time_range_by_date',
                    'meta.total_max_bonus_get': 'meta.total_max_bonus_get',
                    'meta.unrealized_expire_period': 'meta.unrealized_expire_period',
                    platform: 'platform',
                    release_mechanism: 'release_mechanism',
                    release_metric: 'release_metric',
                    release_ratio_type: 'release_ratio_type',
                    start_time: 'start_time',
                    threshold: 'threshold',
                    total_paid_out_amount: 'total_paid_out_amount',
                };

                return mapper[m];
            })
        );
    }

    private mapCasinoSourceTypeEnum(type: CasinoSourceType): BonusCasinoSourceType {
        const sourceTypeMap: Record<CasinoSourceType, BonusCasinoSourceType> = {
            [CasinoSourceType.Internal]: BonusCasinoSourceType.Internal,
            [CasinoSourceType.External]: BonusCasinoSourceType.External,
        };

        return sourceTypeMap[type];
    }
}
