import {ModuleName, SubmoduleName} from '../../common/routeEnums';
import {PermissionAccessibleItem, PermissionEnum} from '../module-shared/types';

export const initiatePaymentKycPermissions: PermissionAccessibleItem[] = [
    {
        moduleName: ModuleName.WithdrawalProcessing,
        permissions: [PermissionEnum.Update],
    },
    {
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.Payment,
        permissions: [PermissionEnum.Update],
    },
    {
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.WithdrawalProcessingRisk,
        permissions: [PermissionEnum.Update],
    },
    {
        moduleName: ModuleName.CustomerSupport,
        permissions: [PermissionEnum.Update],
    },
];
