import {push} from '@vs-centaurea/connected-react-router';
import {merge, of} from 'rxjs';
import {delay, filter, takeUntil} from 'rxjs/operators';
import {isActionOf, PayloadAction, RootState} from 'typesafe-actions';

import {mergeMap, switchMap} from '@otel';
import {
    BaseFilterKeys,
    entitiesSelector,
    EntitiesState,
    entityActions,
    EntityFetchActionPayload,
    EntityKeysMapper,
    EntityType,
    ParsedEntityKey,
    schemaMapper,
} from '@redux/entity';
import {realtimeActions} from '@redux/realtime';
import {appendQuery, filterNotNulls, getFilterString, getPagingString, getUrlWithoutUtilityFilters, queryKeys} from '@utils';

import {Filter, Paging, Sorting} from 'src/common/types';
import {locationSearchSelector, locationSelector} from 'src/features/app/routing/selectors';
import {BaseEpicsBuilder, RootEpic} from '..';

import {viewActions} from './actions';
import {ViewsState, ViewState} from './reducers';
import {createViewSelector, viewEntityFilterSelector, viewsSelector} from './selectors';
import {ViewEntityData} from './types';

export class ViewEpicsBuilder extends BaseEpicsBuilder {
    protected buildEpicList(): RootEpic[] {
        return [
            this.buildInitEpic(),
            this.buildInitFilterEpic(),
            this.buildUpdateFilterEpic(),
            this.buildUpdateFilterKeysEpic(),
            this.buildUpdateKeysEpic(),
            this.buildUpdateEpic(),
            this.buildMarkUnusedEpic(),
            this.buildCloseEpic(),
        ];
    }

    buildInitEpic() {
        const initEpic: RootEpic = (action$, state$) =>
            action$.pipe(
                filter(isActionOf(viewActions.init)),
                switchMap(action => {
                    const resultActions: PayloadAction<string, unknown>[] = [];
                    const {
                        view: viewType,
                        displayName,
                        entity,
                        realtime,
                        defaultFilters,
                        defaultSorting,
                        defaultPaging,
                        syncWithUrl,
                        blockFetchWithInvalidFilter,
                    } = action.payload;

                    const views = viewsSelector(state$.value);
                    const view = views?.[viewType];

                    if (!view || !view?.entities?.[entity.entity]) {
                        resultActions.push(
                            viewActions.save({
                                view: viewType,
                                displayName,
                                entity,
                                realtime,
                            }),
                            viewActions.initFilter({
                                view: viewType,
                                entity: entity.entity,
                                defaultFilters,
                                defaultSorting,
                                defaultPaging,
                                syncWithUrl,
                                blockFetchWithInvalidFilter,
                            })
                        );
                    } else {
                        resultActions.push(
                            ...(view?.entities
                                ? Object.entries(view.entities).flatMap(([_, value]) => {
                                      const entityType = value.entity;
                                      return viewActions.update({
                                          entity: entityType,
                                          views: [viewType],
                                          blockFetchWithInvalidFilter,
                                      });
                                  })
                                : [])
                        );
                        const viewStateFilter = viewEntityFilterSelector(state$.value, {viewType, entityType: entity.entity});
                        const updatedPathAction = this.getUpdatePathAction(state$.value, viewStateFilter, action.payload.syncWithUrl);
                        if (updatedPathAction) {
                            resultActions.push(updatedPathAction);
                        }
                    }

                    if (realtime) {
                        resultActions.push(
                            realtimeActions.subscribe({
                                entity: realtime.entity,
                                view: viewType,
                                triggers: realtime.triggers,
                            })
                        );
                    }

                    resultActions.push(
                        viewActions.markUsed({
                            view: viewType,
                        })
                    );

                    return of(...resultActions);
                })
            );

        return initEpic;
    }

    buildInitFilterEpic() {
        const initFilterEpic: RootEpic = (action$, state$) =>
            action$.pipe(
                filter(isActionOf(viewActions.initFilter)),
                switchMap(action => {
                    const viewType = action.payload.view;
                    const entityType = action.payload.entity;
                    const viewStateFilter = viewEntityFilterSelector(state$.value, {viewType, entityType});
                    const {defaultFilters, defaultSorting, defaultPaging, syncWithUrl, blockFetchWithInvalidFilter} = action.payload;

                    let initialFilterString: string = viewStateFilter;
                    if (syncWithUrl) {
                        const urlSearch: string = locationSearchSelector(state$.value);
                        initialFilterString = getFilterString(urlSearch);
                    }
                    if (!initialFilterString) {
                        initialFilterString = this.getViewStateFilter(viewStateFilter, defaultFilters, defaultSorting, defaultPaging);
                    }
                    return viewStateFilter !== initialFilterString
                        ? of(
                              viewActions.updateFilter({
                                  view: viewType,
                                  entity: entityType,
                                  filter: initialFilterString,
                                  syncWithUrl,
                                  blockFetchWithInvalidFilter,
                              })
                          )
                        : of();
                })
            );

        return initFilterEpic;
    }

    buildUpdateFilterEpic() {
        const updateFilterEpic: RootEpic = (action$, state$) =>
            action$.pipe(
                filter(isActionOf(viewActions.updateFilter)),
                switchMap(action => {
                    const viewType = action.payload.view;
                    const view = createViewSelector(viewType)(state$.value);
                    const entities = view?.entities;

                    if (entities) {
                        const updatedEntity = Object.values(entities).find(i => i.entity === action.payload.entity);
                        const entityFetchIsBlocked = this.getFetchIsBlocked(
                            action.payload.filter,
                            action.payload.blockFetchWithInvalidFilter
                        );

                        const resultActions = [
                            viewActions.saveFilter(action.payload),
                            !entityFetchIsBlocked
                                ? entityActions.fetch({
                                      ...this.mapEntityToFetchActionPayload(updatedEntity),
                                      filter: action.payload.filter,
                                  })
                                : null,
                        ].filter(i => i);

                        const updatedPathAction = this.getUpdatePathAction(state$.value, action.payload.filter, action.payload.syncWithUrl);

                        return of(...(updatedPathAction ? [updatedPathAction, ...resultActions] : resultActions));
                    }

                    return of();
                })
            );

        return updateFilterEpic;
    }

    buildUpdateFilterKeysEpic() {
        const updateFilterKeysEpic: RootEpic = (action$, state$) =>
            action$.pipe(
                filter(isActionOf(viewActions.updateFilterKeys)),
                switchMap(action => {
                    const viewType = action.payload.view;
                    const entityType = action.payload.entity;
                    const {filters, sorting, paging} = action.payload;

                    const viewStateFilter = viewEntityFilterSelector(state$.value, {viewType, entityType});

                    const updatedViewFilter = this.getViewStateFilter(viewStateFilter, filters, sorting, paging);
                    return updatedViewFilter !== viewStateFilter
                        ? of(
                              viewActions.updateFilter({
                                  view: viewType,
                                  entity: entityType,
                                  filter: updatedViewFilter,
                                  syncWithUrl: action?.payload?.syncWithUrl ?? false,
                              })
                          )
                        : of();
                })
            );

        return updateFilterKeysEpic;
    }

    buildUpdateEpic() {
        const updateEpic: RootEpic = (action$, state$) =>
            action$.pipe(
                filter(isActionOf(viewActions.update)),
                switchMap(action => {
                    const {views, entity, blockFetchWithInvalidFilter} = action.payload;
                    const updateViewTypes = views;
                    const allViews = viewsSelector(state$.value);
                    const updatedEntities = updateViewTypes
                        .map(viewType => allViews[viewType]?.entities?.[entity])
                        .filter(updatedEntity => updatedEntity);

                    const resultActions = updatedEntities
                        ? [
                              ...updatedEntities
                                  .map(updatedEntity => {
                                      const fetchIsBlocked = this.getFetchIsBlocked(updatedEntity.filter, blockFetchWithInvalidFilter);
                                      return !fetchIsBlocked
                                          ? entityActions.fetch(this.mapEntityToFetchActionPayload(updatedEntity))
                                          : null;
                                  })
                                  .filter(i => i),
                          ]
                        : [];

                    return updatedEntities ? of(...resultActions) : of();
                })
            );

        return updateEpic;
    }

    buildUpdateKeysEpic(): RootEpic {
        return (action$, state$) =>
            action$.pipe(
                filter(isActionOf(viewActions.updateKeys)),
                switchMap(a => {
                    const resultActions: PayloadAction<string, any>[] = [];

                    const {entity, keys, filter} = a.payload;
                    const views = viewsSelector(state$.value);
                    const entities = entitiesSelector(state$.value);

                    //1. find all affected views by entity and filter
                    const affectedViews = this.getAffectedViews(views, entity, filter);
                    affectedViews.forEach(view => {
                        //2. get list of used keys
                        const oldKeys = this.getViewKeys(view, entities);

                        //3. get list of new keys (considering keys from fetch request)
                        const updatedView = {...view, entities: {...view.entities, [entity]: {...view.entities[entity], keys}}};
                        const newKeys = this.getViewKeys(updatedView, entities);

                        //4. get distinct new keys (references)
                        const newReferences = [...new Set(newKeys.filter(k => !oldKeys.includes(k)))];
                        if (newReferences?.length) {
                            resultActions.push(entityActions.increaseReferenceCounter({references: newReferences}));
                        }

                        //5. get distinct removed keys (references)
                        const deletedReferences = [...new Set(oldKeys.filter(k => !newKeys.includes(k)))];
                        if (deletedReferences?.length) {
                            resultActions.push(entityActions.decreaseReferenceCounter({references: deletedReferences}));
                        }
                    });

                    return of(...resultActions, viewActions.saveKeys(a.payload));
                })
            );
    }

    buildCloseEpic() {
        const closeEpic: RootEpic = (action$, state$) =>
            action$.pipe(
                filter(isActionOf(viewActions.close)),
                mergeMap(action => {
                    const resultActions: PayloadAction<string, any>[] = [];
                    const {view: viewType, delay: cleanDelay} = action.payload;
                    const views = viewsSelector(state$.value);
                    const view = views[viewType];

                    //unsubscribe from realtime updates
                    const realtimes = view?.realtime ? Object.values(view.realtime) : [];
                    resultActions.push(
                        ...realtimes.map(realtime =>
                            realtimeActions.unsubscribe({
                                entity: realtime.entity,
                                view: viewType,
                                triggers: realtime.triggers,
                            })
                        )
                    );

                    return merge(
                        of(...resultActions),
                        of(
                            viewActions.markUnused({
                                view: viewType,
                            })
                        ).pipe(
                            delay(cleanDelay),
                            takeUntil(
                                action$.pipe(
                                    filter(a => {
                                        return isActionOf(viewActions.markUsed)(a) && a.payload.view === viewType;
                                    })
                                )
                            )
                        )
                    );
                })
            );

        return closeEpic;
    }

    buildMarkUnusedEpic() {
        const markUnusedEpic: RootEpic = (action$, state$) =>
            action$.pipe(
                filter(isActionOf(viewActions.markUnused)),
                mergeMap(action => {
                    const resultActions: PayloadAction<string, any>[] = [];
                    const {view: viewType} = action.payload;
                    const views = viewsSelector(state$.value);
                    const view = views[viewType];

                    //decrease reference counter for unused keys
                    const entities = entitiesSelector(state$.value);
                    if (view) {
                        //get distinct removed keys (references)
                        const oldKeys = this.getViewKeys(view, entities);
                        const deletedReferences = [...new Set(oldKeys)];

                        if (deletedReferences?.length) {
                            resultActions.push(entityActions.decreaseReferenceCounter({references: deletedReferences}));
                        }
                    }

                    resultActions.push(
                        viewActions.clean({
                            view: viewType,
                        })
                    );

                    return of(...resultActions);
                })
            );

        return markUnusedEpic;
    }

    private getUpdatePathAction(state: RootState, filter: string, syncWithUrl: boolean) {
        const location = locationSelector(state);
        const currentPath = `${location?.pathname}${location?.search}`;
        const updatedPath = appendQuery(location.pathname, getUrlWithoutUtilityFilters(filter));
        const shouldUpdatePath = syncWithUrl && currentPath !== updatedPath;

        return shouldUpdatePath ? push(updatedPath) : null;
    }

    private getAffectedViews(views: ViewsState, type: EntityType, filter: string) {
        return views && type ? Object.values(views).filter(v => v?.entities[type]?.filter === filter) : [];
    }

    private getViewKeys(view: ViewState, entities: EntitiesState): string[] {
        const keysAdapter = new EntityKeysMapper(schemaMapper);

        return view?.entities
            ? Object.entries(view.entities).flatMap(([_, value]) => {
                  const entityType = value.entity;
                  const viewKeys = value.keys ?? [];
                  return viewKeys.flatMap(key => {
                      return this.getUniqueKeys(entities, entityType, key).map(k => keysAdapter.mapToStringKey(k));
                  });
              })
            : [];
    }

    private getUniqueKeys(entities: EntitiesState, parentType: EntityType, parentId: string): ParsedEntityKey[] {
        const keysAdapter = new EntityKeysMapper(schemaMapper);
        const result: ParsedEntityKey[] = [];
        const entityState = entities?.entities[parentType];

        if (entityState && entityState[parentId]) {
            const keys = keysAdapter.getUniqueKeyObjects(entityState[parentId] as object, parentType);
            result.push(...keys);
            keys.forEach(({type, id}) => {
                if (type !== parentType || id !== parentId) {
                    result.push(...this.getUniqueKeys(entities, type, id));
                }
            });
        }

        return result;
    }

    private getViewStateFilter(
        initialFilterString: string,
        filters?: Filter<any, string>[],
        sorting?: Sorting<string>,
        paging?: Paging
    ): string {
        let updatedFilterString: string = initialFilterString;

        if (filters && filters?.length) {
            updatedFilterString = getFilterString(updatedFilterString, false, ...filterNotNulls(filters));
        }
        if (sorting) {
            const sortingFilters = this.mapSortingToFilter(sorting);
            updatedFilterString = getFilterString(updatedFilterString, false, ...filterNotNulls(sortingFilters));
        }
        if (paging && paging?.page > 0 && paging?.pageSize > 0) {
            updatedFilterString = getPagingString(updatedFilterString, paging);
        }

        return updatedFilterString ?? '';
    }

    private mapSortingToFilter(sorting: Sorting<string>): Filter<any, BaseFilterKeys>[] {
        const sortingFilters: Filter<any, BaseFilterKeys>[] = [
            {
                key: 'sortField',
                value: sorting?.field,
            },
            {
                key: 'sortOrder',
                value: sorting?.sort,
            },
        ];

        return sortingFilters;
    }

    private mapEntityToFetchActionPayload(entity: ViewEntityData<string>): EntityFetchActionPayload {
        return {
            type: entity.entity,
            filter: entity.filter,
            fields: entity.fields,
        };
    }

    private getFetchIsBlocked(filter: string, blockWithInvalidFilter: boolean): boolean {
        const filterObject = new URLSearchParams(filter);
        const invalidFilterValue = filterObject.get(queryKeys.invalidFilter);
        return blockWithInvalidFilter && invalidFilterValue === 'true';
    }
}
