import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {LayoutDetailsActionsCollapsible} from '@components/layout';
import {LayoutDetailsItemGroup} from '@components/layout/LayoutDetailsItemGroup';
import {
    AccountVerification,
    AccountVerificationStatus,
    AccountVerificationType,
    KycInitReasonCode,
    PaymentVendor,
    Transaction,
    TransactionStatus,
} from '@models/generated/graphql';
import {
    AccountVerificationFilterKeys,
    AccountVerificationQueryFields,
    EntityType,
    TransactionFilterKeys,
    TransactionQueryFields,
} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {useViewInit} from '@redux/view';
import {isStringNullOrEmpty} from '@utils/string';

import {InitiatePaymentKyc} from '../../block-account-verification';
import {WithdrawalChangeStatusActions, WithdrawalExternalCashierLink} from '../../block-transaction-actions';

const localized = defineMessages({
    withdrawalActions: {
        id: 'WithdrawalActions_title',
        defaultMessage: 'Withdrawal Actions',
    },
});

type WithdrawalActionsProps = {
    transactionId: string;
};

type UseTransactionData = {
    transaction: Transaction;
};

function useTransactions(transactionId: string): UseTransactionData {
    //TODO: use Transaction hook to read data
    const fields: TransactionQueryFields[] = ['transaction_id', 'payment_vendor', 'transaction_status', 'uid'];
    const {items} = useViewInit<Transaction, TransactionFilterKeys, TransactionQueryFields>({
        displayName: localized.withdrawalActions,
        viewType: 'WithdrawalActions',
        entity: {
            entity: EntityType.Transaction,
            fields,
        },
        defaultPaging: {page: 1, pageSize: 1},
        defaultFilters: [{key: 'transactionId', value: transactionId}],
        realtime: {
            entity: EntityType.Transaction,
            mode: RealtimeUpdatesMode.Silent,
            triggers: [RealtimeMessageTrigger.Update],
        },
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'transactionId')?.value),
    });

    return {transaction: items?.length > 0 ? items[0] : null};
}

type UseAccountVerificationData = {
    latestKyc: AccountVerification;
};

function useAccountVerification(uid: string): UseAccountVerificationData {
    //TODO: use AccountVerification hook to read data
    const fields: AccountVerificationQueryFields[] = ['uid', 'id', 'account_verification_status', 'init_reason_codes'];
    const {items} = useViewInit<AccountVerification, AccountVerificationFilterKeys, AccountVerificationQueryFields>({
        displayName: localized.withdrawalActions,
        viewType: 'WithdrawalActions',
        entity: {
            entity: EntityType.AccountVerification,
            fields,
        },
        defaultPaging: {page: 1, pageSize: 1},
        defaultFilters: [
            {key: 'uid', value: uid},
            {key: 'type', value: [AccountVerificationType.Payment]},
        ],
        defaultSorting: [{field: 'initiated_at.seconds', sort: 'desc'}],
        realtime: {
            entity: EntityType.AccountVerification,
            mode: RealtimeUpdatesMode.Silent,
            triggers: [RealtimeMessageTrigger.Add, RealtimeMessageTrigger.Update],
        },
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'uid')?.value),
    });

    return {latestKyc: items?.length > 0 ? items[0] : null};
}

type UseWithdrawalActionsData = {
    transactionStatus: TransactionStatus;
    transactionPaymentVendor: PaymentVendor;
    uid: string;
    accountVerificationStatus: AccountVerificationStatus;
    initReasonsCodes: KycInitReasonCode[];
};

export function useWithdrawalActionsData(transactionId: string): UseWithdrawalActionsData {
    const {transaction} = useTransactions(transactionId);
    const {latestKyc} = useAccountVerification(transaction?.uid);

    return {
        transactionStatus: transaction?.transaction_status,
        transactionPaymentVendor: transaction?.payment_vendor,
        uid: transaction?.uid,
        accountVerificationStatus: latestKyc?.account_verification_status,
        initReasonsCodes: latestKyc?.init_reason_codes,
    };
}

export function WithdrawalActions({transactionId}: WithdrawalActionsProps) {
    const {formatMessage} = useIntl();
    const {transactionStatus, transactionPaymentVendor, accountVerificationStatus, initReasonsCodes, uid} =
        useWithdrawalActionsData(transactionId);

    return (
        <LayoutDetailsActionsCollapsible header={formatMessage(localized.withdrawalActions)}>
            <LayoutDetailsItemGroup orientation="horizontal">
                <WithdrawalChangeStatusActions
                    transactionId={transactionId}
                    transactionStatus={transactionStatus}
                    paymentVendor={transactionPaymentVendor}
                    uid={uid}
                />
            </LayoutDetailsItemGroup>
            <WithdrawalExternalCashierLink
                transactionId={transactionId}
                transactionStatus={transactionStatus}
                paymentVendor={transactionPaymentVendor}
            />
            <InitiatePaymentKyc uid={uid} status={accountVerificationStatus} reasons={initReasonsCodes} disabled={uid === undefined} />
        </LayoutDetailsActionsCollapsible>
    );
}
