import {PhoneNumber, Reason, Transaction, TransactionHistory, UserContactInfo, UserLabel, UserLabelGroup} from '@models/generated/graphql';
import {TimestampKeys} from '@models/shared';

import {BaseFilterKeys} from './base';
import {AgentReferrerFilterKeys} from './entityAgentProfile';

export type TransactionTextFilterKeys =
    | 'uid'
    | 'username'
    | 'transactionId'
    | 'withdrawalId'
    | 'email'
    | 'uidUsernameTransactionId'
    | 'uid_em_un_rmc'
    | 'paymentMethodName'
    | 'register_marketing_code'
    | 'labels'
    | 'labelsText'
    | 'uid_un_tid_em';

export const TransactionDateRangeFilterPrefix = 'startedTs' as const;

export type TransactionFilterKeys =
    | BaseFilterKeys
    | 'transactionStatus'
    | 'defaultTransactionStatus'
    | 'accountStatus'
    | 'paymentOption'
    | 'transactionTypes'
    | 'defaultTransactionTypes'
    | `${typeof TransactionDateRangeFilterPrefix}.from`
    | `${typeof TransactionDateRangeFilterPrefix}.to`
    | 'updatedTsFrom'
    | 'updatedTsTo'
    | 'accountVerificationStatus'
    | 'reasonCode'
    | 'amountMax'
    | 'amountMin'
    | 'registrationCountry'
    | AgentReferrerFilterKeys
    | TransactionTextFilterKeys;

type TransactionKeys = keyof Transaction;
type StatusLogKeys = keyof TransactionHistory;
const statusLogKey: TransactionKeys = 'status_log';
type ReasonKeys = keyof Reason;
const reasonsKey: TransactionKeys = 'reasons';
const transactionStartedKey: TransactionKeys = 'transaction_started_ts';
const transactionUpdatedKey: TransactionKeys = 'transaction_updated_ts';
type UserLabelKeys = keyof UserLabel;
type UserLabelGroupKeys = keyof UserLabelGroup;
const labelsKey: TransactionKeys = 'user_labels';
const contactKey: TransactionKeys = 'contact';
type UserContactInfoKeys = keyof UserContactInfo;
type PhoneNumberKeys = keyof PhoneNumber;

export type TransactionQueryFields =
    | TransactionKeys
    | `${typeof statusLogKey}.${StatusLogKeys}`
    | `${typeof statusLogKey}.${StatusLogKeys}.${TimestampKeys}`
    | `${typeof transactionStartedKey}.${TimestampKeys}`
    | `${typeof transactionUpdatedKey}.${TimestampKeys}`
    | `${typeof reasonsKey}.${ReasonKeys}`
    | `${typeof reasonsKey}.${ReasonKeys}.${TimestampKeys}`
    | `${typeof contactKey}.${UserContactInfoKeys}`
    | `${typeof contactKey}.${UserContactInfoKeys}.${PhoneNumberKeys}`
    | `${typeof labelsKey}.${UserLabelKeys}`
    | `${typeof labelsKey}.${UserLabelKeys}.${UserLabelGroupKeys}`;
