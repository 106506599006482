import {useDispatch} from 'react-redux';
import {GridRowSelectionModel} from '@mui/x-data-grid';
import equal from 'fast-deep-equal/es6';

import {IModuleGridItem} from '@components/data-grid';

import {itemsSelectedActions} from '../../../../../features/module-shared/actions';
import {MuiDataGridProps} from '../MuiDataGrid';

//TODO: [BO-2720] Remove useGridItemsSelected once switch to view-entity (data grid events should be used instead)
export const useGridItemsSelected = ({domain, rows, selectedItems, onSelectionModelChange, onSelect}: MuiDataGridProps) => {
    const actions = itemsSelectedActions(domain);
    const dispatch = useDispatch();

    const selectionModel = selectedItems?.map(i => i?.id)?.sort();

    const onItemsSelected = (rowSelectionModel: GridRowSelectionModel) => {
        if (!equal(selectionModel, rowSelectionModel)) {
            const selectedIDs = new Set(rowSelectionModel);
            const selectedRows = rows.filter(row => selectedIDs.has(row?.id));
            dispatch(actions.itemsSelected(selectedRows as IModuleGridItem[]));

            if (onSelectionModelChange) {
                onSelectionModelChange({selectionModel: rowSelectionModel});
            }

            if (onSelect) {
                onSelect(rowSelectionModel as string[]);
            }
        }
    };

    const gridSelectionProps = {
        disableRowSelectionOnClick: true,
        onRowSelectionModelChange: onItemsSelected,
        rowSelectionModel: selectionModel,
    };

    return {
        gridSelectionProps,
        selectedItems,
    };
};
