import {useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import produce from 'immer';
import {RootState} from 'typesafe-actions';

import {useAutoMapper} from '@auto-mapper';
import {MultiSelectWithExcludeModel} from '@components/select';
import {
    CasinoGame,
    CasinoGameFields,
    CasinoGameFilterKeys,
    CasinoSourceType,
    CasinoSupplier,
    CasinoSupplierFields,
    CasinoSupplierFilterKeys,
} from '@models/casino-game';
import {BonusCodeDetails} from '@models/generated/graphql';
import {BonusCodeQueryFields, BonusCodeServerFilterKeys, EntityType} from '@redux/entity';
import {UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';
import {CasinoArrayFilterWithMode} from '@services/rest-api/casinoCmsApiService';

import {Filter, Sorting} from '../../common/types';
import {actionItemsSelector, BulkActionItemPayload, BulkActionKey, BulkItemStatus} from '../block-bulk-actions';

import {
    BonusCodeDetailsViewModel,
    BonusCodeDetailsViewModelKeys,
    BonusTriggerViewModel,
    CasinoGameViewModel,
    CasinoSupplierViewModel,
} from './types';

type UseCasinoSuppliers = Omit<UseListViewEntityProps<CasinoSupplierFilterKeys, never>, 'fields'>;

export function useBonusCodes({
    realtimeMode,
    triggers,
    ids,
    fields,
    viewType,
    displayName,
    defaultFilters,
    defaultPaging,
    defaultSorting,
    syncWithUrl,
    validateFilter,
    cleanDelay,
}: UseListViewEntityProps<BonusCodeServerFilterKeys, BonusCodeDetailsViewModelKeys>): UseListViewEntityResult<
    BonusCodeDetailsViewModel,
    BonusCodeDetailsViewModelKeys
> {
    const mapper = useAutoMapper();

    function getDefaultFilters(): Filter<string, BonusCodeServerFilterKeys>[] {
        const filters: Filter<string, BonusCodeServerFilterKeys>[] = defaultFilters ? [...defaultFilters] : [];
        if (ids?.length > 0) {
            const uniqueUids: string[] = getUniqueIds();
            const uidFilter: string = uniqueUids.join(' ');
            filters.push({key: 'bonusId', value: uidFilter});
        }
        return filters;
    }

    function getUniqueIds(): string[] {
        return [...new Set(ids)];
    }

    const {
        items,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handleFilterChange,
        handleSortChange,
        handlePageChange,
        handlePageSizeChange,
    } = useViewInit<BonusCodeDetails, BonusCodeServerFilterKeys, BonusCodeQueryFields>({
        viewType,
        entity: {
            entity: EntityType.BonusCode,
            fields: mapFields(fields),
        },
        realtime: realtimeMode && triggers ? {entity: EntityType.BonusCode, mode: realtimeMode, triggers} : null,
        displayName,
        defaultFilters: getDefaultFilters(),
        defaultPaging: ids?.length > 0 ? {page: 1, pageSize: getUniqueIds().length} : defaultPaging,
        validateFilter,
        cleanDelay,
        defaultSorting: mapSorting(defaultSorting),
        syncWithUrl,
    });

    const bonusCodeItems: BonusCodeDetailsViewModel[] = items?.map(i => mapper.map(i, BonusCodeDetails, BonusCodeDetailsViewModel));

    return {
        items: bonusCodeItems,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange: handleVmSortChange,
        handleFilterChange,
    };

    function mapFields(fields: BonusCodeDetailsViewModelKeys[]): BonusCodeQueryFields[] {
        return fields?.map(field =>
            mapper.map<BonusCodeDetailsViewModelKeys, BonusCodeQueryFields>(
                field,
                nameof<BonusCodeDetailsViewModelKeys>(),
                nameof<BonusCodeQueryFields>()
            )
        );
    }

    function mapField(field: BonusCodeDetailsViewModelKeys): BonusCodeQueryFields {
        return mapFields([field])[0];
    }

    function mapSorting(sorting: Sorting<BonusCodeDetailsViewModelKeys>[]): Sorting<BonusCodeQueryFields>[] {
        return sorting?.map(s => ({...s, field: mapField(s.field)}));
    }

    function handleVmSortChange(sorting: Sorting<BonusCodeDetailsViewModelKeys>[]): void {
        handleSortChange(mapSorting(sorting));
    }
}

export function useCasinoSuppliers({
    viewType,
    displayName,
    defaultFilters,
    validateFilter,
    cleanDelay,
}: UseCasinoSuppliers): UseListViewEntityResult<CasinoSupplierViewModel, CasinoSupplierFields> {
    const mapper = useAutoMapper();

    const {
        items,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handleFilterChange,
        handlePageChange,
        handlePageSizeChange,
    } = useViewInit<CasinoSupplier, CasinoSupplierFilterKeys, CasinoSupplierFields>({
        viewType,
        entity: {
            entity: EntityType.CasinoSupplier,
            fields: null,
        },
        displayName,
        defaultFilters,
        defaultPaging: {page: 1, pageSize: 25},
        validateFilter,
        cleanDelay,
    });

    const suppliers = items?.filter(i => i)?.map(i => mapper.map(i, CasinoSupplier, CasinoSupplierViewModel));

    function handleSortChange() {}

    return {
        items: suppliers,
        totalCount,
        filterString,
        searchFilter,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    };
}

type UseCasinoGames = Omit<UseListViewEntityProps<CasinoGameFilterKeys, never>, 'defaultFilters' | 'fields'> & {
    sourceTypeFilter?: MultiSelectWithExcludeModel;
    aggregatorFilter?: MultiSelectWithExcludeModel;
    suppliersFilter?: MultiSelectWithExcludeModel;
};

export function useCasinoGames({
    viewType,
    displayName,
    sourceTypeFilter,
    aggregatorFilter,
    suppliersFilter,
    validateFilter,
    cleanDelay,
}: UseCasinoGames): UseListViewEntityResult<CasinoGameViewModel, CasinoGameFields> {
    const mapper = useAutoMapper();

    const {
        items,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handleFilterChange,
        handlePageChange,
        handlePageSizeChange,
    } = useViewInit<CasinoGame, CasinoGameFilterKeys, CasinoGameFields>({
        viewType,
        entity: {
            entity: EntityType.CasinoGame,
            fields: null,
        },
        displayName,
        defaultPaging: {page: 1, pageSize: 25},
        validateFilter,
        cleanDelay,
    });

    useEffect(() => {
        const allSourceTypes: CasinoSourceType[] = [CasinoSourceType.External, CasinoSourceType.Internal];
        const sourceTypes =
            sourceTypeFilter?.exclude?.length > 0
                ? allSourceTypes?.filter(t => !sourceTypeFilter?.exclude?.includes(t))
                : sourceTypeFilter?.include;
        handleFilterChange([
            {key: 'source_type', value: sourceTypes},
            {key: 'aggregators', value: mapper.map(aggregatorFilter, MultiSelectWithExcludeModel, CasinoArrayFilterWithMode)},
            {key: 'supplier_codes', value: mapper.map(suppliersFilter, MultiSelectWithExcludeModel, CasinoArrayFilterWithMode)},
        ]);
    }, [
        sourceTypeFilter?.exclude?.join(),
        sourceTypeFilter?.include?.join(),
        aggregatorFilter?.exclude?.join(),
        aggregatorFilter?.include?.join(),
        suppliersFilter?.exclude?.join(),
        suppliersFilter?.include?.join(),
    ]);

    const games = items?.filter(i => i)?.map(i => mapper.map(i, CasinoGame, CasinoGameViewModel));

    function handleSortChange() {}

    return {
        items: games,
        totalCount,
        filterString,
        searchFilter,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    };
}

type UseTriggerBonusSummaryResult = {
    groupedBonuses: Record<string, BulkActionItemPayload<BonusTriggerViewModel>[]>;
};

export function useTriggerBonusesSummaryGroups(actionKey: BulkActionKey): UseTriggerBonusSummaryResult {
    const actionItems: BulkActionItemPayload<BonusTriggerViewModel>[] = useSelector(
        (state: RootState) => actionItemsSelector(state, actionKey) as BulkActionItemPayload<BonusTriggerViewModel>[]
    );

    function groupPlayerBonusByBonusId(
        actionItems: BulkActionItemPayload<BonusTriggerViewModel>[]
    ): Record<string, BulkActionItemPayload<BonusTriggerViewModel>[]> {
        return actionItems.reduce<Record<string, BulkActionItemPayload<BonusTriggerViewModel>[]>>((result, item) => {
            if (!result[item.value.bonus_id]) {
                result[item.value.bonus_id] = [];
            }
            result[item.value.bonus_id].push(item);

            return result;
        }, {});
    }

    return {groupedBonuses: groupPlayerBonusByBonusId(actionItems)};
}

type UseMttBonusSummaryResult = {
    groupByTicket: (items: BulkActionItemPayload<BonusTriggerViewModel>[]) => BulkActionItemPayload<BonusTriggerViewModel>[];
};

export function useMttBonusGroupedByTicket(): UseMttBonusSummaryResult {
    const groupByTicket = useCallback((items: BulkActionItemPayload<BonusTriggerViewModel>[]) => getPlayerBonuses(items), []);

    function getPlayerBonuses(actionItems: BulkActionItemPayload<BonusTriggerViewModel>[]): BulkActionItemPayload<BonusTriggerViewModel>[] {
        return actionItems.reduce<BulkActionItemPayload<BonusTriggerViewModel>[]>((accumulator, item) => {
            const groupedPlayerBonus = accumulator.find(b => b.value?.uid === item.value?.uid);
            let triggeredTickets = groupedPlayerBonus?.value?.triggered_tickets ?? 0;
            if (item.status === BulkItemStatus.Successful) {
                triggeredTickets++;
            }

            const updatedItem = produce(item, draft => {
                draft.value.triggered_tickets = triggeredTickets;
                const isBonusProcessing =
                    groupedPlayerBonus?.status === BulkItemStatus.InProgress || groupedPlayerBonus?.status === BulkItemStatus.Pending;
                if (isBonusProcessing) {
                    draft.status = groupedPlayerBonus.status;
                } else {
                    const isOperationPartiallySuccessful =
                        groupedPlayerBonus?.status === BulkItemStatus.PartiallySuccessful ||
                        (groupedPlayerBonus?.status === BulkItemStatus.Failed && draft.status === BulkItemStatus.Successful) ||
                        (groupedPlayerBonus?.status === BulkItemStatus.Successful && draft.status === BulkItemStatus.Failed);
                    if (isOperationPartiallySuccessful) {
                        draft.status = BulkItemStatus.PartiallySuccessful;
                    }
                }

                return draft;
            });
            const result = accumulator.filter(b => b.value?.uid !== item.value?.uid);
            result.push(updatedItem);

            return result;
        }, []);
    }

    return {groupByTicket};
}
