import React, {useContext, useEffect} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import Button from '@components/button/Buttons';
import {BulkModalContent, BulkModalContext, BulkModalTitle, ModalFooter} from '@components/modal';
import {AccountVerificationViewModelKeys} from '@models/account-verification';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {AccountVerificationFilterKeys, UserProfileServerFilterKeys} from '@redux/entity';
import {getNonEmptyValueValidator, ViewType} from '@redux/view';

import {useAccountVerifications} from '../../block-account-verification';
import {
    BulkActionKey,
    bulkActionsActions,
    BulkNoteApplyStepButton,
    BulkStepProps,
    itemsSelector,
    useBulkModalStatus,
} from '../../block-bulk-actions';
import {BulkUserProfileAccountLockGroup, BulkUserProfileFeatureLockGroup} from '../../block-user-profile-bulk-actions';

import {BulkKYCApplyNotesModal} from './BulkKYCApplyNotesModal';
import {BulkKYCRejectButton} from './BulkKYCRejectButton';

const localized = defineMessages({
    title: {
        id: 'BulkStepChooseActions_actionBulkPlayerSecurity',
        defaultMessage: 'Add Bulk Action',
    },
    cancel: {
        id: 'BulkStepChooseActions_Cancel',
        defaultMessage: 'Cancel',
    },
    confirm: {
        id: 'BulkStepChooseActions_Confirm',
        defaultMessage: 'Confirm',
    },
    itemsSelected: {
        id: 'BulkStepChooseActions_ItemsSelected',
        defaultMessage: '{value} Items Selected',
    },
    displayName: {
        id: 'BulkStepChooseActions_displayName',
        defaultMessage: 'KYC Choose Action Step',
    },
});

type BulkStepPlayerSecurityChooseActionsProps = BulkStepProps & {
    onApply: () => void;
    actionKeys: BulkActionKey[];
    ids: string[];
};

export function BulkKYCChooseActionStepModal({onPrev, onNext, onApply, actionKeys, ids}: BulkStepPlayerSecurityChooseActionsProps) {
    const {formatMessage} = useIntl();
    const dispatch = useDispatch();
    const selectedItems = useSelector(itemsSelector);
    const {hasProcessingItem} = useBulkModalStatus();
    const {chooseStep} = useContext(BulkModalContext);
    const viewType: ViewType = 'BulkKYCChooseActionStep';

    const fields: AccountVerificationViewModelKeys[] = ['id', 'uid', 'username', 'account_verification_status'];
    const userProfileFields: UserProfileViewModelKeys[] = [
        'uid',
        'account_status',
        'deposit_status',
        'casino_status',
        'withdrawal_status',
        'lobby_access_status',
        'p2p_transfer_status',
        'sportsbook_status',
    ];
    const {items} = useAccountVerifications(
        {
            viewType,
            displayName: localized.displayName,
            fields,
            defaultFilters: [{key: 'id', value: ids.join(' ')}],
            validateFilter: getNonEmptyValueValidator<AccountVerificationFilterKeys>('id'),
        },
        {
            viewType,
            fields: userProfileFields,
            validateFilter: getNonEmptyValueValidator<UserProfileServerFilterKeys>('uid'),
        }
    );

    useEffect(() => {
        if (items?.length) {
            dispatch(bulkActionsActions.setBulkActionsItems(items));
        }
    }, [JSON.stringify(items)]);

    const openNotes = () => chooseStep(notes);

    const handleCancelClick = () => {
        onPrev();
    };

    const handleNextClick = () => {
        onNext();
    };

    const totalItemsInfoMessage: string = formatMessage(localized.itemsSelected, {
        value: selectedItems?.length,
    });

    const notes = <BulkKYCApplyNotesModal onPrev={onApply} onNext={onApply} />;

    return (
        <>
            <BulkModalTitle title={localized.title} />
            <BulkModalContent>
                <BulkUserProfileAccountLockGroup actionKeys={actionKeys} />
                <BulkUserProfileFeatureLockGroup actionKeys={actionKeys} />
                {actionKeys.includes(BulkActionKey.KYCReject) ? <BulkKYCRejectButton /> : null}
                {actionKeys.includes(BulkActionKey.NotesAndAttachments) ? <BulkNoteApplyStepButton onClick={openNotes} /> : null}
            </BulkModalContent>
            <ModalFooter infoMessage={totalItemsInfoMessage}>
                <Button label={localized.cancel} onClick={handleCancelClick} />
                <Button color="primary" label={localized.confirm} onClick={handleNextClick} disabled={!hasProcessingItem} />
            </ModalFooter>
        </>
    );
}
