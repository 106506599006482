import {defineMessages} from 'react-intl';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {
    AmountCurrencyCellProps,
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    EditableNumberCellProps,
    MuiDataGridClient,
    renderAmountCurrencyCellWithTooltip,
    renderCellHeader,
    renderCellWithTooltip,
    renderDefaultChipCell,
    renderEditableNumberCell,
    withDataGridEntityClient,
} from '@components/data-grid/mui';

import {DataGridUserProfileConfigFactory} from '../../block-user-profile-list';
import {BonusTriggerViewModel, BonusTriggerViewModelKeys} from '../types';

const localizedHeaders = defineMessages({
    bonusTriggerGridAmount: {
        id: 'bonusTriggerGrid_Amount',
        defaultMessage: 'Amount',
    },
    bonusTriggerGridMarketingCode: {
        id: 'bonusTriggerGrid_MarketingCode',
        defaultMessage: 'Marketing code',
    },
    bonusTriggerGridTicketCount: {
        id: 'bonusTriggerGrid_TicketCount',
        defaultMessage: 'Total tickets',
    },
    bonusTriggerGridAppliedTicketCount: {
        id: 'bonusTriggerGrid_AppliedTicketCount',
        defaultMessage: 'Successfully triggered tickets',
    },
});

export type BonusTriggerEditableColumns =
    | Extract<BonusTriggerViewModelKeys, 'total_amount' | 'marketing_code' | 'ticket_count' | 'triggered_tickets'>
    | 'ticket_count_editable';

export class DataGridBonusTriggerConfigFactory extends DataGridEntityConfigFactory<BonusTriggerEditableColumns, BonusTriggerViewModel> {
    getColumnConfig(): Record<BonusTriggerEditableColumns, DataGridEntityColDef<BonusTriggerEditableColumns>> {
        return {
            total_amount: {
                field: 'total_amount',
                valueGetter: p => this.getAmount(p),
                renderHeader: () => renderCellHeader(localizedHeaders.bonusTriggerGridAmount),
                renderCell: params => renderAmountCurrencyCellWithTooltip(params),
                flex: 0.1,
                sortable: false,
            },
            marketing_code: {
                field: 'marketing_code',
                valueGetter: p => this.getMarketingCode(p),
                renderHeader: () => renderCellHeader(localizedHeaders.bonusTriggerGridMarketingCode),
                renderCell: renderDefaultChipCell,
                flex: 0.15,
                sortable: false,
            },
            ticket_count: {
                field: 'ticket_count',
                valueGetter: p => this.getTicketCount(p),
                renderHeader: () => renderCellHeader(localizedHeaders.bonusTriggerGridTicketCount),
                renderCell: renderCellWithTooltip,
                flex: 0.1,
                sortable: false,
            },
            ticket_count_editable: {
                field: 'ticket_count_editable',
                valueGetter: p => this.getEditableTicketCount(p),
                renderHeader: () => renderCellHeader(localizedHeaders.bonusTriggerGridTicketCount),
                renderCell: renderEditableNumberCell,
                renderEditCell: renderEditableNumberCell,
                editable: true,
                flex: 0.15,
                sortable: false,
            },
            triggered_tickets: {
                field: 'triggered_tickets',
                valueGetter: p => this.getTriggeredTicketCount(p),
                renderHeader: () => renderCellHeader(localizedHeaders.bonusTriggerGridAppliedTicketCount),
                renderCell: renderCellWithTooltip,
                flex: 0.1,
                sortable: false,
            },
        };
    }

    private getAmount(p: GridValueGetterParams): AmountCurrencyCellProps {
        return {amount: this.getRow(p)?.total_amount, currency: this.getRow(p)?.currency};
    }

    private getMarketingCode(p: GridValueGetterParams) {
        return this.getRow(p)?.marketing_code;
    }

    private getTicketCount(p: GridValueGetterParams) {
        return this.getRow(p)?.ticket_count;
    }

    private getTriggeredTicketCount(p: GridValueGetterParams) {
        return this.getRow(p)?.triggered_tickets;
    }

    private getEditableTicketCount(p: GridValueGetterParams): EditableNumberCellProps {
        return {value: this.getRow(p)?.ticket_count};
    }
}

export const DataGridBonusTriggerClient = withDataGridEntityClient<
    BonusTriggerViewModelKeys | BonusTriggerEditableColumns,
    BonusTriggerViewModel
>(MuiDataGridClient, () => ({
    ...new DataGridUserProfileConfigFactory().getColumnConfig(),
    ...new DataGridBonusTriggerConfigFactory().getColumnConfig(),
}));
