import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';

import {PentagonSvgIcon} from '@components/icons/PentagonSvgIcon';
import {BulkModalContext} from '@components/modal';
import {useService} from '@inversify';
import {UserProfileQueryFields} from '@redux/entity';

import {ModuleName, SubmoduleName} from '../../../common/routeEnums';
import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {BulkChooseActionStepButton, useBulkAction} from '../../block-bulk-actions';
import {LoadPlayerItemsStrategy} from '../../block-user-profile-bulk-actions';
import {PermissionEnum} from '../../module-shared/types';

import {BulkLabelChooseActionStepModal} from './BulkLabelChooseActionStepModal';
import {BulkLabelExecutionStepModal} from './BulkLabelExecutionStepModal';
import {BulkLabelSummaryStepModal} from './BulkLabelSummaryStepModal';

export const localized = defineMessages({
    manageLabel: {
        id: 'manageLabel',
        defaultMessage: 'Manage Label',
    },
});

export const bulkManageButtonPermissions = [
    {
        moduleName: ModuleName.Comments,
        submoduleName: SubmoduleName.NotesSecurity,
        permissions: [PermissionEnum.Create, PermissionEnum.Delete],
    },
    {
        moduleName: ModuleName.Comments,
        submoduleName: SubmoduleName.Payment,
        permissions: [PermissionEnum.Create, PermissionEnum.Delete],
    },
    {
        moduleName: ModuleName.Comments,
        submoduleName: SubmoduleName.KYC,
        permissions: [PermissionEnum.Create, PermissionEnum.Delete],
    },
    {
        moduleName: ModuleName.Comments,
        submoduleName: SubmoduleName.NotesPlayer,
        permissions: [PermissionEnum.Create, PermissionEnum.Delete],
    },
    {
        moduleName: ModuleName.Comments,
        submoduleName: SubmoduleName.NotesCustomerSupport,
        permissions: [PermissionEnum.Create, PermissionEnum.Delete],
    },
    {
        moduleName: ModuleName.Comments,
        submoduleName: SubmoduleName.NotesBotDetection,
        permissions: [PermissionEnum.Create, PermissionEnum.Delete],
    },
];

type BulkManageLabelButtonProps = {
    uids: string[];
};

function BulkManageButtonInner({uids}: BulkManageLabelButtonProps) {
    const fields: UserProfileQueryFields[] = [
        'uid',
        'first_name',
        'last_name',
        'nickname',
        'labels.id',
        'labels.name',
        'labels.group.id',
        'labels.group.name',
        'labels.group.color',
    ];
    const loadStrategy = useService<LoadPlayerItemsStrategy>('LoadPlayerItemsStrategy');
    const {resetBulkState} = useBulkAction({strategy: loadStrategy, request: {uids, fields}});
    const {chooseStep, closeModal} = useContext(BulkModalContext);

    const openApplyAction = () => chooseStep(chooseActionStep);
    const openChangesSummary = () => chooseStep(changesSummaryStep);
    const openExecutionSummary = () => chooseStep(executionSummaryStep);
    const changesSummaryStep = <BulkLabelSummaryStepModal onNext={openExecutionSummary} onPrev={closeModal} />;
    const executionSummaryStep = <BulkLabelExecutionStepModal onNext={closeModal} hasRetry={true} />;
    const chooseActionStep = <BulkLabelChooseActionStepModal onNext={openChangesSummary} onPrev={closeModal} />;

    const handleClick = () => {
        resetBulkState();
        openApplyAction();
    };

    return (
        <BulkChooseActionStepButton
            itemsCount={uids.length}
            onClick={handleClick}
            label={localized.manageLabel}
            startIcon={<PentagonSvgIcon color={'#ffffff'} />}
        />
    );
}

export const BulkManageButton = withMultiplePermission(BulkManageButtonInner, {allowedPermissions: bulkManageButtonPermissions});
